import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { map } from 'lodash';

// Actions
import MapActions from '../../../actions/map-actions';
import AdminSettingsActions from '../../../actions/admin-settings-actions';

// Constants
import Help from '../../../constants/help-upgrade-constants';
import { WebHookTriggers, WebHookTriggerNames } from '../../../constants/webhook-triggers';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import MapStore from '../../../stores/map-store';
import ContextStore from '../../../stores/context-store';

// Utils
import UIUtils from '../../../utils/ui-utils';
import { ReactSVG } from 'react-svg';

/**
 * Webhooks Tab Container component on the Logic Dashboard 
 * @class WebhooksLogicDashboardTabContainer
 * @extends {Component}
 */
class WebhooksLogicDashboardTabContainer extends Component {
	/**
	 * Creates an instance of WebhooksLogicDashboardTabContainer.
	 * @param {any} props 
	 */
	constructor(props) {
		super(props);

		this._renderGroupBy = this._renderGroupBy.bind(this);
		this._onChangeSearch = this._onChangeSearch.bind(this);
		this._onLoadTrigger = this._onLoadTrigger.bind(this);
		this._onToggleShow = this._onToggleShow.bind(this);
		this._onToggleAllOpen = this._onToggleAllOpen.bind(this);
		this._onToggleAllClosed = this._onToggleAllClosed.bind(this);
	}

	/**
	 * Gets the stores to watch
	 * 
	 * @static
	 * @returns {Array of Object}
	 */
	static getStores() {
		return [MapStore];
	}

	/**
	 * Returns the current State of the LogicMapContainer
	 * 
	 * @static
	 */
	static calculateState(prevState, props) {
		let search = MapStore.getSearch(AdminSettingsStore.getActiveDashboardTab());
		return {
			search: search,
			groupedWebhooks: this._performGroupBy(search),
			groupToggleStatus: MapStore.getGroupToggleStatusGroupBy(AdminSettingsStore.getActiveDashboard(), 'webhooks'),
		};
	}

	/**
	 * Using the group by set in the UI, group the webhooks and return
	 * an object with the groups as keys and functions array as value.
	 * @param {Array} apis APIs to group
	 * @param {String} groupBy How to group the apis
	 * @param {String} search What search to apply, if any.
	 * @return Object
	 */
	static _performGroupBy(search) {
		let groups = {
			'Stripe': WebHookTriggers[WebHookTriggerNames.STRIPE]
		};

		// Filter out the functions that dont have the search term.
		groups['Stripe'] = groups['Stripe'].filter(trigger => {
			if (search && search.length > 0) {
				return (trigger.label.toLowerCase().includes(search.toLowerCase()));
			} else {
				return true;
			}
			// Sort the functions - by Name.
		}).sort((a, b) => {
			// Ascending Alphabetically
			return b.label < a.label ? 1 : -1;
		});

		return groups;
	}

	/**
	 * Render this component.
	 * 
	 * @returns JSX
	 * @memberof LogicMapContainer
	 */
	render() {
		let { groupedWebhooks, search } = this.state;

		let noSearchFound = null;
		if(groupedWebhooks['Stripe'].length === 0 && search.length) {
			noSearchFound = <li className="no-search-found" style={{ color: 'white'}}><h4>No Results for '{search}' found.</h4></li>
		}

		return (
			<div id="data-map__content" className="map">
				<div className="cd-search-container">
					<input className="form-control select-group-by" placeholder="Search" type="text" value={search} onChange={this._onChangeSearch} />
				</div>
				<div className="section-header" key="expand-collapse-tools">
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex">
							<div title="Expand All" onClick={this._onToggleAllOpen}>
								<i className="fa fa-plus"></i>
							</div>
							<div>|</div>
							<div title="Collapse All" onClick={this._onToggleAllClosed}>
								<i className="fa fa-minus"></i>
							</div>
						</div>
						<div className="d-flex align-items-center">
							<h5 className="bold">Webhooks</h5>
							<div title="Webhooks Help" className="info-icon ml-2" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_LOGIC_WEBHOOKS); }}>
								<i className="fa fa-info-circle mr-1"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="list-content-wrapper" key="pages">
					<ol>
						{map(groupedWebhooks, this._renderGroupBy)}
						{ noSearchFound } 
					</ol>
				</div>
			</div>
		);
	}

	/**
	 * private Render on group of Webhooks
	 * @param {Array} webhooks All of the webhooks triggers
	 * @param {string} groupBy The value to show these pages as grouped by - in this case, the Integration Name
	 */
	_renderGroupBy(triggers, groupBy) {
		let { groupToggleStatus } = this.state;

		let triggerList = [];

		// Generate the LI list for all triggers..
		triggers.forEach((trigger, index) => {
			triggerList.push(
				<li key={index} className="webhook-trigger-item">
					<div className="d-flex justify-content-between webhook-trigger-label py-2">
						<h5>{trigger.label}</h5>
						<div className="cursor-pointer">
							<h5 onClick={this._onLoadTrigger.bind(this, trigger.schemaName)} title="Update Webhook Trigger">
								<ReactSVG 
									beforeInjection={svg => {
										svg.setAttribute('viewBox', '0 0 34 34');
										svg.setAttribute('style', 'width: 16px; height: 16px');
									}}
									src={ContextStore.getUrlMedia() + "/icon-logic.svg"} />
							</h5>
						</div>
					</div>
				</li>
			);
		}); // End loop over all the pages.

		if (triggerList.length) {
			// Add a Group of triggers for this Webhook.
			let show = groupToggleStatus[groupBy];

			// If this is hte group that has the current page in it, and there is NO 
			// indicator for if the user wants this group open or not... open it.
			if (show === undefined) {
				show = true;
			}
			return (
				<li key={groupBy} className="role-group-item">
					<div className="role-group-name d-flex" onClick={this._onToggleShow.bind(this, groupBy, !show)}>
						<div>
							{show 
								? <i title="Collapse" className="fa fa-minus"></i>
								: <i title="Expand" className="fa fa-plus"></i>
							}
						</div>
						<div className="d-flex justify-content-between w-100">
							<h4 className="bold ml-1">{groupBy}</h4>
							<h4 className="bold">({triggerList.length})</h4>
						</div>
					</div>
					<ol id={groupBy} className={'collapse ' + (show ? 'show ' : '') + 'groupby-list pr-0'}>
						{triggerList}
					</ol>
				</li>);
		} else {
			return null;
		}
	} // end _renderGroupBy

	/**
	 * Renders the webhooks
	 */
	_renderWebhooks() {
		return (
			<li className="role-group-item">
				<div className="back-strike role-group-name"
					data-target="#webhooksEditList"
					data-toggle="collapse">
					<div className={'role-group-name-arrow'}></div>
					<a>Integrations - WebHooks</a>
				</div>
				<ol id="webhooksEditList"
					className="collapse show">
					{/* Render Webhook List. Currently only one */}
					<li key='stripe-integrations' className="table-name-item">
						<div className="row">
							<div className="ml-auto col-9">
								<div className="page-load-link"
									data-toggle="modal"
									data-target="#page-dialog"
								>
									<span className="notlink">Stripe Payments</span>
								</div>
							</div>
							<div className="col-2 text-center">
								<span onClick={() => {
									AdminSettingsActions.onRightPanelChange(true);
									AdminSettingsActions.onShowComponentDetails(
										'STRIPE', undefined, undefined, 'webhook',
										undefined, undefined,
										undefined, undefined, // Grid Key?!  and Field Type (which we derive)
										'webhooks');
								}} data-toggle="modal" data-target="#page-dialog" className="fa fa-pencil"><span className='sr-only'>Edit</span></span>
							</div>
						</div>
					</li>
				</ol>
			</li>
		);
	}

	/**
	 * Handles typing into the search box.
	 * @param {object} event Change event
	 */
	_onChangeSearch(event) {
		let search = event.target.value;
		MapActions.search(search, AdminSettingsStore.getActiveDashboardTab());
	}

	/**
	 * Handles loading a trigger in the Details Pane
	 * @param {string} triggerName 
	 */
	_onLoadTrigger(triggerName) {
		AdminSettingsActions.onRightPanelChange(true);
		//Toggle to select and deselect the Setting
		AdminSettingsActions.onSettingChange(triggerName);
		AdminSettingsActions.onShowComponentDetails(
			'STRIPE', undefined, undefined, 'webhook',
			undefined, undefined,
			undefined, undefined, // Grid Key?!  and Field Type (which we derive)
			'webhooks');

		// Hide the settings, always
		AdminSettingsActions.onSettingsListHideChange(true);
	}

	/**
	 * Toggle one group.
	 */
	_onToggleShow(groupLabel, show) {
		MapActions.groupToggle(AdminSettingsStore.getActiveDashboard(), 'webhooks', groupLabel, show);
	}

	/**
	 * Open all of the groups
	 */
	_onToggleAllOpen() {
		let { groupedWebhooks, groupToggleStatus } = this.state;
		Object.keys(groupedWebhooks).forEach(group => {
			groupToggleStatus[group] = true;
		})
		MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'webhooks', groupToggleStatus);
	}

	/**
	 * Close all of the groups
	 */
	_onToggleAllClosed() {
		let { groupedWebhooks, groupToggleStatus } = this.state;
		Object.keys(groupedWebhooks).forEach(key => {
			groupToggleStatus[key] = false;
		})
		MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'webhooks', groupToggleStatus);
	}
}

const container = Container.create(WebhooksLogicDashboardTabContainer, { withProps: true });
export default container;