import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import {TableConstants} from '../constants/table-constants';
import FieldStore from './field-store';

/**
 * Core store that contains table records
 *
 * @class TableStore
 * @extends {ReduceStore}
 */
class TableStore extends ReduceStore {
	/**
	 * getInitialState - initial state for TablesStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.Map({
			allPulledFromDatabase: false,
			records: Immutable.Map()
		});
	}

	/**
	 * Called every time an action is dispatched (for any store)
	 *
	 * @param {Object} state - current state of this store
	 * @param {Object} action - action that's coming in
	 * @returns {Object} new state after the action has been processed.
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case TableConstants.TABLE_DELETE_FROM_DATABASE: {
				// Deal with "cleaning" any dirty flags in the future.
				return state;
			}
			case TableConstants.TABLE_DELETE_FROM_STORE: {
				// Delete the record in the state['records']['recordId'] spot
				return state.deleteIn(['records', action.get('recordId')]);
			}
			case TableConstants.TABLE_PUSH_TO_DATABASE: {
				let tableObject = action.get('tableObject');
				let newState = state;
				// Clean up any dirty values which have now been saved
				if(tableObject) {
					let recordId = tableObject.get('recordId');
					newState = state.withMutations(state => {
						let oldRecord = state.getIn(['records', recordId]) || Immutable.Map();
						oldRecord = oldRecord.withMutations(oldRecord => {
							tableObject.forEach((newSettingValue, settingKey) => {
								let oldValue = oldRecord.get(settingKey) || Immutable.Map();
								let newValue = oldValue.withMutations(oldValue => {
									oldValue.set('value', newSettingValue);
									oldValue.set('isDirty', false);
									oldValue.set('originalValue', null);
								});
								oldRecord.set(settingKey, newValue);
							});
						});
						// I believe that we want to merge deep in here as well
						state.mergeDeepIn(['records', recordId], oldRecord);
					});
				}
				return newState;
			}
			case TableConstants.TABLE_PUSH_TO_STORE: {
				let recordId = action.get('recordId');
				let recordProperties = action.get('recordProperties');
				let forceClean = action.get('forceClean');

				// Merge the recordProperties into the state['records']['recordId'] object... and return it.
				return recordId && recordProperties ? state.withMutations(state => {
					let oldRecord = state.getIn(['records', recordId]) || Immutable.Map();
					let newRecord = oldRecord.withMutations(newRecord => {
						recordProperties.forEach((newSettingValue, settingKey) => {
							if(oldRecord.has(settingKey)) {
								let oldValue = oldRecord.has(settingKey) ? oldRecord.get(settingKey) : Immutable.Map();
								let newValue = oldValue.withMutations(oldValue => {
									if(forceClean) {
										oldValue.set('value', newSettingValue);
										oldValue.set('isDirty', false);
										oldValue.set('originalValue', null);
									} else {
										// If we're not already dirty and the original value hasn't been set, then set it
										if(!oldValue.get('isDirty') && oldValue.get('originalValue') === null) {
											oldValue.set('originalValue', oldValue.get('value'));
										}
	
										if(oldValue.get('originalValue') === newSettingValue) {
											// If the new value is the original value, then revert it and clear the isDirty flag
											oldValue.set('value', newSettingValue);
											oldValue.set('isDirty', false);
											oldValue.set('originalValue', null);
										} else {
											// Otherwise, just set the new value and mark it as dirty
											oldValue.set('value', newSettingValue);
											oldValue.set('isDirty', true);
										}
									}
								});
								newRecord.set(settingKey, newValue);
							} else {
								newRecord.set(settingKey, Immutable.fromJS({
									value: newSettingValue,
									isDirty: forceClean ? false : true,
									inConflict: false,
									conflictValue: null,
									originalValue: forceClean ? null : ''
								}));
							}
						});
					});
					// Merge the recordProperties into the state['records']['recordId'] object... and return it.
					// We want mergeDeepIn and not set in order to preserve old values
					state.mergeDeepIn(['records', recordId], newRecord);
					return state;
				}) : state;
			}
			case TableConstants.TABLE_RECEIVE_BROADCAST: {
				// This is similar to pulling from the database, but needs to consider conflict work
				let records = action.get('records');
				return state.withMutations(newState => {
					records.forEach((record) => {
						let recordId = record.get('recordId');
						let oldRecord = newState.getIn(['records', recordId]) || Immutable.Map();
						let newRecord = oldRecord.withMutations(newRecord => {
							record.get('properties').forEach((value, key) => {
								// Update the value
								
								let isDirty = oldRecord.hasIn([key, 'isDirty']) ? oldRecord.getIn([key, 'isDirty']) : false;
								if(!isDirty) {
									// If it's not dirty, then just update the value
									newRecord.setIn([key, 'value'], value);
									newRecord.setIn([key, 'isDirty'], false);
									newRecord.setIn([key, 'originalValue'], null);
								} else {
									let dirtyValue = oldRecord.getIn([key, 'value']);
									if(dirtyValue !== value) {
										// @TODO: How do we want to handle dirty values from an end user perspective?
										// Warn the user for now, I guess?
										let quickLookup = {
											"auditLogTitleFSN": 'Label each record with',
											"roles": 'Roles',
											"singularName": 'Singular Name',
											"auditLog": 'Audit Logging',
											"icon": 'Icon',
											"pluralName": 'Plural Name',
											"securityPermissions": 'Security Permissions'
										};
										let label = quickLookup[key] || key;
										let InterfaceActions = require('../actions/interface-actions').default;
										InterfaceActions.stickyNotification({
											level: 'warning',
											title: 'Changed: ' + oldRecord.getIn(['singularName', 'value']) + ' Table / ' + label + ' Setting',
											message: 'Your modified setting has been changed by another citizen developer. Save your work to overwrite their changes, or reload to accept their update.',
											id: 'table-' + recordId + '-' + key + '-conflict'
										});
										// Update the original value, since that reflects the DB now
										newRecord.setIn([key, 'originalValue'], value);
									} else {
										// The value in the DB is now actually the same as the "dirty" value, so we can just clean the value in the store
										newRecord.setIn([key, 'isDirty'], false);
										newRecord.setIn([key, 'originalValue'], null);
									}
								}
							});
						});
						newState.setIn(['records', recordId], newRecord);
					})
				});
			}
			case TableConstants.TABLE_PULL_FROM_DATABASE: {
				let overwriteStore = action.get('overwriteStore');
				return state.withMutations(state => {
					let recordMap = Immutable.Map().withMutations(recordMap => {
						action.get('tableArray').forEach(metaRecord => {
							let recordId = metaRecord.get('recordId');
							let newRecord = Immutable.Map().withMutations(newRecord => {
								metaRecord.forEach((settingVal, settingKey) => {
									// Treat as forceClean = true
									newRecord.set(settingKey, Immutable.fromJS({
										value: settingVal,
										isDirty: false,
										originalValue: null
									}));
								});
							});
							recordMap.set(recordId, newRecord);
						});
					});
					if(overwriteStore) {
						state.mergeIn(['records'], recordMap);
					} else {
						// Start with the new records, then merge in what we have in the state already to avoid overwriting
						state.set('records', recordMap.mergeDeep(state.get('records')));
					}
				});
			}
			case TableConstants.TABLE_PULL_FROM_DATABASE_ALL: {
				let overwriteStore = action.get('overwriteStore');
				return state.withMutations(state => {
					let recordMap = Immutable.Map().withMutations(recordMap => {
						action.get('tableArray').forEach(metaRecord => {
							let recordId = metaRecord.get('recordId');
							let newRecord = Immutable.Map().withMutations(newRecord => {
								metaRecord.forEach((settingVal, settingKey) => {
									// Treat as forceClean = true
									newRecord.set(settingKey, Immutable.fromJS({
										value: settingVal,
										isDirty: false,
										originalValue: null
									}));
								});
							});
							recordMap.set(recordId, newRecord);
						});
					});
					if(overwriteStore) {
						state.set('records', recordMap);
					} else {
						// Start with the new records, then merge in what we have in the state already to avoid overwriting
						state.set('records', recordMap.mergeDeep(state.get('records')));
					}
					state.set('allPulledFromDatabase', true);
				});
			}
			case TableConstants.TABLE_PULL_ERROR: {
				console.error('Table Store Error: ' + action.get('error'));
				return state;
			}
			default: {
				return state;
			}
		}
	}

	/**
	 * Gets the entire store as an object
	 *
	 * @returns {Object} current store as an object
	 */
	getAll() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			let records = this.getState().get('records');
			let recordsObj = {};
			records.forEach((record, recordId) => {
				let recordObj = {};
				record.forEach((settingVal, settingKey) => {
					let val = settingVal.get('value');
					recordObj[settingKey] = val;
				});
				recordsObj[recordId] = recordObj;
			});
			return recordsObj;
		} else {
			return undefined;
		}
	}

	/**
	 * Gets the entire store as an array
	 *
	 * @returns {Array} current store as an array
	 */
	getAllArray() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			let records = this.getState().get('records');
			let recordsArr = [];

			records.forEach((record) => {
				let recordObj = {};
				record.forEach((settingVal, settingKey) => {
					let val = settingVal.get('value');
					recordObj[settingKey] = val;
				});
				recordsArr.push(recordObj);
			});
			return recordsArr;
		} else {
			return undefined;
		}
	}

	/**
	 * Gets an individual record from the store
	 * @param {string} recordId UUID of the record to get
	 * @returns {Object} current store as an object for that record
	 */
	get(recordId, justDirty) {
		let state = this.getState();
		if (state.hasIn(['records', recordId])) {
			let rec = state.getIn(['records', recordId]);
			let toReturn = {
				recordId,
				tableSchemaName: rec.getIn(['tableSchemaName', 'value'])
			};
			rec.forEach((settingVal, settingKey) => {
				let val = settingVal.get('value');
				let isDirty = settingVal.get('isDirty');
				if(justDirty ? isDirty : true) {
					toReturn[settingKey] = val;
				}
			});
			return toReturn;
		} else {
			return undefined;
		}
	}

	/**
	 * Gets the full information for a logic function record
	 * @param {string} recordId UUID of the record to get
	 * @param {string} kind Kind of metadata to retrieve
	 */
	getFull(recordId) {
		let state = this.getState();
		return state.hasIn(['records', recordId]) ?
			state.getIn(['records', recordId]) :
			undefined;
	}

	/**
	 * Get an individual table from the store by tableSchemaName
	 * 
	 * @param {string} tableSchemaName Which table should we search for?
	 * @param {boolean} justDirty Ignore any dirty setting for the table
	 * @param {boolean} ignoreCase Ignore the case of the tableSchemaName
	 * @returns {Object}  current store as an object for that record
	 */
	getByTableSchemaName(tableSchemaName, justDirty, ignoreCase) {
		if (this.getState().get('records')) {
			let tableToReturn = this.getState().get('records').find(function (record) {
				let recordName = record.hasIn(['tableSchemaName', 'value']) ? record.getIn(['tableSchemaName', 'value']) : '';
				if(ignoreCase) {
					return recordName.toLowerCase() === tableSchemaName.toLowerCase();
				} else {
					return recordName === tableSchemaName;
				}
			});
			if(!tableToReturn) {
				// This appears to be the old behavior that some locations are expecting
				return {};
			}
			let recordId = tableToReturn.getIn(['recordId', 'value']);
			let toReturn = {
				recordId,
				tableSchemaName: tableToReturn.getIn(['tableSchemaName', 'value'])
			};
			tableToReturn.forEach((settingVal, settingKey) => {
				let val = settingVal.get('value');
				let isDirty = settingVal.get('isDirty');
				if(justDirty ? isDirty : true) {
					toReturn[settingKey] = val;
				}
			});
			return toReturn;
		} else {
			return undefined;
		}
	}

	/**
	 * Get the rounded byte length of all the fields on the table.
	 * @param {string} tableSchemaName 
	 * @return {number} byte length
	 */
	 getRecordSchemaLength(tableSchemaName) {
		let returnLen = 0;
		let fields = FieldStore.getByTableSchemaName(tableSchemaName);
		fields.forEach(field => {
			// Check the FT to see what length to use.
			returnLen += FieldStore.getSchemaLength(field);
		})
		returnLen += (36 * 3); // For the record ID
		return returnLen;
	}

	/**
	 * Get whether all have been pulled from the database or not yet.
	 * 
	 * @returns {boolean}
	 */
	allPulledFromDatabase() {
		return this.getState().get('allPulledFromDatabase');
	}

	/**
	 * Returns the count of tables in the intallation
	 * @returns number
	 */
	getCount() {
		return this.getAllArray().length;
	}
}

const instance = new TableStore(AppDispatcher);
export default instance;