/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';

import { 
	AdminSettingsActions, 
	RelationshipActions,
	InterfaceActions,
} from '../../../actions';

import { 
	AdminSettingsStore, 
	FieldStore,
	RelationshipStore
} from '../../../stores';

import { 
	RelationUtils,
	ObjectUtils,
	UIUtils 
} from '../../../utils';

class RelationshipSettingsChooser extends Component {
	/**
	 * Creates instance of FieldSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._getSettingsList = this._getSettingsList.bind(this);
		this._onDeleteHandler = this._onDeleteHandler.bind(this)
		this._onResetHandler = this._onResetHandler.bind(this)
		this._onSaveHandler = this._onSaveHandler.bind(this)
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, RelationshipStore];
	}

	/**
	 * Calculate the current state of this component
	 * @static
	 * @param {Object} prevState 
	 * @param {Object} prevState 
	 * @returns {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = 'relationship';
		let relationObj = RelationshipStore.get(recordId) || {};
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let componentSettings = [];
		let isNew = relationObj.new;

		let settings =
			[
				{ recordId: 'f3e86ca3-48ed-474c-a21f-dcb8396ff0bc',
					sortOrder: 1, required: true  }, // Table Setup
				{ recordId: '0908873b-dada-4b79-8d0a-62ec47587a8a',
					sortOrder: 2, required: true  }, // Technical Name
				{ recordId: '346111e9-a309-454e-a3d6-d5a5976377c7',
					sortOrder: 3, required: false  }, // Description
				{ recordId: 'd67591a0-fd44-4cc8-a5f4-14513898bf05',
					sortOrder: 4, required: false  }, // Generate Interfaces
				{ recordId: 'b76d7d83-8ecd-4eba-8e00-7f44a039cb77',
					sortOrder: 20, required: false  }, // Audit Logging
			];

		let hasMissingRequiredSettings = false;
			
		settings.forEach(setting => {
			let settingId = setting.recordId;
			let settingObj = FieldStore.get(settingId) || {};
			let settingSchemaName = settingObj.fieldSchemaName;

			// Special handling for the Table Setup field
			let valueObj = {};
			if(settingId === 'f3e86ca3-48ed-474c-a21f-dcb8396ff0bc') {
				valueObj = {
					lCardinality: relationObj.lCardinality || '',
					lTableSchemaName: relationObj.lTableSchemaName || '',
					ltorLabel: relationObj.ltorLabel || '',
					rCardinality: relationObj.rCardinality || '',
					rTableSchemaName: relationObj.rTableSchemaName || '',
					rtolLabel: relationObj.rtolLabel || '',
				};

				// check all the parts of the Table Setup object...
				if(valueObj.lCardinality.length === 0 || 
					valueObj.lTableSchemaName.length === 0 || 
					valueObj.ltorLabel.length === 0 || 
					valueObj.rCardinality.length === 0 || 
					valueObj.rTableSchemaName.length === 0 || 
					valueObj.rtolLabel.length === 0) {
						hasMissingRequiredSettings = true;
						valueObj.missingRequired = true;
					}
			} else {
				// Empty/No Value values.
				if (relationObj[settingSchemaName]) {
					valueObj.value = relationObj[settingSchemaName];
				}
	
				// Check to see if this setting is required, and has no value
				if(setting.required && (!valueObj.value || valueObj.length === 0)) {
					hasMissingRequiredSettings = true;
				}
			}

			componentSettings.push({
				// Basics
				fieldId: settingId,
				fieldObj: settingObj,

				// Label, Value and Sorting..
				label: settingObj.fieldLabel,
				valueObj: valueObj,
				sortOrder: setting.sortOrder,

				// Used for styling
				settingSelected: (selectedSetting === settingSchemaName),
				required: setting.required
			});
		});

		// Sort the results by setting name
		componentSettings.sort(function (a, b) {
			if (a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			return 1;
		});

		return {
			componentSettings: componentSettings,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			settingsHidden: AdminSettingsStore.getSettingsListHidden(),
			hasMissingRequiredSettings: hasMissingRequiredSettings,
			isNew: isNew,
			relationIcon: RelationUtils.getRelationIcon(recordId)
		}
	}

	/**
	 * Render the component
	 * @returns JSX
	 */
	render() {
		let { componentSettings, relationIcon, hasMissingRequiredSettings, isNew } = this.state;

		if (!componentSettings.length) {
			return (
				<div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
				</div>
			);
		}

		let settingsList = this._getSettingsList(componentSettings); // component settings

		/* The ids for these buttons are set in a specific way, so that Ctrl-S and Ctrl-R (UIUtils.onkeyDown method) can find these buttons */
		let buttons = (isNew 
			? [
				<button id={'relationshipSave'} key="submit" className="btn btn-primary btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={this._onSaveHandler}>Save</button>,
				<button id={'relationshipReset'} key="reset" disabled="disabled" className="btn cd-btn-disabled btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Reset" onClick={this._onResetHandler}>Reset</button>,
				<button key="delete" className="btn btn-danger btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Delete" onClick={this._onDeleteHandler}>Delete</button>
			]
			:[
				<button id={'relationshipSave'} key="submit" className="btn btn-primary btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={this._onSaveHandler}>Save</button>,
				<button id={'relationshipReset'} key="reset" className="btn btn-warning btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Reset" onClick={this._onResetHandler}>Reset</button>,
				<button key="delete" className="btn btn-danger btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Delete" onClick={this._onDeleteHandler}>Delete</button>
			]); 

		let requiredMarker = null;
		if(hasMissingRequiredSettings) {
			requiredMarker = <div className="required-marker mx-4 d-flex"><i className="fa fa-circle d-flex align-items-center" aria-hidden="true"></i> <h4 className="ml-2">Required</h4></div>;
		}

		return [
			<div key="settings" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">
					{settingsList && settingsList.length
						?   <li className="settings-list-header">
								<div className="d-flex align-items-center">
									<img height="22" width="22" className="mr-2" src={relationIcon} alt="" />
									<h3>Relationship Settings</h3>
								</div>
							</li>
						: null
					}
					{settingsList && settingsList.length
						? settingsList
						: <div style={{ marginTop: 0 }} className='no-settings-found'>No Settings Found</div>
					}
					{ requiredMarker }
				</ul>
			</div>,
			<div key="buttons" className="btn-wrapper appearance-btn-wrapper">
				{buttons}
			</div>
		];
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	_getSettingsList(settingsArr) {
		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label';
			if (setting.settingSelected) {
				labelClassNames += ' selected';
			}

			let componentName = '';
			if(setting && setting.fieldObj && setting.fieldObj.viewVariant) {
				componentName = setting.fieldObj.viewVariant;
			} else {
				componentName = FieldStore.getDefaultVariantComponentName(
					setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			}

			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;

			let valueDisplay = '';
			let requiredMarker = null;

			if(setting.fieldId === 'f3e86ca3-48ed-474c-a21f-dcb8396ff0bc') {
				if(setting.valueObj.missingRequired) {
					requiredMarker = <div style={{ left: '-14px', top: '2px' }} className="required-marker position-absolute"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
				} else {
					componentProps.value = 1;

					valueDisplay = <h5 className={`settings-list-value ${componentName}`}>
						{(componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null)}
					</h5>;
				}
			}
			else {
				valueDisplay = <h5 className={`settings-list-value ${componentName}`}>
						{(componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null)}
					</h5>;
	
				if(setting.required && (!setting.valueObj.value || setting.valueObj.value.length === 0)) {
					requiredMarker = <div style={{ left: '-14px', top: '2px' }} className="required-marker position-absolute"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
				}
			}

			return (<li key={setting.fieldId} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this._onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames}>{requiredMarker}<h4>{setting.label}</h4></div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								{valueDisplay}
							</div>
							{setting.valueObj.source ?
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}>
									{setting.valueObj.source}
								</div>
								: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}

	/**
	 * _onDeleteHandler - Deletes the object from the store and the database.
	 *
	 * @param  {object} event
	 */
	_onDeleteHandler(event) {
		event.preventDefault();
		let { recordId, isNew } = this.state;
		let confirmRemove = confirm('Are you sure you want to delete this Relationship?');
		if (confirmRemove) {
			// Display notification to user
			let id = InterfaceActions.stickyNotification({ 'level': 'warning', 'message': 'Deleting Relationship...' });
			
			// // Push to database
			if(isNew) {
				InterfaceActions.clearStickyNotification(id);
				UIUtils.closeSettingsPanel();
				RelationshipActions.deleteFromStore(recordId);
			} else {
				RelationshipActions.deleteFromDatabasePromise(recordId).then(() => {
					InterfaceActions.clearStickyNotification(id);
					UIUtils.closeSettingsPanel();
					RelationshipActions.deleteFromStore(recordId);
				}).catch(error => {
					InterfaceActions.clearStickyNotification(id);
					InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to delete Relationship' });
					console.error('Unable to delete Relationship:', error);
				})
			}
		}
	}

	/**
	 * _onResetHandler - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	_onResetHandler(event) {
		event.preventDefault();
		let { recordId } = this.state;
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting Relationship...' });
		// Pull from database, therefore resetting it
		RelationshipActions.pullFromDatabase(recordId);
	}

	/**
	 * _onSaveHandler - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	_onSaveHandler(event) {
		let { recordId } = this.state;
		let relationshipObj = RelationshipStore.get(recordId);
		let valid = true;
		let targetSettingName = '';
		let targetSettingId = '';

		// Relationship Labels
		if( !relationshipObj.ltorLabel || relationshipObj.ltorLabel.length === 0 || 
			!relationshipObj.rtolLabel || relationshipObj.rtolLabel.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Both names are required for a Relationship.' });
			targetSettingName = 'relation-tables';
			targetSettingId = 'f3e86ca3-48ed-474c-a21f-dcb8396ff0bc';
		}
		// Relationship Cardinality
		if(!relationshipObj.lCardinality || relationshipObj.lCardinality.length === 0 || 
		   !relationshipObj.rCardinality || relationshipObj.rCardinality.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Both Cardinalities are required for a Relationship.' });
			targetSettingName = 'relation-tables';
			targetSettingId = 'f3e86ca3-48ed-474c-a21f-dcb8396ff0bc';
		}
		// Relationship Labels
		if(!relationshipObj.lTableSchemaName || relationshipObj.lTableSchemaName.length === 0 || 
		   !relationshipObj.rTableSchemaName || relationshipObj.rTableSchemaName.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Both Tables are required for a Relationship.' });
			targetSettingName = 'relation-tables';
			targetSettingId = 'f3e86ca3-48ed-474c-a21f-dcb8396ff0bc';
		}

		// Technical Name
		let fsnSettingName = 'relationSchemaName';
		let fsnSettingId = '0908873b-dada-4b79-8d0a-62ec47587a8a';
		let relationSchemaName = relationshipObj[fsnSettingName];
		if(!relationSchemaName || relationSchemaName.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Technical Name is required for a Relationship.' });
			if(!targetSettingName) {
				targetSettingName = fsnSettingName;
				targetSettingId = fsnSettingId;
			}
		} else {
			// Validate the technical name

			// starting with a-z or A-Z, and containing letters a-z, A-Z, numbers and _ characters
			// Thanks ChatGPT!!
			const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/;
			// A max of 60 characters
			if (relationSchemaName.length >= 60) {
				valid = false;
				InterfaceActions.notification({ 'level': 'error', 'message': 'Technical Name too long, must be a max of 60 characters.' });
				if(!targetSettingName) {
					targetSettingName = fsnSettingName;
					targetSettingId = fsnSettingId;
				}
			} else if(!regex.test(relationSchemaName)) {
				valid = false;
				InterfaceActions.notification({ 'level': 'error', 'message': 'Technical Name contains invalid characters.' });
				if(!targetSettingName) {
					targetSettingName = fsnSettingName;
					targetSettingId = fsnSettingId;
				}
			}

			// Make sure its unique
			let existingRelationships = RelationshipStore.getAllArray();
			// If we found a matching relationship, and its NOT us...
			existingRelationships.forEach(existingRelationship => {
				if(existingRelationship.recordId && // If the field has a record ID...
					existingRelationship.recordId !== recordId && // and it does NOT match our new record ID...
					existingRelationship.relationSchemaName === relationSchemaName) { // but its Schema Name DOES match ours...
						valid = false;
						InterfaceActions.notification({ 'level': 'error', 'message': 'Another Relationship with this Technical Name already exists: ' + existingRelationship.ltorLabel});
						if(!targetSettingName) {
							targetSettingName = fsnSettingName;
							targetSettingId = fsnSettingId;
						}
					}
				});
		}

		if(!valid) {
			AdminSettingsActions.onSettingChange(targetSettingName, targetSettingId);
		} else {
			let isNew = relationshipObj.new;
			let crudObj = ObjectUtils.getObjFromJSON(relationshipObj.crudInterfaces);

			delete relationshipObj.new;
			delete relationshipObj.crudInterfaces;

			// Trim all of the values...
			Object.keys(relationshipObj).forEach(settingName => {
				if(relationshipObj[settingName]) {
					relationshipObj[settingName] = relationshipObj[settingName].trim();
				}
			})

			// Set the message.
			let message = 'Creating Relationship...';
			if(!isNew) {
				message = 'Updating Relationship...';
			}
			let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': message });

			// Push to database
			RelationshipActions.pushToDatabasePromise(relationshipObj).then(() => {

				let fieldPromises = [];

				// @TODO Implement
				if(crudObj['ldynamicselect']) {
					// if(isNew) {
					fieldPromises.push(RelationUtils.createDynamicSelectionField(recordId, relationshipObj.lTableSchemaName, 
						'l', true, true));
				}
				if(crudObj['llist']) {
					// if(isNew) {
					console.log('Create L List');
					fieldPromises.push(RelationUtils.createListField(recordId, relationshipObj.lTableSchemaName, 
						'l', true, true));
				}
				if(crudObj['rdynamicselect']) {
					// if(isNew) {
					fieldPromises.push(RelationUtils.createDynamicSelectionField(recordId, relationshipObj.rTableSchemaName, 
						'r', true, true));
				}
				if(crudObj['rlist']) {
					// if(isNew) {
					console.log('Create R List');
					fieldPromises.push(RelationUtils.createListField(recordId, relationshipObj.rTableSchemaName, 
						'r', true, true));
				}

				Promise.all(fieldPromises).then(() => {
					// Push the fact we're no longer new to the store...
					RelationshipActions.pullFromDatabase(recordId, true)
	
					// Clear the Sticky about saving.
					InterfaceActions.clearStickyNotification(id);
					UIUtils.openSettingsPanel('relationship', recordId, 'relationship');
	
					// Select FieldLabel
					AdminSettingsActions.onSettingChange('relation-tables', 'f3e86ca3-48ed-474c-a21f-dcb8396ff0bc');
	
					// Unhide the settings, always
					AdminSettingsActions.onSettingsListHideChange(false);
				})
			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Relationship.' });
				console.error('Unable to save Relationship:', error, relationshipObj);
			})
		}
	}


	/**
	 * Click on a Setting and update the admin settings store.
	 * 
	 * @param {string} settingFieldId
	 * @param {Object} event 
	 */
	_onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Expand the Settings Panel
		AdminSettingsActions.onSettingsListHideChange(false);
		if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
			AdminSettingsActions.onSettingChange('', '');
		} else {
			AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
		}
	}
}
const container = Container.create(RelationshipSettingsChooser);
export default container;
