import React, {Component} from 'react';
import PropTypes from 'prop-types';

import DirectionSelector from './direction-selector.react';
import TableIcon from './table-icon.react';
import TableSelector from './table-selector.react';

import { TableStore } from '../../../../stores';
import { MapUtils } from '../../../../utils';

/**
 * Component that wraps direction and table selector for relationship
 */
export default class RelationSelector extends Component {
  render() {
    let {direction, onChange, required, relationship} = this.props,
        lTable = TableStore.getByTableSchemaName(relationship.lTableSchemaName),
        rTable = TableStore.getByTableSchemaName(relationship.rTableSchemaName),
        options = TableStore.getAllArray(),
        left, cardinality, right;

    if ('ltor' === direction) {
      left = <TableIcon label={MapUtils.getTableRelationLabel(lTable, '1', 'l')} iconClass={lTable.icon}  className="d-flex flex-sm-column align-items-center" />;
      cardinality = 'lCardinality';
      right = <TableSelector allowSecurityGroups={true} required={required} fieldSchemaName="rTableSchemaName" options={options} value={rTable} label={MapUtils.getTableRelationLabel(rTable, relationship.lCardinality, 'r')} onChange={onChange} />;
    } else {
      left = <TableSelector allowSecurityGroups={true} required={required} fieldSchemaName="rTableSchemaName" options={options} value={rTable} label={MapUtils.getTableRelationLabel(rTable, '1', 'l')} onChange={onChange} />;
      cardinality = 'rCardinality';
      right = <TableIcon label={MapUtils.getTableRelationLabel(lTable, relationship.rCardinality, 'r')} iconClass={lTable.icon} className="d-flex flex-sm-column align-items-center" />;
    }

    return (
      <div className="relationship form-group d-flex flex-column">
		<div style={{ display: 'grid', gridTemplateColumns: '3fr 4fr 3fr', minHeight: '87px' }}>
			{left}
			<div style={{ margin: 'auto 0' }}><DirectionSelector required={required} fieldSchemaName={cardinality} value={relationship[cardinality]} onChange={onChange} /></div>
			{right}
		</div>
      </div>
    );
  }
}

if ('development' === process.env.NODE_ENV) {
  RelationSelector.propTypes = {
    direction: PropTypes.oneOf(['ltor', 'rtol']),
	onChange: PropTypes.func,
	required: PropTypes.bool,
    relationship: PropTypes.object.isRequired
  }
}
