import keymirror from 'keymirror';

export const MapConstants = keymirror({
  MAP_ADD_FILTER_COLUMN: null,
  MAP_FILTER_CLEAR: null,
  MAP_GROUP_BY: null,
  MAP_GROUP_TOGGLE: null,
  MAP_GROUP_TOGGLE_BULK: null,
  MAP_SEARCH: null,
  MAP_SEARCH_RESET: null,
  MAP_SET: null,
  MAP_SORT_COLUMN: null,
});

export const MapElementTypes = {
  FIELD: 'field',
  PAGE: 'page',
  RECORD: 'record',
  RELATIONSHIP: 'relationship',
  TABLE: 'table'
};

export const ComplexFields = {
  SETTINGS: 'settings',
  FIELD_TYPE: 'fieldType'
};
