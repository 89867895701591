/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from 'flux/utils';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import FieldStore from '../../stores/field-store';
import FieldSettingsStore from '../../stores/field-settings-store';
import FieldTypeStore from '../../stores/field-type-store';
import PageStore from '../../stores/page-store';
import RenderStore from '../../stores/render-store';
import TableStore from '../../stores/table-store';

// Utils
import UIUtils from '../../utils/ui-utils';


/**
 * Container to QueryPin
 *
 * @class QueryPin
 * @extends {Component}
 */
class QueryPin extends Component {
	constructor(props) {
		super(props);
		this.link = null;
		this.onPinClickHandler = this.onPinClickHandler.bind(this);
		this.onPinDoubleClickHandler = this.onPinDoubleClickHandler.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf QueryPin
	 */
	static getStores() {
		return [AdminSettingsStore];
	}

	/**
	 * Determine if this pin is Disabled.
	 * @param {string} renderId Render ID for the component
	 * @param {string} fieldType Field Type of the Field.
	 * @return boolean
	 */
	static calculateIsDisabled(renderId, fieldType) {
		let renderObj = RenderStore.get(renderId);

		// If we dont have a renderObj (yet), then we can't calculate.
		if(!renderObj) { 
			return undefined;
		}

		// if(renderObj.componentType === 'field') {
		// 	if(FieldTypeStore.getQuerySettings(fieldType).length) { 
		// 		return false;
		// 	}
		// 	let parentRenderObj = RenderStore.get(renderObj.renderParentId);
		// 	if(parentRenderObj) {
		// 		// Check for page parents' query settings.
		// 		if(parentRenderObj.componentType === 'field') {
		// 			let parentFieldObj = FieldStore.get(parentRenderObj.componentId);
		// 			if(parentFieldObj) {
		// 				if(FieldTypeStore.getQuerySettings(fieldType, parentFieldObj.fieldType).length) { 
		// 					return false;
		// 				}
		// 			} else {
		// 				return undefined;
		// 			}
		// 		}
		// 	} else {
		// 		return undefined;
		// 	}

		// }
		// Pages don't have query settings... so itll always be disabled.
		// return true;
		return false;
	}

	/**
	 * Determine if this pin is Used.
	 * @param {string} renderId Render ID for the component
	 * @param {string} fieldType Field Type of the Field.
	 * @return boolean
	 */
	static calculateIsUnused(renderId, fieldType) {
		let renderObj = RenderStore.get(renderId);
		let isUnused = true;

		// If we dont have a renderObj (yet), then we can't calculate.
		if(!renderObj) { 
			return undefined;
		}

		let querySettings = undefined;

		let parentRenderObj = RenderStore.get(renderObj.renderParentId);
		if(parentRenderObj) {
			// Check for page parents' query settings.
			if(parentRenderObj.componentType === 'field') {
				let parentFieldObj = FieldStore.get(parentRenderObj.componentId);
				if(parentFieldObj) {
					querySettings = FieldTypeStore.getQuerySettings(fieldType, parentFieldObj.fieldType);
				} else {
					return undefined;
				}
			} else {
				querySettings = FieldTypeStore.getQuerySettings(fieldType);
			}
		} else {
			return undefined;
		}

		let fieldSettingsObj = renderObj.attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(renderObj.attachmentId, renderObj.componentId, parentRenderObj.componentId) : FieldSettingsStore.getSettings(renderObj.componentId, parentRenderObj.componentId);
		Object.keys(querySettings).forEach(keyName => {
			let querySetting = querySettings[keyName];
			if(fieldSettingsObj[querySetting.fieldSchemaName] && 
					fieldSettingsObj[querySetting.fieldSchemaName].length && 
					fieldSettingsObj[querySetting.fieldSchemaName] !== '{}' &&
					fieldSettingsObj[querySetting.fieldSchemaName] !== '""') {
				isUnused = false;
			}
		});

		return isUnused;
	}

	/**
	 * Returns the current state
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf QueryPin
	 */
	static calculateState(prevState, props) {
		let overlayActive = AdminSettingsStore.getIsOverlayActive('query');
		let isDisabled = undefined;
		let isUnused = undefined;

		if(overlayActive) {
			// If we already have an isDisabled in the state, then use it...
			isDisabled = ( prevState && typeof prevState.isDisabled === 'boolean' 
				? prevState.isDisabled 
				: this.calculateIsDisabled(props.renderId, props.fieldType));
			
			if (isDisabled === false) {
				isUnused = this.calculateIsUnused(props.renderId, props.fieldType);
			}
		} // End if the overlay is active

		return {
			'isDisabled': isDisabled,
			'isUnused': isUnused,
			'overlayActive': overlayActive
		}
	}

	componentDidMount() {
		this.clickTimeouts = [];
	}

	/**
	 * onPinClickHandler - Handles pin click. 
	 */
	onPinClickHandler(event) {
		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings preview
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		this.clickTimeouts.push(setTimeout(() => {
			UIUtils.openSettingsPreview('query', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			undefined, undefined, undefined, this.props.renderId, 
			this.props.attachmentId);
			// Time you have between clicks to determine if it was single or double.
		}, 250));
	}

	/**
	 * onPinDoubleClickHandler - Handles pin double click. 
	 */
	onPinDoubleClickHandler(event) {
		this.clickTimeouts.forEach(timeout => {
			clearTimeout(timeout);
		});

		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings panel on the right.
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		UIUtils.openSettingsPanel('query', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			undefined, undefined, undefined, this.props.renderId,
			this.props.attachmentId);

		// Make sure the settings are toggled to visible.
		AdminSettingsActions.onSettingsListHideChange(false);
	}

	render() {
		let { overlayActive, isUnused, isDisabled } = this.state;
		let pinDivClassAppend = (isDisabled 
			? 'disabled' 
			: (isUnused 
				? 'unused' 
				: ''));

		let icon = 'fa-database';
		let tableObj = null;

		if(this.props.tableSchemaName === 'field') {
			let fieldObj = FieldStore.get(this.props.recordId);
			tableObj = fieldObj ? TableStore.getByTableSchemaName(fieldObj.tableSchemaName) : undefined;
		} else {
			// Assume we're a page...
			let pageObj = PageStore.get(this.props.recordId);
			tableObj = pageObj ? TableStore.getByTableSchemaName(pageObj.tableSchemaName) : undefined;
		}

		if(tableObj && tableObj.icon && tableObj.icon.length) {
			icon = 'fa-' + tableObj.icon;
		}

		if( overlayActive ) {
			return <div 
						key={this.props.attachmentId || this.props.recordId}
						className={"pin " + pinDivClassAppend}
						ref={el => this.link = el}
						data-trigger="manual"
						data-toggle="popover"
						data-fallback-placement="flip"
						data-html="true"
						data-content=" "
						onClick={(event) => { if(!isDisabled) { this.onPinClickHandler(event) }}}
						onDoubleClick={(event) => { this.onPinDoubleClickHandler(event); }}>
							<svg className="records pin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
								<path d="M12 2C8.1 2 5 5.1 5 9c0 5.2 7 13 7 13s7-7.8 7-13C19 5.1 15.9 2 12 2z" fill="#BBB"/>
							</svg>
							<span className={"records fa " + icon} alt="Records"/>
					</div>
		} else {
			return null;
		}
	}
}

if ('development' === process.env.NODE_ENV) {
  QueryPin.propTypes = {
    fieldType: PropTypes.string.isRequired,
    recordId: PropTypes.string.isRequired,
    renderId: PropTypes.string.isRequired,
    tableSchemaName: PropTypes.string.isRequired,
    parentRecordId: PropTypes.string,
    parentTableSchemaName: PropTypes.string,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(QueryPin, {withProps: true});
export default container;