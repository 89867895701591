import { 
	AdminSettingsActions, 
	FieldActions,
	RenderActions,
} from '../actions';
import { 
	AdminSettingsStore,
	FieldModesStore, 
	FieldStore,
	FieldSettingsStore,
	FieldTypeStore,
	PageStore,
	TableStore,
	RelationshipStore
} from '../stores';

import BlockUtils from './block-utils';
import PageUtils from './page-utils';
import ObjectUtils from './object-utils';
import ContextStore from '../stores/context-store';
import RenderStore from '../stores/render-store';
import uuid from 'uuid';
import UIUtils from './ui-utils';
import socketFetcher from './socket-fetcher';

const fieldTypeIcons = {
	'50588e62-0132-4307-af80-9d2c3c41bf2c':'address.svg',
	'21ed0311-bfca-4b93-8d4b-1214c4b71320':'authorize-net-payment.svg',
	'0a15630b-700a-4db4-ae88-90a36732c1c3':'calendar.svg',
	'e2da2fbc-ae06-4bda-bf10-fac56c6ae30d':'captcha.svg',
	'071f142c-6146-421a-9183-d989869aaee8':'checkbox.svg',
	'6e5b3bca-88bc-4db8-bd5d-531890660e49':'colorpicker.svg',
	'bb5bedc3-44d1-4e4c-9c40-561a675173b1':'content-drop-down.svg',
	'846b747e-25a0-40df-8115-af4a00a1cab5':'content-tabs.svg',
	'01a20424-6b60-455c-9172-e74f4c0190b4':'currency.svg',
	'2b3b1810-1cfb-4de6-a8a6-f41305efc102':'date-time.svg',
	'9e370d09-f21b-4950-a31b-d5ba18aa33ae':'email.svg',
	'94dc3a69-8f25-420f-9e36-d0ef19ce7c45':'expression.svg',
	'7ff16cd0-3c0c-4aae-950e-842c5e5226c7':'facebook-login.svg',
	'7ebd9251-675c-4129-95e3-6b8e31c135a2':'field-container.svg',
	'dbe04c76-8ed5-4521-9f32-b896b54455ba':'file-attachment.svg',
	'971bae4d-0781-43e4-a3c8-c46618cf57d6':'google-login.svg',
	'1036e34b-aa26-4fa9-973a-9c9f97c67c17':'image.svg',
	'4fb3fe77-76ea-4327-85dd-5da41d59c403':'link.svg',
	'9b782b83-4962-4bd6-993c-f72096e02610':'list.svg',
	'2e8c9ff8-b6a5-4f32-98ba-24fd8794c6a7':'long-text.svg',
	'3cbe12e7-4742-4c18-966f-e44e650af199':'map-google-maps.svg',
	'cd0ee38e-d63f-44d2-b02b-44376fcc7c2e':'navigation-tabs.svg',
	'867b0b3b-f076-4fbc-b979-f1c5e7cb495f':'number.svg',
	'bbc67368-3f04-47d1-bbb8-3d1c39fa9c7c':'password.svg',
	'a0f2cecd-aa8a-4520-8305-885c4dd5111c':'pay-with-paypal.svg',
	'f241b0da-9a7d-41ea-a5d3-0e3a72ca7537':'phone-number.svg',
	'94a222ed-81e2-4fe5-a03e-80c516573bce':'progress-bar.svg',
	'529579e1-d9cc-46f3-b796-1241d6c00f70':'report-bar-chart.svg',
	'b6377d3a-7a75-45d3-b235-ce9e86a9a11f':'report-column-chart.svg',
	'bb72cddb-29e3-4197-8446-fe75fab0bff2':'report-donut-chart.svg',
	'a3ff12aa-96ae-47f8-a031-a0edf21b8dc2':'report-gantt-chart.svg',
	'3f37b377-b0d2-4c72-a5f3-4f0db70b57a8':'report-gauge-chart-dial.svg',
	'71ba5154-f974-4e1d-a289-24df6d949f96':'report-gauge-chart-solid.svg',
	'01f19ad9-72b7-4bc9-8b6d-47f595072ad2':'report-line-chart.svg',
	'd5394285-9aef-42e0-b89f-341707dad8f7':'report-pie-chart.svg',
	'e24cc98a-07a4-4fd2-9733-5e1c4055f66a':'secure-key.svg',
	'528c3e72-3a0d-4dc9-8e81-8be6f3c29c5c':'selection-field-dynamic.svg',
	'0bc7afe8-a123-4a84-962e-f8b7b1aeb3c3':'selection-field-static.svg',
	'd965b6d9-8dd0-440c-a31c-f40bf72accea':'short-text.svg',
	'9aee559c-d5b5-4c3c-a0bc-2bf96664b310':'signature.svg',
	'723a56c6-37cf-40a3-8cee-ca800a52024c':'social-security.svg',
	'f39e24c5-d823-4e87-8769-201cc6b42802':'static-page-content.svg',
	'de1d6e75-5540-4642-badb-11af650d2696':'stripe-capture-payment-details.svg',
	'baf5235e-5750-408b-ba4f-a28b6f612a6d':'stripe-single-payment.svg',
	'f14d598d-a798-4ee5-bf5e-f8ffd522eacc':'url.svg',
	'page':'page.svg',
  }

export class FieldUtils {

	/**
	 * Add a Field
	 * @param {string} tableSchemaName Optional - Table Schema Name to set for new field.
	 * @param {string} fieldType  Optional - Field Type to set for new field.
	 */
	static addNewField(tableSchemaName, fieldType) {
		let recordId = uuid.v4();
		let newField = {
			recordId: recordId,
			fieldLabel: '',
			tableSchemaName: tableSchemaName || '',
			fieldType: fieldType || '',
			new: true,
		};

		newField = this.appendNewFieldDefaultSettings(newField);

		FieldActions.pushToStore(recordId, newField);

		// @todo - Why?
		// MapActions.selectField(recordId, true);

		// targetOverlay, recordId, tableSchemaName, 
		// parentRecordId, parentTableSchemaName, mainTab,
		// subSettingSchemaName, subSettingIndex, renderId, attachmentId
		UIUtils.openSettingsPanel('field-add', recordId, 'field');

		// Select the Field Label field.
		AdminSettingsActions.onSettingChange('fieldLabel', '27fdf34c-252d-4196-bbd8-33486e18db64');
	}

	/**
	 * Control the default settings appended to a new field in one centralized place.
	 * @param {Object} newField 
	 * @returns Object
	 */
	static appendNewFieldDefaultSettings(newField) {
		if(Object.keys(newField).length === 0) {
			newField = {};
		}
		newField.filterable = "1";
		newField.resizable = "1";
		newField.sortable = "1";
		return newField;
	}

	/**
	 * Determine if the user is permitted to delete this field.
	 * If it is the last field with a datatype other than NONE, then no, they are not allowed to delete it.
	 * @param {string} fieldId 
	 * @param {string} tableSchemaName 
	 * @returns boolean
	 */
	static allowFieldDeletion(fieldId, tableSchemaName) {

		// Get all of the fields for this table.
		let fieldArray = FieldStore.getByTableSchemaName(tableSchemaName);

		// Filter them.
		let remainingDataFields = fieldArray.filter(field => {
			// If this field is the field we're trying to delete.. remove it from the remainingDataFields
			if(field.recordId === fieldId) { 
				return false;
			}

			// Find the Field Type
			let fieldTypeObj = FieldTypeStore.get(field.fieldType);

			// If we found it...
			if(fieldTypeObj) {
				// Get the Datatype
				let dataType = fieldTypeObj.dataType;
				// If it's none, then remove this from the remaining data fields.  Otherwise, include it.
				return (dataType === 'none' ? false : true);
			} else {
				// No Datatype?  Assume it would not block field deletion as this should never happen.
				console.warn('No Field Type found for field', fieldId, 'when attempting to delete it. What happened?')
				return false;
			}
		})

		return (remainingDataFields.length ? true : false);
	}

	/**
	 * Get the SVN Icon Path for the Field Type.
	 * @param {string} fieldTypeId 
	 * @returns 
	 */
	static getFieldTypeIcon(fieldId) {
		if(fieldId === 'page') {
			return this.getFieldTypeIconByFieldTypeId('page');
		}

		let fieldObj = FieldStore.get(fieldId);
		if(!fieldObj) {
			console.warn('Couldnt get fieldObj for: ', fieldId);
			return this.getFieldTypeIconByFieldTypeId(undefined);
		}

		return this.getFieldTypeIconByFieldTypeId(fieldObj.fieldType);
	}
	
	/**
	 * Get the SVN Icon Path for the Field Type.
	 * @param {string} fieldTypeId 
	 * @returns 
	 */
	static getFieldTypeIconByFieldTypeId(fieldTypeId) {
		let basePath = "https://cdn3.citizendeveloper.com/engine-build/citdev-media/v2/fieldtypes/";
		if(!fieldTypeId) {
			return basePath + fieldTypeIcons['d965b6d9-8dd0-440c-a31c-f40bf72accea'];
		}

		if(fieldTypeIcons[fieldTypeId]) {
			return basePath + fieldTypeIcons[fieldTypeId];
		} else {
			return basePath + fieldTypeIcons['d965b6d9-8dd0-440c-a31c-f40bf72accea']
		}
	}

	/**
	 * Get the Field Type Name for a field
	 * @param {string} fieldId
	 * @returns 
	 */
	static getFieldTypeName(fieldId) {
		let fieldObj = FieldStore.get(fieldId);
		if(fieldObj) {
			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType);
			if(fieldTypeObj && fieldTypeObj.name) {
				return fieldTypeObj.name;
			}
		}

		return '[ No Field Type Set ]';
	}

	/**
	 * Stand In Response for the Gen AI Calls in suggestFieldsForTable.
	 * @returns Array
	 */
	static _suggestFieldsForTableStandIn() {
			return [
				{
					"fieldName": "State",
					"description": "The state that the city is located in.",
					"fieldTypeName": "Selection of fixed options",
					"fieldTypeId": "0bc7afe8-a123-4a84-962e-f8b7b1aeb3c3",
					"recordId": "ba5e34cc-12fa-4697-a979-048342c77c46",
					"options": [
						{
							"name": "Alabama",
							"value": "AL"
						},
						{
							"name": "Alaska",
							"value": "AK"
						},
						{
							"name": "Arizona",
							"value": "AZ"
						},
						{
							"name": "Arkansas",
							"value": "AR"
						},
						{
							"name": "California",
							"value": "CA"
						},
						{
							"name": "Colorado",
							"value": "CO"
						},
						{
							"name": "Connecticut",
							"value": "CT"
						},
						{
							"name": "Delaware",
							"value": "DE"
						},
						{
							"name": "Florida",
							"value": "FL"
						},
						{
							"name": "Georgia",
							"value": "GA"
						},
						{
							"name": "Hawaii",
							"value": "HI"
						},
						{
							"name": "Idaho",
							"value": "ID"
						},
						{
							"name": "Illinois",
							"value": "IL"
						},
						{
							"name": "Indiana",
							"value": "IN"
						},
						{
							"name": "Iowa",
							"value": "IA"
						},
						{
							"name": "Kansas",
							"value": "KS"
						},
						{
							"name": "Kentucky",
							"value": "KY"
						},
						{
							"name": "Louisiana",
							"value": "LA"
						},
						{
							"name": "Maine",
							"value": "ME"
						},
						{
							"name": "Maryland",
							"value": "MD"
						},
						{
							"name": "Massachusetts",
							"value": "MA"
						},
						{
							"name": "Michigan",
							"value": "MI"
						},
						{
							"name": "Minnesota",
							"value": "MN"
						},
						{
							"name": "Mississippi",
							"value": "MS"
						},
						{
							"name": "Missouri",
							"value": "MO"
						},
						{
							"name": "Montana",
							"value": "MT"
						},
						{
							"name": "Nebraska",
							"value": "NE"
						},
						{
							"name": "Nevada",
							"value": "NV"
						},
						{
							"name": "New Hampshire",
							"value": "NH"
						},
						{
							"name": "New Jersey",
							"value": "NJ"
						},
						{
							"name": "New Mexico",
							"value": "NM"
						},
						{
							"name": "New York",
							"value": "NY"
						},
						{
							"name": "North Carolina",
							"value": "NC"
						},
						{
							"name": "North Dakota",
							"value": "ND"
						},
						{
							"name": "Ohio",
							"value": "OH"
						},
						{
							"name": "Oklahoma",
							"value": "OK"
						},
						{
							"name": "Oregon",
							"value": "OR"
						},
						{
							"name": "Pennsylvania",
							"value": "PA"
						},
						{
							"name": "Rhode Island",
							"value": "RI"
						},
						{
							"name": "South Carolina",
							"value": "SC"
						},
						{
							"name": "South Dakota",
							"value": "SD"
						},
						{
							"name": "Tennessee",
							"value": "TN"
						},
						{
							"name": "Texas",
							"value": "TX"
						},
						{
							"name": "Utah",
							"value": "UT"
						},
						{
							"name": "Vermont",
							"value": "VT"
						},
						{
							"name": "Virginia",
							"value": "VA"
						},
						{
							"name": "Washington",
							"value": "WA"
						},
						{
							"name": "West Virginia",
							"value": "WV"
						},
						{
							"name": "Wisconsin",
							"value": "WI"
						},
						{
							"name": "Wyoming",
							"value": "WY"
						}
					]
				},
				{
					"fieldName": "Country",
					"description": "The country that the city is located in.",
					"fieldTypeName": "Selection of fixed options",
					"fieldTypeId": "0bc7afe8-a123-4a84-962e-f8b7b1aeb3c3",
					"recordId": "f5fe6e96-db7e-4b61-acd4-b2d2ceefe859",
					"options": [
						{
							"name": "United States",
							"value": "US"
						},
						{
							"name": "Canada",
							"value": "CA"
						},
						{
							"name": "Mexico",
							"value": "MX"
						},
						{
							"name": "United Kingdom",
							"value": "UK"
						},
						{
							"name": "France",
							"value": "FR"
						},
						{
							"name": "Germany",
							"value": "DE"
						},
						{
							"name": "Italy",
							"value": "IT"
						},
						{
							"name": "Spain",
							"value": "ES"
						},
						{
							"name": "China",
							"value": "CN"
						},
						{
							"name": "Japan",
							"value": "JP"
						}
					]
				},
				{
					"fieldName": "Population",
					"description": "The population of the city.",
					"fieldTypeName": "Number",
					"fieldTypeId": "867b0b3b-f076-4fbc-b979-f1c5e7cb495f",
					"recordId": "c4a24cbc-edbb-4c74-a274-521e33712867"
				},
				{
					"fieldName": "Area",
					"description": "The area of the city in square miles.",
					"fieldTypeName": "Number",
					"fieldTypeId": "867b0b3b-f076-4fbc-b979-f1c5e7cb495f",
					"recordId": "2b68bb54-6f14-4b5b-8964-cbc428ffed6b"
				},
				{
					"fieldName": "Mayor's Name",
					"description": "The name of the city's mayor.",
					"fieldTypeName": "Short Text",
					"fieldTypeId": "d965b6d9-8dd0-440c-a31c-f40bf72accea",
					"recordId": "19599fe5-3e08-4faa-a804-729f31d708c1"
				}
			];
	} // end _suggestFieldsForTableStandIn

	/**
	 * Suggest Fields for this table, given this description.
	 * @param {String} tableRecordId Which table to suggest for
	 * @param {String} userPromptDescription Users suggestion
	 * @returns 
	 */
	static suggestFieldsForTable(tableRecordId, userPromptDescription) {
		return new Promise((resolve, reject) => {
			let stub = false;
			if(stub) {
				return resolve(this._suggestFieldsForTableStandIn());
			} else {
				let tableObj = TableStore.get(tableRecordId);
				if(tableObj) {
					// For new tables, we use the recordId as the TSN...
					let tableSchemaName = tableObj.new ? tableObj.recordId : tableObj.tableSchemaName;
					// Prep existing Fields
					let fieldArr = FieldStore.getByTableSchemaName(tableSchemaName).filter(fld => {
						return FieldStore.getHasData(fld.recordId);
					}).map(fld => {
						let returnField = {
							recordId: fld.recordId,
							label: fld.fieldLabel,
							fieldType: fld.fieldType
						}
						if(fld.descriptiveText) {
							returnField.descriptiveText = fld.descriptiveText;
						}
						return returnField;
					});

					// Add current suggestions to the existing fields, as if each was selected and added.
					let suggestions = AdminSettingsStore.getTableFieldSuggestions();
					if(suggestions && suggestions.length) {
						suggestions.forEach(fld => {
							fieldArr.push({
								label: fld['fieldName'],
								fieldType: fld['fieldTypeId']
							})
						});
					}

					// Prep Table List
					// let tableArr = TableStore.getAllArray().filter(tbl => {
					// 	return tbl.recordId !== tableRecordId;
					// }).map(tbl => {
					// 	let returnTable = {
					// 		tableId: tbl.recordId,
					// 		singularName: tbl.singularName,
					// 		pluralName: tbl.pluralName
					// 	}
					// 	if(tbl.description) {ription;
					// 	}
					// 	return returnTable;
					// });

					socketFetcher('gw/gen-ai-fields-for-table-v1', JSON.stringify({
						tableName: tableObj.singularName, 
						tableDescription: tableObj.description || 'A collection of ' + tableObj.pluralName, 
						existingFieldsJSON: JSON.stringify(fieldArr),
						// existingTablesJSON: JSON.stringify(tableArr)
						userPromptDescription: userPromptDescription
					})).then(result => {
						if(result.responseCode === 200) {
							let suggestedFields = result.response.filter(fld => {
								if(!fld.fieldTypeId || !fld.fieldTypeName || !fld.fieldName) { 
									return false;
								}
								let fieldTypeObj = FieldTypeStore.get(fld.fieldTypeId);
								if(!fieldTypeObj) { 
									return false;
								}

								return true;
							})
							suggestedFields.map(field => {
								if(!field.recordId) {
									field.recordId = uuid.v4();
								}
								return field;
							})
							return resolve(suggestedFields);
						} else {
							if(result.response) {
								console.error(result.response);	
							} else {
								console.error(result);
							}
							return reject('Error processing suggestFieldsForTable.');
						}
					}).catch(e => {
						console.error('Caught error:', e);
						reject(false);
					});
				} else {
					reject('Table Not Found');
				}
			} // End if stub.
		});
	}

	/**
	 * Validates FieldSchemaName of new Fields 
	 * 
	 * @static
	 * @param {String} schemaName - users' name for the block
	 * @param {String} currentRecordid - The ID of the current record being added
	 * @param {Array} errors - Any errors already existing
	 * @param {Array} additionalInvalidNames - Any additional names to consider invalid (used to power validation through MDGW)
	 * @returns {Object} - { isUnique: true/false, validSchemaName: schemaName, errors: []};
	 * @returns {Array} - Optional - Should only be used to power the recursion when calling itself and gather the found errors every time  
	 * @memberof FieldUtils
	 */
	static validateFieldSchemaName(schemaName, tableSchemaName, currentRecordId, errors, additionalInvalidNames) {

		//General Check up for Schema Names 
		let returnObj = BlockUtils.validateSchemaName(schemaName);

		//Update the SchemaName with the General Validation for Blocks
		schemaName = returnObj['validSchemaName'];

		//Must not be empty
		if(!schemaName){
			returnObj['isValidAndUnique'] = false;
			returnObj['validSchemaName'] = 'field1';
			returnObj['errors'].push('Schema Name is required');
			
			//Bail out
			return returnObj;
		}
		
		//Push the Errors from previous recursive calls the new Errors found: 
		if(errors){
			//Dont repeat same error messages
			for(let i = 0; i < errors.length; i++){
				if(!returnObj['errors'].includes(errors[i]))
				returnObj['errors'].push(errors[i]);	
			}
		}

		//Check the schema Name is unique among its group
		let fieldRecords = tableSchemaName ? FieldStore.getByTableSchemaName(tableSchemaName) : FieldStore.getAllArray(); 
		let relationRecords = RelationshipStore.getAllArray();
		// Concatenate all the records
		let allRecords = []
		allRecords = allRecords.concat(relationRecords, fieldRecords);

		let schemaNamesAlreadyUsed = [];
		if(additionalInvalidNames && Array.isArray(additionalInvalidNames)) {
			schemaNamesAlreadyUsed = schemaNamesAlreadyUsed.map(schemaName => schemaName.toLowerCase()).concat(additionalInvalidNames);
		}
		
		//Look for the Table's Fields and loop if any matches to what we are returning, error it 
		allRecords.forEach(record => {
			// do not include the current schemaName in the schemaNamesAlreadyUsed array
			if(record.recordId !== currentRecordId){
				// push fieldSchemaName and relationSchemaName in the schemaNamesAlreadyUsed array
				if(record.fieldSchemaName){
					schemaNamesAlreadyUsed.push(record.fieldSchemaName.toLowerCase());
				} else if(record.relationSchemaName){
					schemaNamesAlreadyUsed.push(record.relationSchemaName.toLowerCase());
				} 
			}
		});
	
		let digit = 1;

		//Should be unique 
		if(schemaNamesAlreadyUsed.includes(schemaName.toLowerCase())) {
			// Find the next number this schemaName should have as its sufix, to be valid 
			while(schemaNamesAlreadyUsed.includes(schemaName.toLowerCase() + digit)) {
				digit++;
			}

			//Split digits at the end of the string 
			let nextDigit = schemaName.match(/\d+$/);

			if(!Array.isArray(nextDigit)){
				nextDigit = 0;
			} else {
				nextDigit = nextDigit[0];
			}

			try {
				nextDigit = JSON.parse(nextDigit);
			} catch(error) {
				console.error(error.message);
			}

			//Remove the digits at the end of the string 
			let newSchemaName = schemaName.replace(/\d+$/, '');

			//add digit to current serial number 
			nextDigit += digit;

			//Join string and new Digit 
			newSchemaName += nextDigit;

			returnObj['isValidAndUnique'] = false;
			returnObj['validSchemaName'] = newSchemaName;
			returnObj['errors'].push('Technical Name already exists');

			
		} else {
			//Breaks the Recursion when All the Tests pass 
			returnObj['isValidAndUnique'] = true;
		}
		
		//Run the recursion: 
		if(!returnObj['isValidAndUnique']){
			//If we are still not unique then...run itself again
			return this.validateFieldSchemaName(returnObj['validSchemaName'], tableSchemaName, currentRecordId, returnObj['errors'])
		}

		return returnObj;
	}

	/**
	 * Sort an array by field label.
	 * 
	 * @static
	 * @param {Array} fieldsArray Array to sort
	 * @param {Object} options Object of options for the sort
	 * @param {string} options.direction ASC | DESC
	 * @returns Array Sorted Fields
	 * @memberof FieldUtils
	 */
	static sortByFieldLabel(fieldsArray, options = {}) {
		let direction = options.direction || 'ASC';

		if (direction === 'DESC') {
			return fieldsArray.sort((a, b) => {
				try {
					let aSettingsObj = JSON.parse(a.settings || '{}'),
						bSettingsObj = JSON.parse(b.settings || '{}');

					return (aSettingsObj.fieldLabel > bSettingsObj.fieldLabel) ? -1 : 1;
				} catch (e) {
					console.error('Problems to parse field settings to sort!');
					return 1;
				}
			});
		} else {
			return fieldsArray.sort((a, b) => {
				try {
					let aSettingsObj = JSON.parse(a.settings || '{}'),
						bSettingsObj = JSON.parse(b.settings || '{}');

					return (aSettingsObj.fieldLabel > bSettingsObj.fieldLabel) ? 1 : -1;
				} catch (e) {
					console.error('Problems to parse field settings to sort!');
					return 1;
				}
			});
		}
	}

	/**
	 * Retrieve the array of logic triggers for this field.
	 * @param {string} fieldId Which field to get Logic Triggers for
	 * @param {string} parentRecordId That fields parent
	 * @returns Object - triggers, otherTriggerFriendlyNames
	 */
	static getLogicTriggers(fieldId, parentRecordId) {
		let triggers = [];
		let settingsValue = '';
		let settingsValueSource = '';
		let valueSource = '';
		let	valueLabelClass = '';
		let	badgeTypeClass = '';
		let otherTriggerFriendlyNames = {};

		// Find this field
		let fieldObj = FieldStore.get(fieldId);
		let settingsSource = FieldSettingsStore.getSettingsWithSource(fieldId, parentRecordId);
		if (fieldObj) {

			// Get the Field Type
			let fieldTypeId = fieldObj ? fieldObj.fieldType : undefined;
			// Get available triggers for this field type.
			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType);
							
			if(fieldTypeObj.supportedActionTriggers) {
				let supportedActionTriggersArray = fieldTypeObj.supportedActionTriggers.split(',');

				//Adding Local Validation for every field, since the page runs them
				supportedActionTriggersArray.push('preFieldSave'); // @TODO : Set this one from FT BUILDER
				supportedActionTriggersArray.push('onEnterUp'); // @TODO : Set this one from FT BUILDER

				supportedActionTriggersArray.forEach(triggerName => {
					// get the settings value
					settingsValue = settingsSource['automation-'+triggerName] 
					? settingsSource['automation-'+triggerName].value 
					: null;
					let automationObj = ObjectUtils.getObjFromJSON(settingsValue);
					if(!automationObj || !automationObj.js || automationObj.js.length === 0) {
						badgeTypeClass = valueSource = valueLabelClass = settingsValue = '';
					} else {
						// get the source of the setting Local/Global
						settingsValueSource = settingsSource['automation-'+triggerName] 
											? settingsSource['automation-'+triggerName].source 
											: null;
						// set the badges according to local/global
						if(settingsValueSource === 'local') {
							valueSource = 'O';
							badgeTypeClass = 'badge-secondary';
						}
						settingsValue = 'Yes';
					}

					// Handle converting logicFunctionsUsed into an array
					let logicFunctionIds = automationObj && automationObj.logicFunctionsUsed ? automationObj.logicFunctionsUsed : '';
					let logicFunctionIdsArr =[]; 
					if (logicFunctionIds) {
						if(Array.isArray(logicFunctionIds)) {
							logicFunctionIdsArr = logicFunctionIds;
						} else {
							logicFunctionIdsArr = logicFunctionIds.split(',');
						}
					}
					
					triggers.push({
						name: triggerName,
						badgeTypeClass: badgeTypeClass,
						valueSource: valueSource,
						valueLabelClass: valueLabelClass,
						settingsValue: settingsValue,
						logicFunctions:  logicFunctionIdsArr
					});
				});
			} // End default FT Action Triggers.

			let customActionTriggers = fieldTypeObj.customActionTriggers;
			if(customActionTriggers){
				customActionTriggers = ObjectUtils.getObjFromJSON(customActionTriggers);

				customActionTriggers.forEach(triggerObj => {
					let customTriggerCodeName = triggerObj.codeName;
					let customTriggerFriendlyName = triggerObj.friendlyName;

					//Include the custom Triggers to the Friendly Names list 
					otherTriggerFriendlyNames[customTriggerCodeName] = customTriggerFriendlyName;

					// get the settings value
					settingsValue = settingsSource['automation-'+customTriggerCodeName] 
					? settingsSource['automation-'+customTriggerCodeName].value 
					: null;
					let automationObj = ObjectUtils.getObjFromJSON(settingsValue);
					if(!automationObj || !automationObj.js || automationObj.js.length === 0) {
						badgeTypeClass = valueSource = valueLabelClass = settingsValue = '';
					} else {
						// get the source of the setting Local/Global
						settingsValueSource = settingsSource['automation-'+customTriggerCodeName] 
											? settingsSource['automation-'+customTriggerCodeName].source 
											: null;
						// set the badges according to local/global
						if(settingsValueSource === 'local') {
							valueSource = 'O';
							badgeTypeClass = 'badge-secondary';
						}
						settingsValue = 'Yes';
					}

					// Handle converting logicFunctionsUsed into an array
					let logicFunctionIds = automationObj && automationObj.logicFunctionsUsed ? automationObj.logicFunctionsUsed : '';
					let logicFunctionIdsArr =[]; 
					if (logicFunctionIds) {
						if(Array.isArray(logicFunctionIds)) {
							logicFunctionIdsArr = logicFunctionIds;
						} else {
							logicFunctionIdsArr = logicFunctionIds.split(',');
						}
					}

					triggers.push({
						name: customTriggerCodeName,
						badgeTypeClass: badgeTypeClass,
						valueSource: valueSource,
						valueLabelClass: valueLabelClass,
						settingsValue: settingsValue,
						logicFunctions: logicFunctionIdsArr
					});
				});
			} // End Custom Action Triggers

			// If the field type is Main Menu/Primary Navigation, then scan the options for additional triggers.
			if(fieldTypeId === 'cd0ee38e-d63f-44d2-b02b-44376fcc7c2e') {
				let tabOptions = ObjectUtils.getObjFromJSON(fieldObj.tabOptions);

				tabOptions.forEach((tabOption) => {
					if(tabOption.type === 'logic' && tabOption.recordId) {
						let triggerName = tabOption.recordId + '-click';
						otherTriggerFriendlyNames[triggerName] = tabOption.displayedName + ' Click';

						let settingsValue = '';
						let logicFunctionIdsArr =[]; 
						let logic = fieldObj['automation-' + triggerName];
						if(logic && logic !== 'null') {
							settingsValue = 'Yes';
							let automationObj = ObjectUtils.getObjFromJSON(logic);
	
							// Handle converting logicFunctionsUsed into an array
							let logicFunctionIds = automationObj && automationObj.logicFunctionsUsed ? automationObj.logicFunctionsUsed : '';
							if (logicFunctionIds) {
								if(Array.isArray(logicFunctionIds)) {
									logicFunctionIdsArr = logicFunctionIds;
								} else {
									logicFunctionIdsArr = logicFunctionIds.split(',');
								}
							}
						}
						
						triggers.push({
							name: triggerName,
							badgeTypeClass: 'badge-secondary',
							valueSource: '',
							valueLabelClass: '',
							settingsValue: settingsValue,
							logicFunctions: logicFunctionIdsArr
						});
					// If its not tab, then its a menu - lets include that.
					} else {
						let tabName = tabOption.displayedName + ' : ';
						tabOption.subTabs.forEach((subTabOption) => {
							if(subTabOption.type === 'logic' && subTabOption.recordId) {
								let subtabName = tabName + subTabOption.displayedName;
								let triggerName = subTabOption.recordId + '-click';
								otherTriggerFriendlyNames[triggerName] = subtabName + ' Click';
		
								let settingsValue = '';
								let logicFunctionIdsArr =[]; 
								let logic = fieldObj['automation-' + triggerName];
								if(logic && logic !== 'null') {
									settingsValue = 'Yes';
									let automationObj = ObjectUtils.getObjFromJSON(logic);

									// Handle converting logicFunctionsUsed into an array
									let logicFunctionIds = automationObj && automationObj.logicFunctionsUsed ? automationObj.logicFunctionsUsed : '';
									if (logicFunctionIds) {
										if(Array.isArray(logicFunctionIds)) {
											logicFunctionIdsArr = logicFunctionIds;
										} else {
											logicFunctionIdsArr = logicFunctionIds.split(',');
										}
									}
								}

								triggers.push({
									name: triggerName,
									badgeTypeClass: 'badge-secondary',
									valueSource: '',
									valueLabelClass: '',
									settingsValue: settingsValue,
									logicFunctions: logicFunctionIdsArr
								});
							}
						})
		
					}
				})
			} // End if this is a main menu field.
		} // End if there is no field obj

		return {
			triggers: triggers,
			otherTriggerFriendlyNames: otherTriggerFriendlyNames
		}
	}

	/**
	 * Attach a field to the Field
	 * 
	 * @param {string} fieldId - Field Record Id
	 * @param {string} childId - Child Field Id
	 * @param {boolean} pushToDatabase - Optional - save in Database? 
	 * @param {boolean} coordinates - Optional - Object of coordinates - If all 4 are not provided, anything provided is ignored.
	 * @param {boolean} coordinates.x - Optional - X Position on Parent
	 * @param {boolean} coordinates.y - Optional - Y Position on Parent
	 * @param {boolean} coordinates.height - Optional - Height of child object
	 * @param {boolean} coordinates.width - Optional - Width of child object
	 * @param {string} attachmentId - Optional - the attachment ID to use
	 */
	static attachChild(fieldId, childId, pushToDatabase, coordinates, attachmentId){
		attachmentId = attachmentId || uuid.v4();

		let fieldSettings = FieldStore.getSettings(fieldId);

		// Grab our existing fieldPosition
		let fieldPosition = ObjectUtils.getObjFromJSON(fieldSettings.fieldPosition);
		
		// If we don't have layouts yet, this is probably the first field attached to a parent field; assume it's so and make a new one.
		fieldPosition = (fieldPosition && Object.keys(fieldPosition).length) 
			? fieldPosition 
			: {
				lg: [],
				md: [],
				sm: []
			};

		let attachedFieldsArr = ObjectUtils.getObjFromJSON(fieldSettings.attachedFields);

		//Get the Attached Fields
		let attachedFieldFound = false;

		if(Array.isArray(attachedFieldsArr)){
			attachedFieldFound = attachedFieldsArr.find(attachedField => {
				if(attachedField.attachmentId === childId || attachedField.recordId === childId) {
					return true;
				}
				return false;
			});
		} else {
			attachedFieldsArr = [];
		}
		
		
		// If no coordinates are provided, calculate them
		let coordX = 0; // Always 0 (on the left)
		let coordY = 0; // Will be calculated based on last attached field - start at 0
		let width = 6; // Always 6 (half the grid)
		let height = this.getDefaultRows(childId); // default to 7 if not set in the variant
		
		let settingsObj = {};
		// Calculate the coordinates for each screensize 
		// Loop over all screensizes
		['lg', 'md', 'sm'].forEach(screenSize => {
			// If this screensize manages itself...
			if(Array.isArray(fieldPosition[screenSize])) {
				let fieldPositionArr = fieldPosition[screenSize];

				// How many fields are attached to this screen already
				let arrLength = fieldPosition[screenSize].length;

				// Default coordinates for the first position
				let newFieldCoordinates = {
					w: width,
					h: height,
					x: 0,
					y: 0,
					i: attachmentId
				};
				
				// Manage here if we do have coordinates passed from the Params: 
				if(coordinates && 
					coordinates.x !== undefined && !isNaN(coordinates.x) && 
					coordinates.y !== undefined && !isNaN(coordinates.y) && 
					coordinates.height !== undefined && !isNaN(coordinates.height) && 
					coordinates.width !== undefined && !isNaN(coordinates.width) ) {
					newFieldCoordinates = {
						w: coordinates.width,
						h: coordinates.height,
						x: coordinates.x,
						y: coordinates.y,
						i: attachmentId
					}
				} else if(arrLength && !coordinates) {

					// Find the attached field that's lowest down the screen
					let lastY = 0;
					let lastHeight = 0;
					attachedFieldsArr.forEach(attachmentObj => {
						let id = attachmentObj.attachmentId || attachmentObj.recordId;
						let matchingPositionObj = fieldPositionArr.find(positionObj => positionObj.i === id);
						if (matchingPositionObj && matchingPositionObj.y >= lastY) {
							lastY = matchingPositionObj.y;
							lastHeight = matchingPositionObj.h;
						}
					});

					// Re calculate coordY
					coordY = lastHeight + lastY;

					newFieldCoordinates = {
						w: width,
						h: height,
						x: coordX,
						y: coordY,
						i: attachmentId
					};
				}

				// Validate coordinates
				newFieldCoordinates = BlockUtils.getValidCoordinates(newFieldCoordinates);

				//Append Coordinates to Screen Size: 
				fieldPosition[screenSize].push(newFieldCoordinates);

				// Push this one screensize to the store.
				settingsObj[screenSize] = fieldPosition[screenSize];
			}
		});

		let toPush = {};
		
		if(!attachedFieldFound) {
			attachedFieldsArr.push({
				attachmentId: attachmentId,
				recordIds: [childId],
				order: attachedFieldsArr.length + 1
			});
		}

		toPush.fieldPosition = JSON.stringify(settingsObj);
		toPush.attachedFields = JSON.stringify(attachedFieldsArr);
		//Push the updated components list to the store
		FieldActions.pushToStore(fieldId, toPush);
		// FieldActions.pushSettingToStore(fieldId, 'attachedFields', JSON.stringify(attachedFieldsArr));
		
		//Update the Database if set to True
		if(pushToDatabase) {
			let fieldObj = FieldStore.get(fieldId, true);
			FieldActions.pushToDatabase(fieldObj);
		}
	}

	/**
	 * Get the fields' field type's "rows" property for the given field 
	 * component.  If there are no rows specified in the Field Type, returns the default rows.
	 * @param {string} componentId Field Component to get the Field Type Of, and Rows From.
	 * @return {integer} Number of Rows.
	 */
	static getDefaultRows(componentId){
		let defaultHeight = 7;

		if(!componentId){
			return defaultHeight;
		}

		let fieldObj = FieldStore.get(componentId);
		let fieldTypeId = fieldObj ? fieldObj.fieldType : undefined;
		let fieldTypeObj = fieldTypeId ? FieldTypeStore.get(fieldTypeId) : undefined;

		// If we didnt find our field, field type Id, field type Obj, of our Field Type has no variants...
		if(!fieldObj || !fieldTypeId || !fieldTypeObj || (fieldTypeObj && !fieldTypeObj.variants)) {
			return defaultHeight;
		}

		// Find the  view variant value in FieldSettingsStore for this Field 
		let fieldSettingObj = FieldSettingsStore.getSettings(componentId);
		let viewVariant = fieldSettingObj.viewVariant 
			? fieldSettingObj.viewVariant 
			: FieldStore.getDefaultVariantComponentName(componentId, 'view', fieldTypeId);
		let editVariant = fieldSettingObj.editVariant 
			? fieldSettingObj.editVariant 
			: FieldStore.getDefaultVariantComponentName(componentId, 'edit', fieldTypeId);
		if(!editVariant.length) {
			editVariant = viewVariant;
		}

		// Assume the defaults in all cases.
		let vVHeight = defaultHeight;
		let eVHeight = defaultHeight;

		fieldTypeObj.variants.forEach(variantObj => {
			if(variantObj.reactComponentName === viewVariant && variantObj.rows) {
				vVHeight = variantObj.rows;
			}
			if(variantObj.reactComponentName === editVariant && variantObj.rows) {
				eVHeight = variantObj.rows;
			}
		});

		return Math.max(parseInt(vVHeight, 10), parseInt(eVHeight, 10));
	}

	/**
	 * Attach a field to the Field
	 * 
	 * @param {string} fieldId - Field Record Id
	 * @param {string} childId - Child Field Id
	 * @param {boolean} coordinates - Optional - Object of coordinates - If all 4 are not provided, anything provided is ignored.
	 * @param {boolean} coordinates.x - Optional - X Position on Parent
	 * @param {boolean} coordinates.y - Optional - Y Position on Parent
	 * @param {boolean} coordinates.height - Optional - Height of child object
	 * @param {boolean} coordinates.width - Optional - Width of child object
	 * @param {string} attachmentId - Optional - the attachment ID to use
	 */
	static attachChildPromise(fieldId, childId, coordinates, attachmentId){
		this.attachChild(fieldId, childId, false, coordinates, attachmentId);
		let fieldObj = FieldStore.get(fieldId, true);
		return FieldActions.pushToDatabasePromise(fieldObj).then(results => {
			return results;
		});
	}

	/**
	 * Attach all possible children (which store data, including relationships) to a Field, by TableSchemaName
	 * 
	 * @static
	 * @param {string} fieldId - Field Id
	 * @param {string} tableSchemaName - Optional Parent TableSchemaName
	 * @memberof PageUtils
	 */
	static attachAllChildrenWithData(fieldId, tableSchemaName){
		
		// Get the possible children
		let possibleChildren = this.getPossibleChildren(fieldId, tableSchemaName) || [];
		
		let index = 0;
		let lastYs = [0, 0]; // Make sure that each field is always positioned directly below the previous one
		// Attach all children whose dataType is not 'none'
		possibleChildren.sort((a,b) => {
			return a.name.localeCompare(b.name);
		}).forEach(possibleChild => {
			let childFieldTypeId = possibleChild.fieldType;
			let childFieldTypeObj = FieldTypeStore.get(childFieldTypeId) || {};
			if (childFieldTypeObj.dataType !== 'none') {
				let i = index % 2;
				let height = this.getDefaultRows(possibleChild.value);
				let y = lastYs[i];
				lastYs[i] += height;
				this.attachChild(fieldId, possibleChild.value, false, {
					x: index % 2 ? 6 : 0,
					y: y,
					height: height,
					width: 6
				});
				index++;
			}
		});

		return FieldActions.pushToDatabasePromise(FieldStore.get(fieldId, true));
	}

	/**
	 * Attach all possible children (which have a given role) to a Field, by TableSchemaName
	 * 
	 * @static
	 * @param {string} fieldId - Field Id
	 * @param {string} tableSchemaName - Optional Parent TableSchemaName
	 * @param {string} role The role of the fields to attach
	 * @memberof PageUtils
	 */
	static attachAllChildrenWithRole(fieldId, tableSchemaName, role){
		
		// Get the possible children
		let possibleChildren = this.getPossibleChildren(fieldId, tableSchemaName) || [];

		let index = 0;
		let lastYs = [0, 0]; // Make sure that each field is always positioned directly below the previous one
		// Attach all children whose dataType is not 'none'
		possibleChildren.sort((a,b) => {
			return a.name.localeCompare(b.name);
		}).forEach(possibleChild => {
			let settings = FieldSettingsStore.getSettings(possibleChild.value) || {};
			let roles = settings.roles ? settings.roles.split(',') : [];
			if (roles.indexOf(role) > -1) {
				let i = index % 2;
				let height = this.getDefaultRows(possibleChild.value);
				let y = lastYs[i];
				lastYs[i] += height;
				this.attachChild(fieldId, possibleChild.value, false, {
					x: index % 2 ? 6 : 0,
					y: y,
					height: height,
					width: 6
				});
				index++;
			}
		});

		return FieldActions.pushToDatabasePromise(FieldStore.get(fieldId, true));
	}

	/**
	 * 
	 * @param {string} addToField ID of the field to which the page is being added as a tab
	 * @param {string} pageId ID of the page being added as a tab
	 */
	static addPageAsTab(addToField, pageId) {
		let fieldObj = FieldStore.get(addToField) || {};
		
		// Check that we're adding a tab to the right field in the first place
		if(fieldObj.fieldType !== 'cd0ee38e-d63f-44d2-b02b-44376fcc7c2e') {
			console.error('Add page as tab failed. Reason: attempting to add page as a tab to non-tab field ' + addToField);
			return Promise.resolve();
		}

		// Check that the page exists
		let pageObj = PageStore.get(pageId);
		if(!pageObj) {
			console.error('Add page as tab failed. Reason: attempting to add nonexistent page ' + pageId);
			return Promise.resolve();
		}

		// Get information for the page's table
		let tableObj = null;
		tableObj = TableStore.getByTableSchemaName(pageObj.tableSchemaName);
		if(!tableObj) {
			console.error('Add page as tab failed. Reason: attempting to add page on nonexistent table ' + pageObj.tableSchemaName);
			return Promise.resolve();
		}

		// Get old tabs
		let fieldSettings = FieldSettingsStore.getSettings(addToField) || {};
		let oldTabsJSON = fieldSettings.tabOptions;
		let tabsObj = [];
		
		// Not using ObjectUtils to parse the JSON because we want to default to [], not to {}
		try {
			tabsObj = oldTabsJSON ? JSON.parse(oldTabsJSON) : [];
		} catch(err) {
			console.warn('Failed to parse tabOptions JSON for field %s. JSON was', addToField, oldTabsJSON);
		}

		// Create new tab object
		let newTab = {
			displayedName: pageObj.name,
			icon: tableObj.icon,
			order: tabsObj.length,
			page: pageId,
			subTabs: [],
			type: 'tab',
			visibility: ''
		};

		// Add new tab
		tabsObj.push(newTab);

		// Update store
		FieldActions.pushSettingToStore(addToField, 'tabOptions', JSON.stringify(tabsObj));
		
		// Update database with promise
		return FieldActions.pushToDatabase(FieldStore.get(addToField));
	}

  /**
   * Detaches a field from the Field's attachedFields and fieldPosition
   * 
   * @static
   * @param {string} fieldId - Field Record ID
   * @param {string} childFieldId - Field Child record Id
   * @param {boolean} pushToDatabase - save in Database?
   * @memberof FieldUtils
   */
   static detachChild(fieldId, childFieldId, pushToDatabase){
		//Get the FieldObject 
		let fieldSettingsObj = FieldStore.getSettings(fieldId);
		// If we didn't find the field.. return.
		if(!fieldSettingsObj) {
			return false;
		}

		//Get the attachedFields key from which we will delete a child from
		let	attachedFieldsArr = ObjectUtils.getObjFromJSON(fieldSettingsObj.attachedFields);

		// Do we have any render entries for the parent? If so, we may need to update the render store to remove the children
		let parentRenderObjs = RenderStore.getRenderObjectsForComponent('field', fieldId);
		let rendersToRemove = [];
		let fieldAttachmentId;
		if(Array.isArray(attachedFieldsArr)) {
			for(let i = (attachedFieldsArr.length - 1); i >= 0; i--) {
				let {attachmentId, recordId, recordIds, order} = attachedFieldsArr[i];
				let attachmentMatches = false;
				if(attachmentId === childFieldId || recordId === childFieldId) {
					fieldAttachmentId = attachmentId;
					attachedFieldsArr.splice(i, 1);
					attachmentMatches = true;
				} else if (recordIds) {
					// If the ID is in any of the child record IDs, remove it
					// @TODO: Should we update the display field automation in here somehow, too? How do we do that?
					// Maybe table it for when we have a more holistic approach to deleting fields?
					let index = attachedFieldsArr[i].recordIds.indexOf(childFieldId);
					// Remove the attached field from recordIds if it's in here
					if(index > -1) {
						fieldAttachmentId = attachmentId;
						attachedFieldsArr[i].recordIds.splice(index, 1);
						// If it was our only field, remove this attachment entry entirely
						if(!attachedFieldsArr[i].recordIds.length) {
							attachedFieldsArr.splice(i, 1);
							attachmentMatches = true;
						}
					}
				}

				if(attachmentMatches) {
					parentRenderObjs.forEach((renderImmutable) => {
						let renderId = renderImmutable.get('renderId');
						let childRenderId = RenderStore.findChildRenderId(renderId, childFieldId, order);
						if(childRenderId) {
							rendersToRemove.push(childRenderId);
						}
					});
				}
			}
		}

		//Stringify the Value 
		let appendObj = {};
		fieldSettingsObj.attachedFields = JSON.stringify(attachedFieldsArr);
		appendObj.settings = JSON.stringify(fieldSettingsObj);


		// Remove any child configuration entries for this ID
		let childConfigurationsObj = FieldStore.getChildConfigurations(fieldId) || {};
		if (childConfigurationsObj && childConfigurationsObj[childFieldId]) {
			delete childConfigurationsObj[childFieldId];
			// @TODO: This is backwards compatible and can stay for now, but at some point we may need to update this for child configuration splitting
			appendObj.childConfigurations = JSON.stringify(childConfigurationsObj);
		}

		// Also remove any visibility for the attached object
		if(fieldSettingsObj[fieldAttachmentId + '-visibility']) {
			appendObj[fieldAttachmentId + '-visibility'] = null;
		}


		RenderActions.deleteRenderBulk(rendersToRemove);

		//Update the Store with the updated attachedFields
		FieldActions.pushToStore(fieldId, appendObj);

		//Update the Database
		if(pushToDatabase){
			let fieldObj = FieldStore.get(fieldId);
			FieldActions.pushToDatabase(fieldObj);
		}
	}

	/**
	 * Detaches all the child fields
	 * 
	 * @static
	 * @param {string} parentFieldId 
	 * @param {boolean} pushToDatabase 
	 * @memberof FieldUtils
	 * @todo Rewrite!
	 */
	static detachAllChildren(parentFieldId, pushToDatabase){
		//Grab the Columns config that is where the children to delete will be located  
		let fieldSettings =  FieldStore.getSettings(parentFieldId),
			attachedFieldsArr = (fieldSettings.attachedFields
				? ObjectUtils.getObjFromJSON(fieldSettings.attachedFields)
				: []);

		// Look through the child fields we found
		attachedFieldsArr.forEach(attachedFieldObj => {
			//Detach it from its parent and pushToDatabase if pushToDatabase = true
			this.detachChild(parentFieldId, attachedFieldObj.attachmentId || attachedFieldObj.recordId, false);
		})

		if(pushToDatabase) {
			let fieldObj = FieldStore.get(parentFieldId);
			FieldActions.pushToDatabase(fieldObj);
		}
	}

  /**
   * Detaches a field from all the parents it is attached to.
   * 
   * @static
   * @param {string} fieldId - Field to delete
   * @param {string} tableSchemaName - Optional, to constrain the pages records to delete field from 
   * @param {boolean} pushToDatabase - Optional (false) - If true, pushes changes to database
   * @memberof FieldUtils
   */
   static detachChildFromAllParents(childFieldId, tableSchemaName, pushToDatabase){
		let parents = this.getParents(childFieldId, tableSchemaName);
		
		parents.forEach(parentAttachment => {
			if(parentAttachment.tableSchemaName === 'field') {
				this.detachChild(parentAttachment.recordId, childFieldId, pushToDatabase);
			} else if (parentAttachment.tableSchemaName === 'page') {
				PageUtils.detachChild(parentAttachment.recordId, childFieldId, pushToDatabase);
			}
		});
	}

	/**
	 * Get all fields from an attached fields JSON for this fieldId, recusively.
	 * 
	 * @param {string} fieldId Field ID to get child fields from 
	 * @param {string} attachedFieldsJSON  [Optional] attachedFieldsJSON for the fieldId
	 * @returns {Array} Array of attached fields to this parent, and any children of this parent with fields.
	 */
	static getAllChildren(fieldId, attachedFieldsJSON, ignoreFieldTypes) {
		if(!attachedFieldsJSON) {
			let fieldSettings = FieldStore.getSettings(fieldId);
			if(fieldSettings) {
				attachedFieldsJSON = fieldSettings.attachedFields;
			}
		}
		let attachedFieldsArr = ObjectUtils.getObjFromJSON(attachedFieldsJSON),
			fields = [];
		if(Array.isArray(attachedFieldsArr)) {
			let found = {};
			attachedFieldsArr.forEach(attachedField => {
				let fieldIds = attachedField.recordIds ? attachedField.recordIds : [attachedField.recordId];
				fieldIds.forEach(childFieldId => {
					if(!found[childFieldId]) {
						found[childFieldId] = true;
						let childFieldObj = FieldStore.get(childFieldId);
						let include = childFieldObj && ignoreFieldTypes && ignoreFieldTypes.length && ignoreFieldTypes.includes(childFieldObj.fieldType) ? false : true;
						if(childFieldObj && include) {
							fields.push({
								fieldSchemaName: childFieldObj.fieldSchemaName,
								fieldTypeId: childFieldObj.fieldType,
								fieldId: childFieldId,
								parentId: fieldId
							});
							let settings = FieldStore.getSettings(childFieldId);
							if (settings.attachedFields) {
								fields = fields.concat(this.getAllChildren(childFieldId, settings.attachedFields, ignoreFieldTypes));
							}
						}
					}
				});
			});
		}

		return fields;
	}

	static getAllRenderedChildren(renderId) {
		let renderObj = RenderStore.get(renderId);
		let fields = [];
		if (renderObj && Array.isArray(renderObj.children)) {
			renderObj.children.forEach(childRenderId => {
				let childRenderObj = RenderStore.get(childRenderId);
				let fieldMode = FieldModesStore.getMode(childRenderId);
				if (childRenderObj && childRenderObj.dataRecordId &&
					childRenderObj.componentType !== 'page' &&
					fieldMode && fieldMode.currentMode &&
					fieldMode.currentMode !== 'view') {
					let childFieldId = childRenderObj.componentId;
					let childFieldObj = FieldStore.get(childFieldId);
					if (childFieldObj) {
						fields.push({
							fieldSchemaName: childFieldObj.fieldSchemaName,
							fieldTypeId: childFieldObj.fieldType,
							fieldId: childRenderObj.componentId,
							attachmentId: childRenderObj.attachmentId,
							parentId: renderObj.componentId,
							parentComponentType: renderObj.componentType,
							dataRecordId: childRenderObj.dataRecordId,
							dataTableSchemaName: childRenderObj.dataTableSchemaName,
							renderId: childRenderId
						});
						if (childRenderObj.children) {
							fields = fields.concat(this.getAllRenderedChildren(childRenderId));
						}
					}
				} else if (childRenderObj && childRenderObj.children) {
					fields = fields.concat(this.getAllRenderedChildren(childRenderId));
				}
			});
		}
		return fields;
	}

	/**
	 *  Returns all the child fields
	 * 
	 * @static
	 * @param {string} parentFieldId - field Id of Parent
	 * @param {string} getArrayResults - Optional - If no array parameter, it returns an Object 
	 * 
	 * @returns {mixed} - Object (default) or array of child fields on this page
	 * @memberof FieldUtils
	 */
	static getChildren(parentFieldId, getArrayResults){

		//By default Return Object 
		let childrenResults = {}

		/**SEARCH FOR THE FIELD CHILDREN**/
		let fieldSettings = FieldStore.getSettings(parentFieldId);
		
		//Check if This field Settings has columns Config
		//If this Field Does not have any Children, return empty String
		if(!fieldSettings || !fieldSettings.attachedFields) {
			return getArrayResults ? [] : {};
		}

		// Find the children
		let attachedFieldsArr = fieldSettings.attachedFields ? ObjectUtils.getObjFromJSON(fieldSettings.attachedFields) : [];

		if(Array.isArray(attachedFieldsArr)){
			//For each found child, push it to the results 
			attachedFieldsArr.forEach(attachedField => {
				let recordIds = attachedField.recordIds ? attachedField.recordIds : [attachedField.recordId];

				recordIds.forEach(recordId => {
					if(!childrenResults[recordId]) {
						//Grab the object child
						let childSettings = FieldStore.getSettings(recordId);
						
						//Return The Child Only with the Keys we care 
						childrenResults[recordId] = {
							fieldId: recordId,
							fieldLabel: (childSettings && childSettings.fieldLabel ? childSettings.fieldLabel : '[ No Field Label ]'),
							fieldType: attachedField && attachedField.fieldType ? attachedField.fieldType : undefined
						}
					}
				});
			});
		}

		//Return array of results or []
		return getArrayResults ? Object.keys(childrenResults).map(key => childrenResults[key]) : childrenResults;
	}

	/**
	 * Get the highest Y position of any element on the grid.
	 * 
	 * @static
	 * @param {string} parentId Record ID of the Field to look at.
	 * @returns 
	 * @memberof PageUtils
	 */
	static getNextYPosition(parentId) {
		let nextYPosition = 0,
			fieldSettingsObj = FieldStore.getSettings(parentId);
		if(!fieldSettingsObj || !fieldSettingsObj.fieldPosition) {
			return 0;
		}

		let lastYs = { lg: 0, md: 0, sm: 0},
			layouts = ObjectUtils.getObjFromJSON(fieldSettingsObj.fieldPosition);

		['lg', 'md', 'sm'].forEach(screenSize => {
			if(layouts[screenSize] && Array.isArray(layouts[screenSize])) {
				layouts[screenSize].forEach(field => {
					if(field.y >= lastYs[screenSize]) {
						lastYs[screenSize] = (field.y + field.h); // Highest Y + Objects Height
					}
				});
			}
		});

		nextYPosition = lastYs['sm'];
		if(lastYs['md'] > nextYPosition) {
			nextYPosition = lastYs['md'];
		}
		if(lastYs['lg'] > nextYPosition) {
			nextYPosition = lastYs['lg'];
		}
		return nextYPosition;
	}

	/**
	 * Returns all the Parents (both Pages and Fields) where the field is attached
	 * 
	 * @static
	 * @param {string} childFieldId - recordId of Child 
	 * @param {string} tableSchemaName - Optional - to constrain the page results 
	 * @returns {array} parentsArray - Array of Parents containing this field
	 * @memberof FieldUtils
	 */
	static getParents(childFieldId, tableSchemaName){
		//Array to return 
		let parentsArray = [],
			pageArr = [],
			fieldArr = [];

		//Do we have a 'TableSchemaName'?
		if(!tableSchemaName){
			//Grab the TableSchemaName from the Field if we dont have a tableSchemaName 
			let fieldObj = FieldStore.get(childFieldId);
			//Set the value of the tableSchemaName
			tableSchemaName = fieldObj.tableSchemaName;
		}

		/**SEARCH FOR PAGE PARENTS**/ 
		pageArr = PageStore.getAllArray();
			

		//Do we have Records ? if So look for parents
		if(Array.isArray(pageArr)){
			//Loop through all the records 
			pageArr.forEach((page) => {
				//Grab the Page Name
				let parentName = (page && page.name) ? page.name : '',
					fieldFound = false,
					attachedFieldsObj = (page.attachedFields 
						? ObjectUtils.getObjFromJSON(page.attachedFields)
						: {});


				Object.keys(attachedFieldsObj).forEach(attachedFieldKey => {
					let attachedFieldObj = attachedFieldsObj[attachedFieldKey];
					if(attachedFieldObj.attachmentId) {
						// If we were passed an attachment ID vs. a record ID, use that
						if(attachedFieldObj.attachmentId === childFieldId) {
							fieldFound = true;
						} else if (attachedFieldObj.recordIds) {
							// If the field is anywhere within the recordIds attached under an attachment key, include this
							attachedFieldObj.recordIds.forEach(id => {
								if(childFieldId === id) {
									fieldFound = true;
								}
							})
						}
					} else if(attachedFieldObj.recordId === childFieldId) {
						fieldFound = true;
					}
				});


				// If this page doesnt have the field, ignore it
				if(!fieldFound) {
					return;
				}

				//If we get here, is because the component was found in this Page (Parent)
				//Add it to the parentsArray:
				parentsArray.push({
					name: parentName,
					recordId: page.recordId,
					tableSchemaName: 'page'
				});
			});
		}

		/**SEARCH FOR FIELD PARENTS**/ 
		//Just Grab records from the TableSchemaName
		// @todo - Only get me fields with parents
		fieldArr = FieldStore.getAllArray();
		
		//Do we have Records ? if So look for parents
		if(Array.isArray(fieldArr)){
			//Loop through all the records 
			fieldArr.forEach(fieldParent => {
				let parentFieldId = fieldParent.recordId,
					fieldType = (fieldParent ? fieldParent.fieldType : undefined);

				// "9b782b83-4962-4bd6-993c-f72096e02610" => List
				// "7ebd9251-675c-4129-95e3-6b8e31c135a2" => Container
				if(!fieldType || 
					(fieldType !== '9b782b83-4962-4bd6-993c-f72096e02610' && 
					fieldType !== '7ebd9251-675c-4129-95e3-6b8e31c135a2')) {
					return;
				}

				let childFields = this.getChildren(parentFieldId, true),
					parentFieldSettings = FieldStore.getSettings(parentFieldId),
					parentFieldLabel = (parentFieldSettings.fieldLabel ? parentFieldSettings.fieldLabel : '[ No Field Label ]');

				if(Array.isArray(childFields)){
					// Look through the child fields for this screensize
					childFields.forEach(childFieldConfig => {
						// If we find the child Field to Detach
						if(childFieldConfig.fieldId === childFieldId) {
							//If we get here, is because the component was found in this Page (Parent)
							//Add it to the parentsArray:
							parentsArray.push({
								name: parentFieldLabel,
								recordId: parentFieldId,
								tableSchemaName: 'field'
							});
						}
					});
				}
			});
		}
		/**Return All the Parents (Page and Fields)**/
		return parentsArray;
	}
	/**
	 * Get possible Sibilings of the Field
	 * 
	 * @static
	 * @param {any} parentTableSchemaName 
	 * @param {any} parentRecordId 
	 * @returns {array} possibleSibilings
	 * @memberof FieldUtils
	 */
	static getPossibleSiblings(parentTableSchemaName, parentRecordId){
		let possibleSibilings = [];

		//Is our parent Page or Field? 
		if(parentTableSchemaName === 'page'){
			possibleSibilings = PageUtils.getPossibleChildren(parentRecordId);
		} else if(parentTableSchemaName === 'field'){ //@TODO
			//Get the possible sibilings (children of this parentRecordId Restrict to List only)
			possibleSibilings = this.getPossibleChildren(parentRecordId);
		}

		return possibleSibilings;
	}
	/**
	 * Get Fields to attach to a Field, by TableSchemaName  
	 * 
	 * @static
	 * @param {string} parentId Parent Field Id
	 * @param {string} tableSchemaName (Optional) Table Schema Name to get fields from.
	 * @return {array} childrenToAttach 
	 * @memberof FieldUtils
	 */
	static getPossibleChildren(parentId, tableSchemaName){

		let fieldTypeId = FieldStore.get(parentId).fieldType;

		//If this field is not a List or fieldContainer return 0 results == []
		// @todo Change this out to check the hasChildSettings setting instead of this.
		if(fieldTypeId !== '9b782b83-4962-4bd6-993c-f72096e02610' && 
			fieldTypeId !== '7ebd9251-675c-4129-95e3-6b8e31c135a2') {
			return [];
		}		

		//Grab the tableSchemaName: 
		if(!tableSchemaName) {
			tableSchemaName = this.getDefaultChildTableSchemaName(parentId);
		}

		//Get the Children Already attached to this parent 
		let fieldsAlreadyAttached = this.getChildren(parentId);

		//Append the parent Container to the Fields AlreadyAttached, to ban it from attaching to itself : 
		fieldsAlreadyAttached[parentId] = {
			fieldId: parentId,
			fieldLabel: 'itself',
		}

		//Children to Return : 
		let childrenToAttach = this.getFieldsWithFilter(fieldsAlreadyAttached, tableSchemaName);
		
		return childrenToAttach;
	}

	/**
	 * Grab all of the fields for a table schema name, filtering out any fields passed in.
	 * 
	 * @static
	 * @param {object} fieldsToFilter - Object of fields to remove from the results
	 * @param {string} tableSchemaName - Optional - tableSchemaName to find fields from.  If none is given, get all fields.
	 * @returns {array}
	 * @memberof FieldUtils
	 */
	static getFieldsWithFilter(fieldsToFilter, tableSchemaName){
		//Returning Array 
		let filteredFields =[];

		// Make sure we've pulled all fields...
		if(FieldStore.allPulledFromDatabase()) {
			
			// Get the source fields.
			let fieldArray = (tableSchemaName 
				? FieldStore.getByTableSchemaName(tableSchemaName)
				: FieldStore.getAllArray());

			if(Array.isArray(fieldArray)) {
				// Loop over all the fields...
				fieldArray.forEach(field => {
					//If the field is not in the fields to filter
					if(!fieldsToFilter[field.recordId]) {
						//Parse the FieldSettings 
						let fieldSettings = FieldStore.getSettings(field.recordId),
							name = '[ No Field Label ]';

						// If there is a field label
						if(fieldSettings.fieldLabel){
							if(!tableSchemaName) {
								name = field.tableSchemaName + ' - ' + fieldSettings.fieldLabel;
							} else {
								name = fieldSettings.fieldLabel;
							}
						}

						// Append the filtered field
						filteredFields.push({
							name: name,
							value: field.recordId,
							fieldType: field.fieldType
						});
					}
				});
			}
		}
	
		return filteredFields;
	}
	
	/**
	 * Get the Default TableSchemaName for a Child 
	 * 
	 * @param {string} recordId 
	 * @returns 
	 * @memberof FieldUtils
	 */
	static getDefaultChildTableSchemaName(recordId){

		if(!recordId){
			console.warn('Cannot get Default TableSchemaName for Child of recordId:', recordId);
			return undefined;
		}	
		
		let fieldSettings = FieldStore.getSettings(recordId),
			fieldObj = FieldStore.get(recordId) || {},
			tableSchemaName = undefined;
		// @todo DONT hard code to .query - that should be a setting.
		if (fieldSettings && fieldSettings.query) {
			let queryJSON = fieldSettings.query,
				queryObj = null;
				queryObj = ObjectUtils.getObjFromJSON(queryJSON);
		
			if(queryObj && queryObj.nodes && queryObj.returnNode !== '') {
				let returnNodeId = queryObj.returnNode,
					returnNode = queryObj.nodes.find(node => {
						return node.nodeId === returnNodeId;
					});
				tableSchemaName = returnNode.tableSchemaName;
			} 
		}  else {
			tableSchemaName = fieldObj.tableSchemaName || ContextStore.getTableSchemaName();
		}
		
		return tableSchemaName;
	}

	/**
	 * Utility function to find the field on a table with a given role.
	 * Where multiple fields with a given role are found, the first one found will be used.
	 * 
	 * @param {string} tableSchemaName The tableSchemaName on which the page is
	 * @param {string} role The role of the page being searched for
	 */
	static getFieldIdByRole(tableSchemaName, role) {
		let fields = FieldStore.getByTableSchemaName(tableSchemaName) || [];
		let field = fields.find(candidateField => {
			let settings = FieldSettingsStore.getSettings(candidateField.recordId) || {};
			let roles = settings.roles ? settings.roles.split(',') : [];
			return roles.indexOf(role) > -1;
		});
		return field && field.recordId ? field.recordId : '';
	}

	/**
	 * Utility function to find the fields on a table with a given role.
	 * Returns an array of all fields with that role.
	 * 
	 * @param {string} tableSchemaName The tableSchemaName on which the page is
	 * @param {string} role The role of the page being searched for
	 */
	static getFieldIdsByRole(tableSchemaName, role) {
		let fields = FieldStore.getByTableSchemaName(tableSchemaName) || [];
		let fieldArr = fields.filter(candidateField => {
			let settings = FieldSettingsStore.getSettings(candidateField.recordId) || {};
			let roles = settings.roles ? settings.roles.split(',') : [];
			return roles.indexOf(role) > -1;
		});
		return fieldArr;
	}

	/**
	 * Calculate and Return the most recent settings history details as well as
	 * a count of history details.
	 * @param {string} settingId 
	 * @param {string} fieldId 
	 * @param {string} parentRecordId 
	 * @param {string} parentTableSchemaName 
	 * @return {Object}
	 * @return {Object}.historyObj
	 * @return {integer}.historyCount
	 */
	static getSettingHistoryDetails(settingId, fieldId, parentRecordId, parentTableSchemaName) {

		// FieldStore.getSettingHistory(fieldId, fieldObj.fieldSchemaName)

		// // Generate the history string for settings with 'high' quality or length greater than 1.
		// if(fieldSettingHistory && fieldSettingHistory.length) {

		// 	// Work with the full array...
		// 	var lastHistoryLabel = '';
		// 	let fsPatterns = fieldSettingHistory.map(fsh => { 
		// 		let label = '';
		// 		// If this history entry was made with a pattern
		// 		if(fsh.patternId) { 
		// 			// See if we still have the pattern
		// 			let patternObj = PatternStore.get(fsh.patternId);
		// 			if(patternObj) { 
		// 				// And get it's name
		// 				label = patternObj.displayName;
		// 			} else {
		// 				// Otherwise, get "Discontinued Pattern" - TBD @ TL and LP.
		// 				label = 'Discontinued Pattern';
		// 			}
		// 		} else {
		// 			// If there is a history entry with*out* a patternId, then it was entered manually
		// 			// by the user.. which we call "Custom" - TBD @ TL and LP.
		// 			label = 'Custom';
		// 		}

		// 		// Compress consecutive uses of the same pattern label.
		// 		if(label !== lastHistoryLabel) {
		// 			lastHistoryLabel = label;
		// 			return label;
		// 		}
		// 		return undefined;
		// 	})
		// 	// Remove the undefined's from the array
		// 	.filter(historyVal => {
		// 		if(historyVal) {
		// 			return historyVal;
		// 		} else {
		// 			return false;
		// 		}
		// 	});

		// 	// Cut off the last one, and work with it:
		// 	recentFSH = fieldSettingHistory.pop();

		// 	//Grab the Most Recent pattern for the setting
		// 	if(fsPatterns){
		// 		// Check for high quality setting, else check whether patterns length is more than 1
		// 		if(recentFSH.valueQuality === 'high'){
		// 			mostRecentPattern = fsPatterns.pop();
		// 		} else if(fsPatterns.length > 1 ){
		// 			mostRecentPattern = fsPatterns.pop();
		// 			// Check whether Most Recent pattern is custom and set it to empty
		// 			if(mostRecentPattern.trim() === 'Custom'){
		// 				mostRecentPattern = '';
		// 			}
		// 		}
		// 	}

		// let sortOrder = 0;

		// switch(setting.fieldHistory.historyObj.valueQuality) {
		// 	default:
		// 		sortOrder = 0;
		// 	break;
		// 	case 'high':
		// 		sortOrder = 1;
		// 	break;
		// 	case 'low':
		// 		sortOrder = 3;
		// 	break;
		// 	case 'custom':
		// 		sortOrder = 2;
		// 	break;
		// }

		return {
			historyObj: {
				valueQuality: undefined
			},
			historyCount: 0,
			order: 0
		}
	}

	/**
	 * Lookup the array of settings that children of the field would have.
	 * @param {string} parentFieldId 
	 * @return {Array}
	 */
	static getChildSettings(parentFieldId) {
		let parentObj = FieldStore.get(parentFieldId) || {};
		let parentFieldType = FieldTypeStore.get(parentObj.fieldType) || {};

		if (parentFieldType.settingsForChildFields) {
			if(Array.isArray(parentFieldType.settingsForChildFields)) {
				return parentFieldType.settingsForChildFields;
			} else if (typeof parentFieldType.settingsForChildFields === 'string') {
				return ObjectUtils.getObjFromJSON(parentFieldType.settingsForChildFields);
			}
		}
		return [];
	}

	/**
	 * Get the default additional settings to show on a field if its parent component id can't  be found
	 * 
	 * @returns {Array} Array of {recordId} objects
	 */
	static getDefaultSettings() {
		return [
			{'recordId': '4a80da03-821a-4e1b-a59a-78e5b71018ac'}, // Label Position
			{'recordId': '763ebd7e-2555-47df-845d-532353a8f4da'}, // Required
			{'recordId': 'ef33f299-8440-4605-b0ba-e19d23468d88'}, // View Render Variant
			{'recordId': 'c5429518-d94f-4d03-a917-68a4bc551bdf'} // Edit Render Variant
		];
	}

	/**
	 * Converts old attachment-ID-keyed child configurations to be keyed  by
	 * both attachment ID and field (as per ticket 31103)
	 * @param {object} childConfigurations The object to convert
	 * @param {array} attachedFields The corresponding attached fields
	 * @returns 
	 */
	static convertChildConfig(childConfigurations, attachedFields) {

		// Start with page settings for child fields
		let settingsForChildFields = PageUtils.getChildSettings().map(({recordId}) => recordId);
		// Now add in any possible settings for child fields from any parent fields
		FieldTypeStore.getAllArray().forEach(ft => {
			if(ft.settingsForChildFields) {
				let childSettingsForFieldType = [];
				if(Array.isArray(ft.settingsForChildFields)) {
					childSettingsForFieldType = ft.settingsForChildFields;
				} else if (typeof ft.settingsForChildFields === 'string') {
					childSettingsForFieldType = ObjectUtils.getObjFromJSON(ft.settingsForChildFields);
				}
				childSettingsForFieldType.forEach(({recordId}) => {
					if(settingsForChildFields.indexOf(recordId) === -1) {
						settingsForChildFields.push(recordId);
					}
				});
			}
		});

		// let keysToDelete = {};


		attachedFields.forEach(({attachmentId, recordIds, recordId}) => {
			// If there is no attachmentId we can just skip it; it doesn't need this update
			if(!attachmentId) {
				return;
			}
			// If there are no overrides for this attachment ID, we also don't need to do anything
			let attachmentChildConfig = childConfigurations[attachmentId];
			recordIds = recordId && !recordIds ? [recordId] : recordIds;
			let settingKeysToDelete = {};
			recordIds.forEach(recordId => {

				// For any lingering overrides keyed by record ID
				let oldAttachmentChildConfig = childConfigurations[recordId];
				let localSettingKeysToDelete = {};
				if(!attachmentChildConfig && !oldAttachmentChildConfig) {
					return;
				}
				// if(childConfigurations[recordId]) {
				// 	keysToDelete[recordId] = true;
				// }
				let settingsForField = {};
				let field = FieldStore.get(recordId);
				let fieldTypeObj = field && FieldTypeStore.get(field.fieldType);
				let fieldTypeSettings = {};
				if(fieldTypeObj && fieldTypeObj.settings) {
					fieldTypeObj.settings.forEach(({recordId}) => {
						let fieldSettingObj = FieldStore.get(recordId);
						if(fieldSettingObj) {
							fieldTypeSettings[fieldSettingObj.fieldSchemaName] = true;
						}
					});
				}
	
				// Settings for the FT object specifically
				Object.keys(fieldTypeSettings).forEach(settingSchemaName => {

					// If override keyed by record ID
					if(oldAttachmentChildConfig && oldAttachmentChildConfig[settingSchemaName]) {
						localSettingKeysToDelete[settingSchemaName] = true;
						settingsForField[settingSchemaName] = oldAttachmentChildConfig[settingSchemaName];
					}
					// If override keyed by attachment ID
					if(attachmentChildConfig && attachmentChildConfig[settingSchemaName]) {
						settingKeysToDelete[settingSchemaName] = true;
						settingsForField[settingSchemaName] = attachmentChildConfig[settingSchemaName];
					}
				});

				// Settings for child fields
				settingsForChildFields.forEach(recordId => {
					let fieldSettingObj = FieldStore.get(recordId);
					if(fieldSettingObj && oldAttachmentChildConfig && oldAttachmentChildConfig[fieldSettingObj.fieldSchemaName]) {
						let settingSchemaName = fieldSettingObj.fieldSchemaName;
						localSettingKeysToDelete[settingSchemaName] = true;
						settingsForField[settingSchemaName] = oldAttachmentChildConfig[settingSchemaName];
					}
					if(fieldSettingObj && attachmentChildConfig && attachmentChildConfig[fieldSettingObj.fieldSchemaName]) {
						let settingSchemaName = fieldSettingObj.fieldSchemaName;
						settingKeysToDelete[settingSchemaName] = true;
						settingsForField[settingSchemaName] = attachmentChildConfig[settingSchemaName];
					}
				});

				if(Object.keys(settingsForField).length) {
					// Don't override existing values
					childConfigurations[attachmentId + '-' + recordId] = Object.assign(settingsForField, childConfigurations[attachmentId + '-' + recordId]);
				}

				Object.keys(localSettingKeysToDelete).forEach(settingSchemaName => {
					delete oldAttachmentChildConfig[settingSchemaName];
				});
				if(oldAttachmentChildConfig && !Object.keys(oldAttachmentChildConfig).length) {
					delete childConfigurations[recordId];
				}
			});
			Object.keys(settingKeysToDelete).forEach(settingSchemaName => {
				delete attachmentChildConfig[settingSchemaName];
			});
		});
	
		return childConfigurations;
	}
}

export default FieldUtils;