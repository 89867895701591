import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AdminSettingsActions } from '../../../actions';
import { MetadataStore } from '../../../stores';
import { MonacoDiffEditor } from 'react-monaco-editor';

/**
 * Component for CodeReview
 * 
 * @class CodeReview
 * @extends { Component }
 */
export default class CodeReview extends Component {
	/**
	 * Creates an instance of CodeReview
	 * 
	 * @param {Object} props
	 * @constructor
	 */
	constructor(props) {
		super(props);

		this.state = {
			newValue: props.newCode
		}

		this.onClose = this.onClose.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}
	/**
	 * @static
	 * @returns {Array of Object}
	 */
	static getStores() {
		return [ MetadataStore ];
	}

	/**
	 * Close the modal
	 */
	onClose() {
		AdminSettingsActions.closeModal('codeReview');
	}

	/**
	 * When value changes it saves and updates the changes in the variant modal
	 * 
	 */
	onSave() {
		let { newValue } = this.state;
		let { onChange } = this.props;

		onChange({ target: { value: newValue } })
		this.onClose();
	}

	/**
	 * Try to catch a Control-S or Command-S.
	 */
	onKeyDown(event) {
		// Check if keys pressed are ctrl+s
		if(event.ctrlKey && event.key === 's') {
			event.preventDefault();
			this.onSave();
		}
		// when on MAC
		if(event.metaKey && event.key === 's') {
			event.preventDefault();
			this.onSave();
		}
	}

	/**
	 * Render the CodeReview
	 * 
	 */
	render() {
		let { originalCode, newCode } = this.props;
		return(
			<div className="code-review-modal modal-dialog modal-lg show" onKeyDown={this.onKeyDown}>
				<div className="modal-content container">
					<div className="modal-header justify-content-end cd-bg-2 align-items-center">
						<h1 className="modal-title align-self-center mr-auto">
							Code Review
						</h1>
						{/* Save button */}
						<button 
							key="submit" 
							className="btn btn-primary" 
							aria-label="Save" 
							onClick={this.onSave}>
							Save
						</button>
						{/* Close */}
						<button 
							type="button" 
							className="close float-right m-0 align-self-center p-0" 
							aria-label="Close"
							onClick={this.onClose} 
						>
							&times;
						</button>
					</div>
					<div className="modal-body container d-flex flex-column">
						<div className="row mb-2">
							<h4 className="col-6 text-center">Original Variant Code</h4>
							<h4 className="col-6 text-center">New Variant Code</h4>
						</div>
						<div id="flex-container" className="row">
							{/* <input type="textarea" name="codeReviewLeft" value={originalCode} />
							<input type="textarea" name="codeReviewRight" value={newCode} /> */}
							<MonacoDiffEditor
								key="CodeCompare"
								language="javascript"
								theme="vs-dark"
								width="1200"
								height="700"
								original={originalCode}
								value={newCode}
								options={{
									selectOnLineNumbers: true,
									renderSideBySide: true
								}}
								onChange={(value) => { 
									this.setState({'newValue': value});
								}}
								/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	CodeReview.displayName = 'CodeReview';
	CodeReview.propTypes = {
		originalCode: PropTypes.string,
		newCode: PropTypes.string,
		onChange: PropTypes.func,
		onSave: PropTypes.func
	};
}