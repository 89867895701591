import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * Component that selects relationship cardinality
 */
export default class CardinalitySelector extends Component {
  /**
   * Renders CardinalitySelector
   */
  render() {
    let {className, onChange, required, fieldSchemaName, value} = this.props;

    className = (className ? 'form-control ' + className : 'form-control');

    return (
      <select required={required} id={fieldSchemaName} className={className} value={value || ''} onChange={onChange} >
        {value ? null : <option value="">Select Cardinality</option>}
        <option value="1">Has Only One</option>
        <option value="m">Has Many</option>
      </select>
    );
  }
}

if ('development' === process.env.NODE_ENV) {
  CardinalitySelector.propTypes = {
    onChange: PropTypes.func,
	fieldSchemaName: PropTypes.string.isRequired,
	required: PropTypes.bool,
    value:
    PropTypes.string,
  };
}
