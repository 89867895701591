/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';
import {
	AdminSettingsActions,
	InterfaceActions,
	MetadataActions,
	LogicFunctionActions

} from '../../../actions';
import { AdminSettingsStore, ContextStore, FieldStore, MetadataStore } from '../../../stores';

// Utils
// import LogicUtils from '../../../utils/logic-utils';
import {
	UIUtils,
	ObjectUtils,
	BlocklyUtils
} from '../../../utils';

/**
 * 
 */
class APIConfigSettingsChooser extends Component {
	/**
	 * Creates instance of APIConfigSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._getSettingsList = this._getSettingsList.bind(this);
		this._onDeleteHandler = this._onDeleteHandler.bind(this);
		this._onSaveHandler = this._onSaveHandler.bind(this);
		this._onResetHandler = this._onResetHandler.bind(this);
	}
	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, FieldStore, MetadataStore];
	}

	/**
	 * Calculate the current state.
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} State!
	 */
	static calculateState(prevState, props) {
		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = AdminSettingsStore.getTableSchemaName();
		let componentObj = MetadataStore.get(recordId, 'apiconfig') || {};
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let componentSettings = [];

		// Default Settings
		let apiSettings =
			[
				{ recordId: 'b3716b9e-9eb2-42bf-ab4f-4adeb0782f71',
				  sortOrder: 1, required: true  }, // URI
				{ recordId: 'b3c3f007-6eff-4ddb-8428-5e5a64e40888',
				  sortOrder: 2, required: true  }, // Method
				{ recordId: 'eecf7a7d-0bee-4451-bca3-f535e7179941',
				  sortOrder: 3  }, // Querystring Variables
				{ recordId: '949ac609-9868-479b-9a45-3447c5c7a0ae',
				  sortOrder: 4  }, // Header Variables
				{ recordId: 'd05b9c32-7b7b-4080-9901-a29259812a6c',
				  sortOrder: 7 }, // Logic
			];

		// If we have a method and its NOT GET
		let apiObj = MetadataStore.get(recordId, 'apiconfig');
		if(apiObj && apiObj.method && apiObj.method.length && apiObj.method !== 'GET') {
			apiSettings.push({  // Body Type
				recordId: 'e5120137-fd8f-4e97-be4f-4ace2ee246a2',
				sortOrder: 5  });
			if(apiObj.bodyType && apiObj.bodyType !== 'raw text') {
				apiSettings.push({ // Body Variables
					recordId: 'c5d949d3-8e7f-491a-ba29-ee8c871ef090',
					sortOrder: 6
				})
			}
		}

		let hasMissingRequiredSettings = false;

		apiSettings.forEach(apiSetting => {
			let settingId = apiSetting.recordId;
			let settingObj = FieldStore.get(settingId) || {};
			let settingSchemaName = settingObj.fieldSchemaName;

			// Empty/No Value values.
			let valueObj = {};
			if (componentObj[settingSchemaName]) {
				valueObj.value = componentObj[settingSchemaName];
			}

			// Check to see if this setting is required, and has no value
			if(apiSetting.required && (!valueObj.value || valueObj.length === 0)) {
				hasMissingRequiredSettings = true;
			}

			componentSettings.push({
				// Basics
				fieldId: settingId,
				fieldObj: settingObj,

				// Label, Value and Sorting..
				label: settingObj.fieldLabel,
				valueObj: valueObj,
				sortOrder: apiSetting.sortOrder,

				// Used for styling
				settingSelected: (selectedSetting === settingSchemaName),
				required: apiSetting.required
			});
		});

		// Sort the results by setting name
		componentSettings.sort(function (a, b) {
			if (a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			return 1;
		});

		return {
			componentSettings: componentSettings,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			hasMissingRequiredSettings: hasMissingRequiredSettings,
			settingsHidden: AdminSettingsStore.getSettingsListHidden(),
		}
	}

	/**
	 * Render the compenent
	 * @returns JSX
	 */
	render() {
		let { componentSettings, hasMissingRequiredSettings } = this.state;

		if (!componentSettings.length) {
			return (
				<div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
				</div>
			);
		}

		let settingsList = this._getSettingsList(componentSettings); // component settings

		let buttons = [
			<button id={'apiconfigSave'} key="submit" className="btn btn-primary btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={this._onSaveHandler}>Save</button>,
			<button id={'apiconfigReset'} key="reset" className="btn btn-warning btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Reset" onClick={this._onResetHandler}>Reset</button>,
			<button key="delete" className="btn btn-danger btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Delete" onClick={this._onDeleteHandler}>Delete</button>
		];

		let requiredMarker = null;
		if(hasMissingRequiredSettings) {
			requiredMarker = <div className="required-marker mx-4 d-flex"><i className="fa fa-circle d-flex align-items-center" aria-hidden="true"></i> <h4 className="ml-2">Required</h4></div>;
		}

		if(this.props.disabledRetailRestrictions) { 
			buttons = [<button 
				key="submit" 
				className="btn btn-success btn-lg setting-list-button mr-3 ml-1" 
				aria-label="Upgrade to use API's"
				style={{ width: '100%'}}
				onClick={this.props.onUpgradeClick}>
				Upgrade to use API's
			</button>]
		}

		let componentIcon = ContextStore.getUrlMedia() + '/icon-logic.svg';

		return [
			<div key="settings" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">
					<li className="settings-list-header">
						<div className="d-flex">
							<img height="22" width="22" style={{ marginTop:'0.40rem' }} className="mr-2" src={componentIcon} alt="" />
							<h3>API Settings</h3>
						</div>
					</li>
					{ settingsList }
					{ requiredMarker }
				</ul>
			</div>,
			<div key="buttons" className="btn-wrapper appearance-btn-wrapper">
				{buttons}				
			</div>
		];
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	_getSettingsList(settingsArr) {

		let { fieldType, recordId, tableSchemaName } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label';
			if (setting.settingSelected) {
				labelClassNames += ' selected';
			}

			let componentName = FieldStore.getDefaultVariantComponentName(
				setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			componentProps.dataRecordId = recordId;
			componentProps.dataTtableSchemaName = tableSchemaName;

			// These props will only be used by the fake table setting
			componentProps.customExpressionResult = setting.customExpressionResult;
			componentProps.renderAsHTML = setting.renderAsHTML;

			let valueDisplay = <h5 className={`settings-list-value ${componentName}`}>
					{(componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null)}
				</h5>;
			// Checkbox for the value for logic
			if(setting.fieldId === 'd05b9c32-7b7b-4080-9901-a29259812a6c') {
				valueDisplay = componentProps.value && componentProps.value.includes('js') 
					? <span className='fa fa-check'><span className='sr-only'>(Has Logic)</span></span> 
					: null;
			}

			let requiredMarker = null;
			if(setting.required && (!setting.valueObj.value || setting.valueObj.value.length === 0)) {
				requiredMarker = <div style={{ left: '-14px', top: '2px' }} className="required-marker position-absolute"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
			}

			return (<li key={setting.fieldId} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this._onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames}>{requiredMarker}<h4>{setting.label}</h4></div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								{valueDisplay}
							</div>
							{setting.valueObj.source ?
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}>
									{setting.valueObj.source}
								</div>
								: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}

	/**
	 * _onDeleteHandler - Deletes the object from the store and the database.
	 *
	 * @param  {object} event
	 */
	_onDeleteHandler(event) {
		let { recordId } = this.state;

		let confirmRemove = confirm('Are you sure you want to delete this API?');
		if(confirmRemove) {
			// Display notification to user
			let id = InterfaceActions.stickyNotification({ 'level': 'warning', 'message': 'Deleting API...' });
			// Push to database
			MetadataActions.deleteFromDatabasePromise(recordId, 'apiconfig').then(() => {
				let updateNotification = InterfaceActions.stickyNotification({
					'message': 'Updating API endpoints...',
					'level': 'info'
				});
				return new Promise(function(resolve, reject) {
					fetch(ContextStore.getBasePath() + '/gw/updateRouter', {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json; charset=UTF-8'
						}
					}).then(function (response) {
						InterfaceActions.clearStickyNotification(updateNotification);
						InterfaceActions.clearStickyNotification(id);
						UIUtils.closeSettingsPanel();
						MetadataActions.deleteFromStore(recordId, 'apiconfig');
						resolve();
					}).catch(function (error) {
						InterfaceActions.notification({
							level: 'error',
							message: 'API endpoint update failed. ' +
								'Review the console for more information. ' +
								'You may navigate to /gw/updateRouter to update your router manually.'
						});
						console.error('API endpoint update failed:', error);
						reject(error);
					});
				});	
			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to delete API' });
				console.error('Unable to delete API:', error);
			})	
		}
	}

	/**
	 * _onResetHandler - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	_onResetHandler(event) {
		event.preventDefault();
		let { recordId } = this.state;
		let currentObj = MetadataStore.get(recordId, 'apiconfig');
		if(currentObj && currentObj.logic) {
			let logicObj = ObjectUtils.getObjFromJSON(currentObj.logic);
			// Handle converting logicFunctionsUsed into an array
			let logicFunctionIdsArr =[]; 
			let logicFunctionIds = logicObj.logicFunctionsUsed;
			if (logicFunctionIds) {
				if(Array.isArray(logicFunctionIds)) {
					logicFunctionIdsArr = logicFunctionIds;
				} else {
					logicFunctionIdsArr = logicFunctionIds.split(',');
				}
			}
			if (logicFunctionIdsArr && logicFunctionIdsArr.length) {
				InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting all functions used within this trigger to their saved values...' });
				logicFunctionIdsArr.forEach(logicFunctionId => {
					LogicFunctionActions.pullFromDatabase(logicFunctionId);
				});
			}
		}
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting API...' });
		AdminSettingsActions.onSettingChange('', ''); // Temporary workaround pending further reset work
		// Pull from database, therefore resetting it
		MetadataActions.pullFromDatabase(recordId, 'apiconfig');
	}

	/**
	 * _onSaveHandler - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	_onSaveHandler(event) {
		let { recordId } = this.state;
		let apiObj = MetadataStore.get(recordId, 'apiconfig');
		let valid = true;

		if(!apiObj.method || apiObj.method.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Method is required for an API.' });
			AdminSettingsActions.onSettingChange('method', 'b3c3f007-6eff-4ddb-8428-5e5a64e40888');
		}
		if(!apiObj.uri || apiObj.uri.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'URL is required for an API.' });
			AdminSettingsActions.onSettingChange('uri', 'b3716b9e-9eb2-42bf-ab4f-4adeb0782f71');
		}

		if(valid) {
			let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': 'Updating API...' });
			let dirtyApiObj = MetadataStore.get(recordId, 'apiconfig', true);

			let logicObj = ObjectUtils.getObjFromJSON(dirtyApiObj.logic);
			if(logicObj && logicObj.blocklyxml) {
				delete logicObj.forceDirty;
				// Regenerate the JS
				let params = {
					defaultToNull: true,
					memUse: logicObj.memUse ? logicObj.memUse : 'l',
					includeJs: true
				};

				
				BlocklyUtils.saveAutomationFromWorkspaceV2(BlocklyUtils.getWorkspaceFromXml(logicObj.blocklyxml), params, (blocklyValueObj) => {
					dirtyApiObj.logic = JSON.stringify(blocklyValueObj);
					MetadataActions.pushToStore(recordId, 'apiconfig', dirtyApiObj);
				});
			}

			// Push to database
			MetadataActions.pushToDatabasePromise(MetadataStore.get(recordId, 'apiconfig', true), 'apiconfig').then(() => {
				let updateNotification = InterfaceActions.stickyNotification({
					'message': 'Updating API endpoints...',
					'level': 'info'
				});
				return new Promise(function(resolve, reject) {
					fetch(ContextStore.getBasePath() + '/gw/updateRouter', {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json; charset=UTF-8'
						}
					}).then(function (response) {
						InterfaceActions.clearStickyNotification(updateNotification);
						InterfaceActions.clearStickyNotification(id);
						resolve();
					}).catch(function (error) {
						InterfaceActions.notification({
							level: 'error',
							message: 'API endpoint update failed. ' +
								'Review the console for more information. ' +
								'You may navigate to /gw/updateRouter to update your router manually.'
						});
						console.error('API endpoint update failed:', error);
						reject(error);
					});
				});	
			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save API' });
				console.error('Unable to save API Config:', error);
			})
		}
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 */
	_onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Toggle to select and deselect the Setting
		if(settingSchemaName === 'logic') {
			// If logic is already selected
			if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
				// Expand the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
			} else {
				// Hide the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		} else {
			// Expand the Settings Panel
			AdminSettingsActions.onSettingsListHideChange(false);
			if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
				AdminSettingsActions.onSettingChange('', '');
			} else {
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		}
	}
}
const container = Container.create(APIConfigSettingsChooser);
export default container;