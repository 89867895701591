import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Components
import FieldComponentsContainer from './engineering/field-components.react';
import ThemeTemplates from './engineering/theme-templates.react';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';

/**
 * Displays dashboard tabs
 */
class EngineeringDashboardTabs extends Component {
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		return {
			'selectedTab' : AdminSettingsStore.getActiveDashboardTab(),
		};
	}

	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let { 
			onTabSelect,
		} = this.props;
		let { 
			selectedTab,
		} = this.state;

		let activeTabComponent = null;
		switch(selectedTab) {
			default:
			case 'engineering-field-components': {
				activeTabComponent = <FieldComponentsContainer key="tab-content"/>;
				break;
			}
			case 'engineering-theme-template': {
				activeTabComponent = <ThemeTemplates key="tab-content"/>;
				break;
			}
		}

		return (
			<div style={{ flex: 1, overflowY: 'hidden' }} className="d-flex flex-column">
				<div className="cd-dashboard-tabs logic" key="tabs">
					<div className="header-tab-item-container engineering">
						<h5 className={`header-tab-item ${selectedTab === 'engineering-field-components' || selectedTab === undefined ? 'selected-tab' : ''}`} onClick={() => onTabSelect('engineering-field-components')}>Field Variants</h5>
						<h5 className={`header-tab-item ${selectedTab === 'engineering-theme-template' ? 'selected-tab' : ''}`} onClick={() => onTabSelect('engineering-theme-template')}>Theme Templates</h5>
					</div>
				</div>
				{activeTabComponent}
			</div>
		);
	}
}
const container = Container.create(EngineeringDashboardTabs);
export default container;