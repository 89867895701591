import React, { Component } from 'react'
import Helmet from 'react-helmet'
import ContextStore from '../stores/context-store';

class Loading extends Component {
  /**
   * render - renders wrapper
   *
   * @returns JSX
   */
  constructor(props) {
	super(props);

	// used to delay the loading screen from rendering
	this.state = {
		show: false
	}

	this.timeout = React.createRef(null);
  }

  componentDidMount() {
	this.timeout.current = setTimeout(() => {
		this.setState({show: true})
	}, 1000);
  }

  componentWillUnmount() {
	if(this.timeout.current) {
		clearTimeout(this.timeout.current);
	}
  }

  render() {
    return (
		<div id='loading-container' className='main-window'>
			{ this.state.show && 
				<div className='bg-panel loading'>
					<div id='loading-main'>
						<Helmet title="Loading" />
						<img className='loading-image' src={ContextStore.getUrlMedia() + "/loading-helix.gif"} alt="loading animation"/>
						<h1 className='loading-title'>{this.props.title}</h1>
						<h3 className='loading-subject'>{this.props.loadingSubject}</h3>
					</div>
				</div>
  			}
		</div>
    );
  }
}

export default Loading;
