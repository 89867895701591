import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { RelationshipActions } from '../../../actions';
import { AdminSettingsStore, FieldStore, RelationshipStore } from '../../../stores';

// Components;
import FieldTypeSettingsUI from '../../fieldtype-settings-ui.react'

/**
 * Right panel displaying Field settings
 */
class RelationshipSetting extends Component {
	constructor(props) {
		super(props)
		this._onSettingChange = this._onSettingChange.bind(this);
	}
	
	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
	 return [ AdminSettingsStore, FieldStore, RelationshipStore ]; 
	}

	/**
	 * Calculate the current state of the component
	 * @static
	 *
	 * @param  {Object} prevState Previous State
	 * @param  {Object} props     Previous Props
	 * @return {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId =  AdminSettingsStore.getRecordId();
		let settingRecordId = AdminSettingsStore.getSettingRecordId();
		let settingObj = FieldStore.get(settingRecordId);
		let value = '';
		let isNew = false;

		// Pull the value from the MD Store
		if(settingObj){
			let settingSchemaName = settingObj.fieldSchemaName;

			let relationshipObj = RelationshipStore.get(recordId);
			if(relationshipObj) {
				value = relationshipObj[settingSchemaName];
				isNew = relationshipObj['new'];
			}
		}

		return {
			settingObj: settingObj,
			settingRecordId: settingRecordId,
			recordId: recordId,
			value: value,
			isNew: isNew
		};
	}

	/**
	 * Save the value as a value is entered
	 * @param {Object} event
	 */
	_onSettingChange(event) {
		let { recordId, settingObj } = this.state;
		let settingSchemaName = settingObj.fieldSchemaName;
		let value = event.target.value;

		// Push the updated setting to the Field Store
		let relationshipObj = RelationshipStore.get(recordId);
		relationshipObj[settingSchemaName] = value;
		RelationshipActions.pushToStore(recordId, relationshipObj);
	}

	/**
	 * Render the component
	 * @return JSX
	 */
	render() {
		let {isNew, recordId, value, settingObj } = this.state;

		if (!settingObj || !settingObj.fieldSchemaName) {
			return (<div className="select-setting">
						<div className="select-setting-text-wrap">
							Select a Setting to configure on the left.
						</div>
					</div>);
		}

		let settingSchemaName = settingObj.fieldSchemaName;
		let settingFieldType = settingObj.fieldType;
		let descriptiveText = settingObj.descriptiveText;
		let label = settingObj.fieldLabel;

		// If we're NOT new, and on the relation schema name field, make it read only.
		if(!isNew && settingSchemaName === 'relationSchemaName') {
			settingObj.editVariant = settingObj.viewVariant;
			descriptiveText = "The name used in the database's schema for this field. Rarely needed, but important. <div><i>This is read only because Technical Names cannot be changed once the Relationship is made.</i></div>"
		}

		return (<div id="appearance-settings">
					<div className="appearance-settings-container mx-3">
						<h3 className='my-1'>
							{label}
						</h3>
						<div className="setting-global mt-0">
							<div className={"setting-wrapper setting-" + settingSchemaName}>
								<FieldTypeSettingsUI
									dataRecordId={recordId}
									recordId={recordId} 
									tableSchemaName='relationship'
									value={value} 
									setting={settingObj} 
									onChange={this._onSettingChange} 
									fieldType={settingFieldType} 
									label={label}
									skipDescriptiveText={true}
									/>
								{descriptiveText && 
									<small id={'description-' + this.props.renderId} className='form-text text-muted' dangerouslySetInnerHTML={{ __html: descriptiveText || '' }}></small>
								}
							</div>
						</div>
					</div>
				</div>);
	}
}
const container = Container.create(RelationshipSetting, {withProps: true});
export default container;