import React, {Component} from 'react';
import {Container} from 'flux/utils';
import {map} from 'lodash';
import {AdminSettingsStore, FieldTypeStore, MapStore, MetadataStore} from '../../../stores'
import {AdminSettingsActions, InterfaceActions, MapActions, MetadataActions}  from '../../../actions';
import VariantModalContainer from '../../settings/engineering/variant-modal-container.react';
import { FieldComponentCompiler, ObjectUtils } from '../../../utils';
import {GroupFilters} from '../../../constants';
import MapUtils from '../../../utils/map-utils';

/**
 * @class FieldComponentsMapContainer
 * @extends {Component}
 */
class FieldComponentsMapContainer extends Component {
	constructor(props){
		super(props);
		this.renderFieldType = this.renderFieldType.bind(this);
		this.editVariant = this.editVariant.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onChangeSearch = this.onChangeSearch.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onReset = this.onReset.bind(this);
	}
	/**
	 *  
	 * @static
	 * @returns {Array of objects}
	 * @memberof FieldComponentsMapContainer
	 */
	static getStores() {
		return [ AdminSettingsStore, FieldTypeStore, MapStore, MetadataStore ];
	}
	/**
	 * Returns the current State of the LogicMapContainer
	 * 
	 * @static
	 * @memberof LogicMapContainer
	 */
	static calculateState(prevState, props) {
		let fieldTypeVariantOverrides = (MetadataStore.allPulledFromDatabase('fieldtypevariant')
				? MetadataStore.getAllArray('fieldtypevariant')
				: []);

		return {
			ftvcomplete: MetadataStore.allPulledFromDatabase('fieldtypevariant'),
			ftcomplete: MetadataStore.allPulledFromDatabase('fieldtypes'),
			changedVariantCount: fieldTypeVariantOverrides.length,
			search: MapStore.getSearch(AdminSettingsStore.getActiveDashboardTab()),
			groupBy: MapStore.getGroupBy()
		};
	}

	/**
	 * Open the edit modal for this variant
	 * @param {string} fieldTypeRecordId Record Id of the Field Type.
	 * @param {string} componentName Name of the component to edit.
	 * @memberof FieldComponentsMapContainer
	 */
	editVariant(fieldTypeRecordId, componentName) {
		//Open the Modal 
		AdminSettingsActions.onRightPanelChange('');

		// Turn off main-window from everywhere that it exists alone
		// @TODO Remove this when we remove the last CD UX 1.0 Dialog - Engineering Field Components
		console.log('Remove the main-window class from all dialogs in src/components/dashboards/engineering/field-components.react.js as a temporary measure'); 
		Array.from(document.getElementsByClassName('main-window')).forEach(mwDiv => { 
			if(mwDiv.attributes && mwDiv.attributes.length === 1 ) { 
				mwDiv.className = ''; 
			}
		});

		AdminSettingsActions.openModal('field-component-modal', 
			<VariantModalContainer 
				fieldTypeRecordId={fieldTypeRecordId}
				componentName={componentName}
				onChange={this.onChange}
				onSave={this.onSave.bind(this,componentName, false)}
				onReset={this.onReset.bind(this, componentName, fieldTypeRecordId)}
				onClose={this.onClose}
			/>);
	}
		
	/**
	 * Saves the changes made
	 * 
	 * @param {String} componentName 
	 * @memberof FieldComponentsMapContainer
	 */
	onSave(componentName, close) {
		if(typeof close === 'undefined') {
			close = true;
		}

		let variantOverrideObj = MetadataStore.get(componentName, 'fieldtypevariant');
		let self = this;

		// Add a Version to the end of the reactCodeVersions and stringify before pushing to database.
		InterfaceActions.notification({level: 'success', message: 'Saving Variant...'});
		MetadataActions.pushToDatabasePromise(variantOverrideObj, 'fieldtypevariant').then(() => {
			InterfaceActions.notification({level: 'success', message: 'Variant saved.'});

			self.onChangeGroupBy({ target: { value: 'changed' } } );
	
			let reactCode = undefined;
			let forceHeight = undefined;
			if(variantOverrideObj.reactCodeVersions) {
				let reactCodeVersionArray = ObjectUtils.getObjFromJSON(variantOverrideObj.reactCodeVersions);
				if(Array.isArray(reactCodeVersionArray)) {
					reactCode = reactCodeVersionArray[reactCodeVersionArray.length - 1].reactCode;
					forceHeight = reactCodeVersionArray[reactCodeVersionArray.length - 1].forceHeight === '100%';
				}
			}
			if(!reactCode && variantOverrideObj.reactCode) {
				reactCode = variantOverrideObj.reactCode;
			}

			// Compile the Variant
			let compileResults = FieldComponentCompiler.compileComponent(reactCode);
	
			if(typeof compileResults === 'string') {
				console.error(compileResults);
				InterfaceActions.notification({level: 'error', message: 'Compiled failed, check your console =('});
			} else {
				// Overwrite in window.citDev[componentName]
				window.citDev[componentName] = compileResults;
				window.citDev.fields.components[componentName] = {
					isFullHeight: forceHeight,
					component: compileResults
				}
				InterfaceActions.notification({level: 'success', message: 'Compiled successfully'});

				if(close) {
					self.onClose();
				}
			}
		}).catch(error => {
			InterfaceActions.notification({level: 'error', message: 'Error saving variant, check your console =('});
			console.error(error);
		});
	}

	/**
	 * Deletes the local value and resets to old value
	 * 
	 * @param {string} componentName Name of the variant to reset.
	 * @param {string} fieldTypeRecordId Field Type Record to reset a variant in.
	 * @memberof FieldComponentsMapContainer
	 */
	onReset(componentName, fieldTypeRecordId) {
		let fieldTypeObj = MetadataStore.get(fieldTypeRecordId, 'fieldtypes');
		if(!fieldTypeObj) {
			console.error('Error reading Field Type Obj for:', fieldTypeRecordId);
			InterfaceActions.notification({level: 'error', message: 'Error finding Field Type, aborting.'});
			return;
		}

		let confirmReset = confirm('Are you sure you want to reset ' + componentName + ' on ' + fieldTypeObj.name + ' ? ');

		if(confirmReset) {
			let ftConfigJson = fieldTypeObj.configurationJson,
				ftConfigJsonObj = ObjectUtils.getObjFromJSON(ftConfigJson),
				originalFTVariant = ftConfigJsonObj.variants.filter(ftVariant => {
					return ftVariant.reactComponentName === componentName;
				});;
			
			if(!originalFTVariant || !originalFTVariant.length) {
				console.error('Error finding the', componentName, 'FieldType Variant in:', ftConfigJsonObj);
				InterfaceActions.notification({level: 'error', message: 'Error finding original component, aborting.'});
				return;
			}

			originalFTVariant = originalFTVariant[0];

			MetadataActions.deleteFromDatabasePromise(componentName, 'fieldtypevariant').then(() => {
				InterfaceActions.notification({level: 'success', message: 'Variant Reset.'});
			}).catch(error => {
				InterfaceActions.notification({level: 'error', message: 'Error saving variant, check your console =('});
				console.error(error);
			});

			// Compile the Variant
			let compileResults = FieldComponentCompiler.compileComponent(originalFTVariant.reactCode);

			// Overwrite in window.citDev[componentName]
			window.citDev[componentName] = compileResults;

			window.citDev[componentName + 'forcedHeight'] = originalFTVariant.forceHeight === '100%';

			// @todo figure out something to change to trigger change.
			this.onClose();
		}
	}
	/**
	 * Close modal
	 * 
	 * @memberof FieldComponentsMapContainer
	 */
	onClose() {
		AdminSettingsActions.closeModal('field-component-modal');
	}
	/**
	 * Searches by input
	 * 
	 * @param {any} event 
	 */
	onChangeSearch(event) {
		MapActions.search(event.target.value.toLowerCase(), AdminSettingsStore.getActiveDashboardTab());
	}
	/**
	 * Group the list by fieldtype or modified
	 * 
	 * @param {any} event 
	 */
	onChangeGroupBy(event) {
		MapActions.groupBy(event.target.value);
	}
	/**
	 * Renders each Fieldtype Group (Fieldtype Name and Fieldtype Variants)
	 * The fieldtype is already filtered by search and by groupBy 
	 * It determines if the the group should be close or open based on the flag set in the FieldtypeObject
	 * @param {object} fieldTypeObj - Fieldtype Group to render
	 * @param {number} index - Fieldtype Index
	 */
	renderFieldType(fieldTypeObj, index){
		
		let variantArray = fieldTypeObj.variants ? fieldTypeObj.variants : [];
		let variantList = [];

		// Build the array of Variants JSX Elements
		variantArray.forEach((variant, index) => {
			let variantOverwrite = variant.override;
			variantList.push(
				<li key={index} className="table-name-item">
					<div className="row">
						<div className="ml-auto col-9">
						{ variantOverwrite ? 
							<span className="value-changed" >
								<span><i className="fa fa-asterisk fa-1 selection-marker" aria-hidden="true"></i><span className="sr-only">(Overridden)</span></span>
								<span className="notlink">{variant.name}</span>
							</span> 
							: 
							<span className="notlink">
								{variant.name}
							</span> }
						</div> 
						<div className="col-2 text-center">
							<span className="fa fa-pencil" 
							onClick={this.editVariant.bind(this, fieldTypeObj.recordId, variant.reactComponentName)}>
							</span>
						</div> 
					</div>
				</li>
			);
		});

		/* Show only when fieldTypeName and variantList is present */
		if(fieldTypeObj.name && variantArray && variantArray.length){
			// Build the Fieldtype JSX Group
			return (
				<li key={'#fieldtype-'+index} className="role-group-item">
					<div className="back-strike role-group-name" data-target={'#fieldtype-'+index} data-toggle="collapse">
					<div className={'role-group-name-arrow'}></div>
							{ fieldTypeObj.hasOverrides ? 
								<a className="value-changed">
									<span className="changed-ft-marker">
										<i className="fa fa-asterisk fa-1" aria-hidden="true"></i>
									</span>
									<span>{fieldTypeObj.name}</span>
								</a>
								: 
								<a>
								{fieldTypeObj.name}
								</a>
							}
							<small className="float-right">({variantArray.length})</small>
					</div>
					{
						fieldTypeObj.hasOverrides ?
						<ol id={'fieldtype-'+index} className="collapse show variant-list">
							{variantList}
						</ol>
						:
						<ol id={'fieldtype-'+index} className="collapse variant-list">
							{variantList}
						</ol>
					}
				</li>
			);
		} else {
			return null;
		}

	}
	
	/**
	 * 
	 * @returns 
	 * @memberof FieldComponentsMapContainer
	 */
	render() {
		let { search, groupBy } = this.state,
			fieldTypes = (MetadataStore.allPulledFromDatabase('fieldtypes')
				? MetadataStore.getAllArray('fieldtypes')
				: []),
			fieldTypeVariantOverrides = (MetadataStore.allPulledFromDatabase('fieldtypevariant')
				? MetadataStore.getAll('fieldtypevariant')
				: []),
			fieldTypeVariantOverrideNames = Object.keys(fieldTypeVariantOverrides), 
			filteredFieldTypes = MapUtils.filteredFieldTypes(fieldTypes, fieldTypeVariantOverrideNames, search, groupBy);
	
		return (
			<div id="field-component-map__content" className="map">
				<header className="cd-container">
					<h3>Field Components</h3>
					<div className="row justify-content-around">
						<div className="col-5 form-group">
							<label className="form-control-label">Group By...</label>
							<select className="form-control" value={groupBy} onChange={this.onChangeGroupBy}>
								<option value={GroupFilters.CHANGED}>Changed</option>
								<option value={GroupFilters.FIELD_TYPE}>Fieldtype</option>
							</select>
						</div>
						<div className="col-6 form-group">
							<label className="form-control-label">Search By...</label>
							<input className="form-control" type="text" value={search} onChange={this.onChangeSearch}/>
						</div>
					</div>
				</header>
				{ (MetadataStore.allPulledFromDatabase('fieldtypes') && MetadataStore.allPulledFromDatabase('fieldtypevariant')
					? <div className="list-content-wrapper">
						<ol>
						{/* Render all the fieldTypes from the sorted list*/}
						{/*fieldTypes.map(this.renderFieldType)*/}
						{map(filteredFieldTypes, this.renderFieldType)}
						</ol>
					</div>
					: <h5 className="text-center pt-4">Loading...</h5>)
				}
			</div>
		); 
	}
}

const container = Container.create(FieldComponentsMapContainer);
export default container;