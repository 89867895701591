import React, { Component } from 'react';
import { Container } from 'flux/utils';

import {
	AdminSettingsActions,
	TableActions,
	InterfaceActions,
 } from '../../../actions';

import {
	AdminSettingsStore,
 } from '../../../stores';

 import {
	TableUtils,
	UIUtils,
 } from '../../../utils';

 import uuid from 'uuid';

/**
 * Component for suggesting tables
 * 
 * @class TableSuggestions
 * @extends {Component}
 */
class TableSuggestions extends Component {
	/**
	 * Creates instance of SelectOptions
	 * 
	 * @returns React
	 * 
	 * @memberof SelectOptions
	 */
	constructor(props) {
		super(props);

		this._camelize = this._camelize.bind(this);
		this._clearAllSuggestions = this._clearAllSuggestions.bind(this);
		this._createTable = this._createTable.bind(this);
		this._renderSuggestionsList = this._renderSuggestionsList.bind(this);
		this._specifyTable = this._specifyTable.bind(this);
		this._suggestTables = this._suggestTables.bind(this);
	} 

	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}
	
	/**
	 * Calculate the current state of this component
	 * @static
	 * @param {Object} prevState 
	 * @param {Object} prevState 
	 * @returns {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		return {
			suggestions: AdminSettingsStore.getTableSuggestions()
		}
		// return {
		// 	componentSettings: componentSettings,
		// 	recordId: recordId,
		// 	tableSchemaName: tableSchemaName,
		// 	settingsHidden: AdminSettingsStore.getSettingsListHidden(),
		// 	hasMissingRequiredSettings: hasMissingRequiredSettings,
		// 	isNew: isNew
		// }
		// return {
		// 	suggestIsOpen: 
		// }
	}

	/**
	 * Render the Select Options
	 * 
	 * @returns React
	 * 
	 * @memberof SelectOptions
	 */
	render() {
		let { suggestions } = this.state;
		let suggestionsJSX = null;
		let { 
			suggesting,
			userPromptDescription 
		} = this.state;
		let hasSuggestions = (suggestions && suggestions.length ? true : false);
		if(hasSuggestions) {
			suggestionsJSX = [
				<table key="suggestions"  className="tableSuggestionListTable">
					<tbody>
						<tr>
							<th>Name</th>
							<th>Description</th>
						</tr>
						{this._renderSuggestionsList(suggestions)}
					</tbody>
				</table>,
				<div key="instructions" className="d-flex ml-2">
					<small className="tableSuggestionInstruction form-text mt-0 w-100">Click on a suggested Table's name above to create that table.</small>
					<button onClick={() => { this._clearAllSuggestions() }} className="btn btn-warning btn-sm m-1">Reset Suggestions</button>
				</div>
			];
		}
		let suggestButtonJSX = <button className="btn btn-primary btn-sm m-1" onClick={() => { this._suggestTables(userPromptDescription); }}>Suggest Tables</button>;
		if(suggesting) {
			suggestButtonJSX = <button className="btn btn-primary btn-sm m-1">
				Suggesting&nbsp;
				<i className="fa fa-spinner fa-spin fa-3x fa-fw" style={{
					fontSize: "17px",
					padding: "0px"
				}} />
			</button>
		}

		return (<div className={"createTableWrapper" + (hasSuggestions ? ' hasSuggestions' : '')}>
					<div className="createTableHeader">Create a Table starting with name or description</div>
					<div className="suggestionInputWrapper">
						<textarea className="suggestionInput p-1" rows="2" 
							placeholder="Enter a table name for example 'Products' or describe the table's purpose for example 'Store inventory with prices'."
							value={ userPromptDescription } onChange={(event) => { this.setState({ userPromptDescription : event.target.value }); }}
							onKeyDown={(event) => {
								if (event.keyCode === 13) {
									this._suggestTables(userPromptDescription);
									event.preventDefault()
								} }} />
						{ suggestButtonJSX }
						<button className="btn btn-primary btn-sm m-1" onClick={() => { this._specifyTable() }}>Specify Table</button>
					</div>
					<div className="suggestionsWrapper">
						<small className="instructions form-text mt-0">Our assistant can help you define the basics of a table by giving it 
							suggestions above.  Enter a name for the table, or a description of the table's purpose and it will
							attempt to assist you in defining some tables that would suit your needs.  If you see a table you 
							like, click on it, and you will be able to enter final details before creating it.
							<br /><br />
							If the suggestions don't suit you, or you already know exactly what you want to create, simply click
							"Specify Table" in the upper right to skip the suggestion step and move straight into table creation.
						</small>
						{ suggestionsJSX }
						
					</div>
				</div>);
	}

	_camelize(text) {
		// Remove everything thats not a-z, A-Z, 0-9; covert to lowercase, then replace the characters after a space, 
		// - or _ with the upper case version of itself
		const a = text.replace(/[^a-zA-Z0-9\s]/g, '').toLowerCase()
			.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
		return a.substring(0, 1).toLowerCase() + a.substring(1);
	} // end function _camelizez

	/**
	 * What do we do when the user clicks the clear suggestions button.
	 */
	_clearAllSuggestions() {
		AdminSettingsActions.onClearTableSuggestion('all');
	} // end function _clearAllSuggestions

	/**
	 * Logic for when a table is clicked on, to be created.
	 * @param {Integer} index Which Table Suggestion to creat.
	 */
	_createTable(index) {
		let suggestions = AdminSettingsStore.getTableSuggestions();

		let recordId = uuid.v4();
		let tableSchemaNameObj = TableUtils.validateTableSchemaName(this._camelize(suggestions[index]['pluralName']));
		let newTable = {
			recordId: recordId,
			tableSchemaName: tableSchemaNameObj['validSchemaName'],
			singularName: suggestions[index]['singularName'],
			pluralName: suggestions[index]['pluralName'],
			description: suggestions[index]['description'],
			roles: '',
			icon: suggestions[index]['icon'],
			new: true,
			crudInterfaces: JSON.stringify({
				createShortText: true,
				sampleRecord: true,
				searchAdvanced: true
			})
		};

		TableActions.pushToStore(recordId, newTable);

		// Remove this table from the table suggestions
		AdminSettingsActions.onClearTableSuggestion({ singularName: suggestions[index]['singularName'] });

		// Open the Settings Panel
		UIUtils.openSettingsPanel('table', recordId, 'table');
		AdminSettingsActions.onSettingChange('pluralName', 'e97b9453-08e2-4ca8-94fb-36d8867f4fc4');
	} // end function _createTable

	/**
	 * renders the rows of the list of suggested fields.
	 * 
	 * @returns JSX
	 */
	_renderSuggestionsList(suggestions){
		let listRows = [];

		//If the value is undefined or the list is empty
		if(suggestions.length) {
			// Remove any suggestion that is now in the field store!
			listRows = suggestions.map((item, index) => {
				let tableName = item['singularName'];
				let icon = item['icon'];
				let description = item['description'];

				let tableIconJSX = <span className={"mr-2 tableSuggestionIcon fa fa-cube" } height="10" width="10" onClick={() => { this._createTable(index) }} />;
				if(icon) {
					tableIconJSX = <span className={"mr-2 tableSuggestionIcon fa fa-" + icon } height="10" width="10" onClick={() => { this._createTable(index) }} />;
				}
				return (
					<tr key={ "renderSuggestionsList" + index}>
						<td className="wideColumn fieldTypeName">
							<div className="d-flex">
								{tableIconJSX}
								<span><h5 className="tableSuggestion" onClick={() => { this._createTable(index) }}>{tableName}</h5></span>
							</div>
						</td>
						<td className="wideColumn"><h5>{ description }</h5></td>
					</tr>
				);
			});	
		}
		return listRows;
	} // end function _renderSuggestionsList

	/**
	 * Reload to the Add Table interface, to specify a table through normal means.
	 */
	_specifyTable() {
		const iconList = [
			'microchip', 'user-o', 'bank', 'briefcase', 'car', 'calendar', 'cogs', 'coffee', 'cog', 'credit-card', 'cube', 'envelope-o',
			'film', 'fire', 'gift', 'group', 'globe', 'laptop', 'map-o', 'plane', 'rocket', 'ship', 'sitemap', 'snowflake-o', 'soccer-ball-o',
			'space-shuttle', 'sticky-note-o', 'tags', 'tag', 'television', 'ticket', 'tint', 'tree', 'trophy', 'user-secret'
		];
		let randMult = Math.random();
		let recordId = uuid.v4(),
			newTable = {
				recordId: recordId,
				tableSchemaName: '',
				singularName: '',
				pluralName: '',
				roles: '',
				icon: iconList[Math.round(randMult * iconList.length)],
				new: true,
				crudInterfaces: JSON.stringify({
					createShortText: true,
					sampleRecord: true,
					searchAdvanced: true
				})
			};

		TableActions.pushToStore(recordId, newTable);

		// Open the Settings Panel
		UIUtils.openSettingsPanel('table', recordId, 'table');
		AdminSettingsActions.onSettingChange('pluralName', 'e97b9453-08e2-4ca8-94fb-36d8867f4fc4');
	} // end function _specifyTable

	/**
	 * Process field suggestions
	 * @param userPromptDescription User Prompt to pass along.
	 */
	_suggestTables(userPromptDescription) {
		this.setState({ suggesting: true });
		TableUtils.suggestTables(userPromptDescription).then(suggestions => {
			// Eliminate blatant duplicates:
			let currentSuggestions = AdminSettingsStore.getTableSuggestions();

			//Eliminate Duplicates
			if(currentSuggestions.length) {
				suggestions = suggestions.filter(sugg => {
					let returnValue = true;
					currentSuggestions.forEach(currentSugg => {
						if(currentSugg.singularName === sugg.singularName) {
							// console.log('Eliminated duplicate suggestion:', sugg)
							returnValue = false;
						}
					})
					return returnValue;
				});
			}

			// Receive the suggestions into the AdminSettingsStore.
			AdminSettingsActions.onReceiveTableSuggestions(suggestions);
			this.setState({ suggesting: false });
		}).catch(error => {
			InterfaceActions.notification({'message': 'There is an issue connecting to the suggestion engine.', 'title':'Connection Issue', 'level':'error'});
			console.error(error);
			this.setState({ suggesting: false });
		});
	} // end function _suggestTables
}
const container = Container.create(TableSuggestions, {withProps: true});
export default container;