import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { map } from 'lodash';

// Actions
import { 
  AdminSettingsActions,
  InterfaceActions, 
  MapActions,
  MetadataActions
} from '../../../actions';

// Constants
import Help from '../../../constants/help-upgrade-constants';

// Stores
//Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import ContextStore from '../../../stores/context-store';
import MapStore from '../../../stores/map-store';
import MetadataStore from '../../../stores/metadata-store';
import ThemeTemplateStore from '../../../stores/theme-template-store';

// Utils
import ThemeBuilderUtils from '../../../utils/theme-builder-utils';
import UIUtils from '../../../utils/ui-utils';
import uuid from 'uuid';

import { ReactSVG } from 'react-svg';

/**
 * Listing of Themes for Presentation Dashboard
 */
class ThemesPresentationDashboardTabContainer extends Component {
  /**
   * Creates an instance of ThemesPresentationDashboardTabContainer
   *
   * @memberOf ThemesPresentationDashboardTabContainer
   */
  constructor(props) {
    super(props);

    this._renderGroupBy = this._renderGroupBy.bind(this);
    this._onAddTheme = this._onAddTheme.bind(this);
    this._onChangeSearch = this._onChangeSearch.bind(this);
    this._onEditTheme = this._onEditTheme.bind(this);
    this._onSwitchToTheme = this._onSwitchToTheme.bind(this);
    this._removeTags = this._removeTags.bind(this);
  }
  /**
   * static - description
   *
   * @return {type}  description
   */
  static getStores() {
    return [ AdminSettingsStore, ContextStore, MapStore, MetadataStore, ThemeTemplateStore ];
  }
  /**
   * static - description
   *
   * @param  {type} prevState description
   * @param  {type} props     description
   * @return {type}           description
   */
  static calculateState(prevState, props) {
    let themes = [];
		let search = MapStore.getSearch(AdminSettingsStore.getActiveDashboardTab());
		let groupBy = (MapStore.getGroupBy() ? MapStore.getGroupBy() : 'name');

		if(MetadataStore.allPulledFromDatabase('theme')){
			themes = MetadataStore.getAllArray('theme') || [];
			themes.map(theme => {
				if(!theme.name) {
					theme.name = '[ No Name Found ]';
				}
				return theme;
			});
		}
		return {
			themes: themes,
			search: search,
			groupedThemes: this._performGroupBy(themes, groupBy, search)
		};
  }

	/**
	 * Using the group by set in the UI, group the themes and return
	 * an object with the themes as keys and functions array as value.
	 * @param {Array} themes Themes to group
	 * @param {String} groupBy How to group the schedules
	 * @param {String} search What search to apply, if any.
	 * @return Object
	 */
	static _performGroupBy(themes, groupBy, search) {
		if (!themes) {
			return themes;
		}

    let applicationObj = MetadataStore.get(ContextStore.getApplicationId(), 'applications');
		let selectedTheme = applicationObj['selectedTheme'];

		// Filter out the themes that dont have the search term.
		themes = themes.filter(theme => {
			if (search && search.length > 0) {
				return (theme.name.toLowerCase().includes(search.toLowerCase()));
			} else {
				return true;
			}
			// Sort the themes - by Name.
		}).sort((a, b) => {
			// Ascending Alphabetically
			return b.name < a.name ? 1 : -1;
		});

		let groups = {
      'Active Theme': [],
      'Themes': []
    };
		
		// Put the themes into their groups
		themes.forEach(theme => {
      let groupVal = 'Themes';
      if(theme.recordId === selectedTheme) {
        groupVal = 'Active Theme';
      }
			groups[groupVal].push(theme);
		});

    // Add the Legacy Theme if appropriate to do so.
    if(applicationObj.css && applicationObj.css !== '') {
      let groupName = 'Themes';
      if(!selectedTheme || selectedTheme === 'legacy') {
        groupName = 'Active Theme';
      }
      let name = 'Legacy CSS Style';
      if(name.toLowerCase().includes(search.toLowerCase())) {
        groups[groupName].push({
          recordId: 'legacy',
          name,
          description: applicationObj.description,
          lastUpdatedDateTime: applicationObj.lastUpdatedDateTime,
        });
      }
    }

    // Only return those groups with a length.
    let returnGroups = {};
    Object.keys(groups).forEach(groupKey => {
      if(groups[groupKey].length) {
        returnGroups[groupKey] = groups[groupKey];
      }
    })

		return returnGroups;
	}

  /**
   * Render method
   */
  render() {
    let { groupedThemes, search } = this.state;

    let noSearchFound = null;
    if(Object.keys(groupedThemes).length === 0 && search.length) {
      noSearchFound = <li className="no-search-found" style={{ color: 'white'}}><h4>No Results for '{search}' found.</h4></li>
    }

    let notifications = [];
    let newCount = 0;
    let errorCount = 0;
    map(groupedThemes, (themes, groupBy) => {
      themes.forEach((theme, index) => {
        if(theme.errorText && theme.errorText !== '') {
          errorCount++;
        }
        if(theme.new && theme.new === true) {
          newCount++;
        }
      });
    });
    if(newCount > 0) {
			notifications.push(UIUtils.getNotificationTile(
				'status1', 
				'New Themes', 
				newCount + ' New'));
		}
    if(errorCount > 0) {
        notifications.push(UIUtils.getNotificationTile(
          'status3', 
          'Themes with Errors', 
          errorCount + ' Errors'));
    }

    return (
      <div id="data-map_content" className="map">
        <div className="cd-search-container">
      <input className="form-control select-group-by" placeholder="Search" type="text" value={search} onChange={this._onChangeSearch} />
    </div>
    <div className="section-header" key="expand-collapse-tools">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex">
        </div>
        <div className="d-flex align-items-center">
          <h5 className="bold">Themes</h5>
          <div title="Themes Help" className="info-icon ml-2" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_PRESENTATION_THEME); }}>
            <i className="fa fa-info-circle mr-1"></i> | 
          </div>
          <div title="Create Page" data-toggle="modal" onClick={this._onAddTheme.bind(this,'')} >
            <i className="fa fa-plus-circle ml-1"></i>
          </div>
        </div>
      </div>
    </div>
        <div className="list-content-wrapper" key="themes">
          <ol>
            {map(groupedThemes, this._renderGroupBy)}
            { noSearchFound }
          </ol>
          <div className="notifications-wrapper">
						{ notifications }
					</div>
        </div>
      </div>
    );
  }


  /**
   * private Render on group of Themes - from the list of ALL themes.
   * @param {Array} themes All of the Themes
   * @param {string} groupBy The value to show these themes as grouped by - in this case, the table name.
   */
  _renderGroupBy(themes, groupBy) {
    let themeList = [];
    // Generate the LI list for all themes in this group..
    themes.forEach((theme, index) => {
      let themeName = theme.name

      if(themeName === 'null' || themeName === '' || themeName.length === 0) {
        themeName = '[ No Name found ]';
      }

      let themeDescription = theme.description;

      // Try the image from the theme...
      let imageData = theme.imageData;
      if(!imageData || imageData.length === 0) {
        // Try the image from the theme template
        let themeTemplateObj = ThemeTemplateStore.get(theme.template);
        if(themeTemplateObj && themeTemplateObj.imageData && themeTemplateObj.imageData.length) {
          imageData = themeTemplateObj.imageData;
        }
      }

      let themeImageIMG = null;
      if(imageData && imageData.length > 0) {
        themeImageIMG = <img className="themePreviewImage" src={imageData} alt="Theme Preview" />
      } else {
        themeImageIMG = <div className="themePreviewImage empty d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center">
            <ReactSVG 
              beforeInjection={svg => {
                  svg.setAttribute('style', 'height: 26px; width: 26px');
              }}
              src={ContextStore.getUrlMedia() + "/icon-theme.svg"} />
          </div>
        </div>
      }

      // Deactivate | Activate | Switch control
      let controlText = 'Switch';
      let controlCallback = function() {
        this._onSwitchToTheme(theme.recordId);
      }.bind(this);

      if(groupBy === 'Active Theme') {
        let applicationObj = MetadataStore.get(ContextStore.getApplicationId(), 'applications');
        controlText = (applicationObj && applicationObj.hideCSS ? 'Enable' : 'Disable Temporarily');

        controlCallback = function() {
          this._onToggleStyleDisabled();
        }.bind(this);
      }

      let errorDot = (theme.errorText && theme.errorText !== ''
        ? <div className="colorBullet status3">
            <i className="fa fa-circle fa-1" aria-hidden="true" title="Theme has an error"></i>
          </div> 
        : null);

      let newDot = (theme.new && theme.new === true 
        ? <div className="colorBullet status1">
            <i className="fa fa-circle fa-1" aria-hidden="true" title="Theme is new"></i>
          </div> 
        : null);

      if(theme.recordId === 'legacy') {
        themeDescription = this._removeTags(themeDescription);
      }

      themeList.push(
        <li key={index} className="table-name-item">
          <div className="d-flex justify-content-between">
            <div>
              {themeImageIMG}
            </div>
            <div style={{ flex: 2, marginRight: '5px' }}>
              <h5>{themeName}</h5>
              <h6 className="description">{themeDescription}</h6>
              <h5 className="link control" onClick={controlCallback}>{controlText}</h5>
            </div>
            <div className="d-flex">
              {errorDot}
              {newDot}
              <span onClick={this._onEditTheme.bind(this, theme.recordId)} title="Update Theme">
                <img height="14" width="14" src={ContextStore.getUrlMedia() + "/icon-theme.svg"} alt="" />
              </span>
            </div>
          </div>
        </li>
      );
    }); // End loop over all the themes.

    if (themeList.length) {
      return (
        <li key={groupBy} className="role-group-item">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <h4 className="bold ml-1">{groupBy}</h4>
            </div>
            <div className="d-flex">
              <h4 className="bold">({themeList.length})</h4>
            </div>
          </div>
          <ol id={groupBy} className={'theme-list groupby-list'}>
            {themeList}
          </ol>
        </li>);
    } else {
      return null;
    }
  } // end _renderGroupBy

  /**
   * Handle adding a Theme.
   */
  _onAddTheme() {
		let recordId = uuid.v4();
		let newTheme = {
			recordId: recordId,
			name: 'New Theme',
			new: true
		};

		MetadataActions.pushToStore(recordId, 'theme', newTheme);

    this._onEditTheme(recordId);
  }

	/**
	 * Handles typing into the search box.
	 * @param {object} event Change event
	 */
	_onChangeSearch(event) {
		let search = event.target.value;
		MapActions.search(search, AdminSettingsStore.getActiveDashboardTab());
	}

  /**
   * Edit this theme in the Details Pane
   * @param {string} themeId 
   */
  _onEditTheme(themeId) {
    if(themeId === 'legacy') {
      UIUtils.openSettingsPanel('legacytheme',
        ContextStore.getApplicationId(), 'applications',
        undefined, undefined
      );
      // Hide the settings, we're going to css!
      AdminSettingsActions.onSettingsListHideChange(true);
      AdminSettingsActions.onSettingChange('css', '4baa9a7c-47ce-4f99-9519-4f64beb58db8');
    } else {
      UIUtils.openSettingsPanel('theme',
        themeId, 'theme',
        undefined, undefined
      );
  
      // Unhide the settings, always
      AdminSettingsActions.onSettingsListHideChange(false);
    }
  }

  /**
   * Handler for when we want to switch to this theme.
   * @param {string} themeId 
   */
  _onSwitchToTheme(themeId) {
		let applicationObj = MetadataStore.get(ContextStore.getApplicationId(), 'applications');

		applicationObj['selectedTheme'] = themeId;

		// Push the update Application Object into the store.
		MetadataActions.pushToStore(ContextStore.getApplicationId(), 'applications', applicationObj);
    InterfaceActions.stickyNotification({ 
      level: 'success', 
      message: 'Switching theme...',
      id: 'themeSave'
    });
    // Push the update Application Object into the database.
    MetadataActions.pushToDatabasePromise(applicationObj, 'applications')
    .then(() => { 
      InterfaceActions.clearStickyNotification('themeSave'); 
      ThemeBuilderUtils.reloadActiveTheme(themeId);
    }).catch((error) => { 
      InterfaceActions.clearStickyNotification('themeSave'); 
      InterfaceActions.stickyNotification({ 
        level: 'error', 
        message: 'Error Switching theme, please check the console',
      });
      console.error(error) 
    });
  }

	/**
	 * Toggle the hideCSS Setting
	 */
	_onToggleStyleDisabled() {
		let applicationObj = MetadataStore.get(ContextStore.getApplicationId(), 'applications');

		if(applicationObj['hideCSS']) {
			applicationObj['hideCSS'] = !applicationObj['hideCSS'];
		} else {
			applicationObj['hideCSS'] = true;
		}

    // Apply the new value
    ThemeBuilderUtils.hideOrShowTheme(applicationObj['hideCSS']);

		// Close the panel so you can see your change.
		UIUtils.closeSettingsPanel();

		// Push the update API Object into the store.
		MetadataActions.pushToStore(ContextStore.getApplicationId(), 'applications', applicationObj);
	}

  _removeTags(str) {
    if ((str===null) || (str==='') || (typeof str === 'undefined'))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
  }
}
const container = Container.create(ThemesPresentationDashboardTabContainer);
export default container;
