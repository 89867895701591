import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import RenderConstants from '../constants/render-constants';
import {FieldConstants} from '../constants/field-constants';
import { PageConstants } from '../constants/page-constants';
import ContextStore from './context-store';
import FieldStore from './field-store';
import FieldTypeStore from './field-type-store';
import PageStore from './page-store';
import ObjectUtils from '../utils/object-utils';

/**
 * Manages state set by admin tools (such as overlays set in main footer)
 */
class FieldSettingsStore extends ReduceStore {
	/**
	 * getInitialState - initial state for ContextStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.Map();
	}

	/**
	 * getActiveOverlays - Retrieves the current active panels
	 *
	 * @return {array}  names of active panels
	 */
	getSettings(fieldRecordId, parentRecordId, screenSize) {
		if (!screenSize) {
			screenSize = ContextStore.getResponsiveMode();
		}
		let state = this.getState();
		let fieldGlobalSettings = {};
		if (state.has('fields') && state.get('fields').has(fieldRecordId)) {
			fieldGlobalSettings = state.get('fields').get(fieldRecordId).toJS();
		}
		let fieldLocalSettings = {};
		if (parentRecordId && 
			state.has('parents') && 
			state.get('parents').has(parentRecordId) && 
			state.get('parents').get(parentRecordId).has(fieldRecordId) && 
			state.get('parents').get(parentRecordId).get(fieldRecordId).has(screenSize)
		) {
			fieldLocalSettings = state.get('parents').get(parentRecordId).get(fieldRecordId).get(screenSize).toJS();
		}

		// Because of how visibility works, it needs to be merged in at a deeper level than object.assign provides.
		if(fieldGlobalSettings.visibility || fieldLocalSettings.visibility) {
			let globalVisibility = fieldGlobalSettings.visibility ? ObjectUtils.getObjFromJSON(fieldGlobalSettings.visibility) : {};
			let localVisibility = fieldLocalSettings.visibility ? ObjectUtils.getObjFromJSON(fieldLocalSettings.visibility) : {};
			let newVisibility = Object.assign({}, globalVisibility, localVisibility);
			fieldLocalSettings.visibility = JSON.stringify(newVisibility);
		}

		return Object.assign(fieldGlobalSettings, fieldLocalSettings);
	}


	/**
	 * getActiveOverlays - Retrieves the current active panels
	 *
	 * @return {array}  names of active panels
	 */
	getSettingsWithSource(fieldRecordId, parentRecordId, screenSize) {
		if (!screenSize) {
			screenSize = ContextStore.getResponsiveMode();
		}
		let state = this.getState();
		let fieldGlobalSettings = {};
		if (state.has('fields') && state.get('fields').has(fieldRecordId)) {
			fieldGlobalSettings = state.get('fields').get(fieldRecordId).toJS();
		}
		let fieldGlobalSettingsWithSource = {};
		if (fieldGlobalSettings) {
			Object.keys(fieldGlobalSettings).forEach(settingSchemaName => {
				fieldGlobalSettingsWithSource[settingSchemaName] = {
					value: fieldGlobalSettings[settingSchemaName],
					source: 'global'
				};
			})
		}
		let fieldLocalSettings = {};
		if (parentRecordId && 
			state.has('parents') && 
			state.get('parents').has(parentRecordId) && 
			state.get('parents').get(parentRecordId).has(fieldRecordId) && 
			state.get('parents').get(parentRecordId).get(fieldRecordId).has(screenSize)) {
			fieldLocalSettings = state.get('parents').get(parentRecordId).get(fieldRecordId).get(screenSize).toJS();
		}
		let fieldLocalSettingsWithSource = {};
		if (fieldLocalSettings) {
			Object.keys(fieldLocalSettings).forEach(settingSchemaName => {
				fieldLocalSettingsWithSource[settingSchemaName] = {
					value: fieldLocalSettings[settingSchemaName],
					source: 'local'
				};
			})
		}

		return Object.assign(fieldGlobalSettingsWithSource, fieldLocalSettingsWithSource);
	}

	/**
	 *
	 * Gets the settings for a child field given an attachment ID, or just gets
	 * all settings stored under that attachmentId
	 * 
	 * @param {string} attachmentId The attachment ID of the field
	 * @param {string} fieldRecordId Optional. The ID of the field whose settings to get
	 * @param {string} parentRecordId The ID of the parent field or page
	 * @param {string} screenSize Optional. The screensize for which to get these settings.
	 * @memberof FieldSettingsStore
	 */
	getSettingsFromAttachmentId(attachmentId, fieldRecordId, parentRecordId, screenSize) {
		if (!screenSize) {
			screenSize = ContextStore.getResponsiveMode();
		}
		let state = this.getState();
		let fieldGlobalSettings = {};
		if (fieldRecordId && state.hasIn(['fields', fieldRecordId])) {
			fieldGlobalSettings = state.getIn(['fields', fieldRecordId]).toJS();
		}

		// Get the local settings
		let attachmentSettings = {};
		if (
				parentRecordId && 
				state.hasIn(['parents', parentRecordId, attachmentId, screenSize])
			)
		{
			try {
				attachmentSettings = state.getIn(['parents', parentRecordId, attachmentId, screenSize]).toJS();
			} catch(err) {
				console.error('Error getting attachment settings. Was attempting to run toJS on value', state.getIn(['parents', parentRecordId, attachmentId, screenSize]));
			}
		}

		if(fieldRecordId && parentRecordId && state.hasIn(['parents', parentRecordId, attachmentId + '-' + fieldRecordId, screenSize])) {
			try {
				attachmentSettings = Object.assign(attachmentSettings, state.getIn(['parents', parentRecordId, attachmentId + '-' + fieldRecordId, screenSize]).toJS());
			} catch(err) {
				console.error('Error getting attachment settings. Was attempting to run toJS on value', state.getIn(['parents', parentRecordId, attachmentId + '-' + fieldRecordId, screenSize]));
			}
		}

		let fieldLocalSettings = {};
		// Some local settings, such as automation, are still keyed by field ID. We want to retain those, then override with any by attachment key
		if (parentRecordId && fieldRecordId &&
			state.hasIn(['parents', parentRecordId, fieldRecordId, screenSize])
		) {
			fieldLocalSettings = state.getIn(['parents', parentRecordId, fieldRecordId, screenSize]).toJS();
		}

		// Now filter it to only the settings which make sense for the field type if we have a field type
		if(fieldRecordId) {
			let fieldObj = FieldStore.get(fieldRecordId) || {};
			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType) || {};
			let settingsArr = fieldTypeObj && fieldTypeObj.settings ? fieldTypeObj.settings.map(({recordId}) => FieldStore.get(recordId).fieldSchemaName) : [];
			// We also need to grab the settings which are added by default, not by the field type
			settingsArr = settingsArr.concat(['labelPosition', 'viewVariant', 'editVariant', 'requiredForSave']);

			// Now also check for child settings from the parent field, if such a field exists
			let parentFieldObj = FieldStore.get(parentRecordId);
			if(parentFieldObj && parentFieldObj.fieldType) {
				let parentFieldTypeObj = FieldTypeStore.get(parentFieldObj.fieldType) || {};
				if(parentFieldTypeObj.hasChildSettings && parentFieldTypeObj.settingsForChildFields && parentFieldTypeObj.settingsForChildFields.length) {
					parentFieldTypeObj.settingsForChildFields.forEach(({recordId}) => settingsArr.push(FieldStore.get(recordId).fieldSchemaName));
				}
			}
			settingsArr.forEach(settingSchemaName => {
				// We are ignoring null values, as those were set upon deletion at one point
				if(attachmentSettings.hasOwnProperty(settingSchemaName) && attachmentSettings[settingSchemaName] !== null) {
					fieldLocalSettings[settingSchemaName] = attachmentSettings[settingSchemaName];
				}
			});
		} else {
			// If the field ID isn't provided, we just want all settings
			fieldLocalSettings = attachmentSettings;
		}

		// Because of how visibility works, it needs to be merged in at a deeper level than object.assign provides.
		if(fieldGlobalSettings.visibility || fieldLocalSettings.visibility) {
			let globalVisibility = {};
			if(fieldGlobalSettings.visibility) {
				globalVisibility = typeof fieldGlobalSettings.visibility === 'string' ? ObjectUtils.getObjFromJSON(fieldGlobalSettings.visibility) : fieldGlobalSettings.visibility;
			}
			let localVisibility = {};
			if(fieldLocalSettings.visibility) {
				localVisibility = typeof fieldLocalSettings.visibility === 'string' ? ObjectUtils.getObjFromJSON(fieldLocalSettings.visibility) : fieldLocalSettings.visibility;
			}
			let newVisibility = Object.assign({}, globalVisibility, localVisibility);
			fieldLocalSettings.visibility = JSON.stringify(newVisibility);
		}

		return Object.assign({}, fieldGlobalSettings, fieldLocalSettings);
	}

	/**
	 * Given an attachment Id and a parent Record ID, return just g[lobal] or 
	 * l[ocal], for where the setting is set for a screensize.
	 * @param {string} attachmentId 
	 * @param {string} parentRecordId 
	 * @param {string} screenSize Current screen size.
	 * @param {string} settingSchemaName Setting in question.
	 * @returns 
	 */
	getSettingSourceFromAttachmentId(attachmentId, parentRecordId, screenSize, settingSchemaName) {
		if (!screenSize) {
			screenSize = ContextStore.getResponsiveMode();
		}
		let state = this.getState();
		if(attachmentId && parentRecordId && state.hasIn(['parents', parentRecordId, attachmentId, screenSize, settingSchemaName])) {
			return 'l';
		} else {
			return 'g';
		}
	}

	/**
	 *
	 * Gets the settings for a child field given an attachment ID, or just gets
	 * all settings stored under that attachmentId. Same as getSettingsFromAttachmentId, except
	 * that each setting specifies whether the value is local or global
	 * 
	 * @param {string} attachmentId The attachment ID of the field
	 * @param {string} fieldRecordId Optional. The ID of the field whose settings to get
	 * @param {string} parentRecordId The ID of the parent field or page
	 * @param {string} screenSize Optional. The screensize for which to get these settings.
	 * @memberof FieldSettingsStore
	 */
	getSettingsFromAttachmentIdWithSource(attachmentId, fieldRecordId, parentRecordId, screenSize) {
		if (!screenSize) {
			screenSize = ContextStore.getResponsiveMode();
		}
		let state = this.getState();
		let fieldGlobalSettings = {};
		if (fieldRecordId && state.hasIn(['fields', fieldRecordId])) {
			fieldGlobalSettings = state.getIn(['fields', fieldRecordId]).toJS();
		}

		let fieldGlobalSettingsWithSource = {};
		if (fieldGlobalSettings) {
			Object.keys(fieldGlobalSettings).forEach(settingSchemaName => {
				fieldGlobalSettingsWithSource[settingSchemaName] = {
					value: fieldGlobalSettings[settingSchemaName],
					source: 'global'
				};
			})
		}

		// Get the local settings
		let attachmentSettings = {};
		if (
				parentRecordId && 
				state.hasIn(['parents', parentRecordId, attachmentId, screenSize])
			)
		{
			attachmentSettings = state.getIn(['parents', parentRecordId, attachmentId, screenSize]);
			attachmentSettings = attachmentSettings ? attachmentSettings.toJS() : {};
		}

		let fieldLocalSettings = {};
		// Some local settings, such as automation, are still keyed by field ID. We want to retain those, then override with any by attachment key
		if (parentRecordId && fieldRecordId &&
			state.hasIn(['parents', parentRecordId, fieldRecordId, screenSize])
		) {
			fieldLocalSettings = state.getIn(['parents', parentRecordId, fieldRecordId, screenSize]).toJS();
		}

		// Now filter it to only the settings which make sense for the field type if we have a field type
		if(fieldRecordId) {
			let fieldObj = FieldStore.get(fieldRecordId) || {};
			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType) || {};
			let settingsArr = fieldTypeObj && fieldTypeObj.settings ? fieldTypeObj.settings.map(({recordId}) => FieldStore.get(recordId).fieldSchemaName) : [];
			// We also need to grab the settings which are added by default, not by the field type
			settingsArr = settingsArr.concat(['labelPosition', 'viewVariant', 'editVariant', 'requiredForSave']);

			// Now also check for child settings from the parent field, if such a field exists
			let parentFieldObj = FieldStore.get(parentRecordId);
			if(parentFieldObj && parentFieldObj.fieldType) {
				let parentFieldTypeObj = FieldTypeStore.get(parentFieldObj.fieldType) || {};
				if(parentFieldTypeObj.hasChildSettings && parentFieldTypeObj.settingsForChildFields && parentFieldTypeObj.settingsForChildFields.length) {
					parentFieldTypeObj.settingsForChildFields.forEach(({recordId}) => settingsArr.push(FieldStore.get(recordId).fieldSchemaName));
				}
			}
			settingsArr.forEach(settingSchemaName => {
				// We are ignoring null values, as those were set upon deletion at one point
				if(attachmentSettings.hasOwnProperty(settingSchemaName) && attachmentSettings[settingSchemaName] !== null) {
					fieldLocalSettings[settingSchemaName] = attachmentSettings[settingSchemaName];
				}
			});
		} else {
			// If the field ID isn't provided, we just want all settings
			fieldLocalSettings = attachmentSettings;
		}

		// Because of how visibility works, it needs to be merged in at a deeper level than object.assign provides.
		if(fieldGlobalSettings.visibility || fieldLocalSettings.visibility) {
			let globalVisibility = {};
			if(fieldGlobalSettings.visibility) {
				globalVisibility = typeof fieldGlobalSettings.visibility === 'string' ? ObjectUtils.getObjFromJSON(fieldGlobalSettings.visibility) : fieldGlobalSettings.visibility;
			}
			let localVisibility = {};
			if(fieldLocalSettings.visibility) {
				localVisibility = typeof fieldLocalSettings.visibility === 'string' ? ObjectUtils.getObjFromJSON(fieldLocalSettings.visibility) : fieldLocalSettings.visibility;
			}
			let newVisibility = Object.assign({}, globalVisibility, localVisibility);
			fieldLocalSettings.visibility = JSON.stringify(newVisibility);
		}

		let fieldLocalSettingsWithSource = {};
		if (fieldLocalSettings) {
			Object.keys(fieldLocalSettings).forEach(settingSchemaName => {
				fieldLocalSettingsWithSource[settingSchemaName] = {
					value: fieldLocalSettings[settingSchemaName],
					source: 'local'
				};
			})
		}

		return Object.assign(fieldGlobalSettingsWithSource, fieldLocalSettingsWithSource);
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case RenderConstants.SET_RENDER: {
				if(action.get('componentType') === 'page') {
					let pageId = action.get('componentId');
					if (pageId && !state.has(pageId)) {
						return processPage(state, pageId);
					} else {
						return state;
					}
				}
				else {
					return state;
				}
			}

			case PageConstants.PAGE_PUSH_TO_STORE: {
				//Waiting for PageStore to load Data 
				AppDispatcher.waitFor([PageStore.getDispatchToken()]);

				let pageId = action.get('recordId');
				return processPage(state, pageId);
			}

			case PageConstants.PAGE_RECEIVE_BROADCAST: {
				//Waiting for PageStore to load Data 
				AppDispatcher.waitFor([PageStore.getDispatchToken()]);

				// This will typically only have one record, but we may need to update this for efficiency if that changes
				let records = action.get('records');

				return state.withMutations(newState => {
					records.forEach(record => {
						let pageId = record.get('recordId');
						newState =  processPage(newState, pageId);
					});
				});
			}

			case PageConstants.PAGE_PUSH_CHILD_CONFIGURATION_TO_STORE: {
				//Waiting for PageStore to load Data 
				AppDispatcher.waitFor([PageStore.getDispatchToken()]);
				let parentRecordId = action.get('parentRecordId');
				return state.withMutations(state => {
					processPage(state, parentRecordId);
				});
			}

			case FieldConstants.FIELD_DELETE_FROM_STORE: {
				let fieldId = action.get('recordId');
				if (state.has('fields') && state.get('fields').has(fieldId)) {
					let newState = state.deleteIn(['fields', fieldId]);
					if (state.has('parents') && state.get('parents').has(fieldId)) {
						newState = newState.deleteIn(['parents', fieldId]);
					}
					return newState;
				} else {
					return state;
				}
			}

			case PageConstants.PAGE_DELETE_FROM_STORE: {
				let pageId = action.get('recordId');
				if (state.has('parents') && state.get('parents').has(pageId)) {
					return state.deleteIn(['parents', pageId]);
				} else {
					return state;
				}
			}

			case FieldConstants.FIELD_PUSH_SETTING_TO_STORE: 
			case FieldConstants.FIELD_PUSH_TO_STORE: {
				//Waiting for FieldStore to load Data 
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);

				let fieldId = action.get('recordId');
				return state.withMutations(state => {
					processField(state, {recordId: fieldId});
				});
			}

			case FieldConstants.FIELD_RECEIVE_BROADCAST: {
				//Waiting for FieldStore to load Data 
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);

				// This will typically only have one record, but we may need to update this for efficiency if that changes
				let records = action.get('records');

				return state.withMutations(newState => {
					records.forEach(record => {
						let fieldId = record.get('recordId');
						processField(newState, {recordId: fieldId});
					});
				});
			}

			case FieldConstants.FIELD_PUSH_CHILD_CONFIGURATION_TO_STORE: {
				//Waiting for FieldStore to load Data 
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);
				let parentRecordId = action.get('parentRecordId');
				return state.withMutations(state => {
					processField(state, {recordId: parentRecordId});
				});
			}

			case FieldConstants.FIELD_PULL_FROM_DATABASE: 
			case FieldConstants.FIELD_PULL_FROM_DATABASE_ALL: {
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);

				//Update all fields that came in
				return state.withMutations(state => {
					if (action.has('fieldArray')) {
						action.get('fieldArray').forEach((field) => {
							try {
								processField(state, {recordId: field.get('recordId')});
							} catch(err) {
								// Helps identify problem fields in infinite loops
								console.error('Error processing field', field);
								console.error('Error was', err);
							}
							
						});
					}
				});
			}

			case PageConstants.PAGE_PULL_FROM_DATABASE:
			case PageConstants.PAGE_PULL_FROM_DATABASE_ALL: {
				//Waiting for PageStore to load Data 
				AppDispatcher.waitFor([PageStore.getDispatchToken()]);

				//Update all fields that came in
				return state.withMutations(state => {
					if (action.has('pageArray')) {
						action.get('pageArray').forEach((page) => {
							processPage(state, page.get('recordId'));
						});
					}
				});
			}
			default:
				return state;
		}
	}
}

function processPage(state, pageId) {
	return state.withMutations((state) => {
		let page = {};
		let pageObj = PageStore.get(pageId);
		if (pageObj) {
			let childConfigurations = PageStore.getChildConfigurations(pageId) || {};
			
			let attachedFieldsArr = (pageObj 
				? ObjectUtils.getObjFromJSON(pageObj.attachedFields)
				: undefined);
			if (Array.isArray(attachedFieldsArr)) {
				attachedFieldsArr.forEach((childField) => {
					// Process the updated attachmentId format
					if(childField.attachmentId) {
						processAttachment(state, childField.attachmentId, childField.recordIds, page, childConfigurations);
					} else {
						//Check the settings of the child field to see if it has children
						processField(state, childField, page, childConfigurations)
					}
				});
			}
		} 
		state.setIn(['parents', pageId], Immutable.fromJS(page));
	});
}

/**
 * Processes an attached field or set of fields under the new field paradigm.
 *
 * @param {Immutable} state An Immutable object as passed into withMutations
 * @param {string} attachmentId The attachment ID of the attachment entry being processed
 * @param {array} recordIds Array of all fields potentially attached in this spot
 * @param {object} parent The parent object (to store, not in the store)
 * @param {object} parentChildConfiguration The childConfiguration value on the parent object
 */
function processAttachment(state, attachmentId, recordIds, parent, parentChildConfiguration) {
	// Load everything stored on the attachmentId key
	// (for both backwards compatibility with the old format and for some settings which are appropriately at this level)
	if(parentChildConfiguration && parentChildConfiguration[attachmentId]) {
		let childConfigurations = parentChildConfiguration[attachmentId];
		let attachmentSettings = {};
		Object.keys(childConfigurations).forEach(settingSchemaName => {
			if (Array.isArray(childConfigurations[settingSchemaName])) {
				childConfigurations[settingSchemaName].forEach(localConfig => {
					localConfig.screenSizes.forEach(screenSize => {
						if (!attachmentSettings[screenSize]) {
							attachmentSettings[screenSize] = {};
						}
						attachmentSettings[screenSize][settingSchemaName] = localConfig.value;
					});
				});
			} else if (settingSchemaName === 'visibility') {
				let visibilitySettings = childConfigurations[settingSchemaName];
				Object.keys(visibilitySettings).forEach(securityGroupId => {
					let localConfigArr = visibilitySettings[securityGroupId] || [];
					localConfigArr.forEach(localConfig => {
						['lg', 'md', 'sm'].forEach(screenSize => {
							if (!attachmentSettings[screenSize]) {
								attachmentSettings[screenSize] = {};
							}
							if(localConfig.screenSizes.indexOf(screenSize) > -1) {
								if (!attachmentSettings[screenSize][settingSchemaName]) {
									attachmentSettings[screenSize][settingSchemaName] = {};
								}
								attachmentSettings[screenSize][settingSchemaName][securityGroupId] = localConfig.value;
							}
						});
					});
				});
			} else {
				['lg', 'md', 'sm'].forEach(screenSize => {
					if (!attachmentSettings[screenSize]) {
						attachmentSettings[screenSize] = {};
					}
					attachmentSettings[screenSize][settingSchemaName] = 
						childConfigurations[settingSchemaName];
				});
			}
		});
		parent[attachmentId] = attachmentSettings;
	}

	if(recordIds && Array.isArray(recordIds)) {

		let settingsToDeleteFromAttachmentLevel = {};

		// Process the child fields. (Don't include parent information, because we handle that here.)
		recordIds.forEach(recordId => {
			// First see if we have anything on parentChildConfiguration[attachmentId] which applies to this specifically.
			let fieldObj = FieldStore.get(recordId);
			let fieldTypeObj = fieldObj ? FieldTypeStore.get(fieldObj.fieldType) : undefined;
			let fieldTypeSettings = {};
			if(fieldTypeObj && fieldTypeObj.settings) {
				fieldTypeObj.settings.forEach(({recordId}) => {
					let fieldSettingObj = FieldStore.get(recordId);
					if(fieldSettingObj) {
						fieldTypeSettings[fieldSettingObj.fieldSchemaName] = true;
					}
				});
			}


			// This should not override existing child configuration values
			if(parentChildConfiguration && parentChildConfiguration[attachmentId] && fieldTypeObj) {
				let childConfigurations = parentChildConfiguration[attachmentId];
				let attachmentSettings = {};
				Object.keys(fieldTypeSettings).forEach(settingSchemaName => {
					if(childConfigurations[settingSchemaName]) {
						if (Array.isArray(childConfigurations[settingSchemaName])) {
							settingsToDeleteFromAttachmentLevel[settingSchemaName] = true;
							childConfigurations[settingSchemaName].forEach(localConfig => {
								localConfig.screenSizes.forEach(screenSize => {
									if (!attachmentSettings[screenSize]) {
										attachmentSettings[screenSize] = {};
									}
									attachmentSettings[screenSize][settingSchemaName] = localConfig.value;
								});
							});
						} else if (settingSchemaName === 'visibility') {
							let visibilitySettings = childConfigurations[settingSchemaName];
							Object.keys(visibilitySettings).forEach(securityGroupId => {
								let localConfigArr = visibilitySettings[securityGroupId] || [];
								localConfigArr.forEach(localConfig => {
									['lg', 'md', 'sm'].forEach(screenSize => {
										if (!attachmentSettings[screenSize]) {
											attachmentSettings[screenSize] = {};
										}
										if(localConfig.screenSizes.indexOf(screenSize) > -1) {
											if (!attachmentSettings[screenSize][settingSchemaName]) {
												attachmentSettings[screenSize][settingSchemaName] = {};
											}
											attachmentSettings[screenSize][settingSchemaName][securityGroupId] = localConfig.value;
										}
									});
								});
							});
						} else {
							settingsToDeleteFromAttachmentLevel[settingSchemaName] = true;
							['lg', 'md', 'sm'].forEach(screenSize => {
								if (!attachmentSettings[screenSize]) {
									attachmentSettings[screenSize] = {};
								}
								attachmentSettings[screenSize][settingSchemaName] = 
									childConfigurations[settingSchemaName];
							});
						}
					}
				});
				// Only override if it's not already been set
				if(!parentChildConfiguration[attachmentId + '-' + recordId]) {
					parent[attachmentId + '-' + recordId] = attachmentSettings;
				}
				Object.keys(childConfigurations).forEach(settingSchemaName => {
					if (Array.isArray(childConfigurations[settingSchemaName])) {
						childConfigurations[settingSchemaName].forEach(localConfig => {
							localConfig.screenSizes.forEach(screenSize => {
								if (!attachmentSettings[screenSize]) {
									attachmentSettings[screenSize] = {};
								}
								attachmentSettings[screenSize][settingSchemaName] = localConfig.value;
							});
						});
					} else if (settingSchemaName === 'visibility') {
						let visibilitySettings = childConfigurations[settingSchemaName];
						Object.keys(visibilitySettings).forEach(securityGroupId => {
							let localConfigArr = visibilitySettings[securityGroupId] || [];
							localConfigArr.forEach(localConfig => {
								['lg', 'md', 'sm'].forEach(screenSize => {
									if (!attachmentSettings[screenSize]) {
										attachmentSettings[screenSize] = {};
									}
									if(localConfig.screenSizes.indexOf(screenSize) > -1) {
										if (!attachmentSettings[screenSize][settingSchemaName]) {
											attachmentSettings[screenSize][settingSchemaName] = {};
										}
										attachmentSettings[screenSize][settingSchemaName][securityGroupId] = localConfig.value;
									}
								});
							});
						});
					} else {
						['lg', 'md', 'sm'].forEach(screenSize => {
							if (!attachmentSettings[screenSize]) {
								attachmentSettings[screenSize] = {};
							}
							attachmentSettings[screenSize][settingSchemaName] = 
								childConfigurations[settingSchemaName];
						});
					}
				});
				parent[attachmentId] = attachmentSettings;
			}

			// Now we also need to add in the new overrides specific to both fields and attachment location
			if(parentChildConfiguration && parentChildConfiguration[attachmentId + '-' + recordId]) {
				let childConfigurations = parentChildConfiguration[attachmentId + '-' + recordId];
				let attachmentSettings = {};
				Object.keys(childConfigurations).forEach(settingSchemaName => {
					if (Array.isArray(childConfigurations[settingSchemaName])) {
						childConfigurations[settingSchemaName].forEach(localConfig => {
							localConfig.screenSizes.forEach(screenSize => {
								if (!attachmentSettings[screenSize]) {
									attachmentSettings[screenSize] = {};
								}
								attachmentSettings[screenSize][settingSchemaName] = localConfig.value;
							});
						});
					} else if (settingSchemaName === 'visibility') {
						let visibilitySettings = childConfigurations[settingSchemaName];
						Object.keys(visibilitySettings).forEach(securityGroupId => {
							let localConfigArr = visibilitySettings[securityGroupId] || [];
							localConfigArr.forEach(localConfig => {
								['lg', 'md', 'sm'].forEach(screenSize => {
									if (!attachmentSettings[screenSize]) {
										attachmentSettings[screenSize] = {};
									}
									if(localConfig.screenSizes.indexOf(screenSize) > -1) {
										if (!attachmentSettings[screenSize][settingSchemaName]) {
											attachmentSettings[screenSize][settingSchemaName] = {};
										}
										attachmentSettings[screenSize][settingSchemaName][securityGroupId] = localConfig.value;
									}
								});
							});
						});
					} else {
						['lg', 'md', 'sm'].forEach(screenSize => {
							if (!attachmentSettings[screenSize]) {
								attachmentSettings[screenSize] = {};
							}
							attachmentSettings[screenSize][settingSchemaName] = 
								childConfigurations[settingSchemaName];
						});
					}
				});
				parent[attachmentId + '-' + recordId] = attachmentSettings;
			}
			
			// Process the field and any non-attachment settings (visibility and automation, mostly)
			processField(state, {recordId}, parent, parentChildConfiguration);
		});

		// Now remove any settings which have been moved to be on the field level
		Object.keys(settingsToDeleteFromAttachmentLevel).forEach(settingSchemaName => {
			delete parent[attachmentId][settingSchemaName];
		})
	}
}

function processField(state, field, parent, parentChildConfiguration) {
	let fieldSettings = FieldStore.getSettings(field.recordId);
	state.setIn(['fields', field.recordId], Immutable.Map(fieldSettings));
	if (parentChildConfiguration && parentChildConfiguration[field.recordId]) {
		let childFieldConfiguration = parentChildConfiguration[field.recordId];
		let settings = {};
		Object.keys(childFieldConfiguration).forEach(settingSchemaName => {
			if (Array.isArray(childFieldConfiguration[settingSchemaName])) {
				childFieldConfiguration[settingSchemaName].forEach(localConfig => {
					localConfig.screenSizes.forEach(screenSize => {
						if (!settings[screenSize]) {
							settings[screenSize] = {};
						}
						settings[screenSize][settingSchemaName] = localConfig.value;
					});
				});
			} else if (settingSchemaName === 'visibility') {
				let visibilitySettings = childFieldConfiguration[settingSchemaName];
				Object.keys(visibilitySettings).forEach(securityGroupId => {
					let localConfigArr = visibilitySettings[securityGroupId] || [];
					localConfigArr.forEach(localConfig => {
						['lg', 'md', 'sm'].forEach(screenSize => {
							if (!settings[screenSize]) {
								settings[screenSize] = {};
							}
							if(localConfig.screenSizes.indexOf(screenSize) > -1) {
								if (!settings[screenSize][settingSchemaName]) {
									settings[screenSize][settingSchemaName] = {};
								}
								settings[screenSize][settingSchemaName][securityGroupId] = localConfig.value;
							}
						});
					});
				});
			} else {
				['lg', 'md', 'sm'].forEach(screenSize => {
					if (!settings[screenSize]) {
						settings[screenSize] = {};
					}
					settings[screenSize][settingSchemaName] = 
						childFieldConfiguration[settingSchemaName];
				});
			}
		});
		parent[field.recordId] = settings;
	}
	
	//Try and process this field's settings looking for field chooser fields
	let fieldObj = FieldStore.get(field.recordId);
	if (fieldObj) {
		let fieldTypeSettings = FieldTypeStore.get(fieldObj.fieldType);
		if (fieldTypeSettings && fieldTypeSettings.settings && fieldTypeSettings.hasChildSettings) {
			let parentField = {};
			let childConfigurations = FieldStore.getChildConfigurations(field.recordId);
			fieldTypeSettings.settings.forEach(fieldTypeSetting => {
				let settingObj = FieldStore.get(fieldTypeSetting.recordId);
				//If the fieldtype of the setting is for the fields chooser then process child fields
				if (settingObj && 
					
					(   // Child Fields on a Page
						settingObj.fieldType === '81976a6b-5973-4876-b999-d3d9b63ee2dd' || 
						// Child Fields on "Setting - Content Tab Builder"
						settingObj.fieldType === '5115adfb-bc59-471c-9f7f-e8c180528e37' || 
						// Child Fields on a List
						settingObj.fieldType === 'f3cc4b2c-e8cf-4fcc-a2a7-605b9bac0531') && 
					fieldSettings[settingObj.fieldSchemaName]) {
					let childFields = [];
					try {
						childFields = JSON.parse(fieldSettings[settingObj.fieldSchemaName]);
					} catch (error) {
						console.error('Unable to parse setting value %s for field %s. Value was', settingObj.fieldSchemaName, field.recordId, fieldSettings[settingObj.fieldSchemaName]);
					}
					if (Array.isArray(childFields)) {
						childFields.forEach(childField => {
							childField && childField.attachmentId ?
								processAttachment(state, childField.attachmentId, childField.recordIds, parentField, childConfigurations) : 
								processField(state, childField, parentField, childConfigurations);
						});
					}
				}
			});
			state.setIn(['parents', field.recordId], Immutable.fromJS(parentField));
		}
	}
}

const instance = new FieldSettingsStore(AppDispatcher);
export default instance;