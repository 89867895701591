import AppDispatcher from '../dispatcher/app-dispatcher';
import FieldTypeStore from './field-type-store';
import TableStore from './table-store';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import { FieldConstants } from '../constants/field-constants';
import GridUtils from '../utils/grid';

let fieldsByTableCache = {};

/**
 * Core store that contains field records
 *
 * @class FieldStore
 * @extends {ReduceStore}
 */
class FieldStore extends ReduceStore {
	/**
	 * getInitialState - initial state for FieldStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		//Preload the default page field because it is lookup too early in the process
		return Immutable.fromJS({
			allPulledFromDatabase: false,
			records: {}
		});
	}

	/**
	 * Called every time an action is dispatched (for any store)
	 *
	 * @param {Object} state - current state of this store
	 * @param {Object} action - action that's coming in
	 * @returns {Object} new state after the action has been processed.
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case FieldConstants.FIELD_HISTORY_APPEND_TO_STORE: {
				let recordId = action.get('recordId');
				let settingsHistoryJSON = '{}';
				let settingsHistory = {};

				if (state.hasIn(['records', recordId, 'settingsHistory'])) {
					// Read the settings JSON
					settingsHistoryJSON = state.getIn(['records', recordId, 'settingsHistory']);
				}

				try {
					settingsHistory = settingsHistoryJSON && typeof settingsHistoryJSON === 'string'
						? JSON.parse(settingsHistoryJSON)
						: (settingsHistoryJSON && settingsHistoryJSON.toJS ? settingsHistoryJSON.toJS() : {});
				} catch (e) {
					console.warn('Unable to json parse the settings history from field ' + recordId + ' : ' + settingsHistoryJSON);
				}

				// Update the settings
				let newHistory = {
					value: action.get('settingValue'),
					timestamp: +new Date(),
					userId: action.get('userId'),
					userName: action.get('userName'),
				};


				let patternId = action.get('patternId');
				if (patternId) {
					newHistory.patternId = patternId;
				}

				let valueQuality = action.get('valueQuality');
				newHistory.valueQuality = valueQuality ? valueQuality : 1;

				let settingSchemaName = action.get('settingSchemaName');
				if (!settingsHistory[settingSchemaName]) {
					settingsHistory[settingSchemaName] = [];
				}
				settingsHistory[settingSchemaName].push(newHistory);

				settingsHistory = JSON.stringify(settingsHistory);

				// Set the settings into the state['records']['recordId'] object... and return it.
				return state.withMutations(state => {
					let oldRecord = state.getIn(['records', recordId]) || Immutable.Map();
					let newRecord = oldRecord.withMutations(newRecord => {
						if (oldRecord.has('settingsHistory')) {
							let oldValue = oldRecord.get('settingsHistory') || Immutable.Map();
							let newValue = oldValue.withMutations(oldValue => {
								// If we're not already dirty and the original value hasn't been set, then set it
								if (!oldValue.get('isDirty') && oldValue.get('originalValue') === null) {
									oldValue.set('originalValue', oldValue.get('value'));
								}

								// This will never happen in practice, but just in case
								if (oldValue.get('originalValue') === settingsHistory) {
									// If the new value is the original value, then revert it and clear the isDirty flag
									oldValue.set('value', settingsHistory);
									oldValue.set('isDirty', false);
									oldValue.set('originalValue', null);
								} else {
									// Otherwise, just set the new value and mark it as dirty
									oldValue.set('value', settingsHistory);
									oldValue.set('isDirty', true);
								}
							});

							newRecord.set('settingsHistory', newValue);
						} else {
							newRecord.set('settingsHistory', Immutable.fromJS({
								value: settingsHistory,
								isDirty: true,
								inConflict: false,
								conflictValue: null,
								originalValue: ''
							}));
						}
					});
					// state.setIn(['records', recordId, 'settingsHistory'], JSON.stringify(settingsHistory));
					state.mergeDeepIn(['records', recordId], newRecord);
				});
			}
			case FieldConstants.FIELD_DELETE_FROM_DATABASE: {
				// Clear fieldsByTableCache
				fieldsByTableCache = {};
				// Deal with "cleaning" any dirty flags in the future.
				return state;
			}
			case FieldConstants.FIELD_DELETE_FROM_STORE: {
				// Clear fieldsByTableCache
				fieldsByTableCache = {};
				// Delete the record in the state['records']['recordId'] spot
				return state.deleteIn(['records', action.get('recordId')]);
			}

			case FieldConstants.FIELD_PUSH_TO_DATABASE: {
				let fieldObject = action.get('fieldObject');
				let newState = state;
				// Clean up any dirty values which have now been saved
				if (fieldObject) {
					let recordId = fieldObject.get('recordId');
					newState = state.withMutations(state => {
						let oldRecord = state.getIn(['records', recordId]) || Immutable.Map();
						oldRecord = oldRecord.withMutations(oldRecord => {
							fieldObject.forEach((newSettingValue, settingKey) => {
								// If this is a settings history object, make sure it's a JSON string
								// Otherwise it causes weird issues
								if (settingKey === 'settingsHistory') {
									newSettingValue = newSettingValue && typeof newSettingValue !== 'string' ?
										JSON.stringify(newSettingValue) :
										newSettingValue;
								}
								let oldValue = oldRecord.get(settingKey) || Immutable.Map();
								let newValue = oldValue.withMutations(oldValue => {
									oldValue.set('value', newSettingValue);
									oldValue.set('isDirty', false);
									oldValue.set('originalValue', null);
								});
								oldRecord.set(settingKey, newValue);
							});
						});
						// I believe that we want to merge deep in here as well
						state.mergeDeepIn(['records', recordId], oldRecord);
					});
				}
				// Clear fieldsByTableCache
				fieldsByTableCache = {};
				// Deal with "cleaning" any dirty flags in the future.
				return newState;
			}
			case FieldConstants.FIELD_PUSH_TO_STORE: {
				let recordId = action.get('recordId');
				let recordProperties = action.get('recordProperties');
				let forceClean = action.get('forceClean');

				// Clear fieldsByTableCache
				fieldsByTableCache = {};

				// Merge the recordProperties into the state['records']['recordId'] object... and return it.
				return recordId && recordProperties ? state.withMutations(state => {
					let oldRecord = state.getIn(['records', recordId]) || Immutable.Map();
					let newRecord = oldRecord.withMutations(newRecord => {
						recordProperties.forEach((newSettingValue, settingKey) => {
							if (settingKey === 'settings') {
								if (newSettingValue && typeof newSettingValue === 'string') {
									try {
										newSettingValue = JSON.parse(newSettingValue);
										newSettingValue = Immutable.fromJS(newSettingValue);
									} catch (error) {
										console.error('Unable to parse newSettingValue. Value was', newSettingValue);
										newSettingValue = Immutable.Map();
									}
								}
								newSettingValue.forEach((val, name) => {

									// @TODO: Should we do some type of setup about not overwriting existing settings?
									// Also what about child configurations?

									let oldValue = oldRecord.hasIn([name, 'value']) ?
										oldRecord.getIn([name, 'value']) :
										null;
									let isDirty = false;
									if (name.startsWith('automation-')) {
										isDirty = forceClean ? false : compareAutomation(oldValue, val);
									} else {
										// @TODO: Handle other settings
										isDirty = forceClean ? false : oldValue === val;
									}

									newRecord.set(name, Immutable.fromJS({
										value: val,
										isDirty: isDirty,
										originalValue: isDirty ? oldValue : null
									}));
								});
								newRecord.set('settings', Immutable.fromJS({
									value: null,
									isDirty: false,
									originalValue: null
								}));
							} else {
								if (oldRecord.has(settingKey)) {
									let oldValue = oldRecord.has(settingKey) ? oldRecord.get(settingKey) : Immutable.Map();
									let newValue = oldValue.withMutations(oldValue => {
										if (forceClean) {
											oldValue.set('value', newSettingValue);
											oldValue.set('isDirty', false);
											oldValue.set('originalValue', null);
										} else {
											// If we're not already dirty and the original value hasn't been set, then set it
											if (!oldValue.get('isDirty') && oldValue.get('originalValue') === null) {
												oldValue.set('originalValue', oldValue.get('value'));
											}

											if (oldValue.get('originalValue') === newSettingValue) {
												// If the new value is the original value, then revert it and clear the isDirty flag
												oldValue.set('value', newSettingValue);
												oldValue.set('isDirty', false);
												oldValue.set('originalValue', null);
											} else {
												// Otherwise, just set the new value and mark it as dirty
												oldValue.set('value', newSettingValue);
												oldValue.set('isDirty', true);
											}
										}
									});
									newRecord.set(settingKey, newValue);
								} else {
									newRecord.set(settingKey, Immutable.fromJS({
										value: newSettingValue,
										isDirty: forceClean ? false : true,
										inConflict: false,
										conflictValue: null,
										originalValue: forceClean ? null : ''
									}));
								}
							}
						});
					});
					// Merge the recordProperties into the state['records']['recordId'] object... and return it.
					// We want mergeDeepIn and not set in order to preserve old values
					state.mergeDeepIn(['records', recordId], newRecord);
					return state;
				}) : state;
			}
			case FieldConstants.FIELD_PUSH_SETTING_TO_STORE: {

				let settingKey = action.get('settingSchemaName');
				let newSettingValue = action.get('settingValue');
				let recordId = action.get('recordId');
				let forceClean = action.get('forceClean');


				return state.withMutations(state => {

					let oldRecord = state.getIn(['records', recordId]) || Immutable.Map();

					let newRecord = oldRecord.withMutations(newRecord => {
						if (oldRecord.has(settingKey)) {
							let oldValue = oldRecord.get(settingKey) || Immutable.Map();
							let newValue = oldValue.withMutations(oldValue => {
								if (forceClean) {
									oldValue.set('value', newSettingValue);
									oldValue.set('isDirty', false);
									oldValue.set('originalValue', null);
								} else {
									// If we're not already dirty and the original value hasn't been set, then set it
									if (!oldValue.get('isDirty') && oldValue.get('originalValue') === null) {
										oldValue.set('originalValue', oldValue.get('value'));
									}

									if (oldValue.get('originalValue') === newSettingValue) {
										// If the new value is the original value, then revert it and clear the isDirty flag
										oldValue.set('value', newSettingValue);
										oldValue.set('isDirty', false);
										oldValue.set('originalValue', null);
									} else {
										// Otherwise, just set the new value and mark it as dirty
										oldValue.set('value', newSettingValue);
										oldValue.set('isDirty', true);
									}
								}
							});
							newRecord.set(settingKey, newValue);
						} else {
							newRecord.set(settingKey, Immutable.fromJS({
								value: newSettingValue,
								isDirty: forceClean ? false : true,
								inConflict: false,
								conflictValue: null,
								originalValue: forceClean ? null : ''
							}));
						}
					});

					// Merge the recordProperties into the state['records']['recordId'] object... and return it.
					// We want mergeDeepIn and not set in order to preserve old values
					state.mergeDeepIn(['records', recordId], newRecord);

					// If we're changing the attached fields, we also want to update the field position

					if (settingKey === 'attachedFields') {
						// If so, lets setup the Obj part
						let fieldPositionObj = state.hasIn(['records', recordId, 'fieldPosition', 'value']) ? JSON.parse(state.getIn(['records', recordId, 'fieldPosition', 'value'])) : { 'lg': [] };
						let fieldPosition = GridUtils.reconcileFieldPositionsWithAttachedFields(fieldPositionObj, newSettingValue);

						// GridUtils already stringifies this, so we don't need to
						// @TODO: This should probably have better dirtiness checking + handling
						let oldVal = state.getIn(['records', recordId, 'fieldPosition', 'value']);
						state.setIn(['records', recordId, 'fieldPosition', 'value'], fieldPosition);
						if (oldVal !== fieldPosition) {
							state.setIn(['records', recordId, 'fieldPosition', 'oldValue'], oldVal);
							state.setIn(['records', recordId, 'fieldPosition', 'isDirty'], true);
						} else {
							state.setIn(['records', recordId, 'fieldPosition', 'oldValue'], null);
							state.setIn(['records', recordId, 'fieldPosition', 'isDirty'], false);
						}
					}
				});

			}
			case FieldConstants.FIELD_PUSH_CHILD_CONFIGURATION_TO_STORE: {
				// @TODO: Update this once we split child configurations
				let parentRecordId = action.get('parentRecordId');
				let childRecordId = action.get('childRecordId');
				let settingSchemaName = action.get('settingSchemaName');
				let value = action.get('value');
				return state.withMutations(state => {
					if (childRecordId) {
						let childConfigurations = state.hasIn(['records', parentRecordId, 'childConfigurations', 'value']) ?
							state.getIn(['records', parentRecordId, 'childConfigurations', 'value']) :
							'';
						let childConfigurationsObj = {};
						try {
							childConfigurationsObj = childConfigurations ? JSON.parse(childConfigurations) : childConfigurationsObj;
						} catch (err) {
							console.error('Unable to parse child configurations in parent record %s. Value was %s', parentRecordId, childConfigurations);
						}
						if (!childConfigurationsObj[childRecordId]) {
							childConfigurationsObj[childRecordId] = {};
						}
						// We want to delete null and undefined values; they're not valid overrides
						if (value !== null && typeof value !== 'undefined') {
							childConfigurationsObj[childRecordId][settingSchemaName] = value;
						} else {
							delete childConfigurationsObj[childRecordId][settingSchemaName];
						}
						let newChildConfigurations = JSON.stringify(childConfigurationsObj);
						let isDirty = newChildConfigurations === childConfigurations ? false : true;
						state.setIn(['records', parentRecordId, 'childConfigurations', 'value'], newChildConfigurations);
						state.setIn(['records', parentRecordId, 'childConfigurations', 'isDirty'], isDirty);
						if (isDirty) {
							state.setIn(['records', parentRecordId, 'childConfigurations', 'oldValue'], childConfigurations);
						} else {
							state.setIn(['records', parentRecordId, 'childConfigurations', 'oldValue'], null);
						}
					}
				});
			}
			case FieldConstants.FIELD_RECEIVE_BROADCAST: {
				// This is similar to pulling from the database, but needs to consider conflict work
				let records = action.get('records');
				return state.withMutations(newState => {
					records.forEach((record) => {
						let recordId = record.get('recordId');
						let oldRecord = newState.getIn(['records', recordId]) || Immutable.Map();
						let newRecord = oldRecord.withMutations(newRecord => {
							record.get('properties').forEach((value, key) => {
								// Skip fieldTypeJSON; we don't actually want it
								if (key === 'fieldTypeJSON') {
									return;
								}
								// Update the value

								let isDirty = oldRecord.hasIn([key, 'isDirty']) ? oldRecord.getIn([key, 'isDirty']) : false;
								if (!isDirty) {
									// If it's not dirty, then just update the value
									newRecord.setIn([key, 'value'], value);
									newRecord.setIn([key, 'isDirty'], false);
									newRecord.setIn([key, 'originalValue'], null);
								} else {
									let dirtyValue = oldRecord.getIn([key, 'value']);
									if (dirtyValue !== value) {
										// @TODO: How do we want to handle dirty values from an end user perspective?
										// Warn the user for now, I guess?
										let InterfaceActions = require('../actions/interface-actions').default;
										// Quick lookup of different settings which are not generally on the field type itself
										let quickLookup = {
											'fieldLabel': 'Field Label',
											'labelPosition': 'Label Position',
											'fieldPosition': 'Attached Field Positioning',
											'childConfigurations': 'Local Overrides',
											'viewVariant': 'Render Read as',
											'editVariant': 'Render View as',
											'requiredForSave': 'Required for Save',
											'automation-onBlur': 'Lose Focus',
											'automation-onBlurChange': 'Lose Focus and Change',
											'automation-onClick': 'Click',
											'automation-onFocus': 'Gain Focus',
											'automation-onEnterUp': 'On Enter Up',
											'automation-onMouseOut': 'Mouse Out',
											'automation-onMouseOver': 'Mouse Over',
											'automation-validate': 'Validate (Local)',
											'automation-preFieldSave': 'Validate (Global)',
											'automation-onPageLoad': 'After Page Loads',
											'automation-prePageSave': 'Page Validates',
											'automation-postPageSave': 'After Page Saves'
										};
										let label = quickLookup[key];
										// If it's not one of the quick-lookup settings, look it up from the store
										if (!label) {
											let FieldTypeStore = require('./field-type-store').default;
											let fieldType = oldRecord.getIn(['fieldType', 'value']);
											let setting = FieldTypeStore.get(fieldType).settings.filter(({ recordId }) => (state.hasIn(['records', recordId, 'fieldSchemaName', 'value']) ? state.getIn(['records', recordId, 'fieldSchemaName', 'value']) : '') === key);
											label = setting && setting.fieldLabel ? setting.fieldLabel : key;
										}
										// If we STILL have no label, 
										if (!label && key && key.endsWith('-visibility')) {
											label = 'Custom Visibility Logic';
										} else if (!label) {
											label = key;
										}
										InterfaceActions.stickyNotification({
											level: 'warning',
											title: 'Changed: ' + oldRecord.getIn(['fieldLabel', 'value']) + ' Field / ' + label + ' Setting',
											message: 'Your modified setting has been changed by another citizen developer. Save your work to overwrite their changes, or reload to accept their update.',
											id: 'field-' + recordId + '-' + key + '-conflict'
										});
										// Update the original value, since that reflects the DB now
										newRecord.setIn([key, 'originalValue'], value);
									} else {
										// The value in the DB is now actually the same as the "dirty" value, so we can just clean the value in the store
										newRecord.setIn([key, 'isDirty'], false);
										newRecord.setIn([key, 'originalValue'], null);
									}
								}
							});
						});
						newState.setIn(['records', recordId], newRecord);
					});
				});
			}
			case FieldConstants.FIELD_PULL_FROM_DATABASE: {

				let overwriteStore = action.get('overwriteStore');
				let fieldArray = action.get('fieldArray');

				return state.withMutations(state => {
					let recordMap = getRecordMap(fieldArray);
					if (overwriteStore) {
						state.mergeIn(['records'], recordMap);
					} else {
						// Start with the new records, then merge in what we have in the state already to avoid overwriting
						state.set('records', recordMap.mergeDeep(state.get('records')));
					}
				});
			}
			case FieldConstants.FIELD_PULL_FROM_DATABASE_ALL: {
				let overwriteStore = action.get('overwriteStore');
				let fieldArray = action.get('fieldArray');

				return state.withMutations(state => {
					let recordMap = getRecordMap(fieldArray);
					if (overwriteStore) {
						state.set('records', recordMap);
					} else {
						// Start with the new records, then merge in what we have in the state already to avoid overwriting
						state.set('records', recordMap.mergeDeep(state.get('records')));
					}
					state.set('allPulledFromDatabase', true);
				});
			}
			case FieldConstants.FIELD_PULL_ERROR: {
				console.error('Field Store Error: ' + action.get('error'));
				return state;
			}
			default: {
				return state;
			}
		}
	}

	/**
	 * Gets the entire store as an object
	 *
	 * @returns {Object} current store as an object
	 */
	getAll() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			let records = this.getState().get('records');
			let recordsObj = {};
			records.forEach((record, recordId) => {
				let recordObj = {};
				record.forEach((settingVal, settingKey) => {
					let val = settingVal.get('value');
					// *grumbles about automation trigger*
					if (val && val.toJS) {
						val = val.toJS();
					}
					recordObj[settingKey] = val;
				});
				recordsObj[recordId] = recordObj;
			});
			return recordsObj;
		} else {
			return undefined;
		}
	}

	/**
	 * Gets the entire store as an array
	 *
	 * @returns {Array} current store as an array
	 */
	getAllArray() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			let records = this.getState().get('records');
			let recordsArr = [];

			records.forEach((record) => {
				let recordObj = {};
				record.forEach((settingVal, settingKey) => {
					let val = settingVal.get('value');
					// *grumbles about automation trigger*
					if (val && val.toJS) {
						val = val.toJS();
					}
					recordObj[settingKey] = val;
				});
				recordsArr.push(recordObj);
			});
			return recordsArr;
		} else {
			return undefined;
		}
	}

	/**
	 * Get an array of fields on a table schema name
	 * 
	 * @param {string} tableSchemaName 
	 */
	getByTableSchemaName(tableSchemaName) {
		let state = this.getState();
		let allPulled = state.get('allPulledFromDatabase') === true;

		if (allPulled) {
			if (fieldsByTableCache[tableSchemaName]) {
				return JSON.parse(JSON.stringify(fieldsByTableCache[tableSchemaName]));
			}
			fieldsByTableCache[tableSchemaName] = [];
			state.get('records').forEach((fieldMap) => {
				if (fieldMap.getIn(['tableSchemaName', 'value']) === tableSchemaName) {
					let recordId = fieldMap.getIn(['recordId', 'value']);
					let field = this.get(recordId);
					fieldsByTableCache[tableSchemaName].push(field);
				}
			});
			return JSON.parse(JSON.stringify(fieldsByTableCache[tableSchemaName]));
		} else {
			return undefined;
		}

	}

	/**
	 * Get a field Object from of a FieldSchemaName in a given Table
	 * 
	 * @param {string} fieldSchemaName 
	 * @param {string} tableSchemaName 
	 */
	getByFieldSchemaName(fieldSchemaName, tableSchemaName) {
		if (this.getState().get('allPulledFromDatabase') === true) {
			let fields = this.getByTableSchemaName(tableSchemaName);
			let fieldToReturn = {};
			Object.keys(fields).forEach((fieldKey) => {
				let field = fields[fieldKey];
				if (field.fieldSchemaName === fieldSchemaName) {
					fieldToReturn = field;
				}
			});
			return fieldToReturn;
		} else {
			return undefined;
		}

	}

	/**
	 * Get an array of fields of a field type
	 * 
	 * @param {string} fieldType 
	 */
	getByFieldType(fieldType) {
		let state = this.getState();
		if (state.get('allPulledFromDatabase') === true) {
			let returnArray = [];
			state.get('records').forEach((fieldMap) => {
				if (fieldMap.getIn(['fieldType', 'value']) === fieldType) {
					let recordId = fieldMap.getIn(['recordId', 'value']);
					let field = this.get(recordId);
					returnArray.push(field);
				}
			});
			return returnArray;
		} else {
			return undefined;
		}

	}

	/**
	 * Get the Field ID of a field based on the FSN and TSN
	 * 
	 * @param {string} fieldSchemaName 
	 * @param {string} tableSchemaName 
	 */
	getFieldId(fieldSchemaName, tableSchemaName) {
		if (this.getState().get('allPulledFromDatabase') === true) {
			let fields = this.getState().get('records');
			let fieldId = null;
			fields.forEach((field, fieldKey) => {
				if (field && field.getIn(['tableSchemaName', 'value']) === tableSchemaName && field.getIn(['fieldSchemaName', 'value']) === fieldSchemaName) {
					fieldId = fieldKey;
				}
			});
			return fieldId;
		} else {
			return undefined;
		}
	}
	/**
	 * Get the Field schema name of a field based on the Field ID
	 * 
	 * @param {string} recordId 
	 * @returns {string} fieldSchemaName 
	 */
	getFieldSchemaName(recordId) {
		let state = this.getState();
		return recordId && state.hasIn(['records', recordId, 'fieldSchemaName', 'value']) ?
			state.getIn(['records', recordId, 'fieldSchemaName', 'value']) :
			undefined;
	}

	/**
	 * Gets an individual record from the store
	 * @param {string} recordId UUID of the record to get
	 * @returns {Object} current store as an object
	 */
	get(recordId, justDirty) {
		let state = this.getState();
		let rec = state.getIn(['records', recordId]);
		if (state.hasIn(['records', recordId])) {
			// We want these values even when only getting dirty values, as they're required for saving
			let toReturn = {
				recordId,
				fieldType: rec.getIn(['fieldType', 'value']),
				tableSchemaName: rec.getIn(['tableSchemaName', 'value']),
				fieldSchemaName: rec.getIn(['fieldSchemaName', 'value'])
			};
			try {
				// If we've recently split the settings value then we may as well just get the entire object
				// Otherwise we're at risk of erasing setting values
				if (rec.getIn(['settings', 'isDirty'])) {
					justDirty = false;
				}
				rec.forEach((settingVal, settingKey) => {
					let val = settingVal.get('value');
					let isDirty = settingVal.get('isDirty');
					// *grumbles about automation triggers*
					if (val && val.toJS) {
						val = val.toJS();
					}
					if (justDirty ? isDirty : true) {
						toReturn[settingKey] = val;
					}
				});
				// If the attached fields are being updated, then we need to make sure that the field positioning is as well
				if (justDirty && toReturn.attachedFields) {
					toReturn.fieldPosition = rec.getIn(['fieldPosition', 'value']);
				}
				return toReturn;
			} catch (error) {
				console.error('Error getting recordId %s. Raw record is', recordId, rec);
				throw error;
			}
		} else {
			return undefined;
		}
	}

	/**
	 * Calculate this field's schema length; aka its contribution to the 65535 and 8K byte lengths of one row in a database table.
	 * 
	 * Notes:
	 * For Short Text typed Field Types:
	 *	 schemaLength will be a byte length we can just use OR
	 *	 schemaLength will be set to maxLength, in which case the maxLength setting on the Field will be used (and length calculated based on the ><65 split above).  If it's missing, then the Field Type's maxLength will be used (><65 Calc'd).  If it's missing, 255 will be used (><65 Calc'd).
	 * For Multi-Part Field Types:
	 *   schemaLength wil be used for the main stored, JSON part.
	 *   And all "Stored" parts of multi-part fields follow the same as above, based on their type - the only difference is they don't have settings, so the maxLength set on the part will be used for ST Typed Parts.
	 * For all non-Short Text Typed Field Types:
	 *   schemaLength will be a byte length we can just use
	 * @param {Object} field 
	 * @return {number} The schema length for this field.
	 */
	getSchemaLength(field) {
		let fieldLength = 0;
		let fieldTypeObj = FieldTypeStore.get(field.fieldType);

		if(!fieldTypeObj) {
			return 0;
		}
		
		switch (fieldTypeObj.dataType) {
			case 'shortText': {
				if (fieldTypeObj.schemaLength) {
					if (fieldTypeObj.schemaLength === 'maxLength') {
						// console.log('schemaLength = maxLength');
						// console.log('Settings:', field);
						if (field.maxLength) {
							fieldLength = this._getSchemaLengthIn8KPercentage(parseInt(field.maxLength, 10));
							// console.log('Short Text Field...', field, fieldTypeObj);
							// console.log(fieldLength, 'calculated based on a FS maxLength of', field.maxLength);
						} else if (fieldTypeObj.maxLength) {
							fieldLength = this._getSchemaLengthIn8KPercentage(parseInt(fieldTypeObj.maxLength, 10));
							// console.log('Short Text Field...', field, fieldTypeObj);
							// console.log(fieldLength, 'calculated based on a FT maxLength of', fieldTypeObj.maxLength);
						} else {
							fieldLength = this._getSchemaLengthIn8KPercentage(255);
							// console.log('Short Text Field...', field, fieldTypeObj);
							// console.log(fieldLength, 'calculated based on 255.');
						}
					} else {
						fieldLength = parseInt(fieldTypeObj.schemaLength, 10);
						// console.log('Short Text Field...', field, fieldTypeObj);
						// console.log(fieldLength, 'calculated based on FT schemaLength');
					}
				} else { // schemaLength not set at all.. warn out.
					console.warn('Short Text Field Type missing important schemaLength property, using 255.', field.fieldType);
					fieldLength = this._getSchemaLengthIn8KPercentage(255);
				}
				break;
			}
			case 'file':
			case 'multipart': {
				// console.log('MultiPart Field...', fieldTypeObj);
				if (fieldTypeObj.schemaLength) {
					fieldLength = parseInt(fieldTypeObj.schemaLength, 10);
					// console.log(fieldLength, 'is starting length based on FT primary schemaLength');
					if (fieldTypeObj.parts) {
						fieldTypeObj.parts.forEach(part => {
							if (part.stored) {
								switch (part.storageDataType) {
									case 'shortText': {
										if (part.schemaLength && parseInt(part.schemaLength, 10) > 0) {
											fieldLength += parseInt(part.schemaLength, 10);
											// console.log('Short Text Part...', part);
											// console.log(fieldLength, 'adjusted based on part schemaLength', part.schemaLength);
										} else { // schemaLength not set at all.. warn out.
											console.warn('Short Text part missing important schemaLength property, using 255.', part);
											fieldLength += this._getSchemaLengthIn8KPercentage(255);
										}
										break;
									}
									default: {
										if (part.schemaLength) {
											fieldLength += parseInt(part.schemaLength, 10);
											// console.log(part.storageDataType, 'Part...', part);
											// console.log(fieldLength, 'adjusted based on Part schemaLength', part.schemaLength);
										} else {
											fieldLength += this._getSchemaLengthIn8KPercentage(255);
											// console.log(part.storageDataType, 'Part...', part);
											// console.log(fieldLength, 'adjusted by 255');
										}
										break;
									}
								}
							}
						})
					}
				}
				break;
			}
			case 'none': {
				fieldLength = 0;
				break;
			}
			default: {
				if (fieldTypeObj.schemaLength) {
					fieldLength = parseInt(fieldTypeObj.schemaLength, 10);
					// console.log(fieldTypeObj.dataType, 'Field...', field, fieldTypeObj);
					// console.log(fieldLength, 'calculated based on FT schemaLength', fieldTypeObj.schemaLength);
				} else {
					fieldLength = this._getSchemaLengthIn8KPercentage(255);
					// console.log(fieldTypeObj.dataType, 'Field...', field, fieldTypeObj);
					// console.log(fieldLength, 'calculated based on 255.');
				}
				break;
			}
		}
		return fieldLength;
	}

	/**
	 * For use when calculating how a field affects the 8K maximum bytes for
	 * a row.
	 * @param {number} length 
	 * @returns Number
	 */
	_getSchemaLengthIn8KPercentage(length) {
		/* Notes:
			What did we learn:
				12>13 characters is the count > pointer crossover (not 6).
				Pointer is 41 bytes per column, NOT 24.
				Under (and including) 12 its (12 * 3) + 1
				RecordID / "Slush" = 36 * 3
				VARCHAR(110) and below - [ 8K v 64K breakpoint ] - Pointer Math
				Above VARCHAR(110) we'll hit the 64K limit at 196 Columns.
				Pointer Columns take 0.51% of the 8K
				LONGTEXT = Pointer
				TEXT = Pointer
				FLOAT(53) = 9 bytes (8 + 1)
				Algorithms change at 1->12, 13->110, 110+
				Reset Relationship to 36.
				Check Parts, Set to 110 if under 110 and above 13.
		*/
		length = parseInt(length, 10);
		if (length <= 12) {
			return (length * 3) + 1;
		} else if (length <= 110) {
			return 41;
		} else {
			return Math.ceil(((length * 3) / 65535) * 8124);
		}
	}

	/**
	 * Gets a javascript array that is the setting history for a field setting.
	 * 
	 * @param {string} fieldRecordId Field Record ID to get the setting history for.
	 * @param {string} settingSchemaName Field settings to get the history for.
	 * @returns {array}
	 * 
	 * @memberOf FieldStore
	 */
	getSettingHistory(fieldRecordId, settingSchemaName) {
		let state = this.getState();
		if (state.hasIn(['records', fieldRecordId])) {
			let field = state.getIn(['records', fieldRecordId]);
			let settingsHistoryObj = field.hasIn(['settingsHistory', 'value']) ? field.getIn(['settingsHistory', 'value']) : undefined;
			if (settingsHistoryObj) {
				try {
					let settingsHistory = JSON.parse(settingsHistoryObj);
					return (settingsHistory[settingSchemaName] ?
						settingsHistory[settingSchemaName] :
						[]);
				} catch (error) {
					console.warn('Error Parsing JSON in getSettingHistory: ' + error.message);
					return [];
				}
			} else {
				return [];
			}
		} else {
			return undefined;
		}
	}

	/**
	 * Gets a javascript object that is the settings for a field.
	 * 
	 * @param {string} recordId Field Record ID to get the settings for.
	 * @returns {Object}
	 * 
	 * @memberOf FieldStore
	 */
	getSettings(recordId) {
		let field = this.get(recordId);
		if (field) {
			// Delete the 'extra' keys that weren't in field settings before
			// (Unlikely to cause issues, but just in case)
			delete field.recordId;
			delete field.fieldSchemaName;
			delete field.fieldType;
			return field;
		} else {
			return undefined;
		}
	}

	/**
	 * 
	 * @param {string} recordId Field Record ID to get the child configuration for.
	 * @param {string} childRecordId Optional. The record or attachment ID for which to find the local settings
	 * @param {string} settingSchemaName Optional. The specific setting to get
	 */
	getChildConfigurations(recordId, childRecordId, settingSchemaName) {
		let field = this.get(recordId);
		if (field) {

			let childConfigurations = field.childConfigurations;
			let childConfigurationsObj = {};
			try {
				childConfigurationsObj = childConfigurations ? JSON.parse(childConfigurations) : childConfigurationsObj;
			} catch (err) {
				console.error('Unable to parse child configurations in parent record %s. Value was %s', recordId, childConfigurations);
			}

			let toReturn = childConfigurationsObj;
			if (childRecordId) {
				if (settingSchemaName) {
					toReturn = childConfigurationsObj[childRecordId] ? childConfigurationsObj[childRecordId][settingSchemaName] : undefined;
				} else {
					toReturn = childConfigurationsObj[childRecordId];
				}
			}

			return toReturn;

			// All of this will be uncommented once we have the child configurations properly chunked up
			// let toReturn = {};
			// let sw = 'child-';
			// if(childRecordId) {
			// 	sw += childRecordId + '-';
			// 	if(settingSchemaName) {
			// 		sw += settingSchemaName;
			// 	}
			// }
			// Object.keys(page).forEach(key => {
			// 	if(key.startsWith(sw)) {
			// 		toReturn[key] = page[key];
			// 	}
			// });
			// return toReturn;
		} else {
			return undefined;
		}
	}

	/**
	 * 
	 * @param {string} recordId Field Record ID to get the child configuration for.
	 * @param {string} attachmentId Optional. The attachment ID for which to find the local settings.
	 * @param {string} childRecordId Optional. The record ID for which to find the local settings
	 * @param {string} settingSchemaName Optional. The specific setting to get
	 */
	getUpdatedChildConfigurations(recordId, attachmentId, childRecordId, settingSchemaName) {
		let FieldUtils = require('../utils/field-utils').default;
		let field = this.get(recordId);
		if(field) {

			let childConfigurations = field.childConfigurations;
			let childConfigurationsObj = {};
			try {
				childConfigurationsObj = childConfigurations ? JSON.parse(childConfigurations) : childConfigurationsObj;
			} catch(err) {
				console.error('Unable to parse child configurations in parent record %s. Value was %s', recordId, childConfigurations);
			}

			let attachedFields = field.attachedFields;
			let attachedFieldsArr = [];
			try {
				attachedFieldsArr = attachedFields ? JSON.parse(attachedFields) : attachedFieldsArr;
			} catch(err) {
				console.error('Unable to parse attached fields in parent record %s. Value was %s', recordId, attachedFields);
			}
			
			let toReturn = FieldUtils.convertChildConfig(childConfigurationsObj, attachedFieldsArr);
			if(attachmentId && !childRecordId) {
				if(settingSchemaName) {
					toReturn = childConfigurationsObj[attachmentId] ? childConfigurationsObj[attachmentId][settingSchemaName] : undefined;
				} else {
					toReturn = childConfigurationsObj[attachmentId];
				}
			} else if (attachmentId && childRecordId) {
				if(settingSchemaName) {
					// First check the specific location for the attachmentId and childRecordId combination
					if(childConfigurationsObj[attachmentId + '-' + childRecordId] && typeof childConfigurationsObj[attachmentId + '-' + childRecordId][settingSchemaName] !== 'undefined') {
						toReturn = childConfigurationsObj[attachmentId + '-' + childRecordId][settingSchemaName];
					} else if (childConfigurationsObj[attachmentId] && typeof childConfigurationsObj[attachmentId][settingSchemaName] !== 'undefined') {
						toReturn = childConfigurationsObj[attachmentId][settingSchemaName];
					} else {
						toReturn = undefined;
					}
				} else {
					if(childConfigurationsObj[attachmentId + '-' + childRecordId]) {
						toReturn = childConfigurationsObj[attachmentId + '-' + childRecordId];
					} else {
						toReturn = childConfigurationsObj[attachmentId];
					}
				}
			}

			return toReturn;

			// All of this will be uncommented once we have the child configurations properly chunked up
			// let toReturn = {};
			// let sw = 'child-';
			// if(childRecordId) {
			// 	sw += childRecordId + '-';
			// 	if(settingSchemaName) {
			// 		sw += settingSchemaName;
			// 	}
			// }
			// Object.keys(page).forEach(key => {
			// 	if(key.startsWith(sw)) {
			// 		toReturn[key] = page[key];
			// 	}
			// });
			// return toReturn;
		} else {
			return undefined;
		}
	}

	/**
	 * Get whether all have been pulled from the database or not yet.
	 * 
	 * @returns {boolean}
	 */
	allPulledFromDatabase() {
		return this.getState().get('allPulledFromDatabase');
	}
}

/**
 * Gets the record map with which to update the state based on a record from the DB
 * @param {Immutable.Map} state The state.
 * @param {Immutable.List} fieldArray The "array" of fields.
 */
function getRecordMap(fieldArray) {
	let recordMap = Immutable.Map().withMutations(recordMap => {
		fieldArray.forEach(metaRecord => {
			let recordId = metaRecord.get('recordId');
			let settings = metaRecord.get('settings');

			try {
				settings = settings ? JSON.parse(settings) : {};
			} catch(err) {
				console.warn('Unable to parse settings', {settings});
			}

			let newRecord = Immutable.Map().withMutations(newRecord => {
				// Break automation up into keys
				if (settings) {
					Object.keys(settings).forEach(settingName => {
						let val = settings[settingName];
						newRecord.set(settingName, Immutable.fromJS({
							value: val,
							isDirty: false,
							originalValue: null
						}));
					});
				}

				metaRecord.forEach((settingVal, settingKey) => {
					// JSON.parse any automation triggers in the DB
					// While we do this for pages, we hadn't been doing it for fields and it's probably best not to change that
					// However, I'm willing to change it for consistency's sake while everything is being changed + retested anyway
					// if(settingKey.startsWith('automation-') && settingVal) {
					// 	try {
					// 		settingVal = JSON.parse(settingVal);
					// 	} catch(err) {
					// 		console.error('Error: unable to parse JSON automation for trigger %s. Value was', settingKey, settingVal);
					// 	}
					// }
					if (settingKey !== 'settings') {
						// if(settingKey === 'fieldPositionExtras') {
						// 	if(settingVal.includes('autofit":"true')) {
						// 		console.log('Skipping setting key:', settingKey, 'on Field:', recordId);
						// 	}
						// } else {
							// Treat as forceClean = true
							newRecord.set(settingKey, Immutable.fromJS({
								value: settingVal,
								isDirty: false,
								originalValue: null
							}));
						// } 
					}
				});

				// Add the Search Suffix here..
				if(!newRecord.get('searchSuffix')) {
	
					// Generate the search suffix : 
					// TableSingularName + TablePluralName + Field Type Name
					let searchArray = [];
					let tableSchemaName = metaRecord.get('tableSchemaName');
					let fieldType = metaRecord.get('fieldType');
					
					// Find the Table
					if(tableSchemaName) {
						let tableObj = TableStore.getByTableSchemaName(tableSchemaName);
						if(tableObj && tableObj.singularName) {
							searchArray.push(tableObj.singularName);
						}
						if(tableObj && tableObj.pluralName) {
							searchArray.push(tableObj.pluralName);
						}
					}
	
					// Find the Field Type
					if(fieldType) {
						let fieldTypeObj = FieldTypeStore.get(fieldType);
						if(fieldTypeObj && fieldTypeObj.name) {
							searchArray.push(fieldTypeObj.name);
						}
					}

					// Add Field Schema Name
					if(metaRecord.get('fieldSchemaName')) {
						searchArray.push(metaRecord.get('fieldSchemaName'));
					}
	
					newRecord.set('searchSuffix', Immutable.fromJS({
						value: searchArray.join(':'),
						isDirty: false,
						originalValue: null
					}));
				}
			});
			recordMap.set(recordId, newRecord);
		});
	});
	return recordMap;
}

/**
 * 
 * @param {*} oldValue 
 * @param {*} triggerVal 
 */
function compareAutomation(oldValue, triggerVal) {
	if ((!triggerVal || !oldValue) && triggerVal !== oldValue) {
		// If we have an old value but not a new one or a new value but not an old one, it must be dirty
		return true;
	} else if (triggerVal === oldValue) {
		return false;
	} else {
		if (typeof oldValue === 'string') {
			// Turn oldValue into an Immutable Map
			try {
				oldValue = Immutable.fromJS(JSON.parse(oldValue));
			} catch (err) {
				console.error('Unable to parse oldValue %s in FieldStore.compareAutomation', oldValue);
			}
		}
		if (typeof triggerVal === 'string') {
			// Turn triggerVal into an Immutable Map
			try {
				triggerVal = Immutable.fromJS(JSON.parse(triggerVal));
			} catch (err) {
				console.error('Unable to parse triggerVal %s in FieldStore.compareAutomation', triggerVal);
			}
		}

		// Okay, we have both old and new values; compare their keys

		// One may have a key the other doesn't, so get the keys from both
		let keysDict = {};
		oldValue.forEach((val, key) => {
			keysDict[key] = true;
		});
		triggerVal.forEach((val, key) => {
			keysDict[key] = true;
		});
		let isDirty = false;
		Object.keys(keysDict).forEach(key => {
			let oldVal = oldValue.get(key);
			let newVal = triggerVal.get(key);
			// If any of the keys are different, isDirty is true
			if (oldVal !== newVal) {
				isDirty = true;
			}
		});
		return isDirty;
	}
}

// console.log('New Field Store');
class MyFieldStore extends FieldStore {
	getHasData(recordId) {
		if (recordId) {
			let fieldSettings = this.get(recordId);
			if(!fieldSettings) {
				console.warn('Missing field %s in field store', recordId);
			}
			let fieldType = fieldSettings && fieldSettings.fieldType;
			let fieldTypeConfig = FieldTypeStore.get(fieldType);
			if (fieldTypeConfig && (fieldTypeConfig.dataType === 'none' || fieldTypeConfig.dataType === 'relationship')) {
				return false;
			}
			return true;
		} else {
			return false;
		}
	}
	/**
	 * Looks up a field's field type, and then that field type's variant list for the default variant of the given type.
	 * 
	 * @param {string} fieldId Field ID you want the variant component name from.
	 * @param {string} variantType edit|view Which type of variant are you looking for?
	 * @param {string} fieldtype - Optional edit|view Which type of variant are you looking for?
	 * @return {string} Component Name of the default variant of the given type for this field ID's Field Type.
	 * @memberof FieldStore
	 */
	getDefaultVariantComponentName(fieldId, variantType, fieldTypeId) {

		if (!fieldId && !fieldTypeId) {
			console.warn('No fieldId and no FieldTypeId provided to get variantComponentName');
			return '';
		}

		if (!fieldTypeId && fieldId) {
			//Call the FieldStore to get the fieldObj 
			let fieldObj = window.FieldStore.get(fieldId);

			if (!fieldObj) {
				return '';
			}

			//Get the FieldObj.FieldType 
			fieldTypeId = (fieldObj && fieldObj.fieldType) ? fieldObj.fieldType : null;

			if (!fieldTypeId) {
				console.warn('Can not find Fieldtype of ', fieldObj.fieldType);
				return '';
			}

		}

		//Call the fieldStore FieldType and grab the variants 
		let fieldtypeObj = FieldTypeStore.get(fieldTypeId);
		if (!fieldtypeObj) {
			return '';
		}

		//Based on variant Type, grab the default one or the first one 
		let variantsArray = fieldtypeObj.variants,
			variantComponentName = '';

		variantsArray.forEach((variant, index) => {
			//Best Case: We find the variant type and it is the default 
			if (variant.type === variantType && (variant.default || variantComponentName === '')) {
				variantComponentName = variant.reactComponentName;
			}
		})
		return variantComponentName;
	}
}
const instance = new MyFieldStore(AppDispatcher);
export default instance;