import keymirror from 'keymirror';

export const ThemeConstants = keymirror({
	THEME_DELETE_FROM_DATABASE: null,
	THEME_DELETE_FROM_STORE: null,
	THEME_PULL_FROM_DATABASE: null,
	THEME_PULL_FROM_DATABASE_ALL: null,
	THEME_PULL_ERROR: null,
	THEME_PUSH_TO_DATABASE: null,
	THEME_PUSH_TO_STORE: null,
	THEME_RECEIVE_BROADCAST: null
});