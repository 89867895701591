// Actions
import { AdminSettingsActions, InterfaceActions, MetadataActions } from '../actions';

// APIs
import socketFetcher from './socket-fetcher';

// Stores
import { ContextStore, MetadataStore, ThemeTemplateStore } from '../stores';

// Utils
import { themes as ThemeUtils } from '@dmclain-citizendeveloper/citdev-utils';

export default class ThemeBuilderUtils {

	/**
	 * Return the Regex Mask for CSS Variable Names
	 * @returns regex
	 */
	static getCSSVariableMaskRegex() {
		return /^[a-z,A-Z,0-9,\-,_]*/
	}

	/**
	 * Get the Theme Template CSS for the Theme Template selected by the Template.
	 * @returns string
	 */
	static getThemeTemplateCSS(themeRecordId) {
		let theme = MetadataStore.get(themeRecordId,'theme')
		if(!theme || !theme.template ) {
			return '';
		}
		let themeTemplateObj = ThemeTemplateStore.get(theme.template);
		if(!themeTemplateObj) {
			return '/** No Template Selectd **/';
		}
		return themeTemplateObj.css;
	}

	/**
	 * Generate the Theme Variable CSS for the Theme.
	 * @param {*} themeRecordId 
	 * @returns string
	 */
	static getThemeVariableCSS(themeRecordId, themeTemplateRecordId) {
		let themeVariableJSON = ''
		if(themeRecordId) {
			let theme = MetadataStore.get(themeRecordId,'theme');
			if(!theme || !theme.variables) {
				return '';
			}
			themeVariableJSON = theme.variables;
		} else {
			let themeTemplate = ThemeTemplateStore.get(themeTemplateRecordId);
			if(!themeTemplate || !themeTemplate.variables) {
				return '';
			}
			themeVariableJSON = themeTemplate.variables;
		}
		return ThemeUtils.getThemeVariableCSS(themeVariableJSON, true);
	}

	/**
	 * Find the theme builder link and reload it.
	*/
	static reloadActiveTheme(themeId) {
		let newUrl = false;
		if(themeId) {
			newUrl = ContextStore.getBasePath() + '/gw/theme-css-v1?theme=' + themeId;
		}
		var link = document.getElementById("theme-builder");
		if (link && link.rel === "stylesheet") {
			if(newUrl) {
				link.href = newUrl;
			} else {
				link.href += '&1';
			}
		}
	}

	/**
	 * Adjuste the rel propery of the stylesheet link to disable/enable the theme.
	 * @param {boolean} hideTheme 
	 */
	static hideOrShowTheme(hideTheme){
		var link = document.getElementById("theme-builder");
		if(hideTheme) {
			if (link && link.rel === "stylesheet") {
				link.rel = "hide";
			}
		} else {
			if(link) {
				link.rel = 'stylesheet';
			}
		}
	}

	/**
	 * resetTheme - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event passed in as a convenience in case this is used later
	 * @param {string} recordId
	 * @param {string} tableSchemaName
	 */
	static resetLegacyTheme(event, recordId, tableSchemaName) {
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting Theme...' });
		// Pull from database, therefore resetting it
		MetadataActions.pullFromDatabase(recordId, tableSchemaName);
	}

	/**
	 * resetTheme - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event passed in as a convenience in case this is used later
	 * @param {string} recordId
	 * @param {string} tableSchemaName
	 */
	static resetTheme(event, recordId, tableSchemaName) {
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting Theme...' });
		// Pull from database, therefore resetting it
		MetadataActions.pullFromDatabase(recordId, tableSchemaName);
	}

	/**
	 * saveTheme - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event passed in as a convenience in case this is used later
	 * @param {string} recordId
	 * @param {string} tableSchemaName
	 */
	static saveLegacyTheme(event, recordId, tableSchemaName) {
		let valid = true;

		if(valid) {
			// Need to add the updated date time
			let metadataObj = MetadataStore.get(recordId, tableSchemaName);
			metadataObj.lastUpdatedDateTime = JSON.stringify({
				unixTimestamp: (Date.now() / 1000),
				timezone: 'America/New_York'});

			let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': 'Saving Theme...' });
			// Push to database
			MetadataActions.pushToDatabasePromise(metadataObj, tableSchemaName).then(() => {
				this.reloadActiveTheme();
				InterfaceActions.clearStickyNotification(id);
			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Theme' });
				console.error('Unable to save Theme:', error);
			})
		}
	}

	/**
	 * saveTheme - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event passed in as a convenience in case this is used later
	 * @param {string} recordId
	 * @param {string} tableSchemaName
	 */
	static saveTheme(event, recordId, tableSchemaName) {
		let valid = true;
		let metadataObj = MetadataStore.get(recordId, tableSchemaName);

		if(!metadataObj.template || metadataObj.template.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Template is required for a Theme.' });
			AdminSettingsActions.onSettingChange('template', 'cdec96cd-e0c3-495b-af31-98b559727124');
		}

		if(!metadataObj.name || metadataObj.name.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Name is required for a Theme.' });
			AdminSettingsActions.onSettingChange('name', '8f7d983b-43c5-4e6b-a766-b7ac3cff3194');
		}

		// Only save if the required fields are filled in.
		if(valid) {
			// We need to include the variables, and then the prefix before making the validate call.
			let variableCSS = this.getThemeVariableCSS(metadataObj.recordId, metadataObj.template);
			let themeTemplate = ThemeTemplateStore.get(metadataObj.template);

			// Generate the full sass content
			let sassContent = '';
			sassContent += (variableCSS ? variableCSS : '');
			sassContent += (themeTemplate && themeTemplate.prefix ? themeTemplate.prefix : '');
			sassContent += 'div.main-window {';
			sassContent += (themeTemplate && themeTemplate.css ? themeTemplate.css : '');
			sassContent += (metadataObj && metadataObj.css ? metadataObj.css : '');
			sassContent += '}';

			// Put up the validation Toast
			let validateId = InterfaceActions.stickyNotification({ 
				title: 'Sass/CSS Validating', 
				level: 'warning', 
				message: 'Validating SCSS/CSS Theme...' 
			});

			// Validate the Sass
			this.validateSASS(sassContent).then(checkResult => {
				if(checkResult.responseCode !== 200) {
					InterfaceActions.clearStickyNotification(validateId);
					metadataObj.errorText = checkResult.response;
					MetadataActions.pushToStore(recordId, tableSchemaName, metadataObj);
					InterfaceActions.notification({
						title: 'SCSS/CSS Validation Error',
						message: 'SCSS/CSS Validation error in Theme.  Check the "Theme SCSS" setting for details.',
						level: 'error'
					});
				} else {
					InterfaceActions.clearStickyNotification(validateId);
					metadataObj.errorText = '';
					// Need to add the updated date time
					metadataObj.lastUpdatedDateTime = JSON.stringify({
						unixTimestamp: (Date.now() / 1000),
						timezone: 'America/New_York'});
					metadataObj.new = '';
		
					let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': 'Saving Theme...' });
					// Push to database
					MetadataActions.pushToDatabasePromise(metadataObj, tableSchemaName).then(() => {
						MetadataActions.pushToStore(recordId, tableSchemaName, metadataObj);
						this.reloadActiveTheme()
						InterfaceActions.clearStickyNotification(id);
					}).catch(error => {
						InterfaceActions.clearStickyNotification(id);
						InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Theme' });
						console.error('Unable to save Theme:', error);
					})
				}
			}).catch(error => {
				InterfaceActions.clearStickyNotification(validateId);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to validate Theme' });
				console.error('Unable to validate Theme:', error);
			})
		}
	}

	/**
	 * Make a call to the API GW to find out if this theme compiles or not.
	 */
	static validateSASS(sass) {
		if(!sass || sass.length === 0) {
			return new Promise((resolve) => { return resolve({ responseCode: 200 }) });
		} else {
			return new Promise((resolve, reject) => {
				socketFetcher('gw/validate-theme-sass-v1', JSON.stringify({
					'sass': sass,
				})).then(data => {
					return resolve(data);
				})
				.catch(reject);
			});
		}
	}
}