import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { AdminSettingsActions, MetadataActions } from '../../../actions';
import { AdminSettingsStore, ContextStore, FieldStore, MetadataStore, ThemeTemplateStore } from '../../../stores';

// Components;
import FieldTypeSettingsUI from '../../fieldtype-settings-ui.react'

/**
 * Right panel displaying Theme settings
 */
class ThemeSetting extends Component {
	constructor(props) {
		super(props)
		this._onSettingChange = this._onSettingChange.bind(this);
	}
	
	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
	 return [ AdminSettingsStore, FieldStore, MetadataStore ]; 
	}

	/**
	 * Calculate the current state of the component
	 * @static
	 *
	 * @param  {Object} prevState Previous State
	 * @param  {Object} props     Previous Props
	 * @return {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId =  AdminSettingsStore.getRecordId();
		let settingRecordId = AdminSettingsStore.getSettingRecordId();
		let settingObj = FieldStore.get(settingRecordId);
		let value = '';

		// Pull the value from the MD Store
		if(settingObj){
			let settingSchemaName = settingObj.fieldSchemaName;
			let themeObj = MetadataStore.get(recordId, 'theme');
			if(themeObj) {
				value = themeObj[settingSchemaName];
			}
		}

		return {
			settingObj: settingObj,
			settingRecordId: settingRecordId,
			recordId: recordId,
			value: value,
			settingsHidden: AdminSettingsStore.getSettingsListHidden(),
		};
	}

	/**
	 * Save the value as a value is entered
	 * @param {Object} event
	 */
	_onSettingChange(event) {
		let { recordId, settingObj } = this.state;
		let settingSchemaName = settingObj.fieldSchemaName;
		let value = '';

		if(event && event.target) {
			value = event.target.value;
		} else {
			value = event;
		}
		let themeObj = MetadataStore.get(recordId, 'theme');

		// Update the Theme Object with this setting, at its new value
		themeObj[settingSchemaName] = value;

		// Push the update Theme Object into the store.
		MetadataActions.pushToStore(recordId, 'theme', themeObj);
	}

	/**
	 * Render the component
	 * @return JSX
	 */
	render() {
		let {recordId, value, settingObj, settingsHidden } = this.state;

		if (!settingObj || !settingObj.fieldSchemaName) {
			return (<div className="select-setting">
						<div className="select-setting-text-wrap">
							Select a Setting to configure on the left.
						</div>
					</div>);
		}

		let settingSchemaName = settingObj.fieldSchemaName;
		let settingFieldType = settingObj.fieldType;
		let descriptiveText = settingObj.descriptiveText;
		let label = settingObj.fieldLabel;

		// Update the description to the dark theme.
		// @TODO - Make this a parameter on the normal field types!
		if(settingSchemaName === 'description') {
		// 	settingObj.config = {
		// 		// Dark mode
		// 		skin:"oxide-dark",
		// 		content_css:"dark",
		// 	}
			settingObj.editVariant = 'longTextEdit';
			settingObj.height = '200px';
		}
		// For Variables, look up their header!
		if(settingSchemaName === 'variables') {
			let objectKey = settingObj.objectKey;
			if(objectKey) {
				let themeObj = MetadataStore.get(recordId, 'theme');
				if(themeObj.template) {
					let themeTemplate = ThemeTemplateStore.get(themeObj.template);
					settingObj.header = themeTemplate['description-' + objectKey + 'variables'] || null;
				}
			}
		}
		// For the Date/Time, parse it!
		if(settingSchemaName === 'lastUpdatedDateTime') {
			value = (value ? JSON.parse(value) : {});
			settingObj.viewVariant = 'dateTimeView';
			settingObj.editVariant = 'dateTimeView';
		}
		return (<div id="appearance-settings">
					<div className={"appearance-settings-container mx-3 setting-" + settingSchemaName}>
						<h3 className='my-1'>
							{/* Show collapse only when setting has been selected */} 
							{settingsHidden ?
								<button 
									className="btn btn-back pl-0"
									title="Show Theme settings." 
									form="appearance-form" 
									onClick={() => {AdminSettingsActions.onSettingsListHideChange(false);}}>
									<img height="26" 
									width="26" 
									src={ContextStore.getUrlMedia() + "/expand-settings-list.svg"} 
									alt="" />
								</button>
							: null }
							{label}
						</h3>
						<div className="setting-global mt-0">
							<div className={"setting-wrapper setting-" + settingSchemaName}>
								<FieldTypeSettingsUI
									dataRecordId={recordId}
									dataTableSchemaName='theme'
									recordId={recordId} 
									tableSchemaName='theme'
									value={value} 
									setting={settingObj} 
									onChange={this._onSettingChange} 
									fieldType={settingFieldType} 
									label={label}
									skipDescriptiveText={true}
									/>
								{descriptiveText && 
									<small id={'description-' + this.props.renderId} className='form-text text-muted' dangerouslySetInnerHTML={{ __html: descriptiveText || '' }}></small>
								}
							</div>
						</div>
					</div>
				</div>);
	}
}
const container = Container.create(ThemeSetting, {withProps: true});
export default container;