import AppDispatcher from '../dispatcher/app-dispatcher';
import AdminSettingsConstants from '../constants/admin-settings-constants';
import FieldStore from '../stores/field-store';
// import PageStore from '../stores/page-store';
import RenderStore from '../stores/render-store';
import RenderConstants from '../constants/render-constants';
import Immutable from 'immutable';
import UIUtils from '../utils/ui-utils';
import GridHeightUtils from '../utils/grid-height-utils';
import InterfaceActions from './interface-actions';

const AdminSettingsActions = {
	/**
	 * When the Sub Auotmation Tab changes.
	 * 
	 * @param {string} activeTab settings|assistant
	 */
	onAutomationTabChangeSub(activeTab) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.AUTOMATION_SUBTAB_CHANGE,
			'automationSubTab': activeTab
		}));
	},

    /**
     * onCenterColumnResize - passes state of responsive screen mode
     *
     * @param  {object} sizeObj   object with width and height properties
     */
	onCenterColumnResize(sizeObj) {
		clearTimeout(AdminSettingsActions.centerColumnResizeTimeout);

		let startTime = +new Date();
		let AdminSettingsStore = require('../stores/admin-settings-store').default;
		let PageStore = require('../stores/page-store').default;
		let mode = UIUtils.getResponsiveMode(sizeObj.width);

		let toRecalc = RenderStore.getGridsToRecalculate();
		let activeOverlays = AdminSettingsStore.getActiveOverlays();

		// Dispatch with the new size
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.CENTER_COLUMN_RESIZE,
			'sizeObj': sizeObj,
			'responsiveMode': mode,
			'lastChildCalculation': startTime
		}));

		// Now calculate the grids second
		let recalcPromises = toRecalc.map(toRecalc => {
			let {dataRecordId, dataTableSchemaName, componentId, renderId, renderParentId} = toRecalc;
			let pageObj = PageStore.get(componentId);
			return GridHeightUtils.initiatePage(pageObj, dataRecordId, dataTableSchemaName, renderId, renderParentId, activeOverlays);
		});
		AdminSettingsActions.centerColumnResizeTimeout = setTimeout(() => {
			Promise.all(recalcPromises)
				.then(nestedGrids => {
					let flattenedGrids = [];
					nestedGrids.forEach(grids => flattenedGrids = flattenedGrids.concat(grids));
					AppDispatcher.dispatch(Immutable.fromJS({
						'type': RenderConstants.GRID_UPDATE,
						'lastChildCalculation': startTime,
						'grids': flattenedGrids
					}));
				})
				.catch(error => {
					console.error('Error recalculating attached fields when changing active overlay.', error);
				});
		}, 200);
	},

	onChangeDisplayedSettings(selectedSettingsPanel) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.CHANGE_SELECTED_SETTINGS_PANEL,
			'selectedSettingsPanel': selectedSettingsPanel
		}));
	},

    /**
     * onComponentIdChange - triggered when the selected component
	 * in a multi-component display is toggled
     *
     * @param  {string} recordId New component ID
     * @return {type}
     */
	onComponentIdChange(recordId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.CHANGE_COMPONENT,
			'recordId': recordId
		}));
	},

    /**
     * onDashboardTabChange - communicates which dashboard tab is active
     *
     * @param {string} activeDashboardTab Which tab is now open
     */
	onDashboardTabChange(activeDashboardTab) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.LEFT_PANEL_TAB_CHANGE,
			'activeDashboardTab': activeDashboardTab
		}));
	},

    /**
     * onLeftPanelChange - communicates if left panel is open
     *
     * @param {string} activeDashboard Which map is now open
     * @param {boolean} isLeftPanelOpen 
     */
	onLeftPanelChange(activeDashboard, isLeftPanelOpen) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.LEFT_PANEL_CHANGE,
			'activeDashboard': activeDashboard,
			'isLeftPanelOpen': isLeftPanelOpen
		}));
	},

// onModalClose
    /**
     * closeModal - closes modal with modalId identification
     *
     * @param  {String} modalId - identifier for modal to be removed if null it removes the last one opened
     */
	closeModal(modalId = null) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.CLOSE_MODAL,
			'modalId': modalId
		}));
	},

//onModalOpen
	/**
     * openModal - opens modal with modalId identfication and component as content
     *
     * @param  {String} modalId - identifier for modal
     * @param  {Component} component - which will be rendered in the modal
     */
	openModal(modalId, component) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.OPEN_MODAL,
			'modalId': modalId,
			'component': component
		}));
	},

    /**
     * onOverlayChange - triggered when overlay is toggled
     *
     * @param  {string} activatedOverlay overlay clicked
     * @return {type}
     */
	onOverlayChange(activatedOverlay) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.OVERLAY_CHANGE,
			'activatedOverlay': activatedOverlay
		}));
	},

	/**
     * onOverlayChange - triggered when overlay is toggled
	 * 
	 * This overlay will trigger field visibility recalculation when run
     *
     * @param  {string} activatedOverlay overlay clicked
     * @return {type}
     */
	onOverlayChangeWithRecalculation(activatedOverlay) {
		// * Recalculate the visibility on all grid components with child fields
		// * Dispatch the updated grid components to the render store
		// * also dispatch this to admin settings store
		// in order to indicate that the resizer should be toggled on
		let startTime = +new Date();
		let AdminSettingsStore = require('../stores/admin-settings-store').default;
		let PageStore = require('../stores/page-store').default;

		let withOrWithoutVisibilityRules = '';
		let toRecalc = RenderStore.getGridsToRecalculate();
		// toRecalc will always be page objects, so just run initiatePage for now.
		let activeOverlays = AdminSettingsStore.getActiveOverlays();
		if(activeOverlays.includes(activatedOverlay)) {
			activeOverlays = activeOverlays.splice(activeOverlays.indexOf(activatedOverlay), 1);
			withOrWithoutVisibilityRules = 'with';
		} else {
			activeOverlays.push(activatedOverlay);
			withOrWithoutVisibilityRules = 'without';
		}
		let toast = InterfaceActions.stickyNotification({message: 'Recalculating fields ' + withOrWithoutVisibilityRules + ' visibility rules...'});

		let recalcPromises = toRecalc.map(toRecalc => {
			let {dataRecordId, dataTableSchemaName, componentId, renderId, renderParentId} = toRecalc;
			let pageObj = PageStore.get(componentId);
			return GridHeightUtils.initiatePage(pageObj, dataRecordId, dataTableSchemaName, renderId, renderParentId, activeOverlays);
		});
		Promise.all(recalcPromises)
			.then(nestedGrids => {
				let flattenedGrids = [];
				nestedGrids.forEach(grids => flattenedGrids = flattenedGrids.concat(grids));
				AppDispatcher.dispatch(Immutable.fromJS({
					type: AdminSettingsConstants.OVERLAY_CHANGE_WITH_RECALC,
					activatedOverlay: activatedOverlay,
					lastChildCalculation: startTime,
					grids: flattenedGrids
				}));
				InterfaceActions.clearStickyNotification(toast);
			})
			.catch(error => {
				InterfaceActions.stickyNotification({message: 'Error recalculating fields ' + withOrWithoutVisibilityRules + ' visibility rules. Please check your console for more information', level: 'error'});
				console.error('Error recalculating attached fields when changing active overlay.', error);
				InterfaceActions.clearStickyNotification(toast);
			});
	},

	/**
	 * Setup the setting schema name for the query setting we're working on .
	 * 
	 * @param {string} settingSchemaName 
	  */
	onQuerySettingChange(settingSchemaName) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.QUERY_SETTING_CHANGE,
			'settingSchemaName': settingSchemaName
		}));
	},

	/**
     * onReceiveFieldSuggestions - Store Table Field Suggestions.
     *
     * @param  {object} suggestions
     */
	onReceiveTableFieldSuggestions(suggestions) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.TABLE_FIELD_SUGGESTIONS_CHANGE,
			'suggestions': suggestions
		}));
	},

	/**
     * onReceiveTableRelationshipSuggestions - Store Table Relationship Suggestions.
     *
     * @param  {object} suggestions
     */
	onReceiveTableRelationshipSuggestions(suggestions) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.TABLE_RELATIONSHIP_SUGGESTIONS_CHANGE,
			'suggestions': suggestions
		}));
	},

	/**
     * onReceiveRelationshipSuggestions - Store Relationship Suggestions.
     *
     * @param  {object} suggestions
     */
	onReceiveRelationshipSuggestions(suggestions) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.RELATIONSHIP_SUGGESTIONS_CHANGE,
			'suggestions': suggestions
		}));
	},

	/**
     * onReceiveFieldSuggestions - Store Table Suggestions.
     *
     * @param  {object} suggestions
     */
	onReceiveTableSuggestions(suggestions) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.TABLE_SUGGESTIONS_CHANGE,
			'suggestions': suggestions
		}));
	},

	/**
     * onClearRelationshipSuggestion - Clear Relationship Suggestions.
	 * 
	 * @param  {object} suggestions
     */
	onClearRelationshipSuggestion() {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.RELATIONSHIP_SUGGESTIONS_CLEAR,
		}));
	},

	/**
     * onClearTableFieldSuggestion - Clear Table Field Suggestions.
	 * 
	 * @param  {object} suggestions
     */
	onClearTableFieldSuggestion() {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.TABLE_FIELD_SUGGESTIONS_CLEAR,
		}));
	},

	/**
     * onClearTableRelationshipSuggestion - Clear Table Relationship Suggestions.
	 * 
	 * @param  {object} suggestions
     */
	onClearTableRelationshipSuggestion() {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.TABLE_RELATIONSHIP_SUGGESTIONS_CLEAR,
		}));
	},

	/**
     * onClearFieldSuggestions - Clear Table Suggestions.
	 * 
	 * @param  {object} suggestions
     */
	onClearTableSuggestion(suggestions) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.TABLE_SUGGESTIONS_CLEAR,
			'suggestions': suggestions
		}));
	},

    /**
     * onRightPanelChange - communicates if right panel is open, as well as if it should be expanded
     *
     * @param  {boolean} isRightPanelOpen
     * @param  {boolean} isRightPanelExpanded
     */
	onRightPanelChange(isRightPanelOpen, isRightPanelExpanded) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.RIGHT_PANEL_CHANGE,
			'isRightPanelOpen': isRightPanelOpen
		}));
	},

	onSettingChange(settingSchemaName, settingRecordId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.SETTING_CHANGE,
			'settingSchemaName': settingSchemaName,
			'settingRecordId': settingRecordId
		}));
	},

	onSettingsListHideChange(settingsHide) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.SETTINGS_LIST_HIDE,
			'settingsHide': settingsHide
		}));
	},
	/**
	 * When the Dashboard that we want selected changes, due to activity
	 * in the Settings Panel, or Settings Chooser Dropdown.
	 * 
	 * @param {string} activeTab main | parent
	 */
	onSettingsPanelDashboardChange(settingsPanelDashboard) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.SETTINGS_PANEL_DASHBOARD_CHANGE,
			'settingsPanelDashboard': settingsPanelDashboard
		}));
	},

	/**
	 * Inform the Admin Settings Store if the settings panel is expaneded
	 * @param {boolean} isSettingsPanelExpanded 
	 */
	onSettingsPanelExpand(isSettingsPanelExpanded) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.SETTINGS_PANEL_EXPAND,
			'isSettingsPanelExpanded': isSettingsPanelExpanded
		}));
	},

	/**
     * onSettingsPreviewChange - communicates if the settings preview panel is open
     *
     * @param  {boolean} isSettingsPreviewOpen
     */
	onSettingsPreviewChange(isSettingsPreviewOpen) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.SETTINGS_PREVIEW_CHANGE,
			'isSettingsPreviewOpen': isSettingsPreviewOpen
		}));
	},

	/**
	 * When the Main Settings Tab changes.
	 * 
	 * @param {string} activeTab settings|assistant
	 */
	onSettingsTabChangeMain(activeTab) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.SETTINGS_MAINTAB_CHANGE,
			'settingsMainTab': activeTab
		}));
	},

	/**
	 * When the Settings Sub-Tab changes.
	 * 
	 * @param {string} activeTab main | parent
	 */
	onSettingsTabChangeSub(activeTab) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.SETTINGS_SUBTAB_CHANGE,
			'settingsSubTab': activeTab
		}));
	},

    /**
     * onShowComponentDetails - displays details of component
     *
     * @param  {string} recordId          		unique id of thing being "settings"'d
     * @param  {string} tableSchemaName   		what kind of thing are we settings - ing?
     * @param  {string} parentRecordId          unique id of thing being "settings"'d parent (list id if it's a field on a list, parent id if its a field on a page.)
     * @param  {string} parentTableSchemaName   what kind of thing are we settings - ing? 'd parent 
     * @param  {string} gridKey           		id of component in grid
     * @param  {string} fieldType         		field type
     * @param  {string} selectedOverlay   		overlay selected based on pin clicked
	 * @param  {string} recordId          		render id of thing being "settings"'d
     */
	onShowComponentDetails(recordId, renderId, attachmentId, tableSchemaName, parentRecordId, parentTableSchemaName, gridKey, fieldType, selectedOverlay) {
		if (!fieldType) {
			if (tableSchemaName === 'field') {
				let fieldObj = FieldStore.get(recordId);
				fieldType = fieldObj.fieldType;
			}
		}
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.SHOW_SETTINGS,
			'gridKey': gridKey,
			'recordId': recordId,
			'renderId': renderId,
			'attachmentId': attachmentId,
			'tableSchemaName': tableSchemaName,
			'parentRecordId': parentRecordId,
			'parentTableSchemaName': parentTableSchemaName,
			'fieldType': fieldType,
			'selectedOverlay': selectedOverlay
		}));
	},

	onSubSettingChange(subSettingSchemaName, subSettingIndex) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.SUB_SETTING_CHANGE,
			'subSettingSchemaName': subSettingSchemaName,
			'subSettingIndex': subSettingIndex
		}));
	},
	
	onVisibilityControlsAddAndEdit(controlsAddAndEdit) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AdminSettingsConstants.VISIBILITY_CONTROLS_ADD_AND_EDIT,
			'controlsAddAndEdit': controlsAddAndEdit
		}));
	},    
};

export default AdminSettingsActions;