import { each, size } from 'lodash';
import { ComplexFields, GroupFilters, PageRolesConstants, TableRolesConstants } from '../constants';
import FieldTypeStore from '../stores/field-type-store';
import TableStore from '../stores/table-store';
import ObjectUtils from './object-utils';


/**
 * Utils class for SiteMap
 */
export default class MapUtils {
  /**
   * Returns a filtered array of FieldTypeObjects with a flag key for cases where they contain overrides 
   */
  static filteredFieldTypes(fieldTypes, fieldTypeVariantOverrideNames, search, groupBy) {
    let filteredFieldTypes = [];
    let searchArray = search ? search.split(/ /g) : [];

    // Parse the configurationJSON for each field type
    fieldTypes.forEach(fieldType => {
      let fieldTypeObj = fieldType;
      let fieldTypeInSearch = false;

      // Add Flag if Fieldtype name is in the search
      for (let j = 0; j < searchArray.length; j++) {
        let searchWord = searchArray[j];
        if (searchWord.length && fieldType.name && fieldType.name.toLowerCase().includes(searchWord)) {
          fieldTypeInSearch = true;
        }
      }

      //Does this fieldtype has variants?
      if (fieldType.configurationJson) {
        fieldTypeObj['configJsonObj'] = ObjectUtils.getObjFromJSON(fieldType.configurationJson);
        fieldTypeObj['variants'] = [];

        if (fieldTypeObj.configJsonObj.variants) {
          // Check if any variant has an override and flag both the variant and the fieldtype
          fieldTypeObj.configJsonObj.variants.forEach((variantObj, variantIndex) => {

            // groupBy Filter => Add Flag if Fieldtype is "changed"
            if (fieldTypeVariantOverrideNames.includes(variantObj.reactComponentName)) {
              // variant has override
              variantObj['override'] = true;
              // Fieldtype contains override variants
              fieldTypeObj['hasOverrides'] = true;
            }

            // Search Filter => 
            // Add Flag if Fieldtype variant is in the search AND 
            // add the variant to the fieldtypeObj.variants array
            if (searchArray.length) {
              for (let j = 0; j < searchArray.length; j++) {
                let searchWord = searchArray[j];
                if (searchWord.length && (
                  (variantObj.reactComponentName && variantObj.reactComponentName.toLowerCase().includes(searchWord)) ||
                  (variantObj.name && variantObj.name.toLowerCase().includes(searchWord)))
                ) {
                  // Variant is in the Search
                  fieldTypeObj['variants'].push(variantObj);
                  // Fieldtype contains variants we maybe searching for
                  fieldTypeInSearch = true;
                }
              }
            } else {
              // If no search just add them all
              fieldTypeObj['variants'].push(variantObj);
            }
          });
        }
      }
      //Add them all regardless if it has overrides or not
      if (groupBy !== 'changed') {
        // Add them all if there is no search Filter
        if (!searchArray.length) {
          filteredFieldTypes.push(fieldTypeObj);
        } else if (fieldTypeInSearch) {
          // Add them only if the fieldtype or a fieldtype variant is in the search
          filteredFieldTypes.push(fieldTypeObj);
        }
        // Only add the fieldtype to the final list if it hasOverrides
      } else if (groupBy === 'changed' && fieldTypeObj['hasOverrides']) {
        // Add them all if there is no search Filter
        if (!searchArray.length) {
          filteredFieldTypes.push(fieldTypeObj);
        } else if (fieldTypeInSearch) {
          // Add them only if the fieldtype or a fieldtype variant is in the search
          filteredFieldTypes.push(fieldTypeObj);
        }
      }
    });

    // Sort the Field Types by Name.
    filteredFieldTypes = filteredFieldTypes.sort(function (a, b) {
      if ((a.name && b.name) && a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 1;
    });

    return filteredFieldTypes;
  }
  /**
   * Group array by groupBy
   *
   * @params {Array} of objects
   * @params {String} required groupBy from GroupFilter
   * @return {Object} with key as group key and value as array of pages from that group
   */
  static groupBy(array, groupBy, nameField, noNameValue) {
    let groups = {};

    if (!array || !array[0]) {
      return groups;
    }
    
    // Determine how we're sorting...
    if(!nameField) {
      nameField = 'name';
      if(array[0]['pluralName']) {
        nameField = 'pluralName';
      } else if (array[0]['fieldLabel']) {
        nameField = 'fieldLabel';
      }
    }

    // If there is no value for when there is no name.. use 'null'
    if(!noNameValue) {
      noNameValue = 'null';
    }

    // Sort the full array of values... We use upper case here so that [ goes down to the bottom.
    array.sort(function (a, b) {
      a[nameField] = a[nameField] ? a[nameField] : noNameValue;
      b[nameField] = b[nameField] ? b[nameField] : noNameValue;
      
      // if(a[nameField].slice(0,1) === '[')
      if (a[nameField].toUpperCase() < b[nameField].toUpperCase()) {
        return -1;
      }
      return 1;
    });

    switch (groupBy) {
      case GroupFilters.NAME: {
        groups.name = array;
        break;
      }
      case GroupFilters.COMPLIANCE: {
        array.forEach(p => {
          if(p.name) {
            groups[p.name] = p.tags;
          }
        });

        break;
      }
      case GroupFilters.TABLE_ROLE: {
        each(array, (p) => {
          if (size(p) > 0) {
            let roles = p.roles ? p.roles.split(',') : ['Empty Role'];
            each(roles, (r) => groups[r] ? groups[r].push(p) : groups[r] = [p]);
          }
        });
        each(TableRolesConstants, (role) => {
          if (!groups[role]) {
            groups[role] = [];
          }
        });
        break;
      }
      case GroupFilters.PAGE_ROLE: {
        each(array, (p) => {
          if (size(p) > 0) {
            let roles = p.roles ? p.roles.split(',') : ['Empty Role'];
            each(roles, (r) => groups[r] ? groups[r].push(p) : groups[r] = [p]);
          }
        });
        each(PageRolesConstants, (role) => {
          if (!groups[role]) {
            groups[role] = [];
          }
        });
        break;
      }
      case GroupFilters.TABLE_NAME: {
        each(array, (p) => {
          if (size(p) > 0) {
            let table = (p.tableSchemaName) ? p.tableSchemaName : '';
            if (!table && p.dataTableSchemaName) {
              table = p.dataTableSchemaName;
            }
            //Grab the Table Object 
            let tableObj = TableStore.getByTableSchemaName(table);
            //Pass the Plural Name as the Key 
            let pluralNameKey = (tableObj && tableObj.pluralName) ? tableObj.pluralName : 'No table set';
            groups[pluralNameKey] ? groups[pluralNameKey].push(p) : groups[pluralNameKey] = [p];
          }
        });
        break;
      }
      case GroupFilters.FIELD_TYPE: {
        each(array, (p) => {
          if (size(p) > 0) {
            let fieldTypeId = (p.fieldType) ? p.fieldType : '';
            //Grab the Table Object 
            let fieldTypeObj = (fieldTypeId ? FieldTypeStore.get(fieldTypeId) : {});
            if(fieldTypeObj && !fieldTypeObj.hideFromCitDev) {
              //Pass the name as the Key 
              let key = (fieldTypeObj && fieldTypeObj.name) ? fieldTypeObj.name : 'No Field Type set';
              groups[key] ? groups[key].push(p) : groups[key] = [p];
            }
          }
        });
        break;
      }
      default: {
        groups.name = array;
      }
    }
    Object.keys(groups).sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      return 1;
    });

    let pagesArray = [];
    //Create a sorted array of keys 
    Object.keys(groups).forEach(pageKey => {
      pagesArray.push(pageKey);
    });

    pagesArray.sort(function (a, b) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      return 1;
    });

    let newGroupPages = {};
    pagesArray.forEach(key => {
      newGroupPages[key] = groups[key];
    });


    return newGroupPages;
  }

  /**
   * Returns sort function to be used at Array.sort
   *
   * @params {String} column name from object in the array
   * @params {String} ASC or DESC
   * @returns {Function}
   */
  static simpleSortFunction(column, direction) {
    if (direction === 'ASC') {
      return (a, b) => (a[column] > b[column]) ? 1 : -1;
    } else {
      return (a, b) => (a[column] < b[column]) ? 1 : -1;
    }
  }

  /**
   * Returns sort function to be used at Array.sort
   *
   * @params {String} column name from object in the array
   * @params {String} ASC or DESC
   * @returns {Function}
   */
  static getFieldSortFunction(column, direction) {
    let regexStr = `^(${ComplexFields.SETTINGS}|${ComplexFields.FIELD_TYPE})$`,
      regex = new RegExp(regexStr, 'gi');

    if (column.match(regex)) {
      return MapUtils.customSortFunction(column, direction);

    } else {
      return MapUtils.simpleSortFunction(column, direction);
    }
  }

  /**
   * Returns sort function to be used at Array.sort
   *
   * @params {String} column name from object in the array
   * @params {String} ASC or DESC
   * @returns {Function}
   */
  static getRecordSortFunction(column, direction) {
    if (direction === 'ASC') {
      return (a, b) => {
        if (a[column] && b[column]) {
          return (a[column].value > b[column].value) ? 1 : -1;
        }
        return 0;
      }
    } else {
      return (a, b) => {
        if (a[column] && b[column]) {
          return (a[column].value < b[column].value) ? 1 : -1;
        }
        return 0;
      }
    }
  }

  /**
   * Returns sort function to be used at Array.sort
   *
   * @params {String} column name from object in the array
   * @params {String} ASC or DESC
   * @returns {Function}
   */
  static getPagedSortFunction(column, direction) {
    return MapUtils.simpleSortFunction(column, direction);
  }
  /**
   * Returns sort function to be used at Array.sort
   *
   * @params {String} column name from object in the array
   * @params {String} ASC or DESC
   * @returns {Function}
   */
  static customSortFunction(column, direction) {
    switch (column) {
      case ComplexFields.SETTINGS:
        if (direction === 'DESC') {
          return (a, b) => {
            let aSettings = '';
            let bSettings = '';
            if (!a[column]) {
              return 1;
            }
            try {
              aSettings = JSON.parse(a[column]);
              bSettings = JSON.parse(b[column]);
            } catch (error) {
              console.log('field value parse error', error);
            }
            // Check if fieldLabel is present
            if (aSettings.fieldLabel === undefined) {
              return -1;
            }
            if (bSettings.fieldLabel === undefined) {
              return 1;
            }
            return (aSettings.fieldLabel < bSettings.fieldLabel) ? 1 : -1;
          };
        } else {
          return (a, b) => {
            let aSettings = '';
            let bSettings = '';
            if (!a[column]) {
              return 1;
            }
            try {
              aSettings = JSON.parse(a[column]);
              bSettings = JSON.parse(b[column]);
            } catch (error) {
              console.log('field value parse error', error);
            }
            if (aSettings.fieldLabel === undefined) {
              return -1;
            }
            if (bSettings.fieldLabel === undefined) {
              return 1;
            }
            return (aSettings.fieldLabel > bSettings.fieldLabel) ? 1 : -1;
          };
        }
      case ComplexFields.FIELD_TYPE:
        if (direction === 'ASC') {
          return (a, b) => {
            if (!a[column]) {
              return 1;
            }
            let aFieldType = FieldTypeStore.get(a[column]),
              bFieldType = FieldTypeStore.get(b[column]);
            return (aFieldType.name > bFieldType.name) ? 1 : -1;
          };
        } else {
          return (a, b) => {
            if (!a[column]) {
              return 1;
            }
            let aFieldType = FieldTypeStore.get(a[column]),
              bFieldType = FieldTypeStore.get(b[column]);
            return (aFieldType.name < bFieldType.name) ? 1 : -1;
          };
        }
      default:
        MapUtils.simpleSortFunction(column, direction);
    }
  }

  /**
   * Returns filter function for Array.filter
   *
   * @params {Map} where Object[column] has term to be filtered
   * @returns {Function}
   */
  static customFilterFunction(filters) {
    if (filters.size) {
      return (row) => {
        let pass = true;
        filters.forEach((filter, key) => {
          let column = row[key];
          if (column) {
            if (key === ComplexFields.SETTINGS) {
              let settings = JSON.parse(column);
              column = settings.fieldLabel;
            } else if (key === ComplexFields.FIELD_TYPE) {
              let fieldType = FieldTypeStore.get(column);
              column = fieldType.name;
            } else if (column.value) {
              column = column.value;
            }

            if (typeof column === 'string') {
              column = column.toLowerCase();
              pass = (pass && column.indexOf(filter.toLowerCase()) >= 0);
            }
          } else if ('' !== filter) {
            pass = false;
          }
        });
        return pass;
      };
    }
    return (row) => true;
  }

  /**
   * Returns appropriate label for table based on cardinality and side of relationship
   *
   * @params {Object} Table object from TableStore
   * @params {String} relationship cardinality, should be 1 or m
   * @params {String} indication of which side of relationship the table is, l or r
   */
  static getTableRelationLabel(table, cardinality, side) {
    if (!size(table)) {
      return 'No table';
    }
    switch (cardinality) {
      case 'm':
        return table.pluralName;
      default:
        if ('l' === side) {
          return `Each ${table.singularName}`;
        } else {
          return `${table.singularName}`;
        }
    }
  }
}
