import React, { Component } from 'react';
import { Container } from 'flux/utils';
import PropTypes from 'prop-types';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import FieldSettingsStore from '../../../stores/field-settings-store';
import FieldStore from '../../../stores/field-store';
// import FieldTypeStore from '../../../stores/field-type-store';
import PageStore from '../../../stores/page-store';

import ContextStore from '../../../stores/context-store';
import MetadataStore from '../../../stores/metadata-store';

// Utils
// import ObjectUtils from '../../../utils/object-utils';
import UIUtils from '../../../utils/ui-utils';
import FieldUtils from '../../../utils/field-utils';

// Components
import PopoverHeader from './header';

// Disappointments
import $ from 'jquery';

const triggerFriendlyNames = {
	onBlur: 'Lose Focus',
	onBlurChange: 'Lose Focus and Change',
	onClick: 'Click',
	onFocus: 'Gain Focus',
	onEnterUp: 'On Enter Up', // @TODO : Set this one from FT BUILDER
	onMouseOut: 'Mouse Out',
	onMouseOver: 'Mouse Over',
	validate: 'Validate (Local)', // @TODO : Set this one from FT BUILDER
	preFieldSave: 'Validate (Global)',
	onPageLoad: 'After Page Loads',
	prePageSave: 'Page Validates',
	postPageSave: 'After Page Saves',
};

const applicationLevelTriggers = {
	anyPageLoads: 'Any Page Loads',
	onOrientationChange: 'Any Page Changes Orientation'
};


/**
 * Renderer for the appearance assistant
 * 
 * @class AppearanceAssistant
 * @extends {Component}
 */
class AutomationPinPopover extends Component {
	constructor(props) {
		super(props);
		this.onSettingClick = this.onSettingClick.bind(this);
	}
	/**
	 * @static
	 * @returns - the stores that are being watch by NLPBuilder component
	 * 
	 * @memberOf NLPBuilder
	 */
	static getStores() {
		return [AdminSettingsStore, FieldSettingsStore, PageStore, MetadataStore];
	}
	/**
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} - The Dictionaries from NLPBuilderDictionaries Store 
	 * 
	 * @memberOf NLPBuilder
	 */
	static calculateState(prevState, props) {
		let automationTriggers = [];
		let applicationLevelAutomationTriggers = [];
		let { recordId, parentRecordId, tableSchemaName } = props;
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let otherTriggerFriendlyNames = [];

		if (tableSchemaName === 'field') {
			let fieldId = recordId;
			let fieldObj = FieldStore.get(fieldId);
			// Check whether fieldSettingsObj is present for the field
			if (fieldObj) {

				let fieldAutomationTriggers = FieldUtils.getLogicTriggers(recordId, parentRecordId);
				automationTriggers = fieldAutomationTriggers['triggers'];
				otherTriggerFriendlyNames = fieldAutomationTriggers['otherTriggerFriendlyNames'];
			}
		} else if (tableSchemaName === 'page') {
			let applicationObj = MetadataStore.get(ContextStore.getApplicationId(), 'applications');
			if(applicationObj) {
				applicationLevelAutomationTriggers = Object.keys(applicationLevelTriggers).map(triggerName => {
					let hasActions = '';
					let logicFunctionIdsArr = []; 

					if(applicationObj['automation-' + triggerName]) {
						let automationObj = JSON.parse(applicationObj['automation-' + triggerName]);
						// check if it has js, if it does get the xml value
						if(automationObj && automationObj.js && automationObj.js.length > 0) {
							hasActions = 'Yes';
						}
						// Handle converting logicFunctionsUsed into an array
						let logicFunctionIds = automationObj && automationObj.logicFunctionsUsed ? automationObj.logicFunctionsUsed : '';
						if (logicFunctionIds) {
							if(Array.isArray(logicFunctionIds)) {
								logicFunctionIdsArr = logicFunctionIds;
							} else {
								logicFunctionIdsArr = logicFunctionIds.split(',');
							}
						}
					}
					return {
						name: triggerName,
						settingsValue: hasActions,
						valueSource:'',
						badgeTypeClass: 'badge-dark',
						logicFunctions: logicFunctionIdsArr
					};
				});
			}
			let pageObj = PageStore.get(recordId);
			if (pageObj) {
				automationTriggers = ['onPageLoad','prePageSave','postPageSave','onEnterUp'].map(triggerName => {
					// get the setting for the popover pin
					let automationObj = PageStore.getAutomation(recordId, triggerName);
					let hasActions = '';
					// check if it has js, if it does get the xml value
					if(automationObj && automationObj.js && automationObj.js.length > 0) {
						hasActions = 'Yes';
					} 
					return {
						name: triggerName,
						selectedSetting: selectedSetting === triggerName,
						settingsValue: hasActions,
						badgeTypeClass: 'badge-dark',
					};
				});
			}
		}

		let allTriggerFriendlyNames = Object.assign(triggerFriendlyNames, otherTriggerFriendlyNames);

		// Flag the selected Trigger
		automationTriggers.map(automationTrigger => {
			if(automationTrigger.name === selectedSetting) {
				automationTrigger.selectedSetting = true;
			}
			return automationTrigger;
		})

		// Sort the action triggers 
		automationTriggers = automationTriggers.sort((a, b) => {
			return (allTriggerFriendlyNames[a.name] > allTriggerFriendlyNames[b.name]) ? 1 : -1;
		});

		return {
			'automationTriggers': automationTriggers,
			'recordId': recordId,
			'tableSchemaName': tableSchemaName,
			'parentRecordId': parentRecordId,
			'parentTableSchemaName': AdminSettingsStore.getParentTableSchemaName(),
			'allTriggerFriendlyNames' : allTriggerFriendlyNames,
			'applicationLevelAutomationTriggers': applicationLevelAutomationTriggers
		};
	}
	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingSchemaName
	 * @param {any} event 
	 * @memberof SettingsPanel
	 */
	onSettingClick(settingSchemaName, event) {
		event.preventDefault();
		// Open Settings tab under right-panel
		AdminSettingsActions.onSettingsTabChangeMain('settings');

		UIUtils.openSettingsPanel('automation', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName,
			'settings', undefined, undefined, this.props.renderId, this.props.attachmentId);
		// hide the popover when a setting has been selected
		$('.popover').popover('hide');

		// Ensure the Settings are hidden
		AdminSettingsActions.onSettingsListHideChange(true);

		//Toggle to select and deselect the Setting
		AdminSettingsActions.onSettingChange(settingSchemaName);
	}
	/**
	 * @returns 
	 * @memberof AutomationSettingsChooser
	 */
	render() {
		let {automationTriggers, tableSchemaName, recordId, allTriggerFriendlyNames, applicationLevelAutomationTriggers} = this.state;
		let componentIcon = (tableSchemaName === 'field' ? FieldUtils.getFieldTypeIcon(recordId) : FieldUtils.getFieldTypeIcon('page'));

		let settingsList = automationTriggers.map(trigger => {
			let labelClassNames = 'setting-label';
			if(trigger.selectedSetting) { 
				labelClassNames += ' selected';
			}
			// let iconClassNames = 'setting-icon fa custom fa-check-circle-o';
			return (
				<li key={this.state.recordId+'-'+trigger.name} className="nav-item">
					<div 
						className="nav-link" 
						onClick={this.onSettingClick.bind(this, trigger.name)}
					>
						{/* Temporarily removed the following div */}
						{/* <div className={"popover-settings-icon " + iconClassNames} /> */}
						{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
						{/* Add this back in when the setting-icon is used again */}
						<div className="d-flex w-100 setting-text-container">
							<div className={"w-50 d-flex align-items-center p-1 " + labelClassNames}><h4>{allTriggerFriendlyNames[trigger.name]}</h4></div>
							<div className="w-50 d-flex justify-content-end setting-value-container">
								<div className='text-right setting-pattern-list setting-value align-self-center'>
									<h5 className={'settings-list-value'}>{ trigger.settingsValue }</h5>
								</div>
								{ trigger.valueSource ? 
									<div className={"setting-scope badge badge-pill " + trigger.badgeTypeClass + " align-self-center"}> 
										{ trigger.valueSource } 
									</div>
									: null
								}
							</div>
						</div>
					</div>
				</li>
			);
		});

		let applicationLevelSettingSection = null;

		if(tableSchemaName === 'page') {
			let applicationLevelSettingsList = applicationLevelAutomationTriggers && applicationLevelAutomationTriggers.map(trigger => {
				let labelClassNames = 'setting-label';
				if(trigger.selectedSetting) { 
					labelClassNames += ' selected';
				}
				// let iconClassNames = 'setting-icon fa custom fa-check-circle-o';
				return (
					<li key={this.state.recordId+'-'+trigger.name} className="nav-item">
						<div 
							className="nav-link" 
							onClick={this.onSettingClick.bind(this, trigger.name)}
						>
							{/* Temporarily removed the following div */}
							{/* <div className={"popover-settings-icon " + iconClassNames} /> */}
							{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
							{/* Add this back in when the setting-icon is used again */}
							<div className="d-flex w-100 setting-text-container">
								<div className={"w-50 d-flex align-items-center p-1 " + labelClassNames}><h4>{applicationLevelTriggers[trigger.name]}</h4></div>
								<div className="w-50 d-flex justify-content-end setting-value-container">
									<div className='text-right setting-pattern-list setting-value align-self-center'>
										<h5 className={'settings-list-value'}>{ trigger.settingsValue }</h5>
									</div>
									{ trigger.valueSource ? 
										<div className={"setting-scope badge badge-pill " + trigger.badgeTypeClass + " align-self-center"}> 
											{ trigger.valueSource } 
										</div>
										: null
									}
								</div>
							</div>
						</div>
					</li>
				);
			});

			applicationLevelSettingSection = <ul id="popover-settings-other" className="nav flex-column">
			<li className="d-flex align-items-center settings-list-header field-settings-list-header">
				<img style={{ marginTop: '2px' }} height="22" width="22" className="mr-2" src={componentIcon} alt="" />
				<h3>Other Triggers</h3>
			</li>
			{applicationLevelSettingsList && applicationLevelSettingsList.length
				? applicationLevelSettingsList
				: null
			}
		</ul>;
		}

		return (
		<div id="popover-content">
			<PopoverHeader 
				recordId={this.props.recordId} 
				tableSchemaName={this.props.tableSchemaName} 
				iconBase="puzzle-piece" 
				overlayName="Logic"/>
			<div style={{ height: '100%', overflow: 'hidden' }} className="tab-content row mb-2">
				<div style={{ height: '100%', overflowY: 'auto' }} className="ml-3 pr-1 mr-2">
					<ul id="popover-settings" className="nav flex-column">
						<li className="d-flex align-items-center settings-list-header field-settings-list-header">
							<img style={{ marginTop: '2px' }} height="22" width="22" className="mr-2" src={componentIcon} alt="" />
							<h3>Logic Triggers</h3>
						</li>
						{settingsList && settingsList.length
							? settingsList
							: <div className='no-settings-found'>No Settings Found</div>
						}
					</ul>
					{applicationLevelSettingSection}
				</div>
			</div>
		</div>
		)
	}
}

if ('development' === process.env.NODE_ENV) {
	AutomationPinPopover.propTypes = {
	  recordId: PropTypes.string.isRequired,
	  tableSchemaName: PropTypes.string.isRequired,
	  parentRecordId: PropTypes.string,
	  parentTableSchemaName: PropTypes.string,
	};
  }

const container = Container.create(AutomationPinPopover, {withProps: true});
export default container;
