import Immutable from 'immutable';
import { ThemeConstants } from '../constants';
import MetadataBuilder from '@dmclain-citizendeveloper/citdev-module-metadata-builder';
import fetchMetadata from '../utils/fetch-utils';
import AppDispatcher from '../dispatcher/app-dispatcher';

let _instance = null;

/**
 * Actions for the store that contains theme records
 *
 * @class ThemeActionsDev
 */
class ThemeActionsDev {
	/**
	 * Singleton instance of ThemeActionsDev
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if(options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Theme Config Path
	 * 
	 * @param {string} themeConfigPath 
	 */
	setThemeConfigPath(themeConfigPath) {
		this._themeConfigPath = themeConfigPath;
	}

	/**
	 * Set the Installation Id and (optionally the endPoint) and setup the Metadata builder
	 * 
	 * @param {string} installationId 
	 * @param {string} endPoint 
	 */
	setInstallationId(installationId, signedMdKey, endPoint) {
		this._metadataBuilder = new MetadataBuilder(installationId, signedMdKey, endPoint);
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw(new Error('ThemeActionsDev not properly initialized with a dispatcher'));
		}
		if (!this._metadataBuilder) {
			throw(new Error('ThemeActionsDev not properly initialized with an installationId'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: ThemeConstants.THEME_PULL_ERROR,
			error: error.message
		}));
	}

	receiveBroadcast(kind, records) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: ThemeConstants.THEME_RECEIVE_BROADCAST,
			records: records,
			kind: kind
		}));
	}

	/**
	 * Deletes a theme from the database AND the store
	 * 
	 * @param {string} recordId Theme Record ID to delete from the database and the store
	 */
	deleteFromDatabase(recordId) {
		// Just wrap the Promisified version without returning it
		this.deleteFromDatabasePromise(recordId);
	}

	/**
	 * Deletes a theme from the database AND the store and returns a promise.
	 * 
	 * @param {string} recordId Theme Record ID to delete from the database and the store
	 * @return {Promise}
	 */
	deleteFromDatabasePromise(recordId, kind) {
		return new Promise((resolve, reject) => {
			try {
				this._checkInit();
			} catch (error) {
				return reject(error);
			}

			// Local Meta Data Builder
			this._metadataBuilder
				// Get the field(s)
				.deleteRecords([{'recordId': recordId}], 'theme')
				.then(function(){
					// Pass the fields to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: ThemeConstants.THEME_DELETE_FROM_STORE,
						recordId: recordId
					}));
					return resolve(recordId);
				}.bind(this))
				.catch(function(error){ 
					console.error(error); 
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Deletes a theme from the store
	 * 
	 * @param {string} recordId Theme Record ID to delete from the store.
	 */
	deleteFromStore(recordId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: ThemeConstants.THEME_DELETE_FROM_STORE,
			recordId: recordId,
		}));
	}

	/**
	 * Update Metadata Gateway with this theme's data.
	 * @param {string} recordId Theme Record ID to push from the store to the database
	 */
	pushToDatabase(themeObject) {
		// Just wrap the Promisified version without returning it
		this.pushToDatabasePromise(themeObject);
	}

	/**
	 * Update Metadata Gateway with this theme's data and return a promise
	 * @param {string} recordId Theme Record ID to push from the store to the database
	 * @return {Promise}
	 */
	pushToDatabasePromise(themeObject) {
		return new Promise((resolve, reject) => {
			try {
				this._checkInit();
			} catch (error) {
				return reject(error);
			}

			// Local Meta Data Builder
			this._metadataBuilder
				// Update the theme
				.updateRecords([themeObject], 'theme')
				.then(function(){
					// Pass the tables to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: ThemeConstants.THEME_PUSH_TO_DATABASE,
						themeObject
					}));
					return resolve(themeObject);
				}.bind(this))
				.catch(function(error){ 
					console.error(error); 
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Update store theme properties
	 * @param {string} recordId Theme Record ID to update
	 * @param {Object} recordProperties Properties to update
	 * @param {boolean} forceClean Should this store entry be clean?
	 */
	pushToStore(recordId, recordProperties, forceClean) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: ThemeConstants.THEME_PUSH_TO_STORE,
			recordId: recordId,
			recordProperties: recordProperties,
			forceClean: forceClean
		}));
	}

	/**
	 * Update store with a theme's data.
	 * @param {string} recordId Theme's Record ID to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get the theme
			.searchRecords('theme', { 'recordId': recordId})
			.then(function(themeArray){
				// Pass the theme to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: ThemeConstants.THEME_PULL_FROM_DATABASE,
					themeArray: themeArray,
					overwriteStore: overwriteStore
				}));
			}.bind(this))
			.catch(function(error){ 
				console.error(error); 
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Update store with all of the theme's data.
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		fetchMetadata(this._themeConfigPath, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8'
			}
		}).then(function(fetchedThemeObject){

			// Local Meta Data Builder
			this._metadataBuilder
				// Get all the theme
				.browseRecords('theme')
				.then(function(mdbThemeArray){
					let themesArrayAll = Object.assign(fetchedThemeObject, mdbThemeArray);

					// Pass the themes to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: ThemeConstants.THEME_PULL_FROM_DATABASE_ALL,
						themeArray: themesArrayAll,
						overwriteStore: overwriteStore
					}));
				}.bind(this))
				.catch(function(error){ 
					console.error(error); 
					this._onError(error);
				}.bind(this));
			}.bind(this))
			.catch(function(error){ 
				console.error(error); 
				this._onError(error) ;
			}.bind(this));
	}
}

/**
 * Actions for the store that contains theme records for the test/prod environments
 *
 * @class ThemeActionsProd
 */
class ThemeActionsProd {
	/**
	 * Singleton instance of ThemeActionsProd
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if(options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id
	 * 
	 * @param {string} installationId 
	 */
	setInstallationId(installationId) {
		this._installationId = installationId;
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw(new Error('ThemeActionsProd not properly initialized with a dispatcher'));
		}
		if (!this._installationId) {
			throw(new Error('ThemeActionsProd not properly initialized with an installationId'));
		}
		if (!this._installationVersion) {
			throw(new Error('ThemeActionsProd not properly initialized with an installationVersion'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: ThemeConstants.THEME_PULL_ERROR,
			error: error.message
		}));
	}

	/**
	 * Deletes a table from the database AND the store
	 */
	deleteFromDatabase() {
		console.warn('deleteFromDatabase not implemented.');
	}

	/**
	 * Deletes a table from the store
	 * 
	 * @param {string} recordId Table Record ID to delete from the store.
	 */
	deleteFromStore(recordId, kind) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: ThemeConstants.THEME_DELETE_FROM_STORE,
			recordId: recordId,
		}));
	}

	/**
	 * Update Metadata Gateway with this record's data.
	 */
	pushToDatabase() {
		console.warn('pushToDatabase not implemented.');
	}

	/**
	 * Update store record properties
	 * @param {string} recordId Record to update
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, recordProperties) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: ThemeConstants.THEME_PUSH_TO_STORE,
			recordId: recordId,
			recordProperties: recordProperties
		}));
	}

	/**
	 * Update store with a record's data.
	 * @param {string} recordId Record to pull from the database to the store
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' + 
				installationId + '/' + installationVersion + '/theme/' + recordId + '.json';
		fetchMetadata(metadataPath)
			.then((jsonResponse) => {
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: ThemeConstants.THEME_PULL_FROM_DATABASE,
					themeArray: [jsonResponse],
					overwriteStore: overwriteStore
				}));
			})
			.catch(function(error){
				console.error('Unable to retrieve theme from ', metadataPath, '. Error was: ', error);
			});
	}

	/**
	 * Update store with all of the records' data.
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' + 
				installationId + '/' + installationVersion + '/theme/index.json';
		fetchMetadata(metadataPath)
			.then((jsonResponse) => {
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: ThemeConstants.THEME_PULL_FROM_DATABASE_ALL,
					themeArray: jsonResponse,
					overwriteStore: overwriteStore
				}));
			})
			.catch(function(error){
				console.error('Unable to retrieve theme from ', metadataPath, '. Error was: ', error);
			});
	}
}

let toExport;

if(process.env.CITDEV_ENV === 'development'){
	
	try {
		if(window.location.href.includes('settings.html') && typeof window.opener.location.href === 'string') {
			toExport = window.opener.ThemeActions;
		} else {
			const instance = new ThemeActionsDev({dispatcher: AppDispatcher});
			toExport = instance;
		}
	} catch(error) { 
		const instance = new ThemeActionsDev({dispatcher: AppDispatcher});
		toExport = instance;
	}

} else {
	toExport = new ThemeActionsProd({dispatcher: AppDispatcher});
}

export default toExport;