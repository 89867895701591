import React, { Component } from 'react'
import { Container } from 'flux/utils';
import ContextStore from '../stores/context-store';
import RenderStore from '../stores/render-store';
import AdminSettingsStore from '../stores/admin-settings-store';
import Dialog from '@dmclain-citizendeveloper/react-dialog'
import InterfaceActions from '../actions/interface-actions';
import UIUtils from '../utils/ui-utils';
import RenderActions from '../actions/render-actions';

/**
 * Component for getting dialogs on the page.
 */
class DialogContainer extends Component {
	
	constructor(props) {
		super(props);
		this.handleDialogClose = this.handleDialogClose.bind(this);
	}

	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 */
	static getStores() {
		return [ RenderStore, AdminSettingsStore ];
	}

	/**
	 * static - Returns the current state
	 *
	 * @param  {Object} prevState previous state
	 * @param  {Object} props
	 * @returns {Object} state
	 */
	static calculateState(prevState, props) {
		let dialogRenders = RenderStore.getDialogRenders(ContextStore.getPageRenderId());
		let sizeObj = AdminSettingsStore.getSizeJS();
		let newState = {
			dialogRenders: dialogRenders, ...sizeObj
		};
		return newState;
	}
	/**
	 * Removes the RenderId of the Dialog and if provided 
	 * it calls the dialogClose callback which gets handled
	 * by the component that set the Dialog in the RenderStore
	 * 
	 * @param {string} renderId 
	 * @param {callback} dialogClose 
	 */
	handleDialogClose(renderId, dialogClose) {

		// Check if there are any unsaved records in this dialog.
		let hasDirty = RenderStore.hasDirtyFields(renderId);

		let close = hasDirty ? confirm('Changes you made may not be saved.') : true;

		if(!close) {
			return;
		}

		if(dialogClose) {
			dialogClose(); 
		}

		let renderObj = RenderStore.get(renderId);

		if(renderObj && renderObj.componentType === 'page'){
			InterfaceActions.closePage({
				renderId: renderId,
				// Done so that any dialogs opened by this dialog won't be closed
				reassignPageChildren: true
			});
		} else {
			RenderActions.deleteRender(renderId);
		}
	}

	/**
	 * Check to see if any of the dialog renders have changed.  
	 * If we have gained or lost any renders or the render ids of the renders changed
	 * then we need to re render
	 *
	 * @param {*} nextProps
	 * @param {*} nextState
	 * @returns
	 * @memberof DialogContainer
	 */
	shouldComponentUpdate(nextProps, nextState) {
		let shouldUpdate = false;
		if (this.state.dialogRenders && nextState.dialogRenders) {
			//IF we have a different length we need to update
			shouldUpdate = this.state.dialogRenders.length !== nextState.dialogRenders.length;
			if (!shouldUpdate) {
				nextState.dialogRenders.forEach((render, key) => {
					if (
						!this.state.dialogRenders[key] ||
						render.renderId !== this.state.dialogRenders[key].renderId ||
						render.componentId !== this.state.dialogRenders[key].componentId || 
						render.height !== this.state.dialogRenders[key].height || 
						render.width !== this.state.dialogRenders[key].width ||
						render.modal !== this.state.dialogRenders[key].modal ||
						render.isResizable !== this.state.dialogRenders[key].isResizable ||
						render.isClosable !== this.state.dialogRenders[key].isClosable ||
						// render.isDraggable !== this.state.dialogRenders[key].isDraggable || // Commented out because it is in render as well
						render.title !== this.state.dialogRenders[key].title
					) {
						shouldUpdate = true;
					}
				})
			}
		} else if (nextState.dialogRenders.length) {
			//If we don't have a current state and the next state has a length then update
			shouldUpdate = true;
		}
		
		if (this.state.height !== nextState.height || this.state.width !== nextState.width) {
			shouldUpdate = true;
		}
		return shouldUpdate;
	}

	/**
	 * Render the dialogs that should be on the screen
	 * 
	 * @returns JSX
	 * @memberof DialogContainer
	 */
	render() {

		let dialogArray = [];
		this.state.dialogRenders.forEach(dialogRender => {
			let enableModal = false;
			let isDraggable = false;
			let isResizable = false;
			let isClosable = false;
			let height = undefined;
			let width = undefined;
			let dialogTitle = '';

			if(dialogRender.options && dialogRender.options.componentProps) {
				if(dialogRender.options.title) {
					dialogTitle = dialogRender.options.title;
				}
				if(dialogRender.options.isDraggable === 'Y') {
					isDraggable = true;
				}
				if(dialogRender.options.isResizable === 'Y') {
					isResizable = true;
				}
				if(dialogRender.options.isClosable !== 'N') {
					isClosable = true;
				}
				if(dialogRender.options.modal === 'Y') {
					enableModal = true;
				}

				if(ContextStore.getResponsiveMode() !== 'lg') {
					isDraggable = false;
					isResizable = false;
				}

				// Grab the dialog size
				if(dialogRender.options.height) {
					height = dialogRender.options.height;
				}
				if(dialogRender.options.width) {
					width = dialogRender.options.width;
				}

				// Dont let the height or width go over the viewport size
				let viewportSize = UIUtils.getSize();
				if(height > viewportSize.height) {
					height = viewportSize.height - 20;
				}
				if(width > viewportSize.width) {
					width = viewportSize.width - 20;
				}

				// It is necessary for the dialog-wrapper div below to use position: 'relative' to create a new stacking context.
				// Without it, the z-indexes won't work properly and users will be able to click behind the dialogs.
				// (Resolves tickets 25797, 21418, 21931, 24872)
				let componentProps = Object.assign({
					height, 
					width, 
					isDialog: true
				}, dialogRender && dialogRender.options ? dialogRender.options.componentProps : undefined);
				dialogArray.push(
					<div key="dialog" className="dialog-wrapper" style={{ zIndex: dialogRender.options.componentProps.zIndex, position: 'relative'}}>
						<Dialog
							key={[dialogRender.renderId, dialogRender.componentId, height, width].join('-')} /* Because of the way that Dialog calculates its height/width, we need to force a fresh render when height or width changes */
							allowMaximize={false} /* This feature doesnt work.. so please keep it off */
							title={dialogTitle}
							modal={enableModal}
							isDraggable={isDraggable}
							isResizable={isResizable}
							height={height}
							width={width}
							hasCloseIcon={isClosable}
							closeOnEscape={isClosable}
							onClose={this.handleDialogClose.bind(this, dialogRender.renderId, dialogRender.options && dialogRender.options.componentProps ? dialogRender.options.componentProps.dialogClose : undefined)}
							position={{ /* REQUIRED.  If you want to remove the y variable below, leave this object here, empty, because it has default props of x:-250, y:-150 */
								y: document.getElementById('App').scrollTop-(height/2)
							}} 
							>
					{React.createElement(dialogRender.options.component, componentProps, null)}
					</Dialog></div>);
			}
		})
		return (
			<div id="dialogContainer" className="container">
				{dialogArray}
			</div>
		);
	}
}

const container = Container.create(DialogContainer, { withProps: true, pure: false });
export default container;