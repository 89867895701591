import React, {Component} from 'react';
import {Container} from 'flux/utils';

// Actions
import MapActions from '../../../actions/map-actions';
import ThemeTemplateActions from '../../../actions/theme-template-actions';
import AdminSettingsActions from '../../../actions/admin-settings-actions';

// Constants
// import Help from '../../../constants/help-upgrade-constants';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import MapStore from '../../../stores/map-store';
import ThemeTemplateStore from '../../../stores/theme-template-store';

// Utils
import { ReactSVG } from 'react-svg';
import UIUtils from '../../../utils/ui-utils';
import uuid from 'uuid';

/**
 * Theme Builder component on the Presentation Dashboard 
 * @class ThemeTemplatesDashboardTabContainer
 * @extends {Component}
 */
class ThemeTemplatesDashboardTabContainer extends Component {
	/**
	 * Creates an instance of ThemeTemplatesDashboardTabContainer.
	 * @param {any} props 
	 * @memberof LogicMapContainer
	 */
	constructor(props) {
		super(props);

		this._onAddTheme = this._onAddTheme.bind(this);
		this._onChangeSearch = this._onChangeSearch.bind(this);
		this._onEditTheme = this._onEditTheme.bind(this);
	}

	/**
	 * Gets the stores to watch
	 * 
	 * @static
	 * @returns {Array of Object}
	 */
	static getStores() {
		return [ ThemeTemplateStore, MapStore ];
	}

	/**
	 * Returns the current State
	 * 
	 * @static
	 */
	static calculateState(prevState, props) {
		let themes = [];
		let search = MapStore.getSearch(AdminSettingsStore.getActiveDashboardTab());
		let groupBy = (MapStore.getGroupBy() ? MapStore.getGroupBy() : 'name');

		if(ThemeTemplateStore.allPulledFromDatabase()){
			themes = ThemeTemplateStore.getAllArray() || [];
			themes.map(theme => {
				if(!theme.name) {
					theme.name = '[ No Name Found ]';
				}
				return theme;
			});
		}
		return {
			themes: themes,
			search: search,
			groupedThemes: this._performGroupBy(themes, groupBy, search)
		};
	}

	/**
	 * Using the group by set in the UI, group the themes and return
	 * an object with the groups as keys and themes array as value.
	 * @param {Array} themes Themes to group
	 * @param {String} groupBy How to group the themes
	 * @param {String} search What search to apply, if any.
	 * @return Object
	 */
	static _performGroupBy(themes, groupBy, search) {
		let groups = {};

		if (!themes) {
			return groups;
		}

		// Filter out the functions that dont have the search term.
		themes = themes.filter(theme => {
			if (theme && theme.name && theme.name.length > 0) {
				return (theme.name.toLowerCase().includes(search.toLowerCase()));
			} else {
				return false;
			}
			// Sort the functions - by Name.
		}).sort((a, b) => {
			// Ascending Alphabetically
			return b.name < a.name ? 1 : -1;
		});

		let groupVal = 'name';
		
		if (!groups[groupVal]) {
			groups[groupVal] = [];
		}
		
		// Put the schedules into their groups
		themes.forEach(theme => {
			groups[groupVal].push(theme);
		});

		return groups;
	}

	/**
	 * Render this component.
	 * 
	 * @returns JSX
	 * @memberof LogicMapContainer
	 */
	render() {
		let { groupedThemes, search } = this.state;

		// We're not doing the group bys yet... so there is only one group.
		let themes = groupedThemes['name'];

		let noSearchFound = null;
		if(Object.keys(themes).length === 0 && search.length) {
			noSearchFound = <li className="no-search-found" style={{ color: 'white'}}><h4>No Results for '{search}' found.</h4></li>
		}

		let notifications = [];
		let changeCount = 0;
		let newCount = 0;
		let errorCount = 0;
		themes.forEach(theme => {
			if(ThemeTemplateStore.getChanges(theme.recordId).length ? true : false) {
				changeCount++;
			}
			if(theme.new && theme.new === true) {
				newCount++;
			}
			if(theme.errorText && theme.errorText !== '') {
				errorCount++;
			}
		})
		if(newCount > 0) {
			notifications.push(UIUtils.getNotificationTile(
				'status1', 
				'New Theme Templates', 
				newCount + ' New'));
		}
		if(changeCount > 0) {
			notifications.push(UIUtils.getNotificationTile(
				'status2', 
				'Changed from Stage', 
				changeCount + ' Changes'));
		}
		if(errorCount > 0) {
			notifications.push(UIUtils.getNotificationTile(
				'status3', 
				'Theme Templates with Errors', 
				errorCount + ' Errors'));
		}

		return (
			<div id="logic-map__content" className="map">
				<div className="cd-search-container">
					<input className="form-control select-group-by" placeholder="Search" type="text" value={search} onChange={this._onChangeSearch} />
				</div>
				<div className="section-header" key="expand-collapse-tools">
					<div className="d-flex justify-content-end align-items-center">
						<h5 className="bold">Theme Templates</h5>
						
						<div className="d-flex">
							<div 
								title="Create Theme"
								className="add-theme-icon"
								data-toggle="modal" 
								onClick={this._onAddTheme}
							>
								<i className="fa fa-plus-circle ml-1"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="list-content-wrapper">
					<ol id="themeEditList"
						className="collapse show">
						{/* Render Themes List */}
						{themes.map(this._renderTheme.bind(this))}
						{ noSearchFound }
					</ol>
					<div className="notifications-wrapper">
						{ notifications }
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Renders a single theme.
	 * @param {number} index 
	 * @returns 
	 * @memberof LogicMapContainer
	 */
	_renderTheme(theme, index){
		let recordId = (theme && theme.recordId) ? theme.recordId : '';
		let themeName = (theme && theme.name) ? theme.name : '';
	
		if(!themeName) {
			return null;
		}

		let hasChanges = (ThemeTemplateStore.getChanges(recordId).length ? true : false);
		let hasChangesJSX = (hasChanges 
			?   <div className="colorBullet status2">
					<i className="fa fa-circle fa-1" aria-hidden="true" title="Theme Template has changed"></i>
				</div>
			: null);
		let isNewJSX = (theme.new 
			?   <div className="colorBullet status1">
					<i className="fa fa-circle fa-1" aria-hidden="true" title="Theme Template is new"></i>
				</div>
			: null);
		let hasErrorJSX = (theme.errorText 
			?   <div className="colorBullet status3">
					<i className="fa fa-circle fa-1" aria-hidden="true" title="Theme Template has an error"></i>
				</div>
			: null);

		return (
			<li key={recordId + '-' + index} className="table-name-item">
				<div className="list-item-row">
					<div className="mb-0">
						<h4 className="bold">{themeName}</h4>
					</div>
					<div style={{ display: 'flex' }}>
						{ hasChangesJSX }
						{ isNewJSX }
						{hasErrorJSX}
						<div title="Update Theme" className="action-icon" style={{ flex: 1 }}>
							<span 
								onClick={this._onEditTheme.bind(this, recordId)} 
							>
									<ReactSVG 
										beforeInjection={svg => {
											svg.setAttribute('viewBox', '0 0 34 34');
											svg.setAttribute('style', 'width: 16px; height: 16px');
										}}
										src="https://cdn3.citizendeveloper.com/engine-build/citdev-media/v2/icon-logic.svg" />
									<span className='sr-only'>Edit</span>
							</span>
						</div>
					</div>
				</div>
			</li>
		);
	}

	/**
	 * Add new Theme record, then open the editor.
	 * 
	 * @memberof ThemeTemplatesDashboardTabContainer
	 */
	_onAddTheme() {
		// Generate new uuid and empty body for the record
		let recordId = uuid.v4(),
			newThemeObj = {
				recordId: recordId,
				name: 'New Theme',
				new: true
			};

		// //Push the new Record to the store 	
		ThemeTemplateActions.pushToStore(recordId, newThemeObj, true);

		this._onEditTheme(recordId);
	}

	/**
	 * Handles typing into the search box.
	 * @param {object} event Change event
	 */
	_onChangeSearch(event) {
		let search = event.target.value;
		MapActions.search(search, AdminSettingsStore.getActiveDashboardTab());
	}

	/**
	 * Edit existing Theme
	 * 
	 * @param {String} recordId 
	 */
	_onEditTheme(recordId) {
		AdminSettingsActions.onRightPanelChange(true);
		//Toggle to select and deselect the Setting
		AdminSettingsActions.onSettingChange('name', 'ad8dc94f-7f60-48f4-accf-5523a064a05a');

		// Ensure the Settings are NOT hidden
		AdminSettingsActions.onSettingsListHideChange(false);
		
		// recordId, renderId, attachmentId, 
		// tableSchemaName, parentRecordId, parentTableSchemaName, gridKey, fieldType, selectedOverlay) {
		AdminSettingsActions.onShowComponentDetails(recordId, undefined, undefined,
			'themeTemplate', undefined, undefined, undefined, undefined, 'engineering-theme-template');
	}
}

const container = Container.create(ThemeTemplatesDashboardTabContainer, {withProps: true});
export default container;
