import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Components - Third Party
import Helmet from 'react-helmet';

// Components
import NotFound from '../pages/not-found.react';
import Loading from '../pages/loading.react';
import GridComponent from './grid-component.react';
import GoogleTagManager from './vendors/google-tag-manager.react';
import CometChatContainer from './comet-chat.react';

// APIs
import RecordsApi from '../apis/records-api';

// Stores
import AuthenticationStore from '../stores/authentication-store';
import ContextStore from '../stores/context-store';
import FieldModesStore from '../stores/field-modes-store';
import FieldStore from '../stores/field-store';
import FieldTypeStore from '../stores/field-type-store';
import PageStore from '../stores/page-store';
import RecordStore from '../stores/record-store';
import RelationshipStore from '../stores/relationship-store';
import RenderStore from '../stores/render-store';
// import sizeMe from 'react-sizeme';

// Actions
// import RenderActions from '../actions/render-actions';

/**
 *
 */
class PageContents extends Component {
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 */
	static getStores() {
		return [
			AuthenticationStore,
			ContextStore, 
			FieldModesStore, 
			FieldStore, 
			FieldTypeStore, 
			RecordStore, 
			RelationshipStore, 
			RenderStore, 
			PageStore
		];
	}

	/**
	 * static - Returns the current state
	 *
	 * @param  {Object} prevState previous state
	 * @param  {Object} props
	   * @returns {Object} state
	 */
	static calculateState(prevState, props) {
		let pageId = props.pageId;
		let pageObj = PageStore.get(pageId);
		let title = (pageObj && pageObj.name && pageObj.name.length ? pageObj.name : undefined);

		let templateData = {};//= TemplateStore.getTemplate();
		let renderObj = undefined;
		let isDialog = true;
		if(props.renderId) {
			renderObj = RenderStore.get(props.renderId);
			if(renderObj && !renderObj.renderParentId) {
				isDialog = false;
			}
		}

		// Setup the record the page is about
		let tableSchemaName = '';
		let installationRecord = RecordStore.getRecord('installations', ContextStore.getInstallationId());
		let recordId = '';
		if (props.dataRecordId) {
			tableSchemaName = props.dataTableSchemaName;
			recordId = props.dataRecordId;
		} else if (renderObj) {
			tableSchemaName = renderObj.dataTableSchemaName;
			recordId = renderObj.dataRecordId;
		}

		let record = RecordStore.getRecord(tableSchemaName, recordId);

		// Setup Google Tag Manager Id.
		let gtmId = sessionStorage.getItem('googleTagManagerId');

		if(gtmId === null) {

			if(installationRecord && installationRecord.googleTagManagerId) {
				gtmId = installationRecord.googleTagManagerId.value;
				if(gtmId === null) {
					gtmId = false;
				}
				sessionStorage.setItem('googleTagManagerId', gtmId);
			}
		}

		// Setup Comet Chat
		let cometChatEnabled = sessionStorage.getItem('cometChatEnabled');

		// Overwrite session storage value if value changes.
		if(installationRecord && installationRecord.cometChatEnabled !== null && typeof installationRecord.cometChatEnabled !== 'undefined') {
			cometChatEnabled = installationRecord && installationRecord.cometChatEnabled && installationRecord.cometChatEnabled.value ? !!installationRecord.cometChatEnabled.value : false;
			sessionStorage.setItem('cometChatEnabled', cometChatEnabled);
		}

		let cometChatId = null;
		if(cometChatEnabled && cometChatEnabled !== 'false' && !isDialog) {
			// Get cometChatId from session storage
			cometChatId = sessionStorage.getItem('cometChatId');
			// Overwrite session storage value if value changes.
			if(installationRecord && installationRecord.cometChatScriptId !== null && typeof installationRecord.cometChatScriptId !== 'undefined') {
				cometChatId = installationRecord && installationRecord.cometChatScriptId && installationRecord.cometChatScriptId.value ? installationRecord.cometChatScriptId.value : null;
				sessionStorage.setItem('cometChatId', cometChatId);
			}
		}

		/*
		 * So.. pageObj is an object, and it comes from PageStore.get() which is powered by an immutable map.. which means
		 * that every time we run calculate state here the pageObj object will be different THIS time then LAST time .. even if
		 * the properties are all the same.. and the objects "look" the same to us.
		 *
		 * Long story short.. dont put objects into the state that are driven by immutable, unless you write your own shouldComponentUpdate();
		 */
		return {
			'pageId': pageId,
			'pageObj': JSON.stringify(pageObj),
			'title' : title,
			'templateData': JSON.stringify(templateData),
			'recordLoaded': !!record,
			'recordFound': !!(record && record.recordId),
			'dataRecordId': recordId,
			'dataTableSchemaName': tableSchemaName,
			'fieldDataLoaded': FieldStore.allPulledFromDatabase(),
			'fieldTypeDataLoaded': FieldTypeStore.allPulledFromDatabase(),
			'relationshipDataLoaded': RelationshipStore.allPulledFromDatabase(), 
			'pageDataLoaded': PageStore.allPulledFromDatabase(),
			'googleTagManagerId': gtmId,
			'cometChatId': cometChatId,
			'cometChatEnabled': cometChatEnabled,
			'isAuthenticated': localStorage.getItem('authenticated'),
			'haveAuthCreds': localStorage.getItem('haveAuthCreds'),
			'renderExists': !!renderObj
		};
	}

	componentDidMount() {
		if(this.state && this.state.fieldDataLoaded && this.state.fieldTypeDataLoaded) {
			_readInstallationData(this.state.googleTagManagerId, this.state.cometChatEnabled, this.state.cometChatId);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// Once the field and field tpe stores have loaded, load the installation data
		// We previously had this in componentDidMount in a listener, which results in
		// unnecessary additional listeners
		// We do still have it in componentDidMount in case it initializes with the correct state (as in ticket 26130)
		// but do not have the listener
		if(this.state.fieldDataLoaded && this.state.fieldTypeDataLoaded && (!prevState || !prevState.fieldDataLoaded || !prevState.fieldTypeDataLoaded)) {
			_readInstallationData(this.state.googleTagManagerId, this.state.cometChatEnabled, this.state.cometChatId);
		}
	}

	/**
	 * render - renders wrapper
	 *
	   * @returns JSX
	 */
	render() {
		let { pageId, dataRecordId, dataTableSchemaName, title } = this.state;
		let pageObj = this.state.pageObj ? JSON.parse(this.state.pageObj) : null;
		//let headerData = {};
		//let leftData = {};
		//let rightData = {};
		//let footerData = {};
		let contentData = [];

		// Ensure template data has loaded before assigning attributes
		//console.log('this.state.templateData', this.state.templateData)
		// if(Object.keys(this.state.templateData).length !== 0){
		//let tData = this.state.templateData.toJS().data.structure;
		//tData = JSON.parse(tData);

		//headerData = tData.header;
		//leftData = tData.left;
		//rightData = tData.right;
		//footerData = tData.footer;
		// }
		if (!this.state.pageId) {
			return null;
		}
		if (!pageObj || !this.state.pageDataLoaded || (this.state.isAuthenticated && !this.state.haveAuthCreds)) {
			return (<Loading title="Loading..." loadingSubject="Page" />);
		}
		if (!this.state.fieldDataLoaded) {
			return (<Loading title="Loading..." loadingSubject="Fields" />);
		}
		if (!this.state.relationshipDataLoaded) {
			return (<Loading title="Loading..." loadingSubject="Relationships" />);
		}
		if (dataRecordId && !this.state.recordLoaded) {
			return (<Loading title="Loading..." loadingSubject="Record" />);
		}

		// Empty Pages should be allowed?!
		// if (Object.keys(pageObj.components).length === 0) {
		// 	return (<NotFound key="notfound" message={"No Page Components found for " + pageId} />);
		// }
		if (dataRecordId && !this.state.recordFound) {
			return (<NotFound title="NOT FOUND" loadingSubject={'No record data found for record ' + dataRecordId} />);
		}

		// Make sure we have a render store entry first
		// (Helps address some bootstrapping issues from index-dev/prod as part of 24678)
		if(!this.state.renderExists) {
			return (<Loading title="Loading..." loadingSubject="Display Information" />);
		}

		if (pageObj !== null) {

			// Populate content area of page
			contentData = (<div id="page-content" className="main-window">
				<GridComponent 
					recordId={pageId} 
					renderId={this.props.renderId}
					renderParentId={this.props.renderParentId}
					dataRecordId={dataRecordId}
					dataTableSchemaName={dataTableSchemaName}
					tableSchemaName="page"
					attachedFieldsJSON={pageObj.attachedFields} 
					fieldPositionJSON={pageObj.fieldPosition} 
					fieldPositionExtrasJSON={pageObj.fieldPositionExtras} 
					screensizeManagementJSON={pageObj.screensizeManagement}
					showSaveControls={(dataRecordId ? "yes" : "no")}
					height={this.props.height}
					width={this.props.width}
					size={this.props.size}
					useWidthProvider={false}
					isDialog={this.props.isDialog}
				/>
			</div>);
		}

		// let titleImage = '';
		// if (this.state.titleImage) {
		// 	let titleClass = "fa fa-" + this.state.titleImage;
		// 	let style = { marginRight: "10px" };
		// 	titleImage = <i className={titleClass} style={style} aria-hidden="true"></i>
		// }

		// @todo this is a hack.. fix!
		// @DM Removed the wrapper styles so we dont have artificial 10px on the top!  Should do this kind of thing in the themes
		// var styles = {
		// 	paddingTop: '10px'
		// };

		let googleTagManagerComponent = null;

		// Use sessionStorage to cache, and reprocess if it's not there.
		let gtmId = this.state.googleTagManagerId;
		if(gtmId && gtmId !== 'false' && gtmId !== false) { // Apparently booleans arent stored as booleans in sessionStorage.
			googleTagManagerComponent = (<GoogleTagManager gtmId={gtmId} />);
		}

		// Comet Chat
		let cometChatComponent = null;
		let cometChatId = this.state.cometChatId;
		if(cometChatId && cometChatId !== 'null' && this.state.cometChatEnabled) {
			cometChatComponent = (<CometChatContainer cometChatId={cometChatId} />);
		}
		return (
				<div style={{height: '100%'}}>
					{googleTagManagerComponent}
					{cometChatComponent}
					<Helmet title={title} />
					{/*<div className="col-12">
						<h1>{titleImage}{title}</h1>
					</div>*/}
					{/*<Header data={headerData} />
			<div className="row">
			<Left data={leftData} />
			{contentData}
			<Right data={rightData} />
			</div>
			<Footer data={footerData} />*/}
					{contentData}
				</div>
		);
	}
}

function _readInstallationData(gtmId, cometChatEnabled, cometChatScriptId) {
	if(FieldStore.allPulledFromDatabase() && FieldTypeStore.allPulledFromDatabase()) {
		
		let toGet = [];
		if(gtmId === null || typeof gtmId === 'undefined') {
			toGet.push('googleTagManagerId');
		}
		if(cometChatEnabled === null || typeof cometChatEnabled === 'undefined') {
			toGet.push('cometChatEnabled');
		}
		if(cometChatScriptId === null || typeof cometChatScriptId === 'undefined') {
			toGet.push('cometChatScriptId');
		}
		// Use sessionStorage to cache, and reprocess if it's not there.
		if(toGet.length) {
			let installationRecord = RecordStore.getRecord('installations', ContextStore.getInstallationId());
			let rereadFromStore = !installationRecord;
			if(installationRecord) {
				toGet.forEach(fieldSchemaName => {
					rereadFromStore = rereadFromStore && !installationRecord[fieldSchemaName];
				});
			}
			if(rereadFromStore) {
				RecordsApi.getRecord('installations', ContextStore.getInstallationId(), toGet);
			}
		}
	}
}

const container = Container.create(PageContents, { withProps: true });
// const sizedContainer = sizeMe({ monitorWidth: true, monitorHeight: true, refreshMode: 'debounce', refreshRate: 128})(container);
export {container};
export default container;