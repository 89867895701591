import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { AdminSettingsStore, FieldSettingsStore, FieldStore, FieldTypeStore, PageStore, LogicFunctionStore, ContextStore, MetadataStore } from '../../stores';
import { AdminSettingsActions } from '../../actions';
import { 
	FieldUtils, 
	// ObjectUtils 
} from '../../utils';

const triggerFriendlyNames = {
	onBlur: 'Lose Focus',
	onBlurChange: 'Lose Focus and Change',
	onClick: 'Click',
	onFocus: 'Gain Focus',
	onEnterUp: 'On Enter Up', // @TODO : Set this one from FT BUILDER
	onMouseOut: 'Mouse Out',
	onMouseOver: 'Mouse Over',
	validate: 'Validate (Local)', // @TODO : Set this one from FT BUILDER
	preFieldSave: 'Validate (Global)',
	onPageLoad: 'After Page Loads',
	prePageSave: 'Page Validates',
	postPageSave: 'After Page Saves',
};

const applicationLevelTriggers = {
	anyPageLoads: 'Any Page Loads',
	onOrientationChange: 'Any Page Changes Orientation'
};


/**
 * Renderer for the appearance assistant
 * 
 * @class AppearanceAssistant
 * @extends {Component}
 */
class AutomationSettingsChooser extends Component {
	/**
	 * Creates an instance of AppearanceAssistant.
	 * @param {any} props 
	 * 
	 * @memberOf AppearanceAssistant
	 */
	// constructor(props) {
	// 	super(props);
	// }

	/**
	 * @static
	 * @returns - the stores that are being watch by NLPBuilder component
	 * 
	 * @memberOf NLPBuilder
	 */
	static getStores() {
		return [AdminSettingsStore, FieldSettingsStore, FieldStore, FieldTypeStore, PageStore, MetadataStore];
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingSchemaName
	 * @param {any} event 
	 * @memberof SettingsPanel
	 */
	onSettingClick(settingSchemaName, event) {
		event.preventDefault();
		//Toggle to select and deselect the Setting
		AdminSettingsActions.onSettingChange(settingSchemaName);

		// Ensure the Settings are hidden
		AdminSettingsActions.onSettingsListHideChange(true);

		/* find a better way to do this once assistant is in place */
		// setTimeout(() => {
		// 	let sizeObj = AdminSettingsStore.getSizeJS();
		// 	sizeObj.height--;
		// 	AdminSettingsActions.onCenterColumnResize(sizeObj);
		// }, 800);

		// This was here so the old NLP Query gets cleared out when a new setting is chosen.
		// AssistantSearchUtils.runAssistantSearch();
	}

	/**
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} - The Dictionaries from NLPBuilderDictionaries Store 
	 * 
	 * @memberOf NLPBuilder
	 */
	static calculateState(prevState) {
		let automationTriggers = [];
		let applicationLevelAutomationTriggers = [];
		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = AdminSettingsStore.getTableSchemaName();
		let parentRecordId = AdminSettingsStore.getParentRecordId();
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let otherTriggerFriendlyNames = [];

		if (tableSchemaName === 'field') {
			let fieldAutomationTriggers = FieldUtils.getLogicTriggers(recordId, parentRecordId);
			automationTriggers = fieldAutomationTriggers['triggers'];
			otherTriggerFriendlyNames = fieldAutomationTriggers['otherTriggerFriendlyNames'];
		} else if (tableSchemaName === 'page') {
			let applicationObj = MetadataStore.get(ContextStore.getApplicationId(), 'applications');
			if(applicationObj) {
				applicationLevelAutomationTriggers = Object.keys(applicationLevelTriggers).map(triggerName => {
					let hasActions = '';
					let logicFunctionIdsArr = []; 

					if(applicationObj['automation-' + triggerName]) {
						let automationObj = JSON.parse(applicationObj['automation-' + triggerName]);
						// check if it has js, if it does get the xml value
						if(automationObj && automationObj.js && automationObj.js.length > 0) {
							hasActions = 'Yes';
						}
						// Handle converting logicFunctionsUsed into an array
						let logicFunctionIds = automationObj && automationObj.logicFunctionsUsed ? automationObj.logicFunctionsUsed : '';
						if (logicFunctionIds) {
							if(Array.isArray(logicFunctionIds)) {
								logicFunctionIdsArr = logicFunctionIds;
							} else {
								logicFunctionIdsArr = logicFunctionIds.split(',');
							}
						}
					}
					return {
						name: triggerName,
						settingsValue: hasActions,
						valueSource:'',
						badgeTypeClass: 'badge-dark',
						logicFunctions: logicFunctionIdsArr
					};
				});
			}
			let pageObj = PageStore.get(recordId);
			if (pageObj) {
				automationTriggers = ['onPageLoad','prePageSave', 'postPageSave', 'onEnterUp'] // @TODO : Set this one from FT BUILDER
					.map(triggerName => { 
					// get the setting for the popover pin
					let automationObj = PageStore.getAutomation(recordId, triggerName);
					let hasActions = '';
					// check if it has js, if it does get the xml value
					if(automationObj && automationObj.js && automationObj.js.length > 0) {
						hasActions = 'Yes';
					}
					// Handle converting logicFunctionsUsed into an array
					let logicFunctionIds = automationObj && automationObj.logicFunctionsUsed ? automationObj.logicFunctionsUsed : '';
					let logicFunctionIdsArr =[]; 
					if (logicFunctionIds) {
						if(Array.isArray(logicFunctionIds)) {
							logicFunctionIdsArr = logicFunctionIds;
						} else {
							logicFunctionIdsArr = logicFunctionIds.split(',');
						}
					}
					return {
						name: triggerName,
						settingsValue: hasActions,
						valueSource:'',
						badgeTypeClass: 'badge-dark',
						logicFunctions: logicFunctionIdsArr
					};
				});
			}
		}

		let allTriggerFriendlyNames = Object.assign(triggerFriendlyNames, otherTriggerFriendlyNames);

		// Flag the selected Trigger
		automationTriggers.map(automationTrigger => {
			if(automationTrigger.name === selectedSetting) {
				automationTrigger.selectedSetting = true;
			}
			return automationTrigger;
		})

		// Sort the action triggers 
		automationTriggers = automationTriggers.sort((a, b) => {
			return (allTriggerFriendlyNames[a.name] > allTriggerFriendlyNames[b.name]) ? 1 : -1;
		});

		return {
			'automationTriggers': automationTriggers,
			'recordId': recordId,
			'tableSchemaName': tableSchemaName,
			'parentRecordId': parentRecordId,
			'parentTableSchemaName': AdminSettingsStore.getParentTableSchemaName(),
			'settingsHidden': AdminSettingsStore.getSettingsListHidden(),
			'allTriggerFriendlyNames' : allTriggerFriendlyNames,
			'applicationLevelAutomationTriggers': applicationLevelAutomationTriggers
		};
	}

	/**
	 * @returns 
	 * @memberof AutomationSettingsChooser
	 */
	render() {
		let {
			allTriggerFriendlyNames,
			automationTriggers, 
			recordId, 
			tableSchemaName,
			applicationLevelAutomationTriggers
		} = this.state;

		let settingsList = automationTriggers.map(trigger => {
			let labelClassNames = 'setting-label';
			if(trigger.selectedSetting) { 
				labelClassNames += ' selected';
			}
			// let iconClassNames = 'fa custom fa-check-circle-o';
			return (<li key={this.state.recordId+'-'+trigger.name} className="nav-item">
					<div className="nav-link" onClick={this.onSettingClick.bind(this, trigger.name)}>
						{/* Temporarily removed the following div */}
						{/* <div className={"setting-icon " + iconClassNames} /> */}
						{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
						{/* Add this back in when the setting-icon is used again */}
						<div className="d-flex setting-text-container">
							<div className="w-50 setting-label-container">
								<div className={labelClassNames}><h4>{allTriggerFriendlyNames[trigger.name]}</h4></div>
							</div>
							<div className="w-50 d-flex justify-content-between setting-value-container">
								<div className={"text-right setting-pattern-list setting-value " + trigger.valueLabelClass }>
									{ trigger.settingsValue }
								</div>
								{ trigger.valueSource ? 
									<div className={"setting-scope badge badge-pill " + trigger.badgeTypeClass + " align-self-center"}> 
										{ trigger.valueSource } 
									</div>
								: null
								}
							</div>
						</div>
					</div>
					{trigger.logicFunctions && trigger.logicFunctions.length ?
						(<ul style={{ listStyleType: 'none' }}>
							{trigger.logicFunctions.map(functionId => {
								let functionObj = LogicFunctionStore.get(functionId) || {};
								let name = functionObj.name ? functionObj.name : functionId;
								let description = functionObj.description ? functionObj.description : '';
								return (<li key={functionId} className='setting-pattern-list setting-value logic-sub-item' title={description}>
									{name}
								</li>);
							})}
						</ul>) :
						null}
				</li>);
		});

		let applicationLevelSettingSection = null;
		let applicationLevelSettingsList = [];

		let componentIcon = '';
		let headerLabel = 'Logic Triggers';
		if(tableSchemaName === 'page') {
			componentIcon = FieldUtils.getFieldTypeIcon('page');
			headerLabel = 'Logic Triggers';
			// headerLabel = 'Page Logic Triggers';
			// @TODO: No, this won't work... I need to build the full trigger object in the state from the store
			// and then access it here
			applicationLevelSettingsList = applicationLevelAutomationTriggers && applicationLevelAutomationTriggers.map(trigger => {
				let labelClassNames = 'setting-label';
				if(trigger.selectedSetting) { 
					labelClassNames += ' selected';
				}
				// let iconClassNames = 'fa custom fa-check-circle-o';
				return (<li key={this.state.recordId+'-'+trigger.name} className="nav-item">
						<div className="nav-link" onClick={this.onSettingClick.bind(this, trigger.name)}>
							{/* Temporarily removed the following div */}
							{/* <div className={"setting-icon " + iconClassNames} /> */}
							{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
							{/* Add this back in when the setting-icon is used again */}
							<div className="d-flex setting-text-container">
								<div className="w-50 setting-label-container">
									<div className={labelClassNames}><h4>{applicationLevelTriggers[trigger.name]}</h4></div>
								</div>
								<div className="w-50 d-flex justify-content-between setting-value-container">
									<div className={"text-right setting-pattern-list setting-value " + trigger.valueLabelClass }>
										{ trigger.settingsValue }
									</div>
									{ trigger.valueSource ? 
										<div className={"setting-scope badge badge-pill " + trigger.badgeTypeClass + " align-self-center"}> 
											{ trigger.valueSource } 
										</div>
									: null
									}
								</div>
							</div>
						</div>
						{trigger.logicFunctions && trigger.logicFunctions.length ?
							(<ul style={{ listStyleType: 'none' }}>
								{trigger.logicFunctions.map(functionId => {
									let functionObj = LogicFunctionStore.get(functionId) || {};
									let name = functionObj.name ? functionObj.name : functionId;
									let description = functionObj.description ? functionObj.description : '';
									return (<li key={functionId} className='setting-pattern-list setting-value logic-sub-item' title={description}>
										{name}
									</li>);
								})}
							</ul>) :
							null}
					</li>);
			});
			applicationLevelSettingSection = <ul className="nav flex-column settings-list-wrapper mt-0">
			<li className="settings-list-header field-settings-list-header d-flex">
				<img style={{ marginTop: '4px' }} height="22" width="22" className="mr-2" src={componentIcon} alt="" />
				<h3>Other Triggers</h3>
			</li>
			{applicationLevelSettingsList && applicationLevelSettingsList.length
				? applicationLevelSettingsList
				: null
			}
		</ul>
		} else {
			componentIcon = FieldUtils.getFieldTypeIcon(recordId);
			headerLabel = 'Logic Triggers';
			// headerLabel = FieldUtils.getFieldTypeName(recordId) + ' Logic Triggers';
		}

		return (
			<div>
				<ul className="nav flex-column settings-list-wrapper mt-0">
					<li className="settings-list-header field-settings-list-header d-flex">
						<img style={{ marginTop: '4px' }} height="22" width="22" className="mr-2" src={componentIcon} alt="" />
						<h3>{ headerLabel }</h3>
					</li>
					{settingsList && settingsList.length
						? settingsList
						: <div className='no-settings-found'>No Settings Found</div>
					}
				</ul>
				{applicationLevelSettingSection}
			</div>
		);
	};
}

const container = Container.create(AutomationSettingsChooser, {withProps: true});
export default container;
