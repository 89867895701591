import AuthenticationActions from '../actions/authentication-actions';
import BrowserStorageActions from '../actions/browser-storage-actions';
import history from '../utils/history';
import FieldStore from '../stores/field-store';
import FieldSettingsStore from '../stores/field-settings-store';
import {NotificationManager} from 'react-notifications';
import ObjectUtils from '../utils/object-utils';
import PageModeActions from '../actions/page-mode-actions';
import PageModeStore from '../stores/page-mode-store';
import PageStore from '../stores/page-store';
import AuthenticationStore from '../stores/authentication-store';
import ContextStore from '../stores/context-store';
import FieldModesStore from '../stores/field-modes-store';
import FieldModeActions from '../actions/field-mode-actions';
import ProcessingActions from '../actions/processing-actions';
import RecordActions from '../actions/record-actions';
import RecordStore from '../stores/record-store';
import RenderActions from '../actions/render-actions';
import RenderStore from '../stores/render-store';
import RecordSetActions from '../actions/record-set-actions';
import uuid from 'uuid';

var InterfaceActions = {
	componentDisable: function(opts) {
		RenderActions.componentDisable(opts.componentId);
	},

	componentEnable: function(opts) {
		RenderActions.componentEnable(opts.componentId);
	},


	log: function(opts) {
		let level = opts.level ? opts.level : 'log';
		switch (level) {
			default:
			case 'log':
				console.log(opts.message);
				break;
			case 'info':
				console.info(opts.message);
				break;
			case 'warning':
				console.warn(opts.message);
				break;
			case 'error':
				console.error(opts.message);
				break;
		}
	},

	/**
	 * Reloads the UI with an option to force the reload
	 * 
	 * @param {Object} opts 
	 */
	reloadUI: function(opts) {
		ProcessingActions.showReloadUI(opts.force);
	},

	/**
	 * Opens the maintenance ui with the message
	 * 
	 * @param {Object} opts 
	 */
	maintenanceUI: function(message) {
		ProcessingActions.maintenanceUI(message);
	},
	
	/**
	 * Displays a notification to the user
	 * 
	 * @param {Object} opts
	 * @param {string} opts.message - body of the message
	 * @param {string} opts.title - defaults to empty
	 * @param {string} opts.level - (info|success|warning|error) defaults to info
	 * @param {number} opts.timeout - Timeout in milliseconds defaults to 5000
	 */
	notification: function(opts) {
		if (!opts.message && opts.message !== 0 && opts.message !== false) {
			return;
		}

		let type = opts.level ? opts.level : 'info';
		let title = opts.title || opts.title === 0 || opts.title === false ? (opts.title + '') : '';
		let message = opts.message || opts.message === 0 || opts.message === false ? (opts.message + '') : '';
		let id = opts.id ? opts.id : uuid.v4();
		let timeout = typeof opts.timeout ? opts.timeout : 5000;
		if(opts.timeOut) {
			timeout = opts.timeOut;
		}

		NotificationManager.create({ 
			'type': type, 
			'title': title, 
			'message': message, 
			'timeOut': timeout, 
			'id': id 
		});
	},

	/**
	 * Displays a message when a service spins up
	 * 
	 * @param {Object} opts 
	 */
	serviceStartNotification: function(opts) {
		let notificationParams = Object.assign({ 
			'level': 'warning', 
			'title': 'One Moment', 
			'message': 'We are enabling those resources for you.', 
			'timeout': 30000, 
			'id': 'servicespinup'  
		}, opts);

		let existingServiceStartNotices = NotificationManager.listNotify.filter(ln => {
			return ln.id === 'servicespinup'
		})

		if(existingServiceStartNotices.length === 0) {
			this.notification(notificationParams);
		}
	},

	/**
	 * Displays a notification to the user that persists unless clicked or until cleared
	 * 
	 * @param {Object} opts
	 * @param {string} opts.message - body of the message
	 * @param {string} opts.title - defaults to empty
	 * @param {string} opts.level - (info|success|warning|error) defaults to info
	 */
	stickyNotification: function(opts) {
		if (!opts.message && opts.message !== 0 && opts.message !== false ) {
			return;
		}

		const Constants = {
			info: 'info',
			success: 'success',
			warning: 'warning',
			error: 'error'
		  };

		let level = opts.level && Constants[opts.level] ? Constants[opts.level] : Constants.info;
		let title = opts.title || opts.title === 0 || opts.title === false ? (opts.title + '') : '';
		let message = opts.message || opts.message === 0 || opts.message === false ? (opts.message + '') : '';
		let id = opts.id ? opts.id : uuid.v4();
		NotificationManager.create({
			id,
			type: level,
			message: message,
			title,
			timeOut: 0
		  });
		
		  return id;
	},
	/**
	 * Clears a sticky notification using the notification's ID
	 * 
	 * @param {string} id The ID of the notification to clear
	 */
	clearStickyNotification: function(id) {
		try {
			NotificationManager.remove({id: id});
		} catch(err) {
			console.warn('Unable to clear Notification by that ID. Are you sure it exists?');
		}
	},
	clearAllNotifications: function() {
		// Method used to clear all notifications
		NotificationManager.listNotify = [];
		NotificationManager.emitChange();
	},
	/**
	 * Reloads the target (current page or a field)
	 * 
	 * @param {Object} opts
	 * @param {string} opts.target - the page or a specific field
	 * @param {string} opts.clearCache - Whether to clear the cache when reloading the page or not
	 * @param {string} opts.targetId - The ID of the field on the page to reload
	 * 
	 * @todo implement reloading field
	 */
	reloadTarget: function(opts) {
		if(!opts) {
			console.error('Attempted to run reloadTarget with no opts');
		}
		let {clearCache, target} = opts;
		if(target === 'page' || !target) {
			// Reloads current page. Casts clearCache to boolean and passes as parameter to reload.
			location.reload(!!clearCache);
		} else if (target === 'field') {
			if(!opts.fieldId) {
				console.warn('reloadTarget run without fieldId. Skipping. opts value was', opts);
			} else {
				RenderActions.refreshField(opts.fieldId);
			}
		} else {
			console.error('Invalid target passed into reloadTarget');
		}
	},

	/**
	 * Goes to appropriate location in history
	 * @param {Object} pos The position in history to go to. Defaults to -1 (back) if not provided.
	 * 
	 */
	historyGo: function(pos) {
		if(!pos && pos !== 0) {
			pos = -1;
		}
		history.go(pos);
	},
	
	/**
	 * Opens a Dialog above the current page
	 * @todo implement
	 */
	openDialog: function() {
		console.log('Open Dialog is not Implemented');
	},

	closePage: function(opts) {
		let pageRenderId = opts.renderId;
		let renderObj = pageRenderId ? RenderStore.get(pageRenderId) : null;
		while(renderObj && renderObj.componentType !== 'page') {
			pageRenderId = renderObj.renderParentId;
			renderObj = pageRenderId ? RenderStore.get(pageRenderId) : null;
		}


		if (pageRenderId) {
			if (renderObj && renderObj.dataTableSchemaName && renderObj.dataRecordId) {
				let FieldUtils = require('../utils/field-utils').default;
				let fields = FieldUtils.getAllRenderedChildren(pageRenderId);
				let record = RecordStore.getRecord(renderObj.dataTableSchemaName, 
					renderObj.dataRecordId);
				let dirtyFields = {};
				fields.forEach((field) => {
					if (record[field.fieldSchemaName] && record[field.fieldSchemaName].isDirty) {
						if (record[field.fieldSchemaName].inConflict) {
							dirtyFields[field.fieldSchemaName] = record[field.fieldSchemaName].conflictValue;
						} else {
							dirtyFields[field.fieldSchemaName] = record[field.fieldSchemaName].originalValue;
						}
					}
				});
				if (Object.keys(dirtyFields).length) {
					RecordActions.updateRecord(renderObj.dataTableSchemaName, renderObj.dataRecordId, dirtyFields);
				}
			}
			RenderActions.deleteRender(pageRenderId, opts.reassignPageChildren);
			if (pageRenderId === ContextStore.getPageRenderId()) {
				window.close();
			}
		}
	},

	/**
	 * Recalculates a record set given and record set name, component, location and renderId.
	 * @param {Object} opts
	 * @param {string} opts.recordSetName - The record set name
	 * @param {string} opts.componentId - The component Id of the component that has the record set
	 * @param {string} opts.location  - The geneeral location of the record set: currentPage, pageBelow, or allInstances
	 * @param {string} opts.renderId - Render Id of the component to start the record set search from
	 */
	recalculateRecordSet: function(opts) {
		if (!opts.renderId) {
			console.error('Render ID not passed to recalculateRecordSet');
			return;
		}

		let recordSetRenderIds = [];
		let recordSetName = opts.componentId+'-'+opts.recordSetName;

		let pageRenderObj = RenderStore.getPageRenderObj(opts.renderId);
		if (opts.location === 'currentPage') {
			getRecordSetRenderIdsFromPage(recordSetRenderIds, pageRenderObj ? pageRenderObj.renderId : undefined, opts.componentId, opts.recordSetName);
			recordSetRenderIds.push(opts.renderId);
		} else if (opts.location === 'pageBelow' && (pageRenderObj && pageRenderObj.renderParentId)) {
			let pageBelowRenderObj = RenderStore.getPageRenderObj(pageRenderObj ? pageRenderObj.renderParentId : undefined);
			getRecordSetRenderIdsFromPage(recordSetRenderIds, pageBelowRenderObj ? pageBelowRenderObj.renderId : undefined, opts.componentId, opts.recordSetName);
		}

		if (recordSetRenderIds.length) {
			recordSetRenderIds.forEach((renderId) => {
				RecordSetActions.recalculateRecordSet(renderId, recordSetName).catch(error => {
					console.error('Error recalculating recordSet %s-%s', renderId, recordSetName, error);
				});
			})
		}
	},

	/**
	 * Replaces the current Page with a new URL
	 * @param {Object} opts
	 * @param {string} opts.url - URL to go to
	 * @param {string} opts.pageId - page to go to
	 * @param {string} opts.tableSchemaName - Table Schema Name to go to
	 * @param {string} opts.uiTreatment - replace page or open page in new tab
	 * @param {string} opts.recordId - recordID to go to
	 * @param {boolean} opts.replaceHistory - Replace, or add the target page to the react history
	 * @param {boolean} opts.method - Send using POST or GET
	 * @param {boolean} opts.restParams - Parameters to send when using POST 
	 * @param {string} opts.renderParentId
	 */
	replacePage: function(opts) { 
		let url = '';
		let outsideLink = false;
		if(opts.url) {
			url = opts.url;

			// Match this against a URL matcher which looks for a full filepath, typically an outside link, rather than one we want to use to extend the current path.
			outsideLink = !url.startsWith('/');
			//Appends http if does not already begin with http or https
			if (outsideLink && !url.match(/^https?:\/\//i)) {
				url = 'http://' + url;
			}

		}
		
		if(opts.pageId && opts.tableSchemaName && opts.recordId) {
			url = '/' + opts.pageId + '/' + opts.tableSchemaName + '/' + opts.recordId;
		} else if (opts.pageId) {
			url = '/' + opts.pageId;
		}

		if (opts.uiTreatment === 'replacePage' || !opts.uiTreatment) {

			// If the action is in the queue and we have an actionId, clear it to avoid false unsaved changes warnings
			// (ticket 17778)
			
			if(url !== '') {

				// Check if there are any unsaved records on this page
				let hasDirty = RenderStore.hasDirtyRecords(opts.renderParentId);


				let shouldRun = hasDirty ? confirm('Changes you made may not be saved.') : true;

				if(!shouldRun) {
					return;
				}

				// First, if the record store for the page being replaced or any children are dirty,
				// warn the user about changing pages
				if(outsideLink) {
					if(opts.actionId) {
						ProcessingActions.removeActionId(opts.actionId);
					}
					let method = opts.method;
					let restParams = opts.restParams;
					// Right now we only offer GET and POST
					if(method === 'POST') {
						let toSubmit = document.createElement('form');
						toSubmit.action = url;
						toSubmit.method = 'post';
						toSubmit.enctype = 'application/x-www-form-urlencoded';
						if(restParams) {
							Object.keys(restParams).forEach(key => {
								let val = restParams[key];
								let hiddenField = document.createElement('input');
								hiddenField.setAttribute('type', 'hidden');
								hiddenField.setAttribute('name', key);
								hiddenField.setAttribute('value', val);

								toSubmit.appendChild(hiddenField);
							});
						}
						document.body.appendChild(toSubmit);
						toSubmit.submit();
					} else {
						if(restParams) {
							// Add in the parameters
							let paramsArr = Object.keys(restParams);
							url += paramsArr.length ? ((url.includes('?') ? '&' : '?') + paramsArr.map(key => key + '=' + encodeURIComponent(restParams[key])).join('&')) : '';
						}
						// Use document.location if it's a complete outside link
						if (opts.replaceHistory) {
							document.location.replace(url);
						} else {
							document.location.href = url;
						}
					}
				} else {
					// Use our history library if it's an internal/partial link
					if (opts.replaceHistory) {
						history.replace(url, opts.state);
					} else {
						history.push(url);
					}
				}
			}
		} else if(opts.uiTreatment === 'openNewTab'){

			//Build the Base Path 
			let basePath = 'https://dev-eng-sandbox.citizendeveloper.com';
			
			if(window.location.protocol === 'https:') {
				basePath = 'https://' + window.location.host;
			}
			
			// //Support Webpack Development 
			let developmentPath = window.location.host.match(/:\d+$/);

			if(developmentPath) {
				basePath = 'http://' + window.location.host;
			}
			
			// build the url: 
			let targetUrl = outsideLink ? url : basePath + url;
			let method = opts.method;
			let restParams = opts.restParams;
			// Right now we only offer GET and POST
			if(method === 'POST') {
				let toSubmit = document.createElement('form');
				toSubmit.action = targetUrl;
				toSubmit.method = 'post';
				toSubmit.enctype = 'application/x-www-form-urlencoded';
				toSubmit.target = '_blank';
				if(restParams) {
					Object.keys(restParams).forEach(key => {
						let val = restParams[key];
						let hiddenField = document.createElement('input');
						hiddenField.setAttribute('type', 'hidden');
						hiddenField.setAttribute('name', key);
						hiddenField.setAttribute('value', val);

						toSubmit.appendChild(hiddenField);
					});
				}
				document.body.appendChild(toSubmit);
				toSubmit.submit();
			} else {
				if(restParams) {
					// Add in the parameters
					let paramsArr = Object.keys(restParams);
					targetUrl += paramsArr.length ? ((targetUrl.includes('?') ? '&' : '?') + paramsArr.map(key => key + '=' + encodeURIComponent(restParams[key])).join('&')) : '';
				}
				window.open(targetUrl);
			}

		} else if(opts.uiTreatment === 'openNewWindow'){
			//@Todo update Action uiTreatment dropdown and then support that option here
		} else if(opts.uiTreatment === 'openDialog'){
			let {pageId, recordId, tableSchemaName, renderParentId, dialogOptions} = opts;
			let renderId = uuid.v4();
			openPageInDialog(renderId, pageId, tableSchemaName, recordId, renderParentId, dialogOptions);
		} else if (opts.uiTreatment === 'replaceDialog') {
			let {pageId, recordId, tableSchemaName, renderParentId, dialogOptions} = opts;
			
			let renderId = renderParentId;
			// Find the most recent page ancestor
			let renderObj = RenderStore.getPageRenderObj(renderId);
			renderId = renderObj ? renderObj.renderId : renderId;
			// Preserve the page's parent render ID
			renderParentId = renderObj && renderObj.renderParentId ?
				renderObj.renderParentId :
				undefined;
			// If the render ID is the parent page OR if it's nonexistent (such as because "close page" was run first, open a new dialog)
			if(renderId === ContextStore.getPageRenderId() || !renderId) {
				// This is not a dialog, so open a new one
				renderParentId = opts.renderParentId;
				renderId = uuid.v4();
			}

			// Check if there are any unsaved records on this page
			let hasDirty = RenderStore.hasDirtyRecords(renderId);

			let shouldRun = hasDirty ? confirm('Changes you made may not be saved.') : true;

			if(!shouldRun) {
				return;
			}
			
			openPageInDialog(renderId, pageId, tableSchemaName, recordId, renderParentId, dialogOptions);
		}
	},

	/**
	 * Finds the closest page render id for the given render id and saves
	 * the grid for it
	 * 
	 * @param {Object} opts
	 * @param {string} opts.renderId - Render Id of a page or a child element
	 * @returns {Promise}
	 */
	savePage: function(opts) {
		let pageRenderId = opts.renderId;
		let ignoreRequiredSetting = opts.ignoreRequiredSetting || false;
		let renderObj = pageRenderId ? RenderStore.get(pageRenderId) : null;
		while(renderObj && renderObj.componentType !== 'page') {
			pageRenderId = renderObj.renderParentId;
			renderObj = pageRenderId ? RenderStore.get(pageRenderId) : null;
		}

// console.log('Interface Actions: savePage, RenderId:', pageRenderId, RenderStore.get(pageRenderId));

		if (pageRenderId) {
			return this.saveGrid({'renderId': pageRenderId, 'ignoreRequiredSetting': ignoreRequiredSetting});
		} else {
			return Promise.resolve();
		}
	},
	
	/**
	 * Updates the local Storage keys for username and userId
	 * @param {Object} response
	 * @param {string} response.userId - Auth UserId
	 * @param {string} response.username - Auth Username
	 * @param {string} response.signedMdKey - Signed Metadata Key
	 */
	changeUser: (response) => {
		// Authentication Store needs to be updated as well:
		AuthenticationActions.onAuthLogin(response);
	},
	/**
	 *  Updates Records in the Store requested from the backend
	 * 
	 * @param {string} recordId - Record Id to update
	 * @param {string} tableSchemaName - record's tableSchemaName
	 * @param {Array} fields - array of Field Object like {fieldType, fieldSchemaName, value}, where value contains the updates
	 * @param {boolean} forceClean - it accepts updates to the record in the record store, and does NOT mark them as dirty
	 * 
	 */
	updateRecord: ({recordId, tableSchemaName, fields, forceClean}) => {
		//Update the Store:
		//Fields is an array and expects an object so transform it into an object 
		let fieldsObj = {};

		fields.forEach(field => {
			let fieldObj = FieldStore.get(field.fieldId);
					// Is this a content tab or dropdown?
					// If so, also toss this to the record set store
					// in a way that forces this to recalculate appropriately
					if (fieldObj.fieldType === 'bb5bedc3-44d1-4e4c-9c40-561a675173b1' || fieldObj.fieldType === '846b747e-25a0-40df-8115-af4a00a1cab5') {
						try {
							let valueObj = field.value ? ObjectUtils.getObjFromJSON(field.value) : {};
							let renders = RenderStore.getRenderObjectsForComponent('field', field.fieldId, tableSchemaName, recordId);
							renders = renders && renders.toJS ? renders.toJS() : renders;
							if(renders) {
								Object.keys(renders).forEach(renderId => {
									RecordSetActions.setSelectedContentTab(renderId, tableSchemaName, recordId, fieldObj.fieldSchemaName, fieldObj.fieldId + '-selected', valueObj.tableSchemaName, valueObj, 'Selected Tab');
								});
							}
						} catch(err) {
							console.warn('Attempted to update a content tab or dropdown with invalid value', field.value);
							console.warn('error was', err);
						}
						
					} else {
						fieldsObj[field.fieldSchemaName] = field.value;
					}
		});

		//Update RecordStore
		RecordActions.updateRecord(tableSchemaName, recordId, fieldsObj, forceClean); 

	},
	/**
	 * Updates the browserStorage (and the BrowserStore?) with the fieldschemaName and value of a given field
	 * @param {Object} response
	 * @param {string} response.fieldSchemaName - Field to update in browser storage
	 * @param {string} response.value - Value
	 * 
	 */
	setBrowserStorage: (response) => {
		//update browser storage with the fieldSchemaName and its value 
		BrowserStorageActions.setValue(response.fieldSchemaName, response.value)
	},  

	/**
	 * Updates the Authentication State of the session
	 * 
	 * @param {Object} response
	 * @param {Object} response.redirectPath - After logout we redirect user to a public available page (like back to login?)
	 * 
	 */
	logout: () => {
		//Authenticate here
		AuthenticationActions.onAuthLogout();
		
	},

	downloadFile: ({sourceUrl, fileName}) => {
		let downloadUrl = '';
		
		if(sourceUrl.includes('/gw/download-file?')){
			//Means we are downloading a private file 
			let basePath = 'https://' + window.location.host;
			let basePathOverridePrefix = sessionStorage.getItem('defaultAPIGWOverride');
			if(basePathOverridePrefix) { 
				basePath = 'https://' + basePathOverridePrefix + '.citizendeveloper.com';
			}
			
			let portRegexp = new RegExp(/:\d+$/);
			let portMatches = portRegexp.exec(basePath);
			if (portMatches) {
				//if the base path has a port number then this is a webpack dev server
				console.log('Dev Server Detected')
				basePath = basePath.replace(portMatches[0], '');
			}

			downloadUrl = basePath + sourceUrl;
		} else {
			// Means we are downloading a passed url from different domain 
			downloadUrl = sourceUrl;
		}

		//Create an anchor tag 
		let downloadAnchor = document.createElement('a'); 
		
		//Set the attributes with the dataURL 
		downloadAnchor.setAttribute('href',downloadUrl);

		//Set the Download Feature
		downloadAnchor.setAttribute('download', fileName);

		//Don't show it
		downloadAnchor.setAttribute('class','hidden'); 

		// Try adding a blank target?
		downloadAnchor.setAttribute('target','_blank'); 

		//Add it to the DOM 
		document.body.appendChild(downloadAnchor); 

		//Click to Download 
		downloadAnchor.click();

		//Remove from DOM
		document.body.removeChild(downloadAnchor);
	},
	
	/**
	 * Saves the component provided by the render id
	 * 
	 * @param {Object} opts 
	 * @param {string} otps.renderId Render Id fo the component to save
	 * @returns {Promise}
	 */
	saveGrid: function({renderId, ignoreRequiredSetting}) {
		return new Promise((resolve, reject) => {
			let ActionProcessor = require('../utils/action-processor').default;
			let PageStore = require('../stores/page-store').default;
			let RecordsAPI = require('../apis/records-api').default;

			//If no renderId, assume we are saving the Page in Context 
			if (!renderId) {
				renderId = ContextStore.getPageRenderId();
			}

			//If we still dont have a renderId bail out....
			if(!renderId) {
				return reject(new Error(`Can not Save Grid with renderId of ${renderId}`));
			}
			let renderObj = RenderStore.get(renderId);
			//If we still dont have a renderId bail out....
			if(!renderObj) {
				return reject(new Error(`Can not Save Grid with renderId of ${renderId} because render store entry is missing`));
			}
			let {componentId, componentType, dataRecordId, dataTableSchemaName} = renderObj;
			if(!dataRecordId || !dataTableSchemaName) {
				console.warn('Warning: saving grid component with missing render data record ID and/or tableSchemaName. renderObj was', renderObj);
			}
			let currentMode = '';
			let preSaveActionJS = null;
			let postSaveActionJS = null;
			let actionRecordId = componentId;
			let actionTableSchemaName = componentType;

			// Get all records and fields for validation, including non-dirty required fields
			let recordsToValidateObj = recursivelyGetDirtyRecords(renderId, {}, ignoreRequiredSetting);

			// We need this for record validation in the appropriate format
			let recordsToValidate = [];
			Object.keys(recordsToValidateObj).forEach(tsn => {
				Object.keys(recordsToValidateObj[tsn]).forEach(recordId => {
					let toPush = {
						tableSchemaName: tsn,
						recordId,
						fields: {},
						fieldsRender:{}
					};
					Object.keys(recordsToValidateObj[tsn][recordId]).forEach(fsn => {
						toPush.fields[fsn] = recordsToValidateObj[tsn][recordId][fsn].value;
						toPush.fieldsRender[fsn] = recordsToValidateObj[tsn][recordId][fsn].fieldInfo;
					});
					recordsToValidate.push(toPush);
				});
			});

			// Get ONLY the dirty records to save
			let dirtyRecordsObj = recursivelyGetDirtyRecords(renderId, {}, true);

			// Convert this to the dirtyRecords format the rest of this code was set up for
			let dirtyRecords = [];
			Object.keys(dirtyRecordsObj).forEach(tsn => {
				Object.keys(dirtyRecordsObj[tsn]).forEach(recordId => {
					let toPush = {
						tableSchemaName: tsn,
						recordId,
						fields: {},
						fieldsRender:{}
					};
					Object.keys(dirtyRecordsObj[tsn][recordId]).forEach(fsn => {
						toPush.fields[fsn] = dirtyRecordsObj[tsn][recordId][fsn].value;
						toPush.fieldsRender[fsn] = dirtyRecordsObj[tsn][recordId][fsn].fieldInfo;
					});
					dirtyRecords.push(toPush);
				});
			});

			if (componentType === 'page') {
				// Setup the current mode for later use.
				currentMode = PageModeStore.getCurrentMode(renderId);
				
				// Find the pre and post actions to run
				let preSaveObj = PageStore.getAutomation(componentId, 'prePageSave');
				let postSaveObj = PageStore.getAutomation(componentId, 'postPageSave');
				preSaveActionJS = preSaveObj && preSaveObj.js ?
					preSaveObj.js : 
					null;
				postSaveActionJS = postSaveObj && postSaveObj.js ?
					postSaveObj.js : 
					null;
			} else { // We're saving a field container directly.
				let mode = FieldModesStore.getMode(renderId),
					fieldSettingsObj = FieldStore.getSettings(componentId);

				currentMode = mode ? mode.currentMode : '';

				if(fieldSettingsObj && fieldSettingsObj['automation-prePageSave']) {
					let preSaveActionsJSON = fieldSettingsObj['automation-prePageSave'],
					preSaveActionsObj = preSaveActionsJSON ? ObjectUtils.getObjFromJSON(preSaveActionsJSON) : null;
					preSaveActionJS = preSaveActionsObj ? preSaveActionsObj.js : null;
				}
				if(fieldSettingsObj && fieldSettingsObj['automation-postPageSave']) {
					let postSaveActionsJSON = fieldSettingsObj['automation-postPageSave'],
					postSaveActionsObj = postSaveActionsJSON ? ObjectUtils.getObjFromJSON(postSaveActionsJSON) : null;
					postSaveActionJS = postSaveActionsObj ? postSaveActionsObj.js : null;
				}
			}

			if(currentMode === 'add' && (!dirtyRecordsObj[dataTableSchemaName] || !dirtyRecordsObj[dataTableSchemaName][dataRecordId])) {
				// If we're adding a record and the current record isn't dirty, we need to add it anyway.
				let toPush = {
					tableSchemaName: dataTableSchemaName,
					recordId: dataRecordId,
					fields: {},
					fieldsRender:{}
				};
				dirtyRecords.push(toPush);
			}

			let contextObj = ContextStore.getState();

			let validationRequiredPromises = recordsToValidate.map(record => {
				let validationPromise = ignoreRequiredSetting ?
					Promise.resolve(true) :
					verifyRequiredStatus(record.recordId, record.tableSchemaName, record.fieldsRender);
				return validationPromise;
			});

			Promise.all(validationRequiredPromises)
				.then(passedRequiredCheck => {
					passedRequiredCheck = passedRequiredCheck.every((result) => result === true);

					if(!passedRequiredCheck) {
						return [false];
					}

					let validationIsValidCodePromises = recordsToValidate.map(record => verifyIsValidCode(record.recordId, record.tableSchemaName, record.fieldsRender));
					return Promise.all(validationIsValidCodePromises)
				})
				.then(isValid => {
					isValid = isValid.every((result) => result === true);
					if(!isValid) {
						return [false];
					}
					let validationfieldPreSavePromises = recordsToValidate.map(record => fieldPreSaveValidations(record.recordId, record.tableSchemaName, record.fieldsRender, renderId));

					return Promise.all(validationfieldPreSavePromises)
				})
				.then(passedPreFieldSave => {
					passedPreFieldSave = passedPreFieldSave.every((result) => result === true);
					if(!passedPreFieldSave) {
						return false;
					}

					let prePageSave = null;
					if (preSaveActionJS) {
						let actionRequest = {
							actionRecordId: actionRecordId,
							actionTableSchemaName: actionTableSchemaName,
							hookId: 'prePageSave',
							action: preSaveActionJS,
							recordId: dataRecordId,
							renderId: renderId,
							tableSchemaName: dataTableSchemaName
						};
						prePageSave = ActionProcessor.processAction(actionRequest);
					} else {
						prePageSave = Promise.resolve();
					}

					// I think this does actually need to remain nested, at least for now
					return prePageSave
						.then((prePageOutput) => {
							if (prePageOutput && prePageOutput.validationFailed) {
								throw new Error('validationFailed');
							} else {
								// NOW we want to save ONLY the dirty records
								let dirtyPromises = dirtyRecords.map(record => {
									if (currentMode === 'edit' || currentMode === 'view') {
										return RecordsAPI.updateRecord(contextObj, record);
									} else if (currentMode === 'add') {
										return RecordsAPI.addRecord(contextObj, record);
									} else {
										console.warn('Attempted to save grid in unsupported mode: ', currentMode);
										return Promise.resolve();
									}
								});

								return Promise.all(dirtyPromises);
							}
						})
						.then(() => {
							if (!postSaveActionJS) {
								return;
							}
							let actionRequest = {
								actionRecordId: actionRecordId,
								actionTableSchemaName: actionTableSchemaName,
								hookId: 'postPageSave',
								action: postSaveActionJS,
								recordId: dataRecordId,
								renderId: renderId,
								tableSchemaName: dataTableSchemaName
							};
							return ActionProcessor.processAction(actionRequest);
						})
						.then(() => true);
				})
				.then(savedSuccessfully => {
					if(!savedSuccessfully) {
						// If we failed any of our checks, we need to fail the save, but we still resolve
						RenderActions.failSaveGrid(renderId);
						return resolve();
					} else {
						this.toggleCurrentMode(renderId);
						return resolve('Success Saving Grid');
					}
				})
				.catch(error => {
					console.error('Save Error', error);
					let errorMessage = error && error.message ? error.message : JSON.stringify(error);
					if(errorMessage === 'validationFailed') {
						// Trigger the grid save to fail
						RenderActions.failSaveGrid(renderId);
						return resolve('Validation Failed - Grid Not Saved');
					} else if (errorMessage === 'Unauthorized') {
						let message = 'Authorization Failed - User not authorized for this logic';
						InterfaceActions.notification({ 'level': 'error', 'message': message });
						return resolve(message);
					} else {
						return reject(new Error(`Save Error: ${errorMessage}`));
					}
				});
		});
	},
	/**
	 * Loops through all components listed in page data and toggles their currentMode in ModeStore to 'edit' or 'view'
	 * @param {string} renderId - Render Id of the field or page
	 */
	toggleCurrentMode: function(renderId) {
		let renderObj = RenderStore.get(renderId);
		if(renderObj) {
			let {componentType, componentId} = renderObj;
	
			if (componentType === 'page') {
				let currentMode = PageModeStore.getCurrentMode(renderId);
				
				// determine alternative mode
				let newMode = (currentMode === 'view') ? 'edit' : 'view';
				if (PageModeStore.hasAvailableMode(componentId, newMode)) {
					PageModeActions.setMode(renderId, newMode);
				}
			} else {
				let mode = FieldModesStore.getMode(renderId);
				let currentMode = mode ? mode.currentMode : '';
				let newMode = (currentMode === 'view') ? 'edit' : 'view';
				if (FieldModesStore.hasAvailableMode(renderId, newMode)) {
					FieldModeActions.setMode(renderId, newMode)
				}
			}
		}
	},

	/**
	 * Gets the user's current location
	 * Always resolves a Promise of the form {lat, lng}, but
	 * defaults to undefined values for both if location not supported
	 * or if user rejects location permissions.
	 */
	getUserLocation: function(userLoc) {
		return new Promise((resolve) => {
			if(typeof navigator !== 'undefined' && navigator && navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position) => {
					if(userLoc === 'lat') {
						return resolve(position.coords.latitude);
					} else if (userLoc === 'lng') {
						return resolve(position.coords.longitude);
					} else {
						return resolve({
							lat: position.coords.latitude,
							lng: position.coords.longitude
						});
					}
				}, (error) => {
					if(userLoc === 'lat' || userLoc === 'lng') {
						return resolve(undefined);
					} else {
						// @TODO: We could consider attempting to guess based on the user's IP
						return resolve({
							lat: undefined,
							lng: undefined
						});
					}
				}, {
					timeout: 5000,
					maximumAge: 1000,
					enableHighAccuracy: true
				});
			} else {
				// @TODO: We could consider attempting to guess based on the user's IP
				if(userLoc === 'lat' || userLoc === 'lng') {
					return resolve(undefined);
				} else {
					// @TODO: We could consider attempting to guess based on the user's IP
					return resolve({
						lat: undefined,
						lng: undefined
					});
				}
			}
		});
	},

	/**
	 * Reloads records in the store based off of record IDs
	 * @param {*} param0 
	 * @returns 
	 */
	reloadRecordsInStore({tsn, recordIds, fields}) {
		if(!fields || !fields.length) {
			// Get ALL fields for this record, then
			fields = FieldStore.getByTableSchemaName(tsn).map(({fieldSchemaName}) => fieldSchemaName);
		} else {
			fields = fields.map(field => field.fieldSchemaName || field);
		}
		let RecordsAPI = require('../apis/records-api').default;
		let recordPromises = recordIds.map(recordId => RecordsAPI.getRecord(tsn, recordId, fields));
		return Promise.all(recordPromises);
	},

	/**
	 * Reloads records in the store based off of a query
	 */
	reloadRecordsInStoreFromQuery({query, table, namedContexts, extraParams, fields, renderId, runTimeVariables}) {
		if(!fields || !fields.length) {
			// get ALL fields for this table
			fields = FieldStore.getByTableSchemaName(table);
			fields.forEach(field => field.fieldId = field.recordId);
		} else {
			// Make sure that the fields are in the correct format
			fields = fields.map(field => Object.assign(FieldStore.get(field.fieldId), field));
		}
		// No, we can't use boundProcessQueryV2
		// It won't pass back from the BE appropriately
		// We need to set it up to use the processQueryV2 and bind it ourselves
		let ProcessorUtils = require('../utils/processor-utils').default;
		return ProcessorUtils.processQueryV2(ContextStore.getInstallationId(), renderId, runTimeVariables, query ? query : "{}", namedContexts, extraParams, fields);
	}
};

/**
* Checks required fields, sends toast messages on failures and returns false.  On success returns true.
* 
* @param {string} recordId 
* @param {string} tableSchemaName 
* @param {Array} fields 
* @returns 
* @memberof GridComponent
*/
function verifyRequiredStatus(recordId, tableSchemaName, fields) {
	return new Promise((resolve, reject) => {

		let RecordStore = require('../stores/record-store').default;
		let FieldSettingsStore = require('../stores/field-settings-store').default;
		let FieldTypeStore = require('../stores/field-type-store').default;
		// Find fields on this page that are required, and make sure their values are dirty.
		let requiredFailures = [];
		let hasValuePromises = [];
		let recordObj = RecordStore.getRecord(tableSchemaName, recordId);

		if (recordObj) {
			Object.keys(fields).forEach(fieldSchemaName => {
				let field = fields[fieldSchemaName];
				let fieldObj = FieldStore.get(field.fieldId),
					fieldSettings = field.attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(field.attachmentId, field.fieldId, field.parentId) : FieldSettingsStore.getSettings(field.fieldId, field.parentId);
					 
				//Field is required for Save
				if (fieldSettings && fieldSettings.requiredForSave && 
					// fieldSettings.requiredForSave === 'yes'){
					fieldSettings.requiredForSave === 'yes' &&
				 	fieldObj.tableSchemaName === tableSchemaName) {
					let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType),
						fieldSchemaName = fieldObj.fieldSchemaName ? fieldObj.fieldSchemaName : '';

					//Get the field's Value
					let value = (recordObj[fieldSchemaName] && (recordObj[fieldSchemaName].value || recordObj[fieldSchemaName].value === 0)) ? recordObj[fieldSchemaName].value : null;
					//Required Message
					let validationPrefix = field.validationPrefix ? (field.validationPrefix + ' ') : '';
					let message = validationPrefix + (fieldSettings.fieldLabel || fieldSchemaName) + ' is required.';
					// Baseline check for missing a value..
					if (value === null || value === undefined || (typeof value === 'string' && value === '')) { // null, so never set.

						requiredFailures.push(message);

					} else if (fieldTypeObj) { // Fieldtype Checks

						// Get the has Value Code: 
						let hasValueCode = fieldTypeObj.hasValueCode ? fieldTypeObj.hasValueCode : null;

						if (hasValueCode) {
							hasValuePromises.push(
								new Promise((resolve, reject) => {
									let hasValue = '';
									// Wrap the hasValueCode in a wrapper Function 
									let wrappedHasCodeValueFunction =
										'hasValue = (function(dataRecordId, dataTableSchemaName, fieldId, fieldSchemaName, value, fieldSettings) { \n' +
										'\t return new Promise(function(resolve, reject) { \n' +
										hasValueCode +
										'})})';

									// If our browser does not support generators (IE11.. grumble.. ) then babel the code.
									if(!window.supportsGenerators) {
										/*global Babel*/
										wrappedHasCodeValueFunction = Babel.transform(wrappedHasCodeValueFunction,{ presets: ['es2015','react'] }).code;
									}

									// Compile the WrappedFrontend Function 
									//eslint-disable-next-line
									eval(wrappedHasCodeValueFunction);

									// Run the function: 
									hasValue(
										recordId,
										tableSchemaName,
										field.fieldId,
										fieldSchemaName,
										value,
										fieldSettings
									).then(results => {
										if (results && typeof results === 'boolean') { //Positive Results 
											//Resolve Successfully
											return resolve();
										} else { // Expect array of errors messages

											if (!Array.isArray(results)) { 
												let isNotArray = `verifyRequiredStatus Error: hasValueCode should return a Boolean (true) or an Array. Got: ${results}`;
												console.error(isNotArray);
												return reject(new Error(isNotArray));
											}

											//Push all the error Messages for this field
											results.forEach(message => {
												let validationPrefix = field.validationPrefix ? (field.validationPrefix + ' ') : '';
												message = validationPrefix + message;
												requiredFailures.push(message);
											});

											//Resolve inner Promises 
											return resolve();
										}
									}).catch(error => {
										let promiseError = `verifyRequiredStatus Error: ${error.message}`;
										console.error(promiseError + error);
										return reject(new Error(promiseError));
									});
								})
							); //end of push has Code Promises
						}
					} // end if we have a value...
				} // end if we're required
			});

			Promise.all(hasValuePromises).then(() => {
				if (requiredFailures.length) {
					requiredFailures.forEach(requiredFailure => {
						InterfaceActions.notification({ 'level': 'error', 'message': requiredFailure });
					});
					return resolve(false);
				} else {
					//Validations Passed 
					return resolve(true);
				} 
			}).catch(error => {
				let promiseError = `verifyRequiredStatus Error: ${error}`;
				console.error(promiseError, error);
				return reject(new Error(promiseError));
			});
		}
	});
}

/**
 * Evaluates Local and Global Validation Action for Fields 
 * @param {string} recordId 
 * @param {string} tableSchemaName 
 * @param {Array} fields 
 * @returns 
 * @memberof GridComponent
 */
function fieldPreSaveValidations(recordId, tableSchemaName, fields, renderId) {
	return new Promise((resolve, reject) => {
		let ActionProcessor = require('../utils/action-processor').default;
		let FieldSettingsStore = window.FieldSettingsStore ? window.FieldSettingsStore : require('../stores/field-settings-store').default;

		// Find if any field with PreSave Actions failed their validation
		let validationPassed = true,
			validationPromises = [];

		Object.keys(fields).forEach(fieldSchemaName => {
			let field = fields[fieldSchemaName];
			let fieldSettings = FieldSettingsStore.getSettings(field.fieldId, field.parentId); // To find Automation Settings 

			//Get the preFieldSave Global:
			let preFieldSaveCode = fieldSettings['automation-preFieldSave'] ? fieldSettings['automation-preFieldSave'] : null;

			//Get the validate Local for preSave: 
			let validateLocalCode = fieldSettings['automation-validate'] ? fieldSettings['automation-validate'] : null;
			
			if (preFieldSaveCode || validateLocalCode) { // Run preSave Global or Local
				validationPromises.push( // Run all the preSave Validations Regardless of the output
					new Promise((resolve, reject) => {
						let preFieldSaveObj = preFieldSaveCode ? ObjectUtils.getObjFromJSON(preFieldSaveCode) : null,
							preFieldSaveActionJS = preFieldSaveObj ? preFieldSaveObj.js : null,
							validateLocalObj = validateLocalCode ? ObjectUtils.getObjFromJSON(validateLocalCode) : null,
							validateLocalActionJS = validateLocalObj ? validateLocalObj.js : null,
							actionRecordId = field.fieldId,
							actionTableSchemaName = 'field',
							preFieldSavePromise = null,
							validateLocalPromise = null,
							actionParentRecordId = field.parentId,
							actionParentTableSchemaName = field.parentComponentType;
						
						//Make the request to the Action Processor for preFieldSave and then for validateLocal
						if (preFieldSaveActionJS) {
							let actionRequest = {
								actionRecordId: actionRecordId,
								actionTableSchemaName: actionTableSchemaName,
								hookId: 'preFieldSave',
								action: preFieldSaveActionJS,
								recordId: recordId, // The Context RecordId
								renderId: renderId, // The Context RenderId
								tableSchemaName: tableSchemaName //The Context TSN
							};
							preFieldSavePromise = ActionProcessor.processAction(actionRequest);
						} else {
							preFieldSavePromise = Promise.resolve();
						}

						preFieldSavePromise.then((preSaveFieldOutput) => {
							if (preSaveFieldOutput && preSaveFieldOutput.validationFailed) {
								// Validation Failed
								validationPassed = false;
							}

							// Now run the local Validation for the current field 
							if (validateLocalActionJS) {
								let actionRequest = { 
									actionRecordId: actionRecordId,
									actionTableSchemaName: actionTableSchemaName,
									hookId: 'validate',
									action: validateLocalActionJS,
									recordId: recordId,
									renderId: renderId,
									tableSchemaName: tableSchemaName,
									actionParentRecordId,
									actionParentTableSchemaName
								};

								validateLocalPromise = ActionProcessor.processAction(actionRequest);

							} else {
								validateLocalPromise = Promise.resolve();
							}
							// Resolve the Promise: 
							validateLocalPromise.then((validateLocalOutput) => {
								if (validateLocalOutput && validateLocalOutput.validationFailed) {
									// Validation Failed
									validationPassed = false;
								}
								return resolve();
							}).catch(error => {
								let promiseError = `local validation Error: ${error}`;
								console.error(promiseError);
								return reject(new Error(promiseError));
							});
						}).catch(error => {
							let promiseError = `preSaveField validation Error: ${error}`;
							console.error(promiseError);
							return reject(new Error(promiseError));
						});
					})
				); 
			}
		}); // End of Fields Loop 

		Promise.all(validationPromises).then(() => {
			if(!validationPassed) {
				return resolve(false);
			} else {
				// Validations Passed 
				return resolve(true);
			}

		}).catch(error => {
			let promiseError = `fieldPreSaveValidations Error: ${error}`;
			console.error(promiseError);
			return reject(new Error(promiseError));
		});
	});
}

/**
 * Checks required fields, sends toast messages on failures and returns false.  On success returns true.
 * 
 * @param {string} recordId 
 * @param {string} tableSchemaName 
 * @param {Array} fields 
 * @returns 
 * @memberof GridComponent
 */
function verifyIsValidCode(recordId, tableSchemaName, fields) {
	return new Promise((resolve, reject) => {
		let RecordStore = require('../stores/record-store').default;
		let FieldSettingsStore = require('../stores/field-settings-store').default;
		let FieldTypeStore = require('../stores/field-type-store').default;
		let CAPTCHAUtils = require('../utils/captcha-utils.js').default;

		// Find fields on this page that are required, and make sure their values are dirty.
		let invalidValuesFailures = [],
			validCodePromises = [],
			recordObj = RecordStore.getRecord(tableSchemaName, recordId);

		if (recordObj) {
			Object.keys(fields).forEach(fieldSchemaName => {
				let field = fields[fieldSchemaName];
				let fieldObj = FieldStore.get(field.fieldId),
				fieldSettings = field.attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(field.attachmentId, field.fieldId, field.parentId) : FieldSettingsStore.getSettings(field.fieldId, field.parentId);
				//Field is required for Save
				if (fieldSettings && 
					fieldObj.tableSchemaName === tableSchemaName) {
					let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType),
						fieldSchemaName = fieldObj.fieldSchemaName ? fieldObj.fieldSchemaName : '';

					//Get the field's Value
					let value = (recordObj[fieldSchemaName] && recordObj[fieldSchemaName].value ? recordObj[fieldSchemaName].value : null);
					if (fieldTypeObj // Fieldtype Checks
						&& value // Only reach isValidCode  if we have value
						&& value !== '{}') { // support fieldtypes that still pass an empty object 
					
						// Get the has Value Code: 
						let isValidCode = fieldTypeObj.isValidCode ? fieldTypeObj.isValidCode : null;

						if (isValidCode) {
							validCodePromises.push(
								new Promise((resolve, reject) => {

									let isValid = '';

									// Wrap the hasValueCode in a wrapper Function 
									let wrappedIsValidCodeFunction =
										'isValid = (function(dataRecordId, dataTableSchemaName, fieldId, fieldSchemaName, value, fieldSettings, citDev) { \n' +
										'\t return new Promise(function(resolve, reject) { \n' +
										isValidCode +
										'})})';

									// If our browser does not support generators (IE11.. grumble.. ) then babel the code.
									if(!window.supportsGenerators) {
										/*global Babel*/
										wrappedIsValidCodeFunction = Babel.transform(wrappedIsValidCodeFunction,{ presets: ['es2015','react'] }).code;
									}

									// Compile the WrappedFrontend Function 
									//eslint-disable-next-line
									eval(wrappedIsValidCodeFunction);
									
									// Run the function: 
									isValid(
										recordId,
										tableSchemaName,
										field.fieldId,
										fieldSchemaName,
										value,
										fieldSettings,
										// Added in order to provide utility method used in validation for CAPTCHA fields.
										{
											captcha: {
												recaptchaV2: CAPTCHAUtils.recaptchaV2
											}
										}
									).then(results => {
										if (results && typeof results === 'boolean') { //Positive Results 
											
											//Resolve Successfully
											return resolve();

										} else { // Expect array of errors messages
											if (!Array.isArray(results)) { 
												let isNotArray = `verifyIsValidCode Error: isValidCode should return a Boolean (true) or an Array. Got: ${results}`;
												console.error(isNotArray);
												return reject(new Error(isNotArray));
											}

											//Push all the error Messages for this field
											results.forEach(message => {

												//Notification Message
												let validationPrefix = field.validationPrefix ? (field.validationPrefix + ' ') : '';
												message = validationPrefix + message;

												invalidValuesFailures.push(message);
											});

											//Resolve inner Promises 
											return resolve();
										}
									}).catch(error => {
										let promiseError = `verifyIsValidCode Error: ${error}`;
										console.error(promiseError);
										return reject(new Error(promiseError));
									});
								})
							); //end of push has Code Promises
						}
					} // end if we have a value...
				} // end if we're required
			});

			Promise.all(validCodePromises).then(() => {
				if (invalidValuesFailures.length) {
					invalidValuesFailures.forEach(requiredFailure => {
						InterfaceActions.notification({ 'level': 'error', 'message': requiredFailure });
					});
					return resolve(false);
				} else {
					//Validations Passed 
					return resolve(true);
				} 
			}).catch(error => {
				let promiseError = `verifyIsValidCode Error: ${error}`;
				console.error(promiseError);
				return reject(new Error(promiseError));
			});
		}
	});
}

/**
 * Finds the record sets on a page that are powered by a query
 *
 * @param {Array} recordSetRenderIds
 * @param {string} renderId
 * @param {string} componentId
 * @param {string} recordSetName
 */
function getRecordSetRenderIdsFromPage(recordSetRenderIds, renderId, componentId, recordSetName) {
	var childRenderObj = RenderStore.get(renderId) || {};
	if(childRenderObj.componentId === componentId && childRenderObj.recordSets && childRenderObj.recordSets.includes(componentId+'-'+recordSetName)) {
		recordSetRenderIds.push(childRenderObj.renderId);
	}
	
	if(childRenderObj.children) {
		childRenderObj.children.forEach(function(grandchildRenderId) {
			var grandchildObj = RenderStore.get(grandchildRenderId);
			if (grandchildObj.componentType !== 'page') {
				getRecordSetRenderIdsFromPage(recordSetRenderIds, grandchildRenderId, componentId, recordSetName);
			}
		});
	}
}

/**
 * Opens a page in a dialog, whether a new one or an existing one.
 * 
 * @param {string} renderId 
 * @param {string} pageId 
 * @param {string} tableSchemaName 
 * @param {string} recordId 
 * @param {string} renderParentId 
 * @param {object} dialogOptions 
 */
function openPageInDialog(renderId, pageId, tableSchemaName, recordId, renderParentId, dialogOptions) {
	let pageObj = PageStore.get(pageId);
	renderParentId = renderParentId || ContextStore.getPageRenderId();

	// create z-indexes for each new dialog and add the z-index to dialogOptions.componentProps
	const openDialogs = document.querySelectorAll('.dialog-wrapper');
	const dialogZIndex = 100 + openDialogs.length;

	// Don't allow Dialog to be wider than the current canvas
	// The canvas has a 15px padding on either side, so -30px to the max width
	const canvas = document.getElementById('content');

	// Include the type of component we want to render:
	// (This include is done here to avoid circular dependency isues)
	dialogOptions.component = require('../components/page-contents.react').container;
	dialogOptions.componentProps = {
		renderId,
		renderParentId,
		pageId,
		size: {
			height: dialogOptions ? dialogOptions.height : 500,
			width: dialogOptions ? Math.min((canvas && canvas.offsetWidth - 30), dialogOptions.width) : 800
		},
		zIndex: dialogZIndex,
		isDialog: true
	};

	if(pageObj) {
		//Should we check for unauthed?
		tableSchemaName = tableSchemaName || pageObj.tableSchemaName; // Use the overridden TSN if one is provided. (25788 - Context issues)
		let automation = PageStore.getAutomation(pageId);

		// Clear any children of the page being replaced, if the page is different
		let renderEntry = RenderStore.get(renderId);
		if(renderEntry && renderEntry.children && renderEntry.componentId !== pageId) {
			RenderActions.deleteRenderBulk(renderEntry.children);
		}
		RenderActions.initiatePage(pageObj, recordId, tableSchemaName, renderId, renderParentId, dialogOptions)
			.then(() => {
				let renderEntry = RenderStore.get(renderId);
				runPageLoadActions(renderId, renderParentId,
					pageId,
					renderEntry ? renderEntry.dataTableSchemaName : tableSchemaName,
					renderEntry ? renderEntry.dataRecordId :recordId,
					automation
				)
			})
			.catch(console.error);
	} else {
		console.error('Page Object Not Found for Dialog');
	}
}

/**
 * Helper function to run the page load actions
 * 
 * @param {string} renderId 
 * @param {string} pageId 
 * @param {Object} tableSchemaName 
 * @param {string} recordId 
 * @param {Object} automation 
 */
function runPageLoadActions(renderId, renderParentId, pageId, tableSchemaName, recordId, automation) {
	if(!automation) {
		return;
	}
	let onPageLoad = automation.onPageLoad;
	if(onPageLoad && typeof onPageLoad === 'string') {
		onPageLoad = ObjectUtils.getObjFromJSON(onPageLoad);
	}
	if (onPageLoad && onPageLoad.js) {
		let namedContexts = {
			'startingContext': [{
				'recordId': recordId,
				'tableSchemaName': tableSchemaName
			}],
			'page': [{
				'recordId': recordId,
				'tableSchemaName': tableSchemaName
			}],
			'application': [{
				'recordId': ContextStore.getApplicationId(),
				'tableSchemaName': 'applications'
			}],
			'installation': [{
				'recordId': ContextStore.getInstallationId(),
				'tableSchemaName': 'installations'
			}],
			'currentUser': [{
				'recordId': AuthenticationStore.getUserId(),
				'tableSchemaName': 'users'
			}],
			'pagePageRecord': [{
				'recordId': recordId,
				'tableSchemaName': tableSchemaName
			}],
			'pageCurrentRecord': [{
				'recordId': recordId,
				'tableSchemaName': tableSchemaName
			}]
		};

		let pageRenderObj = renderParentId ? RenderStore.getPageRenderObj(renderParentId) : undefined;
		if(pageRenderObj  && pageRenderObj.componentType === 'page') {
			namedContexts['pageBelow'] = [{
				'recordId': pageRenderObj.dataRecordId,
				'tableSchemaName': pageRenderObj.dataTableSchemaName
			}];
		}
		let ActionProcessor = require('../utils/action-processor').default;
		ActionProcessor.processAction({
			actionRecordId: pageId,
			actionTableSchemaName: 'page',
			hookId: 'onPageLoad',
			action: onPageLoad.js,
			renderId: renderId,
			recordId: recordId, 
			tableSchemaName: tableSchemaName,
			namedContexts: namedContexts
		}).catch(error => {
			console.warn('Page Load Action Processing Error for onPageLoad for pageId ' + pageId + ': ' + (error.message ? error.message : error));
		});
	}
}

/**
 * Gets all the immediate children of a render which are displayed, adds them
 * to dirtyRecords as appropriate, and then recurses over the children
 * in order to ensure all fields touched by saving a grid are appropriately represented
 * in dirtyRecords.
 * 
 * @param {string} renderId The renderId being evaluated
 * @param {object} dirtyRecords The dirtyRecords object
 * @param {boolean} ignoreRequiredSetting Whether this needs to look at required fields regardless of if they have a value
 * @param {string} parentRenderId The renderId of the parent (needed for finding local field settings)
 * @returns 
 */
function recursivelyGetDirtyRecords(renderId, dirtyRecords, ignoreRequiredSetting, parentRenderId) {
	dirtyRecords = dirtyRecords || {};

	// Get the information frmom the render object
	let renderObj = RenderStore.get(renderId);
	let {componentId, componentType, dataRecordId, dataTableSchemaName, attachmentId, renderParentId} = renderObj;
	let renderParentObj = renderParentId ? RenderStore.get(renderParentId) : undefined;

	let record = dataTableSchemaName && dataRecordId ? RecordStore.getRecord(dataTableSchemaName, dataRecordId) : {};
	let componentObj;
	
	if(componentType === 'field') {
		let availableModes = FieldModesStore.getAvailableModes(renderId);
		if(!availableModes || !availableModes.size) {
			// If the field has no available modes, stop here (ticket 29497)
			return dirtyRecords;
		}
		// We need to consider local "required" settings
		componentObj = FieldStore.get(componentId);
		if(componentObj && renderParentObj) {
			Object.assign(componentObj, attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, componentId, renderParentObj.componentId) : FieldSettingsStore.getSettings(componentId, renderParentObj.componentId));
		}
	} else {
		componentObj = PageStore.get(componentId);
	}

	if(!componentObj) {
		console.warn('Couldn\'t find %s %s when saving. This is probably harmless.', componentType, componentId)
	}

	let fieldVal = componentType === 'field' && record && componentObj && record[componentObj.fieldSchemaName] ? record[componentObj.fieldSchemaName] : {};
	let fields = renderObj && renderObj.children ? renderObj.children.map(childRenderId => RenderStore.get(childRenderId)) : undefined;

	// If the component itself stores information and is dirty, add it to dirtyRecords
	if(componentType === 'field' && componentObj && dataTableSchemaName && dataRecordId) {
		// If the value is dirty or this is a required field and we are not ignoring the required setting
		if(record && ((fieldVal && fieldVal.isDirty) || (!ignoreRequiredSetting && componentObj && componentObj.requiredForSave))) {
			let parentRenderObj = parentRenderId ? RenderStore.get(parentRenderId) : undefined;
			let fieldRenderInformation = {
				fieldSchemaName: componentObj.fieldSchemaName,
				fieldTypeId: componentObj.fieldType,
				fieldId: renderObj.componentId,
				attachmentId: renderObj.attachmentId,
				parentId: parentRenderObj.componentId,
				parentComponentType: parentRenderObj.componentType,
				dataRecordId: renderObj.dataRecordId,
				dataTableSchemaName: renderObj.dataTableSchemaName,
				renderId: renderId
			};
			dirtyRecords[dataTableSchemaName] = dirtyRecords[dataTableSchemaName] || {};
			dirtyRecords[dataTableSchemaName][record.recordId.value] = dirtyRecords[dataTableSchemaName][record.recordId.value] || {};
			dirtyRecords[dataTableSchemaName][record.recordId.value][componentObj.fieldSchemaName] = {
				value: fieldVal.value,
				fieldInfo: fieldRenderInformation
			};
		}
	}

	if(componentType === 'field' && fields && fields.length && fieldVal && fieldVal.value && componentObj && componentObj.fieldType === 'bb5bedc3-44d1-4e4c-9c40-561a675173b1') {
		// If this is a content dropdown, make sure we get only the fields which are children of the selected tab

		let contentTabValue = fieldVal.value;

		// If we found the value for this content tab option somewhere...
		let contentTabObj = contentTabValue ? ObjectUtils.getObjFromJSON(contentTabValue) : null;
		let targetAttachmentKey = contentTabObj
			? (contentTabObj.attachmentId || contentTabObj.fieldId) + '-' + contentTabObj.recordId
			: '';

		let selectedField = contentTabObj && contentTabObj.fieldId ? fields.find(field => field.attachmentKey === targetAttachmentKey && (field.attachmentId === contentTabObj.fieldId || field.componentId === contentTabObj.fieldId)) : undefined;
		if(selectedField && selectedField.renderId) {
			recursivelyGetDirtyRecords(selectedField.renderId, dirtyRecords, ignoreRequiredSetting, renderId);
		}
		// let dirtyFields = Object.keys(record).filter(fsn => !!record[fsn].isDirty).map(fsn => record[fsn].value);
	} else if (fields && fields.length) {
		fields.forEach(childField => {
			recursivelyGetDirtyRecords(childField.renderId, dirtyRecords, ignoreRequiredSetting, renderId);
		});
	}

	// If the component has any attached fields, figure out the component type and iterate
	// over the attached fields appropriately

	return dirtyRecords;

}

export default InterfaceActions;