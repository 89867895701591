import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import {TableConstants} from '../constants/table-constants';
import MetadataBuilder from '@dmclain-citizendeveloper/citdev-module-metadata-builder';
import fetchMetadata from '../utils/fetch-utils';

let _instance = null;

/**
 * Actions for the core store that contains table records for the dev environment where we talk to the MDGW
 *
 * @class TableActionsDev
 */
class TableActionsDev {
	/**
	 * Singleton instance of TableActionsDev
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if(options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id and (optionally the endPoint) and setup the Metadata builder
	 * 
	 * @param {string} installationId 
	 * @param {string} endPoint 
	 */
	setInstallationId(installationId, signedMdKey, endPoint) {
		this._installationId = installationId;
		this._metadataBuilder = new MetadataBuilder(installationId, signedMdKey, endPoint);
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw(new Error('TableActionsDev not properly initialized with a dispatcher'));
		}
		if (!this._metadataBuilder) {
			throw(new Error('TableActionsDev not properly initialized with an installationId'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: TableConstants.TABLE_PULL_ERROR,
			error: error.message
		}));
	}

	receiveBroadcast(records) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: TableConstants.TABLE_RECEIVE_BROADCAST,
			records: records
		}));
	}

	/**
	 * Deletes a table from the database AND the store
	 * 
	 * @param {string} recordId Table Record ID to delete from the database and the store
	 */
	deleteFromDatabase(recordId) {

		// We need the Table Store
		let TableStore = undefined,
			tableObj = undefined;
		try {
			TableStore = window.TableStore;
			tableObj = TableStore.get(recordId);
		} catch (error) {
			console.error('Unable to attach to the FieldStore from the window in FieldActions.deleteFromDatabase.');
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get the field(s)
			.deleteTables([tableObj])
			.then(function(){
				// Pass the fields to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: TableConstants.TABLE_DELETE_FROM_STORE,
					recordId: recordId
				}));
			}.bind(this))
			.catch(function(error){ 
				console.error(error);
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Deletes a table from the database AND the store in a promisified fashion
	 * 
	 * @param {string} recordId Table Record ID to delete from the database and the store
	 */
	deleteFromDatabasePromise(recordId) {

		return new Promise((resolve, reject) => {
			try {
				// We need the Table Store
				let TableStore = undefined,
					tableObj = undefined;
				try {
					TableStore = window.TableStore;
					tableObj = TableStore.get(recordId);
				} catch (error) {
					console.error('Unable to attach to the FieldStore from the window in FieldActions.deleteFromDatabase.');
				}

				// Local Meta Data Builder
				this._metadataBuilder
					// Get the field(s)
					.deleteTables([tableObj])
					.then(function () {
						// Pass the fields to the store (call reduce with the object below)
						this._dispatcher.dispatch(Immutable.fromJS({
							type: TableConstants.TABLE_DELETE_FROM_STORE,
							recordId: recordId
						}));
						return resolve();
					}.bind(this))
					.catch(function (error) {
						console.error(error);
						this._onError(error);
						return reject(error);
					}.bind(this));
			} catch (err) {
				return reject(err);
			}
		});
	}

	/**
	 * Deletes a table from the store
	 * 
	 * @param {string} recordId Table Record ID to delete from the store.
	 */
	deleteFromStore(recordId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: TableConstants.TABLE_DELETE_FROM_STORE,
			recordId: recordId
		}));
	}

	/**
	 * Update Metadata Gateway with this record's data.
	 * @param {string} recordId Record to push from the store to the database
	 */
	pushToDatabase(tableObject) {
		this._checkInit();

		// make *sure* we have a standard javascript object...
		if(tableObject.toJS) {
			tableObject = tableObject.toJS();
		}

		// Remove the crudInterfaces if that setting was used.
		delete tableObject.crudInterfaces;

		// Local Meta Data Builder
		this._metadataBuilder
			// Get all the tables
			.updateTable(tableObject.singularName, 
				tableObject.pluralName, 
				tableObject.tableSchemaName, 
				tableObject.recordId, 
				tableObject.icon,
				tableObject.roles,
				tableObject.securityPermissions,
				tableObject.auditLog,
				tableObject.auditLogTitleFSN,
				tableObject.description)
			.then(function(){
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: TableConstants.TABLE_PUSH_TO_DATABASE,
					tableObject: tableObject
				}));
			}.bind(this))
			.catch(function(error){ 
				console.error(error);
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Update Metadata Gateway with this record's data.
	 * @param {string} recordId Record to push from the store to the database
	 * @returns {Promise}
	 */
	pushToDatabasePromise(tableObject) {
		return new Promise((resolve, reject) => {

			try {
				this._checkInit();
			} catch (error) {
				return reject(error);
			}
	
			// make *sure* we have a standard javascript object...
			if(tableObject.toJS) {
				tableObject = tableObject.toJS();
			}
	
			// Local Meta Data Builder
			this._metadataBuilder
				// Get all the tables
				.updateTable(tableObject.singularName, 
					tableObject.pluralName, 
					tableObject.tableSchemaName, 
					tableObject.recordId, 
					tableObject.icon,
					tableObject.roles,
					tableObject.securityPermissions,
					tableObject.auditLog,
					tableObject.auditLogTitleFSN,
					tableObject.description)
				.then(function(){
					// Pass the tables to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: TableConstants.TABLE_PUSH_TO_DATABASE,
						tableObject: tableObject
					}));
					return resolve(tableObject);
				}.bind(this))
				.catch(function(error){ 
					console.error(error);
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Changes the Schemaname of a table
	 *
	 * @param {*} recordId
	 * @param {*} newTableSchemaName
	 * @returns
	 * @memberof TableActionsDev
	 */
	changeSchemaName(recordId, tableSchemaName, newTableSchemaName) {
		return new Promise((resolve, reject) => {
			this._metadataBuilder
				.updateSchemaName([{'recordId': recordId, 'oldSchemaName': tableSchemaName, 'newSchemaName': newTableSchemaName}], 'table')
				.then(function(){
					// Pass the tables to the store (call reduce with the object below)
					let tableObject = {recordId: recordId, tableSchemaName: newTableSchemaName};
					this._dispatcher.dispatch(Immutable.fromJS({
						type: TableConstants.TABLE_PUSH_TO_DATABASE,
						tableObject: tableObject
					}));
					return resolve(tableObject);
				}.bind(this))
				.catch(function(error){ 
					console.error(error);
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Update store record properties
	 * @param {string} recordId Record to update
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, recordProperties) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: TableConstants.TABLE_PUSH_TO_STORE,
			recordId: recordId,
			recordProperties: recordProperties
		}));
	}

	/**
	 * Update store with a record's data.
	 * @param {string} recordId Record to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get all the tables
			.searchTables({'recordId': recordId})
			.then(function(tableArray){
				// Pass the tables to the store (call reduce with the object below)
				if(Array.isArray(tableArray)) {
					this._dispatcher.dispatch(Immutable.fromJS({
						type: TableConstants.TABLE_PULL_FROM_DATABASE,
						tableArray: tableArray,
						overwriteStore: overwriteStore
					}));
				}
			}.bind(this))
			.catch(function(error){ 
				console.error(error); 
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Update store with all of the records' data.
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}
		// Local Meta Data Builder
		this._metadataBuilder
			// Get all the tables
			.browseTables()
			.then(function(tableArray){
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: TableConstants.TABLE_PULL_FROM_DATABASE_ALL,
					tableArray: tableArray,
					overwriteStore: overwriteStore
				}));
				// Check if this is a user with max table records and get them if so
				// We only have to do this for dev because prod isn't available to free users
				let AuthenticationStore = require('../stores/authentication-store').default;
				if(AuthenticationStore.getMaxRecordsPerTable()) {
					let RecordsApi = require('../apis/records-api').default;
					RecordsApi.getRecordCounts().catch(console.error);
				}
			}.bind(this))
			.catch(function(error){ 
				console.error(error);
				this._onError(error);
			}.bind(this));
	}
}

/**
 * Actions for the core store that contains table records for the test/prod environments where we talk to the CDN JSON
 *
 * @class TableActionsProd
 */
class TableActionsProd {
	/**
	 * Singleton instance of TableActionsProd
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if(options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id
	 * 
	 * @param {string} installationId 
	 */
	setInstallationId(installationId) {
		this._installationId = installationId;
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw(new Error('TableActions not properly initialized with a dispatcher'));
		}
		if (!this._installationId) {
			throw(new Error('TableActions not properly initialized with an installationId'));
		}
		if (!this._installationVersion) {
			throw(new Error('TableActions not properly initialized with an installationVersion'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: TableConstants.TABLE_PULL_ERROR,
			error: error.message
		}));
	}

	/**
	 * Deletes a table from the database AND the store
	 */
	deleteFromDatabase() {
		console.warn('deleteFromDatabase not implemented.');
	}

	/**
	 * Deletes a table from the store
	 * 
	 * @param {string} recordId Table Record ID to delete from the store.
	 */
	deleteFromStore(recordId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: TableConstants.TABLE_DELETE_FROM_STORE,
			recordId: recordId
		}));
	}

	/**
	 * Update Metadata Gateway with this record's data.
	 */
	pushToDatabase() {
		console.warn('pushToDatabase not implemented.');
	}

	/**
	 * Update store record properties
	 * @param {string} recordId Record to update
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, recordProperties) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: TableConstants.TABLE_PUSH_TO_STORE,
			recordId: recordId,
			recordProperties: recordProperties
		}));
	}

	/**
	 * Update store with a record's data.
	 * @param {string} recordId Record to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' + 
				installationId + '/' + installationVersion + '/table/' + recordId + '.json';
		fetchMetadata(metadataPath)
			.then((jsonResponse) => {
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: TableConstants.TABLE_PULL_FROM_DATABASE,
					tableArray: [jsonResponse],
					overwriteStore: overwriteStore
				}));
			})
			.catch(function(error){
				console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
			});
	}

	/**
	 * Update store with all of the records' data.
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' + 
				installationId + '/' + installationVersion + '/table/index.json';
		fetchMetadata(metadataPath)
			.then((jsonResponse) => {
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: TableConstants.TABLE_PULL_FROM_DATABASE_ALL,
					tableArray: jsonResponse,
					overwriteStore: overwriteStore
				}));
			})
			.catch(function(error){
				console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
			});
	}
}

let toExport;
if(process.env.CITDEV_ENV === 'development'){
	try {
		if(window.location.href.includes('settings.html') && typeof window.opener.location.href === 'string') {
			toExport = window.opener.TableActions;
		} else {
			const instance = new TableActionsDev({dispatcher: AppDispatcher});
			toExport = instance;
		}
	} catch(error) { 
		const instance = new TableActionsDev({dispatcher: AppDispatcher});
		toExport = instance;
	}
} else {
	toExport = new TableActionsProd({dispatcher: AppDispatcher});
}

export default toExport;