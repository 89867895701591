import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Actions
import MapActions from '../../../actions/map-actions';
import LogicFunctionActions from '../../../actions/logic-function-actions';
import AdminSettingsActions from '../../../actions/admin-settings-actions';

// Components
import { ReactSVG } from 'react-svg';

// Constants
import Help from '../../../constants/help-upgrade-constants';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import MapStore from '../../../stores/map-store';
import LogicFunctionStore from '../../../stores/logic-function-store';
import ContextStore from '../../../stores/context-store';

// Utils
// import ObjectUtils from '../../../utils/object-utils';
import UIUtils from '../../../utils/ui-utils';
import uuid from 'uuid';

/**
 * Functions by Table Tab Container component on the Logic Dashboard 
 * @class FunctionsByTableLogicDashboardTabContainer
 * @extends {Component}
 */
class FunctionsByTableLogicDashboardTabContainer extends Component {
	/**
	 * Creates an instance of FunctionsByTableLogicDashboardTabContainer.
	 * @param {any} props 
	 * @memberof FunctionsByTableLogicDashboardTabContainer
	 */
	constructor(props) {
		super(props);
		this._renderListFunction = this._renderListFunction.bind(this);
		this._renderLogicFunctionsUsed = this._renderLogicFunctionsUsed.bind(this);
		this._onEditFunction = this._onEditFunction.bind(this);
		this._onChangeSearch = this._onChangeSearch.bind(this);
	}

	/**
	 * Gets the stores to watch
	 * 
	 * @static
	 * @returns {Array of Object}
	 * @memberof FunctionsByTableLogicDashboardTabContainer
	 */
	static getStores() {
		return [LogicFunctionStore, MapStore];
	}

	/**
	 * Returns the current State
	 * 
	 * @static
	 */
	static calculateState(prevState, props) {
		let functions = [];
		let search = MapStore.getSearch(AdminSettingsStore.getActiveDashboardTab());
		let groupBy = (MapStore.getGroupBy() ? MapStore.getGroupBy() : 'name');

		if (LogicFunctionStore.allPulledFromDatabase()) {
			functions = LogicFunctionStore.getAllArray();
		}

		return {
			functions: functions,
			search: search,
			groupedFunctions: this._performGroupBy(functions, groupBy, search)
		};
	}

	/**
	 * Using the group by set in the UI, group the functions and return
	 * an object with the groups as keys and functions array as value.
	 * @param {Array} functions Functions to group
	 * @param {String} groupBy How to group the functions
	 * @param {String} search What search to apply, if any.
	 * @return Object
	 */
	static _performGroupBy(functions, groupBy, search) {
		let groups = {};

		if (!functions) {
			return groups;
		}

		// Filter out the functions that dont have the search term.
		functions = functions.filter(func => {
			if (search && search.length > 0) {
				return (func.name.toLowerCase().includes(search.toLowerCase()));
			} else {
				return true;
			}
			// Sort the functions - by Name.
		}).sort((a, b) => {
			// Ascending Alphabetically
			return b.name < a.name ? 1 : -1;
		});

		let groupVal = 'name';

		if (!groups[groupVal]) {
			groups[groupVal] = [];
		}
		// Put the functions into their groups
		functions.forEach(func => {
			groups[groupVal].push(func);
		});

		return groups;
	}

	/**
	 * Render this component.
	 * 
	 * @returns JSX
	 * @memberof FunctionsByTableLogicDashboardTabContainer
	 */
	render() {
		let { groupedFunctions, search } = this.state;

		// We're not doing the group bys yet... so there is only one group.
		let functions = groupedFunctions['name'];

		let noSearchFound = null;
		if(Object.keys(functions).length === 0 && search.length) {
			noSearchFound = <li className="no-search-found" style={{ color: 'white'}}><h4>No Results for '{search}' found.</h4></li>
		}

		return (
			<div id="logic-map__content" className="map">
				<div className="cd-search-container">
					<input className="form-control select-group-by" placeholder="Search" type="text" value={search} onChange={this._onChangeSearch} />
				</div>
				<div className="section-header" key="expand-collapse-tools">
					<div className="d-flex justify-content-end align-items-center">
						<h5 className="bold">Functions</h5>
						
						<div className="d-flex">
							<div title="Functions Help" className="info-icon ml-2" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_LOGIC_FUNCTIONS); }}>
								<i className="fa fa-info-circle mr-1"></i> | 
							</div>
							<div 
								title="Create Function"
								className="add-schedule-icon"
								onClick={this._onAddFunction.bind(this, '')}
							>
								<i className="fa fa-plus-circle ml-1"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="list-content-wrapper">
					<ol id="logicFunctionEditList"
						className="collapse show">
						{/* Render API Config List */}
						{functions.map(this._renderListFunction.bind(this))}
						{noSearchFound}
					</ol>
				</div>
			</div>
		);
	}

	/**
	 * Renders a single Function.
	 * 
	 * @param {String} logic 
	 * @param {number} index 
	 * @returns 
	 * @memberof FunctionsByTableLogicDashboardTabContainer
	 */
	_renderListFunction(logic, index) {
		let recordId = (logic && logic.recordId) ? logic.recordId : '';
		let logicName = (logic && logic.name) ? logic.name : '';
		let description = logic && logic.description ? logic.description : '';
		let logicFunctionsUsed = logic && logic.logicFunctionsUsed ? logic.logicFunctionsUsed.split(',') : null;

		if (!logicName) {
			return null;
		}

		return (
			<li key={logicName + '-' + index} className="table-name-item">
				<div className="list-item-row">
					<div
						className="mb-0 py-2"
						data-toggle="modal"
						data-target="#page-dialog"
						title={description}
					>
						<h4 className="bold">{logicName}</h4>
					</div>
					<div title="Update Function" className="action-icon">
						<span 
							onClick={this._onEditFunction.bind(this, recordId)} 
						>
							<ReactSVG 
								beforeInjection={svg => {
									svg.setAttribute('viewBox', '0 0 34 34');
									svg.setAttribute('style', 'width: 16px; height: 16px');
								}}
								src={ContextStore.getUrlMedia() + "/icon-logic.svg"} />
							<span className='sr-only'>Edit</span>
						</span>
					</div>
				</div>
				{logicFunctionsUsed && logicFunctionsUsed.length 
					? <ul>
						{logicFunctionsUsed.map(this._renderLogicFunctionsUsed.bind(this, 'function'))}
					  </ul> 
					: null
				}
			</li>
		);
	}

	/**
	 * Render the list of functions used inside this function.
	 * @param {string} prefix 
	 * @param {string} functionId 
	 * @param {string} index 
	 * @returns JSX
	 */
	_renderLogicFunctionsUsed(prefix, functionId, index) {
		let functionObj = LogicFunctionStore.get(functionId) || {};
		let name = functionObj.name ? functionObj.name : functionId;
		let description = functionObj.description ? functionObj.description : '';
		return (<li key={prefix + functionId} className='sub-item' title={description}>{name}</li>);
	}

	/**
	 * Add new modal for scheduledLogic 
	 * 
	 * @memberof FunctionsByTableLogicDashboardTabContainer
	 */
	_onAddFunction() {
		// Generate new uuid and empty body for the record
		let recordId = uuid.v4(),
			newRecordObj = {
				recordId: recordId,
				id: recordId.replaceAll('-',''),
				name: 'New Function',
				params: '[]',
				logic: '{}',
				new: true
			};

		// //Push the new Record to the store 	
		LogicFunctionActions.pushToStore(recordId, newRecordObj);

		this._onEditFunction(recordId);
	}

	/**
	 * Handles typing into the search box.
	 * @param {object} event Change event
	 */
	_onChangeSearch(event) {
		let search = event.target.value;
		MapActions.search(search, AdminSettingsStore.getActiveDashboardTab());
	}

	/**
	 * Edit existing Logic Map Modal
	 * 
	 * @param {String} recordId 
	 * @memberof FunctionsByTableLogicDashboardTabContainer
	 */
	_onEditFunction(recordId) {
		AdminSettingsActions.onRightPanelChange(true);
		//Toggle to select and deselect the Setting
		AdminSettingsActions.onSettingChange('name', '23e30e42-f3a9-48ec-b49f-ca0521c08afa');

		// Ensure the Settings are NOT hidden
		AdminSettingsActions.onSettingsListHideChange(false);
		
		// recordId, renderId, attachmentId, 
		// tableSchemaName, parentRecordId, parentTableSchemaName, gridKey, fieldType, selectedOverlay) {
		AdminSettingsActions.onShowComponentDetails(recordId, undefined, undefined,
			'logicfunctions', undefined, undefined, undefined, undefined, 'function');
	}
}

const container = Container.create(FunctionsByTableLogicDashboardTabContainer, { withProps: true });
export default container;