var keyMirror = require('keymirror');

// Define action constants
var ContextConstants = keyMirror({
	'ALL_RECORD_DATA_RECEIVED_CHANGE': null,
	'ALL_RECORD_DATA_RECEIVED_CHANGE_WITH_PATH_CHANGE': null,
	'APPLICATION_INIT': null,
	'CONTEXT_CHANGE': null,
	'RESPONSIVE_MODE_CHANGE': null,
	'ORIENTATION_CHANGE': null,
	'SET_PAGE_RENDER_ID': null,
	'ADD_RUNNING_ACTION': null,
	'REMOVE_RUNNING_ACTION': null,
	'UPDATE_DISPLAY_NOTIFICATION': null
});
module.exports = ContextConstants;
