/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';

// APIs
import RecordsAPI from '../../../apis/records-api';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import ContextStore from '../../../stores/context-store';
import FieldStore from '../../../stores/field-store';
import PageStore from '../../../stores/page-store';
import RecordStore from '../../../stores/record-store';
import FieldSettingsStore from '../../../stores/field-settings-store';


// Utils
import ObjectUtils from '../../../utils/object-utils';
import UIUtils from '../../../utils/ui-utils';

// Components
import PopoverHeader from './header';

import { ReactSVG } from 'react-svg';

/**
 * Popover content for the Security Pin
 *
 * @class VisibilityPinPopover
 * @extends {Component}
 */
class VisibilityPinPopover extends Component {
	constructor(props) {
		super(props);
		this.link = null;
		this.onSettingClick = this.onSettingClick.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf VisibilityPinPopover
	 */
	static getStores() {
		return [AdminSettingsStore, RecordStore, RecordStore];
	}

	/**
	 * Handler for clicking on a setting in the popover content
	 * 
	 * @param {any} securityGroupRecordId 
	 * @param {any} event 
	 * @memberof VisibilityPinPopover
	 */
	onSettingClick(securityGroupRecordId, event) {
		event.preventDefault();
		let { attachmentId } = this.props;

		if(!securityGroupRecordId) {
			return;
		}

		// Open Settings tab under right-panel
		AdminSettingsActions.onSettingsTabChangeMain('settings');
		// looks for Add or Edit mode for the visibility settings options
		AdminSettingsActions.onVisibilityControlsAddAndEdit(this.props.controlsAddAndEdit);

		UIUtils.openSettingsPanel('visibility',
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 'settings',
			this.props.subSettingSchemaName, this.props.subSettingIndex, this.props.renderId, this.props.attachmentId
		);

		// If we're rending Logic, and we have an attachmentId..
		if(securityGroupRecordId === 'logic') {
			if(attachmentId) {
				// Ensure the Settings are hidden
				AdminSettingsActions.onSettingsListHideChange(true);
			} else {
				AdminSettingsActions.onSettingsListHideChange(false);
			}
		}
		AdminSettingsActions.onSettingChange(undefined, securityGroupRecordId);
	}

	/**
	 * Returns the current State
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf VisibilityPinPopover
	 */
	static calculateState(prevState, props) {
		let {recordId: componentId, parentRecordId: parentComponentId} = props;

		let { subSettingIndex, subSettingSchemaName } = props;
		let componentObj = FieldStore.get(componentId);
		let componentType = AdminSettingsStore.getTableSchemaName();
		
		if(componentType === 'page') {
			return {
				isPage: true
			}
		}
		
		let settingConfig = props.attachmentId 
			? FieldSettingsStore.getSettingsFromAttachmentId(props.attachmentId, componentId, parentComponentId) 
			: FieldSettingsStore.getSettings(componentId, parentComponentId);
		let visibilityPermissions = {};
		if (subSettingSchemaName && settingConfig[subSettingSchemaName]) {
			let subSetting = ObjectUtils.getObjFromJSON(settingConfig[subSettingSchemaName])[subSettingIndex];
			visibilityPermissions = subSetting && subSetting.visibility ? ObjectUtils.getObjFromJSON(subSetting.visibility) : {};
		} else {
			visibilityPermissions = settingConfig.visibility ? ObjectUtils.getObjFromJSON(settingConfig.visibility) : {};
		}

		let selectedSecurityGroupRecordId = AdminSettingsStore.getSettingRecordId();

		// Generate User State Array
		let userStateArr = [];

		if(!visibilityPermissions['authenticated']) {
			visibilityPermissions['authenticated'] = {
				view: '1',
				edit: '1',
				add: '1'
			};
		}

		if(!visibilityPermissions['nonauthenticated']) {
			visibilityPermissions['nonauthenticated'] = {
				view: '1',
				edit: '1',
				add: '1'
			};
		}

		// Authenticated Users
		userStateArr.push({
			securityGroupRecordId: 'authenticated',
			label: 'All Authenticated Users',
			isSelected: (selectedSecurityGroupRecordId === 'authenticated'),
			type: 'builtIn',
			permissions: visibilityPermissions['authenticated']
		});

		// Unauthenticated Users
		userStateArr.push({
			securityGroupRecordId: 'nonauthenticated',
			label: 'All Non-Authenticated Users',
			isSelected: (selectedSecurityGroupRecordId === 'nonauthenticated'),
			type: 'builtIn',
			permissions: visibilityPermissions['nonauthenticated']
		});

		// Lookup and populate componentSettings with additional Security Groups.
		let securityGroupArr = []; 
		let securityGroups = RecordStore.getRecords('securityGroup');
		if(securityGroups !== null) {
			Object.keys(securityGroups).forEach(securityGroupRecordId => {
				let securityGroup = securityGroups[securityGroupRecordId];
				if(!securityGroup.deleted || (securityGroup.deleted && securityGroup.deleted.value !== true)) {
					securityGroupArr.push({
						securityGroupRecordId: securityGroupRecordId,
						label: (securityGroup.name ? securityGroup.name.value : '[ No Name ]'),
						isSelected: (selectedSecurityGroupRecordId === securityGroupRecordId),
						type: 'defined',
						permissions: (visibilityPermissions[securityGroupRecordId]
							? visibilityPermissions[securityGroupRecordId] : {})
					});
				}
			});
		} else {
			RecordsAPI.getAllRecordsByTable(ContextStore.getState(), 'securityGroup');
			securityGroupArr.push({
				label: 'Loading...',
				isSelected: false,
				type: 'defined'
			});
		}

		// Sort the results by setting name
		securityGroupArr.sort(function(a,b){
			if(a.label.toLowerCase() < b.label.toLowerCase()) { 
				return -1;
			}
			return 1;
		});

		//Content Drop-Down, Content Tabs and Navigation Tabs
		let showCreateUpdate = [
			'bb5bedc3-44d1-4e4c-9c40-561a675173b1',
			'846b747e-25a0-40df-8115-af4a00a1cab5',
			'cd0ee38e-d63f-44d2-b02b-44376fcc7c2e'
		].indexOf(componentObj.fieldType) === -1 && !subSettingSchemaName;

		return {
			securityGroupArr: securityGroupArr,
			userStateArr: userStateArr,
			logicInfo: {
				isSelected: false
			},
			showCreateUpdate
		}
	}

	/**
	 * Renders a single security group as a disabled LI option.
	 * 
	 * @param {Object} securityGroup 
	 * @param {string} securityGroup.securityGroupRecordId Record ID of the Security Group.
	 * @param {string} securityGroup.label Label for the option.
	 * @param {boolean} securityGroup.isSelected Is this option the selected UI option?
	 * @param {string} securityGroup.type builtIn | logic | defined
	 * @param {Object} securityGroup.permissions Optional - if empty there will be no C.R.U.D. displayed
	 * @param {string} securityGroup.permissions.create 1 | 0 - If Create is enabled.
	 * @param {string} securityGroup.permissions.read 1 | 0 - If Read is enabled.
	 * @param {string} securityGroup.permissions.update 1 | 0 - If Update is enabled.
	 * @param {string} securityGroup.permissions.delete 1 | 0 - If Delete is enabled.
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderDisabledOption(securityGroup, index) {
		let labelClassNames = 'setting-label disabled';
		let iconClassNames = 'setting-icon disabled fa ';
		
		switch(securityGroup.type) {
			case 'builtIn':
				iconClassNames += 'fa-user';
			break;
			case 'logic':
				iconClassNames += 'fa-puzzle-piece';
			break;
			default:
			case 'defined':
				iconClassNames += 'fa-users';
			break;
		}

		return (<li key={index} className="nav-item">
			<div className={iconClassNames} />
			<div className="d-flex setting-text-container">
				<div className="w-75 setting-label">
					<div className={labelClassNames}>{securityGroup.label}</div>
				</div>
				<div className="w-25 d-flex justify-content-between setting-value-container">
					<div className="text-right setting-pattern-list setting-value align-self-center">
					</div>
				</div>
			</div>
		</li>);
	}
	/**
	 * Renders a single security group as an LI option.
	 * 
	 * @param {Object} securityGroup 
	 * @param {string} securityGroup.securityGroupRecordId Record ID of the Security Group.
	 * @param {string} securityGroup.label Label for the option.
	 * @param {boolean} securityGroup.isSelected Is this option the selected UI option?
	 * @param {string} securityGroup.type builtIn | logic | defined
	 * @param {Object} securityGroup.permissions Optional - if empty there will be no C.R.U.D. displayed
	 * @param {string} securityGroup.permissions.create 1 | 0 - If Create is enabled.
	 * @param {string} securityGroup.permissions.read 1 | 0 - If Read is enabled.
	 * @param {string} securityGroup.permissions.update 1 | 0 - If Update is enabled.
	 * @param {string} securityGroup.permissions.delete 1 | 0 - If Delete is enabled.
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderOption(securityGroup, index) {
		let labelClassNames = 'setting-label';
		/*************************
			The following commented out statements are for the setting-icon, which is not currently in use
			Uncomment, when setting-icon is used again
		**************************/

		// let iconClassNames = 'setting-icon fa ';
		
		// switch(securityGroup.type) {
		// 	case 'builtIn':
		// 		iconClassNames += 'fa-user';
		// 	break;
		// 	case 'logic':
		// 		iconClassNames += 'fa-puzzle-piece';
		// 	break;
		// 	default:
		// 	case 'defined':
		// 		iconClassNames += 'fa-users';
		// 	break;
		// }

		let valueDisplay = null;
		if(securityGroup.type === 'logic') {
			let {
				parentRecordId: parentComponentId,
				parentTableSchemaName: parentComponentType,
				recordId: componentId,
				tableSchemaName: componentType
			} = this.props;
			let parentComponentObj = parentComponentType === 'page' ? PageStore.get(parentComponentId) : FieldStore.get(parentComponentId);
			let visibilityKey = this.props.subSettingSchemaName ? [this.props.attachmentId, this.props.subSettingSchemaName, this.props.subSettingIndex, 'visibility'].join('-') : (this.props.attachmentId + '-visibility');
			if(this.props.subSettingSchemaName) {
				// As far as I am aware, for logic visibility keys, the presence of subSettingSchemaName always means that it's
				// stored on the current component and not the parent component.
				parentComponentObj = componentType === 'page' ? PageStore.get(componentId) : FieldStore.get(componentId);
			}
			let value = parentComponentObj ? parentComponentObj[visibilityKey] : null;
			if(value) {
				valueDisplay = <span className='fa fa-check'><span className='sr-only'>(Has Logic)</span></span>
			}
		} else if (securityGroup.permissions) {
			valueDisplay = <div>
				<span style={{ color: 'var(--theme-text-4)' }} className={(securityGroup.permissions.view === '1' ? 'selectedCRUDOption' : '')}>V.&nbsp;</span>
				{this.state.showCreateUpdate ? <span style={{ color: 'var(--theme-text-4)' }} className={(securityGroup.permissions.edit   === '1' ? 'selectedCRUDOption' : '')}>A/E.&nbsp;</span> : null}
			</div>;
		}

		return (<li key={index} className="nav-item">
			<div className="nav-link" onClick={this.onSettingClick.bind(this, securityGroup.securityGroupRecordId)}>
				{/* Temporarily removed the following div */}
				{/* <div className={iconClassNames} /> */}
				{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
				{/* Add this back in when the setting-icon is used again */}
				<div className="d-flex setting-text-container">
					<div className="w-75 settings-list-value">
						<div className={labelClassNames}>{securityGroup.label}</div>
					</div>
					<div className="w-25 d-flex justify-content-between setting-value-container">
						<div className="text-right setting-pattern-list setting-value align-self-center">
							{valueDisplay}
						</div>
					</div>
				</div>
			</div>
		</li>);
	}

	render() {
		// let {securityGroupArr, userStateArr, logicInfo} = this.state;
		let {securityGroupArr, userStateArr, logicInfo, isPage} = this.state;
		let settingsList = [];
		if(isPage) {
			return (
				<div style={{ height: '100%', overflow: 'hidden' }} id="popover-content" className="security-popover cd-tools">
					<PopoverHeader 
						recordId={this.props.recordId} 
						tableSchemaName={this.props.tableSchemaName} 
						iconBase="eye-slash" 
						overlayName="Visibility"/>
					<div style={{ height: '100%', overflow: 'hidden' }} className="tab-content row mb-2">
						<div style={{ height: '100%', overflowY: 'auto' }} className="ml-3 pr-1 mr-2">
							<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
						</div>
					</div>
				</div>
			);
		}

		// Process the Security Group Array
		settingsList.push(<li key={settingsList.length} className="settings-list-header nav-header d-flex noMarginAdjustment">
			<ReactSVG 
				beforeInjection={svg => {
					svg.setAttribute('style', 'width: 22px; height: 22px');
				}}
				src={ContextStore.getUrlMedia() + "/icon-visibility.svg"} />
			<h3 style={{ lineHeight: 1.1 }}>Security Group permissions provided to Users...</h3>
			</li>);
		securityGroupArr.forEach(securityGroup => {
			settingsList.push(this._renderOption(securityGroup, settingsList.length));
		});

		// Hard-Coded Security "Groups"
		settingsList.push(<li key={settingsList.length} className="settings-list-header nav-header d-flex noMarginAdjustment">
			<ReactSVG 
				beforeInjection={svg => {
					svg.setAttribute('style', 'width: 22px; height: 22px');
				}}
				src={ContextStore.getUrlMedia() + "/icon-visibility.svg"} />
			<h3>User also receives permissions from...</h3>
			</li>);
		userStateArr.forEach(securityGroup => {
			settingsList.push(this._renderOption(securityGroup, settingsList.length));
		});

		// Add the Custom Visibilty Logic Setting
		settingsList.push(this._renderOption({
			securityGroupRecordId: 'logic',
			label: 'Custom Visibility Logic',
			isSelected: logicInfo.isSelected,
			type: 'logic'
		}, settingsList.length));

		return (
			<div style={{ height: '100%', overflow: 'hidden' }} id="popover-content" className="security-popover cd-tools">
				<PopoverHeader 
					recordId={this.props.recordId} 
					tableSchemaName={this.props.tableSchemaName} 
					iconBase="eye-slash" 
					overlayName="Visibility"/>
				<div style={{ height: '100%', overflow: 'hidden' }} className="tab-content row mb-2">
					<div style={{ height: '100%', overflowY: 'auto' }} className="ml-3 pr-1 mr-2">
						<ul id="popover-settings" className="nav flex-column">
							{settingsList}
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	VisibilityPinPopover.propTypes = {
    recordId: PropTypes.string.isRequired,
	tableSchemaName: PropTypes.string.isRequired,
    parentRecordId: PropTypes.string,
	parentTableSchemaName: PropTypes.string,
	subSettingIndex: PropTypes.number,
	subSettingSchemaName: PropTypes.string,
    // fieldType: PropTypes.string,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(VisibilityPinPopover, {withProps: true});
export default container;