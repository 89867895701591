import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { PageActions } from '../../../actions';
import { AdminSettingsStore, FieldStore, PageStore } from '../../../stores';

// Components;
import FieldTypeSettingsUI from '../../fieldtype-settings-ui.react'

/**
 * Right panel displaying Page Add Settings
 */
class PageAddSetting extends Component {
	constructor(props) {
		super(props)
		this._onSettingChange = this._onSettingChange.bind(this);
	}
	
	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
	 return [ AdminSettingsStore, FieldStore, PageStore ]; 
	}

	/**
	 * Calculate the current state of the component
	 * @static
	 *
	 * @param  {Object} prevState Previous State
	 * @param  {Object} props     Previous Props
	 * @return {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId =  AdminSettingsStore.getRecordId();
		let settingRecordId = AdminSettingsStore.getSettingRecordId();
		let settingObj = FieldStore.get(settingRecordId);
		let value = '';
		let isNew = false;

		// Pull the value from the MD Store
		if(settingObj){
			let settingSchemaName = settingObj.fieldSchemaName;

			let pageObj = PageStore.get(recordId);
			if(pageObj) {
				value = pageObj[settingSchemaName];
				isNew = pageObj['new'];
			}
		}

		return {
			settingObj: settingObj,
			settingRecordId: settingRecordId,
			recordId: recordId,
			value: value,
			isNew: isNew
		};
	}

	/**
	 * Save the value as a value is entered
	 * @param {Object} event
	 */
	_onSettingChange(event) {
		let { recordId, settingObj } = this.state;
		let settingSchemaName = settingObj.fieldSchemaName;
		let value = event.target.value;

		let pageObj = PageStore.get(recordId);
		pageObj[settingSchemaName] = value
		// Push the updated setting to the Field Store
		PageActions.pushToStore(recordId, pageObj);
	}

	/**
	 * Render the component
	 * @return JSX
	 */
	render() {
		let {recordId, value, settingObj, isNew } = this.state;

		if (!settingObj || !settingObj.fieldSchemaName) {
			return (<div className="select-setting">
						<div className="select-setting-text-wrap">
							Select a Setting to configure on the left.
						</div>
					</div>);
		}

		let settingSchemaName = settingObj.fieldSchemaName;
		let settingFieldType = settingObj.fieldType;
		let descriptiveText = settingObj.descriptiveText;
		let label = settingObj.fieldLabel;

		// If we're new, and on the table field, make it editable.
		if(isNew && settingObj.recordId === 'aeb2dc2c-3153-4a27-8820-cd3e1379d29c') {
			settingObj.editVariant = 'tablesEdit';
			settingObj.descriptiveText = 'What Table is this page associated with?';
			settingObj.required = true;
			settingObj.placeholder = 'Select a Table. This is required for a new Page.';
		}

		return (<div id="appearance-settings">
					<div className="appearance-settings-container mx-3">
						<h3 className='my-1'>
							{label}
						</h3>
						<div className="setting-global mt-0">
							<div className={"setting-wrapper setting-" + settingSchemaName}>
								<FieldTypeSettingsUI
									recordId={recordId} 
									tableSchemaName='page'
									value={value} 
									setting={settingObj} 
									onChange={this._onSettingChange} 
									fieldType={settingFieldType} 
									label={label}
									skipDescriptiveText={true}
									/>
								{descriptiveText && 
									<small id={'description-' + this.props.renderId} className='form-text text-muted' dangerouslySetInnerHTML={{ __html: descriptiveText || '' }}></small>
								}
							</div>
						</div>
					</div>
				</div>);
	}
}
const container = Container.create(PageAddSetting, {withProps: true});
export default container;