/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';
import InterfaceActions from '../../actions/interface-actions';
import RecordActions from '../../actions/record-actions';
import FieldActions from '../../actions/field-actions';
import PageActions from '../../actions/page-actions';

// APIs
import RecordsAPI from '../../apis/records-api';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import ContextStore from '../../stores/context-store';
import RecordStore from '../../stores/record-store';
import FieldStore from '../../stores/field-store';
import FieldSettingsStore from '../../stores/field-settings-store';
import PageStore from '../../stores/page-store';

// Utils
import ObjectUtils from '../../utils/object-utils';
import SecurityGroupUtils from '../../utils/security-group-utils';
// import socketFetcher from '../../utils/socket-fetcher';
import uuid from 'uuid';

import { ReactSVG } from 'react-svg';

class VisibilitySettingsChooser extends Component {
	/**
	 * Creates instance of VisibilitySettingsChooser
	 *
	 * @memberOf VisibilitySettingsChooser
	 */
	constructor(props) {
		super(props);
		this._renderDisabledOption = this._renderDisabledOption.bind(this);
		this._renderOption = this._renderOption.bind(this);
		this.onAddClick = this.onAddClick.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.onResetClick = this.onResetClick.bind(this);
		this.onSaveClick = this.onSaveClick.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, RecordStore, FieldStore, FieldSettingsStore];
	}
	
	/**
	 * onAddClick - Sets up a new recordId for the security setting UI to use.
	 *
	 * @param {object} event
	 */
	onAddClick(event) {
		let newRecordId = uuid.v4();
		AdminSettingsActions.onSettingChange('', newRecordId);

		let newRecordObj = {};
		newRecordObj[newRecordId] = {};
		// Load the new record into the record store.
		RecordActions.onDataLoaded({
			securityGroup: newRecordObj
		});
	}

	/**
	 * onSaveClick - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	onSaveClick(event) {
		InterfaceActions.notification({level: 'warning', message: 'Reminder: In order for changes in Security Group membership to affect your application, please log out and log in.'});

		// Display notification to user
		let generalSaveNotification = 
			InterfaceActions.stickyNotification({level: 'info', message: 'Updating Visibility settings. This may take a few seconds, please wait...'});
		
		SecurityGroupUtils.cleanSecurityGroups()
			.then(() => {
				let { componentId, parentComponentId, parentComponentType } = this.state;
				let saveActions = [];
				// Save the current table being edited to the DB (in case it wasn't covered by the table deletion promises)
				let fieldObj = FieldStore.get(componentId, true);
				saveActions.push(FieldActions.pushToDatabasePromise(fieldObj));
				if (parentComponentType === 'field') {
					let parentFieldObject = FieldStore.get(parentComponentId, true);
					saveActions.push(FieldActions.pushToDatabasePromise(parentFieldObject));
				} else if (parentComponentType === 'page') {
					let parentPageObject = PageStore.get(parentComponentId, true);
					saveActions.push(PageActions.pushToDatabasePromise(parentPageObject));
				}
				return Promise.all(saveActions);
			})
			.then(() => {
				InterfaceActions.clearStickyNotification(generalSaveNotification);
			})
			.catch((error) => {
				InterfaceActions.clearStickyNotification(generalSaveNotification);
				InterfaceActions.notification({level: 'error', message: 'Visibility (Field) save error.  See your console for details.'});
				console.error(error);
			});

	}

	/**
	 * Marks the passed in security group ID as deleted.
	 *
	 * @param  {object} event
	 */
	onDeleteClick(securityGroupRecordId, event) {
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Security Group Deleted.' });

		// Clear the selected Security Group
		AdminSettingsActions.onSettingChange('');
		
		// Mark it as deleted.
		let newRecordValues = {};
		newRecordValues['deleted'] = true;

		// Load the new record into the record store.
		RecordActions.updateRecord('securityGroup', securityGroupRecordId, newRecordValues);
	}

	/**
	 * onResetClick - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	onResetClick(event) {
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Re-setting Security Group(s) and Permissions...' });

		// Reset the Groups
		let securityGroupRecords = RecordStore.getRecords('securityGroup');
		Object.keys(securityGroupRecords).forEach(securityGroupRecordId => {
			let secGrpRecObj = securityGroupRecords[securityGroupRecordId];
			if(secGrpRecObj.deleted){
				RecordActions.updateRecord('securityGroup', securityGroupRecordId, { deleted : null});
			}
		})
		RecordsAPI.getAllRecordsByTable(ContextStore.getState(), 'securityGroup');

		// Reset the record
		FieldActions.pullFromDatabase(this.state.componentId, true);
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 * @memberof QuerySettingsChooser
	 */
	onSettingClick(securityGroupRecordId, event) {
		event.preventDefault();
		let { attachmentId } = this.props;

		if(!securityGroupRecordId) {
			return;
		}

		if(securityGroupRecordId === 'logic') {
			// If we have an attachment ID, that means we've already "been converted"
			if(attachmentId) {
				if(AdminSettingsStore.getSettingRecordId() === securityGroupRecordId) {
					AdminSettingsActions.onSettingChange('');
					// Toggle the Settings Hidden
					AdminSettingsActions.onSettingsListHideChange(!AdminSettingsStore.getSettingsListHidden());
				} else {
					AdminSettingsActions.onSettingChange(undefined, securityGroupRecordId);
					// Ensure the Settings are hidden
					AdminSettingsActions.onSettingsListHideChange(true);
				}
			} else {
				if(AdminSettingsStore.getSettingRecordId() === securityGroupRecordId) {
					AdminSettingsActions.onSettingChange('');
					// Always show the settings
					AdminSettingsActions.onSettingsListHideChange(false);
				} else {
					AdminSettingsActions.onSettingChange(undefined, securityGroupRecordId);
					// Ensure the Settings are not hidden becuase hidden settings are scary!
					AdminSettingsActions.onSettingsListHideChange(false);
				}
			}
		} else {
			// Toggle to select and deselect the Setting
			if(AdminSettingsStore.getSettingRecordId() === securityGroupRecordId) {
				AdminSettingsActions.onSettingChange('');
			} else {
				AdminSettingsActions.onSettingChange(undefined, securityGroupRecordId);
			}
		}
	}

	/**
	 * 
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} - Query Setting list
	 * @memberof QuerySettingsChooser
	 */
	static calculateState(prevState, props) {
		let componentId = AdminSettingsStore.getRecordId();
		let componentType = AdminSettingsStore.getTableSchemaName();
		let parentComponentId = AdminSettingsStore.getParentRecordId();
		let parentComponentType = AdminSettingsStore.getParentTableSchemaName();
		if(componentType === 'page') {
			return {
				isPage: true
			}
		}

		let { subSettingIndex, subSettingSchemaName } = props;
		
		let fieldType = '';
		if(componentType === 'field') {
			let componentObj = FieldStore.get(componentId);
			fieldType = componentObj.fieldType;
		}	
		let settingConfig = props.attachmentId 
			? FieldSettingsStore.getSettingsFromAttachmentId(props.attachmentId, componentId, parentComponentId) 
			: FieldSettingsStore.getSettings(componentId, parentComponentId);
		let visibilityPermissions = {};		
		if (subSettingSchemaName && settingConfig[subSettingSchemaName]) {
			let subSetting = ObjectUtils.getObjFromJSON(settingConfig[subSettingSchemaName])[subSettingIndex];
			visibilityPermissions = subSetting && subSetting && subSetting.visibility ? ObjectUtils.getObjFromJSON(subSetting.visibility) : {};
		} else {
			visibilityPermissions = settingConfig.visibility ? ObjectUtils.getObjFromJSON(settingConfig.visibility) : {};
		}

		let selectedSecurityGroupRecordId = AdminSettingsStore.getSettingRecordId();

		// Generate User State Array
		let userStateArr = [];

		if(!visibilityPermissions['authenticated']) {
			visibilityPermissions['authenticated'] = {
				view: '1',
				edit: '1',
				add: '1'
			};
		}

		if(!visibilityPermissions['nonauthenticated']) {
			visibilityPermissions['nonauthenticated'] = {
				view: '1',
				edit: '1',
				add: '1'
			};
		}

		// Authenticated Users
		userStateArr.push({
			securityGroupRecordId: 'authenticated',
			label: 'All Authenticated Users',
			isSelected: (selectedSecurityGroupRecordId === 'authenticated'),
			type: 'builtIn',
			permissions: visibilityPermissions['authenticated']
		});

		// Unauthenticated Users
		userStateArr.push({
			securityGroupRecordId: 'nonauthenticated',
			label: 'All Non-Authenticated Users',
			isSelected: (selectedSecurityGroupRecordId === 'nonauthenticated'),
			type: 'builtIn',
			permissions: visibilityPermissions['nonauthenticated']
		});

		// Lookup and populate componentSettings with additional Security Groups.
		let securityGroupArr = []; 
		let securityGroups = RecordStore.getRecords('securityGroup');
		if(securityGroups !== null) {
			Object.keys(securityGroups).forEach(securityGroupRecordId => {
				let securityGroup = securityGroups[securityGroupRecordId];
				if(!securityGroup.deleted || (securityGroup.deleted && securityGroup.deleted.value !== true)) {
					securityGroupArr.push({
						securityGroupRecordId: securityGroupRecordId,
						label: (securityGroup.name && securityGroup.name.value ? securityGroup.name.value : '[ No Name ]'),
						isSelected: (selectedSecurityGroupRecordId === securityGroupRecordId),
						type: 'defined',
						permissions: (visibilityPermissions[securityGroupRecordId]
							? visibilityPermissions[securityGroupRecordId] : {})
					});
				}
			});
		} else {
			RecordsAPI.getAllRecordsByTable(ContextStore.getState(), 'securityGroup');
			securityGroupArr.push({
				label: 'Loading...',
				isSelected: false,
				type: 'defined'
			});
		}

		// Sort the results by setting name
		securityGroupArr.sort(function(a,b){
			let aLabel = a.label ? a.label.toLowerCase() : '';
			let bLabel = b.label ? b.label.toLowerCase() : '';
			if(aLabel < bLabel) { 
				return -1;
			}
			return 1;
		});

		//Content Drop-Down, Content Tabs and Navigation Tabs
		let showCreateUpdate = [
			'bb5bedc3-44d1-4e4c-9c40-561a675173b1',
			'846b747e-25a0-40df-8115-af4a00a1cab5',
			'cd0ee38e-d63f-44d2-b02b-44376fcc7c2e'
		].indexOf(fieldType) === -1 && !subSettingSchemaName;

		return {
			securityGroupArr: securityGroupArr,
			userStateArr: userStateArr,
			componentId,
			parentComponentId,
			parentComponentType,
			subSettingSchemaName,
			logicInfo: {
				isSelected: false
			},
			showCreateUpdate
		}
	}

	/**
	 * Renders a single security group as a disabled LI option.
	 * 
	 * @param {Object} securityGroup 
	 * @param {string} securityGroup.securityGroupRecordId Record ID of the Security Group.
	 * @param {string} securityGroup.label Label for the option.
	 * @param {boolean} securityGroup.isSelected Is this option the selected UI option?
	 * @param {string} securityGroup.type builtIn | logic | defined
	 * @param {Object} securityGroup.permissions Optional - if empty there will be no C.R.U.D. displayed
	 * @param {string} securityGroup.permissions.create 1 | 0 - If Create is enabled.
	 * @param {string} securityGroup.permissions.read 1 | 0 - If Read is enabled.
	 * @param {string} securityGroup.permissions.update 1 | 0 - If Update is enabled.
	 * @param {string} securityGroup.permissions.delete 1 | 0 - If Delete is enabled.
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderDisabledOption(securityGroup, index) {
		let labelClassNames = 'setting-label disabled';
		if(securityGroup.isSelected) { 
			labelClassNames += ' selected';
		}
		let iconClassNames = 'disabled fa ';
		
		switch(securityGroup.type) {
			case 'builtIn':
				iconClassNames += 'fa-user';
			break;
			case 'logic':
				iconClassNames += 'fa-puzzle-piece';
			break;
			default:
			case 'defined':
				iconClassNames += 'fa-users';
			break;
		}

		return (<li key={index} className="nav-item">
			<div className={"setting-icon " + iconClassNames} />
			<div className="d-flex setting-text-container">
				<div className="w-75 setting-label">
					<div className={labelClassNames}><h4>{securityGroup.label}</h4></div>
				</div>
				<div className="w-25 d-flex justify-content-between setting-value-container">
					<div className="setting-pattern-list" style={{ textAlign:'center', width: '100%' }}>
					</div>
				</div>
			</div>
		</li>);
	}
	/**
	 * Renders a single security group as an LI option.
	 * 
	 * @param {Object} securityGroup 
	 * @param {string} securityGroup.securityGroupRecordId Record ID of the Security Group.
	 * @param {string} securityGroup.label Label for the option.
	 * @param {boolean} securityGroup.isSelected Is this option the selected UI option?
	 * @param {string} securityGroup.type builtIn | logic | defined
	 * @param {Object} securityGroup.permissions Optional - if empty there will be no C.R.U.D. displayed
	 * @param {string} securityGroup.permissions.create 1 | 0 - If Create is enabled.
	 * @param {string} securityGroup.permissions.read 1 | 0 - If Read is enabled.
	 * @param {string} securityGroup.permissions.update 1 | 0 - If Update is enabled.
	 * @param {string} securityGroup.permissions.delete 1 | 0 - If Delete is enabled.
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderOption(securityGroup, index) {
		let { disabledRetailRestrictions, onUpgradeClick } = this.props;
		let labelClassNames = 'setting-label';
		if(securityGroup.isSelected) { 
			labelClassNames += ' selected';
		}
		/*************************
			The following commented out statements are for the setting-icon, which is not currently in use
			Uncomment, when setting-icon is used again
		**************************/

		// let iconClassNames = 'fa ';
		
		// switch(securityGroup.type) {
		// 	case 'builtIn':
		// 		iconClassNames += 'fa-user';
		// 	break;
		// 	case 'logic':
		// 		iconClassNames += 'fa-puzzle-piece';
		// 	break;
		// 	default:
		// 	case 'defined':
		// 		iconClassNames += 'fa-users';
		// 	break;
		// }

		let deleteButton = null;
		if(securityGroup.type === 'defined') {
			deleteButton = <div className={"fa fa-trash settings-icon-delete " + ( disabledRetailRestrictions ? 'cd-btn-disabled' : '' )}
				onClick={( disabledRetailRestrictions 
					? onUpgradeClick
					: this.onDeleteClick.bind(this, securityGroup.securityGroupRecordId)  )}></div>;
		}

		let valueDisplay = null;
		if(securityGroup.type === 'logic') {
			let {parentComponentId, parentComponentType, componentId, subSettingSchemaName} = this.state;
			let parentComponentObj = parentComponentType === 'page' ? PageStore.get(parentComponentId) : FieldStore.get(parentComponentId);
			if(subSettingSchemaName) {
				// As far as I am aware, for logic visibility keys, the presence of subSettingSchemaName always means that it's
				// stored on the current component and not the parent component.
				parentComponentObj = FieldStore.get(componentId);
			}
			let visibilityKey = this.props.subSettingSchemaName ? [this.props.attachmentId, this.props.subSettingSchemaName, this.props.subSettingIndex, 'visibility'].join('-') : (this.props.attachmentId + '-visibility');
			let value = parentComponentObj ? parentComponentObj[visibilityKey] : null;
			if(value) {
				valueDisplay = <span className='fa fa-check'><span className='sr-only'>(Has Logic)</span></span>
			}
		} else if (securityGroup.permissions) {
			valueDisplay = <div className='security-group-values'>
				<span className={(securityGroup.permissions.view === '1' ? 'selectedVEAOption' : '')}>V.&nbsp;</span>
				{this.state.showCreateUpdate ? <span className={(securityGroup.permissions.edit === '1' ? 'selectedVEAOption' : '')}>A/E.&nbsp;</span> : null}
			</div>;
		}

		return (<li key={index} className="nav-item">
					<div className="nav-link">
						{/* Temporarily removed the following div */}
						{/* <div className={"setting-icon " + iconClassNames} /> */}
						{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
						{/* Add this back in when the setting-icon is used again */}
						<div className="d-flex setting-text-container">
							<div className="w-75 setting-label-container" onClick={this.onSettingClick.bind(this, securityGroup.securityGroupRecordId)}>
								<div className={labelClassNames}><h4>{securityGroup.label}</h4></div>
							</div>
							<div className="w-25 d-flex justify-content-between setting-value-container" onClick={this.onSettingClick.bind(this, securityGroup.securityGroupRecordId)}>
								<div className="setting-pattern-list" style={{ textAlign:'center', width: '100%' }}>
									{valueDisplay}
								</div>
							</div>
							{deleteButton}
						</div>
					</div>
				</li>);
}


	/**
	 * 
	 * @returns - DOM of list of Query
	 * @memberof QuerySettingsChooser
	 */
	render() {
		// add this back in when using Custom Security Logic
		let { securityGroupArr, userStateArr, logicInfo, isPage } = this.state;
		let { disabledRetailRestrictions, onUpgradeClick } = this.props;
		let settingsList = [];
		if(isPage) {
			return <div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: 0 }} className='no-settings-found'>No Settings Found</div>
				</div>;
		}
		// Process the Security Group Array
		settingsList.push(
			<li key={settingsList.length} className="settings-list-header nav-header mb-2">
				<div className="d-flex noMarginAdjustment">
					<ReactSVG 
						beforeInjection={svg => {
							svg.setAttribute('style', 'width: 22px; height: 22px');
						}}
						src={ContextStore.getUrlMedia() + "/icon-visibility.svg"} />
					<h3 style={{ lineHeight: '1.1' }}>Visibility Permissions by Security Group:</h3>
				</div>
			</li>);
		securityGroupArr.forEach(securityGroup => {
			settingsList.push(this._renderOption(securityGroup, settingsList.length));
		});
		settingsList.push(
			<li key={settingsList.length} className="nav-header">
				<button 
					key="add" 
					className={"btn btn-success " + (disabledRetailRestrictions ? 'cd-btn-disabled' : '')}
					form="appearance-form" 
					style={{
						marginTop: '10px',
						marginBottom: '15px'
					}}
					aria-label="Add Security Group"
					onClick={(disabledRetailRestrictions ? onUpgradeClick : this.onAddClick)}>
					Add Security Group
				</button>
			</li>);

		// Hard-Coded Security "Groups"
		settingsList.push(
			<li key={settingsList.length} className="settings-list-header nav-header mb-2">
				<div className="d-flex noMarginAdjustment">
					<ReactSVG 
						beforeInjection={svg => {
							svg.setAttribute('style', 'width: 22px; height: 22px');
						}}
						src={ContextStore.getUrlMedia() + "/icon-visibility.svg"} />
					<h3 style={{ lineHeight: '1.1' }}>Other Visibility Permissions:</h3>
				</div>
			</li>);
		userStateArr.forEach(securityGroup => {
			settingsList.push(this._renderOption(securityGroup, settingsList.length));
		});

		settingsList.push(this._renderOption({
			securityGroupRecordId: 'logic',
			label: 'Custom Visibility Logic',
			isSelected: logicInfo.isSelected,
			type: 'logic'
		}, settingsList.length));

		let buttons;
		/* The ids for these buttons are set in a specific way, so that Ctrl-S and Ctrl-R (UIUtils.onkeyDown method) can find these buttons */
		if(disabledRetailRestrictions) {
			buttons = (<div key="button" className="btn-wrapper">
				<button 
					// key="submit" 
					className="btn btn-success btn-lg setting-list-button mr-3 ml-1" 
					form="appearance-form" 
					aria-label="Upgrade to use Visibility"
					style={{ width: '100%'}}
					onClick={onUpgradeClick}>
					Upgrade to use Visiblity
				</button>
			</div>);
		} else {
			buttons = (<div key="button" className="btn-wrapper mx-2">
				<button 
					// key="submit" 
					id={'visibilitySave'}
					title="Save (Ctrl-S)"
					className="btn btn-primary btn-lg setting-list-button w-50 mr-1" 
					form="appearance-form" 
					aria-label="Save"
					onClick={this.onSaveClick}>
					Save
				</button>
				<button 
					id={'visibilityReset'}
					title="Reset (Ctrl-R)"
					// key="reset" 
					className="btn btn-warning btn-lg setting-list-button w-50 ml-1" 
					form="appearance-form" 
					aria-label="Reset" 
					onClick={this.onResetClick}>
					Reset
				</button>
			</div>);
		}
		return (
				[
				<div key="settingsList" className='settings-list-wrapper'>
					<ul className="nav flex-column">{settingsList}</ul>
				</div>, 
				buttons
				]
			);
	}
}
const container = Container.create(VisibilitySettingsChooser, { withProps: true });
export default container;
