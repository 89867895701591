import BlockUtils from './block-utils';
import { 
	AdminSettingsActions,
	FieldActions,
	RelationshipActions
} from '../actions';
import {
	AdminSettingsStore,
	ContextStore, 
	FieldStore, 
	RelationshipStore, 
	TableStore
} from '../stores';
import uuid from 'uuid';
import UIUtils from './ui-utils';
import FieldUtils from './field-utils';
import PageUtils from './page-utils';
import TableUtils from './table-utils';
import socketFetcher from './socket-fetcher';

export class RelationUtils {
	/**
	 * Add a Field
	 * @param {string} tableSchemaName Optional - Table Schema Name to set for new field.
	 */
	static addNewRelationship(tableSchemaName) {
		let recordId = uuid.v4();
		let newRelation = {
			recordId: recordId,
			ltorLabel: '',
			lCardinality: '',
			lTableSchemaName: ( tableSchemaName || ''),
			rtolLabel: '',
			rCardinality: '',
			rTableSchemaName: '',
			new: true
		};

		RelationshipActions.pushToStore(recordId, newRelation);

		UIUtils.openSettingsPanel('relationship', recordId, 'relationship');

		// Select the Field Label field.
		AdminSettingsActions.onSettingChange('relation-tables', 'f3e86ca3-48ed-474c-a21f-dcb8396ff0bc');
	}

	/**
	 * Generate a Dynamic Selection field to manage a relationship
	 * @param {string} relationshipRecordId Which Relationship are we managing with this dynamic select?
	 * @param {string} tableSchemaName Which table is this field going to be ON - it should pick FROM the opposite table.
	 * @param {string} side l or r - Which side is the base record on, so we can check the cardinality to determine 1 or m
	 * @param {boolean} pushToDatabase Should this be p     rushed to the database as well as the store.
	 * @returns Promise
	 */
	static createDynamicSelectionField(relationshipRecordId, tableSchemaName, side, pushToDatabase) {
		let relationshipObj = RelationshipStore.get(relationshipRecordId);
		let fieldId = uuid.v4();
		let fieldProps = {
			fieldType: "528c3e72-3a0d-4dc9-8e81-8be6f3c29c5c",
			filterable: "1",
			sortable: "1",
			searchable: "1",
			recordId: fieldId,
			roles: 'recordDetails',
			tableSchemaName: tableSchemaName,
			fieldSchemaName: FieldUtils.validateFieldSchemaName(relationshipObj.relationSchemaName, tableSchemaName).validSchemaName,
		};

		let sourceTableObj = {};
		let targetTableObj = {};
		if(side === 'l') {
			sourceTableObj = TableStore.getByTableSchemaName(relationshipObj.lTableSchemaName);
			targetTableObj = TableStore.getByTableSchemaName(relationshipObj.rTableSchemaName);
		} else {
			sourceTableObj = TableStore.getByTableSchemaName(relationshipObj.rTableSchemaName);
			targetTableObj = TableStore.getByTableSchemaName(relationshipObj.lTableSchemaName);
		}

		let nameFieldId = TableUtils.getNameFieldId(targetTableObj.tableSchemaName);

		// Target Tables Name Field.
		fieldProps.editMode = "{\"fieldId\":\"" + nameFieldId + "\"}";
		fieldProps.viewMode = "{\"fieldId\":\"" + nameFieldId + "\"}";
		fieldProps.viewVariant = "dynamicSelectionView";
		fieldProps.editVariant = "dynamicSelectSearchSingle";

		// lCardinality - How many r's does each l have?
		// rCardinality - How many l's does each r have?

		if(side === 'l') {
			fieldProps.fieldLabel = relationshipObj.ltorLabel;
			if(relationshipObj['lCardinality'] === 'm') {
				fieldProps.editVariant = "dynamicSelectSearchMulti";	
				fieldProps.description = 'Dynamic Selection for each ' + sourceTableObj.singularName + 
					' to define its many \'' + relationshipObj.ltorLabel + '\' ' + targetTableObj.singularName + ' records.';
			} else {
				fieldProps.description = 'Dynamic Selection for each ' + sourceTableObj.singularName + 
					' to define its \'' + relationshipObj.ltorLabel + '\' ' + targetTableObj.singularName + ' record.';
			}
		} else {  // so, r
			fieldProps.fieldLabel = relationshipObj.rtolLabel;
			if(relationshipObj['rCardinality'] === 'm') {
				fieldProps.editVariant = "dynamicSelectSearchMulti";	
				fieldProps.description = 'Dynamic Selection for each ' + sourceTableObj.singularName + 
					' to define its many \'' + relationshipObj.rtolLabel + '\' ' + targetTableObj.singularName + ' records.';
			} else {
				fieldProps.description = 'Dynamic Selection for each ' + sourceTableObj.singularName + 
					' to define its \'' + relationshipObj.rtolLabel + '\' ' + targetTableObj.singularName + ' record.';
			}
		}
		
		fieldProps.searchSuffix = fieldProps.fieldLabel + ":" + fieldProps.fieldLabel + 
			":Selection Field - Dynamic:" + fieldProps.fieldSchemaName;
			
		// Relationship Based Settings
		let directionLabel = 'ltor';
		if(side === 'r') {
			directionLabel = 'rtol'
		}
		fieldProps.relationshipSelector = "{\"relationId\":\"" + relationshipRecordId + "\",\"direction\":\"" + directionLabel + "\"}";
		let nodeId = uuid.v4();
		fieldProps.selectListOf = JSON.stringify({
			"flushBlockly":true,
			"filters":[],
			"nodes":[
				{
					"nodeId":nodeId,
					"tableSchemaName":targetTableObj.tableSchemaName,
					"displayName":targetTableObj.pluralName
				}
			],
			"queryId":uuid.v4(),
			"source":"table",
			"returnNode":nodeId,
			"sorts":[]
		});

		return new Promise((resolve, reject) => {
			FieldActions.pushToStore(fieldId, fieldProps);
	
			if(pushToDatabase) {
				let processingPromises = [];
				processingPromises.push(FieldActions.pushToDatabasePromise(fieldProps));

				let pagesToAttach = TableUtils.getAttachPages(tableSchemaName);
				processingPromises.push(PageUtils.attachFieldToPagesPromise(fieldId, pagesToAttach.add));
				processingPromises.push(PageUtils.attachFieldToPagesPromise(fieldId, pagesToAttach.edit));
				processingPromises.push(PageUtils.attachFieldToSearchPagesPromise(fieldId, pagesToAttach.search));

				Promise.all(processingPromises).then(() => {
					return resolve(true);
				}).catch((e) =>{
					return reject(e);
				})
			} else {
				return resolve(true);
			}
		})
	} // end function createDynamicSelectionField

	/**
	 * Generate a List field to show a relationship
	 * @param {string} relationshipRecordId Which Relationship are we showing with this list?
	 * @param {string} tableSchemaName Which table is this field going to be ON - it should show the opposite table.
	 * @param {string} side l or r - Which side is the list base record on, so we can show the other side?
	 * @param {boolean} pushToDatabase Should this be pushed to the database as well as the store.
	 */
	static createListField(relationshipRecordId, tableSchemaName, side, pushToDatabase) {
		let relationshipObj = RelationshipStore.get(relationshipRecordId);
		let fieldId = uuid.v4();

		// Default/fixed settings
		let fieldProps = {
			fieldType: "9b782b83-4962-4bd6-993c-f72096e02610",
			filterable: "",
			sortable: "",
			searchable: "",
			recordId: fieldId,
			tableSchemaName: tableSchemaName,
			viewVariant: "listView",
			editVariant: "listEdit"
		};

		// Grab the Relationship Tables
		let sourceTableObj = {};
		let targetTableObj = {};
		if(side === 'l') {
			sourceTableObj = TableStore.getByTableSchemaName(relationshipObj.lTableSchemaName);
			targetTableObj = TableStore.getByTableSchemaName(relationshipObj.rTableSchemaName);
		} else {
			sourceTableObj = TableStore.getByTableSchemaName(relationshipObj.rTableSchemaName);
			targetTableObj = TableStore.getByTableSchemaName(relationshipObj.lTableSchemaName);
		}

		let lCardinality = '';
		let rCardinality = '';
		let direction = '';

		// lCardinality - How many r's does each l have?
		// rCardinality - How many l's does each r have?

		if(side === 'l') { // Show the many r's that the l has.
			fieldProps.fieldLabel = relationshipObj.ltorLabel;
			fieldProps.description = 'List to show its many \'' + relationshipObj.ltorLabel + '\' ' + targetTableObj.singularName + 
				' records for each ' + sourceTableObj.singularName + '.';
			lCardinality = relationshipObj.lCardinality;
			rCardinality = relationshipObj.rCardinality;
			direction = 'ltor';
		} else {  // Show the many l's that the r has.
			fieldProps.fieldLabel = relationshipObj.rtolLabel;
			fieldProps.description = 'List to show its many \'' + relationshipObj.rtolLabel + '\' ' + targetTableObj.singularName + 
				' records for each ' + sourceTableObj.singularName + '.';
			lCardinality = relationshipObj.lCardinality;
			rCardinality = relationshipObj.rCardinality;
			direction = 'rtol';
		}
		
		fieldProps.searchSuffix = fieldProps.fieldLabel + ":" + fieldProps.fieldLabel + 
			":List:" + fieldProps.fieldSchemaName;
			
		// Query/Data Setting.
		let nodeId1 = uuid.v4();
		let nodeId2 = uuid.v4();
		let query = uuid.v4();
		let filterId = uuid.v4();
		fieldProps.query = JSON.stringify({
				"filters": [
					{
						"type": "filter",
						"operation": "recordIs",
						"filteredNode": nodeId2,
						"fieldSchemaName": "recordIs",
						"value": "startingContext",
						"context": "namedContexts[\"startingContext\"]"
					},
					{
						"type": "filter",
						"operation": "relation",
						"filteredNode": nodeId2,
						"relatedNode": nodeId1,
						"relationSchemaName": relationshipObj.relationSchemaName,
						"direction": direction,
						"recordId": filterId,
						"requirement": "must",
						"lCardinality": lCardinality,
						"rCardinality": rCardinality
					}
				],
				"nodes": [
					{
						"nodeId": nodeId1,
						"tableSchemaName": targetTableObj.tableSchemaName,
						"displayName": targetTableObj.pluralName
					},
					{
						"nodeId": nodeId2,
						"tableSchemaName": sourceTableObj.tableSchemaName,
						"displayName": sourceTableObj.pluralName
					}
				],
				"queryId": query,
				"source": "table",
				"returnNode": nodeId1,
		});

		return new Promise((resolve, reject) => {
			FieldActions.pushToStore(fieldId, fieldProps);

			// Get the Name field for the target table
			let nameFieldId = FieldUtils.getFieldIdByRole(targetTableObj.tableSchemaName, 'name');

			// Attach the Name first them.
			FieldUtils.attachChild(fieldProps.recordId, nameFieldId, false);

			// Get the columns for the list.
			let fields = FieldUtils.getFieldIdsByRole(targetTableObj.tableSchemaName, 'list');

			// Attach them.
			fields.forEach(field => {
				if(field.recordId !== nameFieldId) {
					FieldUtils.attachChild(fieldProps.recordId, field.recordId, false);
				}
			})

			// fieldProps.attachedFields = JSON.stringify(columnsArray);
			let updatedFieldProps = FieldStore.get(fieldId);

			if(pushToDatabase) {
				let processingPromises = [];
				processingPromises.push(FieldActions.pushToDatabasePromise(updatedFieldProps));
				console.log('Pushing this field to the DB:', updatedFieldProps);

				let pagesToAttach = TableUtils.getAttachPages(tableSchemaName);
				processingPromises.push(PageUtils.attachFieldToPagesPromise(fieldId, pagesToAttach.edit));

				Promise.all(processingPromises).then(() => {
					return resolve(true);
				}).catch((e) =>{
					return reject(e);
				})
			} else {
				return resolve(true);
			}
		})
	} // end function createListField


	/**
	 * Given that we know one table, get the TSN of the other table in a known relationship.
	 * @param {string} relationId The relationship in question.
	 * @param {string} tableSchemaName The table we know/have.
	 * @returns TSN of the other table.
	 */
	static getOtherTableSchemaName(relationId, tableSchemaName) {
		let relationObj = RelationshipStore.get(relationId);
		if(!relationObj) {
			return undefined;
		}

		return (this.getRelationDirectionByTable(relationId, tableSchemaName) === 'ltor' ? relationObj.rTableSchemaName : relationObj.lTableSchemaName);
	} // end getOtherTableSchemaName

	/**
	 * Determine if, given the inputted tableSchemaName, we're looking at this
	 * relationship from ltor or rtol.
	 * @param {string} relationId relationship to check
	 * @param {string} tableSchemaName Table to check for
	 * @returns undefined or string ltor or rtol
	 */
	static getRelationDirectionByTable(relationId, tableSchemaName) {
		let relationObj = RelationshipStore.get(relationId);
		if(!relationObj) {
			return undefined;
		}

		// If the table is in question is on the left...
		// Of the table on the right isn't the table either... 
		if(relationObj.lTableSchemaName === tableSchemaName || 
			relationObj.rTableSchemaName !== tableSchemaName) {
			return 'ltor';
		} else {
			return 'rtol';
		}
	}

	/**
	 * Returns the relationship cardinality Icon for this relationship, based on the table
	 * @param {string} relationId Relation ID to check
	 * @param {string} tableSchemaName table against which to check
	 * @returns 
	 */
	static getRelationIcon(relationId, tableSchemaName) {
		let relationObj = RelationshipStore.get(relationId);
		if(!relationObj) {
			return ContextStore.getUrlMedia() + '/relation-cardinality/one-to-many.svg';
		}

		let renames = {
			'1': 'one',
			'm': 'many'
		}

		let lCardinality = relationObj.lCardinality;
		let rCardinality = relationObj.rCardinality;

		if(!lCardinality || !rCardinality) {
			return ContextStore.getUrlMedia() + '/relation-cardinality/one-to-many.svg';
		} else {
			let direction = this.getRelationDirectionByTable(relationId, tableSchemaName);
			if(direction === 'ltor') {
				return ContextStore.getUrlMedia() + '/relation-cardinality/' +
					renames[rCardinality] + '-to-' + renames[lCardinality] + '.svg';	
			} else {
				return ContextStore.getUrlMedia() + '/relation-cardinality/' +
					renames[lCardinality] + '-to-' + renames[rCardinality] + '.svg';
			}
		}
	}

	/**
	 * Returns the relationship name for this relationship, based on the table
	 * @param {string} relationId Relation ID to check
	 * @param {string} tableSchemaName table against which to check
	 * @returns 
	 */
	static getRelationName(relationId, tableSchemaName) {
		let relationObj = RelationshipStore.get(relationId);
		if(!relationObj) {
			return '[ No Relationship Name Found ]';
		}

		let lCardinality = relationObj.lCardinality;
		let ltorLabel = relationObj.ltorLabel;
		let rCardinality = relationObj.rCardinality;
		let rtolLabel = relationObj.rtolLabel;

		if(!lCardinality || !rCardinality) {
			if(ltorLabel.length) {
				return ltorLabel;
			} else {
				return '[ No Relationship Name Found ]';
			}
		} else {
			let direction = this.getRelationDirectionByTable(relationId, tableSchemaName);
			if(direction === 'ltor') {
				return ltorLabel;
			} else {
				return rtolLabel;
			}
		}
	}

	/**
	 * Stand In Response for the Gen AI Calls in suggestRelationshipsForApplication.
	 * This Stand In is designed to be used on the Comic Books table in dev-eng-stage.  All tests should be done
	 * from that table to ensure that the table being the l or r table is tested appropriately.
	 * @returns Array
	 */
	static _suggestRelationshipsStandIn() {
		// Descriptions are al written from the Comic Book PoV, since thats what the interface does.
		return [
			// // *** 1 to 1 matching Comic Books to Super Heroes, Comic Books in L ***
			// { 
			// 	"recordId": uuid.v4(),
			// 	"lTableSchemaName": "Comic_Books",
			// 	"rTableSchemaName": "Super_Heroes",
			// 	"lCardinality": "1",
			// 	"rCardinality": "1",
			// 	"ltorLabel": "1 Comic Book to 1 Super Hero",
			// 	"rtolLabel": "1 Super Hero to 1 Comic Book",
			// 	"description": "l1m1 L: Each Comic Book has one Super Hero which has one Comic Book.",
			// }, // 2 Single Dyn Selects, one for each side.
			// // *** 1 to 1 matching Comic Books to Super Heroes, Comic Books in R ***
			// { 
			// 	"recordId": uuid.v4(),
			// 	"lTableSchemaName": "Super_Heroes",
			// 	"rTableSchemaName": "Comic_Books",
			// 	"lCardinality": "1",
			// 	"rCardinality": "1",
			// 	"ltorLabel": "1 Super Hero to 1 Comic Book",
			// 	"rtolLabel": "1 Comic Book to 1 Super Hero",
			// 	"description": "l1ml R: Each Comic Book has one Super Hero which has one Comic Book.",
			// }, // 2 Single Dyn Selects, one for each side.

			// // *** 1 to 1 matching Comic Books to Comic Books ***
			// { 
			// 	"recordId": uuid.v4(),
			// 	"lTableSchemaName": "Comic_Books",
			// 	"rTableSchemaName": "Comic_Books",
			// 	"lCardinality": "1",
			// 	"rCardinality": "1",
			// 	"ltorLabel": "1 Comic Book A to 1 Comic Book B",
			// 	"rtolLabel": "1 Comic Book B to 1 Comic Book A",
			// 	"description": "l1m1 Both: Each A to B Comic Book has one B to A Comic Book which has one A to B Comic Book.",
			// }, // 2 Single Dyn Selects, one for each side - both attached to the same page(s).

// lCardinality - How many r's does each l have?
// rCardinality - How many l's does each r have?

			// // *** lmr1 Comic Books to Super Heroes, Comic Books in L ***
			// {
			// 	"recordId": uuid.v4(),
			// 	"lTableSchemaName": "Comic_Books",
			// 	"rTableSchemaName": "Super_Heroes",
			// 	"lCardinality": "m",
			// 	"rCardinality": "1",
			// 	"ltorLabel": "1 Comic Book to Many Super Heroes",
			// 	"rtolLabel": "Many Super Heroes to 1 Comic Book",
			// 	"description": "lmr1 L: Each comic book has many super heroes which have 1 comic book", 
			// }, // Single Dyn Sel for Super Heroes to show 1 Comic Book.  Multi Dyn Sel and list for Comics to show Super Heroes
			// // *** lmr1 Comic Books to Super Heroes, Comic Books in R ***
			// {
			// 	"recordId": uuid.v4(),
			// 	"lTableSchemaName": "Super_Heroes",
			// 	"rTableSchemaName": "Comic_Books",
			// 	"lCardinality": "m",
			// 	"rCardinality": "1",
			// 	"ltorLabel": "1 Super Hero to Many Comic Books",
			// 	"rtolLabel": "Many Comic Books to 1 Super Hero",
			// 	"description": "lmr1 R: Each Comic Book has One Super Hero which has many Comic Books.",
			// },  // Single Dyn Sel for Comic Books to show 1 Super Hero.  Multi Dyn Sel and list for Super Heroes to show Comic Books.

			// *** l1rm Comic Books to Super Heroes, Comic Books in L ***
			// {
			// 	"recordId": uuid.v4(),
			// 	"lTableSchemaName": "Comic_Books",
			// 	"rTableSchemaName": "Super_Heroes",
			// 	"lCardinality": "1",
			// 	"rCardinality": "m",
			// 	"ltorLabel": "Many Comic Books to 1 Super Hero",
			// 	"rtolLabel": "1 Super Hero to Many Comic Books",
			// 	"description": "l1rm L: Each Comic Book has one Super Hero which has many Comic Books.",
			// },  // Single Dyn Sel for Comic Books to show 1 Super Hero.  Multi Dyn Sel and list for Super Heroes to show Comic Books
			// // *** l1rm Comic Books to Super Heroes, Comic Books in R ***
			// {
			// 	"recordId": uuid.v4(),
			// 	"lTableSchemaName": "Super_Heroes",
			// 	"rTableSchemaName": "Comic_Books",
			// 	"lCardinality": "1", 
			// 	"rCardinality": "m",
			// 	"ltorLabel": "Many Super Heroes to 1 Comic Book",
			// 	"rtolLabel": "1 Comic Book to Many Super Heroes",
			// 	"description": "l1rm R: Each Comic Book has Many Superheros which have one Comic Book.",
			// }, // Single Dyn Sel for Super Heroes to show 1 Comic Book.  Multi Dyn Sel and list for Comic Books to show Super Heroes

			// *** lmrm Comic Books to Super Heroes, Comic Books in L ***
			// {
			// 	"recordId": uuid.v4(),
			// 	"lTableSchemaName": "Comic_Books",
			// 	"rTableSchemaName": "Super_Heroes",
			// 	"lCardinality": "m",
			// 	"rCardinality": "m",
			// 	"ltorLabel": "Many Comic Books to Many Super Heroes",
			// 	"rtolLabel": "Many Super Heroes to Many Comic Books",
			// 	"description": "lmrm L: Each Comic Book has many Super Heroes which have many Comic Books.",
			// }, // Multi Dyn Sel and list for Comic Books to show Super Heroes. Multi Dyn Sel and list for Super Heros to show Comic Books
			// // *** lmrm Comic Books to Super Heroes, Comic Books in R ***
			// {
			// 	"recordId": uuid.v4(),
			// 	"lTableSchemaName": "Super_Heroes",
			// 	"rTableSchemaName": "Comic_Books",
			// 	"lCardinality": "m",
			// 	"rCardinality": "m",
			// 	"ltorLabel": "Many Super Heroes to Many Comic Books",
			// 	"rtolLabel": "Many Comic Books to Many Super Heroes",
			// 	"description": "lmrm R: Each Comic Book has many super heroes which have many Comic Books.",
			// }, // Multi Dyn Sel and list for Comic Books to show Super Heroes. Multi Dyn Sel and list for Super Heros to show Comic Books

			// // *** lmrm Comic Books to Comic Books ***
			// {
			// 	"recordId": uuid.v4(),
			// 	"lTableSchemaName": "Comic_Books",
			// 	"rTableSchemaName": "Comic_Books",
			// 	"lCardinality": "m",
			// 	"rCardinality": "m",
			// 	"ltorLabel": "Many Comic Book A's to Many Comic Book B's",
			// 	"rtolLabel": "Many Comic Book B's to Many Comic Book A's",
			// 	"description": "lmrm Both: Each A to B Comic Book has many B to A Comic Books which have many A to B Comic Books.",
			// } // Multi Dyn Sel and list for Comic Book A's to show Comic Book B's. Multi Dyn Sel and list for Comic Book B's to show Comic Book A's
		];
	} // end _suggestTablesStandIn

	/**
	 * Suggest Relationships for this Application, given this description.
	 * @param {String} userPromptDescription Users suggestion
	 * @returns 
	 */
	// static suggestRelationshipsForApplication(userPromptDescription) {
	// 	return new Promise((resolve, reject) => {
	// 		let stub = false;
	// 		if(stub) {
	// 			return resolve(this._suggestRelationshipsStandIn());
	// 		} else {
	// 			socketFetcher('gw/gen-ai-relationships-for-application-v1', JSON.stringify({
	// 				userPromptDescription: userPromptDescription, 
	// 			})).then(result => {
	// 				if(result.responseCode === 200) {
	// 					let suggestedRelationships = result.response.filter(relationship => {
	// 						if(!relationship.lTableSchemaName || !relationship.rTableSchemaName
	// 							|| !relationship.lCardinality || !relationship.rCardinality
	// 							|| !relationship.ltorLabel || !relationship.rtolLabel
	// 						) { 
	// 							return false;
	// 						} else {
	// 							if(!TableStore.getByTableSchemaName(relationship.lTableSchemaName) ||
	// 								!TableStore.getByTableSchemaName(relationship.rTableSchemaName)) {
	// 								return false;
	// 							}
	// 						}
	// 						let existingRelationshipsStore = RelationshipStore.getByTableSchemaName(relationship.lTableSchemaName);
	// 						let disqualifyRelationshipStore = false;
	// 						existingRelationshipsStore.forEach(existingRelationshipStore => {
	// 							// Not sure if the label has to be an exact match to disqualify...
	// 							if(
	// 								( // Straight match
	// 									existingRelationshipStore.lTableSchemaName === relationship.lTableSchemaName && 
	// 									existingRelationshipStore.rTableSchemaName === relationship.rTableSchemaName && 
	// 									existingRelationshipStore.lCardinality === relationship.lCardinality && 
	// 									existingRelationshipStore.rCardinality === relationship.rCardinality && 
	// 									existingRelationshipStore.ltorLabel === relationship.ltorLabel && 
	// 									existingRelationshipStore.rtolLabel === relationship.rtolLabel
	// 								) || ( // strictly reversed match
	// 									existingRelationshipStore.lTableSchemaName === relationship.rTableSchemaName && 
	// 									existingRelationshipStore.rTableSchemaName === relationship.lTableSchemaName && 
	// 									existingRelationshipStore.lCardinality === relationship.rCardinality && 
	// 									existingRelationshipStore.rCardinality === relationship.lCardinality && 
	// 									existingRelationshipStore.ltorLabel === relationship.rtolLabel && 
	// 									existingRelationshipStore.rtolLabel === relationship.ltorLabel
	// 								)) {
	// 									console.log('Disqualifying:', existingRelationshipStore);
	// 									disqualifyRelationshipStore = true;
	// 								}
	// 						});
	// 						if(disqualifyRelationshipStore) {
	// 							console.log('Removing matching store relationship:', relationship);
	// 							return false;
	// 						}
	// 						let existingRelationshipsSugg = AdminSettingsStore.getTableRelationshipSuggestions();
	// 						let disqualifyRelationshipSugg = false;
	// 						existingRelationshipsSugg.forEach(existingRelationshipSugg => {
	// 							if(
	// 								( // Straight match
	// 								existingRelationshipSugg.lTableSchemaName === relationship.lTableSchemaName && 
	// 								existingRelationshipSugg.rTableSchemaName === relationship.rTableSchemaName && 
	// 								existingRelationshipSugg.lCardinality === relationship.lCardinality && 
	// 								existingRelationshipSugg.rCardinality === relationship.rCardinality && 
	// 								existingRelationshipSugg.ltorLabel === relationship.ltorLabel && 
	// 								existingRelationshipSugg.rtolLabel === relationship.rtolLabel
	// 							) || ( // strictly reversed match
	// 								existingRelationshipSugg.lTableSchemaName === relationship.rTableSchemaName && 
	// 								existingRelationshipSugg.lCardinality === relationship.rCardinality && 
	// 								existingRelationshipSugg.rTableSchemaName === relationship.lTableSchemaName && 
	// 								existingRelationshipSugg.rCardinality === relationship.lCardinality && 
	// 								existingRelationshipSugg.ltorLabel === relationship.rtolLabel && 
	// 								existingRelationshipSugg.rtolLabel === relationship.ltorLabel
	// 							)) {
	// 									console.log(existingRelationshipSugg);
	// 									disqualifyRelationshipSugg = true;
	// 								}
	// 						});
	// 						if(disqualifyRelationshipSugg) {
	// 							console.log('Removing matching suggestion relationship:', relationship);
	// 							return false;
	// 						}
	// 						return true;
	// 					})
	// 					return resolve(suggestedRelationships);
	// 				} else {
	// 					if(result.response) {
	// 						console.error(result.response);	
	// 					} else {
	// 						console.error(result);
	// 					}
	// 					return reject('Error processing suggestRelationshipsForApplication.');
	// 				}
	// 			}).catch(e => {
	// 				console.error('Caught error:', e);
	// 				reject(false);
	// 			});
	// 		} // End if stub.
	// 	});
	// } // end suggestRelationshipsForApplication


	/**
	 * Suggest Relationships for this Application, given this description.
	 * @param {String} tableRecordId Which table to suggest for
	 * @param {String} userPromptDescription Users suggestion
	 * @returns 
	 */
	static suggestRelationshipsForTable(tableRecordId, userPromptDescription) {
		return new Promise((resolve, reject) => {
			let stub = false;
			if(stub) {
				return resolve(this._suggestRelationshipsStandIn());
			} else {
				let tableObj = TableStore.get(tableRecordId);
				let existingRelationshipsSugg = AdminSettingsStore.getTableRelationshipSuggestions();
				socketFetcher('gw/gen-ai-relationships-for-table-v1', JSON.stringify({
					userPromptDescription: userPromptDescription,
					tableName: tableObj.tableSchemaName,
					pluralName: tableObj.pluralName,
					existingSuggestions: JSON.stringify(existingRelationshipsSugg)
				})).then(result => {
					if(result.responseCode === 200) {
						let suggestedRelationships = result.response.filter(relationship => {
							let leftTable = {};
							let rightTable = {};
							if(!relationship.lTableSchemaName || !relationship.rTableSchemaName ||
								!relationship.lCardinality || !relationship.rCardinality ||
								!relationship.ltorLabel || !relationship.rtolLabel
							) { 
								console.log('Removing relationship missing an l or r tableSchemaName, Cardinality or Label:', relationship);
								return false;
							} else {
								leftTable = TableStore.getByTableSchemaName(relationship.lTableSchemaName, false, true);
								rightTable = TableStore.getByTableSchemaName(relationship.rTableSchemaName, false, true);
								if(!leftTable.recordId || !rightTable.recordId) {
									console.log('Removing relationship missing a valid table:', relationship);
									return false;
								}
							}
							let existingRelationshipsStore = RelationshipStore.getByTableSchemaName(relationship.lTableSchemaName);
							let disqualifyRelationshipStore = false;
							Object.keys(existingRelationshipsStore).forEach(key => {
								let existingRelationshipStore = existingRelationshipsStore[key];
								// Not sure if the label has to be an exact match to disqualify...
								if(
									( // Straight match
										existingRelationshipStore.lTableSchemaName.toLowerCase() === relationship.lTableSchemaName.toLowerCase() && 
										existingRelationshipStore.rTableSchemaName.toLowerCase() === relationship.rTableSchemaName.toLowerCase() && 
										existingRelationshipStore.lCardinality === relationship.lCardinality && 
										existingRelationshipStore.rCardinality === relationship.rCardinality &&
										existingRelationshipStore.ltorLabel.toLowerCase() === relationship.ltorLabel.toLowerCase() && 
										existingRelationshipStore.rtolLabel.toLowerCase() === relationship.rtolLabel.toLowerCase()
									) ||  ( // Twisted, opposite match
										existingRelationshipStore.lTableSchemaName.toLowerCase() === relationship.rTableSchemaName.toLowerCase() && 
										existingRelationshipStore.rTableSchemaName.toLowerCase() === relationship.lTableSchemaName.toLowerCase() && 
										existingRelationshipStore.lCardinality === relationship.rCardinality && 
										existingRelationshipStore.rCardinality === relationship.lCardinality &&
										existingRelationshipStore.ltorLabel.toLowerCase() === relationship.rtolLabel.toLowerCase() && 
										existingRelationshipStore.rtolLabel.toLowerCase() === relationship.ltorLabel.toLowerCase()
									)) {
										disqualifyRelationshipStore = true;
									}
							});
							if(disqualifyRelationshipStore) {
								console.log('Removing matching store relationship:', relationship);
								return false;
							}
							
							let disqualifyRelationshipSugg = false;
							existingRelationshipsSugg.forEach(existingRelationshipSugg => {
								// Not sure if the label has to be an exact match to disqualify...
								if(
									( // Straight match
										existingRelationshipSugg.lTableSchemaName.toLowerCase() === relationship.lTableSchemaName.toLowerCase() && 
										existingRelationshipSugg.rTableSchemaName.toLowerCase() === relationship.rTableSchemaName.toLowerCase() && 
										existingRelationshipSugg.lCardinality === relationship.lCardinality && 
										existingRelationshipSugg.rCardinality === relationship.rCardinality && 
										existingRelationshipSugg.ltorLabel.toLowerCase() === relationship.ltorLabel.toLowerCase() && 
										existingRelationshipSugg.rtolLabel.toLowerCase() === relationship.rtolLabel.toLowerCase() 
									) || (  // Twisted, opposite match
										existingRelationshipSugg.lTableSchemaName.toLowerCase() === relationship.rTableSchemaName.toLowerCase() && 
										existingRelationshipSugg.rTableSchemaName.toLowerCase() === relationship.lTableSchemaName.toLowerCase() && 
										existingRelationshipSugg.lCardinality === relationship.rCardinality && 
										existingRelationshipSugg.rCardinality === relationship.lCardinality &&
										existingRelationshipSugg.ltorLabel.toLowerCase() === relationship.rtolLabel.toLowerCase() && 
										existingRelationshipSugg.rtolLabel.toLowerCase() === relationship.ltorLabel.toLowerCase()
									)) {
										disqualifyRelationshipSugg = true;
									}
							});
							if(disqualifyRelationshipSugg) {
								console.log('Removing matching suggestion relationship:', relationship);
								return false;
							}
							return true;
						}).map(relationship => {
							if(!relationship.recordId) {
								relationship.recordId = uuid.v4();
							}

							// Fix TSN Case
							let leftTable = TableStore.getByTableSchemaName(relationship.lTableSchemaName, false, true);
							relationship.lTableSchemaName = leftTable.tableSchemaName;
							let rightTable = TableStore.getByTableSchemaName(relationship.rTableSchemaName, false, true);
							relationship.rTableSchemaName = rightTable.tableSchemaName;
							return relationship;
						})
						return resolve(suggestedRelationships);
					} else {
						if(result.response) {
							console.error(result.response);	
						} else {
							console.error(result);
						}
						return reject('Error processing suggestRelationshipsForApplication.');
					}
				}).catch(e => {
					console.error('Caught error:', e);
					reject(false);
				});
			} // End if stub.
		});
	} // end suggestRelationshipsForTable

	/**
	 * Validates RelationSchemaName of new Relationship 
	 * 
	 * @static
	 * @param {String} schemaName - users' name for the block
	 * @param {String} currentRecordid - The ID of the current record being added
	 * @param {Array} errors - Any errors already existing
	 * @param {Array} additionalInvalidNames - Any additional names to consider invalid (used to power validation through MDGW)
	 * @returns {object} - { valid: true/false, validSchemaName: schemaName, errors: []};
	 * @memberof RelationUtils
	 */
	static validateRelationSchemaName(schemaName, currentRecordId, errors, additionalInvalidNames) {
		
		//General Check up for Schema Names 
		let returnObj = BlockUtils.validateSchemaName(schemaName);

		//Update the SchemaName with the General Validation for Blocks
		schemaName = returnObj['validSchemaName'];

		// SchemaName is required, can not be empty
		if(!schemaName){
			returnObj['isValidAndUnique'] = false;
			returnObj['validSchemaName'] = 'field1';
			returnObj['errors'].push('Schema Name is required');
			return returnObj;
		}
		
		//Push the Errors from previous recursive calls the new Errors found: 
		if(errors){
			//Dont repeat same error messages
			for(let i = 0; i < errors.length; i++){
				if(!returnObj['errors'].includes(errors[i]))
				returnObj['errors'].push(errors[i]);	
			}
		}
		
		// Get records for Fields, Relations and Tables
		let relationRecords = RelationshipStore.getAllArray(); 
		let fieldRecords = FieldStore.getAllArray();
		let tableRecords = TableStore.getAllArray();
		// Concatenate all the records
		let allRecords = []
			allRecords = allRecords.concat(relationRecords, fieldRecords, tableRecords);

		let schemaNamesAlreadyUsed = [];
		if(additionalInvalidNames && Array.isArray(additionalInvalidNames)) {
			schemaNamesAlreadyUsed = schemaNamesAlreadyUsed.map(schemaName => schemaName.toLowerCase()).concat(additionalInvalidNames);
		}
		
		//Look for the Table's relations and loop if any matches to what we are returning, error it 
		allRecords.forEach(record => {
			// do not include the current schemaName in the schemaNamesAlreadyUsed array
			if(record.recordId !== currentRecordId){
				// push fieldSchemaName, tableSchemaName and relationSchemaName in the schemaNamesAlreadyUsed artray
				if(record.relationSchemaName){
					schemaNamesAlreadyUsed.push(record.relationSchemaName.toLowerCase());
				} else if(record.fieldSchemaName){
					schemaNamesAlreadyUsed.push(record.fieldSchemaName.toLowerCase());
				} else if(record.tableSchemaName){
					schemaNamesAlreadyUsed.push(record.tableSchemaName.toLowerCase());
				}
			}
		});
		
		let digit = 1;
		//Should be unique 
		if(schemaNamesAlreadyUsed.includes(schemaName.toLowerCase())) {
			// Find the next number this schemaName should have as its sufix, to be valid 
			while(schemaNamesAlreadyUsed.includes(schemaName.toLowerCase() + digit)) {
				digit++;
			}

			//Split digits at the end of the string 
			let nextDigit = schemaName.match(/\d+$/);

			if(!Array.isArray(nextDigit)){
				nextDigit = 0;
			} else {
				nextDigit = nextDigit[0];
			}

			try {
				nextDigit = JSON.parse(nextDigit);
			} catch(error) {
				console.error(error.message);
			}

			//Remove the digits at the end of the string 
			let newSchemaName = schemaName.replace(/\d+$/, '');

			//add digit to current serial number 
			nextDigit += digit;

			//Join string and new Digit 
			newSchemaName += nextDigit;

			returnObj['isValidAndUnique'] = false;
			returnObj['validSchemaName'] = newSchemaName;
			returnObj['errors'].push('Technical Name already exits');
		} else {
			//Breaks the Recursion when All the Tests pass 
			returnObj['isValidAndUnique'] = true;
		}
		
		//Run the recursion: 
		if(!returnObj['isValidAndUnique']){
			//If we are still not unique then...run itself again
			return this.validateRelationSchemaName(returnObj['validSchemaName'], currentRecordId, returnObj['errors'])
		}

		return returnObj;
	}
}

export default RelationUtils;