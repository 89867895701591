import InterfaceActions from '../actions/interface-actions';
import restFetcher from './rest-fetcher';
import socketFetcher from './socket-fetcher';
import ContextStore from '../stores/context-store';
import RecordStore from '../stores/record-store';
import RecordActions from '../actions/record-actions';
import FieldStore from '../stores/field-store';
import FieldSettingsStore from '../stores/field-settings-store';
import PageStore from '../stores/page-store';
import MetadataStore from '../stores/metadata-store';
// import TableStore from '../stores/table-store';
// import RenderStore from '../stores/render-store';
import AuthenticationApi from '../apis/authentication-api';
import AuthenticationActions from '../actions/authentication-actions';
import BrowserStorageStore from '../stores/browser-storage-store';
import socket from './socket';
import RemoteFileStorage from '../utils/stream-data-utils.js';
import ProcessorUtils from './processor-utils.js';
import RecordVariableUtils from './record-variable-utils';
import ObjectUtils from './object-utils';
import pointInPolygon from 'robust-point-in-polygon';
import { Base64 } from 'js-base64';

// Used to get action ID
import uuid from 'uuid';

//Used in action processing
// eslint-disable-next-line
import moment from 'moment-timezone';
import { AuthenticationStore, LogicFunctionStore } from '../stores';
// eslint-disable-next-line
let ProcessingActions = require('../actions/processing-actions').default;
// eslint-disable-next-line
let async = require('async');

// used when generating secure key
let randomBytes = require('crypto').randomBytes;

// eslint-disable-next-line
let co = require('co');

// let sandbox = vm.createContext();
// sandbox.co = co;
// sandbox.moment = moment;

let citDevGlobal = {
	/**
	 * Make an http request
	 * 
	 * @param {string} verb
	 * @param {string} url
	 * @param {Object} params
	 * @returns {Promise}
	 */
	httpRequest: function (verb, url, params) {
		return new Promise(function (resolve, reject) {
			let basePath = 'https://' + window.location.host;
			let basePathOverridePrefix = sessionStorage.getItem('defaultAPIGWOverride');
			if (basePathOverridePrefix) {
				basePath = 'https://' + basePathOverridePrefix + '.citizendeveloper.com';
			}

			let portRegexp = new RegExp(/:\d+$/);
			let portMatches = portRegexp.exec(basePath);
			if (portMatches) {
				//if the base path has a port number then this is a webpack dev server
				console.log('Dev Server Detected')
				basePath = basePath.replace(portMatches[0], '');
			}

			basePath += '/';

			let installationData = ContextStore.getInstallationData();
			var fetchInit = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json; charset=UTF-8'
				},
				credentials: 'include',
				body: JSON.stringify({
					'verb': verb,
					'url': url,
					'params': params,
					'installationId': installationData.id
				})
			};

			fetch(basePath + 'gw/proxy', fetchInit).then(function (response) {
				let headers = {};
				if (response.headers) {
					response.headers.forEach((value, name) => {
						headers[name] = value;
					})
				}
				let bodyPromise = response.text();
				bodyPromise.then(body => {
					//The backend package request processes json strings
					//regardless of content type headers so we are going to try and do the same
					try {
						body = JSON.parse(body);
					} catch(e) {
						//Do nothing and just return the string
					}
					resolve({
						body: body,
						headers: headers,
						statusCode: response.status
					});
				}).catch(error => {
					console.error('Error Processing HTTP Request result: ', error);
					reject(error);
				});
			}).catch(function (error) {
				console.error('Error Processing HTTP Request call: ', error);
				reject(error);
			});
		});
	},
	/**
	 * Creates a Promise that acquires a lock
	 * 
	 * @param {string} name
	 * @returns {Promise}
	 */
	lockAcquire: function (key) {
		// return new Promise(function(resolve, reject){
		// 	let lock = consul.lock({key: key});

		// 	lock.on('acquire', function(){
		// 		resolve(lock);
		// 	}.bind(this));

		// 	lock.on('error', function(err) {
		// 		reject(err);
		// 	});

		// 	lock.acquire();
		// }.bind(this));
	},
	interface: function (action, parameters) {
		return Promise.resolve(InterfaceActions[action].apply(this, [parameters]));
	},
	interfaceV2: function (action, parameters) {
		return Promise.resolve(InterfaceActions[action].apply(this, [parameters]));
	},
	getUserLocation: function(userLoc) {
		return InterfaceActions.getUserLocation(userLoc);
	},
	getPageOrientation: function() {
		return ContextStore.getOrientation();
	},
	/**
	 * Releases a lock acquired via lockAcquire
	 * 
	 * @param {any} lock
	 */
	lockRelease: function (lock) {
		// lock.release();
	},

	getFieldValue: function (fieldSchemaName, recordId, tableSchemaName, fieldPart, fieldId, fieldType, useInterface) {
		if (!fieldSchemaName) {
			console.error('getFieldValue called with no fieldSchemaName');
			return Promise.reject(new Error('getFieldValue called with no fieldSchemaName'));
		}

		if (!recordId || !tableSchemaName) {
			return Promise.resolve(null);
		}

		let valueObj = RecordStore.getValueByFieldSchemaName(tableSchemaName, recordId, fieldSchemaName);

		// If it's already in the store
		if (typeof valueObj === 'object' && valueObj !== null) {
			let fieldValue = valueObj.get('isDirty') && !useInterface ? valueObj.get('originalValue') : valueObj.get('value');
			// And we have a part..
			if (fieldPart) {
				// Read the JSON and Parse
				let fieldValueObj = {};
				if (fieldValue) {
					try {
						fieldValueObj = JSON.parse(fieldValue);
					} catch (error) {
						console.warn('Invalid multipart field value', fieldValue,
							'from record:', recordId, 'in field:', fieldSchemaName, '. Continuing with null.');
					}
				}

				// return the part
				return Promise.resolve(fieldValueObj[fieldPart] ? fieldValueObj[fieldPart] : null);
			} else {
				// No part?  Just resolve the value.
				return Promise.resolve(fieldValue);
			}
		} else {
			return new Promise(function (resolve, reject) {

				// Loop over the fieldSchemaNames, and remove/store those that are dataType === none
				let fieldSchemaNamesWithoutData = [];
				let requestFields = [];

				let fieldId = FieldStore.getFieldId(fieldSchemaName, tableSchemaName);
				let fieldObj = FieldStore.get(fieldId);
				if (!fieldId || (fieldId && !FieldStore.getHasData(fieldId))) {
					fieldSchemaNamesWithoutData.push(fieldSchemaName);
				} else {
					requestFields.push({
						fieldSchemaName: fieldSchemaName,
						fieldType: fieldObj.fieldType,
						fieldId: fieldId
					});
				}

				if (requestFields.length) {
					//Do service call for field value
					socketFetcher('gw/recordRead-v4', JSON.stringify({
						tableSchemaName: tableSchemaName,
						recordId: recordId,
						fields: requestFields
					})).then(function (result) {
						if (result.responseCode === 200) {
							RecordActions.onDataLoaded(result.response);
							if (fieldPart) {
								if (
									result.response &&
									result.response[tableSchemaName] && 
									result.response[tableSchemaName][recordId] &&
									result.response[tableSchemaName][recordId][fieldSchemaName]
								) {
									let fieldValueJSON = result.response[tableSchemaName][recordId][fieldSchemaName],
										fieldValueObj = {};

									try {
										fieldValueObj = JSON.parse(fieldValueJSON);
									} catch (error) {
										console.warn('Error parsing multipart field value JSON:', fieldValueJSON);
									}
									resolve(fieldValueObj[fieldPart] ? fieldValueObj[fieldPart] : null);
								} else {
									resolve(null);
								}
							} else {
								let toResolve = (result && result.response && result.response[tableSchemaName] && result.response[tableSchemaName][recordId] && result.response[tableSchemaName][recordId][fieldSchemaName] ?
									result.response[tableSchemaName][recordId][fieldSchemaName] : null);
								resolve(toResolve);
							}
						} else {
							reject(result.response);
						}
					}).catch(function (err) {
						reject(err);
					});
				} else {
					//if there are no request fields then the field schema name was missing or
					//for a field that has no data so just resolve to null
					resolve(null);
				}
			});
		}

	},
	getFieldValueBulk: function(fieldSchemaName, recordSet, fieldPart, fieldId) {
		if (!fieldSchemaName) {
			console.error('getFieldValueBulk called with no fieldSchemaName');
			return Promise.reject(new Error('getFieldValueBulk called with no fieldSchemaName'));
		}

		if(!recordSet || !recordSet.length) {
			return Promise.resolve([]);
		}

		// Try to get the TSN from the record set
		let tableSchemaName = recordSet[0] && recordSet[0].tableSchemaName ? recordSet[0].tableSchemaName : '';

		if (!tableSchemaName) {
			console.error('getFieldValueBulk called with no tableSchemaName provided either explicitly or implicitly');
			return Promise.reject(new Error('getFieldValueBulk called with no tableSchemaName'));
		}

		let fields = [];
		// Make sure we have the fieldId;
		fieldId = fieldId || FieldStore.getFieldId(fieldSchemaName, tableSchemaName);
		let fieldObj = FieldStore.get(fieldId);
		if (!fieldId || (fieldId && !FieldStore.getHasData(fieldId))) {
			// If this isn't even a data-storing field then just give them a bunch of empty strings
			return Promise.resolve(new Array(recordSet.length).fill(''));
		} else {
			fields.push({
				fieldSchemaName: fieldSchemaName,
				fieldType: fieldObj.fieldType,
				fieldId: fieldId
			});
		}

		// Due to query length limitations, we limit the number of records that we can use per record browse call
		// So if we have a million records, we split it up into 40 record browse calls
		// (which is still a lot fewer than the record-read calls we'd have if using getFieldValue)
		// Calculating using (1000000 - 'SELECT `root.'.length - 128 - '` FROM `'.length - 129 - ' WHERE `root.recordId` IN ['.length - 1) / 38
		// we could actually go up to 26307, but this is a round number that leaves a bit of room for error
		// Lowered to 1000 after running into issues with return size in grpc
		let maxRecords = 1000;
		let i = 0;
		let len = recordSet.length;
		let lookupPromises = [];
		while(i < len) {
			// Get the end index of the record subset
			let end = Math.min(i + maxRecords, len);
			let recordSubset = recordSet.slice(i, end);
			let nodeId = 'return-node';

			// We need our results to be sorted in the appropriate order according to the records.
			// I'm doing this within this loop in hopes that it will result in garbage cleanup cleaning up this
			// variable once it's done so that we don't have to load a potentially very large object into memory
			let indexLookup = {};
			recordSubset.forEach(({recordId}, index) => {
				indexLookup[recordId] = index;
			});
			let results = [];
			
			let query = {
				queryId: 'getFieldBulk-FE-AP-1234-abcd-123412341234',
				returnNode: nodeId,
				nodes: [
					{
						nodeId,
						tableSchemaName
					}
				],
				sorts: [],
				filters: [{
					type: 'filter',
					operation: 'recordIs',
					filteredNode: nodeId,
					fieldSchemaName: 'recordIs',
					value: recordSubset
				}]
			};
			// Now actually make the record browse call
			// (Notably, this is the ONLY place in the action processor right now where we call record browse)
			// @TODO: Why are we stringifying this, anyway? It's wasteful here and I'd prefer not to if we don't need to.
			let lookupPromise = new Promise((resolve, reject) => {
				socketFetcher('gw/recordBrowse-v4', JSON.stringify({
					// We don't actually need to provide recordSets because we 
					tableSchemaName,
					fields,
					query,
					recordSets: {},
					browserStorage: {}
				}))
					.then(({responseCode, response, error}) => {
						// If our response is a 200 response, it was successful
						if(responseCode >= 200 && responseCode < 300) {
							// Unfortunately, due to the way that record-browse returns, we have no choice but to do this
							Object.keys(response.records[tableSchemaName]).forEach(recordId => {
								let record = response.records[tableSchemaName][recordId];
								let value = record[fieldSchemaName];
								// If this is a multipart field, we need to get the part from it
								if(fieldPart && value) {
									let fieldValueObj = typeof value === 'string' ? ObjectUtils.getObjFromJSON(value) : value;
									value = typeof fieldValueObj[fieldPart] === 'undefined' ? null : fieldValueObj[fieldPart];
								}
								// Make sure the results are sorted appropriately
								results[indexLookup[recordId]] = value;
							});
							// Load our response into the record store for later use.
							RecordActions.onDataLoaded(response.records);
							return resolve(results);
						} else if (responseCode < 500) {
							console.warn('%s response received from record browse in front end getFieldValueBulk: ', responseCode, response || error);
							resolve([]);
						} else {
							let err = new Error(responseCode + ' error received from recordBrowse in front end getFieldValue bulk: ' + (response || error));
							return reject(err);
						}
					})
					.catch(reject);
			});
			lookupPromises.push(lookupPromise);
			i = end;
		}
		
		return new Promise((resolve, reject) => {
			Promise.all(lookupPromises)
				.then(results => {
					// Concatenate them all into one array
					let flattenedResults = [];
					results.forEach(resultSet => {
						flattenedResults = flattenedResults.concat(resultSet);
					});
					return resolve(flattenedResults);
				})
				.catch(reject);
		});
	},
	log: function (message, level) {
		level = level ? level : 'log';
		switch (level) {
			default:
			case 'log':
				console.log(message);
				break;
			case 'info':
				console.info(message);
				break;
			case 'warning':
				console.warn(message);
				break;
			case 'error':
				console.error(message);
				break;
		}
	},
	/**
	 * Override for validate Password on the front end.. not available.
	 * 
	 * @param {string} password 
	 * @param {string} hash 
	 * @param {string} salt 
	 * @param {string} version 
	 */
	validatePassword: function () {
		console.warn('validatePassword is not supported on the front end.');
		InterfaceActions.notification({
			level: 'error',
			message: 'You are already logged in.'
		});

		return Promise.resolve(false);
	},
	/**
	 * Override for changeUser on the front end.. not available.
	 */
	changeUser: function () {
		console.warn('changeUser is not supported on the front end.');
		InterfaceActions.notification({
			level: 'error',
			message: 'You are already logged in.'
		});
		return Promise.resolve(false);
	},

	/**
	 * Override for setCookie on the front end.. not available.
	 */
	 setCookie: function () {
		console.warn('setCookie is not supported on the front end.');
		InterfaceActions.notification({
			level: 'error',
			message: 'This should be running on the backend.'
		});
		return Promise.resolve(false);
	},

	/**
	 * Google Cloud Storage functions
	 */
	gcsUtils: {
		uploadFile: function (recordId, tableSchemaName, fieldId, fieldSchemaName,
			fileMode, fileName, contentType, fileContent, fileContentsAreBase64Encoded) {

			return new Promise((resolve, reject) => {
				//Verify the content passed
				let base64EncodedFileContents = '';

				if (fileContentsAreBase64Encoded) {
					base64EncodedFileContents = fileContent;
				} else {
					//encode the content to base64 
					base64EncodedFileContents = Base64.encode(fileContent);
				}

				//Step 1: Upload file with data to GCS 
				RemoteFileStorage.uploadFile(recordId, tableSchemaName, fieldId, fileMode,
					fileName, contentType, base64EncodedFileContents).then(results => {
						let { gcsURL, fileSize } = results;

						if (!gcsURL) {
							return reject('Error Uploading File to GCS');
						}

						let installationId = ContextStore.getInstallationId();

						//Step 2: Update Target Field where we store the Created File

						let fields = [
							{
								fieldType: 'dbe04c76-8ed5-4521-9f32-b896b54455ba', // File Attachment Field Type
								fieldId: fieldId,
								fieldSchemaName: fieldSchemaName,
								value: JSON.stringify({
									name: fileName,
									gcsUrl: gcsURL,
									size: fileSize,
									mimeType: contentType,
									height: null,
									width: null,
									lastModifiedDate: null,
									storageType: fileMode,
									newFileUploaded: 'true'
								}),
							}
						];

						let inputObj = {};
						inputObj.tableSchemaName = tableSchemaName;
						inputObj.installationTenantId = null;
						inputObj.recordId = recordId;
						inputObj.fields = fields;
						inputObj.communityUrl = ContextStore.getUrlCommunity();

						ProcessorUtils.callService(installationId, '', 'record-edit-v3', inputObj, 30000).then((response) => {
							if (response.code !== 200) {
								reject(new Error(response.error));
							} else {
								fields[0].value = response.records[recordId][fieldSchemaName];
								let recordObj = {
									recordId: inputObj.recordId,
									tableSchemaName: inputObj.tableSchemaName,
									fields: fields
								};

								//Action Complete
								return resolve(recordObj);
							}
						}).catch(error => {
							console.error(error);
							return reject(error);
						});

					}).catch(error => {
						console.error('Error Uploading File to GCS. Error was: ' + error);
						return reject('Error Uploading File to GCS. Error was: ' + error);
					});
			});
		},
		/**
		 * Uploads a file to GCS from a URL and updates the database with the new value
		 * @param {Object} communityUrl Community url used by the record edit service to handle field type specific processing
		 * @param {string} installationId - GCS Bucket.
		 * @param {string} recordId - RecordID the destination file will be stored.
		 * @param {string} fieldId - FieldId where this file will be stored.
		 * @param {string} fieldSchemaName What field to store your file in.
		 * @param {string} tableSchemaName What table to store your file on.
		 * @param {string} sourceFileUrl  - The file's Url to pull its contents from 
		 * @param {string} fileMode  - 'private' or 'public' - Was this file stored publicly or privately.? Defaults to Private
		 * @returns   
		 */
		uploadToGCSFromUrl: (recordId, fieldId, fieldSchemaName, tableSchemaName, sourceFileUrl, fileMode = 'private') => {
			return new Promise((resolve, reject) => {
				if (!recordId || !fieldId || !fieldSchemaName || !tableSchemaName || !sourceFileUrl) {
					console.error('Cannot upload File To GCS from Url. One or more params are missing');
					reject(new Error('Cannot upload File To GCS from Url. One or more params are missing'));
				}

				let bodyData = JSON.stringify({
					tableSchemaName: tableSchemaName,
					recordId: recordId,
					installationId: ContextStore.getInstallationId(),
					fieldId: fieldId,
					fileMode: fileMode,
					fileUrl: sourceFileUrl
				});

				socketFetcher('gw/upload-file-toGCS-from-url', bodyData).then(function (data) {
					if (data.responseCode === 200) {
						let fieldValue = data.response;

						//Build the FieldValue with Parts to send to record-edit-v3
						fieldValue['height'] = null;
						fieldValue['width'] = null;
						fieldValue['lastModifiedDate'] = null;
						fieldValue['newFileUploaded'] = 'true';

						let fields = [
							{
								fieldId: fieldId,
								fieldType: 'dbe04c76-8ed5-4521-9f32-b896b54455ba', // File Attachment Field Type
								fieldSchemaName: fieldSchemaName,
								value: JSON.stringify(fieldValue),
							}
						];
						let bodyData = JSON.stringify({
							currentContextObj: ContextStore.getState(),
							tableSchemaName: tableSchemaName,
							recordId: recordId,
							installationId: ContextStore.getInstallationId(),
							fields: fields
						});

						socketFetcher('gw/recordEdit-v3', bodyData).then(function (data) {
							if (data.responseCode === 200) {
								// Update the value of the File Attachment: 
								fields[0]['value'] = data.response[tableSchemaName][recordId][fieldSchemaName];

								let recordObj = {
									recordId: recordId,
									tableSchemaName: tableSchemaName,
									fields: fields
								};

								resolve(recordObj);
							} else {

								let response = data.response,
									responseCode = data.responseCode,
									records = response[tableSchemaName];

								if (records) {
									Object.keys(records).forEach(recordId => {
										let fields = records[recordId];
										if (fields) {
											Object.keys(fields).forEach(fieldKey => {
												let fieldObj = fields[fieldKey];
												let uiMessage = fieldObj && fieldObj.uiMessage ? fieldObj.uiMessage : fieldObj;
												console.error('Error ' + responseCode + ': ' + uiMessage);
											});
										}
									});
								}

								reject(new Error(data.response));
							}
						}).catch(function (error) {
							reject(error.message);
						});
					} else {
						reject(new Error(data.response));
					}
				}).catch(function (error) {
					reject(error.message);
				});
			});
		},
		/**
		 * Deletes a file from GCS
		 * Necessary info is passed in to locate
		 * and delete a file from GCS 
		 * @param {string} gcsUrlToDelete - location of file in GCS
		 * @return {Promise}
		 */
		deleteFromGCS: (gcsUrlToDelete) => {
			return new Promise((resolve, reject) => {
				if (!gcsUrlToDelete) {
					console.error('Cannot delete File with Url Provided: ' + gcsUrlToDelete);
					reject(new Error('Cannot delete File with Url Provided: ' + gcsUrlToDelete));
				}

				let bodyData = JSON.stringify({
					gcsUrlToDelete: gcsUrlToDelete
				});
				socketFetcher('gw/delete-file-from-GCS', bodyData).then(function (data) {
					if (data.responseCode === 200) {
						resolve();
					} else {
						reject(new Error(data.response));
					}
				}).catch(function (error) {
					reject(error.message);
				});
			});
		}
	},
	fileUtils: {
		uploadFile: function (recordId, tableSchemaName, fieldId, fieldSchemaName,
			fileMode, fileName, contentType, fileContent, forceEncoding) {

			return new Promise((resolve, reject) => {
				//Verify the content passed
				let base64EncodedFileContents = '';

				if (forceEncoding) {
					//encode the content to base64 
					base64EncodedFileContents = Base64.encode(fileContent);
				} else {
					base64EncodedFileContents = fileContent;
				}

				//Step 1: Upload file with data to supported remote location 
				RemoteFileStorage.uploadFile(recordId, tableSchemaName, fieldId, fileMode,
					fileName, contentType, base64EncodedFileContents).then(results => {
						let { gcsURL, fileSize } = results;

						if (!gcsURL) {
							return reject('Error Uploading File to Remote');
						}

						//Step 2: Update Target Field where we store the Created File

						let fields = [
							{
								fieldType: 'dbe04c76-8ed5-4521-9f32-b896b54455ba', // File Attachment Field Type
								fieldId: fieldId,
								fieldSchemaName: fieldSchemaName,
								value: JSON.stringify({
									name: fileName,
									gcsUrl: gcsURL,
									size: fileSize,
									mimeType: contentType,
									height: null,
									width: null,
									lastModifiedDate: null,
									storageType: fileMode,
									newFileUploaded: 'true'
								}),
							}
						];

						let inputObj = {};
						inputObj.tableSchemaName = tableSchemaName;
						inputObj.installationTenantId = null;
						inputObj.recordId = recordId;
						inputObj.fields = fields;
						inputObj.communityUrl = ContextStore.getUrlCommunity();

						citDevGlobal.logic.record.editRecord(inputObj, true, true)
							.then(resolve)
							.catch(error => {
								console.error(error);
								return reject(error);
							});
					}).catch(error => {
						console.error('Error Uploading File to GCS. Error was: ' + error);
						return reject('Error Uploading File to GCS. Error was: ' + error);
					});
			});
		},
		uploadToRemoteFromUrl: (recordId, fieldId, fieldSchemaName, tableSchemaName, sourceFileUrl, fileMode = 'private') => {
			return new Promise((resolve, reject) => {
				if (!recordId || !fieldId || !fieldSchemaName || !tableSchemaName || !sourceFileUrl) {
					console.error('Cannot upload File To Remote from Url. One or more params are missing');
					reject(new Error('Cannot upload File To Remote from Url. One or more params are missing'));
				}

				let bodyData = JSON.stringify({
					tableSchemaName: tableSchemaName,
					recordId: recordId,
					installationId: ContextStore.getInstallationId(),
					fieldId: fieldId,
					fileMode: fileMode,
					fileUrl: sourceFileUrl
				});

				socketFetcher('gw/upload-file-to-remote-from-url', bodyData).then(function (data) {
					if (data.responseCode === 200) {
						let fieldValue = data.response;

						//Build the FieldValue with Parts to send to record-edit-v3
						fieldValue['height'] = null;
						fieldValue['width'] = null;
						fieldValue['lastModifiedDate'] = null;
						fieldValue['newFileUploaded'] = 'true';

						let fields = [
							{
								fieldId: fieldId,
								fieldType: 'dbe04c76-8ed5-4521-9f32-b896b54455ba', // File Attachment Field Type
								fieldSchemaName: fieldSchemaName,
								value: JSON.stringify(fieldValue),
							}
						];
						let bodyData = JSON.stringify({
							currentContextObj: ContextStore.getState(),
							tableSchemaName: tableSchemaName,
							recordId: recordId,
							installationId: ContextStore.getInstallationId(),
							fields: fields
						});

						socketFetcher('gw/recordEdit-v3', bodyData).then(function (data) {
							if (data.responseCode === 200) {
								// Update the value of the File Attachment: 
								fields[0]['value'] = data.response[tableSchemaName][recordId][fieldSchemaName];

								let recordObj = {
									recordId: recordId,
									tableSchemaName: tableSchemaName,
									fields: fields
								};

								resolve(recordObj);
							} else {

								let response = data.response,
									responseCode = data.responseCode,
									records = response[tableSchemaName];

								if (records) {
									Object.keys(records).forEach(recordId => {
										let fields = records[recordId];
										if (fields) {
											Object.keys(fields).forEach(fieldKey => {
												let fieldObj = fields[fieldKey];
												let uiMessage = fieldObj && fieldObj.uiMessage ? fieldObj.uiMessage : fieldObj;
												console.error('Error ' + responseCode + ': ' + uiMessage);
											});
										}
									});
								}

								reject(new Error(data.response));
							}
						}).catch(function (error) {
							reject(error.message);
						});
					} else {
						reject(new Error(data.response));
					}
				}).catch(function (error) {
					reject(error.message);
				});
			});
		},
		deleteFromRemote: (urlToDelete) => {
			return new Promise((resolve, reject) => {
				if (!urlToDelete) {
					console.error('Cannot delete File with Url Provided: ' + urlToDelete);
					reject(new Error('Cannot delete File with Url Provided: ' + urlToDelete));
				}

				let bodyData = JSON.stringify({
					urlToDelete: urlToDelete
				});
				socketFetcher('gw/delete-file-from-remote', bodyData).then(function (data) {
					if (data.responseCode === 200) {
						resolve();
					} else {
						reject(new Error(data.response));
					}
				}).catch(function (error) {
					reject(error.message);
				});
			});
		},
		retrievePrivateUrl: function (recordId, fieldId, value, tableSchemaName) {
			return new Promise((resolve, reject) => {
	
				if (typeof value === 'string') {
					try {
						value = JSON.parse(value);
					} catch (error) {
						console.error(`retrievePrivateUrl Error parsing value: ${error.message}`);
						return reject(`retrievePrivateUrl Error parsing value: ${error.message}`);
					}
				}
	
				//Get the info of the field where the File is stored in 
				let recordObj = RecordStore.getRecord(tableSchemaName, recordId),
					fieldObj = FieldStore.get(fieldId),
					fieldSchemaName = fieldObj ? fieldObj.fieldSchemaName : '',
					fieldValue = '';
	
				if (!fieldSchemaName) {
					let errorMessage = `retrievePrivateUrl Error: Can not get Field Schema Name with provided Field Id`;
					console.error(errorMessage);
					return reject(errorMessage);
				}
	
				if (!recordObj) {
					let requestBody = {
						recordId: recordId,
						tableSchemaName: tableSchemaName,
						fields: [{
							fieldSchemaName: fieldSchemaName,
							fieldId: fieldId,
							fieldType: fieldObj.fieldType
						}]
					};
	
					socketFetcher('gw/recordRead-v4', requestBody).then(results => {
						let response = results.response,
							recordObj = (response[tableSchemaName] && response[tableSchemaName][recordId]) ?
								response[tableSchemaName][recordId] : null;
	
						// Overwrite the Field Value 
						fieldValue = recordObj[fieldSchemaName];
	
						try {
							fieldValue = JSON.parse(fieldValue);
						} catch (error) {
							console.error(`retrievePrivateUrl Error parsing value: ${error.message}`);
							return reject(`retrievePrivateUrl Error parsing value: ${error.message}`);
						}
	
						//Return the gcsUrl
						return resolve(fieldValue.gcsUrl);
	
					}).catch(error => {
						let errorMessage = `retrievePrivateUrl Error:Can not get File Name and Content Type with provided TableSchemaName and Record Id`;
						console.error(errorMessage);
						return reject(errorMessage);
					});
				} else {
					let fieldObj = recordObj[fieldSchemaName],
						fieldValue = (fieldObj && fieldObj.value) ? fieldObj.value : {};
	
					try {
						fieldValue = JSON.parse(fieldObj.value);
					} catch (error) {
						console.error(`retrievePrivateUrl Error parsing value: ${error.message}`);
						return reject(`retrievePrivateUrl Error parsing value: ${error.message}`);
					}
	
					//Return the gcsUrl
					return resolve(fieldValue.gcsUrl);
				}
			});
		},
		sanitizeFilename: RemoteFileStorage.sanitizeFilename
	},
	/**
	 * Override for logout on the front end... not available.
	 */
	logout: function () {
		return AuthenticationApi.logout(false);

	},
	crypto: {
		uuid: uuid,
		generateSecureKey: function (byteLength) {
			return new Promise((resolve, reject) => {
				randomBytes(byteLength, (err, buffer) => {
					if (err) {
						return reject(err);
					} else {
						return resolve(buffer.toString('hex'));
					}
				});
			});
		}
	}
};

// NEW citDev Object : 
citDevGlobal.logic = {
	saveGrid: function ({ renderId }) {
		return new Promise((resolve, reject) => {
			// console.log('saveGrid in interface actions:', renderId, RenderStore.get(renderId));
			InterfaceActions.saveGrid({
				renderId: renderId
			}).then(response => {
				// console.log(`Success Saving Grid FrontEnd Action Processor: ${response}`);
				return resolve(response);
			}).catch(error => {
				let errorMessage = error && error.message ? error.message : JSON.stringify(error);
				console.error(`Error saving Grid: ${errorMessage}`);
				return reject(new Error(`Save Error: ${errorMessage}`));
			});
		})
	},
	savePage: function ({ renderId, ignoreRequiredSetting }) {
		return new Promise((resolve, reject) => {
			InterfaceActions.savePage({
				renderId: renderId,
				ignoreRequiredSetting: ignoreRequiredSetting
			}).then(response => {
				// console.log(`Success Saving Page FrontEnd Action Processor: ${response}`);
				return resolve(response);
			}).catch(error => {
				let errorMessage = error && error.message ? error.message : JSON.stringify(error);
				console.error(`Error saving Page: ${errorMessage}`);
				return reject(new Error(`Save Error: ${errorMessage}`));
			});
		})
	},
	FieldStore: {
		getAllArray: function () {
			return new Promise((resolve, reject) => {
				return resolve(FieldStore.getAllArray());
			});
		},
		getAll: function () {
			return new Promise((resolve, reject) => {
				return resolve(FieldStore.getAll());
			});
		},
		get: function (recordId) {
			return new Promise((resolve, reject) => {
				return resolve(FieldStore.get(recordId));
			});
		},
		getSettings: function (recordId) {
			return new Promise((resolve, reject) => {
				return resolve(FieldStore.getSettings(recordId));
			});
		},
		getFieldId: function (fieldSchemaName, tableSchemaName) {
			return new Promise((resolve, reject) => {
				return resolve(FieldStore.getFieldId(fieldSchemaName, tableSchemaName));
			});
		},
		getByFieldType: function (fieldType) {
			return new Promise((resolve, reject) => {
				return resolve(FieldStore.getByFieldType(fieldType));
			});
		},
		getByTableSchemaName: function (tableSchemaName) {
			return new Promise((resolve, reject) => {
				return resolve(FieldStore.getByTableSchemaName(tableSchemaName));
			});
		}
	},
	communication: {
		sendEmailExchange: sendEmailExchange
	},
	file: {
		/**
		 * Download files on action triggered
		 * 
		 * @param {string} recordId 
		 * @param {string} tableSchemaName - used to find file parts in the Stores, when call goes to Action Processor in the UI
		 * @param {string} fieldId 
		 * @param {string} fieldSchemaName - used to be passed to record-read-v3 to find the value of the field when call goes to Action Processor Service 
		 */
		downloadFile: function (recordId, tableSchemaName, fieldId, fieldSchemaName) {
			return new Promise((resolve, reject) => {
				//Grab the File Parts from the store: 
				let errorMessage = '';
				
				if (!fieldSchemaName) {
					let fieldObj = FieldStore.get(fieldId);
					fieldSchemaName = fieldObj ? fieldObj.fieldSchemaName : null;

					if (!fieldSchemaName) {
						errorMessage = `downloadFile Error: Can not get fieldSchemaName for fieldId: ${fieldId}.`;
						console.error(errorMessage);
						return reject(new Error(errorMessage));
					}
				}

				citDevGlobal.getFieldValue(fieldSchemaName, recordId, tableSchemaName, undefined, undefined, undefined, false)
					.then(fieldValue => {
						if(fieldValue && typeof fieldValue === 'string') {
							try {
								fieldValue = JSON.parse(fieldValue);
							} catch(err) {
								console.error('Unable to parse field value', fieldValue);
								return reject(new Error('Unable to parse field value. See console for more information.'));
							}
						}
						if (fieldValue &&
							fieldValue.name &&
							fieldValue.mimeType &&
							fieldValue.storageType
						) {
							//Build the url to return 
							let citDevUrl = '/gw/download-file?' +
								'fileName=' + encodeURIComponent(fieldValue.name) +
								'&fileContentType=' + encodeURIComponent(fieldValue.mimeType) +
								'&fileRecordId=' + encodeURIComponent(recordId) +
								'&fileMode=' + encodeURIComponent(fieldValue.storageType) +
								'&fieldId=' + encodeURIComponent(fieldId);
	
							//Trigger the download File 
							InterfaceActions.downloadFile.apply(this, [{
								sourceUrl: citDevUrl,
								fileName: fieldValue.name
							}]);
	
							return resolve();
						} else {
							let errorMessage = 'downloadFile Error: File Name, MIME Type or Storage Type for Attachment File to download have not been provided.';
							console.error(errorMessage);
							return reject(new Error(errorMessage));
						}
					})
					.catch(reject);
			});
		},
		csv: {},
		gcsFileStream: remoteFileStream,
		remoteFileStream
	},
	record: {
		addRecord: function (recordObj) {
			return new Promise((resolve, reject) => {
				let tableSchemaName = recordObj.tableSchemaName;
				// Check if we're over capacity
				let recordCount = RecordStore.getRecordCount(tableSchemaName);
				let maxTables = AuthenticationStore.getMaxRecordsPerTable();
				if(maxTables && recordCount >= maxTables) { 
					// let tableObj = TableStore.getByTableSchemaName(tableSchemaName);
					// InterfaceActions.notification({ 'level': 'error', 'message': 'Permission Denied: Record count for ' + tableObj.singularName + ' exceeded.' });
					return reject(new Error('Record count exceeded for table ' + tableSchemaName));
				}
				
				let contextObj = ContextStore.getState();
				let bodyData = JSON.stringify({
					currentContextObj: contextObj,
					tableSchemaName: recordObj.tableSchemaName,
					recordId: recordObj.recordId,
					installationId: contextObj.get('installationId'),
					fields: recordObj.fields
				});

				socketFetcher('gw/recordAdd-v4', bodyData).then(function (data) {
					if (data.responseCode === 200) {
						let recordIds = data.response && data.response[recordObj.tableSchemaName] ? Object.keys(data.response[recordObj.tableSchemaName]) : null;
						let recordId = recordIds && recordIds[0] ? recordIds[0] : null;
						let returnObj = {};
						let fieldsObj = {};
						if (recordObj && recordObj.fields) {
							recordObj.fields.forEach(field => {
								fieldsObj[field.fieldSchemaName] = field.value;
							});
						}
						returnObj[recordId] = fieldsObj;
						if(maxTables) {
							RecordActions.updateTableCounts({[tableSchemaName]: recordCount ? (recordCount + 1) : 1});
						}
						resolve(returnObj);
					} else {
						reject(new Error(data.response));
					}
				}).catch(function (error) {
					reject(error.message);
				});

			});
		},
		editRecord: function (recordObj, updateDatabase, updateInterface, forceClean) {
			return new Promise((resolve, reject) => {
				if (updateInterface !== false) {
					//Update the Stores: 
					InterfaceActions.updateRecord.apply(this, [Object.assign({}, recordObj, { forceClean: !!forceClean })]);
				}

				if (updateDatabase) {
					let contextObj = ContextStore.getState();
					let bodyData = JSON.stringify({
						currentContextObj: contextObj,
						tableSchemaName: recordObj.tableSchemaName,
						recordId: recordObj.recordId,
						installationId: contextObj.get('installationId'),
						fields: recordObj.fields
					});

					socketFetcher('gw/recordEdit-v3', bodyData).then(function (data) {
						if (data.responseCode === 200) {
							let response = data.response;
							let { tableSchemaName, recordId } = recordObj;
							let newFieldsObj = response && response[tableSchemaName] && response[tableSchemaName][recordId] ? response[tableSchemaName][recordId] : {};
							delete newFieldsObj.recordId;

							// Force the updated values as "clean" in the Record Store
							RecordActions.updateRecord(tableSchemaName, recordId, newFieldsObj, true);
							
							// Build the value to resolve
							let newRecordObj = Object.assign({}, recordObj);
							if(newRecordObj.fields) {
								newRecordObj.fields = newRecordObj.fields.slice();
								newRecordObj.fields.forEach((fieldObj) => {
									fieldObj.value = newFieldsObj[fieldObj.fieldSchemaName];
								});
							}
							resolve(newRecordObj);
						} else {
							let response = data.response,
								responseCode = data.responseCode,
								records = response[recordObj.tableSchemaName];

							if (records) {
								Object.keys(records).forEach(recordId => {
									let fields = records[recordId];
									if (fields) {
										Object.keys(fields).forEach(fieldKey => {
											let fieldObj = fields[fieldKey];
											let uiMessage = fieldObj && fieldObj.uiMessage ? fieldObj.uiMessage : fieldObj;
											console.error('Error ' + responseCode + ': ' + uiMessage);
										});
									}
								});
							}

							if (responseCode === 404) {
								console.warn('Record '+recordObj.recordId+' Not Found In DB')
								resolve(recordObj);
							} else {
								reject(new Error(data.response));
							}

						}
					}).catch(function (error) {
						reject(error.message);
					});

				} else {
					resolve(recordObj);
				}
			});
		}
	}
};

// Aliased becuase we have a v2 version on the backend
citDevGlobal.logic.saveGridV2 = citDevGlobal.logic.saveGrid;
citDevGlobal.logic.savePageV2 = citDevGlobal.logic.savePage;

citDevGlobal.context = {
	getBasePath() {
		let installationData = ContextStore.getInstallationData();
		return 'https://' + installationData.id + '.citizendeveloper.com';
	}
};

citDevGlobal.math = {
	geo: {
		pointInPolygon
	}
};
/**
 * Helper function to Send Emails through Exchange Services 
 * 
 * @param {string} subject - OPTIONAL - Title of email 
 * @param {string} body - OPTIONAL - Message of email
 * @param {Array} attachments - OPTIONAL - Array of Objects of File Attachments to include in Email 
 * @param {object} attachments[0] Each array entry is an object that contains EITHER:
 * @param {string} attachments[0].sourceFileGCSUrl Required - URL to file
 * @param {string} attachments[0].sourceFileName Required - File Attachment file name
 * @param {string} attachments[0].sourceFileContentType Required - File Attachment contentType 
 * @param {object} attachments[0] OR the below:
 * @param {string} attachments[0].sourceFileRecordId Required - Record Id for Record where the attachment is
 * @param {string} attachments[0].sourceFileFieldId Required - Field Id for field where file attached (File Attachment Field)
 * @param {string} attachments[0].sourceFileTableSchemaName Required - TableSchemaName for Record where the attachment is
 * @param {object} attachments[0] Each Array object is treated individually, so you can mix and match style 1 and style 2 as you need to.
 * @param {Array} to - an Array of Objects like: {emailAddress: 'REQUIRED For Each emailAddress', name: 'OPTIONAL'}
 * @param {Array} cc - an Array of Objects like: {emailAddress: 'REQUIRED For Each emailAddress', name: 'OPTIONAL'}
 * @param {Array} bcc - an Array of Objects like: {emailAddress: 'REQUIRED For Each emailAddress', name: 'OPTIONAL'}
 * @param {string} username - REQUIRED - Sender 
 * @param {string} password  - REQUIRED - Sender password
 * @param {string} type - REQUIRED - Server Type. Supported Servers: exchange || outlook365  
 * @param {string} host -OPTIONAL - when type is outlook365 host is OPTIONAL and defaults to "https://outlook.office365.com" else required. 
 */
function sendEmailExchange(subject, body, attachments, to, cc, bcc, username, password, type, host) {
	return new Promise((resolve, reject) => {
		//Validations
		let errorMessage = '';

		if ((!host && type !== 'outlook365')) {
			errorMessage = 'sendEmailExchange Error: host is required if type is not outlook365';
			console.error(errorMessage);
			return reject(errorMessage);
		}

		if (!username) {
			errorMessage = `sendEmailExchange Error: username is required and got ${username}`
			console.error(errorMessage);
			return reject(errorMessage);
		}

		if (!password) {
			errorMessage = `sendEmailExchange Error: password is required and got ${password}`;
			console.error(errorMessage);
			return reject(errorMessage);
		}

		if (!to) {
			errorMessage = `sendEmailExchange Error: At least one recipient "to" send email is required and got ${to}`;
			console.error(errorMessage);
			return reject(errorMessage);
		}

		if (!Array.isArray(attachments) && typeof attachments === 'string') {
			try {
				attachments = JSON.parse(attachments);
			} catch (error) {
				errorMessage = `sendEmailExchange Error: Failed to parse attachments String to Array. Got: ${attachments}`;
				console.error(errorMessage)
				return reject(errorMessage)
			}
		}

		//Create a new Array for Attachments to add the Missing parts (File Name && ContentType)
		let overwriteAttachments = attachments ? JSON.parse(JSON.stringify(attachments)) : [];

		let attachmentPromises = [];

		if (attachments) {
			attachments.forEach((file, index) => {

				//If a URL is provided
				if (file.sourceFileGCSUrl) {
					// File Name is Required
					if (!file.sourceFileName) {
						errorMessage = `sendEmailExchange Error: Attachment File Name is required and got${file.sourceFileName}`;
						console.error(errorMessage);
						return reject(errorMessage);
					}
					// Content Type is Required
					if (!file.sourceFileContentType) {
						errorMessage = `sendEmailExchange Error: Attachment File ContentType is required and got${file.sourceFileContentType}`;
						console.error(errorMessage);
						return reject(errorMessage);
					}
				}

				//If no URL is provided... look for it an just Pass it!
				if (!file.sourceFileGCSUrl) {
					// TSN is Required
					if (!file.sourceFileTableSchemaName) {
						errorMessage = `sendEmailExchange Error: Attachment File TableSchemaName is required and got${file.sourceFileTableSchemaName}`;
						console.error(errorMessage);
						return reject(errorMessage);
					}
					//RecordId is Required 
					if (!file.sourceFileRecordId) {
						errorMessage = `sendEmailExchange Error: Attachment File Record Id is required and got${file.sourceFileRecordId}`;
						console.error(errorMessage);
						return reject(errorMessage);
					}
					//Field Id is Required
					if (!file.sourceFileFieldId) {
						errorMessage = `sendEmailExchange Error: Attachment File Field Id is required and got${file.sourceFileFieldId}`;
						console.error(errorMessage);
						return reject(errorMessage);
					}

					if (!file.sourceFileName || !file.sourceFileContentType || !file.sourceFileMode) {

						//Get the info of the field where the File is stored in 
						let tableSchemaName = file.sourceFileTableSchemaName,
							recordId = file.sourceFileRecordId,
							recordObj = RecordStore.getRecord(tableSchemaName, recordId),
							fieldObj = FieldStore.get(file.sourceFileFieldId),
							fieldSchemaName = fieldObj ? fieldObj.fieldSchemaName : '',
							fieldValue = '';

						if (!fieldSchemaName) {
							errorMessage = `sendEmailExchange Error: Can not get Field Schema Name for Attachment File with provided Field Id`;
							console.error(errorMessage);
							return reject(errorMessage);
						}


						//If this was not found directly on the record, call the backend 
						if (!recordObj) {
							let requestBody = {
								recordId: recordId,
								tableSchemaName: tableSchemaName,
								fields: [{
									fieldSchemaName: fieldSchemaName,
									fieldId: file.sourceFileFieldId,
									fieldType: fieldObj.fieldType
								}]
							}
							attachmentPromises.push(new Promise((resolve, reject) => {
								// @todo Make sure you test this.. if the send email 
								// action is ever upgraded to use communication.sendEmailExchange
								socketFetcher('gw/recordRead-v4', requestBody).then(results => {
									let response = results.response,
										recordObj = (response[tableSchemaName] && response[tableSchemaName][recordId]) ?
											response[tableSchemaName][recordId] : null;

									// Overwrite the Field Value 
									fieldValue = recordObj[fieldSchemaName];

									try {
										fieldValue = JSON.parse(fieldValue);
									} catch (error) {
										errorMessage = `sendEmailExchange Error: ${error.message}`;
										console.error(errorMessage);
										return reject(errorMessage);
									}
									//Overwrite Values with All the data 
									overwriteAttachments[index] = {
										sourceFileName: fieldValue.name,
										sourceFileMode: fieldValue.storageType,
										sourceFileRecordId: recordId,
										sourceFileTableSchemaName: tableSchemaName,
										sourceFileContentType: fieldValue.mimeType,
										sourceFileGCSUrl: fieldValue.gcsUrl
									};
									return resolve();
								}).catch(error => {
									errorMessage = `sendEmailExchange Error: Can not get File Name and Content Type for Attachment File with provided TableSchemaName and Record Id`;
									console.error(errorMessage);
									return reject(errorMessage);
								});
							}));
						} else {

							fieldObj = recordObj[fieldSchemaName];
							fieldValue = (fieldObj && fieldObj.value) ? fieldObj.value : {};

							try {
								fieldValue = JSON.parse(fieldValue);
							} catch (error) {
								errorMessage = `sendEmailExchange Error: ${error.message}`;
								console.error(errorMessage);
							}

							//Overwrite Values with All the data 
							overwriteAttachments[index] = {
								sourceFileName: fieldValue.name,
								sourceFileMode: fieldValue.storageType,
								sourceFileRecordId: recordId,
								sourceFileTableSchemaName: tableSchemaName,
								sourceFileContentType: fieldValue.mimeType,
								sourceFileGCSUrl: fieldValue.gcsUrl
							};

						}
					}
				}
			});
		}

		Promise.all(attachmentPromises).then(() => {
			let params = {
				installationId: ContextStore.getInstallationId(),
				type: type,
				subject: subject,
				body: body,
				to: to,
				cc: cc,
				bcc: bcc,
				username: username,
				password: password,
				host: host,
				attachments: overwriteAttachments
			};
			// @todo Make sure you test this.. if the send email 
			// action is ever upgraded to use communication.sendEmailExchange
			socketFetcher('gw/send-email-service-v1', JSON.stringify(params)).then(function (result) {
				if (result.responseCode === 200) {
					return resolve(result.response)
				} else {
					console.error(result.response);
					return reject(result.response);
				}
			}).catch(error => {
				errorMessage = `sendEmailExchange Error: ${error.message}`;
				console.error(errorMessage);
				return reject(errorMessage);
			});
		}).catch(error => {
			errorMessage = `sendEmailExchange Error: Can not get File Name and Content Type for Attachment File with provided TableSchemaName and Record Id`;
			console.error(errorMessage);
			return reject(errorMessage);
		});

	});
}

citDevGlobal.gcs = {
	/**
	 * @param  {string} - recordId - required
	 * @param  {string} fieldId - required
	 * @param  {any} value - required
	 * @param  {string} tableSchemaName - optional so we can look up for values in the store before making server calls 
	 */
	retrievePrivateUrl: function (recordId, fieldId, value, tableSchemaName) {
		return new Promise((resolve, reject) => {

			if (typeof value === 'string') {
				try {
					value = JSON.parse(value);
				} catch (error) {
					console.error(`retrievePrivateUrl Error parsing value: ${error.message}`);
					return reject(`retrievePrivateUrl Error parsing value: ${error.message}`);
				}
			}

			//Get the info of the field where the File is stored in 
			let recordObj = RecordStore.getRecord(tableSchemaName, recordId),
				fieldObj = FieldStore.get(fieldId),
				fieldSchemaName = fieldObj ? fieldObj.fieldSchemaName : '',
				fieldValue = '';

			if (!fieldSchemaName) {
				let errorMessage = `retrievePrivateUrl Error: Can not get Field Schema Name with provided Field Id`;
				console.error(errorMessage);
				return reject(errorMessage);
			}

			if (!recordObj) {
				let requestBody = {
					recordId: recordId,
					tableSchemaName: tableSchemaName,
					fields: [{
						fieldSchemaName: fieldSchemaName,
						fieldId: fieldId,
						fieldType: fieldObj.fieldType
					}]
				};

				socketFetcher('gw/recordRead-v4', requestBody).then(results => {
					let response = results.response,
						recordObj = (response[tableSchemaName] && response[tableSchemaName][recordId]) ?
							response[tableSchemaName][recordId] : null;

					// Overwrite the Field Value 
					fieldValue = recordObj[fieldSchemaName];

					try {
						fieldValue = JSON.parse(fieldValue);
					} catch (error) {
						console.error(`retrievePrivateUrl Error parsing value: ${error.message}`);
						return reject(`retrievePrivateUrl Error parsing value: ${error.message}`);
					}

					//Return the gcsUrl
					return resolve(fieldValue.gcsUrl);

				}).catch(error => {
					let errorMessage = `retrievePrivateUrl Error:Can not get File Name and Content Type with provided TableSchemaName and Record Id`;
					console.error(errorMessage);
					return reject(errorMessage);
				});
			} else {
				let fieldObj = recordObj[fieldSchemaName],
					fieldValue = (fieldObj && fieldObj.value) ? fieldObj.value : {};

				try {
					fieldValue = JSON.parse(fieldObj.value);
				} catch (error) {
					console.error(`retrievePrivateUrl Error parsing value: ${error.message}`);
					return reject(`retrievePrivateUrl Error parsing value: ${error.message}`);
				}

				//Return the gcsUrl
				return resolve(fieldValue.gcsUrl);
			}
		});
	}
};

/**
 * Get a promise which resolves to a file stream
 * 
 * @param {string} recordId 
 * @param {string} tableSchemaName - used to find file parts in the Stores, when call goes to Action Processor in the UI
 * @param {string} fieldId 
 * @param {string} fieldSchemaName - used to be passed to record-read-v3 to find the value of the field when call goes to Action Processor Service 
 */
function remoteFileStream(recordId, tableSchemaName, fieldId, fieldSchemaName) {
	console.error('GCSFileStream is not supported on the frontEnd. Please set the cd_force_backend flag to true within your block\'s XML');
	return Promise.resolve();
}

/**
 * Processes and action request on the front end
 * 
 * @param {any} actionRequest 
 * @returns 
 */
function processAction(actionRequest) {
	return new Promise(function (resolve, reject) {
		//IMPORTANT: 
		//Must isolate the context of the citDev object inside this promise, so returnOutput 
		//resolves properly matches its promise.
		let citDev = Object.assign({}, citDevGlobal);
		/**
		 * Returns the output from the action processing
		 * 
		 * @param {any} output
		 * @param {Error} error
		 */
		citDev.returnOutput = function (output, error) {
			if (!error) {
				resolve(output);
			} else {
				reject(error);
			}
		};

		/**
		 * Calls a service via the api gateway
		 * 
		 * @param {string} serviceName
		 * @param {Object} params
		 * @param {number} serviceTimeout
		 * @returns
		 */
		citDev.callService = ProcessorUtils.callService.bind(this, actionRequest.installationId, actionRequest.renderId);

		citDev.getUserAgent = ProcessorUtils.getUserAgent.bind(this);

		// let hash = crypto.createHash('md5');
		if (actionRequest.action) {
			actionRequest.lookupFunctions = true; // From here on out, we need to look up functions separately
			let renderId = actionRequest.renderId;
			//Used in action processing
			// eslint-disable-next-line
			let tableSchemaName = actionRequest.tableSchemaName;
			// eslint-disable-next-line
			let recordId = actionRequest.recordId;
			// eslint-disable-next-line
			let runTimeVariables = actionRequest.runTimeVariables ? actionRequest.runTimeVariables : null;
			// eslint-disable-next-line
			let browserStorage = actionRequest.browserStorage ? actionRequest.browserStorage : {};
			//Used in action processing
			// eslint-disable-next-line
			let namedContexts = actionRequest.namedContexts ?
				actionRequest.namedContexts :
				RecordVariableUtils.buildNamedContexts(renderId, null, actionRequest.action, actionRequest.tableSchemaName, actionRequest.recordId);

			// If we have a data record ID and a data table schema name passed in, forcibly set those as the current context
			if(typeof recordId !== 'undefined' && tableSchemaName && actionRequest.overrideStartingRecord) {
				namedContexts.startingContext = [{recordId: recordId, tableSchemaName: tableSchemaName}]
			}

			citDev.processQuery = function (query, extraParams) {
				// Removing all escaping slashes from query so as to catch cases like 'namedContexts[\"pagePageRecord\"], etc.
				let unescapedQuery = query ? query.replace(/\\/g, '') : '';
				let hasPageInterfaceValues = (unescapedQuery.includes('namedContexts["pagePageRecord"]'));
				let hasCurrentRecordInterfaceValues = (unescapedQuery.includes('namedContexts["pageCurrentRecord"]'));
				let recordStoreRecords = {};
				if (hasPageInterfaceValues) {
					let tsn = namedContexts && namedContexts['page'] && namedContexts['page'][0] ?
						namedContexts['page'][0].tableSchemaName :
						'';
					let recordId = namedContexts && namedContexts['page'] && namedContexts['page'][0] ?
						namedContexts['page'][0].recordId :
						'';
					if (tsn && recordId) {
						recordStoreRecords[tsn] = {
							[recordId]: RecordStore.getRecord(tsn, recordId)
						};
					}
				}
				if (hasCurrentRecordInterfaceValues) {
					let tsn = namedContexts && namedContexts['startingContext'] && namedContexts['startingContext'][0] ?
						namedContexts['startingContext'][0].tableSchemaName :
						'';
					let recordId = namedContexts && namedContexts['startingContext'] && namedContexts['startingContext'][0] ?
						namedContexts['startingContext'][0].recordId :
						'';
					if (tsn && recordId) {
						recordStoreRecords[tsn] = {
							[recordId]: RecordStore.getRecord(tsn, recordId)
						};
					}
				}
				return ProcessorUtils.callService(actionRequest.installationId, actionRequest.renderId, 'query-processor-v2', Object.assign({
					query: query,
					browserStorage: browserStorage,
					runTimeVariables: runTimeVariables || {},
					context: {
						namedContexts: namedContexts,
						user: namedContexts && namedContexts['currentUser'] && namedContexts['currentUser'][0] ?
							namedContexts['currentUser'][0].recordId :
							'',
						installationId: namedContexts && namedContexts['installation'] && namedContexts['installation'][0] ?
							namedContexts['installation'][0].recordId :
							'',
						page: namedContexts && namedContexts['page'] && namedContexts['page'][0] ?
							namedContexts['page'][0].recordId :
							'',
						application: namedContexts && namedContexts['application'] && namedContexts['application'][0] ?
							namedContexts['application'][0].recordId :
							''
					},
					recordStoreRecords: recordStoreRecords
				}, extraParams));
			}

			citDev.processQueryV2 = ProcessorUtils.processQueryV2.bind(this, actionRequest.installationId, actionRequest.renderId, runTimeVariables);


			citDev.installationId = actionRequest.installationId;
			citDev.renderId = renderId;
			citDev.runTimeVariables = runTimeVariables;

			let code = [];
			let includeTimeout = actionRequest.actionTableSchemaName !== 'visibility';
			try {
				code.push('let actionId = "' + uuid.v4() + '"');
				code.push('let timeout;');
				code.push('co(function* () {');
				// Skip actions which redirect to an external page
				code.push('ProcessingActions.addActionId(actionId);');
				if(includeTimeout) {
					// Only show this timeout for non-visibility logic, since visibility logic does not need this warning
					code.push('timeout = setTimeout(function(){');
					code.push('\tProcessingActions.updateShouldDisplayNotification(actionId, true);');
					// Set the timeout value here to control how long an action should run before we display the notification that it's running.
					code.push('}, 750);');
				}
				code.push('let output = {"message": "", "cookies": []};');
				code.push(actionRequest.action);
				code.push('return output;');
				code.push('}).then(function(output) {');
				code.push('output.message = output.message ? output.message : "Complete";');
				code.push('citDev.returnOutput(output);');
				code.push('clearTimeout(timeout);');
				code.push('ProcessingActions.removeActionId(actionId);');
				code.push('}).catch(function(error) {');
				code.push('citDev.returnOutput("", error.message ? error.message : error);');
				code.push('clearTimeout(timeout);');
				code.push('ProcessingActions.removeActionId(actionId);');
				code.push('});');
				code = code.join('\n');  // Blank line between each section.
				// console.log('Processing');
				// console.log(code);
				// console.log(namedContexts);

				// If our browser does not support generators (IE11.. grumble.. ) then babel the code.
				if (!window.supportsGenerators) {
					/*global Babel*/
					code = Babel.transform(code, { presets: ['es2015', 'react'] }).code;
				}

				// eslint-disable-next-line
				eval(code);
			} catch (e) {
				console.error('Error processing action', e);
				citDev.returnOutput(null, 'Script Error: ' + e.message + '.  Script was:' + (Array.isArray(code) ? code.join('\n') : code));
			}
		} else {
			citDev.returnOutput(null, 'Invalid Action');
		}
	});
}

export default {
	processAction: function (actionRequest) {
		//Socket Id has been replace with windowId but is still
		//sent as a socketId for backwards compatabilty
		actionRequest.socketId = socket.id;
		actionRequest.windowId = window.sessionStorage.getItem('citdevWindowId');
		actionRequest.browserStorage = BrowserStorageStore.getStateJS();
		let originalNamedContexts = actionRequest.namedContexts;
		actionRequest.namedContexts = Object.assign({}, actionRequest.namedContexts, RecordVariableUtils.buildNamedContexts(actionRequest.renderId, null, actionRequest.action, actionRequest.tableSchemaName, actionRequest.recordId), originalNamedContexts);
		actionRequest.lookupFunctions = true;
		let { actionTableSchemaName, actionRecordId, hookId, actionParentRecordId } = actionRequest;
		let runOnFrontend = localStorage.getItem('authenticated') === 'true';
		let automation = '',
			automationObj = {};

		//Append the Global Runtime Varaibles
		let globalRunTimeVariables = {};

		// Landing Ref allows us to know the URL we actually loaded/landed on, 
		// when redirected to the Login Page.
		if (sessionStorage.getItem('targetHref')) {
			let targetUrl = new URL(sessionStorage.getItem('targetHref'));
			globalRunTimeVariables.TargetUrl = {
				'href': targetUrl.href,
				'hostname': targetUrl.hostname,
				'port': targetUrl.port,
				'protocol': targetUrl.protocol,
				'path': targetUrl.pathname,
				'query': targetUrl.search
			};
		}
		actionRequest.runTimeVariables =  actionRequest.runTimeVariables ? Object.assign(actionRequest.runTimeVariables, globalRunTimeVariables) : globalRunTimeVariables

		return new Promise((resolve, reject) => {
			// No need to bother with this check if it's going to run on the backend anyway
			if (runOnFrontend) {
				if (actionTableSchemaName === 'field') {
					let settingsObj = FieldSettingsStore.getSettings(actionRecordId, actionParentRecordId);
					automation = settingsObj['automation-' + hookId];
					automationObj = ObjectUtils.getObjFromJSON(automation);
				} else if (actionTableSchemaName === 'page') {
					automationObj = PageStore.getAutomation(actionRecordId, hookId);
				} else if (actionTableSchemaName === 'scheduledLogic') {
					automationObj.runOnBackend = true;
				} else if (actionTableSchemaName === 'visibility') {
					automationObj.runOnBackend = actionRequest.runOnBackend;
				} else if (actionTableSchemaName === 'applications') {
					let applicationObj = MetadataStore.get(ContextStore.getApplicationId(), 'applications') || {};
					automation = applicationObj['automation-' + hookId];
					automationObj = ObjectUtils.getObjFromJSON(automation);
				}
				if (automationObj && !window.supportsGenerators && !automationObj.js.includes('citDev.logic.savePage')) {
					// Screw it. IE 11 will run on the BE unless it has a save page action
					automationObj.runOnBackend = true;
				}
				let automationRunOnBackend = !!automationObj.runOnBackend;
				runOnFrontend = runOnFrontend && !automationRunOnBackend;
			}
			// We need to check some stuff in the logic functions used as well
			if(automationObj.js && automationObj.logicFunctionsUsed) {
				let fullActionJs = actionRequest.action;
				let logicFunctionsUsed = automationObj.logicFunctionsUsed.split(',');
				logicFunctionsUsed.forEach(functionId => {
					fullActionJs = LogicFunctionStore.get(functionId).js + '\n' + fullActionJs;
				});
				if(runOnFrontend) {
					// Add the function definitions in 
					actionRequest.action = fullActionJs
				}
				// Update the namedContexts to make sure it includes the record sets from the page which are only used in a logic function
				// (ticket 20786)
				actionRequest.namedContexts = Object.assign(actionRequest.namedContexts, RecordVariableUtils.buildNamedContexts(actionRequest.renderId, null, fullActionJs, actionRequest.tableSchemaName, actionRequest.recordId), originalNamedContexts);
			}
			if (runOnFrontend) {
				return processAction(actionRequest).then(resolve).catch(error => {
					if (ContextStore.getInstallationRole() !== 'production') {
						InterfaceActions.stickyNotification({
							id: 'actionProcessingError',
							level: 'error',
							title: 'Error processing action!',
							message: 'Please check your console for more information'
						});
					}
					return reject(error);
				});
			} else {
				// Handle high memory processing
				actionRequest.highMemory = automationObj.memUse === 'h';

				let installationData = ContextStore.getInstallationData();
				actionRequest.installationId = installationData.id;
				actionRequest.pageRecord = ContextStore.getRecordId();
				actionRequest.pageTSN = ContextStore.getTableSchemaName();

				let locationPromise = Promise.resolve({
					lat: undefined,
					lng: undefined
				});
				// Check if we need the user location
				if(actionRequest && actionRequest.action && actionRequest.action.includes('citDev.getUserLocation')) {
					locationPromise = InterfaceActions.getUserLocation("full");
				}

				// Check if we need to run this against the REST endpoint (e.g. changeUser or setCookie runs)
				let forceRest = actionRequest && actionRequest.action && (actionRequest.action.includes('citDev.interface("changeUser"') || actionRequest.action.includes('output.cookies.push(') || actionRequest.action.includes('citDev.cookies ? citDev.cookies[')) ? true : false;

				locationPromise
					.then(location => {
						actionRequest.requestInfo = actionRequest.requestInfo || {};
						Object.assign(actionRequest.requestInfo, {location, userAgent: navigator.userAgent, pageOrientation: ContextStore.getOrientation()});
						let maxRecordsPerTable = AuthenticationStore.getMaxRecordsPerTable();
						if(maxRecordsPerTable) {
							actionRequest.requestInfo.maxRecordsPerTable = maxRecordsPerTable;
							actionRequest.recordCounts = RecordStore.getRecordCounts();
						}
						// Get the recordStoreRecords
						actionRequest.recordStoreRecords = RecordVariableUtils.getRecordStoreRecords(actionRequest.renderId, actionRequest.action);
		
						if (process.env.CITDEV_ENV === 'development') {
							actionRequest.mdgwMode = ContextStore.getMDGWMode();
						} else {
							actionRequest.installationVersion = installationData.version;
						}

						delete actionRequest.action;
						// If we're authenticated...
						if (!forceRest && localStorage.getItem('authenticated') === 'true') {
							// Run through socket.io
							return socketFetcher('gw/actionRun-v3', JSON.stringify(actionRequest)).then(results => {
								if (results.response && results.response.hasAuthCreds) {
									AuthenticationActions.haveAuthCreds(true);
								}
								if(results.response && results.response.recordCounts) {
									RecordActions.updateTableCounts(results.response.recordCounts);
								}
								if (results.responseCode === 200) {
									return resolve(results.response);
								} else {
									if (ContextStore.getInstallationRole() !== 'production') {
										InterfaceActions.stickyNotification({
											id: 'actionProcessingError',
											level: 'error',
											title: 'Error processing action!',
											message: 'Please check your console for more information'
										});
									}
									let errorMessage = 'Error processing action';
									if (results.response && results.response.error) {
										errorMessage = results.response.error;
									} else if (results.response) {
										errorMessage = results.response;
									}
									return reject(new Error(errorMessage));
								}
							}).catch(error => {
								if (ContextStore.getInstallationRole() !== 'production') {
									InterfaceActions.stickyNotification({
										id: 'actionProcessingError',
										level: 'error',
										title: 'Error processing action!',
										message: 'Please check your console for more information'
									});
								}
								return reject(error);
							});
						} else {
							// Not authenticated yet.. then we have to run through REST.
							return restFetcher('gw/actionRun-v3', JSON.stringify(actionRequest)).then(results => {
								if (results.response && results.response.hasAuthCreds) {
									AuthenticationActions.haveAuthCreds(true);
								}
								if(results.response && results.response.recordCounts) {
									RecordActions.updateTableCounts(results.response.recordCounts);
								}
								if (results.responseCode === 200) {
									return resolve(results.response);
								} else {
									if (ContextStore.getInstallationRole() !== 'production') {
										InterfaceActions.stickyNotification({
											id: 'actionProcessingError',
											level: 'error',
											title: 'Error processing action!',
											message: 'Please check your console for more information'
										});
									}
									let errorMessage = 'Error processing action';
									if (results.response && results.response.error) {
										errorMessage = results.response.error;
									} else if (results.response) {
										errorMessage = results.response;
									}
									return reject(new Error(errorMessage));
								}
							}).catch(error => {
								if (ContextStore.getInstallationRole() !== 'production') {
									InterfaceActions.stickyNotification({
										id: 'actionProcessingError',
										level: 'error',
										title: 'Error processing action!',
										message: 'Please check your console for more information'
									});
								}
								return reject(error);
							});
						}
					});
			}
		});
	}
};
