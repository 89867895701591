import React, { Component } from 'react';
import { AdminSettingsActions, InterfaceActions, MetadataActions } from '../../../actions';
import { AuthenticationStore, ContextStore, MetadataStore } from '../../../stores';

/**
 * Component for Authenticator
 * 
 * @class Authenticator
 * @extends { Component }
 */
export default class Authenticator extends Component {
	/**
	 * Creates an instance of Authenticator
	 * 
	 * @param {Object} props
	 * @constructor
	 */
	constructor(props) {
		super(props);
		this.state = {
			password: undefined,
			incorrectPassword: false,
		}
		this.onClose = this.onClose.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	/**
	 * Close the modal
	 */
	onClose(event) {
		event.preventDefault();
		AdminSettingsActions.closeModal('pushToStage');
	}
	/**
	 * Pushes to stage
	 */
	onSave(event) {
		event.preventDefault();

		//This is a pre-encrypted password, provided by Drew McLain.
		let drewEncryptedPasswordToMatch = 'RnI0a2sxbkN5bDBucw==';
		// let yoliEncryptedPasswordToMatch = 'VGVtcFBhc3M=';
		// let noahEncryptedPasswordToMatch = 'UGwwcCZCdWNrbDN5IQ==';

		// Grab the password that's been typed in.
		let plainTextPassword = this.state.password;

		// Encrypt what was typed in with the salt...
		let encryptedEnteredPassword = window.btoa(unescape(encodeURIComponent(plainTextPassword)));

		// Compare the pre-encrypted password above to the encrypted version of what they typed in.
		if(drewEncryptedPasswordToMatch === encryptedEnteredPassword 
			// || yoliEncryptedPasswordToMatch === encryptedEnteredPassword
			// || noahEncryptedPasswordToMatch === encryptedEnteredPassword
		) {

			// Access full field type
			let { fieldTypeId, reactComponentName, reactCode, forceHeight } = this.props;
			let fieldTypeObj = MetadataStore.get(fieldTypeId, 'fieldtypes');
			if(fieldTypeObj) {
				// Grab the config JSON, and parse...
				let configJSON = fieldTypeObj.configurationJson,
					configurationObj = {};

				try {
					configurationObj = JSON.parse(configJSON);
				} catch(error) {
					return console.error('Unable to parse configuration JSON:', configJSON, 'Aborting save', error);
				}
				
				// Make sure our config has variants
				if(!configurationObj || !configurationObj.variants) {
					return console.error('No variants in Field Type:', configurationObj, 'Aborting save');
				}

				let changed = false;

				// Loop over the variants, looking for ours...
				configurationObj.variants.forEach(variant => {
					if(variant.reactComponentName === reactComponentName) {
						// we found a match.. overwrite!
						variant.reactCode = reactCode;
						variant.forceHeight = forceHeight;
						changed = true;
					}
				});

				if(changed && ContextStore.getMDGWMode() === 'stage') {
					InterfaceActions.notification({ level: 'warning', message: 'Pushing to stage!' });

					let newConfigJSON = JSON.stringify(configurationObj);
					fieldTypeObj.configurationJson = newConfigJSON;

					// Switch the MD Actions to talk to citdev-engine
					MetadataActions.setInstallationId('citdev-engine', AuthenticationStore.getSignedMdKey(), ContextStore.getMDGWMode());
					// Update the store, so we can see the latest updates.
					MetadataActions.pushToStore(fieldTypeId, 'fieldtypes', fieldTypeObj);
					// Update the FT in the Engine's database
					MetadataActions.pushToDatabasePromise(fieldTypeObj, 'fieldtypes')
						.then(() => {
							InterfaceActions.notification({ level: 'success', message: 'Push to stage successful.  =)' });
							// Then switch it back to talk to our installation!
							MetadataActions.setInstallationId(ContextStore.getInstallationId(), AuthenticationStore.getSignedMdKey(), ContextStore.getMDGWMode());
							// Delete our variant override.
							MetadataActions.deleteFromDatabasePromise(reactComponentName, 'fieldtypevariant')
								.then(() => {
									InterfaceActions.notification({ level: 'success', message: 'Removal from local overrides successful.' });
									// Close the Password Modal.
									AdminSettingsActions.closeModal('pushToStage');
									// Close the Variant Modal.
									AdminSettingsActions.closeModal('field-component-modal');
								})
								.catch((error) => {
									console.error(error);
									InterfaceActions.notification({ level: 'error', message: 'Error removing the ft variant override from the database.  Check your console =(' });
								});
						})
						.catch(error => {
							console.error(error);
							InterfaceActions.notification({ level: 'error', message: 'Error pushing to stage.  Check your console =(' });
						});
				} else {
					InterfaceActions.notification({ level: 'error', message: 'No change detected, or not on staging cluster.  Not pushing to stage.' });
				}

			} else {
				console.error('Unable to find Field Type Obj by ID:', fieldTypeId, 'Aborting save');
			}
		} else {
			this.setState({ incorrectPassword: true });
		}		
	}

	/**
	 * Render the Authenticator
	 * 
	 * @returns React
	 */
	render() {
		return(
			<div className="authenticator-modal modal-dialog show">
				<div className="modal-content container">
					<div className="modal-header justify-content-end cd-bg-2">
						<h1 className="modal-title align-self-center mr-auto">
							Team Leader Authorization
						</h1>
						{/* Close */}
						<button 
							type="button" 
							className="close float-right m-0 align-self-center p-0" 
							aria-label="Close"
							onClick={this.onClose} 
						>
							&times;
						</button>
					</div>
					<div className="modal-body container">
						<div className="input-form-container container-fluid">
							<div className="row align-items-center">
								<h4 className="align-items-center form-control-label labels col-4">Password: </h4>
								<div className="form-control-input col-8">
									<input type="password" autoComplete="off" onChange={(event) => { this.setState({password: event.target.value}); }} className="form-control input-password" required={true} id="password" placeholder="Enter password" />
									{ this.state.incorrectPassword ? <div className="small" style={{ color:'red', textAlign:'center' }}>Incorrect Password</div> : null }
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer justify-content-center">
						{/* Close */}
						<button 
							className="btn btn-danger" 
							aria-label="Success"
							onClick={this.onSave} 
						>
							Push to Stage
						</button>
					</div>
				</div>
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	Authenticator.displayName = 'Authenticator';
	Authenticator.propTypes = {
	};
}