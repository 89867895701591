import AppDispatcher from '../dispatcher/app-dispatcher';
import {RecordConstants} from '../constants/record-constants';
import RenderConstants from '../constants/render-constants';
import Immutable from 'immutable';
import GridHeightUtils from '../utils/grid-height-utils';
import uuid from 'uuid';

let heightsByScreensize = {};
let heightUpdateTimeout;

/**
 * Render Actions
 * 
 * @namespace RenderActions
 */
const RenderActions = {

	/**
	 * Flags all rendered instances of a component as disabled
	 *
	 * @param {*} componentId
	 */
	componentDisable(componentId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.COMPONENT_DISABLE,
			componentId: componentId
		}));
	},

	/**
	 * Flags all rendered instances of a component as enabled
	 *
	 * @param {*} componentId
	 */
	componentEnable(componentId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.COMPONENT_ENABLE,
			componentId: componentId
		}));
	},

	/**
	 * Removes a render and all of its children from the renderStore
	 * 
	 * @param {string} renderId 
	 * @memberof RenderActions
	 */
	deleteRender(renderId, reassignPageChildren) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.DELETE_RENDER,
			renderId: renderId,
			reassignPageChildren: reassignPageChildren
		}));
	},

	/**
	 * Removes multiple renders and all of their children from the renderStore
	 * 
	 * @param {array} renderIds 
	 * @memberof RenderActions
	 */
	deleteRenderBulk(renderIds) {
		renderIds = renderIds || [];
		if(typeof renderIds === 'string') {
			renderIds = renderIds.split(',');
		}
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.DELETE_RENDER_BULK,
			renderIds: renderIds
		}));
	},

    /**
     * Setup a render in the Render Store.
     * 
     * @param {string} renderId
	 * @param {string} dataRecordId
     * @param {string} dataTableSchemaName
     * @param {string} [renderParentId] 
	 * @param {string} [componentId] 
	 * @param {string} [componentType] 
	 * @param {Object} [options] 
	 * @param {string} [attachmentKey]
	 * @memberof RenderActions
     */
	setRender(renderId, dataRecordId, dataTableSchemaName, renderParentId, componentId, componentType, options, attachmentKey, attachmentId, protectDataRecordId) {
		let toDispatch = {
			type: RenderConstants.SET_RENDER,
			renderId: renderId,
			dataRecordId: dataRecordId,
			dataTableSchemaName: dataTableSchemaName,
			renderParentId: renderParentId,
			componentId: componentId,
			componentType: componentType,
			options: options,
			attachmentKey: attachmentKey
		};
		// Don't override the old attachment ID if it hasn't been included (ticket 6901)
		if(attachmentId) {
			toDispatch.attachmentId = attachmentId;
		}
		if(typeof protectDataRecordId !== 'undefined') {
			toDispatch.protectDataRecordId = protectDataRecordId;
		}
		AppDispatcher.dispatch(Immutable.fromJS(toDispatch));
	},

	/**
	 * Set a array of renders in the Render Store
	 * 
	 * @param {Object[]} renders 
	 * @param {string} renders[].renderId - renderId
	 * @param {string} renders[].dataRecordId - dataRecordId
     * @param {string} renders[].dataTableSchemaName - dataTableSchemaName
     * @param {string} renders[].renderParentId - (Optional)
	 * @param {string} renders[].componentId - (Optional) ComponentId eg pageId or fieldId
	 * @param {string} renders[].componentType - (Optional) ComponentType eg page or field
	 * @param {Object} renders[].options - (Optional) Object of options 
	 * @param {string} renders[].attachmentKey - (Optional) attachmentKey 
	 * @memberof RenderActions
	 */
	setRenderBulk(renders) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.SET_RENDER_BULK,
			renders: renders
		}));
	},

	/**
	 * Add a record to the render item
	 * 
	 * @param {string} renderId 
	 * @param {string} dataRecordId 
	 * @param {string} dataTableSchemaName 
	 * @memberof RenderActions
	 */
	setRecord(renderId, dataRecordId, dataTableSchemaName) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.SET_RENDER_RECORD,
			renderId: renderId,
			dataRecordId: dataRecordId,
			dataTableSchemaName: dataTableSchemaName,
		}));
	},

	/**
	 * Add records to the render items
	 * 
	 * @param {Object[]} renders
	 * @param {string} renders[].renderId
	 * @param {string} renders[].dataRecordId 
	 * @param {string} renders[].dataTableSchemaName 
	 * @memberof RenderActions
	 */
	setRecordBulk(renders) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.SET_RENDER_RECORD_BULK,
			renders: renders
		}));
	},

	/**
	 * Add a record set to a render's list of included record sets.
	 * @param {string} renderId 
	 * @param {string} recordSetName 
	 * @memberof RenderActions
	 */
	addRecordSet(renderId, recordSetName) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.SET_RENDER_RECORDSET,
			renderId: renderId,
			recordSetName: recordSetName
		}));
	},

	/**
	 * Remove a record set from a render's list
	 * @param {string} renderId 
	 * @param {string} recordSetName 
	 * @memberof RenderActions
	 */
	deleteRecordSet(renderId, recordSetName) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.DELETE_RENDER_RECORDSET,
			renderId: renderId,
			recordSetName: recordSetName
		}));
	},

	/**
	 * Similar to refreshing a field, but instead only refreshes a given render entry + its children
	 * @param {string} renderId The render store entry to refresh
	 * @returns 
	 */
	refreshRender(renderId) {
		let lastRefresh = +new Date();

		let FieldStore = require('../stores/field-store').default;
		let FieldSettingsStore = require('../stores/field-settings-store').default;
		let PageStore = require('../stores/page-store').default;

		let RenderStore = require('../stores/render-store').default;
		let AdminSettingsStore = require('../stores/admin-settings-store').default;
		let FieldModesStore = require('../stores/field-modes-store').default;
		let PageModeStore = require('../stores/page-mode-store').default;
		let activeOverlays = AdminSettingsStore.getActiveOverlays();

		let renderItem = RenderStore.getImmutable(renderId);
		if (renderItem && renderItem.get('componentType') === 'field') {
			let fieldId = renderItem.get('componentId');
			let fieldObj = FieldStore.get(fieldId);
			if (fieldObj && fieldObj.fieldType === '9b782b83-4962-4bd6-993c-f72096e02610') {
				// Is this a List? Just update the lastRefresh date and let the rest take care of itself
				AppDispatcher.dispatch(Immutable.fromJS({
					type: RenderConstants.REFRESH_FIELD,
					fieldId: fieldObj.recordId,
					lastRefresh: lastRefresh
				}));

				return;
			}

			let localFieldObj = Object.assign({}, fieldObj);

			// We need to find the parent information for refreshing

			let parentRender = RenderStore.getImmutable(renderItem.get('renderParentId'));
			let pageRenderObj = RenderStore.getPageRenderObj(renderItem.get('renderId'));
			let parentFieldPosition;
			let parentFieldPositionExtras;
			let parentAttachedFields;
			// @TODO: May need to pass parent mode through as well?
			if (parentRender) {
				// If this is the child of a repeating grid being refreshed then skip it
				// It will already be taken care of by the parent refresh.
				if (parentRender.get('repeatingGrid')) {
					return;
				}
				let componentType = parentRender.get('componentType');
				let parentComponentId = parentRender.get('componentId');
				let attachmentId = renderItem.get('attachmentId');
				// Local settings for the field being refreshed
				if (renderItem.get('componentType' === 'field')) {
					let localSettings = attachmentId
						? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, fieldId, parentComponentId)
						: FieldSettingsStore.getSettings(fieldId, parentComponentId);
					Object.assign(localFieldObj, localSettings);
				}

				if (componentType === 'field') {
					let parentFieldObj = FieldStore.get(parentComponentId);
					// I think we actually need to check local settings on the parentFieldObj here
					let grandparentRender = RenderStore.getImmutable(parentRender.get('renderParentId'));
					if (grandparentRender) {
						let grandparentComponentId = grandparentRender.get('componentId');
						let parentAttachmentId = parentRender.get('attachmentId');
						let parentSettings = parentAttachmentId
							? FieldSettingsStore.getSettingsFromAttachmentId(parentAttachmentId, parentComponentId, grandparentComponentId)
							: FieldSettingsStore.getSettings(parentComponentId, grandparentComponentId);
						Object.assign(parentFieldObj, parentSettings);
					}
					parentFieldPosition = parentFieldObj && parentFieldObj.fieldPosition ? JSON.parse(parentFieldObj.fieldPosition) : {};
					parentFieldPositionExtras = parentFieldObj && parentFieldObj.fieldPositionExtras ? JSON.parse(parentFieldObj.fieldPositionExtras) : {};
					parentAttachedFields = parentFieldObj && parentFieldObj.attachedFields ? JSON.parse(parentFieldObj.attachedFields) : [];
				} else {
					let PageStore = require('../stores/page-store').default;
					let pageObj = PageStore.get(parentRender.get('componentId'));
					parentFieldPosition = pageObj && pageObj.fieldPosition ? JSON.parse(pageObj.fieldPosition) : {};
					parentFieldPositionExtras = pageObj && pageObj.fieldPositionExtras ? JSON.parse(pageObj.fieldPositionExtras) : {};
					parentAttachedFields = pageObj && pageObj.attachedFields ? JSON.parse(pageObj.attachedFields) : [];
				}
			}

			let fieldPosition = localFieldObj && localFieldObj.fieldPosition ? JSON.parse(localFieldObj.fieldPosition) : {};
			let fieldPositionExtras = localFieldObj && localFieldObj.fieldPositionExtras ? JSON.parse(localFieldObj.fieldPositionExtras) : {};
			let attachedFields = localFieldObj && localFieldObj.attachedFields ? JSON.parse(localFieldObj.attachedFields) : [];

			let dataRecordId = renderItem.get('dataRecordId');
			let dataTableSchemaName = renderItem.get('dataTableSchemaName');

			let availableModes = renderItem.get('componentType') === 'page' ? PageModeStore.getAvailableModes(renderItem.get('componentId')) : FieldModesStore.getAvailableModes(renderItem.get('renderId'));
			let parentAvailableModes = parentRender && parentRender.get('componentType') === 'page'
				? PageModeStore.getAvailableModes(parentRender.get('componentId')) :
				(parentRender ? FieldModesStore.getAvailableModes(parentRender.get('renderId')) : []);


			// Get the relevant attached fields
			let parentAttachedFieldResults = RenderStore.getAttachedFields(renderItem.get('renderParentId')) || [];
			if (parentAttachedFieldResults) {
				// Put these in the same format as that produced by FieldComponentUtils.visibility.runAttachedFieldVisibility
				parentAttachedFieldResults.forEach((att, index) => {
					att.fieldId = att.recordId;
					let modes = FieldModesStore.getMode(att.renderId);
					if (modes) {
						att.availableModes = modes.availableModes;
						att.currentMode = modes.currentMode;
					}
					att.order = index;
				});
			}
			let parentGrid = {
				attachedFields: parentAttachedFields,
				fieldPosition: parentFieldPosition,
				fieldPositionExtras: parentFieldPositionExtras,
				attachmentId: parentRender ? parentRender.get('attachmentId') : '',
				parentRenderId: parentRender ? parentRender.get('renderId') : '',
				grandparent: parentRender ? parentRender.get('renderParentId') : '',
				dataRecordId: parentRender ? parentRender.get('dataRecordId') : '',
				dataTableSchemaName: parentRender ? parentRender.get('dataTableSchemaName') : '',
				componentId: parentRender ? parentRender.get('componentId') : '',
				componentType: parentRender ? parentRender.get('componentType') : '',
				availableModes: parentAvailableModes && parentAvailableModes.toJS ? parentAvailableModes.toJS() : parentAvailableModes,
				gridInfo: parentRender && parentRender.get('gridInfo') ? parentRender.get('gridInfo').toJS() : {},
				gridLayoutHeights: parentRender && parentRender.get('gridLayoutHeights') ? parentRender.get('gridLayoutHeights').toJS() : {},
				attachedFieldsResults: parentAttachedFieldResults
			};
			let childGrid = {
				attachmentId: renderItem.get('attachmentId'),
				renderId: renderItem.get('renderId'),
				renderParentId: renderItem.get('renderParentId'),
				componentId: renderItem.get('componentId'),
				componentType: renderItem.get('componentType'),
				dataRecordId,
				dataTableSchemaName,
				fieldPosition: fieldPosition,
				fieldPositionExtras: fieldPositionExtras,
				attachedFields: attachedFields,
				gridLayoutHeights: renderItem.get('gridLayoutHeights'),
				modes: availableModes && availableModes.toJS ? availableModes.toJS() : availableModes
			}
			return GridHeightUtils.recalcSingleField(parentGrid, childGrid, pageRenderObj, activeOverlays)
				.then(grids => {
					AppDispatcher.dispatch(Immutable.fromJS({
						type: RenderConstants.REFRESH_FIELD,
						fieldId: fieldId,
						lastRefresh: lastRefresh,
						grids: grids
					}));
				});
		} else if (renderItem) {
			let pageObj = PageStore.get(renderItem.get('componentId'));
			if(pageObj) {
				return RenderActions.replacePage(pageObj.recordId, renderId, renderItem.get('renderParentId'),
					renderItem.get('dataRecordId'), renderItem.get('dataTableSchemaName')
				);
			}
		}

		return Promise.resolve();
	},

	/**
	 * Triggers an update to all render entries with a given field ID
	 *
	 * @param {*} fieldId
	 */
	refreshField(fieldId) {

		let lastRefresh = +new Date();

		let FieldStore = require('../stores/field-store').default;
		let FieldSettingsStore = require('../stores/field-settings-store').default;

		let fieldObj = FieldStore.get(fieldId);

		let RenderStore = require('../stores/render-store').default;
		let AdminSettingsStore = require('../stores/admin-settings-store').default;
		let FieldModesStore = require('../stores/field-modes-store').default;
		let PageModeStore = require('../stores/page-mode-store').default;
		let activeOverlays = AdminSettingsStore.getActiveOverlays();

		let recalcPromises = [];
		
		// If this field is a grid component with field positions
		// then it also needs to recalculate its children here
		let renderItems = RenderStore.getRenderObjectsForComponent('field', fieldId);
		if(renderItems) {
			renderItems.forEach(renderItem => {

				if(fieldObj && fieldObj.fieldType === '9b782b83-4962-4bd6-993c-f72096e02610') {
					// Is this a List? Just update the lastRefresh date and let the rest take care of itself
					AppDispatcher.dispatch(Immutable.fromJS({
						type: RenderConstants.REFRESH_FIELD,
						fieldId: fieldObj.recordId,
						lastRefresh: lastRefresh
					}));

					return;
				}

				let localFieldObj = Object.assign({}, fieldObj);

				// We need to find the parent information for refreshing

				let parentRender = RenderStore.getImmutable(renderItem.get('renderParentId'));
				let pageRenderObj = RenderStore.getPageRenderObj(renderItem.get('renderId'));
				let parentFieldPosition;
				let parentFieldPositionExtras;
				let parentAttachedFields;
				// @TODO: May need to pass parent mode through as well?
				if(parentRender) {
					// If this is the child of a repeating grid being refreshed then skip it
					// It will already be taken care of by the parent refresh.
					if(parentRender.get('repeatingGrid')) {
						return;
					}
					let componentType = parentRender.get('componentType');
					let parentComponentId = parentRender.get('componentId');
					let attachmentId = renderItem.get('attachmentId');
					// Local settings for the field being refreshed
					if(renderItem.get('componentType' === 'field')) {
						let localSettings = attachmentId
							? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, fieldId, parentComponentId)
							: FieldSettingsStore.getSettings(fieldId, parentComponentId);
						Object.assign(localFieldObj, localSettings);
					}

					if(componentType === 'field') {
						let parentFieldObj = FieldStore.get(parentComponentId);
						// I think we actually need to check local settings on the parentFieldObj here
						let grandparentRender = RenderStore.getImmutable(parentRender.get('renderParentId'));
						if(grandparentRender) {
							let grandparentComponentId = grandparentRender.get('componentId');
							let parentAttachmentId = parentRender.get('attachmentId');
							let parentSettings = parentAttachmentId
								? FieldSettingsStore.getSettingsFromAttachmentId(parentAttachmentId, parentComponentId, grandparentComponentId)
								: FieldSettingsStore.getSettings(parentComponentId, grandparentComponentId);
							Object.assign(parentFieldObj, parentSettings);
						}
						parentFieldPosition = parentFieldObj && parentFieldObj.fieldPosition ? JSON.parse(parentFieldObj.fieldPosition) : {};
						parentFieldPositionExtras = parentFieldObj && parentFieldObj.fieldPositionExtras ? JSON.parse(parentFieldObj.fieldPositionExtras) : {};
						parentAttachedFields = parentFieldObj && parentFieldObj.attachedFields ? JSON.parse(parentFieldObj.attachedFields) : [];
					} else {
						let PageStore = require('../stores/page-store').default;
						let pageObj = PageStore.get(parentRender.get('componentId'));
						parentFieldPosition = pageObj && pageObj.fieldPosition ? JSON.parse(pageObj.fieldPosition) : {};
						parentFieldPositionExtras = pageObj && pageObj.fieldPositionExtras ? JSON.parse(pageObj.fieldPositionExtras) : {};
						parentAttachedFields = pageObj && pageObj.attachedFields ? JSON.parse(pageObj.attachedFields) : [];
					}
				}

				let fieldPosition = localFieldObj && localFieldObj.fieldPosition ? JSON.parse(localFieldObj.fieldPosition) : {};
				let fieldPositionExtras = localFieldObj && localFieldObj.fieldPositionExtras ? JSON.parse(localFieldObj.fieldPositionExtras) : {};
				let attachedFields = localFieldObj && localFieldObj.attachedFields ? JSON.parse(localFieldObj.attachedFields) : [];

				let dataRecordId = renderItem.get('dataRecordId');
				let dataTableSchemaName = renderItem.get('dataTableSchemaName');

				let availableModes = renderItem.get('componentType') === 'page' ? PageModeStore.getAvailableModes(renderItem.get('componentId')) : FieldModesStore.getAvailableModes(renderItem.get('renderId'));
				let parentAvailableModes = parentRender && parentRender.get('componentType') === 'page'
					? PageModeStore.getAvailableModes(parentRender.get('componentId')) :
					(parentRender ? FieldModesStore.getAvailableModes(parentRender.get('renderId')) : []);

				
				// Get the relevant attached fields
				let parentAttachedFieldResults = RenderStore.getAttachedFields(renderItem.get('renderParentId')) || [];
				if(parentAttachedFieldResults) {
					// Put these in the same format as that produced by FieldComponentUtils.visibility.runAttachedFieldVisibility
					parentAttachedFieldResults.forEach((att, index) => {
						att.fieldId = att.recordId;
						let modes = FieldModesStore.getMode(att.renderId);
						if(modes) {
							att.availableModes = modes.availableModes;
							att.currentMode = modes.currentMode;
						}
						att.order = index;
					});
				}
				let parentGrid = {
					attachedFields: parentAttachedFields,
					fieldPosition: parentFieldPosition,
					fieldPositionExtras: parentFieldPositionExtras,
					attachmentId: parentRender ? parentRender.get('attachmentId') : '',
					attachmentKey: parentRender ? parentRender.get('attachmentKey') : '',
					parentRenderId: parentRender ? parentRender.get('renderId') : '',
					grandparent: parentRender ? parentRender.get('renderParentId') : '',
					dataRecordId: parentRender ? parentRender.get('dataRecordId') : '',
					dataTableSchemaName: parentRender ? parentRender.get('dataTableSchemaName') : '',
					componentId: parentRender ? parentRender.get('componentId') : '',
					componentType: parentRender ? parentRender.get('componentType') : '',
					availableModes: parentAvailableModes && parentAvailableModes.toJS ? parentAvailableModes.toJS() : parentAvailableModes,
					gridInfo: parentRender && parentRender.get('gridInfo') ? parentRender.get('gridInfo').toJS() : {},
					gridLayoutHeights: parentRender && parentRender.get('gridLayoutHeights') ? parentRender.get('gridLayoutHeights').toJS() : {},
					attachedFieldsResults: parentAttachedFieldResults
				};
				let childGrid = {
					attachmentId: renderItem.get('attachmentId'),
					renderId: renderItem.get('renderId'),
					renderParentId: renderItem.get('renderParentId'),
					componentId: renderItem.get('componentId'),
					componentType: renderItem.get('componentType'),
					dataRecordId,
					dataTableSchemaName,
					fieldPosition: fieldPosition,
					fieldPositionExtras: fieldPositionExtras,
					attachedFields: attachedFields,
					gridLayoutHeights: renderItem.get('gridLayoutHeights'),
					modes: availableModes && availableModes.toJS ? availableModes.toJS() : availableModes
				}
				recalcPromises.push(GridHeightUtils.recalcSingleField(parentGrid, childGrid, pageRenderObj, activeOverlays))
			});
		}

		Promise.all(recalcPromises)
			.then(nestedGrids => {
				let flattenedGrids = [];
				nestedGrids.forEach(grids => flattenedGrids = flattenedGrids.concat(grids));
				AppDispatcher.dispatch(Immutable.fromJS({
					type: RenderConstants.REFRESH_FIELD,
					fieldId: fieldId,
					lastRefresh: lastRefresh,
					grids: flattenedGrids
				}));
			})
			.catch(error => {
				console.error('Error recalculating attached fields when refreshing field.', error);
			});
	},

	failSaveGrid(gridId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.FAIL_SAVE_GRID,
			gridId,
			failedAt: +new Date()
		}));
	},

	/**
	 * Runs the visibility logic + queries for all attached fields of a parent, then
	 * updates the appropriate stores.
	 *
	 * @param {string} renderId The render ID of the parent component
	 * @param {string} dataRecordId The data record ID of the parent component
	 * @param {string} dataTableSchemaName The data table schema name ID of the parent component
	 * @param {string} fieldId The ID of the field whose children are being calculated
	 * @param {array} attachedFields The attached fields to calculate
	 * @param {array} modes The modes available to the child
	 * @returns
	 */
	calculateChildFields(renderId, dataRecordId, dataTableSchemaName, fieldId, attachedFields, modes, componentType) {
		return new Promise((resolve, reject) => {
			let startTime = +new Date();
			let FieldComponents = require('../utils/field-components').default;

			FieldComponents.visibility.runAttachedFieldVisibility(attachedFields, modes, renderId, fieldId, dataRecordId, dataTableSchemaName)
				.then(newAttachedFields => {
					AppDispatcher.dispatch(Immutable.fromJS({
						type: RenderConstants.CALCULATE_ATTACHED_FIELDS,
						lastChildCalculation: startTime,
						parentRenderId: renderId,
						dataRecordId,
						dataTableSchemaName,
						componentId: fieldId,
						componentType: componentType || 'field',
						// fieldId,
						attachedFields: newAttachedFields
					}));


					return resolve(newAttachedFields);
				})
				.catch(reject);
		});
	},

	/**
	 * Initiates a grid component by calculating its attached fields and positions and updating the render store.
	 * 
	 * @param {string} renderId The renderID of the grid
	 * @param {string} parentRenderId The renderId of the grid's parent, if present
	 * @param {string} dataRecordId The data record ID
	 * @param {string} dataTableSchemaName The data TSN
	 * @param {string} componentId The component's field or page ID
	 * @param {string} componentType The component's type (field or page)
	 * @param {array} attachedFields Array of attached fields
	 * @param {object} fieldPosition Object containing arrays of field positions keyed by canvas size
	 * @param {object} fieldPositionExtras Object containing arrays of extra field position information keyed by canvas size
	 * @param {array} modes The modes available to the field on init
	 * @returns 
	 */
	initiateGrid(renderId, parentRenderId, dataRecordId, dataTableSchemaName, componentId, componentType, attachedFields, fieldPosition, fieldPositionExtras, modes, query) {
		return new Promise((resolve, reject) => {

			let AdminSettingsStore = require('../stores/admin-settings-store').default;
			let activeOverlays = AdminSettingsStore.getActiveOverlays();

			let startTime = +new Date();

			let action = {
				type: RenderConstants.INIT_GRID,
				lastChildCalculation: startTime,
				parentRenderId: renderId,
				grandparent: parentRenderId,
				dataRecordId,
				dataTableSchemaName,
				componentId,
				componentType
			}

			// If there's a query then it will override our data TSN and record ID
			// so we need to run it
			// This should only happen with field containers which have query overrides
			// as this parameter is otherwise not passed in
			let fieldDict = {};
			let lookupFields = [];
			let FieldStore = require('../stores/field-store').default;
			
			if (query) {
				attachedFields.forEach(field => {
					let recordIds = field.recordIds ? field.recordIds : [field.recordId];
					recordIds.forEach(recordId => {
						if (!fieldDict[recordId]) {
							let fieldObj = FieldStore.get(recordId) || {};
							fieldObj.fieldId = recordId;
							fieldDict[recordId] = fieldObj;
						}
					});
				});
				lookupFields = Object.keys(fieldDict).map(recordId => fieldDict[recordId]);
				action.setName = componentId + '-results';
				action.uiName = 'results';
				action.query = query;
				action.fields = lookupFields;
			} else if (dataRecordId && dataTableSchemaName) {
				let RecordStore = require('../stores/record-store').default;
				let FieldTypeStore = require('../stores/field-type-store').default;
				let RecordsAPI = require('../apis/records-api').default;
				//We should make sure the other fields are looked up at this point as well. (Ticket 24285)
				// Only look up values we don't already have

				attachedFields.forEach(field => {
					let recordIds = field.recordIds ? field.recordIds : [field.recordId];
					recordIds.forEach(recordId => {
						let fieldHasData = FieldStore.getHasData(recordId);
						let recordData = RecordStore.getRecord(dataTableSchemaName, dataRecordId);

						if (!fieldDict[recordId]) {
							let fieldObj = FieldStore.get(recordId) || {};
							if (!fieldHasData) {
								// Also get dynamic selection fields
								let fieldTypeObj = fieldObj ? FieldTypeStore.get(fieldObj.fieldType) : {};
								fieldHasData = fieldTypeObj.dataType === 'relationship';
							}
							// @TODO: Does/can this handle empty field values?
							// If our field requires data && we don't yet have ANY data... || we have data, we just don't have THIS piece of data..
							if (fieldHasData && (!recordData || typeof recordData[fieldObj.fieldSchemaName] === 'undefined')) {
								fieldObj.fieldId = recordId;
								fieldDict[recordId] = fieldObj;
							}
						}
					});
				});

				lookupFields = Object.keys(fieldDict).map(recordId => fieldDict[recordId]);
				if (lookupFields.length) {
					let fieldSchemaNamesToGet = lookupFields.map(({ fieldSchemaName }) => fieldSchemaName);
					RecordsAPI.getRecord(dataTableSchemaName, dataRecordId, fieldSchemaNamesToGet);
				}
			}

			// @TODO: With the other changes in grid-height-utils,
			// this query double-runs.
			// We do, however, have some need of it, so.

			return GridHeightUtils.recalcAttachedFields([{
				renderId: renderId,
				renderParentId: parentRenderId,
				componentId,
				componentType,
				dataRecordId,
				dataTableSchemaName,
				fieldPosition,
				fieldPositionExtras,
				attachedFields,
				modes
			}], activeOverlays, [], true, true, false)
			.then(grids => {
				if(grids && grids[0] && grids[0].actionRows && query) {
					action.dataRecordId = grids[0].dataRecordId || '';
					action.dataTableSchemaName = grids[0].dataTableSchemaName || '';
					action.rows = grids[0].actionRows;
				}
				action.grids = grids;
				AppDispatcher.dispatch(Immutable.fromJS(action));
				return resolve(grids);
			})
			.catch(reject);
		});
	},

	/**
	 * Calculates the render store information for a page and all its descendants.
	 * @param {object} pageObj The page object
	 * @param {string} dataRecordId The data record ID about which to open the page
	 * @param {string} dataTableSchemaName The data TSN about which to open the page
	 * @param {string} renderId The render ID for the page
	 * @param {string} renderParentId Optional: the render ID for the page's parent (for dialogs)
	 * @param {object} dialogOptions Optional: the dialog options for the page, if a dialog
	 * @returns 
	 */
	initiatePage(pageObj, dataRecordId, dataTableSchemaName, renderId, renderParentId, dialogOptions) {
		let AdminSettingsStore = require('../stores/admin-settings-store').default;
		let activeOverlays = AdminSettingsStore.getActiveOverlays();

		let startTime = +new Date();

		let action = {
			type: RenderConstants.INIT_PAGE,
			lastChildCalculation: startTime
		}

		let mode = undefined;

		// Generate the record if it does not exist yet and this is an add page
		let attachedFieldsArr = pageObj.attachedFields ? JSON.parse(pageObj.attachedFields) : [];

		let PageModeStore = require('../stores/page-mode-store').default;
		let loadData;
		// If the page has no records and supports add mode, create a dummy record
		if (!dataRecordId && PageModeStore.hasAvailableMode(pageObj.recordId, 'add')) {
			mode = 'add';
			// Use a "new" variable here so we don't overwrite recordID and continue processing later.  We want to 
			// "Wrap around" with the recordID from context store.

			let FieldStore = require('../stores/field-store').default;
			let FieldTypeStore = require('../stores/field-type-store').default;

			let recordData = {};
			dataRecordId = uuid.v4();
			dataTableSchemaName = pageObj.tableSchemaName;
			recordData.recordId = dataRecordId;
			if(Array.isArray(attachedFieldsArr)) {
				attachedFieldsArr.forEach(attachedField => { 
					let fieldIds = attachedField.recordIds ? attachedField.recordIds : [attachedField.recordId];
					fieldIds.forEach(fieldId => {
						let fieldHasData = FieldStore.getHasData(fieldId);

						let fieldObj = FieldStore.get(fieldId);
						if(fieldObj) {
							if (!fieldHasData) {
								let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType);
								// Also get dynamic selection fields
								fieldHasData = fieldTypeObj.dataType === 'relationship';
							}
							if(fieldHasData) {
								recordData[fieldObj.fieldSchemaName] = null;
							}
						}
					})
				});
			}
			loadData = {};
			loadData[dataTableSchemaName] = {};
			loadData[dataTableSchemaName][dataRecordId] = recordData;
		}

		return new Promise((resolve, reject) => {
			GridHeightUtils.initiatePage(pageObj, dataRecordId, dataTableSchemaName, renderId, renderParentId,
					activeOverlays, mode, undefined, dialogOptions
				)
				.then(grids => {
					if(loadData) {
						AppDispatcher.dispatch(Immutable.fromJS({
							type: RecordConstants.NEW_RECORDS_CREATED,
							records: loadData
						}));
					}
					action.grids = grids;
					AppDispatcher.dispatch(Immutable.fromJS(action));
					return resolve(grids);
				})
				.catch(reject);
		});
	},

	/**
	 * Similar to initiatePage except that it also clears out the old child grids
	 * and always overrides the data TSNs and record IDs
	 * @param {string*} newPageId  The ID of the new page to open
	 * @param {string} renderId The render ID of the page to replace
	 * @param {string} renderParentId The parent render ID of the page
	 * @param {string} newDataRecordId The new data record ID to open the page about
	 * @param {string} newDataTSN The new data table schema name to open the page about
	 * @returns 
	 */
	replacePage(newPageId, renderId, renderParentId, newDataRecordId, newDataTSN) {
		
		let AdminSettingsStore = require('../stores/admin-settings-store').default;
		let activeOverlays = AdminSettingsStore.getActiveOverlays();
		let PageStore = require('../stores/page-store').default;
		let pageObj = PageStore.get(newPageId);

		let startTime = +new Date();

		let action = {
			type: RenderConstants.REPLACE_PAGE,
			lastChildCalculation: startTime
		}

		// Generate the record if it does not exist yet and this is an add page
		let attachedFieldsArr = pageObj.attachedFields ? JSON.parse(pageObj.attachedFields) : [];

		let PageModeStore = require('../stores/page-mode-store').default;
		let loadData;
		// If the page has no records and supports add mode, create a dummy record
		if (!newDataRecordId && PageModeStore.hasAvailableMode(pageObj.recordId, 'add')) {
			// Use a "new" variable here so we don't overwrite recordID and continue processing later.  We want to 
			// "Wrap around" with the recordID from context store.

			let FieldStore = require('../stores/field-store').default;

			let recordData = {};
			newDataRecordId = uuid.v4();
			newDataTSN = pageObj.tableSchemaName;
			recordData.recordId = newDataRecordId;
			if(Array.isArray(attachedFieldsArr)) {
				attachedFieldsArr.forEach(attachedField => { 
					let fieldIds = attachedField.recordIds ? attachedField.recordIds : [attachedField.recordId];
					fieldIds.forEach(fieldId => {
						let fieldObj = FieldStore.get(fieldId);
						if(fieldObj) {
							recordData[fieldObj.fieldSchemaName] = null;
						}
					})
				});
			}
			loadData = {};
			loadData[newDataTSN] = {};
			loadData[newDataTSN][newDataRecordId] = recordData;
		}


		return new Promise((resolve, reject) => {
			GridHeightUtils.initiatePage(pageObj, newDataRecordId, newDataTSN, renderId,renderParentId, activeOverlays)
			.then(grids => {
				if(loadData) {
					AppDispatcher.dispatch(Immutable.fromJS({
						type: RecordConstants.NEW_RECORDS_CREATED,
						records: loadData
					}));
				}
				action.grids = grids;
				AppDispatcher.dispatch(Immutable.fromJS(action));
				return resolve(grids);
			})
			.catch(reject);
		});
	},

	/**
     * Extremely similar to the onCenterColumnResize in AdminSettingsActions
	 * but is run inside a debounce and needs to run separately for ease
     *
     * @param  {object} sizeObj   object with width and height properties
     */
	 onCenterColumnResize(sizeObj) {
		let startTime = +new Date();
		let AdminSettingsStore = require('../stores/admin-settings-store').default;
		let RenderStore = require('../stores/render-store').default;

		let toRecalc = RenderStore.getGridsToRecalculate();
		let activeOverlays = AdminSettingsStore.getActiveOverlays();

		// Now calculate the grids second
		GridHeightUtils.recalcAttachedFields(toRecalc, activeOverlays, [], undefined, undefined, true)
			.then((grids) => {
				AppDispatcher.dispatch(Immutable.fromJS({
					'type': RenderConstants.GRID_UPDATE,
					'lastChildCalculation': startTime,
					'grids': grids
				}));
			})
			.catch(error => {
				console.error('Error recalculating attached fields when resizing canvas.', error);
			});
	},

	/**
	 * Specifically used when initiating a repeating grid component, which is different from
	 * initiating a regular one.
	 * 
	 * @param {string} renderId The renderID of the grid
	 * @param {string} parentRenderId The renderId of the grid's parent, if present
	 * @param {string} dataRecordId The data record ID
	 * @param {string} dataTableSchemaName The data TSN
	 * @param {string} componentId The component's field or page ID
	 * @param {string} componentType The component's type (field or page)
	 * @param {number} gridItemHeight The default height of each repeated grid
	 * @param {number} gridItemWidth The default width of each repeated grid
	 * @param {string} autofitIndividualGrids string "true" or "false" or undefined. Whether individual items should autofit
	 * @param {array} attachedFields Array of attached fields
	 * @param {object} fieldPosition Object containing arrays of field positions keyed by canvas size
	 * @param {object} fieldPositionExtras Object containing arrays of extra field position information keyed by canvas size
	 * @param {array} modes The modes available to the field on init
	 * @param {string | object} query The query used to determine the grids which repeat
	 * @returns 
	 */
	initiateRepeatingGrid(renderId, parentRenderId, dataRecordId, dataTableSchemaName, componentId, componentType, gridItemHeight, gridItemWidth, autofitIndividualGrids, attachedFields, fieldPosition, fieldPositionExtras, modes, query) {
		// Specifically calculates repeating grid record sets,
		// as those have some special cases involved
		// Skipping this for now.
		return new Promise((resolve, reject) => {
			let setName = componentId + '-results';
			let AdminSettingsStore = require('../stores/admin-settings-store').default;
			let FieldModesStore = require('../stores/field-modes-store').default;
			let activeOverlays = AdminSettingsStore.getActiveOverlays();
			let action = {
				type: RenderConstants.INIT_REPEATING_GRID,
				lastDt: +new Date(),
				parentRenderId: renderId,
				grandparent: parentRenderId,
				dataRecordId,
				dataTableSchemaName,
				componentId,
				componentType,
				setName,
				uiName: 'results'
			};

			if(query) {
				action.query = query;
			}

			return GridHeightUtils.recalcRepeatingGrid({
				renderId,
				renderParentId: parentRenderId,
				componentId,
				componentType,
				dataRecordId,
				dataTableSchemaName,
				gridItemHeight,
				gridItemWidth,
				availableModes: FieldModesStore.getAvailableModes(renderId),
				attachedFields,
				fieldPosition,
				fieldPositionExtras,
				query
			}, activeOverlays)
				.then(({grids, rows, fields}) => {
					action.fields = fields;
					action.rows = rows;
					action.grids = grids;
					if(grids && grids[0]) {
						action.tableSchemaName = grids[0].tableSchemaName;
					}
					AppDispatcher.dispatch(Immutable.fromJS(action));
					return resolve({grids, rows, fields});
				})
				.catch(reject);
		});
	},

	/**
	 * Sets the outer height including the field label, edit buttons, etc.
	 * Primarily used when getting the height of a container
	 * such as for autofitting
	 * @param {string} renderId The renderId of the item
	 * @param {number} newHeight The height of the item
	 * @param {string} managingScreensize What screensize this is for
	 * @param {number} lastDt When this height was set
	 */
	setGridItemHeight(renderId, newHeight, resizerHeightType, managingScreensize, lastDt) {

		if(heightUpdateTimeout) {
			clearTimeout(heightUpdateTimeout);
		}

		heightsByScreensize[managingScreensize] = heightsByScreensize[managingScreensize] || {};
		if(
			!heightsByScreensize[managingScreensize][renderId] ||
			(heightsByScreensize[managingScreensize][renderId] && heightsByScreensize[managingScreensize][renderId].lastDt <= lastDt)
		) {
			heightsByScreensize[managingScreensize][renderId] = {
				lastDt,
				height: newHeight,
				resizerHeightType
			}
		}

		heightUpdateTimeout = setTimeout(() => {
			// Bulk sets the outer height including the field label, edit buttons, etc.
			// Primarily used when getting the height of a container
			// such as for autofitting
			// Done as bulk updates in a debounce for performance
			// Debounce rate of 500ms increased to 750ms to try and reduce crashiness on Mobile.
			AppDispatcher.dispatch(Immutable.fromJS({
				type: RenderConstants.UPDATE_GRID_ITEM_HEIGHTS_BULK,
				heightsByScreensize
			}));
			heightsByScreensize = {};
		}, 750);

		// Sets the outer height including the field label, edit buttons, etc.
		// Primarily used when getting the height of a container
		// such as for autofitting
		// AppDispatcher.dispatch(Immutable.fromJS({
		// 	type: RenderConstants.UPDATE_GRID_ITEM_HEIGHT,
		// 	renderId,
		// 	height: newHeight,
		// 	managingScreensize,
		// 	lastDt,
		// 	resizerHeightType
		// }));
	},

	/**
	 * Sets the inner height including only the GridLayout
	 * Used by pages and fields when one of its children
	 * is set to fill height
	 * @param {string} renderId The renderId of the item
	 * @param {number} newHeight The height of the item
	 * @param {string} managingScreensize What screensize this is for
	 */
	setGridLayoutHeight(renderId, newHeight, managingScreensize) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.UPDATE_GRID_LAYOUT_HEIGHT,
			renderId,
			height: newHeight,
			managingScreensize
		}));
	},

	updateFieldHeight(renderId, newHeight) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.UPDATE_GRID_LAYOUT_HEIGHT,
			renderId,
			height: newHeight
		}));
	},

	resetFieldHeights(renderIds) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RenderConstants.RESET_FIELD_HEIGHTS,
			renderIds
		}));
	}
};

export default RenderActions;