import React,{Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {size} from 'lodash';

import TableIcon from './table-icon.react';

/**
 * Tooltip selector of options
 *
 * @memberOf TableSelector
 */
export default class TableSelector extends Component {
  /**
   * Creates instance for TableSelector
   *
   * @memberOf TableSelector
   */
  constructor(props) {
    super(props);
    this.state = {showOptions: false};
    this._onClick = this._onClick.bind(this);
    this._onItemClick = this._onItemClick.bind(this);
    this._renderOption = this._renderOption.bind(this);
    this._renderPopover = this._renderPopover.bind(this);
    this._setRef = this._setRef.bind(this);
    this._setStyle = this._setStyle.bind(this);
    this._showOptions = this._showOptions.bind(this);
  }

  /**
   * Callback when component finished mounting
   *
   * @memberOf TableSelector
   */
  componentDidMount() {
    //listen to any click to close popup when clicked outside of popover
    document.body.addEventListener('click', this._onClick);
  }

  /**
   * Callback when component will unmount
   *
   * @memberOf TableSelector
   */
  componentWillUnmount() {
    document.body.removeEventListener('click', this._onClick);
  }

  /**
   * Callback when component finished updating
   *
   * @memberOf TableSelector
   */
  componentDidUpdate() {
    //Force render after html dimensions to positon popover
    if (this.popover && !this._rect) {
      this._button = this.popover.previousSibling.getBoundingClientRect();
      this._rect = this.popover.getBoundingClientRect();
      this.forceUpdate();
      if (this.state.showOptions) {
        ReactDOM.findDOMNode(this.popover).focus();
      }
    }
  }

  /**
   * Render TableSelector
   *
   * @memberOf TableSelector
   */
  render() {
    let { label, required, value } = this.props,
        icon;

	if(typeof(value) === 'string') {
		value = window.TableStore.getByTableSchemaName(value) || {};
		label = value.pluralName;
	}

    if (size(value)) {
      icon = value.icon;
    } else {
      label = 'Choose Table';
      icon = 'database';
    }
    return (
      <div className="d-flex flex-sm-column align-items-center choose-table">
        <TableIcon required={required} label={label} iconClass={icon} onClick={this._showOptions} className="table-selector d-flex flex-sm-column align-items-center"/>
        {this._renderPopover()}
      </div>
    );
  }

  /**
   * private Handle document click event
   *
   * @memberOf TableSelector
   */
  _onClick(e) {
    let {showOptions} = this.state;

    if (showOptions) {
      let element = e.target,
          classes = element.className,
          limit = 3;

      while (!(classes && classes.includes('popover')) || limit <= 0) {
        element = element.parentNode;
        if (!element) {
          this.setState({showOptions: false});
          return;
        }
        classes = element.className;
        limit--;
      }
    }
  }

  /**
   * private Handle item click event
   *
   * @memberOf TableSelector
   */
  _onItemClick(option) {
    let {onChange, fieldSchemaName} = this.props,
        event = {
          target: {
            id: fieldSchemaName,
            value: option.tableSchemaName,
          }
        };

    if (onChange) {
      onChange(event);
    }

    this.setState({showOptions: false});
  }

  /**
   * private Sets reference
   *
   * @memberOf TableSelector
   */
  _setRef(popover) {
    this.popover = popover;
  }

  /**
   * private Sets reference
   *
   * @memberOf TableSelector
   */
  _setStyle() {
    let {left, top, width} = this._rect || {},
      button = this._button || {},
      offsetX = button ? button.left - left + (button.width * .5) : 0,
      x = offsetX - (width * .5),
      y = button ? button.top - top + 30 : 0,
      translate = 'translate('+x+'px,'+y+'px)';

    return {transform: translate};
  }

  /**
   * private Render option
   *
   * @memberOf TableSelector
   */
  _renderOption(option, index) {
    let iconClass = 'fa fa-' + option.icon;
    let { disabled } = this.props;

	// Hard coded exception to the table selector, by DM
	if(option.tableSchemaName === 'securityGroup' && !this.props.allowSecurityGroups) {
		return null;
	}
	
    return (
      <div key={index} className="table-option d-flex align-items-center ml-2 py-2" onClick={(disabled ? null : this._onItemClick.bind(this,option))}>
        <div className={"table-option-icon mr-2 " + iconClass} />
        <small style={{ color: 'var(--theme-text-1)', fontSize: '1rem'}} className="d-block mt-0">{option.pluralName}</small>
      </div>
    );
  }

  /**
   * private Render popover
   *
   * @memberOf TableSelector
   */
  _renderPopover() {
    let {options} = this.props,
        {showOptions} = this.state,
		css = this._setStyle();
		
    if (!showOptions) {
      return null;
    }

	if(!options) {
		options = window.TableStore.getAllArray();
	}
	
	// Sort options by pluralName
	options.sort(function(a,b){
		if(a.pluralName.toLowerCase() < b.pluralName.toLowerCase() ) {
			return -1;
		} 
		return 1;
	});

    return (
      <div ref={this._setRef} style={css} tabIndex="99" className="popover popover-bottom relationship-table-dropdown show" >
        <div className="popover-content d-flex flex-column ">
          {options.map(this._renderOption)}
        </div>
      </div>
    );
  }

  /**
   * private Show options
   *
   * @memberOf TableSelector
   */
  _showOptions() {
    this.setState({showOptions: true});
  }
}

if ('development' === process.env.NODE_ENV) {
  TableSelector.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
	onChange: PropTypes.func,
	required: PropTypes.bool,
    fieldSchemaName: PropTypes.string,
    value: PropTypes.any
  };
}
