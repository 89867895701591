import React from 'react';
import _ from 'lodash';
import MonacoEditor from 'react-monaco-editor';
import BaseSettingForm from './base-setting-form.react';

/**
 * Component for CodeEditor
 * 
 * @class CodeEditor
 * @extends {Component}
 */
export default class CodeEditor extends BaseSettingForm {
	/**
	 * Creates an instance of CodeEditor
	 * 
	 * @param {Object} props
	 * @constructor
	 * 
	 * @memberof CodeEditor
	 */
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	/**
	 * Handles and bubble onChange event from AceEditor
	 */
	onChange(value) {
		let {onChange, fieldSchemaName} = this.props,
			changeEvent = {
				target: {
					id: fieldSchemaName,
					value: value
				}
			};

		if(onChange) {
			onChange(changeEvent);
		}
	}

	/**
	 * Render the CodeEditor
	 * 
	 * @returns React
	 * 
	 * @memberof CodeEditor
	 */
	render() {
		let {value, readOnly} = this.props;
		return(
			<div className="pt-2" style={{ height: '100%' }}>
				<MonacoEditor
					key="VariantEditor"
					language="javascript"
					theme="vs-dark"
					width="100%"
					height="100%"
					value={value}
					options={{
						selectOnLineNumbers: true,
						automaticLayout: true,
						readOnly: readOnly,
					}}
					onChange={this.onChange}
					/>
			</div>
		);
	}
}