/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';
import uuid from 'uuid';
import { AdminSettingsActions, InterfaceActions, MetadataActions } from '../../../actions';
import { AdminSettingsStore, ContextStore, FieldStore, MetadataStore } from '../../../stores';
import UIUtils from '../../../utils/ui-utils';
import ThemeBuilderUtils from '../../../utils/theme-builder-utils';

/**
 * 
 */
class ThemeSettingsChooser extends Component {
	/**
	 * Creates instance of ThemeSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._getSettingsList = this._getSettingsList.bind(this);
		this._onDeleteHandler = this._onDeleteHandler.bind(this);
		this._onSaveAsHandler = this._onSaveAsHandler.bind(this);
	}
	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, ContextStore, FieldStore, MetadataStore];
	}

	/**
	 * Calculate the current state.
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} State!
	 */
	static calculateState(prevState, props) {
		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = 'theme';
		let componentObj = MetadataStore.get(recordId, tableSchemaName) || {};
		let selectedSetting = AdminSettingsStore.getSettingRecordId();
		let componentSettings = [];

		// Default Settings
		let themeSettings = [
			{ recordId: '8f7d983b-43c5-4e6b-a766-b7ac3cff3194', // Name
				sortOrder: 1, required: true  },
			{ recordId: 'a9f933b3-4e25-4ec7-8bc5-0ed830e81328', // Description
				sortOrder: 2 }, 
			{ recordId: '158b379d-932b-4229-ab45-0ebd1bb559f7', // Image
				sortOrder: 3 }, 
			{ recordId: 'cdec96cd-e0c3-495b-af31-98b559727124', // Template
				sortOrder: 4, required: true }, 
			{ recordId: 'b5be73cf-a0e5-4d9b-8c1d-e4c7de8b6b05', // Colors
				sortOrder: 5 }, 
			{ recordId: '20cd351e-81de-49b2-ac57-3a59f9de6acd', // Sizes
				sortOrder: 6 }, 
			{ recordId: '388db87e-baa8-416f-8ea3-0449e67765f6', // Variables
				sortOrder: 7 }, 
			{ recordId: '05b49177-6ef8-4dbc-8260-bb8c907a1831', // CSS
				sortOrder: 8 }, 
			{ recordId: 'd6a6b3b9-db73-453e-a262-a375e80f3173', // Last Updated DT
				sortOrder: 9 }, 
		];

		let hasMissingRequiredSettings = false;

		themeSettings.forEach(themeSetting => {
			let settingId = themeSetting.recordId;
			let settingObj = FieldStore.get(settingId) || {};
			let settingSchemaName = settingObj.fieldSchemaName;

			// Empty/No Value values.
			let valueObj = {};
			if (componentObj[settingSchemaName]) {
				valueObj.value = componentObj[settingSchemaName];
			}

			// Check to see if this setting is required, and has no value
			if(themeSetting.required && (!valueObj.value || valueObj.length === 0)) {
				hasMissingRequiredSettings = true;
			}

			componentSettings.push({
				// Basics
				fieldId: settingId,
				fieldObj: settingObj,

				// Label, Value and Sorting..
				label: settingObj.fieldLabel,
				valueObj: valueObj,
				sortOrder: themeSetting.sortOrder,

				// Used for styling
				settingSelected: (selectedSetting === settingId),
				required: themeSetting.required
			});
		});

		// Sort the results by setting name
		componentSettings.sort(function (a, b) {
			if (a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			return 1;
		});

		let applicationObj = MetadataStore.get(ContextStore.getApplicationId(), 'applications');
		let selectedTheme = applicationObj['selectedTheme'];

		return {
			componentSettings: componentSettings,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			hasMissingRequiredSettings: hasMissingRequiredSettings,
			isSelectedTheme: (selectedTheme === recordId ? true : false),
		}
	}

	/**
	 * Render the compenent
	 * @returns JSX
	 */
	render() {
		let { 
			componentSettings, 
			hasMissingRequiredSettings,
			recordId, tableSchemaName,
			isSelectedTheme
		} = this.state;

		if (!componentSettings.length) {
			return (
				<div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
				</div>
			);
		}

		let settingsList = this._getSettingsList(componentSettings); // component settings

		let saveButton = <button id={'themeSave'} key="submit"  className="btn btn-primary btn-lg w-50 d-flex justify-content-center" 
			form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={(event) => {
				ThemeBuilderUtils.saveTheme(event, recordId, tableSchemaName) }}>Save</button>;
		let saveAsButton = <button key="saveAs" className="btn btn-primary btn-lg w-50 ml-1 d-flex justify-content-center" 
			form="appearance-form" aria-label="Save As" title="Save as a new Name." onClick={this._onSaveAsHandler}>Save As</button>;
		let resetButton = <button id={'themeReset'} key="reset" className="btn btn-warning btn-lg w-50 d-flex justify-content-center" 
			form="appearance-form" aria-label="Reset" onClick={(event) => {
				ThemeBuilderUtils.resetTheme(event, recordId, tableSchemaName)}}>Reset</button>;
		let deleteButton = <button key="delete" className="btn btn-danger btn-lg ml-1 w-50 d-flex justify-content-center disabled" 
			form="appearance-form" aria-label="Delete" onClick={() => { 
				InterfaceActions.notification({ 'level': 'warning', 'message': 'Unable to delete the currently active Theme.' });
			}}>Delete</button>;

		// If this theme is NOT selected, then allow deleting.
		if(!isSelectedTheme) {
			deleteButton = <button key="delete" className="btn btn-danger btn-lg ml-1 w-50 d-flex justify-content-center" 
				form="appearance-form" aria-label="Delete" onClick={this._onDeleteHandler}>Delete</button>;
		}

		let requiredMarker = null;
		if(hasMissingRequiredSettings) {
			requiredMarker = <div className="required-marker mx-4 d-flex"><i className="fa fa-circle d-flex align-items-center" aria-hidden="true"></i> <h4 className="ml-2">Required</h4></div>;
		}

		let iconPath = ContextStore.getUrlMedia() + "/icon-theme.svg";

		return [
			<div key="settings" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">
					{settingsList && settingsList.length
						? <li className='settings-list-header'>
							<div className="d-flex">
								<img height="22" width="22" className="mr-2" src={iconPath} alt="" style={{ marginTop: '0.4rem'}} />
								<h3>Theme Settings</h3>
							</div>
						</li>
						: null
					}
					{settingsList && settingsList.length
						? settingsList
						: <div style={{ marginTop: 0 }} className='no-settings-found'>No Settings Found</div>
					}
					{ requiredMarker }
				</ul>
			</div>,
			<div key="buttons" className="btn-wrapper appearance-btn-wrapper justify-content-start ml-2 flex-column" >
				<div className="d-flex" key="saveAndSaveAs">
					{saveButton}{saveAsButton}
				</div>
				<div className="d-flex mt-1" key="row2">
					{resetButton}{deleteButton}
				</div>
			</div>
		];
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	_getSettingsList(settingsArr) {

		let { fieldType, recordId, tableSchemaName } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label';
			if (setting.settingSelected) {
				labelClassNames += ' selected';
			}

			let componentName = setting.fieldObj.viewVariant;
			if(!componentName) {
				componentName = FieldStore.getDefaultVariantComponentName(
					setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			}
			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			componentProps.dataRecordId = recordId;
			componentProps.dataTtableSchemaName = tableSchemaName;

			// These props will only be used by the fake table setting
			componentProps.customExpressionResult = setting.customExpressionResult;
			componentProps.renderAsHTML = setting.renderAsHTML;

			// For the Date/Time, parse it!
			if(setting.fieldObj.fieldSchemaName === 'lastUpdatedDateTime') {
				if(componentProps.value && componentProps.value.includes('{')) {
					componentProps.value = JSON.parse(componentProps.value);
				} else {
					componentProps.value = '';
				}
			}

			let valueDisplay = <h5 className={`settings-list-value ${componentName}`}>
					{(componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null)}
				</h5>;
			// Checkbox for the value for logic
			if(setting.fieldId === 'd05b9c32-7b7b-4080-9901-a29259812a6c') {
				valueDisplay = componentProps.value && componentProps.value.includes('js') 
					? <span className='fa fa-check'><span className='sr-only'>(Has Logic)</span></span> 
					: null;
			}

			let requiredMarker = null;
			if(setting.required && (!setting.valueObj.value || setting.valueObj.value.length === 0)) {
				requiredMarker = <div style={{ left: '-14px', top: '2px' }} className="required-marker position-absolute"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
			}

			return (<li key={setting.fieldId} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this._onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames}>{requiredMarker}<h4>{setting.label}</h4></div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								{valueDisplay}
							</div>
							{setting.valueObj.source ?
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}>
									{setting.valueObj.source}
								</div>
								: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}

	/**
	 * _onDeleteHandler - Deletes the theme
	 *
	 * @param  {object} event
	 */
	_onDeleteHandler(event) {
		let { recordId } = this.state;
		if(confirm('Are you sure you want to delete this Theme?')) {
			// Display notification to user
			let id = InterfaceActions.stickyNotification({ 'level': 'warning', 'message': 'Deleting Theme...' });
			// Push to database
			MetadataActions.deleteFromDatabasePromise(recordId, 'theme').then(() => {
				InterfaceActions.clearStickyNotification(id);
				UIUtils.closeSettingsPanel();
				MetadataActions.deleteFromStore(recordId, 'theme');
			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to delete theme' });
				console.error('Unable to delete theme:', error);
			})		
		}
	}

	/**
	 * _onSaveAsHandler - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	_onSaveAsHandler(event) {
		let { recordId } = this.state;
		let newName = UIUtils.prompt('Enter a Name for this new Theme:');
		if(!newName) {
			return;
		}

		// Need to add the updated date time
		let themeObj = MetadataStore.get(recordId, 'theme');
		themeObj.lastUpdatedDateTime = JSON.stringify({
			unixTimestamp: (Date.now() / 1000),
			timezone: 'America/New_York'});

		themeObj.recordId = uuid.v4();
		themeObj.name = newName.substring(0, 255);
		themeObj.new = true;

		let id = InterfaceActions.stickyNotification({ title: 'Saving', 'level': 'success', 'message': 'Saving Theme As...' });
		// Push to database
		MetadataActions.pushToDatabasePromise(themeObj, 'theme').then(() => {
				InterfaceActions.clearStickyNotification(id);

				// Switch to the new one.
				// recordId, renderId, attachmentId, 
				// tableSchemaName, parentRecordId, parentTableSchemaName, gridKey, fieldType, selectedOverlay) {
				AdminSettingsActions.onShowComponentDetails(themeObj.recordId, undefined, undefined,
					'theme', undefined, undefined, undefined, undefined, 'theme');
		}).catch(error => {
			InterfaceActions.clearStickyNotification(id);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Theme' });
			console.error('Unable to save Theme Config:', error);
		})
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 */
	_onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		if(!settingFieldObj) {
			console.warn('No setting field object found for field ID %s', settingFieldId);
			return;
		}
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Toggle to select and deselect the Setting
		if(settingSchemaName === 'css') {
			// If logic is already selected
			if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
				// Expand the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
			} else {
				// Hide the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		} else {
			// Expand the Settings Panel
			AdminSettingsActions.onSettingsListHideChange(false);
			if (AdminSettingsStore.getSettingRecordId() === settingFieldId) {
				AdminSettingsActions.onSettingChange('', '');
			} else {
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		}
	}
}
const container = Container.create(ThemeSettingsChooser);
export default container;