import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';
import MapActions from '../../../actions/map-actions';

// Consants
import GroupFilters from '../../../constants/group-filters';
import Help from '../../../constants/help-upgrade-constants';

//Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import ContextStore from '../../../stores/context-store';
import MapStore from '../../../stores/map-store';
import RelationshipStore from '../../../stores/relationship-store';
import TableStore from '../../../stores/table-store';

// Utils
// import MapUtils from '../../../utils/map-utils';
import RelationUtils from '../../../utils/relation-utils';
import UIUtils from '../../../utils/ui-utils';

import { ReactSVG } from 'react-svg';

/**
 * Listing of Relationships for Data Dashboard
 *
 * @class RelationshipsDataDashboardTabContainer
 * @extends {Component}
 */
class RelationshipsDataDashboardTabContainer extends Component {
  /**
   * Creates an instance of RelationshipsDataDashboardTabContainer
   */
  constructor(props) {
    super(props);

    this._renderGroupBy = this._renderGroupBy.bind(this);
    this._onAddRelationship = this._onAddRelationship.bind(this);
    this._onChangeGroupBy = this._onChangeGroupBy.bind(this);
    this._onChangeSearch = this._onChangeSearch.bind(this);
    this._onEditRelationship = this._onEditRelationship.bind(this);
    this._onToggleShow = this._onToggleShow.bind(this);
    this._onToggleAllOpen = this._onToggleAllOpen.bind(this);
    this._onToggleAllClosed = this._onToggleAllClosed.bind(this);
  }

  /**
  * Loads the Stores to watch
  *
  * @static
  * @returns {Array of Object}
  */
  static getStores() {
    return [MapStore, RelationshipStore, AdminSettingsStore];
  }

  /**
   * Returns the current State
   *
   * @static
   * @returns {Object}
   */
  static calculateState(prevState) {
    let groupBy = (MapStore.getGroupBy() ? MapStore.getGroupBy() : GroupFilters.TABLE_NAME);
    let relationships = RelationshipStore.getAllArray();
    let search = MapStore.getSearch(AdminSettingsStore.getActiveDashboardTab());

    if (prevState
      && prevState.relationships === relationships
      && prevState.groupBy === groupBy
      && prevState.search === search) {
      return false;
    }

    // Determine the previous search
    let prevSearch = (prevState && prevState.search ? prevState.search : '')

    return {
      relationships: relationships,
      groupBy: groupBy,
      search: search,
      groupedRelationships: this._performGroupBy(relationships, groupBy, search, prevSearch),
      groupToggleStatus: MapStore.getGroupToggleStatusGroupBy(AdminSettingsStore.getActiveDashboard(), 'relationships'),
      currentRelationshipId: AdminSettingsStore.getRecordId()
    };
  }

  /**
   * Using the group by set in the UI, group the relationships and return
   * an object with the groups as keys and functions array as value.
   * @param {Array} relationships relationships to group
   * @param {String} groupBy How to group the relationships
   * @param {String} search What search to apply, if any.
   * @return Object
   */
  static _performGroupBy(relationships, groupBy, search, prevSearch) {
    let groups = {};

    if (!relationships) {
      return groups;
    }

    let currentRelationshipId = '';
    if (AdminSettingsStore.getTableSchemaName() === 'relationship') {
      currentRelationshipId = AdminSettingsStore.getRecordId();
    }

    // Filter out the relationships that dont have the search term
    relationships = relationships.filter(relationship => {
      if (relationship.recordId === currentRelationshipId) {
        return true;
      } else if (search && search.length > 0) {
        // Relationships Name
        let filterableString = (relationship.lTableSchemaName) ? relationship.lTableSchemaName.toLowerCase() : ' ';
        filterableString += (relationship.rTableSchemaName) ? relationship.rTableSchemaName.toLowerCase() : ' ';
        filterableString += (relationship.ltorLabel) ? relationship.ltorLabel.toLowerCase() : ' ';
        filterableString += (relationship.rtolLabel) ? relationship.rtolLabel.toLowerCase() : ' ';
        return (filterableString.toLowerCase().includes(search.toLowerCase()));
      } else {
        return true;
      }
    });

    // Prepare the final return
    let returnGroups = this.groupBy(relationships, groupBy, search);

    let groupToggleStatus = {};

    // Open the Group that has the active relationship in it.
    if (AdminSettingsStore.getTableSchemaName() === 'relationship' && groupBy === GroupFilters.TABLE_NAME) {
      let relationObj = RelationshipStore.get(AdminSettingsStore.getRecordId());
      if(relationObj) {
        if (relationObj.lTableSchemaName) {
          groupToggleStatus[relationObj.lTableSchemaName] = true
        }
        if (relationObj.rTableSchemaName) {
          groupToggleStatus[relationObj.rTableSchemaName] = true
        }
      }
    }

    // If we have a search...
    if (search.length > 0) {
      Object.keys(returnGroups).forEach((group, index) => {
        if (index <= 2) { // Only open the top 5
          groupToggleStatus[group] = true;
        }
      })
      // If we HAD a search, but no longer do... close them up!
    } else if (search.length === 0 && prevSearch.length > 0) {
      Object.keys(returnGroups).forEach((group, index) => {
        groupToggleStatus[group] = false;
      })
    }

    // @TODO REMOVE!
    /*
    In order to remove this, all of the performGroupBy stuff needs to be moved
    to the MapSTORE - and done in response to the OnDashboardChange 
    [ which should be moved there from the AdminSettingsStore ],
    onSearchChange, and onGroupByChange Actions. If we did this, then these
    components would become very `dumb` rendering components - as they are 
    meant to be.
     */
    if (Object.keys(groupToggleStatus).length) {
      setTimeout(() => {
        MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'relationships', groupToggleStatus);
      }, 0);
    }

    return returnGroups;
  }

  static groupBy(relationships, groupBy, search) {

    // Make sure we're on a valid group by
    if(groupBy !== GroupFilters.TABLE_NAME) {
      console.warn('Relationships Group By only supports grouping by Table Name');
      return {};
    }

    // Fire up the groups!
    let groups = {};

    // If we dont have any relationships, bail early.
    if(relationships.length === 0) { 
      return groups;
    }

    relationships.forEach(relationship => {
      let pushed = false;
      // If our relationship has a left TSN..
      if(relationship.lTableSchemaName && relationship.lTableSchemaName.length){
        // Try and look up the table
        let tableObj = TableStore.getByTableSchemaName(relationship.lTableSchemaName);
        if(tableObj) {
          // Setup the group for this table if its not there...
          if(!groups[relationship.lTableSchemaName]) {
            groups[relationship.lTableSchemaName] = [];
          }

          // Push us onto the array for this table.
          groups[relationship.lTableSchemaName].push(relationship);
          pushed = true;
        } else {
          console.warn('No table object found for:', relationship.lTableSchemaName);
        }
      }

      // If this relationship joins the same table to itself, then only add it once!
      if(relationship.lTableSchemaName === relationship.rTableSchemaName) {
        return;
      }

      // If our relationship has a right TSN..
      if(relationship.rTableSchemaName && relationship.rTableSchemaName.length){
        // Try and look up the table
        let tableObj = TableStore.getByTableSchemaName(relationship.rTableSchemaName);
        if(tableObj) {
          // Setup the group for this table if its not there...
          if(!groups[relationship.rTableSchemaName]) {
            groups[relationship.rTableSchemaName] = [];
          }

          // Push us onto the array for this table.
          groups[relationship.rTableSchemaName].push(relationship);
          pushed = true;
        } else {
          console.warn('No table object found for:', relationship.rTableSchemaName);
        }
      }

      // If we didnt push this relationship onto EITHER table...
      if(!pushed){
        if(!groups['none']) {
          groups['none'] = [];
        }
        groups['none'].push(relationship);
      }
    })

    // If we have no search, then add all tables that are missing.
    if(!search || !search.length) {
      let allTables = TableStore.getAllArray();
      allTables.forEach(table => {
        if(!groups[table.tableSchemaName]) {
          groups[table.tableSchemaName] = [];
        }
      })
    }

    let keyArray = [];
    //Create a sorted array of keys 
    Object.keys(groups).forEach(groupKey => {
      if(groupKey !== 'none') {
        keyArray.push(groupKey);
      }
    });

    // Sort the keys
    keyArray = keyArray.sort(function (a, b) {
      let aTableObj = TableStore.getByTableSchemaName(a) || {};
      let bTableObj = TableStore.getByTableSchemaName(b) || {};
      if (aTableObj.pluralName.toUpperCase() < bTableObj.pluralName.toUpperCase()) {
        return -1;
      }
      return 1;
    });

    // Go back over the sorted keys, and build the new object with its values,
    // But in key order...
    let newGroupedRelationships = {};
    keyArray.forEach(key => {
      newGroupedRelationships[key] = groups[key].sort(function (a, b) {
        if (
          RelationUtils.getRelationName(a.recordId, key).toUpperCase() < 
          RelationUtils.getRelationName(b.recordId, key).toUpperCase()) {
          return -1;
        }
        return 1;
      });
    });

    // Dont forget about None!  It should be at the bottom...
    if(groups['none']) {
      newGroupedRelationships['none'] = groups['none'];
    }

    return newGroupedRelationships;
  }

  /**
   * Render method
   */
  render() {
    let { 
      // groupBy, 
      groupedRelationships, 
      search 
    } = this.state;

    // Loop over the groupedRelationships Object, and call renderGroup
    // for each Group found.
    let relationList = [];
    Object.keys(groupedRelationships).forEach(groupName => {
      let group = this._renderGroupBy(groupedRelationships[groupName], groupName);
      if (group) {
        relationList.push(group);
      }
    });

    let noSearchFound = null;
    if (relationList.length === 0 && search.length) {
      noSearchFound = <li className="no-search-found" style={{ color: 'white' }}><h4>No Results for '{search}' found.</h4></li>
    }

    return (
      <div id="data-map__content" className="map">
        <div className="cd-search-container">
          <div className="d-flex">
            {/* <div style={{ flex: 1 }} className="mr-1">
              <select className="form-control select-group-by" value={groupBy} onChange={this._onChangeGroupBy}>
                <option value={GroupFilters.TABLE_NAME}>Group By Table</option>
              </select>
            </div> */}
            <div title="Search either Name or either Table Technical Name" style={{ flex: 1 }} className="ml-1">
              <input placeholder="Search" className="form-control discussion-search-input" type="text" value={search} onChange={this._onChangeSearch} />
            </div>
          </div>
        </div>
        <div className="section-header" key="expand-collapse-tools">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <div title="Expand All" onClick={this._onToggleAllOpen}>
                <i className="fa fa-plus"></i>
              </div>
              <div>|</div>
              <div title="Collapse All" onClick={this._onToggleAllClosed}>
                <i className="fa fa-minus"></i>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <h5 className="bold">Relationships</h5>
              <div title="Relationship Help" className="info-icon ml-2" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_DATA_RELATIONSHIPS); }}>
                <i className="fa fa-info-circle mr-1"></i>{/* | */}
              </div>
              {/* <div title="Create Relationship" data-toggle="modal" onClick={this._onAddRelationship.bind(this, '')} >
                <i className="fa fa-plus-circle ml-1 mb-1"></i>
              </div> */}
            </div>
          </div>
        </div>
        <div className="list-content-wrapper" key="relationships">
          <ol>
            {relationList}
            {noSearchFound}
          </ol>
        </div>
      </div>
    );
  }

  /**
   * private Render on group of relations - from the list of ALL relations.
   * @param {Array} relations All of the relationships
   * @param {string} groupBy The value to show these relations as grouped by - in this case, the table name.
   */
  _renderGroupBy(relations, groupName) {
    let { groupToggleStatus, currentRelationshipId, groupBy } = this.state;

    // Figure out if this group contains the current page.
    let relationThatIsCurrent = relations.filter(relation => {
      return relation.recordId === currentRelationshipId;
    })

    // Basic JSX for the youAreHereIndicator
    let youAreHereIndicator = (
      <span>
        <i className="fa fa-circle fa-1 selection-marker" aria-hidden="true" />
        <span className="sr-only">(Current)</span>
      </span>
    );

    // Add a Group of relationships for this Table.
    let show = groupToggleStatus[groupName];
    if (relationThatIsCurrent.length && show === undefined) {
      show = true;
    }

    let relationshipList = [];

    if (show) {
      // Find out which table this is...

      // Generate the LI list for all relationships..
      relations.forEach((relation,index) => {
        let iconJSX = null;
        let relationshipName = '';
        switch (groupBy) {
          case GroupFilters.TABLE_NAME: // Set the Icon to the Relationship Icon
            let relationshipIcon = RelationUtils.getRelationIcon(relation.recordId, groupName);
            iconJSX = <img height="10" width="10" className="mr-2" src={relationshipIcon} alt="" />
            relationshipName = RelationUtils.getRelationName(relation.recordId, groupName);
            break;
          default:
            console.warn('Group By', groupBy, 'not supported for icon in group by');
            break;
        }
        if (!relation.recordId) {
          return;
        }

        relationshipList.push(
          <li key={index + relation.recordId} className="table-name-item">
            <div className="list-item-row">
              <div className="d-flex align-items-center">
                <h5 className="d-flex">
                    {iconJSX}
                    {relationshipName}
                </h5>
              </div>
                <div className="d-flex">
                  {(relation.recordId === currentRelationshipId ? youAreHereIndicator : null)}
                  <span onClick={this._onEditRelationship.bind(this, relation.recordId)} title="Update Relationship">
                    <ReactSVG
                      beforeInjection={svg => {
                        svg.setAttribute('viewBox', '0 0 34 34');
                        svg.setAttribute('style', 'width: 16px; height: 16px');
                      }}
                      src={ContextStore.getUrlMedia() + "/icon-records-tables.svg"} />
                  </span>
                </div>
            </div>
          </li>
        );
      }); // End loop over all the relationships.
    }

    let tableSchemaName = groupName;
    let tableIcon = '';
    let tableName = '';
    let tableObj = TableStore.getByTableSchemaName(tableSchemaName);
    if(tableObj) {
      tableIcon = tableObj.icon;
      tableName = tableObj.pluralName;
    }

    // Determine the JSX for the Group Icon
    let groupIconJSX = null;
    switch (groupBy) {
      case GroupFilters.TABLE_NAME:
        // This is a group by table - so lets get the TSN
        if (tableIcon) {
          groupIconJSX = <span className={"fa fa-" + tableIcon}></span>;
        }
        break;
      default:
        console.warn('No Icon Definition for Group By:', groupBy);
        break;
    }

    if(relations.length > 0) {
      // If this is the group that has the current relationship in it, and there is NO 
      // indicator for if the user wants this group open or not... open it.
      return (
        <li key={groupName} className="role-group-item">
          <div className="role-group-name d-flex" onClick={this._onToggleShow.bind(this, groupName, !show)}>
            <div className="mr-1">
              {show
                ? <i title="Collapse" className="fa fa-minus"></i>
                : <i title="Expand" className="fa fa-plus"></i>
              }
            </div>
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex align-items-center">
                {groupIconJSX}
                <h4 className="bold ml-1">{tableName}</h4>
              </div>
              <div className="d-flex">
                {(relationThatIsCurrent.length ? youAreHereIndicator : null)}
                <h4 className="bold">({relations.length})</h4>
                {(groupBy === GroupFilters.TABLE_NAME ?
                  <div title="Create Relationship" onClick={this._onAddRelationship.bind(this, tableSchemaName, undefined)} >
                    <i style={{ color: 'var(--theme-text-1)' }} className="fa fa-plus-circle ml-1"></i>
                  </div>
                  : null)}
              </div>
            </div>
          </div>
          <ol id={groupName} className={'collapse pl-4 pt-2 pr-0 ' + (show ? 'show ' : '') + 'groupby-list'}>
            {relationshipList}
          </ol>
        </li>);
    } else {
      return (
        <li key={groupName} className="role-group-item">
          <div className="role-group-name d-flex">
            <div className="mr-1">
              <i title="No relationships found" style={{ width: '9px', display: 'block' }}></i>
            </div>
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex align-items-center">
                {groupIconJSX}
                <h4 className="bold ml-1">{tableName}</h4>
              </div>
              <div className="d-flex">
                {(relationThatIsCurrent.length ? youAreHereIndicator : null)}
                <h4 className="bold">({relations.length})</h4>
                {(groupBy === GroupFilters.TABLE_NAME ?
                  <div title="Create Relationship" onClick={this._onAddRelationship.bind(this, tableSchemaName, undefined)} >
                    <i style={{ color: 'var(--theme-text-1)' }} className="fa fa-plus-circle ml-1"></i>
                  </div>
                  : null)}
              </div>
            </div>
          </div>
        </li>);
    }
  } // end _renderGroupBy

  /**
   * Handle adding a relationship.
   */
  _onAddRelationship(tableSchemaName) {
    RelationUtils.addNewRelationship(tableSchemaName);
    // Unhide the settings, always
    AdminSettingsActions.onSettingsListHideChange(false);
  }

  /**
   * private Handles change to group by dropdown
   */
  _onChangeGroupBy(event) {
    let groupBy = event.target.value;
    MapActions.groupBy(groupBy);
  }

  /**
   * Handles typing into the search box.
   * @param {object} event Change event
   */
  _onChangeSearch(event) {
    let search = event.target.value;
    MapActions.search(search, AdminSettingsStore.getActiveDashboardTab());
    this.lastSearchKeystroke = Date.now();
  }

  /**
   * Edit this relationship in the Details Pane
   * @param {string} relationId 
   */
  _onEditRelationship(relationId) {
    UIUtils.openSettingsPanel('relationship', relationId, 'relationship');
    
    AdminSettingsActions.onSettingChange('relation-tables', 'f3e86ca3-48ed-474c-a21f-dcb8396ff0bc');

    // Unhide the settings, always
    AdminSettingsActions.onSettingsListHideChange(false);
  }

  /**
   * Toggle one group.
   */
  _onToggleShow(groupLabel, show) {
    MapActions.groupToggle(AdminSettingsStore.getActiveDashboard(), 'relationships', groupLabel, show);
  }

  /**
   * Open all of the groups
   */
  _onToggleAllOpen() {
    let { groupedRelationships, groupToggleStatus } = this.state;
    Object.keys(groupedRelationships).forEach(group => {
      groupToggleStatus[group] = true;
    })
    MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'relationships', groupToggleStatus);
  }

  /**
   * Close all of the groups
   */
  _onToggleAllClosed() {
    let { groupToggleStatus } = this.state;
    Object.keys(groupToggleStatus).forEach(key => {
      groupToggleStatus[key] = false;
    })
    MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'relationships', groupToggleStatus);
  }
}

const container = Container.create(RelationshipsDataDashboardTabContainer);
export default container;
