import keymirror from 'keymirror';

export const ThemeTemplateConstants = keymirror({
	THEME_TEMPLATE_DELETE_FROM_DATABASE: null,
	THEME_TEMPLATE_DELETE_FROM_STORE: null,
	THEME_TEMPLATE_PULL_FROM_DATABASE: null,
	THEME_TEMPLATE_PULL_FROM_DATABASE_ALL: null,
	THEME_TEMPLATE_PULL_ERROR: null,
	THEME_TEMPLATE_PUSH_TO_DATABASE: null,
	THEME_TEMPLATE_PUSH_TO_STORE: null,
	THEME_TEMPLATE_RECEIVE_BROADCAST: null
});