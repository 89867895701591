import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { AdminSettingsStore, FieldStore, FieldTypeStore, PageStore, TableStore, RenderStore } from '../../stores/';
import FieldComponents from '../../utils/field-components.js';
import { FieldActions, PageActions } from '../../actions/';
import QueryLocalSettings  from './query-local-settings.react';
import FieldTypeSettingsUI from '../fieldtype-settings-ui.react';
import UIUtils from '../../utils/ui-utils';

class QuerySetting extends Component {
	/**
	 * Creates instance of QuerySetting
	 *
	 * @memberOf QuerySetting
	 */
	constructor(props) {
		super(props);
		this._onChange = this._onChange.bind(this);
		this._onPaste = this._onPaste.bind(this);

		this.renderLocalContent = this.renderLocalContent.bind(this);
		this.updateGlobalSetting = this.updateGlobalSetting.bind(this);
		this.updateLocalSettings = this.updateLocalSettings.bind(this);
	}
	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, FieldStore, FieldTypeStore, PageStore];
	}
	/**
	 * @static
	 * @param {any} prevState 
	 * @param {any} props 
	 * @returns {Object} - of Query Setting
	 * @memberof QuerySetting
	 */
	static calculateState(prevState, props) {
		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = AdminSettingsStore.getTableSchemaName();
		let settingRecordId = AdminSettingsStore.getSettingRecordId();
		let parentRecordId = AdminSettingsStore.getParentRecordId();
		let parentTableSchemaName = AdminSettingsStore.getParentTableSchemaName();
		let attachmentId =  AdminSettingsStore.getAttachmentId();
		let settingConfig = FieldStore.get(settingRecordId);
		let fieldSettings = FieldStore.getSettings(recordId);
		let fieldSchemaName = '';
		let currentQueryString = '';
		let localValues = [];

		let fieldType = '';
		
		if(tableSchemaName === 'field') {
			let fieldObj = FieldStore.get(recordId);
			fieldType = fieldObj.fieldType;
		}

		if (settingConfig) {
			fieldSchemaName = settingConfig.fieldSchemaName;
			if (fieldSettings[fieldSchemaName]) {
				currentQueryString = fieldSettings[fieldSchemaName];
			}
		}

		/* Places we know that don't have an attachmentId:
		 *   Field Container as a child of a Content Tab
		 */
		if(!attachmentId && recordId) {
			attachmentId = recordId;
			console.warn('AttachmentId not found, using recordId instead. Assuming this is pre-attachmentId setting config.');
		}

		if (parentRecordId && attachmentId && fieldSchemaName) {
			try {
				let childConfiguration;
				if(parentTableSchemaName === 'field') {
					if(attachmentId) {
						childConfiguration = FieldStore.getUpdatedChildConfigurations(parentRecordId, attachmentId, recordId, settingConfig.fieldSchemaName);
					} else {
						childConfiguration = FieldStore.getChildConfigurations(parentRecordId, recordId, settingConfig.fieldSchemaName);
					}
				} else {
					if(attachmentId) {
						childConfiguration = PageStore.getUpdatedChildConfigurations(parentRecordId, attachmentId, recordId, settingConfig.fieldSchemaName);
					} else {
						childConfiguration = PageStore.getChildConfigurations(parentRecordId, recordId, settingConfig.fieldSchemaName);
					}
				}
				if (childConfiguration) {
					localValues = childConfiguration;
				}
			} catch (error) {
				console.error(error.message);
			}
		}
		return {
			'currentQueryString': currentQueryString,
			'recordId': recordId,
			'tableSchemaName': tableSchemaName,
			'fieldSchemaName': fieldSchemaName,
			'fieldType': fieldType,
			'setting': settingConfig,
			'flushBlockly': prevState ? (currentQueryString !== prevState.onChangeValue) : true,
			'onChangeValue': currentQueryString,
			'parentRecordId': parentRecordId,
			'parentTableSchemaName': parentTableSchemaName,
			'localValues': localValues,
			'attachmentId': attachmentId,
		}
	}
	/**
	 * Handles paste event from QueryEditor
	 *
	 * @param {Object} value
	 *
	 * @memberOf QuerySetting
	 */
	_onPaste(value) {
		let { recordId, fieldSchemaName } = this.state;
		value = JSON.stringify(value);
		FieldActions.pushSettingToStore(recordId, fieldSchemaName, value);
		// this.setState({ onChangeValue: value, flushBlockly: false });
	}
	/**
	 * Handles change event from QueryEditor
	 *
	 * @param {Object} queryObj
	 *
	 * @memberOf QuerySetting
	 */
	_onChange(value) {
		let { recordId, fieldSchemaName } = this.state;
		value = value ? JSON.stringify(value) : value;
		this.setState({ onChangeValue: value, flushBlockly: false }, () => {
			FieldActions.pushSettingToStore(recordId, fieldSchemaName, value);
		});
	}

	/**
	 * Save the value as a field is entered
	 *
	 * @param {Object} event
	 *
	 * @memberOf FieldSettings
	 */
	updateGlobalSetting(event) {
		let { recordId, tableSchemaName, setting } = this.state,
		fieldSchemaName = setting.fieldSchemaName,
		value = event.target.value;
		switch (tableSchemaName) {
			case 'field':
				FieldActions.pushSettingToStore(recordId, fieldSchemaName, value);
				break;
			case 'page':
				let pageValue = {};
				pageValue[fieldSchemaName] = value;
				PageActions.pushToStore(recordId, pageValue);
				break;
			default:
				if (tableSchemaName) {
					console.warn('Invalid table schema name ', tableSchemaName);
				}
				break;
		}
	}

	/**
	 * Save the value as a field is entered
	 *
	 * @param {Object} event
	 *
	 * @memberOf FieldSettings
	 */
		updateLocalSettings(event) {
			let {attachmentId, parentRecordId, parentTableSchemaName, setting, recordId } = this.state,
				fieldSchemaName = setting.fieldSchemaName,
				value = event.target.value;
	
			if(!attachmentId) {
				console.error('Attempting to update query local override: AttachmentId not found in state.');
			}

			switch (parentTableSchemaName) {
				case 'page': {
					let childConfiguration = attachmentId ?
					PageStore.getUpdatedChildConfigurations(parentRecordId, attachmentId, recordId, fieldSchemaName) :
					PageStore.getChildConfigurations(parentRecordId, recordId, fieldSchemaName);
				childConfiguration = childConfiguration || [];
					if (value.action === 'delete') {
						if (value.childIndex < childConfiguration.length) {
							childConfiguration.splice(value.childIndex,1);
						}
					} else {
						let newValue = {value: value.value,
							screenSizes: value.screenSizes};
						if (value.childIndex < childConfiguration.length) {
							childConfiguration[value.childIndex] = 
								newValue;
						} else {
							childConfiguration.push(newValue);
						}
	
						//Loop over all the child configuration lines and if any of them are for the
						//same screen size remove the screen size so that we can notend up with an illegal 
						//configuration
						childConfiguration = childConfiguration.map((currentConfiguration, currentIndex) => {
							if (currentIndex !== value.childIndex) {
								currentConfiguration.screenSizes = 
									currentConfiguration.screenSizes.filter((screenSize) => {
										return (!newValue.screenSizes.includes(screenSize));
									});
							}
							return currentConfiguration;
						});
					}
	
					if(Array.isArray(childConfiguration) && childConfiguration.length > 0) {
						PageActions.pushChildConfigurationToStore(parentRecordId, attachmentId ? (attachmentId + '-' + recordId) : recordId, fieldSchemaName, childConfiguration);
					} else {
						PageActions.pushChildConfigurationToStore(parentRecordId, attachmentId ? (attachmentId + '-' + recordId) : recordId, fieldSchemaName, null);
						if(attachmentId) {
							// Clear the old format value as well
							PageActions.pushChildConfigurationToStore(parentRecordId, attachmentId, fieldSchemaName, null);
						}
					}
					break;
				}
				
				case 'field': {
				let childConfiguration = attachmentId ?
					FieldStore.getUpdatedChildConfigurations(parentRecordId, attachmentId, recordId, fieldSchemaName) :
					FieldStore.getChildConfigurations(parentRecordId, recordId, fieldSchemaName);
				childConfiguration = childConfiguration || [];
					if (value.action === 'delete') {
						if (value.childIndex < childConfiguration.length) {
							childConfiguration.splice(value.childIndex,1);
						}
					} else {
						let newValue = {value: value.value,
							screenSizes: value.screenSizes};
						if (value.childIndex < childConfiguration.length) {
							childConfiguration[value.childIndex] = 
								newValue;
						} else {
							childConfiguration.push(newValue);
						}
	
						//Loop over all the child configuration lines and if any of them are for the
						//same screen size remove the screen size so that we can notend up with an illegal 
						//configuration
						childConfiguration = childConfiguration.map((currentConfiguration, currentIndex) => {
							if (currentIndex !== value.childIndex) {
								currentConfiguration.screenSizes = 
									currentConfiguration.screenSizes.filter((screenSize) => {
										return (!newValue.screenSizes.includes(screenSize));
									});
							}
							return currentConfiguration;
						});
					}
					if(Array.isArray(childConfiguration) && childConfiguration.length > 0) {
						FieldActions.pushChildConfigurationToStore(parentRecordId, attachmentId + '-' + recordId, fieldSchemaName, childConfiguration);
					} else {
						FieldActions.pushChildConfigurationToStore(parentRecordId, attachmentId + '-' + recordId, fieldSchemaName, null);
						if(attachmentId) {
							// Clear the old format value as well
							FieldActions.pushChildConfigurationToStore(parentRecordId, attachmentId, fieldSchemaName, null);
						}
					}
					break;
				}
	
				default:
					console.warn('Unsupported Parent Table Schema Name');
			}
		}

	/**
	 * Renders the local override interface
	 * 
	 * @returns Local Settings JSX
	 * 
	 * @memberof QuerySettings
	 */
	renderLocalContent(namedContexts, fieldLabel) {
		let {fieldType, localValues, parentRecordId, recordId, setting, tableSchemaName, parentTableSchemaName} = this.state;
		// Field Container Children of Content Tabs and Content Drop downs 
		// are NOT allowed to have overridden Record Set for Field Values intentionally.
		// Reasons: 
		// 1. Re-using a Tab-Child elsewhere, to necessitate an override of this setting is effectively not possible, and at the very least is not good practice.
		// 2. The code in here for managing renderIds is a mess.
		//
		// As far as we can tell - The query setting changes when the screensize, or option changes.
		// and it gets pushed to the Field Container properly.  However from there, the renderStore
		// has to pick that up and re-generate the records for the renders, which it appears to be doing
		// but for the OLD render ID's - as the fields have now been re-rendered and so they get new render ID's.
		// We basically need to trace all of the renderId's for the Content Tab > Option > Option Result and 
		// either make them sticky, so the renderStore can update the dataRecordId/dataTableSchemaName for the 
		// render, or we need the renderStores recalculation of the results (with the new query) to take in the NEW renders
		// and update them so that the new records are respected.
		//
		// Good luck!

		// Make sure to have a parent Record Id or the setting to render is a fieldType "Relationship to Manage"
		if(!parentRecordId || setting.fieldType === "ce0dbfec-e9d9-4dc3-b39a-456eca2b5282") {
			// Do not allow overrides
			return null;
		}
		if(parentTableSchemaName === 'field') {
			let parentFieldObj = FieldStore.get(parentRecordId);
			// Content Tab or Content DropDown Fields
			if(parentFieldObj.fieldType === "846b747e-25a0-40df-8115-af4a00a1cab5" || 
				parentFieldObj.fieldType === "bb5bedc3-44d1-4e4c-9c40-561a675173b1") {
					// Do not allow overrides
					return null;
			}
		}
		return (<div style={{ overflowY: 'auto' }} className="setting-local-wrapper">
			<QueryLocalSettings 
				namedContexts={namedContexts}
				label={fieldLabel}
				recordId={recordId} 
				fieldType={fieldType} 
				tableSchemaName={tableSchemaName} 
				setting={setting} 
				value={localValues} 
				onChange={this.updateLocalSettings}/>
		</div>);
	}

	/**
	 * @returns - DOM for selected query setting
	 * @memberof QuerySetting
	 */
	render() {
		let { 
			currentQueryString, 
			setting, 
			recordId, 
			tableSchemaName, 
			fieldType, 
			parentTableSchemaName, 
			parentRecordId, 
		} = this.state;

		let settingFieldSchemaName = this.state.setting && this.state.setting.fieldSchemaName;
		let fieldLabel = this.state.setting && this.state.setting.fieldLabel;
		let settingFieldType = this.state.setting && this.state.setting.fieldType;
		let renderId = AdminSettingsStore.getRenderId();
		let componentId = AdminSettingsStore.getRecordId();
		let pageRenderObj = RenderStore.getPageRenderObj(renderId);
		let pageTSN = pageRenderObj ? pageRenderObj.dataTableSchemaName : '';
		
		// set the Table Name for the Override Setting Parent label
		let overrideSettingParentName = '';

		if(parentTableSchemaName === 'page') {
			let pageObj = PageStore.get(parentRecordId);
			overrideSettingParentName = pageObj && pageObj.name;

			if(!overrideSettingParentName) {
				overrideSettingParentName = '[ No Page Name found ]';
			}
		} else if(parentTableSchemaName === 'field') {
			let fieldObj = FieldStore.get(parentRecordId);
			overrideSettingParentName = fieldObj && fieldObj.fieldLabel;

			if(!overrideSettingParentName) {
				overrideSettingParentName = '[ No Field Label found ]';
			}
		}

		if (!setting) {
			return (<div className="select-setting">
						<div className="select-setting-text-wrap">
							Select a Setting to configure on the left.
						</div>
					</div>);
		} else {
			let startingTSN;
			let namedContexts;

			// Generate namedContexts for query settings
			if(setting.fieldType === '45b6601f-02e8-4dcd-acce-9815d094f009') {
				let field = FieldStore.get(componentId);

				// If this is the "Record Set for Field" setting, we actually _don't_ want to use the field's table, because
				// the actual current record is that of the parent field
				// The parent field itself may have an override, so use the value from the render store as the first resort
				if(setting && setting.recordId === 'f0a5b428-65c8-40aa-9e83-e0682381d1a1') {
					let parentComponentType = AdminSettingsStore.getParentTableSchemaName();
					let parentComponentId = AdminSettingsStore.getParentRecordId();

					let renderObj = RenderStore.get(renderId);
					let parentRenderObj = renderObj && renderObj.renderParentId ? RenderStore.get(renderObj.renderParentId) : undefined;
					if(parentRenderObj && parentRenderObj.dataTableSchemaName) {
						startingTSN = parentRenderObj.dataTableSchemaName;
					} else if(parentComponentType && parentComponentId) {
						let parentComponent = parentComponentType === 'field' ? FieldStore.get(parentComponentId) : PageStore.get(parentComponentId);
						startingTSN = parentComponent && parentComponent.tableSchemaName ? parentComponent.tableSchemaName : pageTSN;
					}
				} else if(AdminSettingsStore && FieldStore && AdminSettingsStore.getTableSchemaName() === 'field'){
					startingTSN = field ? field.tableSchemaName : pageTSN;
				}
				let parentComponentId = AdminSettingsStore.getParentRecordId();
				let parentComponentType = AdminSettingsStore.getParentTableSchemaName();
				// We need to get the parent render object and test THAT
				// We don't currently care about page parents
				let parentComponent =  parentComponentType === 'field' ? FieldStore.get(parentComponentId) : undefined;
				// If this field is a List field, use the record set from the list
				// @TODO: What's a better way to do this without having to specifically look for Lists?
				if(parentComponent && parentComponent.fieldType === '9b782b83-4962-4bd6-993c-f72096e02610' && parentComponent.query) {
					let queryReturnTable = FieldComponents.query.getReturnTable(parentComponent.query);
					startingTSN =  queryReturnTable || startingTSN;
				}

				// Now get the pageTSN and startingTSN proper names for use in the labels
				let startingTSNObj = TableStore.getByTableSchemaName(startingTSN);
				let pageTSNObj = TableStore.getByTableSchemaName(pageTSN);

				namedContexts = {
					pagePageRecord: ['Page, Page Record' + (pageTSN ? ' (' + (pageTSNObj ? pageTSNObj.pluralName || pageTSNObj.singularName || pageTSN : pageTSN)  + ')' : ''), 'namedContexts["pagePageRecord"]', pageTSN],
					pageCurrentRecord: ['Page, Current Record' + (startingTSN ? ' (' + (startingTSNObj ? startingTSNObj.pluralName || startingTSNObj.singularName || startingTSN : startingTSN)  + ')' : ''), 'namedContexts["pageCurrentRecord"]', startingTSN]
				};

				// If this is the "Record Set for Field" setting, we actually _don't_ want to use the field's table, because
				// the actual current record is that of the parent field
				if(setting && setting.recordId === 'f0a5b428-65c8-40aa-9e83-e0682381d1a1') {
					namedContexts.startingContext = [
						'DB, Current Record' + (startingTSN ? ' (' + (startingTSNObj ? startingTSNObj.pluralName || startingTSNObj.singularName || startingTSN : startingTSN)  + ')' : ''),
						'namedContexts["startingContext"]',
						startingTSN
					];
				}
			}

			// set the Table Name for the Override Setting Parent label
			let allowLocalOverride = UIUtils.getFieldAllowsOverrides(parentRecordId, settingFieldSchemaName, settingFieldType);

			let localOverrideComponents = ( allowLocalOverride
				? [<h3 key={parentRecordId} className="mb-2">Parent Overrides:</h3>,
				this.renderLocalContent(namedContexts, fieldLabel)]
				: null
			);
			// This needs to be a string, so convert it now that we're done with it
			namedContexts = JSON.stringify(namedContexts);
			return (
				<div id="appearance-settings">
					<div className="appearance-settings-container mx-3">
						<h3 className='my-1'>
							{fieldLabel}
						</h3>
						<div className="setting-global mt-0">
							<p className="mb-2">Click the button to setup your query.</p>
							<div className={"setting-wrapper setting-" + settingFieldSchemaName}>
								<FieldTypeSettingsUI
									namedContexts={namedContexts}
									recordId={recordId} 
									tableSchemaName={tableSchemaName} 
									setting={setting} 
									value={currentQueryString} 
									onChange={this.updateGlobalSetting} 
									fieldType={fieldType} 
									label={fieldLabel}
									skipDescriptiveText={true}
									/>
							</div>
						</div>
						{localOverrideComponents}
					</div>
				</div>);
		}
	}
}

const container = Container.create(QuerySetting);
export default container;
