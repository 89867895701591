import AppearanceAssistantStore from '../stores/appearance-assistant-store';
import FieldTypeStore from '../stores/field-type-store';
import FieldStore from '../stores/field-store';
import TableStore from '../stores/table-store';
import FieldSettingsStore from '../stores/field-settings-store';
import PageStore from '../stores/page-store';
import AppearanceAssistantActions from '../actions/appearance-assistant-actions';
import AssistantResultsActions from '../actions/assistant-results-actions';
import GoogleNLPApi from '../apis/google-nlp-api';
import NlpProcessorObj from './nlp-processor-obj';
import AssistantNLPUtil from './assistant-nlp-utils';
import PageUtils from '../utils/page-utils';
import FieldUtils from '../utils/field-utils';

export default {
	/**
	 * Trigger an Assistant Search, from start to dispatch
	 * 
	 * @param {string} input 
	 */
	runAssistantSearch(currentComponent, parentComponent, input) {

		// Initialize variable for context
		let contextRecordId = currentComponent.componentId;
		
		if(input !== undefined) {
			AppearanceAssistantActions.onInputChange(input);
		} else {
			input = AppearanceAssistantStore.getInput();
		}

		// If we still have no input, just clear the list of results
		if(!input) {
			AssistantResultsActions.updateAnalyzerResults({});
			AssistantResultsActions.updateAssistantResults([]);
			return;
		}

		// AssistantResultsActions.patternSearch(input, settingSchemaName, recordId, tableSchemaName, 
		// 	parentRecordId, parentTableSchemaName, parentLabel);
		
		// Clear timeout to run advanced NLP on change
		clearTimeout(this.advancedNLPTimeout);
		// Set a timeout to run the Google NLP Search to augment the above results if a certain amount of time has passed
		this.advancedNLPTimeout = setTimeout(() => {
			let analyzerResults = {};
			// AssistantResultsActions.googleNLPAPISearch(input);
			
			// Get the results from Google, then pass it in as a NlpProcessorObj
			GoogleNLPApi.getResults(input)
			.then(({response: {syntax: [data], quotedValuesObj, connectionId} = {syntax: []}, responseCode}) => {
				analyzerResults.googleNL = {data, quotedValuesObj};
				var testProcessor = new NlpProcessorObj(data, quotedValuesObj);
				let results = testProcessor.getResults();
				analyzerResults.tokenizer = results;
				return results;
			})
			.then(results => {
				analyzerResults.intentAnalyzer = {};
				let operatorIteratorResults = _operatorIterator(results, currentComponent, parentComponent) || [];
				let analyzerOperatorIteratorResults = [], candidateActions = [];
				operatorIteratorResults.forEach(operatorIteration => {
					candidateActions = candidateActions.concat(operatorIteration.candidateActions);
					let clonedObject = JSON.parse(JSON.stringify(operatorIteration));
					delete clonedObject.candidateActions;
					analyzerOperatorIteratorResults.push(clonedObject);
				});
				analyzerResults.intentAnalyzer.operatorIterator = analyzerOperatorIteratorResults;
			analyzerResults.candidateActions = candidateActions;
			return _reduceCandidatePatterns(candidateActions);
		})
		.then((resultsMap) => {
				let results = resultsMap.map((result, i) => {
					let toReturn = {};
					if(Array.isArray(result)) {
						// Just joining the pattern names into the label will result in capitalization issues and a run-on sentence
						// So we instead build the label with more rules
						let label = '';
						result.forEach((pattern, index) => {
							let name = pattern.name;
							if(index) {
								// Set all patterns but the first to lowercase
								label += name.charAt(0).toLowerCase() + name.slice(1);
							} else {
								// Capitalize the first pattern
								label += name.charAt(0).toUpperCase() + name.slice(1);
							}
							// Join the results appropriately
							if(index < result.length - 2) {
								label += ', then ';
							} else if(index === result.length - 2) {
								label += ', and then ';
							} else {
								label += '.'
							}
							toReturn = {
								resultId: 'combo' + i,
								label: label,
								type: 'combo',
								operation: 'combo',
								childResults: result.map(result => {
									result.parentRecordId = result.parentRecordId ? result.parentRecordId : contextRecordId;
									result.closeSettingsPanel = false;
									return result;
								})
							}
						});
					} else {
						toReturn = {
							resultId: result.patternId,
							label: result.name,
							type: result.operation,
							operation: result.operation,
							patternId: result.patternId
						};
					}
					return toReturn;
				});
				if(!results.length) {
					results = [{
						resultId: 'noresults',
						label: 'No Results Found.',
						type: 'noresults'
					}];
				}
				AssistantResultsActions.updateAnalyzerResults(analyzerResults);
				AssistantResultsActions.updateAssistantResults(results);
			})
			.catch((error) => {
				console.error('Error: ', error);
				let results = [{
					resultId: 'error',
					label: 'Error: ' + (error && error.message ? error.message : JSON.stringify(error)),
					type: 'error'
				}];
				AssistantResultsActions.updateAssistantResults(results);
			});
		}, 250); //Changed from 250 for debugging purposes
	}
}

/**
 * This iterates over the results of the tokenizer and appropriately handles each action object.
 * The main purpose of this method is to find the possible operation matches and then run the entity iterator
 * over each child object.
 * 
 * @TODO: Update this to handle preposition and context points.
 * 
 * @param {array} results The operator results of an NLP tokenizer over which to iterate
 * 
 * @returns {array} Array of results
 */
function _operatorIterator(results, currentComponent, parentComponent) {
	if(!results) {
		return null;
	}
	try {
		results = JSON.parse(JSON.stringify(results));
	} catch(err) {
		console.warn('Failed to clone results object in _operatorIterator. Value was', results);
		results = [];
	}
	let toReturn = {};
	// For each action object
	toReturn = results.map(result => {
		let iteratorEntry = {};
		let actions = AssistantNLPUtil.getOperation(result.action);
		result.objects = result.objects && result.objects.length ? result.objects : [{
			"objectAdjectives": [],
			// "det": actions['Detach'] || actions['Delete'] || actions['Update'] ? "this" : "" // Detach, Delete and Update operations can implicitly apply to the current context point; others cannot
			"det": ''
		}];
		if(result.preps) {
			result.objects.forEach(object => {
				object.preps = object.preps || result.preps;
			});
		}
		let entityResults = result.objects.map(_entityIterator.bind(this, actions, result.action, result.quotedObjs, currentComponent, parentComponent));
		let analyzerResults = [], candidateActions = [];
		entityResults.forEach(entityResult => {
			analyzerResults = analyzerResults.concat(entityResult && entityResult.analyzer ? entityResult.analyzer : []);
			if (entityResult && entityResult.candidateActions) {
				candidateActions.push(entityResult.candidateActions);
			}
		});
		iteratorEntry.operatorSearch = actions;
		iteratorEntry.entityIterator = analyzerResults;
		iteratorEntry.candidateActions = candidateActions;
		return iteratorEntry;
	});

	return toReturn;
}

/**
 * This iterates over each object within an action's objects array,
 * determines the likely operations and remaining keywords, and then 
 * forwards it on appropriately.
 * 
 * @TODO: Update this to handle preposition and context points.
 * 
 * @param {array} results 
 * 
 * @returns {object} Object whose keys include the analyzer results and an array of results meant for processing
 */
function _entityIterator(actions, actionKeyword, defaultQuotedObjs, currentComponent, parentComponent, entity) {
	if(!actions || !entity) {
		return {};
	}
	// let analyzer = {}, fullResults = {};
	let toReturn = {};
	let guesses = AssistantNLPUtil.guessOperation(entity.det || entity.objectUnit, actions);
	toReturn.operatorDisambiguation = Object.assign({}, guesses);
	let objectPhrase = (entity && entity.objectAdjectives ? entity.objectAdjectives.concat([entity.objectUnit]).join(' ').trim() : '');
	if(!Object.keys(guesses).length){
		guesses = actionKeyword === 'relate' ? {
			'Create': 'Create'
		} : {
			'Update': 'Update'
		};
		objectPhrase = actionKeyword ?
			actionKeyword + ' ' + (entity.det === 'this' || entity.det === 'these' ? entity.det + ' ' : '') + objectPhrase :
			objectPhrase;
	} else if (entity.det === 'this' || entity.det === 'these') {
		objectPhrase = entity.det + ' ' + objectPhrase;
	}
	toReturn.objectPhrase = objectPhrase;

	// Get information from prepositions
	let processedPreps = _processPreps(entity.preps, currentComponent, parentComponent);
	let {tableCandidates, quotedObjs: prepQuotedObjs} = processedPreps;

	toReturn.processedPreps = processedPreps;

	// // If no other quoted object was found, it may be from the preposition
	// if(!entity.quotedObjs || !entity.quotedObjs.length) {
	// 	entity.quotedObjs = prepQuotedObjs && prepQuotedObjs.length ? prepQuotedObjs : defaultQuotedObjs;
	// }

	// If prepQuotedObjs were found, they likely supersede other quoted values
	// Which may represent the names of settings or other metadata
	if(prepQuotedObjs && prepQuotedObjs.length) {
		entity.quotedObjs = prepQuotedObjs;	
	}

	let NLPInput = entity.quotedObjs ? entity.quotedObjs.join(' ') : '';
	toReturn.NLPInput = NLPInput;
	let candidateActions = [];
	let patternGuesses = [];
	toReturn.componentTypes = [];
	toReturn.componentSubtypes = [];
	if(guesses && guesses['Create']) {
		// @TODO: Determine if we want to offer options to add and attach to different points
		// Current behavior is to add as a child of the field if the field supports children
		// And add to the page otherwise

		if(NLPInput) {
			objectPhrase = objectPhrase.replace(NLPInput, '');
		}

		let attachPoint = {};

		// Deal with table to which to add field
		if(!tableCandidates || !tableCandidates.length) {
			let tableSchemaName = '';

			// Automatically permit attachment to/detachment from/adding to pages
			if(currentComponent.componentType === 'page' && currentComponent.componentId) {
				tableSchemaName = PageUtils.getDefaultChildTableSchemaName(currentComponent.componentId);
			} else if (currentComponent.componentType === 'field' && currentComponent.componentId) {
				tableSchemaName = FieldUtils.getDefaultChildTableSchemaName(currentComponent.componentId);
			} else {
				console.warn('Unsupported context component.')
			}


			let tableObj = tableSchemaName ? TableStore.getByTableSchemaName(tableSchemaName) : {};

			tableCandidates = [{
				componentType: 'table',
				componentId: tableObj.recordId,
				score: 0
			}];
		}

		// Deal with point to which to attach field (if there is one)
		// Automatically permit attachment to/detachment from/adding to pages
		if(currentComponent.componentType === 'page' && currentComponent.componentId) {
			attachPoint = currentComponent;
		} else if (currentComponent.componentType === 'field' && currentComponent.componentId) {
			// If the current field has child fields, we want to attach this as a child.
			// Otherwise, attach it on the same level as the parent.
			let fieldObj = FieldStore.get(currentComponent.componentId) || {};
			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType) || {};
			// Forbid attaching to content tab & dropdown fields, as it *will* break
			if (fieldTypeObj.hasChildSettings && fieldObj.fieldType !== '846b747e-25a0-40df-8115-af4a00a1cab5' && fieldObj.fieldType !== 'bb5bedc3-44d1-4e4c-9c40-561a675173b1') {
				attachPoint = currentComponent;
			} else {
				attachPoint = parentComponent;
			}
		} else {
			console.warn('Unsupported context component.')
		}


		let createResults = _getCreatePatterns(objectPhrase, Object.assign({}, entity, {operation: 'create'}), currentComponent, parentComponent);
		let possibleRoles = AssistantNLPUtil.guessFieldRoles(attachPoint);

		toReturn.componentTypes = toReturn.componentTypes.concat(createResults.componentTypes || []);
		toReturn.componentSubtypes = toReturn.componentSubtypes.concat(createResults.subTypes || []);
		let preparedCandidates = [];
		createResults.patternGuesses = createResults.patternGuesses ? createResults.patternGuesses : [];

		createResults.patternGuesses.forEach(patternGuess => {
			possibleRoles = patternGuess.currentComponentInfo.componentType === 'field' ? possibleRoles : [{label: '', value: ''}];
			// Special handling for navigation tabs, whose role is not location-dependent
			if(patternGuess.componentSubtype === 'cd0ee38e-d63f-44d2-b02b-44376fcc7c2e') {
				possibleRoles = [{
					value: [],
					label: ''
				}, {
					value: ['primaryNavigation'],
					label: 'Primary Navigation'
				}];
			}
			let remainingKeywords = patternGuess.remainingKeywords ? patternGuess.remainingKeywords.trim() : '';
			let updateGuesses = [];
			if(remainingKeywords) {
				updateGuesses = _getUpdatePatterns(Object.assign({}, patternGuess, {operation: 'update', NLPInput: NLPInput}), currentComponent, parentComponent);
			}
			tableCandidates.forEach(candidate => {
				possibleRoles.forEach(role => {
					let tableObj = TableStore.get(candidate.componentId) || {};
					let tableSingularName = tableObj.singularName;
					let tableSchemaName = tableObj.tableSchemaName;
					let name = (['table', 'relationship'].indexOf(patternGuess.componentSubtype) > -1) ? patternGuess.patternName : patternGuess.patternName + ' on the ' + tableSingularName + ' table';
					// Builds searchResult object for Assistant Processor
					let createGuessFormatted = {
						operation: patternGuess.operation,
						// No need to clutter the interface with the "with" clause unless it has multiple possible roles
						name: name,
						methodInfo: patternGuess.methodInfo,
						currentTableInfo: {
							tableSchemaName
						},
						currentParentInfo: attachPoint,
						currentComponentInfo: patternGuess.currentComponentInfo ? patternGuess.currentComponentInfo : {
							componentType: patternGuess.currentComponentInfo.componentType,
							componentSubtype: patternGuess.componentSubtype
						},
						// type: patternGuess.type,
						// patternId: patternGuess.patternId,
						// roles: role.value,
						// NLPInput: patternGuess.NLPInput,
						// withCRUD: patternGuess.withCRUD,
						// parentRecordId: attachPoint.recordId,
						// parentComponentType: attachPoint.schemaName,
						// tableSchemaName: tableSchemaName
					};

					if(patternGuess.currentComponentInfo.componentType === 'field' && role.value && role.value.length) {
						let toPush = {
							operation: 'update',
							patternName: 'Set Roles on the field to "' + role.label + '"',
							methodInfo: {
								NLPDisplayName: role.label,
								NLPInput: role.value.join(','),
								method: 'patternless',
								settingSchemaName: 'roles',
								settingId: '73f635e9-1d8f-4fb5-bc54-6cf40f27db4e'
							}
						};
						if(!updateGuesses.length) {
							updateGuesses.push([toPush]);
						} else {
							updateGuesses = updateGuesses.map(updateGuess => {
								updateGuess.unshift(toPush);
								return updateGuess;
							});
						}
					}

					let createGuessesFormatted = [];
					if(updateGuesses && updateGuesses.length) {
						updateGuesses.forEach(updateGuessSet => {
							let updateGuessesFormatted = updateGuessSet.map(updateGuess => {
								let label = 'the ' + (patternGuess.currentComponentInfo.componentType || 'field');
								if(patternGuess.currentComponentInfo.componentType === 'field' && patternGuess.currentComponentInfo.componentId) {
									let settings = FieldSettingsStore.getSettings(patternGuess.currentComponentInfo.componentId);
									label = settings && settings.fieldLabel ? settings.fieldLabel : label;
								}
								// Builds searchResult object for Assistant Processor
								return {
									operation: updateGuess.operation,
									name: updateGuess.patternName.replace('<<NLPValue>>', '"' + (updateGuess.methodInfo.NLPDisplayName || updateGuess.methodInfo.NLPInput) + '"').replace('<<FieldLabel>>', label),
									methodInfo: updateGuess.methodInfo,
									currentTableInfo: {
										tableSchemaName: tableSchemaName
									},
									// type: updateGuess.type,
									// patternId: updateGuess.patternId,
									// settingSchemaName: updateGuess.settingSchemaName,
									// NLPInput: updateGuess.NLPInput,
								};
							});
							createGuessesFormatted.push([createGuessFormatted].concat(updateGuessesFormatted));
						});
					} else {
						createGuessesFormatted.push([createGuessFormatted]);
					}
					patternGuesses.push([patternGuess].concat(updateGuesses));
					preparedCandidates = preparedCandidates.concat(createGuessesFormatted);
				});
			});
		});
		candidateActions = candidateActions.concat(preparedCandidates);
	}

	if(guesses && guesses['Delete']) {
		let deleteResults = _getDeleteResults(objectPhrase, currentComponent, parentComponent) || [];
		patternGuesses.push(deleteResults);
		let preparedCandidates = deleteResults.map(deleteGuess => {
			// Builds searchResult object for Assistant Processor
			return [{
				operation: deleteGuess.operation,
				name: (['field', 'page'].indexOf(deleteGuess.currentComponentInfo.componentType) > -1) ?
					'Delete ' + deleteGuess.currentComponentInfo.componentName + ' ' + deleteGuess.currentComponentInfo.componentType + ' from ' + deleteGuess.currentTableInfo.tableSingularName + ' table' :
					'Delete ' + deleteGuess.currentComponentInfo.componentName + ' ' + deleteGuess.currentComponentInfo.componentType,
				methodInfo: deleteGuess.methodInfo,
				currentTableInfo: deleteGuess.currentTableInfo,
				currentComponentInfo: deleteGuess.currentComponentInfo,
			}];
		});
		candidateActions = candidateActions.concat(preparedCandidates);
	}

	if(guesses && guesses['Detach']) {
		let detachResults = _getDetachResults(objectPhrase, currentComponent, parentComponent) || [];
		patternGuesses.push(detachResults);
		let preparedCandidates = detachResults.map(detachGuess => {
			// Builds searchResult object for Assistant Processor
			return [{
				operation: detachGuess.operation,
				name: 'Detach ' + detachGuess.componentName + ' from ' + detachGuess.parentLabel + ' ' + detachGuess.parentComponentType,
				methodInfo: {
					method: 'patternless',
				},
				currentTableInfo: {
					tableSchemaName: detachGuess.tableSchemaName,
				},
				currentParentInfo: {
					componentType: detachGuess.parentComponentType,
					componentId: detachGuess.parentComponentId,
				},
				currentComponentInfo: {
					componentType: 'field',
					// componentName: detachGuess.componentName,
					componentId: detachGuess.componentId,
				},
			}];
		});
		candidateActions = candidateActions.concat(preparedCandidates);
	}

	if(guesses && guesses['Attach']) {
		let attachResults = _getAttachResults(objectPhrase, currentComponent, parentComponent) || [];
		patternGuesses.push(attachResults);
		let preparedCandidates = [];
		attachResults.forEach(attachGuess => {
			// Builds searchResult object for Assistant Processor
			let attachInfo = {
				operation: attachGuess.operation,
				name: 'Attach ' + attachGuess.componentName + ' to ' + attachGuess.parentLabel + ' ' + attachGuess.parentComponentType,
				methodInfo: {
					method: 'patternless',
				},
				currentTableInfo: {
					tableSchemaName: attachGuess.tableSchemaName,
				},
				currentParentInfo: {
					componentType: attachGuess.parentComponentType,
					componentId: attachGuess.parentComponentId
				},
				currentComponentInfo: {
					componentType: 'field',
					componentName: attachGuess.componentName,
					componentId: attachGuess.componentId,
				},
				// roles: attachGuess.roles
			};
			let roles = AssistantNLPUtil.guessFieldRoles(attachInfo.currentParentInfo, attachGuess.componentId);
			roles.forEach(role => {
				let toReturn = [attachInfo];
				if(role && role.value && role.value.length) {
					toReturn.push({
						operation: 'update',
						name: 'Set Roles on the field to "' + role.label + '"',
						methodInfo: {
							NLPDisplayName: role.label,
								NLPInput: role.value.join(','),
								method: 'patternless',
								settingSchemaName: 'roles',
								settingId: '73f635e9-1d8f-4fb5-bc54-6cf40f27db4e'
						},
						currentComponentInfo: Object.assign({}, attachInfo.currentComponentInfo)
					});
				}
				preparedCandidates.push(toReturn);
			});
		});
		candidateActions = candidateActions.concat(preparedCandidates);
	}

	if(guesses && guesses['Update']) {
		if(NLPInput) {
			objectPhrase = objectPhrase.replace(NLPInput, '');
		}
		let updateCandidates = _getUpdateCandidates(objectPhrase, currentComponent, parentComponent);
		let preparedCandidates = [];
		updateCandidates.forEach(patternGuess => {
			let remainingKeywords = patternGuess.remainingKeywords ? patternGuess.remainingKeywords.trim() : NLPInput;
			let updateGuesses = [];
			if(remainingKeywords) {
				updateGuesses = _getUpdatePatterns(Object.assign({}, patternGuess, {
					operation: 'update',
					methodInfo: Object.assign({NLPInput}, patternGuess.methodInfo)
					// NLPInput: NLPInput
				}), currentComponent, parentComponent)
			}
			let allUpdateGuessesFormatted = [];
			if(updateGuesses && updateGuesses.length) {
				updateGuesses.forEach(updateGuessSet => {
					let updateGuessesFormatted = updateGuessSet.map(updateGuess => {
						let label = 'the ' + (updateGuess.currentComponentInfo && updateGuess.currentComponentInfo.componentType ? updateGuess.currentComponentInfo.componentType : 'field');
						if(updateGuess.currentComponentInfo && updateGuess.currentComponentInfo.componentType === 'field' && updateGuess.currentComponentInfo.componentId) {
							let settings = FieldSettingsStore.getSettings(updateGuess.currentComponentInfo.componentId);
							label = settings && settings.fieldLabel ? settings.fieldLabel : label;
						}
						// Builds searchResult object for Assistant Processor
						let toReturn = {
							operation: updateGuess.operation,
							name: updateGuess.patternName
							// Replace the <<NLPValue>> code with either the NLPDisplayName or the raw NLPInput value
							// Show 'undefined' if updateGuess.methodInfo does not exist
								.replace('<<NLPValue>>',
									'"' + (updateGuess.methodInfo ?
									(updateGuess.methodInfo.NLPDisplayName || updateGuess.methodInfo.NLPInput) :
									'undefined') + '"')
								// Replace the <<FieldLabel>> code with the field label, trimmed
								.replace('<<FieldLabel>>', label.trim()),
							methodInfo: updateGuess.methodInfo,
							currentComponentInfo: updateGuess.currentComponentInfo,
							// type: updateGuess.type,
							// patternId: updateGuess.patternId,
							// settingSchemaName: updateGuess.settingSchemaName,
							// NLPInput: updateGuess.NLPInput,
							// fieldId: updateGuess.componentId
						};
						return toReturn;
					});
					allUpdateGuessesFormatted.push(updateGuessesFormatted);
				});
			}
			patternGuesses.push([patternGuess].concat(updateGuesses));
			preparedCandidates = preparedCandidates.concat(allUpdateGuessesFormatted);
		});
		candidateActions = candidateActions.concat(preparedCandidates);
	}

	toReturn.patternGuesses = patternGuesses;

	// Each entry in the array should be an array of objects consisting of a possible create action + any subsequent update actions
	// This represents a combinatorial space

	return {analyzer: toReturn, candidateActions};
}

/**
 * Method which finds the valid attach points based on the currently selected component.
 * Basically, considers whether there is a parent (and includes it if there is),
 * and whether the current component allows children (and includes it if it does).
 * 
 * @returns {array} Array of valid attachment points.
 */
function _getValidAttachPoints(contextComponent, parentComponent) {
	let attachPoints = [];

	// Automatically permit attachment to/detachment from/adding to pages
	if(contextComponent.componentType === 'page' && contextComponent.componentId) {
		attachPoints.push(contextComponent);
	} else if (contextComponent.componentType === 'field' && contextComponent.componentId) {
		let fieldObj = FieldStore.get(contextComponent.componentId) || {};
		let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType) || {};
		if (fieldTypeObj.hasChildSettings && fieldObj.fieldType !== '846b747e-25a0-40df-8115-af4a00a1cab5' && fieldObj.fieldType !== 'bb5bedc3-44d1-4e4c-9c40-561a675173b1') {
			attachPoints.push(contextComponent);
		}
	}

	// Automatically permit attachment to/detachment from/adding to valid parents.
	if(parentComponent.componentType && parentComponent.componentId) {
		if(parentComponent.componentType === 'field') {
			// Disallow attaching to content tab and content dropdown fields
			let parentField = FieldStore.get(parentComponent.componentId) || {};
			if(parentField.fieldType !== '846b747e-25a0-40df-8115-af4a00a1cab5' && parentField.fieldType !== 'bb5bedc3-44d1-4e4c-9c40-561a675173b1') {
				attachPoints.push(parentComponent);
			}
		} else {
			attachPoints.push(parentComponent);
		}
	}

	return attachPoints;
}

/**
 * Get the valid attach results from an objectPhrase.
 * 
 * 
 * @param {string} objectPhrase The keywords being matched.
 * 
 * @returns {array} Array of results
 */
function _getAttachResults(objectPhrase, contextComponent, parentComponent) {
	let toReturn = [];
	let attachPoints = _getValidAttachPoints(contextComponent, parentComponent);
	// The idea here is that when attaching fields to/detaching fields from a page, it will work regardless of the selected context point.
	let metadataMatches = AssistantNLPUtil.getMetadataMatches(objectPhrase, 'attach', contextComponent, parentComponent, attachPoints, ['field']) || [];
	// Need to also get the componentSubtype of each one and the componentSubtypeName
	metadataMatches.forEach(match => {
		let subtype = '';
		let subtypeName = '';
		let componentName = '';
		let tableSchemaName = '';
		// This only really makes sense for fields at this time.
		switch(match.componentType) {
			case 'field':
				let fieldObj = FieldStore.get(match.componentId) || {};
				let settingsObj = FieldStore.getSettings(match.componentId) || {};
				componentName = settingsObj.fieldLabel || '';
				subtype = fieldObj.fieldType || '';
				tableSchemaName = fieldObj.tableSchemaName;
				let fieldTypeObj = FieldTypeStore.get(subtype) || {};
				subtypeName = fieldTypeObj.name || '';
					toReturn.push(Object.assign({},
							match,
							{
								operation: 'attach',
								componentSubtype: subtype,
								componentSubtypeName: subtypeName,
								componentName: componentName,
								tableSchemaName: tableSchemaName
							}
						));
						break;
						default:
						console.warn('No support yet for attachment with componentType', match.componentType);
						return {};
					}
				});
	return toReturn;
}

/**
 * Get the valid delete candidates for an input.
 * 
 * 
 * @param {string} objectPhrase  The keywords being matched.
 * 
 * @returns {array} Array of results
 */
function _getDeleteResults(objectPhrase, contextComponent, parentComponent) {
	let toReturn = [];
	let componentTypes = AssistantNLPUtil.getComponentType(objectPhrase, contextComponent, parentComponent);
	
	let metadataMatches = [];
	
	if(!componentTypes || !componentTypes.length) {
		metadataMatches = AssistantNLPUtil.getMetadataMatches(objectPhrase, 'delete', contextComponent, parentComponent, null, ['page', 'field', 'table', 'relationship']) || [];
	} else {
		componentTypes.forEach(componentObject => {
			let {remainingKeywords, componentType} = componentObject;
			let localMatches = AssistantNLPUtil.getMetadataMatches(remainingKeywords, 'delete', contextComponent, parentComponent, null, [componentType]) || [];
			metadataMatches = metadataMatches.concat(localMatches);
		});
	}

	// metadataMatches = AssistantNLPUtil.getMetadataMatches(objectPhrase, 'delete', contextComponent, parentComponent, null, ['page', 'field', 'table']) || [];
	// Need to also get the componentSubtype of each one and the componentSubtypeName
	toReturn = metadataMatches.map(match => {
		let subtype = '';
		let subtypeName = '';
		let componentName = '';
		let tableSchemaName = '';
		let tableSingularName = '';
		switch(match.componentType) {
			case 'field': {
				let fieldObj = FieldStore.get(match.componentId) || {};
				let settingsObj = FieldStore.getSettings(match.componentId) || {};
				componentName = settingsObj.fieldLabel || '';
				subtype = fieldObj.fieldType || '';
				tableSchemaName = fieldObj.tableSchemaName;
				let tableSchemaNameObj = TableStore.getByTableSchemaName(tableSchemaName) || {};
				tableSingularName = tableSchemaNameObj.singularName ? tableSchemaNameObj.singularName : '';
				let fieldTypeObj = FieldTypeStore.get(subtype) || {};
				subtypeName = fieldTypeObj.name || '';
			}
			break;
			case 'page': {
				let pageObj = PageStore.get(match.componentId) || {};
				componentName = pageObj.name;
				subtype = 'page';
				tableSchemaName = pageObj.tableSchemaName;
				let tableSchemaNameObj = TableStore.getByTableSchemaName(tableSchemaName) || {};
				tableSingularName = tableSchemaNameObj.singularName ? tableSchemaNameObj.singularName : '';
				break;
			}
			case 'table': {
				let tableObj = TableStore.get(match.componentId) || {};
				componentName = tableObj.singularName || tableObj.pluralName || tableObj.tableSchemaName;
				subtype = 'table';
				tableSchemaName = tableObj.tableSchemaName;
				tableSingularName = tableObj.singularName || tableObj.tableSchemaName;
				break;
			}
			case 'relationship': {
				componentName = match.componentName;
				subtype = 'relationship';
				break;
			}
			default:
				console.warn('No support yet for componentType', match.componentType);
		}
		// Combine match information with assistant processing information and assign to a new object to de-reference
		return Object.assign(
			{},
			match, 
			{
				operation: 'delete',
				methodInfo: {
					method: 'patternless'
				},
				currentTableInfo: {
					tableSchemaName,
					tableSingularName
				},
				currentComponentInfo: {
					componentType: match.componentType,
					componentSubtype: subtype,
					componentSubtypeName: subtypeName,
					componentName: componentName,
					componentId: match.componentId
				},
			});
	});
	return toReturn;
}

/**
 * Get the valid attach candidates for an input.
 * 
 * 
 * @param {string} objectPhrase  The keywords being matched.
 * 
 * @returns {array} Array of results
 */
function _getDetachResults(objectPhrase, contextComponent, parentComponent) {
	let toReturn = [];
	let attachPoints = _getValidAttachPoints(contextComponent, parentComponent);
	// If there is no parent ID, then attach here.
	// The idea here is that when attaching fields to/detaching fields from a page, it will work regardless of the selected context point.
	// @TODO: Deal with attachment/detachment for fields which have children.
	let metadataMatches = AssistantNLPUtil.getMetadataMatches(objectPhrase, 'detach', contextComponent, parentComponent, attachPoints, ['field']) || [];
	// Need to also get the componentSubtype of each one and the componentSubtypeName
	metadataMatches.forEach(match => {
		let subtype = '';
		let subtypeName = '';
		let componentName = '';
		let tableSchemaName = '';
		// This only really makes sense for fields at this time.
		switch(match.componentType) {
			case 'field':
				let fieldObj = FieldStore.get(match.componentId) || {};
				let settingsObj = FieldStore.getSettings(match.componentId) || {};
				componentName = settingsObj.fieldLabel || '';
				subtype = fieldObj.fieldType || '';
				tableSchemaName = fieldObj.tableSchemaName;
				let fieldTypeObj = FieldTypeStore.get(subtype) || {};
				subtypeName = fieldTypeObj.name || '';
					toReturn.push(Object.assign({},
							match,
							{
								operation: 'detach',
								componentName: componentName,
								tableSchemaName: tableSchemaName,
								currentComponentInfo: {
									componentType: match.componentType,
									componentSubtype: subtype,
									componentId: match.componentId,
									componentSubtypeName: subtypeName
								},
								currentParentInfo: {
									componentType: match.parentComponentType,
									componentId: match.parentComponentId
								}
							}
						));
						break;
					default:
						console.warn('No support yet for detaching with componentType', match.componentType);
						return {};
					}
				});
	return toReturn;
}

/**
 * Get the valid update candidates for an input.
 * This only applies to existing components. It does not find patterns
 * for them; that's found using _getUpdatePatterns
 * 
 * @param {string} objectPhrase  The keywords being matched.
 * 
 * @returns {array} Array of results
 */
function _getUpdateCandidates(objectPhrase, contextComponent, parentComponent) {
	let toReturn = [];

	let metadataMatches = AssistantNLPUtil.getMetadataMatches(objectPhrase, 'update', contextComponent, parentComponent, null, ['field', 'page']) || [];
	// Need to also get the componentSubtype of each one and the componentSubtypeName
	toReturn = metadataMatches.map(match => {
		let subtype = '';
		let subtypeName = '';
		let componentName = '';
		let tableSchemaName = '';
		let tableSingularName = '';
		switch(match.componentType) {
			case 'field': {
				let fieldObj = FieldStore.get(match.componentId) || {};
				let settingsObj = FieldStore.getSettings(match.componentId) || {};
				componentName = settingsObj.fieldLabel || '';
				subtype = fieldObj.fieldType || '';
				tableSchemaName = fieldObj.tableSchemaName;
				let tableSchemaNameObj = TableStore.getByTableSchemaName(tableSchemaName) || {};
				tableSingularName = tableSchemaNameObj.singularName ? tableSchemaNameObj.singularName : '';
				let fieldTypeObj = FieldTypeStore.get(subtype) || {};
				subtypeName = fieldTypeObj.name || '';
				break;
			}
			case 'page': {
				let pageObj = PageStore.get(match.componentId) || {};
				componentName = pageObj.name;
				subtype = 'page';
				subtypeName = 'page';
				tableSchemaName = pageObj.tableSchemaName;
				let tableSchemaNameObj = TableStore.getByTableSchemaName(tableSchemaName) || {};
				tableSingularName = tableSchemaNameObj.singularName ? tableSchemaNameObj.singularName : '';
				break;
			}
			default:
				console.warn('No support yet for componentType', match.componentType);
		}
		return Object.assign({}, match, {
			operation: 'update',
			currentComponentInfo: {
				componentType: match.componentType,
				componentSubtype: subtype,
				componentSubtypeName: subtypeName,
				componentId: match.componentId,
				componentName: componentName,
			},
			currentTableInfo: {
				tableSchemaName: tableSchemaName,
				tableSingularName: tableSingularName
			}
		});
	});
	return toReturn;
}

/**
 * Get the valid update results for an entity based off of the remainingKeywords and objectPhrase parameter.
 * 
 * @param {string} objectPhrase  The keywords being matched.
 * @param {object} entity Object with information about the entity being updated (if provided)
 */
function _getUpdatePatterns(entity, currentComponent, parentComponent) {
	let contextRecordId = currentComponent.componentId,
		contextSchemaName = currentComponent.componentType,
		parentComponentId = parentComponent.componentId,
		parentComponentType = parentComponent.componentType;
	
		if(!entity) {
			// Assume the entity is the current context.
			// Get its component type and subtype information.
			entity = {
				componentId: contextRecordId,
				componentType: contextSchemaName
			};
			if(contextSchemaName === 'field') {
				let fieldObj = FieldStore.get(contextRecordId);
				entity.currentComponentInfo = entity.currentComponentInfo ? entity.currentComponentInfo : {};
				entity.currentComponentInfo.componentSubtype = fieldObj ? fieldObj.fieldType : '';
			}
		}

		let patternInfo = {
			remainingKeywords: entity.remainingKeywords,
			NLPInput: entity.methodInfo && entity.methodInfo.NLPInput ? entity.methodInfo.NLPInput : '',
			componentType: entity.currentComponentInfo && entity.currentComponentInfo.componentType ? entity.currentComponentInfo.componentType : '',
			componentSubtype: entity.currentComponentInfo && entity.currentComponentInfo.componentSubtype ? entity.currentComponentInfo.componentSubtype : '',
			componentId: entity.currentComponentInfo && entity.currentComponentInfo.componentId ? entity.currentComponentInfo.componentId : '',
			parentComponentType,
			parentComponentId,
			operation: entity.operation
		};

		// This will always be an array of objects
		let patterns = AssistantNLPUtil.getPatternMatches(patternInfo) || [];

		let settingUpdatesObj = AssistantNLPUtil.getSettingMatches(patternInfo) || {};
		let settingUpdates = Object.keys(settingUpdatesObj).map(key => settingUpdatesObj[key]);

		let topPatternScore = patterns && patterns[0] && patterns[0].score ? patterns[0].score : 0;
		let topSettingScore = settingUpdates && settingUpdates[0] && settingUpdates[0].score ? settingUpdates[0].score : 0;

		// @TODO: Fix scoring issue
		if (topPatternScore === topSettingScore) {
			// If they have the same score, merge them together
			patterns = patterns.concat(Object.keys(settingUpdates).map(key => settingUpdates[key]));
		} else if (topSettingScore > topPatternScore) {
			// If the settings score better, use them
			patterns = Object.keys(settingUpdates).map(key => settingUpdates[key]);
		}
		
		// No special case needed if the patterns score better
		

		let toReturn = [];

		patterns.forEach(pattern => {
			let newPatterns = [];
			if(pattern.remainingKeywords.trim()) {
				// This will always be an array of arrays of objects
				newPatterns = _getUpdatePatterns(pattern, currentComponent, parentComponent) || [];
			}
			if(newPatterns && newPatterns.length) {
				newPatterns.forEach(newPattern => {
					toReturn.push([pattern].concat(newPattern));
				});
			} else {
				toReturn.push([pattern]);	
			}
		});

		// toReturn = patterns.map(pattern => [pattern]);
		return toReturn;
}

/**
 * From an input objectPhrase, get the likely componentTypes, componentSubtypes, and best guess Create patterns.
 * 
 * @param {string} objectPhrase The keywords being matched.
 * 
 * @returns {array} Array of results
 */
function _getCreatePatterns(objectPhrase, entity, currentComponent, parentComponent) {
	let contextComponentId = currentComponent.componentId,
		contextComponentType = currentComponent.componentType,
		parentComponentId = currentComponent.componentId,
		parentComponentType = currentComponent.componentType;
	
	let NLPInput = entity && entity.quotedObjs && entity.quotedObjs[0] ? entity.quotedObjs[0] : '';
	let preps = entity && entity.preps && entity.preps ? entity.preps : null;

	// Get potential component types (e.g., field, table, etc.)
	let componentTypes = AssistantNLPUtil.getComponentType(
		objectPhrase,
		{
			componentType: contextComponentType,
			componentId: contextComponentId
		},
		{
			componentType: parentComponentType,
			componentId: parentComponentId
		}
	);
	let subTypes = [];

	// Get potential component subTypes (e.g., phone number field, address field, etc.)
	if(componentTypes) {
		componentTypes.forEach(candidate => {
			let toConcat = AssistantNLPUtil.getComponentSubtype(
				candidate.remainingKeywords,
				candidate.componentType,
				{
					componentType: contextComponentType,
					componentId: contextComponentId
				},
				{
					componentType: parentComponentType,
					componentId: parentComponentId
				}
			);
			subTypes = subTypes.concat(toConcat);
		});
	} else {
		subTypes = AssistantNLPUtil.getComponentSubtype(
			objectPhrase,
			null,
			{
				componentType: contextComponentType,
				componentId: contextComponentId
			},
			{
				componentType: parentComponentType,
				componentId: parentComponentId
			}
		);
	}

	let templateToAssign = {
		operation: 'create',
		NLPInput: NLPInput,
		preps: preps,
		det: entity.det
	};

	let patternGuesses = [];
	if(subTypes && subTypes.length) {
		subTypes.forEach(subType => {
			// subType.operation = 'create';
			// subType.componentType = contextComponentType;
			// subType.NLPInput = NLPInput;
			// subType.preps = preps;
			let {matchedKeywords, remainingKeywords} = subType;
			matchedKeywords = matchedKeywords || '';
			remainingKeywords = remainingKeywords || '';
			let keyword = (matchedKeywords.trim() + ' ' + remainingKeywords.trim()).trim();
			// subType.remainingKeywords = keyword;
			subType = Object.assign({}, subType, templateToAssign, {
				remainingKeywords: keyword,
				det: entity.det
			});
			// patternGuesses = patternGuesses.concat(AssistantNLPUtil.getPatternMatches(subType) || []);
			patternGuesses = patternGuesses.concat(AssistantNLPUtil.getCreateMatches(subType) || []);
		});
	} else if (componentTypes && componentTypes.length) {
		componentTypes.forEach(componentType => {
			componentType = Object.assign(componentType, templateToAssign);
			// componentType.operation = 'create';
			// componentType.NLPInput = NLPInput;
			// componentType.preps = preps;
			// patternGuesses = patternGuesses.concat(AssistantNLPUtil.getPatternMatches(componentType) || []);
			patternGuesses = patternGuesses.concat(AssistantNLPUtil.getCreateMatches(componentType) || []);
		});
	} else {
		// patternGuesses = AssistantNLPUtil.getPatternMatches({
		// 	operation: 'create',
		// 	remainingKeywords: objectPhrase
		// }) || [];
		patternGuesses = AssistantNLPUtil.getCreateMatches(Object.assign({
			// operation: 'create',
			remainingKeywords: objectPhrase,
			det: entity.det
			// NLPInput: NLPInput,
			// preps: preps
		}, templateToAssign)) || [];
	}

	return {
		componentTypes,
		subTypes,
		patternGuesses
	};
}

/**
 * Accepts an array of nested arrays and maps them out as combinatorial results.
 * 
 * @param {array} candidates 
 * 
 * @returns {array} Flattened array of results, each of which contains a single-dimension array of patterns to run in order
 */
function _reduceCandidatePatterns(candidates) {
	// let toReturn = [];
	if(!candidates.length) {
		return [];
	} else if (candidates.length === 1) {
		return candidates[0];
	}

	let toReturn = [];

	let leftCandidates = candidates[0];
	for (let i = 1; i < candidates.length; i++) {
		let newLeft = [];
		let rightCandidates = candidates[i];
		leftCandidates.forEach(left => {
			rightCandidates.forEach(right => {
				newLeft.push(left.concat(right));
			});
		});
		leftCandidates = newLeft;
	}

	toReturn = leftCandidates;
	return toReturn;
}

/**
 * Processes a set of prepositions looking for likely tables, attach points and quoted objects.
 * Will likely be updated later as further functionality becomes supported, particularly for queries.
 * 
 * @param {array} preps Array of prepositions to consider
 * 
 * @returns {object} Object whose keys correspond to various values.
 * Return object is of the form {tableCandidates, quotedObjs, attachPoints}
 */
function _processPreps(preps, contextComponent, parentComponent) {
	let toReturn = {
		tableCandidates: [],
		quotedObjs: [],
		attachPoints: []
	};
	if(!preps || !preps.length) {
		return toReturn;
	}

	// Easy lookup for prepositions to determine which one is likely to correspond to which options
	let tablePreps = {
		'on': true,
		'for': true,
		'to': true
	};
	let attachmentPreps = {
		'on': true
	};
	let valuePreps = {
		'to': true
	};
	//@TODO: How to handle situations like "with role [x]"? Just the full pobj can't serve as the value; we also have a settings keyword in there.
	//@TODO: How do we want to handle detachment? Its own set, or just add 'from' to the set of prepositions?

	preps.forEach(prep => {
		let prepKeyword = prep.prep.toLowerCase();
		prep.pobjs.forEach(pobj => {
			let newTableCandidates = [];
			let newAttachPoints = [];
			if(tablePreps[prepKeyword]) {
				newTableCandidates = AssistantNLPUtil.getMetadataMatches(pobj.objectAdjectives.join(' ') + ' ' + pobj.objectUnit, '', contextComponent, parentComponent, '', ['table']) || [];
				toReturn.tableCandidates = toReturn.tableCandidates.concat(newTableCandidates);
			}
			// @TODO: Update getMetadataMatches to deal with this situation; Deal with potential inappropriate attachment points
			if(attachmentPreps[prepKeyword]) {
				newAttachPoints = AssistantNLPUtil.getMetadataMatches(pobj.objectAdjectives.join(' ') + ' ' + pobj.objectUnit, '', contextComponent, parentComponent, '', ['page', 'field']) || [];
				toReturn.attachPoints = toReturn.attachPoints.concat(newAttachPoints);
			}
			if(valuePreps[prepKeyword]) {
				// Deal with cases such as 'Set available modes to view and edit'
				if(pobj.quotesObjs && pobj.quotedObjs.length) {
					toReturn.quotedObjs = toReturn.quotedObjs.concat(pobj.quotedObjs);
				} else if (!newTableCandidates.length && !newAttachPoints.length) {
					toReturn.quotedObjs.push(pobj.objectAdjectives.join(' ') + ' ' + pobj.objectUnit);
				}
			}
		});
	});

	return toReturn;
}