import React, { Component } from 'react';
import { Container } from 'flux/utils';
import FieldActions from '../../actions/field-actions';
import {AdminSettingsStore, FieldStore, FieldTypeStore, PageStore} from '../../stores';
import FieldTypeSettingsUI from '../fieldtype-settings-ui.react';
import PageActions from '../../actions/page-actions';
import AppearanceLocalSettings  from './appearance-local-settings.react';
import UIUtils from '../../utils/ui-utils';
import FieldUtils from '../../utils/field-utils';

/**
 * Right panel displaying appearance settings
 */
class AppearanceSetting extends Component {
	constructor(props) {
		super(props)
		this.renderLocalContent = this.renderLocalContent.bind(this);
		this.updateGlobalSetting = this.updateGlobalSetting.bind(this);
		this.updateLocalSettings = this.updateLocalSettings.bind(this);
	}
	
	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
	 return [AdminSettingsStore, FieldStore, FieldTypeStore, PageStore]; 
	}
	/**
	 * static - description
	 *
	 * @param  {type} prevState description
	 * @param  {type} props     description
	 * @return {type}           description
	 */
	static calculateState(prevState, props) {
		let recordId =  AdminSettingsStore.getRecordId(),
			attachmentId =  AdminSettingsStore.getAttachmentId(),
			tableSchemaName = AdminSettingsStore.getTableSchemaName(),
			parentRecordId = AdminSettingsStore.getParentRecordId(),
			parentTableSchemaName = AdminSettingsStore.getParentTableSchemaName(),
			settingRecordId = AdminSettingsStore.getSettingRecordId(),
			settingConfig = FieldStore.get(settingRecordId),
			fieldType = null,
			globalValue = '';
		//Calculate the local values
		let localValues = [];

		let idToUse = attachmentId || recordId;

		// Check for Setting has been selected
		if(settingConfig){
			let fieldSchemaName = settingConfig.fieldSchemaName;

			switch (tableSchemaName) {
				case 'field': {
					let fieldObj = FieldStore.get(recordId);
					if(fieldObj && fieldObj.fieldType) {
						fieldType = fieldObj.fieldType;
						let fieldSettings = FieldStore.getSettings(recordId);
						if(fieldSettings && fieldSettings[fieldSchemaName]) {
							globalValue = fieldSettings[fieldSchemaName];
						}
					}
					break;
				}
				case 'page': {
					let pageObj = PageStore.get(recordId);
					if (pageObj && pageObj[fieldSchemaName]) {
						globalValue = pageObj[fieldSchemaName];
					}

					// change stuff for the Table setting
					if(pageObj.new && settingRecordId === 'aeb2dc2c-3153-4a27-8820-cd3e1379d29c') {
						settingConfig.editVariant = 'tablesEdit';
						settingConfig.descriptiveText = 'What Table is this page associated with?';
						settingConfig.required = true;
						settingConfig.placeholder = 'Select a Table. This is required for a new page.';
					}
					break;
				}
				default: {
					if (tableSchemaName) {
						console.warn('Invalid tableschema name', tableSchemaName);
					}
				}
			}
			
			if (parentRecordId) {
				try {
					let childConfiguration;
					if(parentTableSchemaName === 'field') {
						if(attachmentId) {
							childConfiguration = FieldStore.getUpdatedChildConfigurations(parentRecordId, attachmentId, recordId, settingConfig.fieldSchemaName);
						} else {
							childConfiguration = FieldStore.getChildConfigurations(parentRecordId, idToUse, settingConfig.fieldSchemaName);
						}
					} else {
						if(attachmentId) {
							childConfiguration = PageStore.getUpdatedChildConfigurations(parentRecordId, attachmentId, recordId, settingConfig.fieldSchemaName);
						} else {
							childConfiguration = PageStore.getChildConfigurations(parentRecordId, idToUse, settingConfig.fieldSchemaName);
						}
					}
					if (childConfiguration) {
						localValues = childConfiguration;
					}
				} catch (error) {
					console.error(error.message);
				}
			}
		}

		return {
			'globalValue': globalValue,
			'localValues': localValues,
			'setting': settingConfig,
			'fieldType': fieldType,
			'recordId': recordId,
			'attachmentId': attachmentId,
			'tableSchemaName': tableSchemaName,
			'parentRecordId': parentRecordId,
			'parentTableSchemaName': parentTableSchemaName,
			'settingRecordId': settingRecordId
		};
	}
	
	/**
	 * Save the value as a field is entered
	 *
	 * @param {Object} event
	 *
	 * @memberOf FieldSettings
	 */
	updateGlobalSetting(event) {
		let { recordId, tableSchemaName, setting } = this.state,
		fieldSchemaName = setting.fieldSchemaName,
		value = event.target.value;
		switch (tableSchemaName) {
			case 'field':
				FieldActions.pushSettingToStore(recordId, fieldSchemaName, value);
				break;
			case 'page':
				let pageValue = {};
				pageValue[fieldSchemaName] = value;
				PageActions.pushToStore(recordId, pageValue);
				break;
			default:
				if (tableSchemaName) {
					console.warn('Invalid table schema name ', tableSchemaName);
				}
				break;
		}
	}

	 	/**
	 * Save the value as a field is entered
	 *
	 * @param {Object} event
	 *
	 * @memberOf FieldSettings
	 */
	updateLocalSettings(event) {
		let {recordId, attachmentId, parentRecordId, parentTableSchemaName, setting } = this.state,
			fieldSchemaName = setting.fieldSchemaName,
			value = event.target.value;

		let idToUse = attachmentId || recordId;

		switch (parentTableSchemaName) {
			case 'page': {
				let childConfiguration = attachmentId ?
					PageStore.getUpdatedChildConfigurations(parentRecordId, attachmentId, recordId, fieldSchemaName) :
					PageStore.getChildConfigurations(parentRecordId, idToUse, fieldSchemaName);
				childConfiguration = childConfiguration || [];
				if (value.action === 'delete') {
					if (value.childIndex < childConfiguration.length) {
						childConfiguration.splice(value.childIndex,1);
					}
				} else {
					let newValue = {value: value.value,
						screenSizes: value.screenSizes};
					if (value.childIndex < childConfiguration.length) {
						childConfiguration[value.childIndex] = 
							newValue;
					} else {
						childConfiguration.push(newValue);
					}

					//Loop over all the child configuration lines and if any of them are for the
					//same screen size remove the screen size so that we can notend up with an illegal 
					//configuration
					childConfiguration = childConfiguration.map((currentConfiguration, currentIndex) => {
						if (currentIndex !== value.childIndex) {
							currentConfiguration.screenSizes = 
								currentConfiguration.screenSizes.filter((screenSize) => {
									return (!newValue.screenSizes.includes(screenSize));
								});
						}
						return currentConfiguration;
					});
				}

				if(Array.isArray(childConfiguration) && childConfiguration.length > 0) {
					PageActions.pushChildConfigurationToStore(parentRecordId, attachmentId ? (attachmentId + '-' + recordId) : recordId, fieldSchemaName, childConfiguration);
				} else {
					PageActions.pushChildConfigurationToStore(parentRecordId, attachmentId ? (attachmentId + '-' + recordId) : recordId, fieldSchemaName, null);
					if(attachmentId) {
						// Clear the old format value as well
						PageActions.pushChildConfigurationToStore(parentRecordId, attachmentId, fieldSchemaName, null);
					}
				}
				break;
			}
			
			case 'field': {
				let childConfiguration = attachmentId ?
					FieldStore.getUpdatedChildConfigurations(parentRecordId, attachmentId, recordId, fieldSchemaName) :
					FieldStore.getChildConfigurations(parentRecordId, idToUse, fieldSchemaName);
				childConfiguration = childConfiguration || [];
				if (value.action === 'delete') {
					if (value.childIndex < childConfiguration.length) {
						childConfiguration.splice(value.childIndex,1);
					}
				} else {
					let newValue = {value: value.value,
						screenSizes: value.screenSizes};
					if (value.childIndex < childConfiguration.length) {
						childConfiguration[value.childIndex] = 
							newValue;
					} else {
						childConfiguration.push(newValue);
					}

					//Loop over all the child configuration lines and if any of them are for the
					//same screen size remove the screen size so that we can notend up with an illegal 
					//configuration
					childConfiguration = childConfiguration.map((currentConfiguration, currentIndex) => {
						if (currentIndex !== value.childIndex) {
							currentConfiguration.screenSizes = 
								currentConfiguration.screenSizes.filter((screenSize) => {
									return (!newValue.screenSizes.includes(screenSize));
								});
						}
						return currentConfiguration;
					});
				}
				if(Array.isArray(childConfiguration) && childConfiguration.length > 0) {
					FieldActions.pushChildConfigurationToStore(parentRecordId, attachmentId ? (attachmentId + '-' + recordId) : recordId, fieldSchemaName, childConfiguration);
				} else {
					FieldActions.pushChildConfigurationToStore(parentRecordId, attachmentId ? (attachmentId + '-' + recordId) : recordId, fieldSchemaName, null);
					if(attachmentId) {
						// Clear the old format value as well
						FieldActions.pushChildConfigurationToStore(parentRecordId, attachmentId, fieldSchemaName, null);
					}
				}
				break;
			}

			default:
				console.warn('Unsupported Parent Table Schema Name');
		}
	}

	
	/**
	 * Renders the tabs content
	 * 
	 * @param {any} settings
	 * @param {any} showAttachmentSettingsTab 
	 * @param {any} parentTableSchemaNameToRender 
	 * @param {any} parentRecordId 
	 * @param {any} parentTableSchemaName 
	 * @returns 
	 * 
	 * @memberof AppearanceSettings
	 */
	renderLocalContent() {
		let content = null;
		let {fieldType, localValues, recordId, setting, tableSchemaName} = this.state;
		let label = setting && setting.fieldLabel;

		//Make sure to have a parent Record Id and 
		content = <div className="setting-local-wrapper" key={'localSettings' + recordId}>
			<AppearanceLocalSettings 
				recordId={recordId} 
				fieldType={fieldType} 
				tableSchemaName={tableSchemaName} 
				setting={setting || {}} 
				value={localValues}
				label={label} 
				onChange={this.updateLocalSettings}/>
		</div>

		return content;
	}

	
	/**
	 * 
	 * 
	 * @returns 
	 * 
	 * @memberof AppearanceSetting
	 */
	render() {
		let {fieldType, globalValue, recordId, tableSchemaName, setting, parentTableSchemaName, parentRecordId } = this.state;
		let settingFieldSchemaName = this.state.setting && this.state.setting.fieldSchemaName;
		let settingFieldType = this.state.setting && this.state.setting.fieldType;
		let descriptiveText = this.state.setting && this.state.setting.descriptiveText;
		let label = this.state.setting && this.state.setting.fieldLabel;

		// set the Table Name for the Override Setting Parent label
		let allowLocalOverride = UIUtils.getFieldAllowsOverrides(parentRecordId, settingFieldSchemaName, settingFieldType);

		let parentHeaderIcon = '';
		if(parentTableSchemaName === 'page') {
			parentHeaderIcon = FieldUtils.getFieldTypeIcon('page');
		} else {
			parentHeaderIcon = FieldUtils.getFieldTypeIcon(parentRecordId);
		}

		let localOverrideComponents = ( allowLocalOverride
			? [
				<div className="d-flex" key={recordId}>
					<img height="22" width="22" className="mr-2" style={{ marginTop:'0.3rem' }} src={parentHeaderIcon} alt="" />
					<h3 key="name" className="mb-2">Parent Overrides</h3>
				</div>,
				this.renderLocalContent()]
			: null
		);

		let fieldTypeIcon = '';
		if(tableSchemaName === 'page') {
			fieldTypeIcon = FieldUtils.getFieldTypeIcon('page');
		} else {
			fieldTypeIcon = FieldUtils.getFieldTypeIcon(recordId);
		}

		if (!setting) {
			return (<div className="select-setting">
						<div className="select-setting-text-wrap">
							Select a Setting to configure on the left.
						</div>
					</div>);
		} else {
			return (
				<div id="appearance-settings">
					<div className="appearance-settings-container mx-3">
						<div className="d-flex align-items-center">
							<img height="22" width="22" className="mr-2" src={fieldTypeIcon} alt="" />
							<h3 className="my-1">{label}</h3>
						</div>
						<div className="setting-global mt-0">
							<div className={"setting-wrapper setting-" + settingFieldSchemaName}>
								<FieldTypeSettingsUI
									recordId={recordId} 
									tableSchemaName={tableSchemaName} 
									setting={setting} 
									value={globalValue} 
									onChange={this.updateGlobalSetting} 
									fieldType={fieldType} 
									label={label}
									skipDescriptiveText={true}
									/>
									{descriptiveText && 
										<small id={'description-' + this.props.renderId} className='form-text text-muted' dangerouslySetInnerHTML={{ __html: descriptiveText || '' }}></small>
									}
							</div>
						</div>
						{localOverrideComponents} 
					</div>
				</div>);
		}
	}
}
const container = Container.create(AppearanceSetting, {withProps: true});
export default container;