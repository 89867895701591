import AppDispatcher from '../dispatcher/app-dispatcher'
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import {
  MapConstants,
  MapElementTypes,
  GroupFilters
} from '../constants';
import {FieldConstants} from '../constants/field-constants';
import {TableConstants} from '../constants/table-constants';
import { PageConstants } from '../constants/page-constants';
import {RelationshipConstants} from '../constants/relationship-constants';;
import MapUtils from '../utils/map-utils';

const INITIAL_FILTER = Immutable.fromJS({field: {}, page: {}, record: {}, relationship: {}, table: {}}),
      INITIAL_SORT = {
        field: {sortColumn: 'settings', sortDirection: 'ASC'},
        page: {sortColumn: 'settings', sortDirection: 'ASC'},
        relationship: {sortColumn: 'settings', sortDirection: 'ASC'},
        record: {sortColumn: '', sortDirection: ''}
      };
/**
 * Store to contain tables for Sitemap
 *
 * @class MapStore
 * @extends {ReduceStore}
 */
class MapStore extends ReduceStore {
  /**
   * getInitialState - initial state for MapStore
   *
   * @return {Object}  event
   */
  getInitialState() {
    return Immutable.fromJS({
      'filters': INITIAL_FILTER,
      'groupBy': GroupFilters.NAME,
      'isNew': {},
      'selected': {},
      'search': {},
      'sort': INITIAL_SORT
    });
  }
  /**
   * Updates state store
   *
   * @param {Object} Current state
   * @param {Object} action
   * @returns {Object} updated state
   */
  reduce(state, action) {
    let type = action.get('type');

    switch(type) {
      case MapConstants.MAP_ADD_FILTER_COLUMN: {
        let filterColumn = action.get('filterColumn'),
            term = action.get('term'),
            elementType = action.get('elementType');

        return state.withMutations((newState) => {
          newState.setIn(['filters', elementType, filterColumn], term);
        });
      }
      case MapConstants.MAP_FILTER_CLEAR: {
        return state.set('filters', INITIAL_FILTER);
      }
      case MapConstants.MAP_GROUP_BY: {
        let groupBy = action.get('groupBy');

        return state.set('groupBy', groupBy);
      }
      case MapConstants.MAP_GROUP_TOGGLE: {
        let dashboard = action.get('dashboard');
        let groupBy = action.get('groupBy');
        let groupName = action.get('groupName');
        let toggleState = action.get('toggleState');
        
        return state.setIn(['groupToggle', dashboard, groupBy, groupName], toggleState);
      }
      case MapConstants.MAP_GROUP_TOGGLE_BULK: {
        let dashboard = action.get('dashboard');
        let groupBy = action.get('groupBy');
        let groupToggleStateObj = action.get('groupToggleStateObj');
        
        return state.mergeIn(['groupToggle', dashboard, groupBy], groupToggleStateObj);
      }
      case MapConstants.MAP_SEARCH: {
        let search = action.get('search');
        let dashboard = action.get('dashboard');

        return state.setIn(['search', dashboard], search || '');
      }
      case MapConstants.MAP_SEARCH_RESET: {
        return state.delete('search');
      }
      case MapConstants.MAP_SET: {
        let elementType = action.get('elementType'),
            elementId = action.get('elementId'),
            isNew = action.get('isNew');

        state = state.setIn(['isNew', elementType], isNew);
        return state.setIn(['selected', elementType], elementId);
      }
      case MapConstants.MAP_SORT_COLUMN: {
        let sortColumn = action.get('sortColumn'),
            direction = action.get('direction'),
            elementType = action.get('elementType');

        return state.withMutations((newState) => {
          newState.setIn(['sort', elementType, 'sortColumn'], sortColumn);
          newState.setIn(['sort', elementType, 'sortDirection'], direction);
        });
      }
      //external events for tables
      case TableConstants.TABLE_PUSH_TO_DATABASE: {
        let selectedTable = this.selectedTable(),
            table = action.get('tableObject');

        return state.withMutations((newState) => {
          if (selectedTable === table.get('recordId')) {
            newState.setIn(['isNew', 'table'], false);
          }
        });
      }
      case TableConstants.TABLE_DELETE_FROM_STORE: {
		    let selectedTable = this.selectedTable(),
            recordId = action.get('recordId');

        return state.withMutations((newState) => {
          if (selectedTable === recordId) {
            newState.setIn(['selected', 'table'], null);
            newState.setIn(['isNew', 'table'], false);
          }
        });
      }
      //external events for fields
      case FieldConstants.FIELD_PUSH_TO_DATABASE: {
        let selectedField = this.selectedField(),
            field = action.get('fieldObject');

        return state.withMutations((newState) => {
          if (selectedField === field.get('recordId')) {
            newState.setIn(['selected', 'field'], null);
            newState.setIn(['isNew', 'field'], false);
          }
        });
      }
      case FieldConstants.FIELD_DELETE_FROM_STORE: {
        let selectedField = this.selectedField(),
            recordId = action.get('recordId');

        return state.withMutations((newState) => {
          if (selectedField === recordId) {
            newState.setIn(['selected', 'field'], null);
            newState.setIn(['isNew', 'field'], false);
          }
        });
      }
      //external events for relationships
      case RelationshipConstants.RELATIONSHIP_PUSH_TO_DATABASE: {
        let selectedRelationshipId = this.selectedRelationship(),
            updatedRelationship = action.get('relationshipObject');

        return state.withMutations((newState) => {
          if (selectedRelationshipId === updatedRelationship.get('recordId')) {
            newState.setIn(['selected', 'relationship'], null);
            newState.setIn(['isNew', 'relationship'], false);
          }
        });
      }
      case RelationshipConstants.RELATIONSHIP_DELETE_FROM_STORE: {
        let selectedRelationshipId = this.selectedRelationship(),
            relationshipId = action.get('recordId');

        return state.withMutations((newState) => {
          if (selectedRelationshipId === relationshipId) {
            newState.setIn(['selected', 'relationship'], null);
            newState.setIn(['isNew', 'relationship'], false);
          }
        });
      }
      //external events for page
      case PageConstants.PAGE_PUSH_TO_DATABASE: {
        let selectedPageId = this.selectedPage(),
            updatedPage = action.get('pageObject');

        return state.withMutations((newState) => {
          if (selectedPageId === updatedPage.get('recordId')) {
            newState.setIn(['isNew', 'page'], false);
          }
        });
      }
      case PageConstants.PAGE_DELETE_FROM_STORE: {
        let selectedPageId = this.selectedPage(),
            pageId = action.get('recordId');

        return state.withMutations((newState) => {
          if (selectedPageId === pageId) {
            newState.setIn(['selected', 'page'], null);
            newState.setIn(['isNew', 'page'], false);
          }
        });
      }
      //external event from AdminSettingsEvents
      // case AdminSettingsConstants.LEFT_PANEL_CHANGE: {
      //   let newactiveDashboard = action.get('activeDashboard');
      //   let newGroupBy = GroupFilters.NAME;
      //   if(newactiveDashboard === 'site') {
      //     newGroupBy = GroupFilters.TABLE_NAME;
      //   }
      //   return state.set('groupBy', newGroupBy);
      // }
      default: {
        return state;
      }
    }
  }

  /**
   * Gets store current state
   *
   * @returns {Object} current state
   */
  browse() {
    let state = this.getState();
    return state.toJS();
  }

  /**
   * Get selected elementType 
   *
   * @params {String} elementType represents one of (field, page, record, relationship, table)
   */
  isNew(elementType) {
    return this.getState().getIn(['isNew', elementType]);
  }
  /**
   * Flag to indicate the selected field is new
   */
  isNewField() {
    return this.isNew('field');
  }

  /**
   * Flag to indicate the selected page is new
   */
  isNewPage() {
    return this.isNew('page');
  }

  /**
   * Flag to indicate the selected table is new
   */
  isNewRecord() {
    return this.isNew('record');
  }

  /**
   * Flag to indicate the selected relationship is new
   */
  isNewRelationship() {
    return this.isNew('relationship');
  }

  /**
   * Flag to indicate the selected table is new
   */
  isNewTable() {
    return this.isNew('table');
  }

  /**
   * Get selected elementType 
   *
   * @params {String} elementType represents one of (field, page, record, relationship, table)
   */
  selected(elementType) {
    return this.getState().getIn(['selected', elementType]);
  }
  /**
   * Get selected field
   */
  selectedField() {
    return this.selected('field');
  }

  /**
   * Get selected page
   */
  selectedPage() {
    return this.selected('page');
  }

  /**
   * Get selected record
   */
  selectedRecord() {
    return this.selected('record');
  }

  /**
   * Get selected relationship
   */
  selectedRelationship() {
    return this.selected('relationship');
  }


  /**
   * Get selected table
   */
  selectedTable() {
    return this.selected('table');
  }

  /**
   * Get groupby filter
   */
  getGroupBy() {
    let groupBy = this.getState().get('groupBy');
    return groupBy;
  }

  /**
   * Returns the entire object of Group's Show/Hide status - for a Dashboards + GroupBys
   * @return {Object} toggleState for all groups, dashboards, group bys
   */
  getGroupToggleStatusObj() {
    let groupToggleStatus = (this.getState().get('groupToggle') 
     ? this.getState().get('groupToggle').toJS() : {});
    return groupToggleStatus;
  }

  /**
   * Return a Group's Show/Hide status - for a Dashboard+GroupBy+Group
   * @param {string} dashboard Dashboard to do the toggle for.
   * @param {string} groupBy Group by ON the Dashboard to do the toggle for.
   * @param {string} groupName Name of the group to toggle.
   * @return {boolean} toggleState Is this toggled on (true) or off (false)
   */
  getGroupToggleStatus(dashboard, groupBy, groupName) {
    let groupToggleStatus = this.getState().get('groupToggle').toJS();
    if(!groupToggleStatus || !groupToggleStatus[dashboard] || !groupToggleStatus[dashboard][groupBy]) {
      return undefined;
    } else {
      return groupToggleStatus[dashboard][groupBy][groupName];
    }
  }

  /**
   * Return a Group's Show/Hide status - for a Dashboard+GroupBy
   * @param {string} dashboard Dashboard to do the toggle for.
   * @param {string} groupBy Group by ON the Dashboard to do the toggle for.
   * @param {string} groupName Name of the group to toggle.
   * @return {boolean} toggleState Is this toggled on (true) or off (false)
   */
  getGroupToggleStatusGroupBy(dashboard, groupBy) {
    let groupToggleStatus = this.getGroupToggleStatusObj();
    if(!groupToggleStatus || !groupToggleStatus[dashboard] || !groupToggleStatus[dashboard][groupBy]) {
      return {};
    } else {
      return groupToggleStatus[dashboard][groupBy];
    }
  }

  /**
   * Get search filter
   */
  getSearch(dashboard) {
    return this.getState().getIn(['search', dashboard]) || '';
  }

  /**
   * Get function that sorts the column
   *
   * @returns {Function} sort function
   */
  getSortFunction(elementType) {
    let state = this.getState(),
        sortColumn = state.getIn(['sort', elementType, 'sortColumn']) || 'settings',
        direction = state.getIn(['sort', elementType, 'sortDirection']) || 'ASC';

    switch (elementType) {
      case MapElementTypes.FIELD: {
        return MapUtils.getFieldSortFunction(sortColumn, direction);
      }
      case MapElementTypes.RECORD: {
        return MapUtils.getRecordSortFunction(sortColumn, direction);
      }
      default: {
        return MapUtils.simpleSortFunction(sortColumn, direction);
      }
    }
  }

  getSortedColumns(elementType) {
    let state = this.getState(),
        sortColumn = state.getIn(['sort', elementType, 'sortColumn']),
        direction = state.getIn(['sort', elementType, 'sortDirection']);
    return sortColumn && direction ? [{columnKey: sortColumn, direction }] : [];
  }

  /**
   * Get fieldType of fieldTypeId
   *
   * @params {String} fieldType recordId
   * @returns {Object} plain object of recordId or null?
   */
  getFilterFunction(elementType) {
    let filter = this.getState().getIn(['filters', elementType]);

    return MapUtils.customFilterFunction(filter);
  }
}

const instance = new MapStore(AppDispatcher);
export default instance;
