import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { RelationshipActions } from '../../../actions';
import { AdminSettingsStore, FieldStore, RelationshipStore } from '../../../stores';

import FormLabelWrapper from '../../maps/form-label-wrapper.react';
import RelationSelector from './relationship-tables/relation-selector.react';
import RelationshipDirectionFormatter from './relationship-tables/relationship-direction-formatter.react';

/**
 * Right panel displaying Field settings
 */
class RelationshipTablesSetting extends Component {
	constructor(props) {
		super(props)
		this._onSettingChange = this._onSettingChange.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, FieldStore, RelationshipStore];
	}

	/**
	 * Calculate the current state of the component
	 * @static
	 *
	 * @param  {Object} prevState Previous State
	 * @param  {Object} props     Previous Props
	 * @return {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId = AdminSettingsStore.getRecordId();
		let settingRecordId = AdminSettingsStore.getSettingRecordId();
		let settingObj = FieldStore.get(settingRecordId);

		// Pull the value from the MD Store
		let relationObj = RelationshipStore.get(recordId);

		return {
			settingObj: settingObj,
			settingRecordId: settingRecordId,
			recordId: recordId,
			relationship: relationObj || {},
		};
	}

	/**
	 * Save the value as a value is entered
	 * @param {Object} event
	 */
	_onSettingChange(event) {
		let { recordId } = this.state;
		let settingSchemaName = event.target.id;
		let value = event.target.value;

		// Push the updated setting to the Field Store
		let relationshipObj = RelationshipStore.get(recordId);
		relationshipObj[settingSchemaName] = value;
		RelationshipActions.pushToStore(recordId, relationshipObj);
	}

	/**
	 * Render the component
	 * @return JSX
	 */
	render() {
		let { settingObj } = this.state; // recordId, value, relationship

		// let isNew = relationship.new;
		if (!settingObj || !settingObj.fieldSchemaName) {
			return (<div className="select-setting">
				<div className="select-setting-text-wrap">
					Select a Setting to configure on the left.
				</div>
			</div>);
		}

		let settingSchemaName = settingObj.fieldSchemaName;
		let descriptiveText = settingObj.descriptiveText;
		let label = settingObj.fieldLabel;

		// If we're NOT new, and on the field schema name field, make it read only.
		// if(!isNew && settingSchemaName === 'fieldSchemaname') {
		// 	settingObj.editVariant = settingObj.viewVariant;
		// 	descriptiveText = "The name used in the database's schema for this field. Rarely needed, but important. <div><i>This is read only because Technical Names cannot be changed once the Field is made.</i></div>"
		// }

		// // If we're new, and on the table field, make it editable.
		// if(isNew && settingObj.recordId === 'aeb2dc2c-3153-4a27-8820-cd3e1379d29c') {
		// 	settingObj.editVariant = 'tablesEdit';
		// 	settingObj.descriptiveText = 'What Table is this page associated with?';
		// 	settingObj.required = true;
		// 	settingObj.placeholder = 'Select a Table. This is required for a new Field.';
		// }

		return (<div id="appearance-settings">
			<div className="appearance-settings-container mx-3">
				<h3 className='my-1'>
					{label}
				</h3>
				<div className="setting-global mt-0">
					<div className={"setting-wrapper setting-" + settingSchemaName}>
						<div>
							{this._renderDirections()}
						</div>
						{descriptiveText &&
							<small id={'description-' + this.props.renderId} className='form-text text-muted' dangerouslySetInnerHTML={{ __html: descriptiveText || '' }}></small>
						}
					</div>
				</div>
			</div>
		</div>);
	}

	/**
	 * private Renders relationship directions
	 *
	 * @return {Array of Component} ltor and rtol relations
	 */
	_renderDirections() {
		let { relationship } = this.state;
		let isNew = relationship.new;
		let ltor = { relation: relationship, direction: 'ltor' };
		let rtol = { relation: relationship, direction: 'rtol' };

		if (isNew) {
			return (
				<div>
					 <div className="relationship-outline">
						<RelationSelector key="ltor" required={true} relationship={relationship} direction="ltor" onChange={this._onSettingChange} />
						<div className="mt-2">
							<FormLabelWrapper field="ltorLabel" label="" >
								<input type="text" required placeholder="Label in this direction" className="form-control" id="ltorLabel" value={relationship.ltorLabel} onChange={this._onSettingChange} />
							</FormLabelWrapper>
						</div>
					</div>
					<div className="relationship-outline">
						<RelationSelector key="rtol" required={true} relationship={relationship} direction="rtol" onChange={this._onSettingChange} />
						<div className="mt-2">
							<FormLabelWrapper field="rtolLabel" label="" >
								<input type="text" required placeholder="Label in this direction" className="form-control" id="rtolLabel" value={relationship.rtolLabel} onChange={this._onSettingChange} />
							</FormLabelWrapper>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div className="relationship-outline">
						<RelationshipDirectionFormatter key="ltor" value={ltor} />
						<div className="mt-2">
							<FormLabelWrapper field="ltorLabel" label="" >
								<input type="text" required placeholder="Label in this direction" className="form-control" id="ltorLabel" value={relationship.ltorLabel} onChange={this._onSettingChange} />
							</FormLabelWrapper>
						</div>
					</div>
					<div className="relationship-outline">
						<RelationshipDirectionFormatter key="rtol" value={rtol} />
						<div className="mt-2">
							<FormLabelWrapper field="rtolLabel" label="" >
								<input type="text" required placeholder="Label in this direction" className="form-control" id="rtolLabel" value={relationship.rtolLabel} onChange={this._onSettingChange} />
							</FormLabelWrapper>
						</div>
					</div>
				</div>
			)
		}
	}
}
const container = Container.create(RelationshipTablesSetting, { withProps: true });
export default container;