/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';
import uuid from 'uuid';
import { AdminSettingsActions, InterfaceActions, MetadataActions, ThemeTemplateActions } from '../../../actions';
import { AdminSettingsStore, AuthenticationStore, ContextStore, FieldStore, ThemeTemplateStore } from '../../../stores';

// Utils
import UIUtils from '../../../utils/ui-utils';
import ThemeBuilderUtils from '../../../utils/theme-builder-utils';

import MetadataBuilder from '@dmclain-citizendeveloper/citdev-module-metadata-builder';

/**
 * 
 */
class ThemeBuilderSettingsChooser extends Component {
	/**
	 * Creates instance of ThemeSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._getSettingsList = this._getSettingsList.bind(this);
		this._onDeleteHandler = this._onDeleteHandler.bind(this);
		this._onResetHandler = this._onResetHandler.bind(this);
		this._onSaveAsHandler = this._onSaveAsHandler.bind(this);
		this._onSaveHandler = this._onSaveHandler.bind(this);
		this._onPushToProdHandler = this._onPushToProdHandler.bind(this);
		this._onPushToStageHandler = this._onPushToStageHandler.bind(this);
		this._onPushToStagePush = this._onPushToStagePush.bind(this);
	}
	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, ContextStore, FieldStore, ThemeTemplateStore];
	}

	/**
	 * Calculate the current state.
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} State!
	 */
	static calculateState(prevState, props) {
		let recordId =  AdminSettingsStore.getRecordId();
		let componentObj = ThemeTemplateStore.get(recordId) || {};
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let componentSettings = [];

		// Default Settings
		let themeSettings =
			[
				{ recordId: 'ad8dc94f-7f60-48f4-accf-5523a064a05a',
					sortOrder: 1, required: true }, // Name
				{ recordId: '2aa9d728-1eb7-489c-827f-e957bcebe33b',
					sortOrder: 2, required: true }, // Description
				{ recordId: '38237917-2152-4e12-87d3-61276b346761',
					sortOrder: 3  }, // Image
				{ recordId: 'c5c974e9-f915-4145-bf4a-5ca69287ecd4',
					sortOrder: 4 }, // Prefix
				{ recordId: 'db89ba4e-0650-47cd-ae70-16d7cf35f1e8',
					sortOrder: 5, required: true }, // CSS
				{ recordId: 'de2f28d7-bdbc-446a-ae5e-8c5e7ca90269',
					sortOrder: 6 }, // Variables
				{ recordId: '9e0974e1-5710-46ec-93a4-7beeba6b4783',
					sortOrder: 7 }, // Variable Description - Colors
				{ recordId: 'a489f40c-38e1-4a50-ad75-145776a1248d',
					sortOrder: 8 }, // Variable Description - Sizes
				{ recordId: '170e9b40-9d9f-44d9-a115-e0551cf80f0d',
					sortOrder: 9 }, // Variable Description - Other
				{ recordId: '76abbd08-f32c-4fba-9d43-066275fd920f',
					sortOrder: 10 }, // Last DT
				{ recordId: 'b00f3d4b-d0c5-4c71-bb89-1fef0a5e840b',
					sortOrder: 11 }, // Last Pushed to Stage
				{ recordId: '963ed5a6-ee98-4139-8d78-595c85d4dee1',
					sortOrder: 12 }, // Last Pushed to Prod
			];

		let hasMissingRequiredSettings = false;

		themeSettings.forEach(themeSetting => {
			let settingId = themeSetting.recordId;
			let settingObj = FieldStore.get(settingId) || {};
			let settingSchemaName = settingObj.fieldSchemaName;

			// Empty/No Value values.
			let valueObj = {};
			if (componentObj[settingSchemaName]) {
				valueObj.value = componentObj[settingSchemaName];
			}

			// Check to see if this setting is required, and has no value
			if(themeSetting.required && (!valueObj.value || valueObj.length === 0)) {
				hasMissingRequiredSettings = true;
			}

			componentSettings.push({
				// Basics
				fieldId: settingId,
				fieldObj: settingObj,

				// Label, Value and Sorting..
				label: settingObj.fieldLabel,
				valueObj: valueObj,
				sortOrder: themeSetting.sortOrder,

				// Used for styling
				settingSelected: (selectedSetting === settingSchemaName),
				required: themeSetting.required
			});
		});

		// Sort the results by setting name
		componentSettings.sort(function (a, b) {
			if (a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			return 1;
		});

		let changes = ThemeTemplateStore.getChanges(recordId);

		return {
			recordId,
			componentObj,
			changes,
			componentSettings,
			hasMissingRequiredSettings,
		}
	}

	/**
	 * Render the compenent
	 * @returns JSX
	 */
	render() {
		let { 
			componentObj,
			componentSettings,
			changes,
			hasMissingRequiredSettings,
		} = this.state;

		let settingsList = this._getSettingsList(componentSettings, changes); // component Settings
		let notifications = [];
		let themeHasChanged = (changes.length > 0 ? true : false);

		// Assume all buttons are disabled.
		let saveButton = <button key="save" id="engineering-theme-templateSave" className="btn btn-primary btn-lg mr-1 w-50 d-flex justify-content-center disabled" 
			form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={() => { 
				InterfaceActions.notification({ 'level': 'warning', 'message': 'No changes to Save.' });
			}}>Save</button>;
		let saveAsButton = <button key="saveAs" className="btn btn-primary btn-lg mr-1 w-50 d-flex justify-content-center disabled" 
			form="appearance-form" aria-label="Save As" title="Save as a new Name." onClick={() => { 
				InterfaceActions.notification({ 'level': 'warning', 'message': 'Only saved Themes can be Save As\'d.' });
			}}>Save As</button>;
		let resetButton = <button key="reset" id="engineering-theme-templateReset" className="btn btn-warning btn-lg ml-1 mr-2 w-50 d-flex justify-content-center disabled" 
			form="appearance-form" aria-label="Reset" onClick={() => { 
				InterfaceActions.notification({ 'level': 'warning', 'message': 'No changes to Reset.' });
			}}>Reset</button>;
		let pushToStageButton = <button key="pushToStage" className="btn btn-warning disabled btn-lg ml-1 mr-2 w-50 d-flex justify-content-center" 
			form="appearance-form" aria-label="Push To Stage" onClick={() => { 
				InterfaceActions.notification({ 'level': 'warning', 'message': 'Make changes and save them before pushing to Stage.' });
			}}>Push Stage</button>;
		let pushToProdButton = <button key="pushToProd" className="btn btn-danger disabled btn-lg ml-1 mr-2 w-50 d-flex justify-content-center" 
			form="appearance-form" aria-label="Push to Prod" onClick={() => { 
				InterfaceActions.notification({ 'level': 'warning', 'message': 'Make changes and save them before pushing to Production.' });
			}}>Push Prod</button>;
		let deleteButton = <button key="delete" className="btn btn-danger btn-lg ml-1 mr-2 w-50 d-flex justify-content-center disabled" 
			form="appearance-form" aria-label="Delete" onClick={() => { 
				InterfaceActions.notification({ 'level': 'warning', 'message': 'Unable to delete a Theme Template once its been pushed to Stage.' });
			}}>Delete</button>;

		// Calculate most buttons
		if(componentObj.new) {
			saveButton = <button key="save" id="engineering-theme-templateSave" className="btn btn-primary btn-lg mr-1 w-50 d-flex justify-content-center" 
				form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={this._onSaveHandler}>Save</button>;
			deleteButton = <button key="delete" className="btn btn-danger btn-lg ml-1 mr-2 w-50 d-flex justify-content-center" 
				form="appearance-form" aria-label="Delete" onClick={this._onDeleteHandler}>Delete</button>;
		} else {
			if(themeHasChanged || !componentObj.lastPushedToStage) {
				saveButton = <button key="save" id="engineering-theme-templateSave" className="btn btn-primary btn-lg mr-1 w-50 d-flex justify-content-center" 
					form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={this._onSaveHandler}>Save</button>;
				pushToStageButton = <button key="pushToStage" className="btn btn-warning btn-lg ml-1 mr-2 w-50 d-flex justify-content-center" 
					form="appearance-form" aria-label="Push To Stage" onClick={this._onPushToStageHandler}>Push Stage</button>;
			}

			// If the theme has been pushed to stage, and it was NOT pushed to stage at the exact same time as it was push to prod...
			if(componentObj.lastPushedToStage && componentObj.lastPushedToStage !== componentObj.lastPushedToProd) {
				pushToProdButton = <button key="pushToProd" className="btn btn-danger btn-lg ml-1 mr-2 w-50 d-flex justify-content-center" 
				form="appearance-form" aria-label="Push to Prod" onClick={this._onPushToProdHandler}>Push Prod</button>;
			}

			if(themeHasChanged) {
				resetButton = <button key="reset" id="engineering-theme-templateReset" className="btn btn-warning btn-lg ml-1 mr-2 w-50 d-flex justify-content-center" 
					form="appearance-form" aria-label="Reset" onClick={this._onResetHandler}>Reset</button>;
			}

			// Calculate Delete Button
			if(!componentObj.lastPushedToStage) {
				deleteButton = <button key="delete" className="btn btn-danger btn-lg ml-1 mr-2 w-50 d-flex justify-content-center" 
					form="appearance-form" aria-label="Delete" onClick={this._onDeleteHandler}>Delete</button>;
			}
	
			// Calculate SaveAsButton
			saveAsButton = <button key="saveAs" className="btn btn-primary btn-lg mr-1 w-50 d-flex justify-content-center" 
				form="appearance-form" aria-label="Save As" title="Save as a new Name." onClick={this._onSaveAsHandler}>Save As</button>;
		}

		if(themeHasChanged) {
			notifications.push(UIUtils.getNotificationTile(
				'status2',
				'Changed from Stage',
				changes.length + ' Changes'
			));
		}

		if(hasMissingRequiredSettings) {
			notifications.push(UIUtils.getNotificationTile(
				'status3',
				'Setting is required'
			));
		}

		if (!settingsList.length) {
			return (
				<div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
				</div>
			);
		}

		return [
			<div key="settings" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">
					{settingsList && settingsList.length
						? <li className='settings-list-header' key="header">
							<div className="d-flex">
								<img height="22" width="22" className="mr-2" 
									src="https://cdn1secure.citizendeveloper.com/engine-build/citdev-media/v2/icon-theme.svg" 
									alt="" style={{ marginTop: '0.4rem'}} />
								<h3>Theme Template Settings</h3>
							</div>
						</li>
						: null
					}
					{settingsList && settingsList.length
						? settingsList
						: <div style={{ marginTop: 0 }} className='no-settings-found' key="noSettings">No Settings Found</div>
					}
				</ul>
			</div>,
			<div className="notifications-wrapper" key="notificationsWrapper">
				{notifications}
			</div>,
			<div key="buttons" className="btn-wrapper appearance-btn-wrapper justify-content-start ml-2 flex-column" >
				<div className="d-flex" key="saveAndSaveAs">
					{saveButton}{saveAsButton}
				</div>
				<div className="d-flex mt-1" key="row2">
					{resetButton}{deleteButton}
				</div>
				<div className="d-flex mt-1" key="row3">
					{pushToStageButton}{pushToProdButton}
				</div>
			</div>
		];
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @param {array} changes Array of changes to settings
	 * @returns { array } array of <li> settings elements
	 */
	_getSettingsList(settingsArr, changes) {

		let { fieldType, recordId, tableSchemaName } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label w-100 d-flex';
			if (setting.settingSelected) {
				labelClassNames += ' selected';
			}

			let componentName = setting.fieldObj.viewVariant;
			if(!componentName) {
				componentName = FieldStore.getDefaultVariantComponentName(
					setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			}
			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			componentProps.dataRecordId = recordId;
			componentProps.dataTtableSchemaName = tableSchemaName;

			// These props will only be used by the fake table setting
			componentProps.customExpressionResult = setting.customExpressionResult;
			componentProps.renderAsHTML = setting.renderAsHTML;

			// For the Date/Time, parse it!
			if(setting.fieldObj.fieldSchemaName === 'lastUpdatedDateTime' || 
				setting.fieldObj.fieldSchemaName === 'lastPushedToStage' || 
				setting.fieldObj.fieldSchemaName === 'lastPushedToProd') {
				if(componentProps.value && componentProps.value.includes('{')) {
					componentProps.value = JSON.parse(componentProps.value);
				} else {
					componentProps.value = '';
				}
			}

			let valueDisplay = <h5 className={`settings-list-value ${componentName}`}>
					{(componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null)}
				</h5>;

			let requiredMarker = null;
			if(setting.required && (!setting.valueObj.value || setting.valueObj.value.length === 0)) {
				// requiredMarker = <div style={{ left: '-14px', top: '2px' }} className="required-marker position-absolute"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
				requiredMarker = <div className="colorBullet narrow status3"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
			}

			let changedMarker = null;
			if(changes && Array.isArray(changes) && changes.includes(setting.fieldObj.fieldSchemaName)) {
				changedMarker = <div className="colorBullet narrow status2"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
			}

			return (<li key={setting.fieldId} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this._onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames}>
								<h4 style={{ flex:2 }}>{setting.label}</h4>
								<div className="d-flex" style={{ paddingRight: '2px'}}>{requiredMarker}{changedMarker}</div>
							</div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								{valueDisplay}
							</div>
							{setting.valueObj.source ?
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}>
									{setting.valueObj.source}
								</div>
								: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}

	/**
	 * _onDeleteHandler - Calls API to delete this theme template from dev-eng.
	 *
	 * @param  {object} event
	 */
	_onDeleteHandler(event) {
		let { recordId } = this.state;
		if(UIUtils.prompt('Deleting will drop this new Theme Template.  If you are sure, type "Delete" below:') === 'Delete') {
			// Display notification to user
			let id = InterfaceActions.stickyNotification({ title: 'Deleting', 'level': 'success', 'message': 'Deleting Theme Template...' });

			// Delete from database
			ThemeTemplateActions.deleteFromDatabasePromise(recordId).then(() => {
				// Drop it from the store.
				ThemeTemplateActions.deleteFromStore(recordId);
				InterfaceActions.clearStickyNotification(id);
				UIUtils.closeSettingsPanel();
				// Do something to put the style in place?
			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to delete Theme Template' });
				console.error('Unable to delete Theme Template:', error);
			})
		}
	}

	/**
	 * _onPushToStageHandler - Calls API to push this theme template to stage.
	 *
	 * @param  {object} event
	 */
	_onPushToStageHandler(event) {
		//This is a pre-encrypted password, provided by Drew McLain.
		let drewEncryptedPasswordToMatch = 'RnI0a2sxbkN5bDBucw==';
		
		// Grab the password that's been typed in.
		let plainTextPassword = UIUtils.prompt('Tier 3 approval required for Stage push.  Enter your password below:');
		if(!plainTextPassword) {
			return;
		}
		
		// Encrypt what was typed in with the salt...
		let encryptedEnteredPassword = window.btoa(unescape(encodeURIComponent(plainTextPassword)));

		// Compare the pre-encrypted password above to the encrypted version of what they typed in.
		if(drewEncryptedPasswordToMatch === encryptedEnteredPassword)
		{
			// Display notification to user
			let toastId = InterfaceActions.stickyNotification({ title: 'To Stage!', 'message': 'Pushing Theme Template to Stage...', 'level': 'success' });

			let { recordId } = this.state;
			let themeTemplateObj = ThemeTemplateStore.get(recordId);
			themeTemplateObj.new = false;

			themeTemplateObj['lastPushedToStage'] = JSON.stringify({
				unixTimestamp: (Date.now() / 1000),
				timezone: 'America/New_York'});

			this._onPushToStagePush(themeTemplateObj, toastId);
		} else {
			InterfaceActions.notification({ 'level': 'error', 'message': 'Invalid Password.' });
		}
	}

	/**
	 * Helper function that actually just does the push to stage databases of the object.
	 * @param {Object} themeTemplateObj Theme Template to push to stage.
	 * @param {string} toastId Toast to clear and then put up our own
	 */
	_onPushToStagePush(themeTemplateObj, toastId) {
		return new Promise((resolve, reject) => {
			// Switch the MD Actions to talk to citdev-engine
			MetadataActions.setInstallationId('citdev-engine', AuthenticationStore.getSignedMdKey(), ContextStore.getMDGWMode());
			// Update the TT in the Engine's database
			MetadataActions.pushToDatabasePromise(themeTemplateObj, 'themeTemplate').then(() => {
				if(toastId){
					InterfaceActions.clearStickyNotification(toastId);
				}
				InterfaceActions.notification({ level: 'success', message: 'Push to stage successful.  =)' });
				
				// Push to the Database the update that includes the lastPushedToStage
				toastId = InterfaceActions.stickyNotification({ title: 'Saving', 'level': 'success', 'message': 'Saving Theme Template...' });
				// Push to database
				ThemeTemplateActions.pushToDatabasePromise(themeTemplateObj).then(() => {
					// Then switch it back to talk to our installation!
					MetadataActions.setInstallationId(ContextStore.getInstallationId(), AuthenticationStore.getSignedMdKey(), ContextStore.getMDGWMode());

					InterfaceActions.clearStickyNotification(toastId);
					return resolve(true);
				}).catch(error => {
					// Then switch it back to talk to our installation!
					MetadataActions.setInstallationId(ContextStore.getInstallationId(), AuthenticationStore.getSignedMdKey(), ContextStore.getMDGWMode());

					InterfaceActions.clearStickyNotification(toastId);
					InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Theme Template' });
					console.error('Unable to save Theme Config:', error);
					return reject(error);
				})
			})
			.catch(error => {
				// Then switch it back to talk to our installation!
				MetadataActions.setInstallationId(ContextStore.getInstallationId(), AuthenticationStore.getSignedMdKey(), ContextStore.getMDGWMode());
				reject(error);
			});
		});
	}

	/**
	 * _onPushToProdHandler - Calls API to push this theme template to production and then delete the local override.
	 *
	 * @param  {object} event
	 */
	_onPushToProdHandler(event) {
		// Lookup the record to push.
		let { recordId } = this.state;
		let themeTemplateObj = ThemeTemplateStore.get(recordId);

		// Set the lastPushToProd
		themeTemplateObj['lastPushedToProd'] = JSON.stringify({
			unixTimestamp: (Date.now() / 1000),
			timezone: 'America/New_York'});
		themeTemplateObj.new = false;

		// Verify Supervisor Password:
		//This is a pre-encrypted password, provided by Drew McLain.
		let drewEncryptedPasswordToMatch = 'RnI0a2sxbkN5bDBucw==';
		let plainTextPassword = UIUtils.prompt('Tier 3 approval required for Prod push.  Password:');
		if(!plainTextPassword) {
			return;
		}

		// Encrypt what was typed in with the salt...
		let encryptedEnteredPassword = window.btoa(unescape(encodeURIComponent(plainTextPassword)));
		if(drewEncryptedPasswordToMatch === encryptedEnteredPassword)
		{
			// Require login to Dev Platform			
			let plainTextPDUsername = UIUtils.prompt('Platform Dev Username:');
			let plainTextPDPassword = false;
			if(plainTextPDUsername) {
				plainTextPDPassword = UIUtils.prompt('Platform Dev Password:');
			}

			// If one was missed, then lets abort.
			if(!plainTextPDUsername || !plainTextPDPassword) {
				return;
			}

			// Display notification to user
			let toastId = InterfaceActions.stickyNotification({ title: 'Login', 'level': 'success', 'message': 'Logging in to Dev Plaform...' });

			// Login to platform
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");

			fetch("https://dev-platform.citizendeveloper.com/gw/auth-v3", {
				method: 'POST',
				headers: myHeaders,
				body: JSON.stringify({
					"encodedBody": btoa(JSON.stringify({ 
						username: plainTextPDUsername, 
						password: plainTextPDPassword, 
						installationId: '9cd6440b-9f5e-4f41-98c8-155e0c078534' })),
					"encoding": "base64"
				}),
				redirect: 'follow'
			})
			.then(response => response.json())
			.then(responseJSON => {
				// Clear the login sticky
				InterfaceActions.clearStickyNotification(toastId);
				
				if(responseJSON.responseCode !== 200) {
					if(responseJSON.responseCode === 401) {
						InterfaceActions.notification({ level: 'warning', message: 'Authentication Failed' });
					} else {
						console.error('Error logging in to Platform Dev:', responseJSON);
						InterfaceActions.notification({ level: 'error', message: 'Error logging in to Platform Dev.  Check your console =(' });
					}
				} else {
					// Display notification to user
					toastId = InterfaceActions.stickyNotification({ 'level': 'success', 'message': 'Pushing to Stage...' });

					// Change the push to stage date/time to match the push to prod date/time
					themeTemplateObj['lastPushedToStage'] = themeTemplateObj['lastPushedToProd'];

					// Push to stage.. since now we have the lastPushToProd value.
					this._onPushToStagePush(themeTemplateObj, toastId).then(() => {
						// Clear the push to stage sticky
						InterfaceActions.clearStickyNotification(toastId);
		
						// Display notification to user
						toastId = InterfaceActions.stickyNotification({ title: 'To Production!', 'level': 'success', 'message': 'Pushing Theme Template to Production...' });
	
						// Establish Platform Dev Connection
						let defaultAPIGWOverride = (sessionStorage.getItem('defaultAPIGWOverride') || ContextStore.getInstallationId());
						sessionStorage.setItem('defaultAPIGWOverride', 'dev-platform');
						let _metadataBuilder = new MetadataBuilder('citdev-engine', responseJSON.response.signedMdKey, 'prod', true);
		
						// Update the FT in the Engine's database
						_metadataBuilder.updateRecords([themeTemplateObj], 'themeTemplate').then(() => {
							InterfaceActions.clearStickyNotification(toastId);
							InterfaceActions.notification({ level: 'success', message: 'Push to prod successful.  =)' });
							
							// Then switch it back to talk to our installation!
							sessionStorage.setItem('defaultAPIGWOverride', defaultAPIGWOverride);
							MetadataActions.setInstallationId(ContextStore.getInstallationId(), AuthenticationStore.getSignedMdKey(), ContextStore.getMDGWMode(), false);
							
							InterfaceActions.notification({ level: 'warning', title: 'New Platform Version', message: 'Remember - A Stage or Prod PV Release is now required!' });
							this._onResetHandler(event, true);
						})
						.catch(error => {
							sessionStorage.setItem('defaultAPIGWOverride', defaultAPIGWOverride);
							InterfaceActions.clearStickyNotification(toastId);
							console.error(error);
							InterfaceActions.notification({ level: 'error', message: 'Error pushing to stage.  Check your console =(' });
						});
					}).catch(error => console.log('error', error));
				} // End if the login succeeded.
			})
		} else {
			InterfaceActions.notification({ level: 'warning', message: 'Invalid Password.' });
		}
	}

	/**
	 * _onResetHandler - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	_onResetHandler(event, forceReset) {
		let { recordId } = this.state;
		if(forceReset || UIUtils.prompt('Resetting will drop all changes to this Theme Template and revert to the version currently in Stage.  If you are sure, type "Reset" below:') === 'Reset') {
			// Display notification to user
			let id = InterfaceActions.stickyNotification({ title: 'Resetting', 'level': 'success', 'message': 'Resetting Theme Template...' });

			// Delete from database
			ThemeTemplateActions.deleteFromDatabasePromise(recordId).then(() => {
				// Load it back into the store.
				ThemeTemplateActions.pullFromDatabase(recordId, true);
				InterfaceActions.clearStickyNotification(id);
				// Do something to put the style in place?
			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to reset Theme Template' });
				console.error('Unable to delete Theme Template:', error);
			})

		}
	}

	/**
	 * _onSaveAsHandler - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	_onSaveAsHandler(event) {
		let { recordId } = this.state;
		let newName = UIUtils.prompt('Enter a Name for this new Theme Template:');
		if(!newName) {
			return;
		}

		// Need to add the updated date time
		let themeObj = ThemeTemplateStore.get(recordId);
		themeObj.lastUpdatedDateTime = JSON.stringify({
			unixTimestamp: (Date.now() / 1000),
			timezone: 'America/New_York'});

		themeObj.recordId = uuid.v4();
		themeObj.name = newName.substring(0, 255);
		themeObj.lastPushedToProd = '';
		themeObj.lastPushedToStage = '';
		themeObj.new = true;

		let id = InterfaceActions.stickyNotification({ title: 'Saving', 'level': 'success', 'message': 'Saving Theme Template As...' });
		// Push to database
		ThemeTemplateActions.pushToDatabasePromise(themeObj).then(() => {
				InterfaceActions.clearStickyNotification(id);

				// Switch to the new one.
				// recordId, renderId, attachmentId, 
				// tableSchemaName, parentRecordId, parentTableSchemaName, gridKey, fieldType, selectedOverlay) {
				AdminSettingsActions.onShowComponentDetails(themeObj.recordId, undefined, undefined,
					'themeTemplate', undefined, undefined, undefined, undefined, 'engineering-theme-template');
		}).catch(error => {
			InterfaceActions.clearStickyNotification(id);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Theme Template' });
			console.error('Unable to save Theme Config:', error);
		})
	}

	/**
	 * _onSaveHandler - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	_onSaveHandler(event) {
		let { recordId } = this.state;
		let themeTemplateObj = ThemeTemplateStore.get(recordId);

		// We need to include the variables, and then the prefix before making the validate call.
		let variableCSS = ThemeBuilderUtils.getThemeVariableCSS('', themeTemplateObj.recordId);

		// Generate the full sass content
		let sassContent = '';
		sassContent += (variableCSS ? variableCSS : '');
		sassContent += (themeTemplateObj && themeTemplateObj.prefix ? themeTemplateObj.prefix : '');
		sassContent += 'div.main-window {';
		sassContent += (themeTemplateObj && themeTemplateObj.css ? themeTemplateObj.css : '');
		sassContent += '}';

		// Put up the validation Toast
		let validateId = InterfaceActions.stickyNotification({ 
			title: 'SCSS/CSS Validating', 
			level: 'warning', 
			message: 'Validating SCSS/CSS Theme Template...' 
		});

		// Validate the Sass
		ThemeBuilderUtils.validateSASS(sassContent).then(checkResult => {
			if(checkResult.responseCode !== 200) {
				themeTemplateObj.errorText = checkResult.response;
				ThemeTemplateActions.pushToStore(recordId, themeTemplateObj);
				InterfaceActions.clearStickyNotification(validateId);
				InterfaceActions.notification({
					title: 'SCSS/CSS Validation Error',
					message: 'SCSS/CSS Validation error in Theme Template.  Check the "SCSS" setting for details.',
					level: 'error'
				});
			} else {
				themeTemplateObj.errorText = '';

				// Need to add the updated date time
				themeTemplateObj.lastUpdatedDateTime = JSON.stringify({
					unixTimestamp: (Date.now() / 1000),
					timezone: 'America/New_York'});
				themeTemplateObj.new = false;
				
				InterfaceActions.clearStickyNotification(validateId);
				let id = InterfaceActions.stickyNotification({ title: 'Saving', 'level': 'success', 'message': 'Saving Theme Template...' });
				// Push to database
				ThemeTemplateActions.pushToDatabasePromise(themeTemplateObj).then(() => {
					ThemeTemplateActions.pushToStore(recordId, themeTemplateObj, true);
					InterfaceActions.clearStickyNotification(id);
				}).catch(error => {
					InterfaceActions.clearStickyNotification(id);
					InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Theme Template' });
					console.error('Unable to save Theme Config:', error);
				})
			}
		}).catch(error => {
			InterfaceActions.clearStickyNotification(validateId);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to validate Theme Template' });
			console.error('Unable to validate Theme Template:', error);
		})
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 */
	_onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Toggle to select and deselect the Setting
		if(settingSchemaName === 'css') {
			// If logic is already selected
			if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
				// Expand the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
			} else {
				// Hide the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		} else {
			// Expand the Settings Panel
			AdminSettingsActions.onSettingsListHideChange(false);
			if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
				AdminSettingsActions.onSettingChange('', '');
			} else {
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		}
	}
}
const container = Container.create(ThemeBuilderSettingsChooser);
export default container;