import React, {Component} from 'react';
import {Container} from 'flux/utils';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';
import MapActions from '../../../actions/map-actions';
import MetadataActions from '../../../actions/metadata-actions';

// Constants
import Help from '../../../constants/help-upgrade-constants';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import MapStore from '../../../stores/map-store';
import MetadataStore from '../../../stores/metadata-store';
import LogicFunctionStore from '../../../stores/logic-function-store';
import ContextStore from '../../../stores/context-store';

// Utils
import ObjectUtils from '../../../utils/object-utils';
import UIUtils from '../../../utils/ui-utils';
import uuid from 'uuid';
import { ReactSVG } from 'react-svg';

/**
 * APIs Tab Container component on the Logic Dashboard 
 * @class APIsLogicDashboardTabContainer
 * @extends {Component}
 */
class APIsLogicDashboardTabContainer extends Component {
	/**
	 * Creates an instance of APIsLogicDashboardTabContainer.
	 * @param {any} props 
	 */
	constructor(props) {
		super(props);

		this._renderLogicFunctionsUsed = this._renderLogicFunctionsUsed.bind(this);
		this._renderListNonFunction = this._renderListNonFunction.bind(this);
		this._onAddAPI = this._onAddAPI.bind(this);
		this._onChangeSearch = this._onChangeSearch.bind(this);
		this._onEditLogic = this._onEditLogic.bind(this);
	}

	/**
	 * Gets the stores to watch
	 * 
	 * @static
	 * @returns {Array of Object}
	 */
	static getStores() {
		return [ MetadataStore, MapStore ];
	}

	/**
	 * Returns the current State
	 * 
	 * @static
	 */
	static calculateState(prevState, props) {
		let apis = [];
		let search = MapStore.getSearch(AdminSettingsStore.getActiveDashboardTab());
		let groupBy = (MapStore.getGroupBy() ? MapStore.getGroupBy() : 'name');

		if(MetadataStore.allPulledFromDatabase('apiconfig')){
			apis = MetadataStore.getAllArray('apiconfig') || [];
			apis.map(api => {
				if(!api.name) {
					api.name = '[ No Name Found ]';
				}
				return api;
			});
		}
		return {
			apis: apis,
			search: search,
			groupedAPIs: this._performGroupBy(apis, groupBy, search)
		};
	}

	/**
	 * Using the group by set in the UI, group the apis and return
	 * an object with the groups as keys and functions array as value.
	 * @param {Array} apis APIs to group
	 * @param {String} groupBy How to group the apis
	 * @param {String} search What search to apply, if any.
	 * @return Object
	 */
	static _performGroupBy(apis, groupBy, search) {
		let groups = {};

		if (!apis) {
			return groups;
		}

		// Filter out the functions that dont have the search term.
		apis = apis.filter(api => {
			if (search && search.length > 0) {
				return (api.name.toLowerCase().includes(search.toLowerCase()));
			} else {
				return true;
			}
			// Sort the functions - by Name.
		}).sort((a, b) => {
			// Ascending Alphabetically
			return b.name < a.name ? 1 : -1;
		});

		// Put the apis into their groups
		let groupVal = 'name';

		if (!groups[groupVal]) {
			groups[groupVal] = [];
		}

		apis.forEach(api => {
			groups[groupVal].push(api);
		});

		return groups;
	}

	/**
	 * Render this component.
	 * 
	 * @returns JSX
	 * @memberof LogicMapContainer
	 */
	render() {
		let { groupedAPIs, search } = this.state;

		// We're not doing the group bys yet... so there is only one group.
		let apis = groupedAPIs['name'];

		let noSearchFound = null;
		if(Object.keys(apis).length === 0 && search.length) {
			noSearchFound = <li className="no-search-found" style={{ color: 'white'}}><h4>No Results for '{search}' found.</h4></li>
		}

		return (
			<div id="logic-map__content" className="map">
				<div className="cd-search-container">
					<input className="form-control select-group-by" placeholder="Search" type="text" value={search} onChange={this._onChangeSearch} />
				</div>
				<div className="section-header" key="expand-collapse-tools">
					<div className="d-flex justify-content-end align-items-center">
						<h5 className="bold">APIs</h5>
						<div className="d-flex">
							<div title="API Help" className="info-icon ml-2" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_LOGIC_APIS); }}>
								<i className="fa fa-info-circle mr-1"></i> | 
							</div>
							<div title="Create API" data-toggle="modal" onClick={this._onAddAPI} >
								<i className="fa fa-plus-circle ml-1"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="list-content-wrapper">
					<ol id="apiEditList"
						className="collapse show">
						{/* Render API Config List */}
						{apis.map(this._renderListNonFunction.bind(this, 'apiconfig'))}
						{ noSearchFound }
					</ol>
				</div>
			</div>
		);
	}

	_renderLogicFunctionsUsed(prefix, functionId) {
		let functionObj = LogicFunctionStore.get(functionId) || {};
		let name = functionObj.name ? functionObj.name : functionId;
		let description = functionObj.description ? functionObj.description : '';
		return (<li key={prefix + functionId} className='sub-item' title={description}>{name}</li>);
	}

	/**
	 * Renders a single, non-Function.
	 * 
	 * @param {String} kind 
	 * @param {String} logic 
	 * @param {number} index 
	 * @returns 
	 * @memberof LogicMapContainer
	 */
	_renderListNonFunction(kind, logic, index){
		let recordId = (logic && logic.recordId) ? logic.recordId : '';
		let logicName = (logic && logic.name) ? logic.name : '';
	
		if(!logicName) {
			return null;
		}

		let logicObj = logic && logic.logic ? ObjectUtils.getObjFromJSON(logic.logic) : null;
		let logicFunctionsUsedArr =[]; 
		if (logicObj && logicObj.logicFunctionsUsed) {
			if(Array.isArray(logicObj.logicFunctionsUsed)) {
				logicFunctionsUsedArr = logicObj.logicFunctionsUsed;
			} else {
				logicFunctionsUsedArr = logicObj.logicFunctionsUsed.split(',');
			}
		}
		return (
			<li key={logicName + '-' + index} className="table-name-item">
				<div className="list-item-row">
					<div 
						className="mb-0 py-1"
						data-toggle="modal"
						data-target="#page-dialog"
					>
						<h4 className="bold">{logicName}</h4>
					</div>
					<div title="Update API" className="action-icon">
						<span onClick={this._onEditLogic.bind(this, recordId)} data-toggle="modal" data-target="#page-dialog">
						<ReactSVG 
							beforeInjection={svg => {
								svg.setAttribute('viewBox', '0 0 34 34');
								svg.setAttribute('style', 'width: 16px; height: 16px');
							}}
							src={ContextStore.getUrlMedia() + "/icon-logic.svg"} />
							<span className='sr-only'>Edit</span>
						</span>
					</div>
				</div>
				{
					logicFunctionsUsedArr && logicFunctionsUsedArr.length 
					? <ul>
						{logicFunctionsUsedArr.map(this._renderLogicFunctionsUsed.bind(this, 'apis'))}
						</ul>
					:null
				}
			</li>
		);
	}

	/**
	 * Adds new modal for apiConfig
	 * 
	 * @memberof LogicMapContainer
	 */
	_onAddAPI() {
		// Generate new uuid and empty body for the record
		let recordId = uuid.v4(),
			newRecordObj = {
				recordId: recordId,
				name: 'New Incoming REST API Endpoint',
				method: '',
				uriPrefix: '',
				uri: '',
				querystringVariables: '[]',
				headerVariables: '[]',
				bodyVariables: '[]',
				bodyType: '',
				logic: '{}',
				new: true
			};

		// //Push the new Record to the store 	
		MetadataActions.pushToStore(recordId, 'apiconfig', newRecordObj, true);

		AdminSettingsActions.onRightPanelChange(true);
		//Toggle to select and deselect the Setting
		AdminSettingsActions.onSettingChange('uri', 'b3716b9e-9eb2-42bf-ab4f-4adeb0782f71');

		// Unhide the settings, always
		AdminSettingsActions.onSettingsListHideChange(false);
		
		// recordId, renderId, attachmentId, 
		// tableSchemaName, parentRecordId, parentTableSchemaName, gridKey, fieldType, selectedOverlay) {
		AdminSettingsActions.onShowComponentDetails(recordId, undefined, undefined,
			'apiconfig', undefined, undefined, undefined, undefined, 'apiconfig');

	}

	/**
	 * Handles typing into the search box.
	 * @param {object} event Change event
	 */
	_onChangeSearch(event) {
		let search = event.target.value;
		MapActions.search(search, AdminSettingsStore.getActiveDashboardTab());
	}

	/**
	 * Edit existing Logic Map Modal
	 * 
	 * 
	 * @param {String} recordId 
	 * @param {String} kind 
	 * @memberof LogicMapContainer
	 */
	_onEditLogic(recordId) {
		AdminSettingsActions.onRightPanelChange(true);
		//Toggle to select and deselect the Setting
		AdminSettingsActions.onSettingChange('uri', 'b3716b9e-9eb2-42bf-ab4f-4adeb0782f71');

		// Unhide the settings, always
		AdminSettingsActions.onSettingsListHideChange(false);

		// recordId, renderId, attachmentId, 
		// tableSchemaName, parentRecordId, parentTableSchemaName, gridKey, fieldType, selectedOverlay) {
		AdminSettingsActions.onShowComponentDetails(recordId, undefined, undefined,
			'apiconfig', undefined, undefined, undefined, undefined, 'apiconfig');
	}
}

const container = Container.create(APIsLogicDashboardTabContainer,  {withProps: true});
export default container;