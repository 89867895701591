/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from 'flux/utils';
import { ReactSVG } from 'react-svg';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import FieldSettingsStore from '../../stores/field-settings-store';
import FieldTypeStore from '../../stores/field-type-store';
import PageStore from '../../stores/page-store';
import RenderStore from '../../stores/render-store';
import ContextStore from '../../stores/context-store';

// Utils
import { UIUtils } from '../../utils';

/**
 * Container to AutomationPin
 *
 * @class AutomationPin
 * @extends {Component}
 */
class AutomationPin extends Component {
	constructor(props) {
		super(props);
		this.link = null;
		this.onPinClickHandler = this.onPinClickHandler.bind(this);
		this.onPinDoubleClickHandler = this.onPinDoubleClickHandler.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf AutomationPin
	 */
	static getStores() {
		return [AdminSettingsStore];
	}

	/**
	 * Determine if this pin is Disabled.
	 * @param {string} renderId Render ID for the component
	 * @param {string} fieldType Field Type of the Field.
	 * @return boolean
	 */
	static calculateIsDisabled(renderId, fieldType) {
		let renderObj = RenderStore.get(renderId);

		// If we dont have a renderObj (yet), then we can't calculate.
		if(!renderObj) { 
			return undefined;
		}

		if(renderObj.componentType === 'field') {
			let fieldtypeObj = FieldTypeStore.get(fieldType), 
				supportedActionTriggers = fieldtypeObj ? fieldtypeObj.supportedActionTriggers : null,
				customActionTriggers = fieldtypeObj ? fieldtypeObj.customActionTriggers : null; 

			if(supportedActionTriggers && supportedActionTriggers.length) {
				return false;
			}
			if(customActionTriggers && customActionTriggers.length) {
				return false;
			}

			// Main Menu Fields
			if(fieldType === 'cd0ee38e-d63f-44d2-b02b-44376fcc7c2e') {
				let fieldId = renderObj.componentId;
				let fieldSettings = FieldSettingsStore.getSettings(fieldId);
				// With logic tab options.
				if(fieldSettings['tabOptions'].includes('logic')) {
					return false;
				}
			}
		} else {
			// Pages have triggers... so itll never be disabled.
			return false;
		}

		return true;
	}

	/**
	 * Determine if this pin is Used.
	 * @param {string} renderId Render ID for the component
	 * @return boolean
	 */
	static calculateIsUnused(renderId) {
		let renderObj = RenderStore.get(renderId);

		// If we dont have a renderObj (yet), then we can't calculate.
		if(!renderObj) { 
			return undefined;
		}

		if(renderObj.componentType === 'field') {
			// Get our parent
			let parentRenderObj = RenderStore.get(renderObj.renderParentId);
			if(parentRenderObj) {
				let fieldSettingsObj = renderObj.attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(renderObj.attachmentId, renderObj.componentId, parentRenderObj.componentId) : FieldSettingsStore.getSettings(renderObj.componentId, parentRenderObj.componentId);
				if(Object.keys(fieldSettingsObj).filter(keyName => {
					return keyName.startsWith('automation-') && fieldSettingsObj[keyName] !== 'null';
				}).length) {
					return false;
				}
			} else {
				return undefined;
			}
		} else {
			let automation = PageStore.getAutomation(renderObj.componentId);
			// getAutomation already checks to see if we have automation and returns null if we do not
			if(!automation) {
				return true;
			}
			if(Object.keys(automation).filter(keyName => {
				return (automation[keyName] !== null && automation[keyName] !== "");
			}).length) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Returns the current state
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf AutomationPin
	 */
	static calculateState(prevState, props) {
		let overlayActive = AdminSettingsStore.getIsOverlayActive('automation');
		let isDisabled = undefined;
		let isUnused = undefined;

		if(overlayActive) {
			// If we already have an isDisabled in the state, then use it...
			isDisabled = ( prevState && typeof prevState.isDisabled === 'boolean' 
				? prevState.isDisabled 
				: this.calculateIsDisabled(props.renderId, props.fieldType));
			
			if (isDisabled === false) {
				isUnused = this.calculateIsUnused(props.renderId);;
			}
		}

		return {
			// Only calculate this once...
			'isDisabled': isDisabled,
			'isUnused': isUnused,
			'overlayActive': overlayActive,
		}
	}

	componentDidMount() {
		this.clickTimeouts = [];
	}

	/**
	 * onPinClickHandler - Handles pin click. 
	 */
	onPinClickHandler(event) {
		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings preview
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		this.clickTimeouts.push(setTimeout(() => {
			UIUtils.openSettingsPreview('automation', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			undefined, undefined, undefined, this.props.renderId, 
			this.props.attachmentId);
			// Time you have between clicks to determine if it was single or double.
		}, 250));
	}

	/**
	 * onPinDoubleClickHandler - Handles pin double click. 
	 */
	onPinDoubleClickHandler(event) {
		this.clickTimeouts.forEach(timeout => {
			clearTimeout(timeout);
		});

		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings panel on the right.
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		UIUtils.openSettingsPanel('automation', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			undefined, undefined, undefined, this.props.renderId,
			this.props.attachmentId);

		// Make sure the settings are toggled to visible.
		AdminSettingsActions.onSettingsListHideChange(false);
	}

	render() {
		let { overlayActive, isUnused, isDisabled } = this.state;
		
		if( overlayActive ) {
			let pinDivClassAppend = (isDisabled 
				? 'disabled' 
				: (isUnused 
					? 'unused' 
					: ''));
			return <div 
						key={this.props.attachmentId || this.props.recordId}
						className={"pin " + pinDivClassAppend}
						ref={el => this.link = el}
						data-trigger="manual"
						data-toggle="popover"
						data-fallback-placement="flip"
						data-html="true"
						data-content=" "
						onClick={(event) => { if(!isDisabled) { this.onPinClickHandler(event) }}}
						onDoubleClick={(event) => { this.onPinDoubleClickHandler(event); }}
					>
							<svg className="automation-pin pin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
								<path d="M12 2C8.1 2 5 5.1 5 9c0 5.2 7 13 7 13s7-7.8 7-13C19 5.1 15.9 2 12 2z" fill="#BBB"/>
							</svg>
							<ReactSVG
								beforeInjection={svg => {
									svg.setAttribute('viewBox', '0 0 30 30');
									svg.setAttribute('style', 'width: 16px; height: 16px');
								}}
								className="automation-pin pin-icon"
								src={ContextStore.getUrlMedia() + "/icon-logic.svg"} />
					</div>
		} else {
			return null;
		}
	}
}

if ('development' === process.env.NODE_ENV) {
  AutomationPin.propTypes = {
    recordId: PropTypes.string.isRequired,
    tableSchemaName: PropTypes.string.isRequired,
    parentRecordId: PropTypes.string,
    parentTableSchemaName: PropTypes.string,
    fieldType: PropTypes.string,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(AutomationPin, {withProps: true});
export default container;
