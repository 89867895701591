import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { AdminSettingsActions, ThemeTemplateActions } from '../../../actions';
import { AdminSettingsStore, FieldStore, ThemeTemplateStore } from '../../../stores';

// Components;
import FieldTypeSettingsUI from '../../fieldtype-settings-ui.react';

/**
 * Right panel displaying Theme Template Settings
 */
class ThemeTemplateSetting extends Component {
	constructor(props) {
		super(props)
		this._onSettingChange = this._onSettingChange.bind(this);
	}
	
	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
	 return [ AdminSettingsStore, FieldStore, ThemeTemplateStore ]; 
	}

	/**
	 * Calculate the current state of the component
	 * @static
	 * @param  {Object} prevState Previous State
	 * @param  {Object} prevProps Previous Props
	 * @return {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId =  AdminSettingsStore.getRecordId();
		let settingRecordId = AdminSettingsStore.getSettingRecordId();
		let settingObj = FieldStore.get(settingRecordId);
		let value = '';

		// Pull the value from the MD Store
		if(settingObj){
			let settingSchemaName = settingObj.fieldSchemaName;

			let scheduleObj = ThemeTemplateStore.get(recordId);
			if(scheduleObj) {
				value = scheduleObj[settingSchemaName];
			}
		}

		return {
			settingObj: settingObj,
			settingRecordId: settingRecordId,
			recordId: recordId,
			tableSchemaName: 'themeTemplate',
			value: value,
			settingsHidden: AdminSettingsStore.getSettingsListHidden(),
		};
	}

	/**
	 * Save the value as a value is entered
	 * @param {Object} event
	 */
	_onSettingChange(event) {
		let { recordId, settingObj } = this.state;
		let settingSchemaName = settingObj.fieldSchemaName;
		let value = '';
		if(settingSchemaName === 'css' || settingSchemaName === 'prefix') {
			value = event;
		} else {
			value = event.target.value;
		}
		let themeTemplateObj = ThemeTemplateStore.get(recordId);

		// Update the API Object with this setting, at its new value
		themeTemplateObj[settingSchemaName] = value;

		// Push the update API Object into the store.
		ThemeTemplateActions.pushToStore(recordId, themeTemplateObj);
	}

	/**
	 * Render the component
	 * @return JSX
	 */
	render() {
		let {recordId, tableSchemaName, value, settingObj, settingsHidden } = this.state;

		if (!settingObj || !settingObj.fieldSchemaName) {
			return (<div className="select-setting">
						<div className="select-setting-text-wrap">
							Select a Setting to configure on the left.
						</div>
					</div>);
		}

		let settingSchemaName = settingObj.fieldSchemaName;
		let settingFieldType = settingObj.fieldType;
		let descriptiveText = settingObj.descriptiveText;
		let label = settingObj.fieldLabel;

		// Update the description to the dark theme.
		// @TODO - Make this a parameter on the normal field types!
		if(settingSchemaName.includes('description')) {
			settingObj.config = {
				// Dark mode
				skin:"oxide-dark",
				content_css:"dark",
			}
		} else if(settingSchemaName === 'lastUpdatedDateTime' || 
			settingSchemaName === 'lastPushedToStage' || 
			settingSchemaName === 'lastPushedToProd') {
				if(value && value.includes('{')) {
					value = JSON.parse(value);
				} else {
					value = '';
				}
			}

		return (
			<div id="appearance-settings">
				<div className="appearance-settings-container mx-3">
					<h3 className='my-1 d-flex align-items-center'>
						{/* Show collapse only when setting has been selected */} 
						{settingsHidden ?
							<button 
								className="btn btn-back pl-0"
								title="Show Theme Template settings." 
								form="appearance-form" 
								onClick={() => {AdminSettingsActions.onSettingsListHideChange(false);}}>
								<img height="26" width="26" src="https://cdn3.citizendeveloper.com/engine-build/citdev-media/v2/expand-settings-list.svg" alt="" />
							</button>
						: null }
						{label}
					</h3>
					<div className="setting-global mt-0 h-100">
						<div className={"setting-wrapper h-100 setting-" + settingSchemaName}>
							<FieldTypeSettingsUI
								recordId={recordId} 
								tableSchemaName={tableSchemaName}
								value={value} 
								setting={settingObj} 
								onChange={this._onSettingChange} 
								fieldType={settingFieldType} 
								label={label}
								skipDescriptiveText={true}
								dataRecordId={recordId}
								dataTableSchemaName={tableSchemaName}
								/>
								{descriptiveText && 
									<small id={'description-' + this.props.renderId} className='form-text text-muted' dangerouslySetInnerHTML={{ __html: descriptiveText || '' }}></small>
								}
						</div>
					</div>
				</div>
			</div>);
	}
}
const container = Container.create(ThemeTemplateSetting, {withProps: true});
export default container;