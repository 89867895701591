import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import ListConstants from '../constants/list-constants';
import FieldComponentUtils from '../utils/field-components';
import FieldStore from '../stores/field-store';
import FieldTypeStore from '../stores/field-type-store';
import FieldSettingsStore from '../stores/field-settings-store';
import RelationshipStore from '../stores/relationship-store';
import ListStore from '../stores/list-store';
import ObjectUtils from '../utils/object-utils';
import uuid from 'uuid';

/**
 * Lists have a lot of moving parts when it comes to calculating their rows.
 * It can be difficult to follow just from the logic, so here's a breakdown:
 * 
 * Lists have two primary moving parts in terms of configuration:
 * 1. The main List query. This controls the main record set for the list,
 * and serves as the "current record" query for any override or other related queries.
 * 
 * 2. Columns. These are all attached fields for the list, each of which needs to receive and display a value.
 * All columns correspond to fields, but their configuration can change wildly within that.
 * In the most basic case, these are just value-storing fields on the table for the list's query.
 * However, these can have a few possible snags, including:
 * 	a. Being an expression-valued field (such as a link or an expression field)
 *	b. Being a dynamic selection field
 *	c. Having a context query override
 * 
 * This results in the following data sets when actually processed:
 * 1. The raw list rows, which are obtained via a query and include record IDs for each row
 * and any columns which store and use data and have no column overrides (the queryFields array).
 * This is the simplest possible configuration for a List, and were this our only use case, we could have stuck with record sets.
 * Indeed, this value corresponds very closely to record set values, and if we didn't have to care about expressions, dynamic selection fields
 * or fields with overridden queries, would be all we needed from the Store for an easy List implementation.
 * In the code, these values are all found within the recordSetRows variable, and represent the values
 * corresponding to the queryFields array.
 * 
 * 2. THIS FUNCTIONALITY HAS BEEN REMOVED AT LEAST TEMPORARILY AS OF 3/9/2020 DUE TO PERFORMANCE ISSUES WITH UNOPTIMIZED QUERIES.
 * The "single-value" relationship query results. When a dynamic selection field whose relationship configuration only
 * permits one value to be selected is included in a query, these values are included when processing
 * queries. These values correspond to the fields in the singleRelationshipFields array and can be accessed using
 * a combination of the recordSetRows and relations variables. These otherwise behave as normal dynamic selection fields.
 * 
 * 3. The "multi-valued" relationship query results. When a dynamic selection field permits multiple
 * values to be selected, the queries to populate this record set must be processed as individual calls to
 * record browse. These queries are stored in parallelQueries with recordSetTemplate information to indicate
 the record sets that we expect them to generate.
 *
 * 4. The "overridden" columns. When a column has a query override, we have to call record-browse with that query
 * in order to get the values for that column. In order to work, this query override must include one (and only one) "record is" filter for
 * the current record on a table which is in some way joined to the return table with "relationship exists".
 * These queries are stored in parallelQueries and do NOT have recordSetTemplate information, nor do they generate record sets unless they are also dynamic selection fields.
 * 
 * 5. The "non-overridden expression-valued" columns. These are columns which have expression settings and do not have context overrides.
 * These expressions can't be processed until the main query is done. At the moment, they are processed in parallel with the
 * dependent queries. (@TODO a potential future optimization to explore would be whether we need to wait for the parallel queries to finish
 * to run these expressions, or if we only need the main query to finish. We could perhaps set this up into two separate
 * Promise chains, one with main query -> expressions and another with parallel queries -> dependent queries, then merge all of
 * the results together once both have finished.) Either way, the expressions are stored in the expressionFields array.
 * 
 * 6. The "overridden dynamic selection field" columns. Related to #4. In addition to having the context query in parallelQueries,
 * these fields must run queries in order to get their values by using the results from their overridden queries.
 * These are stored in depQueries arrays on queries in parallelQueries.
 * 
 * 7. The "overridden expression field-valued" columns. Basically the same as 5, except that they
 * have column context queries. These are stored in expressionMaps arrays on queries in parallelQueries.
 * 
 * These lead to the following different objects being created when running `getDependentInfo`:
 * 	1. fieldsNeedLoading: array of column keys in the format [attachmentKey]-[fieldSchemaName].
 * 	Fields in this array do NOT get their values directly from the result of the mainQuery and so
 * 	display the "Loading..." message while retrieving their values.
 * 	2. queryFields: array of field objects in the form
 * 	{
 * 		fieldSchemaName,			// The field's schema name
 * 		fieldType,					// The record ID of the field's fieldType 
 * 		fieldId,					// The field's record ID
 * 		order,						// The order in which the field is attached to the list
 * 		includeInRows				// Whether the field is to be included in the list as a column (false if it's the lookup for a field-valued expression)
 * 	}
 * 	The fields in this array are fields which represent the fields which will be included in the query to record-browse.
 * 	Not all fields in this array represent columns, as some are just in there to get the value for field-valued expressions
 * 
 * 	3. expressionFields: array of expressions which run with a current record set represented by the 'main' query.
 * 	The expression objects in this array have the form
 * 	{
 * 		optimizationScheme,			// The expression's optimization scheme (needed when evaluating)
 * 		optimizationData,			// Any optimization data (such as static value, the field being used, etc.)
 * 		expressionTSN,				// The table on which the expression is (@TODO: Evaluate if we use this)
 * 		expressionFieldId,			// The record ID of the field for which this expression is being evaluated (@TODO: Is this used?)
 * 		settingSchemaName,			// All expressions come from a setting (linkText, etc.). This is that setting's schema name
 * 		expressionForColumn,		// Which column on the list is this expression for? (Value is in the column name format of `${order}-${fieldSchemaName}`)
 * 		expressionFieldSchemaName	// The fieldSchemaName of the field for which this expression is being evaluated (matches above, but without the `${order}-' prefix)
 * 	}
 * 
 * 	4. singleRelationshipFields: array of dynamic selection fields which, by the constrictions of
 * 	their relationships, will only ever have _one_ value. We handle these separately because
 * 	our record browse handling has support for single-valued dynamic selection fields, so we can process these
 * 	alongside our main query and save on extra record-browse calls.
 * 	The field objects have the form
 * 	{
 * 		fieldId,				// The record ID of the dyn sel field
 * 		fieldSchemaName,		// The FSN of the dyn sel field
 * 		relationSchemaName,		// The RSN of the relationship the dyn sel field manages
 * 		resultTableSchemaName,	// This field selects records from which table?
 * 		viewFieldSchemaName,	// The FSN of the field displayed in view mode
 * 		editFieldSchemaName,	// The FSN of the field displayed in edit mode
 * 		columnFieldMap: {		// Object used to help map the record browse results to the dyn sel's entries in rows
 * 			fieldSchemaName,	// Get the value in this field
 * 			columnSchemaName,	// And put it in this column (format is the usual `${order}-${fielSchemaName}`) setup)
 * 			columnId,			// The column field's field ID
 * 			useResultData		// Boolean for whether to use the result as the dataRecordId and dataTableSchemaName. Always false here
 * 		}
 * 	}
 * 
 * 	5. parallelQueries: array of queries to be run in parallel with the main query.
 * 	This is a *big* one, because in addition to handling all of the queries for our columns with overrides
 * 	AND for dynamic selection fields, it also contains information required to run the "dependent" values
 * 	(those being expressions and dynamic selection fields which have context overrides). These parallel queries
 * 	contain all queries which must be run, and the query array goes through a 'compressor' such that where multiple columns have the same
 * 	query (for example, two columns on the same relate record), they will be compressed into one
 * 	parallelQuery entry which includes the information required for all fields which rely on this query.
 * 	When parallelQueries is first built, the parallelQuery objects have the form
 * 	{
 * 		query,					// The query to run
 * 		fields: [				// Same as the format for queryFields
 * 			{
 * 				fieldSchemaName,
 * 				fieldType,
 * 				fieldId,
 * 				order,
 * 				includeInRows
 * 			}
 * 		],
 * 		columnFieldMap: {		// Required. Used to map the query results to row columns. Same format as in singleRelationshipFields
 * 			fieldSchemaName,	// Get the value in this field
 * 			columnSchemaName,	// And put it in this column (format is the usual `${order}-${fielSchemaName}`) setup)
 * 			columnId,			// The column field's field ID
 * 			useResultData		// Boolean for whether to use the result as the dataRecordId and dataTableSchemaName.
 * 			dataType			// The dataType of this column. Used primarily to identify whether this query is for an override or to populate a dyn sel field
 * 		},
 * 		expressionInfo: {		// Optional. Expression information if this is an expression field. Format is nearly the same as in expressionFields
 * 			optimizationScheme,			// The expression's optimization scheme (needed when evaluating)
 * 			optimizationData,			// Any optimization data (such as static value, the field being used, etc.)
 * 			expressionTSN,				// The table on which the expression is (@TODO: Evaluate if we use this)
 * 			expressionFieldId,			// The record ID of the field for which this expression is being evaluated (@TODO: Is this used?)
 * 			settingSchemaName,			// All expressions come from a setting (linkText, etc.). This is that setting's schema name
 * 			expressionForColumn,		// Which column on the list is this expression for? (Value is in the column name format of `${order}-${fieldSchemaName}`)
 * 			expressionFieldSchemaName	// The fieldSchemaName of the field for which this expression is being evaluated (matches above, but without the `${order}-' prefix)
 * 		},
 * 		recordSetTemplates: [{	// Array of information needed to build record sets from the query results
 * 			setName,			// The internal name of the record set
 * 			uiName,				// The citdev-facing name of the record set
 * 			tableSchemaName		// The table of the record set's records
 * 		}],
 * 		depQueries: [{			// Array of queries to be run after this query finishes, with a current context corresponding to this query. (Primarily used to get the values for record sets which have context overrides)
 * 			fieldId,				// The ID of the field for which to run this dep query
 * 			fieldSchemaName,		// The column for which this query is to be run
 * 			relationSchemaName,		// Which relationship is this field managing, if a dyn sel field (which it usually is)?
 * 			resultTableSchemaName,	// The TSN for the dependent query result
 * 			viewFieldSchemaName,	// The FSN of the field for view mode
 * 			editFieldSchemaName,	// The FSN of the field for edit mode
 * 			columnFieldMap: {		// Used to map the query results to row columns. Same format as in singleRelationshipFields
 * 				fieldSchemaName,	// Get the value in this field
 * 				columnSchemaName,	// And put it in this column (format is the usual `${order}-${fielSchemaName}`) setup)
 * 				columnId,			// The column field's field ID
 * 				useResultData		// Boolean for whether to use the result as the dataRecordId and dataTableSchemaName. (Will always be false for dep queries at the moment)
 * 			}
 * 			query,				// Query to run
 * 			fields,				// Fields on the query
 * 			recordSetTemplates: [{		// The same as recordSetTemplates above
 * 				setName,				// As above
 * 				uiName,					// As above
 * 				tableSchemaName			// As above
 * 			}]
 * 		}]
 * 	}
 * 
 * 	After being run through the query compressor, it changes format slightly to
 * 
 * 	{
 * 		fields,					// Same as above, but with fields combined into one array where duplicate queries exist
 * 		columnFieldMaps,		// Array of columnFieldMap object, in the format from above
 * 		recordSetTemplates,		// Same as above, but with record sets combined into one array where duplicate queries exist
 * 		expressionMaps,			// Array of expressionInfo objects
 * 		depQueries				// Same as above, but with depQuery objects combined into one array where duplicate queries exist
 * 	}
 * 
 * 
 * The general workflow of the List lifecycle as relates to this store is then this:
 * 	1. Initialize the field and figure out its columns (done in the component)
 * 	2. Run initializeList
 * 	3. Figure out all of the variables above using getDependentInfo
 * 	4. Run the 'main' query and then dispatch its results to the List store (to populate the List partially while the other fields are loading). The record set store and render store will also listen to this so as to update their record sets + render store entries appropriately
 * 	5. In parallel with the 'main' query, loop over and run all of the parallelQueries.
 * 	6. Once the parallel queries and the main query have both finished, merge the parallel results into the rows from the main query using mergeParallelQueryResultsIntoRows
 * 	7. Process both the expressions in expressionFields AND the dependent queries + expressions in the parallelQueries array
 * 	8. Merge both expressionResults and parallelResults from the above method into listRows
 * 	9. Dispatch to the List store with the now completed List rows
 * 
 * 	The lifecycle is slightly different when a record set is recalculated.
 * 	When a record set for a List is recalculated, the List store listens to the dispatch
 * 	Inside the List store, it will take the record set rows and reformat them to match the format of List's rows,
 * 	similar to the functionality in runMainQuery, and will mark that the List's dependent values need recalculation.
 * 	The List component itself will then run recalculateDependentValues, which from the List store gets the record set rows
 * 	the list rows as they currently exist, and the variable generated by getDependentInfo and enumerated above, and
 * 	then does the following:
 * 	1. Run all of the parallel queries
 * 	2. Once those have finished, merge them into the list rows using mergeParallelQueryResultsIntoRows
 * 	3. As above, process both the expressions in expressionFields AND the dependent queries + expressions in the parallelQueries array
 * 	4. Merge both expressionResults and parallelResults from the above method into listRows
 * 	5. Dispatch to the List store with the now completed List rows, marking the List as no longer needing its dependencies recalculated.
 * 
 * As a note, rows are of the form
 * 	[{
 * 		dataRecordId,			// The recordId of the row's record
 * 		dataTableSchemaName,	// The TSN of the row's record
 * 		// This is the format used for all displayed columns on the List
 * 		// It was chosen to best match the specific settings passed in to each field component when rendered by a List Cell, as each of these entries is one List Cell
 * 		[`${order}-${columnSchemaName}`]: {
 * 			isLoading,				// Whether or not the field is waiting for its value (used for columns not a part of the main query's fields)
 * 			displayValue,			// The value displayed in the field. Used when sorting and filtering
 * 			value,					// The actual value passed in when rendering the field in the List Cell. Not always the same as displayValue
 * 			dataTableSchemaName,	// The TSN of the record data for the cell. Doesn't always match that of the row
 * 			dataRecordId,			// The record ID of the record data for the cell. Doesn't always match that of the row
 * 			[expressionSetting]: ''	// Results when calculating the expression for the cell, if provided
 * 			// Other settings corresponding to settings for an individual field, such as waitForRecordSets, may be added in here
 * 		}
 * 	}]
 * 
 * parallelResults, meanwhile, start out as an array of results from processQueryBulk, meaning it's of the form:
 * 
 * 	[{
 * 		records: {	// Results from the parallel query. These are records returned by the parallel query, NOT records from list rows
 * 			[tableSchemaName]: {	// Set of record objects, keyed by TSN. Will always only be one
 * 				[recordId]: {			// Record object, keyed by record ID
 * 					recordId,					// The record's record ID is also in the object
 * 					// May have any number of fieldSchemaName values, depending on how many fields were included
 * 					[fieldSchemaName]: value	// The value of each field included in the query
 * 				}
 * 			}
 * 		},
 * 		contextMap: {	// This exists to map each row from the starting context (in this case, the main query) to all of the corresponding record IDs.
 * 			[rowRecordId] : // The source row's record ID
 * 				[columnRecordId, ...] // Array of record IDs for records related to the row (to be looked up in the records object)
 * 		}
 * 	}]
 * 
 * 
 * Once dependent queries and expressions are processed, each record in the records array
 * is updated with new values corresponding to the expression and dynamic selection columns.
 * The expression values are just the expression value, while the dynamic selection values are
 * in the form {recordIds, values}, where recordIds is an array of related records and
 * values corresponds to the display value of the cell, being a comma (and space)-separated list of the view field
 * values for each record.
/** @type {*} */
const ListActions = {
	/**
	 * The purpose of this method is to calculate a List's rows from scratch and then 
	 * dispatch List events in order to populate the List store. It includes processing
	 * the main List query, processing all expressions + subsidiary queries, combining 
	 * the results of all of that into ListRows, and dispatching it to the store.
	 * 
	 * Notably, running this will result in TWO dispatches to the List store, one for the basic List rows before any expressions,
	 * multi-valued dynamic selection fields or expressions come in, and one for after all of those
	 * dependent values are processed. (@TODO: Can we clean this up so that if there are no dependent values, it only dispatches once? We do occasionally have Lists without dependent value columns.)
	 * 
	 * It does NOT handle sorting or filtering, which is entirely the domain of the List component.
	 * It is only used when instantiating a List 'from scratch' (mainly when the List component mounts or
	 * if any of its props or stateful values which are passed into this method have changed).
	 * 
	 * It is NOT used when recalculating a List's existing record set.
	 *
	 * @param {string} renderId The render ID of the list field. Used to distinguish multiple instances of a single List within a browser window.
	 * @param {string} listFieldId The record ID of the list field.
	 * @param {string} setName The internal name used for the record set for the list. Used by the record set store + to help sync up the list and record set store data.
	 * @param {string} setUIName The CitDev-facing name for the record set. Used purely by the record set store.
	 * @param {string|object} query The query for the main list records. Each record returned from this query will create a new row in list rows.
	 * @param {array<object>} fields The fields used by the list. This includes ALL fields, regardless of data type or context override.
	 * @param {boolean} mergeIn Optional parameter to merge the results into the existing rows rather than reset the rows entirely.
	 */
	initializeList(renderId, listFieldId, setName, setUIName, query, fields, mergeIn) {
		let startTime;
		let {
			fieldsNeedLoading,
			queryFields,
			expressionFields,
			singleRelationshipFields,
			parallelQueries
		} = getDependentInfo(query, fields, listFieldId);
		let listRows = null;
		let recordSetRows = null;
		let rowLookup = {};
		let recordSets = {
			[renderId]: {}
		};
		// This will run the 'main' query which drives the list and populate the initial rows
		// This includes all non-relationship fields on the list which have values and any dynamic selection fields which only have single values
		// It does NOT include dynamic selection fields which may have multiple values, fields with query overrides, or expressions
		let mainQueryPromise = new Promise((resolve, reject) => {
			runMainQuery(renderId, setName, setUIName, query, queryFields, singleRelationshipFields, recordSets, fieldsNeedLoading, mergeIn)
				.then(mainQueryResults => {
					startTime = mainQueryResults.startTime;
					listRows = mainQueryResults.listRows;
					rowLookup = mainQueryResults.rowLookup;
					recordSets = mainQueryResults.recordSets;
					recordSetRows = mainQueryResults.recordSetRows;
					// We dispatch our initial, er, dispatch here in order to populate the list without having to wait for the expressions
					AppDispatcher.dispatch(Immutable.fromJS({
						type: ListConstants.INIT_LIST,
						renderId: renderId,
						rows: listRows,
						recordSets: recordSets,
						recordSetRows: recordSetRows,
						setName: setName,
						query: query,
						fields: queryFields,
						fieldsNeedLoading,
						queryFields,
						expressionFields,
						singleRelationshipFields,
						parallelQueries,
						setUIName: setUIName,
						lastDt: startTime,
						dependenciesNeedRecalculation: false,
						mergeIn: !!mergeIn
					}));
					return resolve();
				})
				.catch(reject);
		});
		// Get the results for all queries which can be run in parallel with the main query
		// (Dynamic selection fields w/o overrides and column override queries)
		let parallelQueryPromises = parallelQueries.map((queryInfo) => {
			let depQuery = queryInfo.query;
			let depFields = queryInfo.fields;
			return FieldComponentUtils.query.processQueryBulk(depQuery, depFields, renderId, null, null, query);
		});
		Promise.all([Promise.all(parallelQueryPromises), mainQueryPromise])
			.then(([parallelResults]) => {
				// Now add in the parallel results
				mergeParallelQueryResultsIntoRows(listRows, parallelQueries, parallelResults, recordSets);
				// Now run any expressions or other dependent values
				return Promise.all([runExpressions(recordSetRows, expressionFields, listFieldId, renderId), processDependentCalculations(renderId, listFieldId, parallelQueries, parallelResults)]);
			})
			.then(([expressionResults, parallelResults]) => {
				// Use a non-pure method to mutate listRows/rowLookup in place
				// with the new values added in appropriately
				mergeExpressionsIntoListRows(rowLookup, expressionFields, expressionResults);
				mergeDependentValuesIntoRows(rowLookup, listRows, parallelQueries, parallelResults, recordSets);
				AppDispatcher.dispatch(Immutable.fromJS({
					type: ListConstants.INIT_LIST,
					renderId: renderId,
					rows: listRows,
					recordSets: recordSets,
					recordSetRows: recordSetRows,
					setName: setName,
					query: query,
					fields: queryFields,
					fieldsNeedLoading,
					queryFields,
					expressionFields,
					singleRelationshipFields,
					parallelQueries,
					setUIName: setUIName,
					lastDt: startTime,
					mergeIn: !!mergeIn
				}));
			})
			.catch(console.error);
	},

	/**
	 * Similar to initList, but meant for refreshing Lists nondisruptively, meaning that it waits until all results are in
	 * in order to return and merges the values into the existing rows.
	 * @param {string} renderId The render ID of the list field. Used to distinguish multiple instances of a single List within a browser window.
	 * @param {string} listFieldId The record ID of the list field.
	 * @param {string} setName The internal name used for the record set for the list. Used by the record set store + to help sync up the list and record set store data.
	 * @param {string} setUIName The CitDev-facing name for the record set. Used purely by the record set store.
	 * @param {string|object} query The query for the main list records. Each record returned from this query will create a new row in list rows.
	 * @param {array<object>} fields The fields used by the list. This includes ALL fields, regardless of data type or context override.
	 */
	nondisruptiveRefresh(renderId, listFieldId, setName, setUIName, query, fields) {
		let startTime;
		let {
			fieldsNeedLoading,
			queryFields,
			expressionFields,
			singleRelationshipFields,
			parallelQueries
		} = getDependentInfo(query, fields, listFieldId);
		let listRows = null;
		let recordSetRows = null;
		let rowLookup = {};
		let recordSets = {
			[renderId]: {}
		};
		let mainQueryPromise = new Promise((resolve, reject) => {
			runMainQuery(renderId, setName, setUIName, query, queryFields, singleRelationshipFields, recordSets, fieldsNeedLoading, true)
				.then(mainQueryResults => {
					startTime = mainQueryResults.startTime;
					listRows = mainQueryResults.listRows;
					rowLookup = mainQueryResults.rowLookup;
					recordSets = mainQueryResults.recordSets;
					recordSetRows = mainQueryResults.recordSetRows;
					return resolve();
				})
				.catch(reject);
		});
		// Basically, no new init until it's finished processing everything
		// Also be sure to preserve existing heights
		// Get the results for all queries which can be run in parallel with the main query
		// (Dynamic selection fields w/o overrides and column override queries)
		let parallelQueryPromises = parallelQueries.map((queryInfo) => {
			let depQuery = queryInfo.query;
			let depFields = queryInfo.fields;
			return FieldComponentUtils.query.processQueryBulk(depQuery, depFields, renderId, null, null, query);
		});
		Promise.all([Promise.all(parallelQueryPromises), mainQueryPromise])
			.then(([parallelResults]) => {
				// Now add in the parallel results
				mergeParallelQueryResultsIntoRows(listRows, parallelQueries, parallelResults, recordSets);
				// Now run any expressions or other dependent values
				return Promise.all([runExpressions(recordSetRows, expressionFields, listFieldId, renderId), processDependentCalculations(renderId, listFieldId, parallelQueries, parallelResults)]);
			})
			.then(([expressionResults, parallelResults]) => {
				// Use a non-pure method to mutate listRows/rowLookup in place
				// with the new values added in appropriately
				mergeExpressionsIntoListRows(rowLookup, expressionFields, expressionResults);
				mergeDependentValuesIntoRows(rowLookup, listRows, parallelQueries, parallelResults, recordSets);
				AppDispatcher.dispatch(Immutable.fromJS({
					type: ListConstants.INIT_LIST,
					renderId: renderId,
					rows: listRows,
					recordSets: recordSets,
					recordSetRows: recordSetRows,
					setName: setName,
					query: query,
					fields: queryFields,
					fieldsNeedLoading,
					queryFields,
					expressionFields,
					singleRelationshipFields,
					parallelQueries,
					setUIName: setUIName,
					lastDt: startTime,
					mergeIn: true
				}));
			})
			.catch(console.error);
	},

	/**
	 * This runs slightly differently than above because we have the record set rows
	 * but haven't run the 'parallel' queries as a part of recalculating the record set.
	 * 
	 * This method does not accept fields because it assumes they're already in the List store.
	 * 
	 * Unlike above, this will only dispatch to the List store once, since it doesn't need the
	 * initial dispatch to set up the List whlie the dependent values are loading.
	 *
	 * @param {string} renderId The render ID of the list field. Used to distinguish multiple instances of a single List within a browser window.
	 * @param {string} listFieldId The record ID of the list field.
	 * @param {string} setName The internal name used for the record set for the list. Used by the record set store + to help sync up the list and record set store data.
	 * @param {string} setUIName The CitDev-facing name for the record set. Used purely by the record set store.
	 * @param {string|object} query The query for the main list records. Each record returned from this query will create a new row in list rows.
	 */
	recalculateDependentValues(renderId, listFieldId, setName, setUIName, query) {
		// First, get the actual value in the record set store for the list rows
		// Then do the appropriate postprocessing using the various utility methods
		let listEntry = ListStore.getAsJS(renderId);
		if (!listEntry) {
			return Promise.resolve();
		}
		let listRows = listEntry.rows || [];
		let recordSets = listEntry.recordSets || {};
		let recordSetRows = listEntry.recordSetRows || [];
		let queryFields = listEntry.queryFields || [];
		let fieldsNeedLoading = listEntry.fieldsNeedLoading || [];
		let singleRelationshipFields = listEntry.singleRelationshipFields || [];
		let lastDt = listEntry.lastDt || [];
		let rowLookup = {};
		listRows.forEach(row => rowLookup[row.recordId] = row);
		// We already calculated this when initializing the list, so we can reuse it
		let {
			expressionFields,
			parallelQueries
		} = listEntry;
		let parallelQueryPromises = parallelQueries ?
			parallelQueries.map((queryInfo) => {
				let depQuery = queryInfo.query;
				let depFields = queryInfo.fields;
				return FieldComponentUtils.query.processQueryBulk(depQuery, depFields, renderId, null, null, query);
			}) :
			[];
		return Promise.all(parallelQueryPromises)
			.then(parallelResults => {
				mergeParallelQueryResultsIntoRows(listRows, parallelQueries, parallelResults, recordSets);
				// Now run any expressions or other dependent values
				return Promise.all([runExpressions(recordSetRows, expressionFields, listFieldId, renderId), processDependentCalculations(renderId, listFieldId, parallelQueries, parallelResults)]);
			})
			.then(([expressionResults, parallelResults]) => {
				// Use a non-pure method to mutate listRows/rowLookup in place
				// with the new values added in appropriately
				mergeExpressionsIntoListRows(rowLookup, expressionFields, expressionResults);
				mergeDependentValuesIntoRows(rowLookup, listRows, parallelQueries, parallelResults, recordSets);
				AppDispatcher.dispatch(Immutable.fromJS({
					type: ListConstants.SET_LIST_ROWS,
					renderId: renderId,
					rows: listRows,
					recordSets: recordSets,
					recordSetRows: recordSetRows,
					setName: setName,
					query: query,
					fields: queryFields,
					fieldsNeedLoading,
					queryFields,
					expressionFields,
					singleRelationshipFields,
					parallelQueries,
					setUIName: setUIName,
					lastDt: lastDt,
					dependenciesNeedRecalculation: false
				}));
			})
			.catch(console.error);
	},
	/**
	 * Triggers a height recalculation for a List using the stored refs
	 * 
	 * @param {string} renderId  The renderId of the list
	 * @returns 
	 */
	recalculateHeights(renderId) {
		let listEntry = ListStore.getAsJS(renderId);
		if(!listEntry) {
			return;
		}
		let refs = listEntry.refs;
		if(!refs) {
			return;
		}

		let heights = {};
		// Refs should be of the form {[recordId]: {[columnKey]}}
		Object.keys(refs).forEach(recordId => {
			heights[recordId] = heights[recordId] || {};
			// let maxHeight = 0;
			Object.keys(refs[recordId]).forEach(columnKey => {
				let columnRef = refs[recordId][columnKey];
				if(columnRef) {
					let cell = columnRef.parentElement;
					if(cell) {
						try {
							let computedStyle = window.getComputedStyle(cell);
							// be VERY careful with this.  Zooming on the browser causes the size of the border to change to a decimal 
							// value - so in order to stop lists for shrinking or growing indefinitely we need to properly round them.
							// Ticket # 29850 and 30455
							heights[recordId][columnKey] = Math.round(columnRef.scrollHeight + parseFloat(computedStyle['paddingTop']) 
								+ parseFloat(computedStyle['paddingBottom']) 
								+ parseFloat(computedStyle['border-bottom-width']) 
								+ parseFloat(computedStyle['border-top-width']));
						} catch(err) {
							console.error('error getitng computed style of cell', cell);
							console.error(err);
						}
					}
				}
			});
		});
		AppDispatcher.dispatch(Immutable.fromJS({
			type: ListConstants.SET_ROW_HEIGHTS,
			renderId: renderId,
			heights: heights
		}));
	},
	/**
	 * Straightforward method to remove a List from the List store, as when the component
	 * is about to be unmounted.
	 *
	 * @param {string} renderId The renderId of the List entry being deleted
	 */
	deleteList(renderId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: ListConstants.DELETE_LIST,
			renderId: renderId
		}));
	},

	/**
	 * Method to update the heights of the rows in a List, used for dynamic heights
	 *
	 * @param {string} renderId The renderId of the List entry being deleted
	 * @param {object} heights An object with the row record IDs and row heights as key/value pairs
	 */
	setRowHeights(renderId, heights) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: ListConstants.SET_ROW_HEIGHTS,
			renderId: renderId,
			heights: heights
		}));
	},

	/**
	 * Method to update the refs of the rows in a List, used for dynamic heights
	 *
	 * @param {string} renderId The renderId of the List entry being deleted
	 * @param {object} refs An object with the row record IDs and row heights as key/value pairs
	 */
	 setRowRefs(renderId, refs) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: ListConstants.SET_REFS,
			renderId: renderId,
			refs: refs
		}));
	}
};

/**
 * Gets field information in a format that can be used
 * when calculating record sets and post-expression calculation work
 *
 * @param {string|object} query The 'main' query for the List. The queryFields will correspond to this.
 * @param {array} fields Array of field objects. The purpose of this method is to loop over this array and classify the fields
 * in order to determine how best to process them subsequently.
 * 
 * @returns {object} Returns an object with arrays of fields broken down by their
 * role in the List. See the top of the page for more information on these fields.
 * Return value is of the form
 * 
 * {
 *	queryFields,
 *	expressionFields,
 *	singleRelationshipFields,
 *	parallelQueries,
 *	fieldsNeedLoading
 * }
 */
function getDependentInfo(query, fields, parentFieldId) {
	// Catch-all array for fields which need loading, so that we can easily initialize them as isLoading before we populate their values
	let fieldsNeedLoading = [];

	// Fields included in the main query
	let queryFields = [];
	// Expression fields processed using the results from the main query
	let expressionFields = [];

	// Related fields with single values, which can be run as a part of processQuery
	// @TODO: Check to make sure this is actually a performance gain or if it should all just be in parallel queries
	let singleRelationshipFields = [];
	// Queries to run in parallel with the main query (primarily column overrides + non-single-value dyn selection fields)
	let parallelQueries = [];

	// @TODO: Can we eliminate some of the unnecessary TSN lookups?
	let tableSchemaName = FieldComponentUtils.query.getReturnTable(query);
	if(fields) {
		fields.forEach((field) => {
			let { recordId: fieldId, order, attachmentId } = field;
			let fieldObj = FieldStore.get(fieldId);
			// Skip nonexistent fields
			if (!fieldObj) {
				return;
			}

			// Get field type information
			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType);
	
			// Skip fields with missing field types
			if (!fieldTypeObj) {
				return;
			}
	
			// If we have any expression settings on the field, we need to make sure to process them appropriately
			let fieldTypeSettings = fieldTypeObj.settings || [];
			let columnSettings = attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, fieldId, parentFieldId) : FieldSettingsStore.getSettings(fieldId, parentFieldId);
			fieldTypeSettings.forEach(ftSetting => {
				let settingId = ftSetting.recordId;
				let settingObj = FieldStore.get(settingId);
				if (!settingObj) {
					return;
				}
				let settingFieldSchemaName = settingObj.fieldSchemaName;
	
				// If it's an expression field...
				if (
					// If it's an expression field...
					settingObj.fieldType === 'd7183192-d4d0-42e9-a1f6-78f3984cef8c' &&
	
					// If we have a value for it in our field settings
					settingFieldSchemaName &&
					columnSettings[settingFieldSchemaName]
				) {
					let expressionObj = ObjectUtils.getObjFromJSON(columnSettings[settingFieldSchemaName]);
					if (expressionObj &&
						expressionObj.optimizationScheme === 'singleField' &&
						expressionObj.optimizationData &&
						expressionObj.optimizationData.fieldData
					) {
						let field = {
							fieldSchemaName: expressionObj.optimizationData.fieldData.fieldSchemaName,
							fieldType: expressionObj.optimizationData.fieldData.fieldTypeId,
							fieldId: expressionObj.optimizationData.fieldData.fieldId,
							order: order,
							includeInRows: false
						};
						if (columnSettings.query && FieldComponentUtils.query.queryIsValid(columnSettings.query)) {
							// If the column has a context override query, it needs to be handled specially
							let infoForQuery = {
								// From these records
								query: columnSettings.query,
								// Get this value
								fields: [field],
								// Store it according to this mapping
								columnFieldMap: {
									// Store the data from this field
									fieldSchemaName: expressionObj.optimizationData.fieldData.fieldSchemaName,
									// In this column
									columnSchemaName: order + '-' + fieldObj.fieldSchemaName,
									// We may not actually need this but we store the column's field ID anyway
									columnId: fieldId,
									// The results from this query will be used for this column's dataTableSchemaName and dataRecordId
									useResultData: true
								},
								expressionInfo: {
									optimizationScheme: expressionObj.optimizationScheme,
									optimizationData: expressionObj.optimizationData,
									expressionTSN: tableSchemaName,
									expressionFieldId: fieldId,
									settingSchemaName: settingFieldSchemaName,
									expressionForColumn: order + '-' + fieldObj.fieldSchemaName,
									expressionFieldSchemaName: fieldObj.fieldSchemaName,
									attachmentId
								}
							};
							// We process this as one of the parallel queries, then add its results in via that fashion
							parallelQueries.push(infoForQuery);
						} else {
							// This expression belongs with the 'main' query
							queryFields.push(field);
							// And we also need to process the expression
							expressionFields.push({
								optimizationScheme: expressionObj.optimizationScheme,
								optimizationData: expressionObj.optimizationData,
								expressionTSN: tableSchemaName, // @TODO: Do we actually use this? Should we remove this, or update it to match the override?
								expressionFieldId: fieldId,
								settingSchemaName: settingFieldSchemaName,
								expressionForColumn: order + '-' + fieldObj.fieldSchemaName,
								expressionFieldSchemaName: fieldObj.fieldSchemaName,
								attachmentId
							});
						}
					} else {
						if (columnSettings.query && FieldComponentUtils.query.queryIsValid(columnSettings.query)) {
							// If this is an overridden column, we need to get the single field value from those results
							// So process the query for this expression and include the results
							
							let infoForQuery = {
								// From these records
								query: columnSettings.query,
								// Get this value
								fields: [],
								// Store it according to this mapping
								columnFieldMap: {
									// Store the data from this field
									fieldSchemaName: expressionObj && expressionObj.optimizationData &&  expressionObj.optimizationData.fieldData && expressionObj.optimizationData.fieldData.fieldSchemaName ?
										expressionObj.optimizationData.fieldData.fieldSchemaName :
										'',
									// In this column
									columnSchemaName: order + '-' + fieldObj.fieldSchemaName,
									// We may not actually need this but we store the column's field ID anyway
									columnId: fieldId,
									// The results from this query will be used for this column's dataTableSchemaName and dataRecordId
									useResultData: true
								},
								expressionInfo: {
									optimizationScheme: expressionObj.optimizationScheme,
									optimizationData: expressionObj.optimizationData,
									expressionTSN: tableSchemaName,
									expressionFieldId: fieldId,
									settingSchemaName: settingFieldSchemaName,
									expressionForColumn: order + '-' + fieldObj.fieldSchemaName,
									expressionFieldSchemaName: fieldObj.fieldSchemaName
								}
							};
							// We process this as one of the parallel queries, then add its results in via that fashion
							parallelQueries.push(infoForQuery);
						} else {
							expressionFields.push({
								optimizationScheme: expressionObj.optimizationScheme,
								optimizationData: expressionObj.optimizationData,
								expressionTSN: tableSchemaName,
								expressionFieldId: fieldId,
								settingSchemaName: settingFieldSchemaName,
								expressionForColumn: order + '-' + fieldObj.fieldSchemaName,
								expressionFieldSchemaName: fieldObj.fieldSchemaName,
								attachmentId
							});
						}
					}
				}
			});
	
			// Add in the field data type and schema name to make our life easier
			field.dataType = fieldTypeObj.dataType;
			field.fieldSchemaName = fieldObj.fieldSchemaName;
			
			// The query processor actually handles some relationship fields, so we include them in our queries
			// However, it does not handle expression-valued fields
			if (fieldTypeObj.dataType !== 'none') {
				field.includeInRows = true;
				if (columnSettings.query && FieldComponentUtils.query.queryIsValid(columnSettings.query)) {
					fieldsNeedLoading.push(order + '-' + fieldObj.fieldSchemaName);
					// If the column has a query, treat it similarly to the dynamic selection fields
					let overrideInfo = {
						query: columnSettings.query,
						fields: [fieldObj],
						columnFieldMap: {
							// Get the query value in this field
							fieldSchemaName: fieldObj.fieldSchemaName,
							// And put it in this field
							columnSchemaName: order + '-' + fieldObj.fieldSchemaName,
							// The column's columnId is
							columnId: fieldObj.recordId,
							// The results from this query will be used for this column's dataTableSchemaName and dataRecordId
							useResultData: true,
							dataType: fieldTypeObj.dataType
						},
						resultTableSchemaName: FieldComponentUtils.query.getReturnTable(columnSettings.query)
					};
					parallelQueries.push(overrideInfo);
				} else {
					queryFields.push(field);
				}
			} else {
				fieldsNeedLoading.push(order + '-' + fieldObj.fieldSchemaName);
			}
			// If it's a relationship field, _also_ push it into the relationship fields array
			if (fieldTypeObj.dataType === 'relationship') {
				fieldsNeedLoading.push(order + '-' + fieldObj.fieldSchemaName);
	
				let relationshipSelector = columnSettings.relationshipSelector ?
					ObjectUtils.getObjFromJSON(columnSettings.relationshipSelector) :
					null;
				if (!relationshipSelector) {
					console.warn('No relationshipSelector value for attached field %s', fieldId);
					return;
				}
				let { relationId, direction } = relationshipSelector;
				let relationObj = RelationshipStore.get(relationId);
				if (!relationObj) {
					console.warn('No relationship found for relationship %s on attached field %s', relationId, fieldId);
					return;
				}
	
				// Special handling for intratable 1-1 relationships
				let { relationSchemaName, lCardinality, rCardinality, lTableSchemaName, rTableSchemaName } = relationObj;
				if (lCardinality === '1' && rCardinality === '1' && lTableSchemaName === rTableSchemaName) {
					// Intratable 1-1 relationships need special handling
					relationSchemaName += direction === 'rtol' ? '_l' : '_r';
				}
				let resultTableSchemaName = direction === 'ltor' ?
					rTableSchemaName :
					lTableSchemaName;
				// This seems backwards from above, but is correct
				let resultCardinality = direction === 'ltor' ?
					lCardinality :
					rCardinality;
	
				// Now get the view and edit mode field information
				let viewFieldSchemaName = '';
				if (columnSettings.viewMode && columnSettings.viewMode[0] === '{') {
					let viewField = ObjectUtils.getObjFromJSON(columnSettings.viewMode);
					let viewFieldObj = FieldStore.get(viewField.fieldId);
					viewFieldSchemaName = viewFieldObj.fieldSchemaName;
				} else {
					viewFieldSchemaName = columnSettings.viewMode;
				}
				let editFieldSchemaName = '';
				if (columnSettings.editMode && columnSettings.editMode[0] === '{') {
					let editField = ObjectUtils.getObjFromJSON(columnSettings.editMode);
					let editFieldObj = FieldStore.get(editField.fieldId);
					editFieldSchemaName = editFieldObj.fieldSchemaName;
				} else {
					editFieldSchemaName = columnSettings.editMode;
				}
	
				let relatedFieldInfo = {
					fieldId,
					fieldSchemaName: order + '-' + fieldObj.fieldSchemaName,
					relationSchemaName,
					resultTableSchemaName,
					viewFieldSchemaName,
					editFieldSchemaName,
					columnFieldMap: {
						// Get the query value in this field
						fieldSchemaName: viewFieldSchemaName,
						// And put it in this field
						columnSchemaName: order + '-' + fieldObj.fieldSchemaName,
						// The column's columnId is
						columnId: fieldObj.recordId,
						// Because this is the query to get the values, it will NOT be used for the dataRecordId and dataTableSchemaName
						useResultData: false
					}
				};
	
				if (columnSettings.query && FieldComponentUtils.query.queryIsValid(columnSettings.query)) {
					// If this is an overridden column, we need to process its query and then process the record set results
	
					// Add in additional necessary related field info
					relatedFieldInfo.query = getValueLookupQuery(relationshipSelector);
					relatedFieldInfo.fields = [viewFieldSchemaName, editFieldSchemaName];
					relatedFieldInfo.recordSetTemplates = [
						{
							setName: fieldId + '-original',
							uiName: 'Original User Selected Record(s)',
							tableSchemaName: resultTableSchemaName
						},
						{
							setName: fieldId + '-selected',
							uiName: 'Current User Selected Record(s)',
							tableSchemaName: resultTableSchemaName
						}
					];
	
					// Push the override into parallelQueries
					parallelQueries.push({
						// From these records
						query: columnSettings.query,
						// Get this value
						fields: [],
						// Store it according to this mapping
						columnFieldMap: {
							// Store the data from this field
							// NOTE: This is not actually a field on the table from the override query
							// However, we still store this information here in order to use it once we
							// run the query for the selection options
							fieldSchemaName: viewFieldSchemaName,
							// In this column
							columnSchemaName: order + '-' + fieldObj.fieldSchemaName,
							// We may not actually need this but we store the column's field ID anyway
							columnId: fieldId,
							// However, the results from THIS query will be used for this column's dataTableSchemaName and dataRecordId
							useResultData: true
						},
						depQueries: [relatedFieldInfo]
					});
				} else {
					// This is a field on the main table and can be processed as a part of the main query management
					// Temporarily disable this to avoid using single relationship query functionality in main query, as of 3/19/2020
					if (resultCardinality === '1' && false) {
						// Singleton results can be included as a part of the main query
						singleRelationshipFields.push(relatedFieldInfo);
					} else {
						// Otherwise, we need to run a new query for these values
						// Add in additional necessary related field info
						relatedFieldInfo.query = getValueLookupQuery(relationshipSelector);
						relatedFieldInfo.fields = [];
						if(viewFieldSchemaName) {
							relatedFieldInfo.fields.push(viewFieldSchemaName);
						}
						if(editFieldSchemaName) {
							relatedFieldInfo.fields.push(editFieldSchemaName);
						}
						relatedFieldInfo.recordSetTemplates = [
							{
								setName: fieldId + '-original',
								uiName: 'Original User Selected Record(s)',
								tableSchemaName: resultTableSchemaName
							},
							{
								setName: fieldId + '-selected',
								uiName: 'Current User Selected Record(s)',
								tableSchemaName: resultTableSchemaName
							}
						];
						parallelQueries.push(relatedFieldInfo);
					}
				}
			}
		});
	
		// Parallel query optimization (combine multiple identical queries for column overrides, etc. into one)
		parallelQueries = compressQueries(parallelQueries);
	}

	return {
		queryFields,
		expressionFields,
		singleRelationshipFields,
		parallelQueries,
		fieldsNeedLoading
	};
}

/**
 * Used to run the main query and then build the initial List based off of it
 * The purpose here is to get only the values which can be derived from a single call to
 * processQueryV2 so that we can partially populate the list before we have to wait on the expressions
 * and columns with query overrides.
 *
 * @param {string} renderId The render ID of the list field. Used to distinguish multiple instances of a single List within a browser window.
 * @param {string|object} query The query for the main list records. Each record returned from this query will create a new row in list rows.
 * @param {array} queryFields The fields to be used by this query. These are passed directly into processQueryV2, and correspond to the queryFields from getDependentInfo
 * @param {array} singleRelationshipFields The single-valued dynamic selection fields. These are also in queryFields, as they may be handled
 * directly by processQueryV2. These fields are also listed separately because they require
 * dedicated handling when formatting the results of processQueryV2 according to our list row format.
 * @param {object} recordSets The recordSets object. This object is mutated in order to
 * update the umbrella recordSets object when we dispatch our event. This allows the recordSet store to be
 * updated with these records in one go, populating the record sets for any dynamic selection fields in our list.
 * @param {boolean} skipIsLoading The skipIsLoading parameter to pass along to basicRecordSetRowsPostProcessing
 * 
 * @returns {object} Returns an object of the form
 * {
 *	startTime,
 *	listRows,
 *	rowLookup,
 *	recordSets,
 *	recordSetRows
 * }
 */
function runMainQuery(renderId, setName, setUIName, query, queryFields, singleRelationshipFields, recordSets, fieldsNeedLoading, skipIsLoading) {
	return new Promise((resolve) => {
		let startTime = +new Date();
		// Get the rows
		FieldComponentUtils.query.processQueryV2(query, queryFields, renderId, undefined, undefined, true)
			.then(recordSet => {
				// Process the rows into usable formats, which will be used later
				let recordSetRows = recordSet.rows;
				let relations = recordSet.relations;
				let relatedRecords = recordSet.relatedRecords;
				let { listRows, rowLookup } = basicRecordSetRowsPostProcessing(renderId, recordSetRows, fieldsNeedLoading, relations, relatedRecords, queryFields, singleRelationshipFields, recordSets, skipIsLoading);
				// Add our initial record set to recordSets, which our calling location will dispatch or not as need be
				recordSets[renderId][setName] = {
					tableSchemaName: FieldComponentUtils.query.getReturnTable(query),
					setName,
					uiName: setUIName,
					lastDt: startTime,
					query: query,
					fields: queryFields,
					rows: recordSetRows
				};
				return resolve({
					startTime,
					listRows,
					rowLookup,
					recordSets,
					recordSetRows
				});
			})
	});
}

/**
 * Converts a record set into list rows format.
 * Does NOT include expression management, multi-valued
 * dynamic selection fields or columns with query overrides.
 * 
 * This method also builds a rowLookup object, keyed by record ID, whose purpose is to allow
 * easy access to any given row object in the list rows array. This allows us to avoid looping over the 
 * list rows repeatedly when updating rows in later methods, which is an efficiency gain.
 * 
 * This is a helper method for runMainQuery.
 *
 * @param {string} renderId The render ID of the list field. Used to distinguish multiple instances of a single List within a browser window. @TODO: We don't actually use this, so remove it + update calls to this method
 * @param {array} recordSetRows Array of objects with record information. This corresponds to the format returned by processQueryV2, and does not match our list rows format.
 * @param {array} relations The relations value returned by processQueryV2. @TODO: This is unused: remove this and update references to this method accordingly
 * @param {array} queryFields The fields to be used by this query. These are passed directly into processQueryV2, and correspond to the queryFields from getDependentInfo
 * @param {array} singleRelationshipFields The single-valued dynamic selection fields. These are also in queryFields, as they may be handled
 * directly by processQueryV2. These fields are also listed separately because they require
 * dedicated handling when formatting the results of processQueryV2 according to our list row format.
 * @param {object} recordSets The recordSets object. This object is mutated in order to
 * update the umbrella recordSets object when we dispatch our event. This allows the recordSet store to be
 * updated with these records in one go, populating the record sets for any dynamic selection fields in our list.
 * @param {skipIsLoading} boolean Do not include the isLoading flag for fields which may need loading, as we don't want them to display that message.
 * 
 * @returns {object} Returns an object of the form
 * {
 *	listRows,
 *	rowLookup
 * }
 */
function basicRecordSetRowsPostProcessing(renderId, recordSetRows, fieldsNeedLoading, relations, relatedRecords, queryFields, singleRelationshipFields, recordSets, skipIsLoading) {
	let listRows = [];
	// We iterate through rows once in order to make for easier lookup later
	let rowLookup = {};
	recordSetRows.forEach(row => {
		// We want rows in the format [{recordId, fieldSchemaName: {value}}]
		// We do this so that we can add in setting values for individual fields for ListCell to use
		let recordId = row.recordId;
		let tableSchemaName = row.tableSchemaName;
		let newRow = {
			recordId,
			tableSchemaName
		};
		if(!skipIsLoading) {
			// Start by marking all of the fields which may require loading as loading
			fieldsNeedLoading.forEach(fieldSchemaName => {
				newRow[fieldSchemaName] = {
					isLoading: true
				};
			});
		}
		// Loop over all the query columns and handle appropriately
		queryFields.forEach(({ order, fieldSchemaName, dataType, includeInRows }) => {
			// If it's not a relationship field, push everything in normally
			if (dataType !== 'relationship' && includeInRows !== false) {
				let value = row[fieldSchemaName];
				if((dataType === 'multipart' || dataType === 'file') && value) {
					// Parse the value if it's a multipart field
					value = ObjectUtils.getObjFromJSON(value);
				}
				newRow[order + '-' + fieldSchemaName] =
					{
						value: value,
						displayValue: value,
						// Establish a renderId here for each cell that we will then just use
						renderId: uuid.v4(),
						dataRecordId: recordId,
						dataTableSchemaName: tableSchemaName
					};
			}
		});
		// Add in the single related field queries
		singleRelationshipFields.forEach(({ resultTableSchemaName, fieldSchemaName, fieldId, viewFieldSchemaName, relationSchemaName }) => {
			let relatedRecordId = row[relationSchemaName];
			let relationValueObj = relatedRecords && relatedRecords[resultTableSchemaName] && relatedRecords[resultTableSchemaName][relatedRecordId] ?
				relatedRecords[resultTableSchemaName][relatedRecordId] :
				{};
			let relatedRecordValue = relationValueObj[viewFieldSchemaName];
			let cellRenderId = uuid.v4();
			let recordJSON = JSON.stringify([{
				recordId: relatedRecordId,
				tableSchemaName: resultTableSchemaName
			}])
			newRow[fieldSchemaName] =
				{
					// Calculate 'value' appropriately according to the value format for dynamic selection fields
					value: JSON.stringify({
						newRecordJSON: recordJSON,
						startingRelatedRecordsJSON: recordJSON
					}),
					waitForRecordSets: true,
					// We use this value for sorting, etc., and populate the record set store as appropriate
					displayValue: relatedRecordValue,
					// Establish a renderId here for each cell that we will then just use
					renderId: cellRenderId,
					dataRecordId: recordId,
					dataTableSchemaName: tableSchemaName
				};

			// Also push into the record sets
			recordSets[cellRenderId] = recordSets[cellRenderId] || {};
			recordSets[cellRenderId][fieldId + '-original'] = {
				setName: fieldId + '-original',
				uiName: 'Original User Selected Record(s)',
				renderId: cellRenderId,
				tableSchemaName: resultTableSchemaName,
				recordSetArray: [relatedRecordId]
			};
			recordSets[cellRenderId][fieldId + '-selected'] = {
				setName: fieldId + '-selected',
				uiName: 'Current User Selected Record(s)',
				renderId: cellRenderId,
				tableSchemaName: resultTableSchemaName,
				recordSetArray: [relatedRecordId]
			};
		});

		// We do NOT do parallel query processing; that happens elsewhere

		listRows.push(newRow);
		// Store in the rowLookup dictionary
		rowLookup[row.recordId] = newRow;

		return {
			listRows,
			rowLookup
		}
	});

	return {
		listRows,
		rowLookup
	};
}

/**
 * This is not a pure function because it updates both rows and recordSets.
 * 
 * The purpose of this method is to take our list rows (rows) and the results of our parallel
 * queries (parallelResults) and then use the maps in parallelQueries to merge the parallel query's results
 * into our list rows in our appropriate format.
 *
 * @param {array} rows Our list rows in list rows format (see documentation at the top of the page)
 * @param {array} parallelQueries Array of objects which include mapping information to help match parallelResults to their
 * appropriate row and column in rows. Corresponds to the parallelQueries from getDependentValues
 * @param {array} parallelResults Array of objects which have results from the parallel queries.
 * @param {object} recordSets The recordSets object. This object is mutated in order to
 * update the umbrella recordSets object when we dispatch our event. This allows the recordSet store to be
 * updated with these records in one go, populating the record sets for any dynamic selection fields in our list.
 * 
 * Because this is an impure method which updates rows and recordSets in place, we do not
 * return anything, only update the objects/arrays using reference equality
 */
function mergeParallelQueryResultsIntoRows(rows, parallelQueries, parallelResults, recordSets) {
	// We loop over all of the rows in order to clear the loading... flag out + set up render IDs, etc.
	// This can NOT be optimized to use only values from the contextMap, sadly
	rows.forEach(newRow => {
		let recordId = newRow.recordId;
		let tableSchemaName = newRow.tableSchemaName;
		parallelQueries.forEach((queryInfo, index) => {
			let { columnFieldMaps, query: parallelQuery, recordSetTemplates } = queryInfo;
			let resultTableSchemaName = FieldComponentUtils.query.getReturnTable(parallelQuery);
			let queryResult = parallelResults[index];
			if (!queryResult) {
				// @TODO: Handle empty query returns (just return null for now, we shouldn't see this in usual use)
				return;
			}
			let { contextMap, records } = queryResult;
			if (!contextMap || !records) {
				// @TODO: Handle empty contextMap and empty records (just return null for now, we shouldn't see this in usual use)
				return;
			}
			let relatedRecordIds = contextMap[recordId] || [];
			columnFieldMaps.forEach(({ fieldSchemaName, columnSchemaName, useResultData, dataType }) => {
				let cellRenderId = newRow && newRow[columnSchemaName] && newRow[columnSchemaName].renderId ? newRow[columnSchemaName].renderId : uuid.v4();
				let relatedRecordValues = [];
				// Used to build the value if this is a dynamic selection field
				let formattedForDynSelValues = [];
				// Dynamic selection fields should concatenate their results; column override fields should only use the first one
				if(useResultData) {
					let relatedRecordId = relatedRecordIds[0];
					if(relatedRecordId) {
						let relatedRecordValue = records && records[resultTableSchemaName] && records[resultTableSchemaName][relatedRecordId] && records[resultTableSchemaName][relatedRecordId][fieldSchemaName];
						if((dataType === 'multipart' || dataType === 'file') && relatedRecordValue) {
							relatedRecordValue = ObjectUtils.getObjFromJSON(relatedRecordValue);
						}
						relatedRecordValues = relatedRecordValue;
					}
				} else {
					relatedRecordIds.forEach(relatedRecordId => {
						let relatedRecordValue = records && records[resultTableSchemaName] && records[resultTableSchemaName][relatedRecordId] && records[resultTableSchemaName][relatedRecordId][fieldSchemaName];
						relatedRecordValues.push(relatedRecordValue);
						formattedForDynSelValues.push({
							recordId: relatedRecordId,
							tableSchemaName: resultTableSchemaName
						});
					});
				}
				// If this is a dynamic selection field, we'll use this for the value
				let recordJSON = JSON.stringify(formattedForDynSelValues);
				newRow[columnSchemaName] = {
					// Get the appropriate value depending on the field type
					value: useResultData ? relatedRecordValues : JSON.stringify({newRecordJSON: recordJSON, startingRelatedRecordsJSON: recordJSON}),
					// We use this value for sorting, etc., and populate the record set store as appropriate
					displayValue: useResultData ? relatedRecordValues : relatedRecordValues.join(', '),
					// Establish a renderId here for each cell that we will then just use
					renderId: cellRenderId,
					// This recordId and tableSchemaName information may vary based on if dyn sel or column override
					dataRecordId: useResultData ? (relatedRecordIds[0] || '') : recordId,
					dataTableSchemaName: useResultData ? resultTableSchemaName : tableSchemaName
				};

				if(!useResultData) {
					newRow[columnSchemaName].waitForRecordSets = true;
				}

				// Now generate the record sets for the cell, if they're present
				if (recordSetTemplates) {
					recordSets[cellRenderId] = recordSets[cellRenderId] || {};
					recordSetTemplates.forEach(({ setName, uiName, tableSchemaName }) => {
						recordSets[cellRenderId][setName] = {
							setName,
							uiName,
							renderId: cellRenderId,
							tableSchemaName,
							recordSetArray: relatedRecordIds
						};
					});
				}
			});



		});
		return newRow;
	});
}

/**
 * The purpose of this method is to process any expressions in the list which
 * do NOT have context overrides and return a Promise which resolves to their results.
 *
 * @param {array} recordSetRows Array of objects with record information. This corresponds to the format returned by processQueryV2, and does not match our list rows format.
 * @param {array} expressionFields Array of expressions to process. (Corresponds to expressionFields created by getDependentInfo)
 * @param {string} parentFieldId The record ID of the parent field. Used to ensure that local override settings are respective
 * 
 * @returns {array} Array of expression results of the form
 * 
 * [{
 *		recordId,
 *		value
 * }]
 */
function runExpressions(recordSetRows, expressionFields, parentFieldId, renderId) {
	if (!recordSetRows || !expressionFields) {
		return Promise.resolve([]);
	}

	let expressionPromises = expressionFields.map(({ expressionFieldId, attachmentId, settingSchemaName, optimizationScheme, optimizationData }) => {
		if (optimizationScheme === 'singleField' && optimizationData && optimizationData.fieldData && optimizationData.fieldData.fieldSchemaName) {
			// Get fields from the row values
			let fsn = optimizationData.fieldData.fieldSchemaName;
			let part = optimizationData.fieldData.part;
			let singleFieldPromises = recordSetRows.map(row => {
				let value = row[fsn];
				// Support multipart fields (ticket 4464)
				if(part && value) {
					value = typeof value === 'string' ? ObjectUtils.getObjFromJSON(value) : value;
					value = value[part];
				}
				return Promise.resolve({
					recordId: row.recordId,
					value: value
				});
			});
			return Promise.all(singleFieldPromises);
		} else {
			// Forward on to the bulk processor, which already handles other optimizations accordingly
			// Process non-field expressions here
			let settingPath = undefined;
			return FieldComponentUtils.expression.processExpressionBulk(
				expressionFieldId,
				settingSchemaName,
				recordSetRows,
				renderId,
				parentFieldId,
				'field',
				settingPath,
				attachmentId
			);
		}
	});
	return new Promise(resolve => {
		Promise.all(expressionPromises)
			.then(resolve)
			.catch(error => {
				console.error('Error processing expressionPromises', error);
				return resolve([]);
			});
	});
}

/**
 * Updates the list rows for a List with the expression results, using expressionFields to map
 * the results to columns and rows.
 * 
 * This is NOT a pure function: it uses and mutates rowLookup in place,
 * which is in turn an object which uses reference equality to allow for easy
 * lookup of list rows by recordId while still using the listRows array out of which the
 * list is built.
 * 
 * @param {object} rowLookup An object keyed by recordId where each value is a row in list rows
 * @param {array} expressionFields An array of expression fields on the list without context overrides. Used for 
 * @param {array} expressionResults An array of expressionResults, matching the return from runExpressions
 */
function mergeExpressionsIntoListRows(rowLookup, expressionFields, expressionResults) {
	if (!expressionFields || !expressionResults) {
		return;
	}

	// Merge the main expression values in
	expressionFields.forEach(({ expressionForColumn, settingSchemaName, expressionTSN }, index) => {
		let values = expressionResults[index];
		values.forEach(({ recordId, value }) => {
			let row = rowLookup[recordId];
			row[expressionForColumn] = row[expressionForColumn] ?
				row[expressionForColumn] :
				{};
			// There should only be one value per expressionForColumn. If we ever need multiple expression settings on one field on a list, this needs to be updated
			row[expressionForColumn].displayValue = value;
			row[expressionForColumn][settingSchemaName + 'Result'] = value;
			row[expressionForColumn].dataRecordId = recordId;
			row[expressionForColumn].dataTableSchemaName = expressionTSN;
			row[expressionForColumn].renderId = row[expressionForColumn].renderId || uuid.v4();
			row[expressionForColumn].isLoading = false;
		});
	});
}

/**
 * Runs any expressions with overridden columns and inserts it back into parallelResults
 * Also runs dynamic selection fields and handles it similarly to the above
 *
 * @param {array} parallelQueries Array of objects which include mapping information to help match parallelResults to their
 * appropriate row and column in rows. Corresponds to the parallelQueries from getDependentValues
 * @param {array} parallelResults Array of objects which have results from the parallel queries.
 * 
 * @returns {Promise<parallelResults>}
 */
function processDependentCalculations(renderId, parentFieldId, parallelQueries, parallelResults) {
	let depExpressionPromises = [];
	let depQueryPromises = [];
	if (parallelQueries && parallelResults) {
		parallelQueries.forEach((queryObj, index) => {
			let { expressionMaps, query, depQueries } = queryObj;
			if ((!expressionMaps || !expressionMaps.length) && (!depQueries || !depQueries.length)) {
				// Skip unnecessary work
				return;
			}
			let tableSchemaName = FieldComponentUtils.query.getReturnTable(query);
			// Now get the information for the actual expressions and run them
			let records = parallelResults && parallelResults[index] && parallelResults[index].records && parallelResults[index].records[tableSchemaName] ?
				parallelResults[index].records[tableSchemaName] :
				{};
			let recordIds = Object.keys(records);
			// Process the expressions
			if (expressionMaps && expressionMaps.length) {
				let depRecordSetRows = recordIds.map(recordId => {
					return {
						recordId,
						tableSchemaName
					}
				});
				let queryExpressionsPromises = expressionMaps.map(expressionInfo => {
					return new Promise((resolve) => {
						let { expressionFieldSchemaName, expressionFieldId, attachmentId, settingSchemaName, optimizationScheme, optimizationData } = expressionInfo;
						// The goal here is to store the results of each expression on the expressionInfo key itself once the expressions calculate, and _then_ resolve
						// The idea is that the later method to merge rows in can access these values
						// We do this because index-based referencing can get somewhat hairy for these purposes and this makes it easier to find the value
						// This does not resemble the method by which non-overridden expression results are accessed and is not currently intended to

						let expressionResultsPromise = Promise.resolve();
						// Single fields are a map based on the result record IDs + values
						if (optimizationScheme === 'singleField' && optimizationData && optimizationData.fieldData && optimizationData.fieldData.fieldSchemaName) {
							let fsn = optimizationData.fieldData.fieldSchemaName;
							expressionResultsPromise = Promise.all(recordIds.map(recordId => {
								let value = records[recordId] && records[recordId][fsn];
								return Promise.resolve({
									recordId,
									value
								});
							}));
						} else {
							// Forward on to the bulk processor
							let settingPath = undefined;
							expressionResultsPromise = FieldComponentUtils.expression.processExpressionBulk(
								expressionFieldId,
								settingSchemaName,
								depRecordSetRows,
								null,
								parentFieldId,
								'field',
								settingPath,
								attachmentId
							);
						}

						expressionResultsPromise
							.then(results => {
								if (results) {
									results.forEach(({ recordId, value }) => {
										// Add this value into the appropriate parallelResult object
										// It will then be referenced later when merging expressions into the rows
										if (!records[recordId]) {
											records[recordId] = {};
										}
										records[recordId][expressionFieldSchemaName] = value;
									});
								}
								resolve();
							})
							.catch(error => {
								console.error('Error processing expression', error);
								resolve();
							});
					});
				});
				depExpressionPromises.push(Promise.all(queryExpressionsPromises));
			}
			// Process the queries
			if (depQueries && depQueries.length) {
				let querySubqueryPromises = depQueries.map(depQueryInfo => {
					let depQuery = depQueryInfo.query;
					let depFields = depQueryInfo.fields;
					let { columnSchemaName, fieldSchemaName } = depQueryInfo.columnFieldMap || {};
					let depQueryTSN = FieldComponentUtils.query.getReturnTable(depQuery);
					return FieldComponentUtils.query.processQueryBulk(
						depQuery,
						depFields,
						renderId,
						recordIds,
						tableSchemaName
					).then(results => {
						if (results) {
							let { contextMap, records: depRecords } = results;
							// Now merge the results for each recordId together and put into the records
							if(contextMap) {
								Object.keys(contextMap).forEach(recordId => {
									if (!records[recordId]) {
										records[recordId] = {};
									}
									let relatedRecords = contextMap[recordId] || [];
									let values = relatedRecords.map(depRecordId => {
										let newValue = depRecords && depRecords[depQueryTSN] && depRecords[depQueryTSN][depRecordId] && (depRecords[depQueryTSN][depRecordId][fieldSchemaName] || depRecords[depQueryTSN][depRecordId][fieldSchemaName] === 0) ?
											depRecords[depQueryTSN][depRecordId][fieldSchemaName] :
											'';
										return newValue;
									});
									records[recordId][columnSchemaName] = {
										recordIds: relatedRecords,
										values: values.join(', ')
									};
								});
							}
						}
						return results;
					});
				});
				depQueryPromises.push(Promise.all(querySubqueryPromises));
			}
		});
	}

	return new Promise(resolve => {
		Promise.all([Promise.all(depQueryPromises), Promise.all(depExpressionPromises)])
			.then(([depQueryResults]) => {
				return resolve(parallelResults);
			})
			.catch(error => {
				console.error('Error processing expressions in postprocessParallelQueries', error);
				return resolve(parallelResults);
			});
	});
}

/**
 *
 *
 * @param {object} rowLookup An object keyed by recordId where each value is a row in list rows
 * @param {array} parallelQueries Array of objects which include mapping information to help match parallelResults to their
 * appropriate row and column in rows. Corresponds to the parallelQueries from getDependentValues
 * @param {array} parallelResults Array of objects which have results from the parallel queries. This object should
 * have the results added by processDependentCalculations by the time this runs.
 */
function mergeDependentValuesIntoRows(rowLookup, rows, parallelQueries, parallelResults, recordSets) {
	if (parallelQueries && parallelQueries.length && parallelResults) {
		parallelQueries.forEach(({ query, expressionMaps, depQueries }, index) => {

			// @TODO: We get the tableSchemaName so many times; can we optimize that some?
			let tableSchemaName = FieldComponentUtils.query.getReturnTable(query);
			// Process the expressions
			if (expressionMaps) {
				// We'll need the context map on the query when we merge these back into the rows, so add it in
				let contextMap = parallelResults && parallelResults[index] && parallelResults && parallelResults[index].contextMap ?
					parallelResults[index].contextMap :
					{};
				let records = parallelResults && parallelResults[index] && parallelResults[index].records && parallelResults[index].records[tableSchemaName] ?
					parallelResults[index].records[tableSchemaName] :
					{};
				Object.keys(contextMap).forEach(rowRecordId => {
					// Each expression should have 0 or 1 recordIds, but if we somehow find multiples, we use only the first
					let depRecordId = contextMap[rowRecordId] && contextMap[rowRecordId][0] ? contextMap[rowRecordId][0] : '';
					let row = rowLookup[rowRecordId];
					if (row && depRecordId && records[depRecordId]) {
						let depRow = records[depRecordId];
						// Get the expression values from depRow and add them to our row
						expressionMaps.forEach(({ expressionForColumn, expressionFieldSchemaName, settingSchemaName, optimizationData }) => {
							// Initialize the value for this expression in the main rows
							row[expressionForColumn] = row[expressionForColumn] ? row[expressionForColumn] : {};
							// Add the expression result to the main row
							let value = depRow[expressionFieldSchemaName];

							// If our optimized expression is pulling a part from a 
							// multi-part field, then we need to honor that.
							if(optimizationData && optimizationData.fieldData && optimizationData.fieldData.part) {
								value = ObjectUtils.getObjFromJSON(value);
								value = value[optimizationData.fieldData.part];
							}

							// displayValue is used by the list for sorting and filtering
							row[expressionForColumn].displayValue = value;
							// expression Result key is used for fields to read the expression result
							row[expressionForColumn][settingSchemaName + 'Result'] = value;

							// Add the dataRecordId and dataTableSchemaName from the override
							row[expressionForColumn].dataRecordId = depRecordId;
							// @TODO: For regular expressions we do this as expressionTSN. Pick one source of truth and stick to it
							row[expressionForColumn].dataTableSchemaName = tableSchemaName;
							row[expressionForColumn].renderId = row[expressionForColumn].renderId || depRow[expressionForColumn].renderId || uuid.v4();

							// Clear out the isLoading flag
							row[expressionForColumn].isLoading = false;
						});
					}

				});
			}

			if (depQueries) {
				let contextMap = parallelResults && parallelResults[index] && parallelResults && parallelResults[index].contextMap ?
					parallelResults[index].contextMap :
					{};
				let records = parallelResults && parallelResults[index] && parallelResults[index].records && parallelResults[index].records[tableSchemaName] ?
					parallelResults[index].records[tableSchemaName] :
					{};

				// Unfortunately, this cannot be optimized, for the same reason as mergeParallelQueryResultsIntoRows
				rows.forEach(row => {
					let rowRecordId = row.recordId;
					// Each expression should have 0 or 1 recordIds, but if we somehow find multiples, we use only the first
					let depRecordId = contextMap[rowRecordId] && contextMap[rowRecordId][0] ? contextMap[rowRecordId][0] : '';
					if (row) {
						let depRow = records[depRecordId];
						// Get the query result values from depRow and add them to our row
						depQueries.forEach(({ columnFieldMap, resultTableSchemaName }) => {
							let { columnSchemaName, columnId } = columnFieldMap || {};
							row[columnSchemaName] = row[columnSchemaName] ? row[columnSchemaName] : {};
							// Add the query result to the main row
							
							let values = '';
							let recordIds = [];
							// Used to build the value if this is a dynamic selection field
							let formattedForDynSelValues = [];
							if (depRow && depRow[columnSchemaName]) {
								values = depRow[columnSchemaName].values;
								recordIds = depRow[columnSchemaName].recordIds;
								formattedForDynSelValues = recordIds.map(recordId => {
									return {
										recordId,
										tableSchemaName: resultTableSchemaName
									}
								})
							}
							
							let recordJSON = JSON.stringify(formattedForDynSelValues);
							row[columnSchemaName].value = JSON.stringify({newRecordJSON: recordJSON, startingRelatedRecordsJSON: recordJSON});
							row[columnSchemaName].displayValue = values;

							// Add the dataRecordId and dataTableSchemaName from the override
							row[columnSchemaName].dataRecordId = depRecordId;
							row[columnSchemaName].dataTableSchemaName = tableSchemaName;

							// Generate or reuse the render ID
							row[columnSchemaName].renderId =
								(row && row[columnSchemaName] && row[columnSchemaName].renderId ? row[columnSchemaName].renderId : '') ||
								(depRow && depRow[columnSchemaName] && depRow[columnSchemaName].renderId ? depRow[columnSchemaName].renderId : '') ||
								uuid.v4();

							// Clear out the isLoading flag
							row[columnSchemaName].isLoading = false;

							// NOW update recordSets
							let cellRenderId = row[columnSchemaName].renderId;
							recordSets[cellRenderId] = recordSets[cellRenderId] || {};
							recordSets[cellRenderId][columnId + '-original'] = {
								setName: columnId + '-original',
								uiName: 'Original User Selected Record(s)',
								renderId: cellRenderId,
								tableSchemaName: resultTableSchemaName,
								recordSetArray: recordIds
							};
							recordSets[cellRenderId][columnId + '-selected'] = {
								setName: columnId + '-selected',
								uiName: 'Current User Selected Record(s)',
								renderId: cellRenderId,
								tableSchemaName: resultTableSchemaName,
								recordSetArray: recordIds
							};
						});
					}
				});
			}

			// Process the queries

		});
	}
}

/**
 * Build the query for a relationship selector. Used to get the query to
 * run for dynamic selection fields.
 *
 * @param {object} relationshipSelectorObj The relationship selector object
 * (corresponds to the "relationship to manage" setting on a dyn sel field)
 */
function getValueLookupQuery(relationshipSelectorObj) {
	if (!relationshipSelectorObj || !relationshipSelectorObj.relationId) {
		console.warn('Attempting to get query for dynamic selection field with no relationship information. Did you select a relationship to manage?');
		return '';
	}
	let { relationId, direction } = relationshipSelectorObj;
	let relationshipObj = RelationshipStore.get(relationId);
	if (!relationshipObj) {
		console.error('Invalid Relationship selected in Dynamic Select field. Relationship %s does not exist.', relationId);
		return;
	}

	let { rTableSchemaName, lTableSchemaName } = relationshipObj;

	//Define either side's table schema name based on if it's 'left to right' or 'right to left'
	let returnTableSchemaName = (direction === 'ltor' ? rTableSchemaName : lTableSchemaName);
	let relatedTableSchemaName = (direction === 'ltor' ? lTableSchemaName : rTableSchemaName);

	// Now generate the query information
	let queryId = uuid.v4();
	let returnNodeId = uuid.v4();

	let query = {
		queryId: queryId,
		returnNode: returnNodeId,
		filters: [{
			type: 'filter',
			operation: 'recordIs',
			filteredNode: '1a60f7b6-a5d1-4577-a131-bc35ae0cd85b',
			fieldSchemaName: 'recordIs',
			value: 'startingContext'
		}],
		nodes: [
			{
				tableSchemaName: returnTableSchemaName,
				nodeId: returnNodeId,
			},
			{
				tableSchemaName: relatedTableSchemaName,
				nodeId: '1a60f7b6-a5d1-4577-a131-bc35ae0cd85b',
				filters: [
					{
						'type': 'filter',
						'operation': 'relation',
						'recordId': relationId, // relationship record ID
						'filteredNode': '1a60f7b6-a5d1-4577-a131-bc35ae0cd85b',
						'relatedNode': returnNodeId,
						'relationSchemaName': relationshipObj.relationSchemaName,
						'direction': direction,
						'lCardinality': relationshipObj.lCardinality,
						'rCardinality': relationshipObj.rCardinality,
						'requirement': 'must'
					}
				],
			}
		],
		sorts: []
	};

	// @TODO: We stringify this because a variety of utility methods rely on having stringified queries
	// Eventually, we may update all of them and be able to remove this
	// For now, however, we just go with it
	return JSON.stringify(query);
}

/**
 * Compresses redundant queries which use multiple field values so as to avoid more queries than is necessary
 *
 * @param {array} queries Array of query objects. Generally the parallelQueries object.
 * See documentation at the top of this documentation for more information.
 * 
 * @returns {array} Returns an array of queries which have been 'compressed' for efficiency
 */
function compressQueries(queries) {
	let compressedQueries = [];
	if (!queries || !queries.length) {
		return compressedQueries;
	}
	queries.forEach(query => {
		// Have we already seen this query?
		let compressedIndex = compressedQueries.findIndex((currentQuery) => {
			return (FieldComponentUtils.query.compareQueries(currentQuery.query, query.query));
		});

		if (compressedIndex !== -1) {
			// We've already seen this query, so just add in the information for mapping
			compressedQueries[compressedIndex].fields = compressedQueries[compressedIndex].fields.concat(query.fields || []);
			compressedQueries[compressedIndex].columnFieldMaps.push(query.columnFieldMap);
			if (query.recordSetTemplates) {
				compressedQueries[compressedIndex].recordSetTemplates =
					compressedQueries[compressedIndex].recordSetTemplates.concat(query.recordSetTemplates);
			}
			if (query.expressionInfo) {
				compressedQueries[compressedIndex].expressionMaps.push(query.expressionInfo);
			}
			if (query.depQueries) {
				compressedQueries[compressedIndex].depQueries =
					compressedQueries[compressedIndex].depQueries.concat(query.depQueries);
			}
		} else {
			// First time we've seen this query; set everything up
			if (FieldComponentUtils.query.queryIsValid(query.query)) {
				compressedQueries.push({
					query: query.query,
					fields: query.fields || [],
					recordSetTemplates: query.recordSetTemplates ? query.recordSetTemplates : [],
					/*
					* expressionInfo is of format
					{
						optimizationScheme,
						optimizationData,
						expressionTSN, // Table schema name for the expression
						expressionFieldId,
						settingSchemaName,
						expressionForColumn
					}
					*/
					expressionMaps: query.expressionInfo ? [query.expressionInfo] : [],
					depQueries: query.depQueries ? query.depQueries : [],
					/*
					* columnFieldMap is of format
					{
						fieldSchemaName,
						columnSchemaName,
						columnId
					}
					where fieldSchemaName is the field whose value to get,
					columnSchemaName is the field schema name of the column into which to put the value,
					and columnId is the fieldId of the column
					*/
					columnFieldMaps: [query.columnFieldMap]
				});
			}
		}
	});

	return compressedQueries;
}

export default ListActions;
